import { postWithAuth, getWithAuth } from '../../Basic/HttpService';

export const getVehicleInvoice = async (vehicleID, status) => {
    let res = '';
    if (status) {
        res = await getWithAuth(`Invoices?VehicleID=${vehicleID}&Status=${status}`);
    } else {
        res = await getWithAuth(`Invoices?VehicleID=${vehicleID}`);
    }
    return res;
};

export const getJobInvoice = async (JobID) => {
    let res = await getWithAuth(`Invoices?JobID=${JobID}`);
    return res;
};

export const getVehicleInvoiceById = async (id) => {
    let res = await getWithAuth(`Invoices/${id}`);
    return res;
};

export const postgetVehicleInvoiceData = async (data) => {
    let res = await postWithAuth(`Invoices`, data);
    return res;
};

export const invoiceScreenDropdowns = async () => {
    let res = await getWithAuth(`Invoices/screenDropdowns`);
    return res;
};

export const invoiceSearchOrderNo = async (val) => {
    let res = await getWithAuth(`VehicleJob/SearchOrderNo/${val}`);
    return res;
};
