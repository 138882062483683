import { postWithAuth, getWithAuth } from '../../../Basic/HttpService';

export const getJourney = async (Id) => {
    let res = await getWithAuth(`Journeys?VehicleID=${Id}&Active=true`);
    return res;
};

export const getJourneyById = async (Id) => {
    let res = await getWithAuth(`Journeys/${Id}`);
    return res;
};

export const postJourney = async (data) => {
    let res = await postWithAuth(`Journeys`, data);
    return res;
};

export const journeyDropDown = async () => {
    let res = await getWithAuth(`Journeys/screenDropdowns`);
    return res;
};

