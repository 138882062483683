import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

export const FromLoader = (props) => {
    return (
        <div
            style={{
                height: 'calc(100vh - 300px)',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
            }}
        >
            <CircularProgress style={{ marginLeft: 'auto', marginRight: 'auto' }} />
        </div>
    );
};

export const ModalLoader = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <CircularProgress style={{ marginLeft: 'auto', marginRight: 'auto' }} />
        </div>
    );
};
