import React, { useEffect, useState } from 'react';
import { FormHelperText, InputLabel, FormControlLabel } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { SecondaryButton, SecondaryCheckbox, DatePicker, MandatoryField, SimpleField } from '../../../../Core/Controls';
import { useToast } from '../../../../Core/Hooks/useToast';
import moment from 'moment';
import { validatePostCode, emailValidation } from '../../../../Core/Basic/StaticService';
import { getMaintenanceProviderById, postMaintenanceProviderData } from '../../../../Core/Services/InternalUser/ServiceAgent/maintenanceProvider';

const AddMaintenanceProvider = (props) => {
    const [state, setState] = useState({
        maintenanceProviderID: null,
        maintenanceProviderIncludesRFL: false,
        maintenanceProviderIncludesRM: false,
        maintenanceProviderIncludesTyres: false,
        maintenanceProviderIncludesCranes: false,
        maintenanceProviderIncludesFridges: false,
        maintenanceProviderIncludesTailLifts: false,
        maintenanceProviderIncludesOther: false,
        maintenanceProviderIncludesActive: true,
        errors: {}
    });

    const { showToastSuccess, showToastError } = useToast();

    useEffect(async () => {
        if (props.providerID) {
            let res = await getMaintenanceProviderById(props.providerID);
            let data = res.data;
            if (res.success) {
                setState((st) => ({
                    ...st,
                    maintenanceProviderID: data.maintenanceProviderID,
                    maintenanceProviderName: data.maintenanceProviderName,
                    maintenanceProviderAddress1: data.maintenanceProviderAddress1,
                    maintenanceProviderAddress2: data.maintenanceProviderAddress2,
                    maintenanceProviderCity: data.maintenanceProviderCity,
                    maintenanceProviderPostCode: data.maintenanceProviderPostCode,
                    maintenanceProviderCounty: data.maintenanceProviderCounty,
                    maintenanceProviderCountryCode: data.maintenanceProviderCountryCode,
                    maintenanceProviderPhone: data.maintenanceProviderPhone,
                    maintenanceProviderFax: data.maintenanceProviderFax,
                    maintenanceProviderEmail: data.maintenanceProviderEmail,
                    maintenanceProviderWebsite: data.maintenanceProviderWebsite,
                    maintenanceProviderContactName: data.maintenanceProviderContactName,
                    maintenanceProviderContactPhone: data.maintenanceProviderContactPhone,
                    maintenanceProviderContractRef: data.maintenanceProviderContractRef,
                    maintenanceProviderContractStartDate: data.maintenanceProviderContractStartDate
                        ? moment(data.maintenanceProviderContractStartDate).format('YYYY-MM-DD')
                        : null,
                    maintenanceProviderContractEndDate: data.maintenanceProviderContractEndDate
                        ? moment(data.maintenanceProviderContractEndDate).format('YYYY-MM-DD')
                        : null,
                    maintenanceProviderIncludesRFL: data.maintenanceProviderIncludesRFL,
                    maintenanceProviderIncludesRM: data.maintenanceProviderIncludesRM,
                    maintenanceProviderIncludesTyres: data.maintenanceProviderIncludesTyres,
                    maintenanceProviderIncludesCranes: data.maintenanceProviderIncludesCranes,
                    maintenanceProviderIncludesFridges: data.maintenanceProviderIncludesFridges,
                    maintenanceProviderIncludesTailLifts: data.maintenanceProviderIncludesTailLifts,
                    maintenanceProviderIncludesOther: data.maintenanceProviderIncludesOther,
                    maintenanceProviderIncludesActive: data.maintenanceProviderIncludesActive
                }));
            }
        }
    }, []);

    const inputChange = (e) => {
        const { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    const selectHandler = (e) => {
        const { name, checked } = e.target;
        setState((state) => ({ ...state, [name]: checked }));
    };

    const validations = () => {
        const { maintenanceProviderName, maintenanceProviderAddress1, maintenanceProviderCity, maintenanceProviderPostCode, maintenanceProviderEmail } = state;
        let formIsValid = true;
        let errors = {};
        if (!maintenanceProviderName) {
            errors.maintenanceProviderName = 'Name is required';
            formIsValid = false;
        }
        if (!maintenanceProviderAddress1) {
            errors.maintenanceProviderAddress1 = 'Address is required';
            formIsValid = false;
        }
        if (!maintenanceProviderCity) {
            errors.maintenanceProviderCity = 'City is required';
            formIsValid = false;
        }
        if (!validatePostCode(maintenanceProviderPostCode)) {
            errors.maintenanceProviderPostCode = `Please fill valid post code, "Ex: BB11AA"`;
            formIsValid = false;
        }
        if (!maintenanceProviderPostCode) {
            errors.maintenanceProviderPostCode = 'Post Code is required';
            formIsValid = false;
        }
        if (!emailValidation(maintenanceProviderEmail)) {
            errors.maintenanceProviderEmail = 'Please Enter Valid Email';
            formIsValid = false;
        }
        setState((state) => ({ ...state, errors: errors }));
        return formIsValid;
    };

    const submitHandler = async () => {
        if (validations()) {
            const ProviderData = {
                maintenanceProviderID: state.maintenanceProviderID,
                maintenanceProviderName: state.maintenanceProviderName,
                maintenanceProviderAddress1: state.maintenanceProviderAddress1,
                maintenanceProviderAddress2: state.maintenanceProviderAddress2,
                maintenanceProviderCity: state.maintenanceProviderCity,
                maintenanceProviderPostCode: state.maintenanceProviderPostCode,
                maintenanceProviderCounty: state.maintenanceProviderCounty,
                maintenanceProviderCountryCode: state.maintenanceProviderCountryCode,
                maintenanceProviderPhone: state.maintenanceProviderPhone,
                maintenanceProviderFax: state.maintenanceProviderFax,
                maintenanceProviderEmail: state.maintenanceProviderEmail,
                maintenanceProviderWebsite: state.maintenanceProviderWebsite,
                maintenanceProviderContactName: state.maintenanceProviderContactName,
                maintenanceProviderContactPhone: state.maintenanceProviderContactPhone,
                maintenanceProviderContractRef: state.maintenanceProviderContractRef,
                maintenanceProviderContractStartDate: state.maintenanceProviderContractStartDate,
                maintenanceProviderContractEndDate: state.maintenanceProviderContractEndDate,
                maintenanceProviderIncludesRFL: state.maintenanceProviderIncludesRFL,
                maintenanceProviderIncludesRM: state.maintenanceProviderIncludesRM,
                maintenanceProviderIncludesTyres: state.maintenanceProviderIncludesTyres,
                maintenanceProviderIncludesCranes: state.maintenanceProviderIncludesCranes,
                maintenanceProviderIncludesFridges: state.maintenanceProviderIncludesFridges,
                maintenanceProviderIncludesTailLifts: state.maintenanceProviderIncludesTailLifts,
                maintenanceProviderIncludesOther: state.maintenanceProviderIncludesOther,
                maintenanceProviderIncludesActive: state.maintenanceProviderIncludesActive
            };
            let res = await postMaintenanceProviderData(ProviderData);
            if (res.success) {
                showToastSuccess('Maintenance Provider added successfully');
                props.onClose(true);
            } else {
                showToastError(res.message);
                // props.onClose(false);
            }
        }
    };

    return (
        <Grid container direction={'row'} spacing={2}>
            <Grid item xs={12} sm={4} className="mandatory-fields">
                <MandatoryField
                    inputLabel="Name"
                    placeholder="Provider Name"
                    value={state.maintenanceProviderName}
                    onChange={inputChange}
                    name="maintenanceProviderName"
                    errors={state.errors.maintenanceProviderName}
                />
            </Grid>
            <Grid item xs={12} sm={4} className="mandatory-fields">
                <MandatoryField
                    inputLabel="Address1"
                    placeholder="Address1"
                    value={state.maintenanceProviderAddress1}
                    onChange={inputChange}
                    name="maintenanceProviderAddress1"
                    errors={state.errors.maintenanceProviderAddress1}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <SimpleField
                    inputLabel="Address2"
                    placeholder="Address2"
                    value={state.maintenanceProviderAddress2}
                    onChange={inputChange}
                    name="maintenanceProviderAddress2"
                />
            </Grid>

            <Grid item xs={12} sm={4} className="mandatory-fields">
                <MandatoryField
                    inputLabel="City"
                    placeholder="Provider City"
                    value={state.maintenanceProviderCity}
                    onChange={inputChange}
                    name="maintenanceProviderCity"
                    errors={state.errors.maintenanceProviderCity}
                />
            </Grid>
            <Grid item xs={12} sm={4} className="mandatory-fields">
                <MandatoryField
                    inputLabel="Post Code"
                    placeholder="Post Code"
                    value={state.maintenanceProviderPostCode}
                    onChange={inputChange}
                    name="maintenanceProviderPostCode"
                    maxLenght={8}
                    errors={state.errors.maintenanceProviderPostCode}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <SimpleField
                    inputLabel="County"
                    placeholder="County"
                    value={state.maintenanceProviderCounty}
                    onChange={inputChange}
                    name="maintenanceProviderCounty"
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <SimpleField
                    inputLabel="Country Code"
                    placeholder="Country Code"
                    onChange={inputChange}
                    value={state.maintenanceProviderCountryCode}
                    name="maintenanceProviderCountryCode"
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <SimpleField
                    inputLabel="Phone Number"
                    placeholder="Phone Number"
                    value={state.maintenanceProviderPhone}
                    onChange={inputChange}
                    name="maintenanceProviderPhone"
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <SimpleField inputLabel="Fax" placeholder="Fax" value={state.maintenanceProviderFax} onChange={inputChange} name="maintenanceProviderFax" />
            </Grid>
            <Grid item xs={12} sm={4} className="mandatory-fields">
                <MandatoryField
                    inputLabel="Email"
                    placeholder="Email"
                    value={state.maintenanceProviderEmail}
                    onChange={inputChange}
                    name="maintenanceProviderEmail"
                    errors={state.errors.maintenanceProviderEmail}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <SimpleField
                    inputLabel="Website"
                    placeholder="Website"
                    value={state.maintenanceProviderWebsite}
                    onChange={inputChange}
                    name="maintenanceProviderWebsite"
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <SimpleField
                    inputLabel="Contact Name"
                    placeholder="Contact Name"
                    value={state.maintenanceProviderContactName}
                    onChange={inputChange}
                    name="maintenanceProviderContactName"
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <SimpleField
                    inputLabel="Contract Ref"
                    placeholder="Contract Ref"
                    value={state.maintenanceProviderContractRef}
                    onChange={inputChange}
                    name="maintenanceProviderContractRef"
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <InputLabel shrink>Start Date</InputLabel>
                <DatePicker
                    placeholder="Start Date"
                    value={state.maintenanceProviderContractStartDate}
                    onChange={inputChange}
                    name="maintenanceProviderContractStartDate"
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <InputLabel shrink>End Date</InputLabel>
                <DatePicker
                    placeholder="End Date"
                    InputProps={{ inputProps: { min: `${state.maintenanceProviderContractStartDate}` } }}
                    value={state.maintenanceProviderContractEndDate}
                    onChange={inputChange}
                    name="maintenanceProviderContractEndDate"
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <SimpleField
                    inputLabel="Contact Phone"
                    placeholder="Contact Phone"
                    value={state.maintenanceProviderContactPhone}
                    onChange={inputChange}
                    name="maintenanceProviderContactPhone"
                />
            </Grid>
            <Grid item sm={12}></Grid>
            <Grid item xs={12} sm={3}>
                <SecondaryCheckbox name="maintenanceProviderIncludesRFL" checked={state.maintenanceProviderIncludesRFL} onChange={selectHandler} label="RFL?" />
            </Grid>
            <Grid item xs={12} sm={3}>
                <SecondaryCheckbox name="maintenanceProviderIncludesRM" checked={state.maintenanceProviderIncludesRM} onChange={selectHandler} label="RM?" />
            </Grid>
            <Grid item xs={12} sm={3}>
                <SecondaryCheckbox
                    name="maintenanceProviderIncludesTyres"
                    checked={state.maintenanceProviderIncludesTyres}
                    onChange={selectHandler}
                    label="Tyres?"
                />
            </Grid>
            <Grid item xs={12} sm={3}>
                <SecondaryCheckbox
                    name="maintenanceProviderIncludesCranes"
                    checked={state.maintenanceProviderIncludesCranes}
                    onChange={selectHandler}
                    label="Cranes?"
                />
            </Grid>
            <Grid item xs={12} sm={3}>
                <SecondaryCheckbox
                    name="maintenanceProviderIncludesFridges"
                    checked={state.maintenanceProviderIncludesFridges}
                    onChange={selectHandler}
                    label="Fridges?"
                />
            </Grid>
            <Grid item xs={12} sm={3}>
                <SecondaryCheckbox
                    name="maintenanceProviderIncludesTailLifts"
                    checked={state.maintenanceProviderIncludesTailLifts}
                    onChange={selectHandler}
                    label="Tail Lifts?"
                />
            </Grid>
            <Grid item xs={12} sm={3}>
                <SecondaryCheckbox
                    name="maintenanceProviderIncludesOther"
                    checked={state.maintenanceProviderIncludesOther}
                    onChange={selectHandler}
                    label="Other?"
                />
            </Grid>
            <Grid item xs={12} sm={3}>
                <SecondaryCheckbox
                    name="maintenanceProviderIncludesActive"
                    checked={state.maintenanceProviderIncludesActive}
                    onChange={selectHandler}
                    label="Is Active ?"
                    disabled={props.providerID ? false : true}
                />
            </Grid>

            <>
                <Grid item xs={6} className="submit_btn">
                    <SecondaryButton onClick={submitHandler}>{props.providerID ? 'Save' : 'Add'}</SecondaryButton>
                </Grid>
                <Grid item xs={6} className="submit_btn">
                    <SecondaryButton forceEnabled={true} variant="contained" color="secondary" onClick={props.onClose}>
                        Cancel
                    </SecondaryButton>
                </Grid>
            </>
        </Grid>
    );
};

export default AddMaintenanceProvider;
