import React, { useState, useEffect, useMemo } from 'react';
import EditDriverDailyChecks from '../../../Components/InternalUser/Controllers/Vehicles/DriverDailyChecks/editDriverChecks'; //'./editDriverChecks';
import { BreadCrumbs, formatters, EditButton } from '../../../Core/Controls';
import '../../../Components/commonStyle.scss';
import { DataGrid } from '../../../Core/Controls';
import GijgoGrid from '../../../Core/Controls/GijgoGrid';
import { getChecksByQuery } from '../../../Core/Services/InternalUser/DailyChecksInternalUser/dailyChecksInternalUserService';

const basecolumns = [
    // { key: 'action', name: 'Action', width: 80 },
    // { key: 'checked', name: 'Checked', formatter: formatters.Date_DDMMMYYYY_HHMM, width: 150 },
    // { key: 'result', name: 'Result', width: 55 },
    // { key: 'checkType', name: 'Check Type' },
    // { key: 'status', name: 'Status' },
    // { key: 'regNumber', name: 'Reg Number', width: 110 },
    // // { key: 'customer', name: 'Customer', width: 200 },
    // { key: 'checkerName', name: 'Checker Name', width: 140 },
    // { key: 'driverName', name: 'Driver Name', width: 140 },
    // { key: 'mileage', name: 'Mileage', width: 110 },
    // { key: 'driverComment', name: 'Driver Comment', width: 150 },
    // { key: 'checkerComment', name: 'Checker Comment', width: 150 },
    // { key: 'displayName', name: 'Created By' }

    { key: 'action', name: 'Action', width: 80 },
    { key: 'checked', name: 'Checked', formatter: formatters.Date_DDMMMYYYY_HHMM, width: 150 },
    { key: 'regNumber', name: 'Reg Number', width: 90 },
    { key: 'result', name: 'Result', width: 55 },
    // { key: 'checkType', name: 'Check Type' },
    { key: 'status', name: 'Status' },
    { key: 'driverName', name: 'Driver Name' },
    // { key: 'mileage', name: 'Mileage' },
    { key: 'checkerName', name: 'Checker Name' },
    { key: 'checkerComment', name: 'Checker Comment', width: 220 },
    { key: 'customer', name: 'Customer', width: 100 },
    { key: 'depotName', name: 'Depot' },
    { key: 'displayName', name: 'Created By' }
];

const DailyChecksCustomer = (props) => {
    const [state, setState] = useState({
        open: false,
        showLoader: true,
        selectedRowData: {},
        title: '',
        btnFlag: '',
        rows: []
    });

    const pullApiData = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    const editChecksScreen = (id) => {
        // alert('Edit screen is pending');
        setState((st) => ({
            ...st,
            editChecksShow: true,
            checksID: id.checkID
        }));
    };
    const editClose = () => {
        setState((st) => ({
            ...st,
            editChecksShow: false
        }));
        pullApiData();
    };

    const actionButton = (data, row) => {
        return (
            <div style={{ textAlign: 'center' }}>
                <EditButton onClick={() => editChecksScreen(row)} toolTipTitle="Edit Check" />
            </div>
        );
    };
    const crumbs = useMemo(() => {
        return [
            { name: 'Home', path: '/' },
            { name: 'Daily Checks', active: true },
            { name: props.fromPage, active: true }
        ];
    }, [props.fromPage]);

    const columns = useMemo(() => {
        const tempCols = [...basecolumns];
        let actionCol = tempCols.find((e) => e.key === 'action');
        if (actionCol) {
            actionCol.formatter = actionButton;
        }
        return tempCols;
    }, []);

    let dataUrl = `DriverDailyCheck/VehicleDailyChecksList?Result=${props.resultCode}&Status=${props.statusCode}`;
    return (
        <div className="screen">
            <BreadCrumbs crumbs={crumbs} />
            {state.editChecksShow ? (
                <EditDriverDailyChecks onClose={editClose} chekcsID={state.checksID} />
            ) : (
                // <DataGrid
                //     rows={state.rows}
                //     columns={columns}
                //     enableSearch={true}
                //     loadingData={state.showLoader}
                //     paginate
                //     offset={220}
                //     defaultColumnOptions={{
                //         resizable: true
                //     }}
                // />
                <GijgoGrid
                    dataConverter={(res) => ({
                        records: res.data.list,
                        total: res.data.total
                    })}
                    columns={columns}
                    getUrl={dataUrl}
                    // btnTitle={gridBtnTitle}
                    // onBtnClick={addCheck}
                    isReload={state.isReload}
                />
            )}
        </div>
    );
};

export default DailyChecksCustomer;
