import { postWithAuth, getWithAuth, putWithAuth } from '../../../Basic/HttpService';

export const getAllCustomer = async () => {
    let res = await getWithAuth('customers');
    return res;
};

export const getCustomerById = async (Id) => {
    let res = await getWithAuth(`customers/${Id}`);
    return res;
};

export const addCustomeres = async (data, tempOptions, invoice) => {
    let res = await postWithAuth('customers', {
        customerID: null,
        customerShortName: data.customerShortName,
        customerCompanyName: data.customerCompanyName,
        customerAddress1: data.customerAddress1,
        customerAddress2: data.customerAddress2,
        customerCity: data.customerCity,
        customerCounty: data.customerCounty,
        customerPostCode: data.customerPostCode,
        // customerCountryCode: data.customerCountryCode,
        customerTelephone: data.customerTelephone,
        customerEmail: data.customerEmail,
        customerWebSite: data.customerWebSite,
        // customerActive: data.customerActive,
        customerStatusID: tempOptions.customerStatusIDValue?.id,
        customerAccountManagerID: tempOptions.customerAccountManagerName?.id,
        // customerOperationsManagerID: data.customerOperationsManagerID,
        customerOperationsManagerName: data.customerOperationsManagerName,
        customerOperationsManagerPhone: data.customerOperationsManagerPhone,
        customerOperationsManagerEmail: data.customerOperationsManagerEmail,
        customerFinanceDirectorName: data.customerFinanceDirectorName,
        customerFinanceDirectorPhone: data.customerFinanceDirectorPhone,
        customerFinanceDirectorEmail: data.customerFinanceDirectorEmail,
        // customerCurrencyID: tempOptions.customerCurrencyIDValue?.id,
        customerRechargeMarkup: data.customerRechargeMarkup,
        customerISOWeekStartDay: tempOptions.ISODaysValue?.id,
        customerSendEmailNotifications: data.customerSendEmailNotifications || false,
        // customerSendVehicleNotification: data.customerSendVehicleNotification || false,
        // customerVehicleNotificationEmail: data.customerVehicleNotificationEmail,
        customerSendDefectEmail: data.customerSendDefectEmail || false,
        customerDefectEmail: data.customerDefectEmail,
        customerSendBreakdownEmail: data.customerSendBreakdownEmail || false,
        customerBreakdownEmail: data.customerBreakdownEmail,
        customerSendFailedDailyCheck: data.customerSendFailedDailyCheck || false,
        customerFailedDailyCheckEmail: data.customerFailedDailyCheckEmail,
        // ccheduleCompletionReminder: data.ccheduleCompletionReminder,
        sendScheduleConfirmation: data.sendScheduleConfirmation || false,
        customerScheduleConfirmationEmail: data.customerScheduleConfirmationEmail,
        customerSendScheduleCompletion: data.customerSendScheduleCompletion || false,
        customerScheduleCompletionEmail: data.customerScheduleCompletionEmail,
        customerSendVOREmail: data.customerSendVOREmail || false,
        customerVOREmail: data.customerVOREmail,
        customerSendQueryResponseReceived: data.customerSendQueryResponseReceived || false,
        customerQueryResponseReceivedEmail: data.customerQueryResponseReceivedEmail,
        customerSendJobApprovalRequired: data.customerSendJobApprovalRequired || false,
        customerJobApprovalRequiredEmail: data.customerJobApprovalRequiredEmail,
        // customerVORMobileNumbers: data.customerVORMobileNumbers,
        // oLicenseWarningInterval: data.oLicenseWarningInterval,
        // oLicenseWarningFollowupInterval: data.oLicenseWarningFollowupInterval,
        customerAutoApprovalLimit: data.customerAutoApprovalLimit,
        customerInspectionSheetHeadingBackgroundColour: data.customerInspectionSheetHeadingBackgroundColour,
        customerInspectionSheetHeadingTextColour: data.customerInspectionSheetHeadingTextColour,
        // autoApproveDays: data.autoApproveDays,
        // overdueQueriesDays: data.overdueQueriesDays,
        //  autoApproveQueryDays: data.autoApproveQueryDays,
        // autoApproveOrderAckNo: data.autoApproveOrderAckNo,
        // customerAllowedDayRange: data.customerAllowedDayRange,
        // customerDisplayDefectsMonths: data.customerDisplayDefectsMonths,
        customerHandlingDefects: data.customerHandlingDefects || false,
        handlingOLicense: data.handlingOLicense || false,
        // autoCreateMileageBasedService: data.autoCreateMileageBasedService,
        customerEarnedRecognition: data.customerEarnedRecognition || false,
        customerERStartDate: data.customerERStartDate,
        customerLogo: data.customerLogo,
        customerLicenceCheckAccountCode: data.customerLicenceCheckAccountCode,
        customerCountryCode: data.customerCountryCode,
        customerInspectionEventID: tempOptions.customerInspectionEventIDValue?.id,
        customerPaymentDays: tempOptions.customerPaymentDaysValue?.id,
        customerAccountCode: data.customerAccountCode,
        customerOverdueReminderEmail: data.customerOverdueReminderEmail,
        customerSendOverdueReminderEmail: data.customerSendOverdueReminderEmail,

        customerSendInvoiceToDepot: invoice.customerSendInvoiceToDepot,
        customerUseAddressAsInvoiceAddress: invoice.customerUseAddressAsInvoiceAddress,
        customerInvoiceAddress1: invoice.customerInvoiceAddress1,
        customerInvoiceAddress2: invoice.customerInvoiceAddress2,
        customerInvoiceAddressCity: invoice.customerInvoiceAddressCity,
        customerInvoiceAddressCounty: invoice.customerInvoiceAddressCounty,
        customerInvoiceAddressPostCode: invoice.customerInvoiceAddressPostCode,
        customerInvoiceAddressCountryCode: invoice.customerInvoiceAddressCountryCode,
        customerInvoiceEmailAddress: invoice.customerInvoiceEmailAddress,
        customerEmailInvoices: invoice.customerEmailInvoices,
        customerTableHeaderBackgroundColour: data.customerTableHeaderBackgroundColour,
        customerTableHeaderColour: data.customerTableHeaderColour,
        customerOddRowBackgroundColour: data.customerOddRowBackgroundColour,
        customerOddRowColour: data.customerOddRowColour,
        customerEvenRowBackgroundColour: data.customerEvenRowBackgroundColour,
        customerEvenRowColour: data.customerEvenRowColour,
        customerLabourRate: data.customerLabourRate,
        customerOutOfHoursLabourRate: data.customerOutOfHoursLabourRate,
        customerVORHours: data.customerVORHours,
        customerVORTimed: data.customerVORTimed
    });
    return res;
};

export const updateCustomer = async (data, tempOptions, invoice) => {
    let res = await putWithAuth(`customers/${data.customerID}`, {
        customerID: data.customerID,
        customerShortName: data.customerShortName,
        customerCompanyName: data.customerCompanyName,
        customerAddress1: data.customerAddress1,
        customerAddress2: data.customerAddress2,
        customerCity: data.customerCity,
        customerCounty: data.customerCounty,
        customerPostCode: data.customerPostCode,
        // customerCountryCode: data.customerCountryCode,
        customerTelephone: data.customerTelephone,
        customerEmail: data.customerEmail,
        customerWebSite: data.customerWebSite,
        // customerActive: data.customerActive,
        customerStatusID: tempOptions.customerStatusIDValue?.id,
        customerAccountManagerID: tempOptions.customerAccountManagerName?.id,
        // customerOperationsManagerID: data.customerOperationsManagerID,
        customerOperationsManagerName: data.customerOperationsManagerName,
        customerOperationsManagerPhone: data.customerOperationsManagerPhone,
        customerOperationsManagerEmail: data.customerOperationsManagerEmail,
        customerFinanceDirectorName: data.customerFinanceDirectorName,
        customerFinanceDirectorPhone: data.customerFinanceDirectorPhone,
        customerFinanceDirectorEmail: data.customerFinanceDirectorEmail,
        //  customerCurrencyID: tempOptions.customerCurrencyIDValue?.id,
        customerISOWeekStartDay: tempOptions.ISODaysValue?.id,
        customerSendEmailNotifications: data.customerSendEmailNotifications || false,
        // customerSendVehicleNotification: data.customerSendVehicleNotification || false,
        // customerVehicleNotificationEmail: data.customerVehicleNotificationEmail,
        customerSendDefectEmail: data.customerSendDefectEmail || false,
        customerDefectEmail: data.customerDefectEmail,
        customerSendBreakdownEmail: data.customerSendBreakdownEmail || false,
        customerBreakdownEmail: data.customerBreakdownEmail,
        customerSendFailedDailyCheck: data.customerSendFailedDailyCheck || false,
        customerFailedDailyCheckEmail: data.customerFailedDailyCheckEmail,
        // ccheduleCompletionReminder: data.ccheduleCompletionReminder,
        sendScheduleConfirmation: data.sendScheduleConfirmation || false,
        customerScheduleConfirmationEmail: data.customerScheduleConfirmationEmail,
        customerSendScheduleCompletion: data.customerSendScheduleCompletion || false,
        customerScheduleCompletionEmail: data.customerScheduleCompletionEmail,
        customerSendVOREmail: data.customerSendVOREmail || false,
        customerVOREmail: data.customerVOREmail,
        customerSendQueryResponseReceived: data.customerSendQueryResponseReceived || false,
        customerQueryResponseReceivedEmail: data.customerQueryResponseReceivedEmail,
        customerSendJobApprovalRequired: data.customerSendJobApprovalRequired || false,
        customerJobApprovalRequiredEmail: data.customerJobApprovalRequiredEmail,
        // customerVORMobileNumbers: data.customerVORMobileNumbers,
        // customerVORHours: data.customerVORHours,
        // oLicenseWarningInterval: data.oLicenseWarningInterval,
        // oLicenseWarningFollowupInterval: data.oLicenseWarningFollowupInterval,
        customerAutoApprovalLimit: data.customerAutoApprovalLimit,
        customerRechargeMarkup: data.customerRechargeMarkup,
        customerInspectionSheetHeadingBackgroundColour: data.customerInspectionSheetHeadingBackgroundColour,
        customerInspectionSheetHeadingTextColour: data.customerInspectionSheetHeadingTextColour,
        // autoApproveDays: data.autoApproveDays,
        // overdueQueriesDays: data.overdueQueriesDays,
        //  autoApproveQueryDays: data.autoApproveQueryDays,
        //autoApproveOrderAckNo: data.autoApproveOrderAckNo,
        // customerAllowedDayRange: data.customerAllowedDayRange,
        // customerDisplayDefectsMonths: data.customerDisplayDefectsMonths,
        customerHandlingDefects: data.customerHandlingDefects || false,
        handlingOLicense: data.handlingOLicense || false,
        // autoCreateMileageBasedService: data.autoCreateMileageBasedService,
        customerEarnedRecognition: data.customerEarnedRecognition || false,
        customerERStartDate: data.customerERStartDate,
        customerLogo: data.customerLogo,
        customerLicenceCheckAccountCode: data.customerLicenceCheckAccountCode,
        customerCountryCode: data.customerCountryCode,
        customerInspectionEventID: tempOptions.customerInspectionEventIDValue?.id,
        customerPaymentDays: tempOptions.customerPaymentDaysValue?.id,
        customerAccountCode: data.customerAccountCode,
        customerOverdueReminderEmail: data.customerOverdueReminderEmail,
        customerSendOverdueReminderEmail: data.customerSendOverdueReminderEmail,
        customerSendInvoiceToDepot: invoice.customerSendInvoiceToDepot,
        customerUseAddressAsInvoiceAddress: invoice.customerUseAddressAsInvoiceAddress,
        customerInvoiceAddress1: invoice.customerInvoiceAddress1,
        customerInvoiceAddress2: invoice.customerInvoiceAddress2,
        customerInvoiceAddressCity: invoice.customerInvoiceAddressCity,
        customerInvoiceAddressCounty: invoice.customerInvoiceAddressCounty,
        customerInvoiceAddressPostCode: invoice.customerInvoiceAddressPostCode,
        customerInvoiceAddressCountryCode: invoice.customerInvoiceAddressCountryCode,
        customerInvoiceEmailAddress: invoice.customerInvoiceEmailAddress,
        customerEmailInvoices: invoice.customerEmailInvoices,
        customerTableHeaderBackgroundColour: data.customerTableHeaderBackgroundColour,
        customerTableHeaderColour: data.customerTableHeaderColour,
        customerOddRowBackgroundColour: data.customerOddRowBackgroundColour,
        customerOddRowColour: data.customerOddRowColour,
        customerEvenRowBackgroundColour: data.customerEvenRowBackgroundColour,
        customerEvenRowColour: data.customerEvenRowColour,
        customerLabourRate: data.customerLabourRate,
        customerOutOfHoursLabourRate: data.customerOutOfHoursLabourRate,
        customerVORHours: data.customerVORHours,
        customerVORTimed: data.customerVORTimed
    });
    return res;
};

export const getCustomerDropDown = async (Id) => {
    let res = await getWithAuth(`Customers/CustomersscreenDropdowns`);
    return res;
};

export const postImportMileage = async (data) => {
    let res = await postWithAuth(`OdometerReadings/ImportMileageData`, data);
    return res;
};

export const postImportJourney = async (data) => {
    let res = await postWithAuth(`Journeys/ImportJourneyData`, data);
    return res;
};

export const postImportDepot = async (CustomerID, FileData) => {
    let res = await postWithAuth(`Depots/ImportDepotData/${CustomerID}`, FileData);
    return res;
};

export const postImportServiceAgent = async (CustomerID, FileData) => {
    let res = await postWithAuth(`ServiceAgents/ImportServiceAgentData/${CustomerID}`, FileData);
    return res;
};

export const postImportFuel = async (data) => {
    let res = await postWithAuth(`FuelPurchases/ImportFuelPurchases`, data);
    return res;
};

export const getVORVehiclesByType = async (Id, start, end, vehicleCategoryID, makeID) => {
    let res = await getWithAuth(
        `Customers/VORVehiclesByType?id=${Id || ''}&StartDate=${start}&EndDate=${end}&vehicleCategoryID=${vehicleCategoryID}&makeID=${makeID}`
    );
    return res;
};
export const getTop10VORVehiclesByOccurance = async (Id) => {
    let res = await getWithAuth(`Customers/Top10VORVehiclesByOccurance?id=${Id && Id}`);
    return res;
};
export const getTop10VORVehiclesByDays = async (Id) => {
    let res = await getWithAuth(`Customers/Top10VORVehiclesByDays?id=${Id && Id}`);
    return res;
};
