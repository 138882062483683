import React, { useState, useEffect, useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormHelperText, InputLabel } from '@material-ui/core';
import { SecondaryButton, MandatoryField, SimpleField, YesButton, NoButton, DatePicker } from '../../../Core/Controls';
import Paper from '@material-ui/core/Paper';
import { postDefect } from '../../../Core/Services/InternalUser/Maintenance/DefectsService';
import { useToast } from '../../../Core/Hooks/useToast';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useLoginInfo } from '../../../Core/Hooks/useLoginInfo';

const AddEditDefectModal = (props) => {
    const { displayName } = useLoginInfo();
    const [btnLoader, setBtnLoader] = useState(false);

    const [defectFields, setDefectFields] = useState({
        errors: {},
        defectContactName: displayName,
        defectDriverName: displayName,
        customerName: '',
        depotName: '',
        vehicleLoadTypesList: [],
        depotsList: [],
        vehicleID: null,
        defectTypeCode: 'DD',
        workshopBooking: false,
        defectNumberGenerated: '',
        defectRegNumber: props.regNumber,
        defectID: '',
        defectVehicleID: props.filterVehicleID,
        defectReportingDepotID: '',
        defectLocation: '',
        defectCustomerDefectNumber: '',
        dateOfReg: moment().format('YYYY-DD-MM')
    });

    const { showToastSuccess, showToastError } = useToast();

    let history = useHistory();

    const handleActive = (val) => {
        setDefectFields((field) => ({
            ...field,
            workshopBooking: val
        }));
    };

    const inputChange = useCallback((e) => {
        const { name, value } = e.target;
        setDefectFields((st) => ({ ...st, [name]: value }));
    }, []);

    const trimmedHost = window.location.host.includes('localhost') ? 'localhost' : window.location.host;

    const validations = () => {
        const { dateOfReg, defectLocation, defectContactName, defectContactNumber, defectDescription } = defectFields;
        let formIsValid = true;
        let errors = {};
        if (!defectDescription) {
            errors.defectDescription = 'This field is required';
            formIsValid = false;
        }
        if (!defectLocation) {
            errors.defectLocation = 'This field is required';
            formIsValid = false;
        }
        if (!defectContactName) {
            errors.defectContactName = 'This field is required';
            formIsValid = false;
        }

        if (!defectContactNumber) {
            errors.defectContactNumber = 'This field is required';
            formIsValid = false;
        }
        setDefectFields((st) => ({ ...st, errors: errors }));
        return formIsValid;
    };

    const submitHandler = async () => {
        if (validations()) {
            setBtnLoader(true);
            const FormData = {
                workshopBooking: defectFields.workshopBooking || null,
                defectID: defectFields.defectID || null,
                defectTypeCode: defectFields.defectTypeCode || null,
                defectVehicleID: props.filterVehicleID || null,
                defectReportingDepotID: defectFields.defectReportingDepotIDValue?.id || null,
                defectLocation: defectFields.defectLocation || null,
                defectCustomerDefectNumber: defectFields.defectCustomerDefectNumber || null,
                defectContactName: defectFields.defectContactName || null,
                defectContactNumber: defectFields.defectContactNumber || null,
                defectDriverName: defectFields.defectDriverName || null,
                defectDriverTelephone: defectFields.defectDriverTelephone || null,
                defectDescription: defectFields.defectDescription || null,
                defectAuthorisedBy: defectFields.defectAuthorisedBy || null,
                preferredServiceAgent: defectFields.preferredServiceAgent || null,
                defectAlreadyBooked: defectFields.defectAlreadyBooked || false,
                defectAlreadyCompleted: defectFields.defectAlreadyCompleted || false,
                defectCallOutRequired: defectFields.defectCallOutRequired || false,
                defectUrgentCallOut: defectFields.defectUrgentCallOut || false,
                defectVOR: defectFields.defectVOR || false,
                defectPassengersInvolved: defectFields.defectPassengersInvolved || false,
                defectNumberOfPassengers: defectFields.defectNumberOfPassengers || null,
                defectAccidentDamage: defectFields.defectAccidentDamage || false,
                defectInsuranceReference: defectFields.defectInsuranceReference || null,
                domainName: trimmedHost || null,
                defectReplacementVehicle: defectFields.defectReplacementVehicle || false,
                defectVehicleLoaded: defectFields.defectVehicleLoaded || false,
                defectVehicleLoadTypeID: defectFields.defectVehicleLoadTypeIDValue?.id || null,
                defectPG9Issued: defectFields.defectPG9Issued || false,
                defectPoliceOnScene: defectFields.defectPoliceOnScene || false,
                defectAdvisedDontDrive: defectFields.defectAdvisedDontDrive || false,
                defectMobilityRequested: defectFields.defectMobilityRequested || false,
                defectMobilityUsed: defectFields.defectMobilityUsed || false,
                defectMileage: defectFields.defectMileage
            };

            let res = await postDefect(FormData);
            if (res.success) {
                showToastSuccess(`Defect number ${res.data.defectNumber} generated successfully`);
                history.push(`/maintenance/defect/edit/${res.data.defectID}/${props.filterVehicleID}`);
            } else {
                showToastError(res.message);
            }
            setBtnLoader(false);
        }
    };

    return (
        <>
            <Grid container spacing={2}>
                {/* <Grid item xs={12} sm={6} md={4} lg={3} className="mandatory-fields">
                    <InputLabel required shrink>
                        Defect Date
                    </InputLabel>
                    <DatePicker placeholder="Defect Date" value={defectFields.dateOfReg} onChange={inputChange} name="dateOfReg" />
                    <FormHelperText error>{defectFields.errors.dateOfReg}</FormHelperText>
                </Grid> */}
                <Grid item xs={12} sm={6} className="mandatory-fields">
                    <MandatoryField
                        inputLabel="Reported By"
                        placeholder="Reported By"
                        value={defectFields.defectContactName}
                        onChange={inputChange}
                        name="defectContactName"
                        errors={defectFields.errors.defectContactName}
                    />
                </Grid>
                <Grid item xs={12} sm={6} className="mandatory-fields">
                    <MandatoryField
                        inputLabel="Reported Phone"
                        placeholder="Reported Phone"
                        value={defectFields.defectContactNumber}
                        onChange={inputChange}
                        name="defectContactNumber"
                        errors={defectFields.errors.defectContactNumber}
                    />
                </Grid>
                <Grid item xs={12} className="mandatory-fields">
                    <MandatoryField
                        inputLabel="Vehicle Location"
                        placeholder="Vehicle Location"
                        value={defectFields.defectLocation}
                        onChange={inputChange}
                        name="defectLocation"
                        errors={defectFields.errors.defectLocation}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <SimpleField
                        inputLabel="Preferred Service Agent"
                        placeholder="Preferred Service Agent"
                        value={defectFields.preferredServiceAgent}
                        onChange={inputChange}
                        name="preferredServiceAgent"
                    />
                    <FormHelperText error>{defectFields.errors.preferredServiceAgent}</FormHelperText>
                </Grid>
                <Grid item container xs={6} className="grid-paper-shadow">
                    <InputLabel shrink>Workshop Booking required?</InputLabel>
                    <Grid xs={12} item container spacing={2}>
                        <Grid item xs={6}>
                            <YesButton state={defectFields.workshopBooking} onClick={() => handleActive(true)}></YesButton>
                        </Grid>
                        <Grid item xs={6}>
                            <NoButton state={defectFields.workshopBooking} onClick={() => handleActive(false)}></NoButton>
                        </Grid>
                    </Grid>
                </Grid>
                {/* <Grid item xs={12} sm={6} md={4} lg={3}>
                    <MandatoryField
                        inputLabel="Driver Name"
                        placeholder="Driver Name"
                        value={defectFields.defectDriverName}
                        onChange={inputChange}
                        name="defectDriverName"
                        errors={defectFields.errors.defectDriverName}
                    />
                </Grid> */}

                <Grid item xs={12} sm={12} className="mandatory-fields">
                    <MandatoryField
                        inputLabel="Defect Details"
                        multiline
                        rows={3}
                        placeholder="Defect Details"
                        value={defectFields.defectDescription}
                        onChange={inputChange}
                        name="defectDescription"
                        errors={defectFields.errors.defectDescription}
                    />
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6} className="submit_btn">
                        <SecondaryButton onClick={submitHandler} isBtnLoader={btnLoader}>
                            Add
                        </SecondaryButton>
                    </Grid>
                    <Grid item xs={6} className="submit_btn">
                        <SecondaryButton onClick={props.onClose}>Cancel</SecondaryButton>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default AddEditDefectModal;
