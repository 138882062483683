import { InputLabel } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React, { useState, useContext, useMemo } from 'react';
import { MandatoryField, PrimaryCheckbox, DataGrid, DeleteButton, formatters, SecondaryButton, SelectBox } from '../../../../Core/Controls';
import AddReportStepper from './AddReportStepper';
import { FormHelperText } from '@material-ui/core';
import { ReportsProvider as StateProvider, ReportsScreenContext } from './ReportProvider';
const baseColumns = [
    {
        key: 'action',
        name: 'Action',
        width: '5%'
    },
    {
        key: 'reportCatalogueColumnDisplayName',
        name: 'Name'
    },
    {
        key: 'reportCatalogueColumnFieldType',
        name: 'Field Type'
    }
];
const baseSequenceColumn = [
    {
        key: 'action',
        name: 'Action',
        width: '5%'
    },
    { key: 'reportCatalogueColumnDisplayName', name: 'Column Name' },
    { key: 'seqDescription', name: 'Order' }
];

const AddReportScreen = () => {
    const {
        state,
        inputChange,
        handlePreviousStep,
        handleNextSteps,
        selectionOnChange,
        handelAddSequence,
        handleSubmit,
        handleDelete,
        deleteSequence,
        reportInputChange
    } = useContext(ReportsScreenContext);

    const dataFormatter = (data) => {
        return (
            <div style={{ textAlign: 'center' }}>
                <DeleteButton onClick={() => handleDelete(data.row.reportCatalogueColumnID)} toolTipTitle="Delete Job Item" />
            </div>
        );
    };

    const dataFormatterSequenceColumn = (data) => {
        return (
            <div style={{ textAlign: 'center' }}>
                <DeleteButton onClick={() => deleteSequence(data.row)} toolTipTitle="Delete Job Item" />
            </div>
        );
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.key === 'action');
        if (actionCol) {
            actionCol.formatter = dataFormatter;
        }
        return tempCols;
    }, [state.selectedColumns]);

    const sequenceColumn = useMemo(() => {
        const tempCols = [...baseSequenceColumn];
        let actionCol = tempCols.find((element) => element.key === 'action');
        if (actionCol) {
            actionCol.formatter = dataFormatterSequenceColumn;
        }
        return tempCols;
    }, [state.sequenceVM]);

    function getStepContent() {
        switch (state.activeStep) {
            case 0:
                return (
                    <>
                        <Grid item xs={12} sm={6} md={4}>
                            <MandatoryField
                                name="userReportName"
                                value={state.userReportName}
                                inputLabel="Report Name"
                                placeholder="Report Name"
                                onChange={reportInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <InputLabel shrink>Report Catalogue</InputLabel>
                            <SelectBox
                                name="userReportCatalogueID"
                                value={+state.userReportCatalogueID}
                                List={state.reportCatalogue}
                                onChange={reportInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <InputLabel shrink>Schedule</InputLabel>
                            <SelectBox name="userReportScheduleID" value={+state.userReportScheduleID} List={state.schedule} onChange={reportInputChange} />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <PrimaryCheckbox
                                name="userReportSendEmail"
                                value={state.userReportSendEmail}
                                checked={state.userReportSendEmail}
                                onChange={reportInputChange}
                                label="Receive Email?"
                            />
                        </Grid>
                    </>
                );
            case 1:
                return (
                    <Grid container direction={'row'} spacing={2} item xs={12} justify="flex-start">
                        <Grid item xs={4} sm={3}>
                            <InputLabel shrink>Columns</InputLabel>
                            <SelectBox name="userColumnId" value={+state.userColumnId} List={state.columnList} onChange={inputChange} />
                        </Grid>
                        <Grid item xs={12} sm={12} style={{ marginTop: 8 }}>
                            <DataGrid
                                rows={state.rows}
                                columns={columns}
                                // loadingData={state.showLoader}
                                offset={530}
                            />
                        </Grid>
                    </Grid>
                );
            case 2:
                return state.selectionList.map((m) => {
                    return (
                        <>
                            <Grid item xs={12} sm={3} style={{ margin: 'auto' }}>
                                <InputLabel shrink>{m?.reportCatalogueColumnDisplayName}</InputLabel>
                            </Grid>
                            <Grid item xs={4} sm={3}>
                                <InputLabel shrink>Operator</InputLabel>
                                <SelectBox
                                    value={m?.userReportSelectionOperator}
                                    name="userReportSelectionOperator"
                                    List={state.operator}
                                    onChange={selectionOnChange(m?.reportCatalogueColumnID)}
                                />
                                <p>{state.errors[`toOprator${m.reportCatalogueColumnID}`]}</p>
                            </Grid>
                            <Grid item xs={4} sm={3}>
                                <MandatoryField
                                    inputLabel="From"
                                    value={m?.userReportFromValue}
                                    name="userReportFromValue"
                                    placeholder="From"
                                    onChange={selectionOnChange(m?.reportCatalogueColumnID)}
                                    errors={state.errors[`fromError${m.reportCatalogueColumnID}`]}
                                    disabled={m?.userReportSelectionOperator == '<' || m?.userReportSelectionOperator == '<=' ? true : false}
                                />
                            </Grid>
                            <Grid item xs={4} sm={3}>
                                <MandatoryField
                                    value={m?.userReportToValue}
                                    name="userReportToValue"
                                    inputLabel="To"
                                    placeholder="To"
                                    onChange={selectionOnChange(m?.reportCatalogueColumnID)}
                                    errors={state.errors[`toError${m.reportCatalogueColumnID}`]}
                                    disabled={m?.userReportSelectionOperator == '>' || m?.userReportSelectionOperator == '>=' ? true : false}
                                />
                            </Grid>
                        </>
                    );
                });
            case 3:
                return (
                    <>
                        <Grid item xs={3} sm={3}>
                            <InputLabel shrink>Columns</InputLabel>
                            <SelectBox name="sequenceReportCatID" value={state.sequenceReportCatID} List={state.sequenceColumList} onChange={inputChange} />
                            <FormHelperText error>{state.errors.sequenceReportCatID}</FormHelperText>
                        </Grid>
                        <Grid item xs={4}>
                            <InputLabel shrink>Order</InputLabel>
                            <SelectBox name="userReportSequenceOrder" value={state.userReportSequenceOrder} List={state.sequenceList} onChange={inputChange} />
                            <FormHelperText error>{state.errors.userReportSequenceOrder}</FormHelperText>
                        </Grid>
                        <Grid item xs={2} className="btn-hw">
                            <InputLabel>{'\u{2800}'}</InputLabel>
                            <SecondaryButton onClick={handelAddSequence}>Add</SecondaryButton>
                        </Grid>
                        <Grid item xs={12} sm={12} style={{ marginTop: 8 }}>
                            <DataGrid
                                rows={state.sequenceVM}
                                columns={sequenceColumn}
                                // loadingData={state.showLoader}
                                offset={530}
                            />
                        </Grid>
                    </>
                );
        }
    }

    return (
        <>
            <Grid container direction={'row'} spacing={2}>
                <AddReportStepper activeStep={state.activeStep} />

                <Grid item xs={12} sm={12}>
                    <hr />
                </Grid>

                {getStepContent()}
            </Grid>

            <Grid container direction={'row'} spacing={3} className="controller-screen">
                <Grid item xs={12} className="btn-hw">
                    <SecondaryButton disabled={state.activeStep === 0} onClick={() => handlePreviousStep(-1)}>
                        Previous Step
                    </SecondaryButton>
                    {state.activeStep != 3 ? (
                        <SecondaryButton onClick={() => handleNextSteps(1)}>Next Step</SecondaryButton>
                    ) : (
                        <SecondaryButton onClick={handleSubmit}>Save and Close</SecondaryButton>
                    )}
                </Grid>
            </Grid>
        </>
    );
};

const Screen = (props) => {
    return (
        <StateProvider {...props}>
            <AddReportScreen {...props} />
        </StateProvider>
    );
};
export default Screen;
