import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormHelperText, InputLabel } from '@material-ui/core';
import { InputText, SecondaryButton, MandatoryField, SimpleField } from '../../../Core/Controls';
import { useToast } from '../../../Core/Hooks/useToast';
import { addBranches, updateBranches } from '../../../Core/Services/Admin/branchService';
import { isNull } from 'lodash';
import { validatePostCode } from '../../../Core/Basic/StaticService';
import { Postcode } from '../../../Core/Controls/index';
import { getBranchById } from '../../../Core/Services/Admin/branchService';

const AddEditBranch = (props) => {
    const { showToastSuccess, showToastError } = useToast();

    const [state, setState] = useState({
        errors: {},
        shortName: '',
        branchCode: '',
        branchName: '',
        branchID: null,
        fax: null,
        address2: '',
        address1: '',
        address3: '',
        town: '',
        country: '',
        county: '',
        postcode: '',
        phone: ''
    });

    useEffect(async () => {
        if (props.branchData?.branchID) {
            let res = await getBranchById(props.branchData.branchID);
            if (res.success) {
                const data = res.data;
                setState((st) => ({
                    ...st,
                    branchID: data.id,
                    shortName: data.shortName,
                    branchCode: data.branchCode,
                    branchName: data.name,
                    address1: data.address1,
                    address2: data.address2,
                    address3: data.address3,
                    town: data.town,
                    county: data.county,
                    country: data.country,
                    phone: data.phone,
                    fax: data.branchFax,
                    postcode: data.postcode
                }));
            }
        }
    }, []);

    const setVal = (s) => {
        setState((st) => ({
            ...st,
            address1: s.addressLine1,
            address2: s.addressLine2,
            town: s.town,
            county: s.county,
            postcode: s.postCode
        }));
    };
    const inputChange = (e) => {
        const { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    const validations = () => {
        const { branchCode, shortName, branchName, fax, postcode } = state;
        let faxRegEx = /^\+?[0-9]{11,}$/;
        let formIsValid = true;
        let errors = {};
        if (!branchName) {
            errors.branchName = 'Branch Name is required';
            formIsValid = false;
        }
        if (!validatePostCode(postcode) && /[^\s]/.test(postcode) && !isNull(postcode)) {
            errors.postCode = `Please fill valid post code, "Ex: BB11AA"`;
            formIsValid = false;
        }
        if (!branchCode) {
            errors.branchCode = 'Branch Code is required';
            formIsValid = false;
        }
        if (!shortName) {
            errors.shortName = 'Short Name is required.';
            formIsValid = false;
        }
        if (!/[^\s]/.test(fax) && !faxRegEx.test(fax)) {
            errors.fax = 'Please fill correct data.';
            formIsValid = false;
        }
        setState((st) => ({ ...st, errors: errors }));
        return formIsValid;
    };

    const handleSubmit = async () => {
        if (validations()) {
            let res;
            if (props.branchData.id) {
                res = await updateBranches(state);
            } else {
                res = await addBranches(state);
            }
            if (res.success) {
                showToastSuccess(res.message);
                props.onFormSubmit(true);
            } else {
                showToastError(res.message);
            }
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={4} className="mandatory-fields">
                <MandatoryField
                    inputLabel="Branch Code"
                    placeholder="Branch Code"
                    value={state.branchCode}
                    onChange={inputChange}
                    name="branchCode"
                    errors={state.errors.branchCode}
                />
            </Grid>
            <Grid item xs={12} sm={4} className="mandatory-fields">
                <MandatoryField
                    inputLabel="Short Name"
                    placeholder="Short Name"
                    value={state.shortName}
                    onChange={inputChange}
                    name="shortName"
                    errors={state.errors.shortName}
                />
            </Grid>
            <Grid item xs={12} sm={4} className="mandatory-fields">
                <MandatoryField
                    inputLabel="Branch Name"
                    placeholder="Branch Name"
                    value={state.branchName}
                    onChange={inputChange}
                    name="branchName"
                    errors={state.errors.branchName}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <SimpleField inputLabel="Address1" placeholder="Address1" value={state.address1} onChange={inputChange} name="address1" />
            </Grid>
            <Grid item xs={12} sm={4}>
                <SimpleField inputLabel="Address2" placeholder="Address2" value={state.address2} onChange={inputChange} name="address2" />
            </Grid>
            <Grid item xs={12} sm={4}>
                <SimpleField inputLabel="Address3" placeholder="Address3" value={state.address3} onChange={inputChange} name="address3" />
                <FormHelperText error>{state.errors.address3}</FormHelperText>
            </Grid>
            <Grid item xs={12} sm={4}>
                <SimpleField inputLabel="Town" placeholder="Town" value={state.town} onChange={inputChange} name="town" />
            </Grid>
            <Grid item xs={12} sm={4}>
                <SimpleField inputLabel="County" placeholder="County" value={state.county} onChange={inputChange} name="county" />
            </Grid>
            <Grid item xs={12} sm={4}>
                <SimpleField inputLabel="Country" placeholder="Country" value={state.country} onChange={inputChange} name="country" />
            </Grid>
            <Grid item xs={12} sm={4}>
                <Postcode value={state.postcode} onChange={inputChange}  setVal={setVal} name="postcode" errors={state.errors.postcode} />
                {/* <SimpleField inputLabel="Post Code" placeholder="Post Code" value={state.postcode} onChange={inputChange} name="postcode" />*/}
                <FormHelperText error>{state.errors.postcode}</FormHelperText> 
            </Grid>
            <Grid item xs={12} sm={4}>
                <SimpleField inputLabel="Phone" placeholder="Phone" value={state.phone} onChange={inputChange} name="phone" />
            </Grid>
            <Grid item xs={12} sm={4}>
                <SimpleField inputLabel="Fax" placeholder="Fax" value={state.fax} onChange={inputChange} name="fax" />
                <FormHelperText error>{state.errors.fax}</FormHelperText>
            </Grid>
            <Grid item xs={12}>
                <SecondaryButton onClick={handleSubmit} fullWidth className="submit_btn">
                    Submit
                </SecondaryButton>
            </Grid>
        </Grid>
    );
};

export default AddEditBranch;
