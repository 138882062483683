import React, { useEffect, useContext, useState } from 'react';
import { getAllStickies } from '../../../../Core/Services/InternalUser/ControllerService/noteService';

import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import './NotesandSticky.scss';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(1)
        }
    }
}));

export const StickyNotes = (props) => {
    const classes = useStyles();
    const [state, setState] = useState({
        stickyList: []
    });
    useEffect(async () => {
        let res = await getAllStickies(props.frompage, props.NoteVehicleID, props.NoteServiceAgID, props.customerID, props.NoteDriverID, props.noteRefsGuidID);
        setState((st) => ({ ...st, stickyList: res.data }));
    }, [props.NoteVehicleID]);

    return (
        <div className="alert-container">
            {state.stickyList.length > 0 &&
                state.stickyList.map((q, index = 1) => {
                    return (
                        <Alert key={index} severity="warning" className="alert-items">
                            {q.title}
                            <br />
                            {q.description}
                        </Alert>
                    );
                })}
        </div>
    );
};

export default StickyNotes;
