import { AppStorage } from '../Basic/storage-service';

let checkhaveAccess = AppStorage.getAllowAcces();
let obj = {
    Customers: checkhaveAccess?.CanViewCustomers,
    Jobs: checkhaveAccess?.CanViewJobs,
    Drivers: checkhaveAccess?.CanViewDrivers,
    'Service Agents': checkhaveAccess?.CanViewServiceAgents,
    Invoices: checkhaveAccess?.CanViewInvoices,
    'Company Details': checkhaveAccess?.CanViewCompanyDetails
};

export const GetAllowMenus = (menus) => {
    let tempMenus = menus;
    Object.entries(obj).forEach((item) => {
        if (!item[1]) {
            console.log(item[0], item[1]);
            let jobIndex = tempMenus.findIndex((m) => m.screenLabel === item[0]);
            if (jobIndex >= 0) {
                tempMenus.splice(jobIndex, 1);
            }
        }
    });
    return tempMenus;
};
