import { createContext, useEffect, useState, useCallback } from 'react';
import { useToast } from '../../../../Core/Hooks/useToast';
import { addDriver, updateDriver, getDriverById, getDriverScreenDropdowns } from '../../../../Core/Services/InternalUser/ControllerService/driverService';
import { formatters } from '../../../../Core/Controls';
import { useLoginInfo } from '../../../../Core/Hooks/useLoginInfo';

import { isNull } from 'lodash';
import { validatePostCode } from '../../../../Core/Basic/StaticService';
export const DriverScreenContext = createContext();

export const DriverProvider = (props) => {
    const { showToastSuccess, showToastError } = useToast();
    const isCustomer = useLoginInfo().userRoles.includes('customer');
    const [btnLoader, setBtnLoader] = useState(false);
    const [state, setState] = useState({
        driverDefectManager: '',
        driverLicenseCheckFrequency: '',
        driverLicenceTypeID: '',
        driverFuelCardProvider: '',
        driverFuelCardNumber: '',
        driverFuelCardPIN: '',
        driverLicenseNumber: '',
        errors: {},
        driverID: null,
        customerID: null,
        depotsID: '',
        driverTypeID: '',
        costCentreID: null,
        firstName: '',
        licenseTypeID: null,
        payrollNumber: null,
        middleName: '',
        firstName: '',
        lastName: '',
        jobTitle: '',
        dateOfBirth: '',
        dateOfBirth1: '',
        address1: '',
        address2: '',
        town: '',
        county: '',
        postCode: '',
        telePhone: '',
        driverMobile: '',
        driverReceiveEmailNotification: false,
        driverLoginEnabled: false
    });

    const [optionsState, setOptionState] = useState({
        customerList: [],
        depotWithCustList: [],
        costCentreList: [],
        depotsList: [],
        licenseTypeList: [],
        driverTypeList: [],
        genderList: [],
        licenceList: [],
        driverLicenceTypesList: [],
        fuelCardProvidersList: [],
        driverFuelCardNumberList: [],
        defectManagerList: []
    });

    const [tempOptions, setTempOptions] = useState({
        customerIDValue: null,
        depotsIDValue: null,
        driverTypeIDValue: null,
        costCentreIDValue: null,
        licenseTypeIDValue: null,
        genderValue: null,
        driverDefectManagerValue: null,
        driverLicenseCheckFrequencyValue: null,
        driverFuelCardProviderValue: null
    });

    useEffect(async () => {
        let res = await getDriverScreenDropdowns();
        setOptionState((st) => ({
            ...st,
            customerList: res.data.customers,
            costCentreList: res.data.costCentres,
            depotsList: res.data.depots,
            licenseTypeList: res.data.driverLicenceTypes,
            driverTypeList: res.data.driverTypes,
            genderList: res.data.genders,
            licenceList: res.data.licenceCheckFrequency,

            driverLicenceTypesList: res.data.driverLicenceTypes,
            fuelCardProvidersList: res.data.fuelCardProviders,
            driverFuelCardNumberList: res.data.driverFuelCardNumber,
            defectManagerList: res.data.defectManager
        }));

        if (props.driverID) {
            let res = await getDriverById(props.driverID);
            const data = res.data;
            setState((st) => ({
                ...st,
                customer: data.customer,
                address1: data.driverAddress1,
                address2: data.driverAddress2,
                address3: data.driverAddress3,
                driverCostCentreDescription: data.driverCostCentreDescription,
                customerID: data.driverCustomerID,
                depotsID: data.driverDepotID,
                driverDepotName: data.driverDepotName,
                jobTitle: data.driverJobTitle,
                gender: data.driverGenderID,

                //new
                driverLicenseCheckFrequency: data.driverLicenseCheckFrequency,
                driverFuelCardProvider: data.driverFuelCarProvider,
                driverDefectManager: data.driverDefectManager,
                driverFuelCardNumber: data.driverFuelCardNumber,
                driverFuelCardPIN: data.driverFuelCardPIN,
                driverLicenseNumber: data.driverLicenseNumber,
                //end

                costCentreID: data.driverCostCentreID,
                driverLicenceDescription: data.driverLicenceDescription,
                licenseTypeID: data.driverLicenceTypeID,
                lastName: data.driverLastName,
                middleName: data.driverMiddleName,
                dateOfBirth: formatters.DateFormatter(data.driverDateOfBirth),
                dateOfBirth1: formatters.YearFormatter(data.driverDateOfBirth),
                driverID: data.driverID,
                firstName: data.driverFirstName,
                driverPayrollNumber: data.driverPayrollNumber,
                postCode: data.driverPostCode,
                town: data.driverTown,
                county: data.driverCounty,
                driverLoginEnabled: data.driverLoginEnabled,
                driverMobile: data.driverMobile,
                driverReceiveEmailNotification: data.driverReceiveEmailNotification,
                telePhone: data.driverTelephone,
                driverTypeID: data.driverTypeID,
                licenceCheckFrequencyDescription: data.licenceCheckFrequencyDescription,
                fuelCardProviderName: data.fuelCardProviderName,
                fileCount: data.fileCount
            }));
        }
    }, []);

    useEffect(() => {
        let newSt = {};
        if (state.customerID) {
            newSt.customerIDValue = optionsState.customerList.find(({ id }) => id === state.customerID);
        }
        if (state.depotsID) {
            newSt.depotsIDValue = optionsState.depotsList.find(({ id }) => id === state.depotsID);
        }
        if (state.driverTypeID) {
            newSt.driverTypeIDValue = optionsState.driverTypeList.find(({ id }) => id === state.driverTypeID);
        }
        if (state.costCentreID) {
            newSt.costCentreIDValue = optionsState.costCentreList.find(({ id }) => id === state.costCentreID);
        }
        if (state.licenseTypeID) {
            newSt.licenseTypeIDValue = optionsState.licenseTypeList.find(({ id }) => id === state.licenseTypeID);
        }
        if (state.gender) {
            newSt.genderValue = optionsState.genderList.find(({ id }) => id === state.gender);
        }
        if (state.driverDefectManager) {
            newSt.driverDefectManagerValue = optionsState.defectManagerList.find(({ id }) => id === state.driverDefectManager);
        }
        if (state.driverLicenseCheckFrequency) {
            newSt.driverLicenseCheckFrequencyValue = optionsState.licenceList.find(({ id }) => id === state.driverLicenseCheckFrequency);
        }
        if (state.driverFuelCardProvider) {
            newSt.driverFuelCardProviderValue = optionsState.fuelCardProvidersList.find(({ id }) => id === state.driverFuelCardProvider);
        }
        setTempOptions((st) => ({
            ...st,
            ...newSt
        }));
    }, [
        state.customerID,
        state.depotsID,
        state.driverTypeID,
        state.costCentreID,
        state.licenseTypeID,
        state.gender,
        state.driverDefectManager,
        state.driverLicenseCheckFrequency,
        state.driverFuelCardProvider
    ]);

    useEffect(async () => {
        let Depots = [];
        if (isCustomer) {
            setOptionState((st) => ({ ...st, depotWithCustList: optionsState.depotsList }));
        } else {
            if (tempOptions.customerIDValue) {
                optionsState.depotsList.filter((obj) => {
                    if (tempOptions.customerIDValue.id === obj.depotCustomerID) {
                        Depots.push(obj);
                    }
                });
                setOptionState((st) => ({ ...st, depotWithCustList: Depots }));
                // setTempOptions((st) => ({ ...st, depotsIDValue: null }));
            }
        }
    }, [tempOptions.customerIDValue, isCustomer, optionsState.depotsList]);

    const inputChange = useCallback((e) => {
        const { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    });

    const singleSelectChange = (nm) => (ev, val) => {
        setTempOptions((st) => ({ ...st, [nm]: val }));
    };

    const handleCheckbox = useCallback((e) => {
        const { name, checked } = e.target;
        setState((state) => {
            const nst = { ...state, [name]: checked };
            return nst;
        });
    });

    const setVal = (s) => {
        setState((st) => ({
            ...st,
            address1: s.addressLine1,
            address2: s.addressLine2,
            town: s.town,
            county: s.county,
            postCode: s.postCode
        }));
    };

    const validations = () => {
        const { customerID, costCentreID, licenseTypeID, firstName, depotsID, driverTypeID, dateOfBirth, postCode } = state;

        const {
            customerIDValue,
            depotsIDValue,
            driverTypeIDValue,
            costCentreIDValue,
            licenseTypeIDValue,
            genderValue,
            driverDefectManagerValue,
            driverLicenseCheckFrequencyValue,
            driverFuelCardProviderValue
        } = tempOptions;

        let formIsValid = true;
        let errors = {};

        if (!validatePostCode(postCode) && /[^\s]/.test(postCode) && !isNull(postCode)) {
            errors.postCode = `Please fill valid post code, "Ex: BB11AA"`;
            formIsValid = false;
        }
        if (!costCentreIDValue) {
            errors.costCentreID = 'Cost centre is required.';
            formIsValid = false;
        }
        if (!depotsIDValue) {
            errors.depotsID = 'Depots is required.';
            formIsValid = false;
        }
        if (!dateOfBirth) {
            errors.dateOfBirth = 'Driver Date of birth is required.';
            formIsValid = false;
        }
        if (!driverTypeIDValue) {
            errors.driverTypeID = 'Driver type is required.';
            formIsValid = false;
        }
        if (!customerIDValue && !isCustomer) {
            errors.customerID = 'Customer is required.';
            formIsValid = false;
        }
        if (!licenseTypeIDValue) {
            errors.licenseTypeID = 'License type is required';
            formIsValid = false;
        }
        if (!/[^\s]/.test(firstName)) {
            errors.firstName = 'First name is required';
            formIsValid = false;
        }
        setState((st) => ({ ...st, errors: errors }));
        return formIsValid;
    };

    const handleSubmit = async () => {
        if (validations()) {
            const formData = {
                driverID: state.driverID,
                driverCustomerID: isCustomer ? null : tempOptions.customerIDValue?.id,
                driverDepotID: tempOptions.depotsIDValue?.id,
                driverTypeID: tempOptions.driverTypeIDValue?.id,
                driverGenderID: tempOptions.genderValue?.id,
                driverJobTitle: state.jobTitle,
                driverCostCentreID: tempOptions.costCentreIDValue?.id,
                driverFirstName: state.firstName,
                driverLicenceTypeID: tempOptions.licenseTypeIDValue?.id,
                driverMobile: state.driverMobile,
                driverReceiveEmailNotification: state.driverReceiveEmailNotification,
                driverLoginEnabled: state.driverLoginEnabled,
                driverTelephone: state.telePhone,
                driverLastName: state.lastName,
                driverMiddleName: state.middleName,
                driverDateOfBirth: state.dateOfBirth,
                driverAddress1: state.address1,
                driverAddress2: state.address2,
                driverAddress3: state.address3,
                driverTown: state.town,
                driverCounty: state.county,
                driverPostCode: state.postCode,
                driverPayrollNumber: state.driverPayrollNumber,
                driverLicenseCheckFrequency: tempOptions.driverLicenseCheckFrequencyValue?.id,
                driverFuelCarProvider: tempOptions.driverFuelCardProviderValue?.id,
                driverDefectManager: tempOptions.driverDefectManagerValue?.id,
                driverFuelCardNumber: state.driverFuelCardNumber,
                driverFuelCardPIN: state.driverFuelCardPIN,
                driverLicenseNumber: state.driverLicenseNumber
            };
            setBtnLoader(true);
            let res;
            if (props.driverID) {
                res = await updateDriver(props.driverID, formData);
            } else {
                res = await addDriver(formData);
            }
            if (res.success) {
                showToastSuccess('Driver added successfully');
                props.onFormSubmit(true);
            } else {
                showToastError(res.message);
            }
            setBtnLoader(false);
        }
    };

    const handleClose = () => {
        props.onFormSubmit(false);
    };

    return (
        <DriverScreenContext.Provider
            value={{
                state,
                handleSubmit,
                inputChange,
                handleClose,
                handleCheckbox,
                setVal,
                singleSelectChange,
                optionsState,
                tempOptions,
                btnLoader
            }}
        >
            {props.children}
        </DriverScreenContext.Provider>
    );
};
