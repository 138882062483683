import React, { useEffect, useState, useMemo, createContext, useCallback } from 'react';
import { getAllNotes, addUpdateNote, getNoteById, getNotesStickButtons } from '../../../../Core/Services/InternalUser/ControllerService/noteService';
import { useToast } from '../../../../Core/Hooks/useToast';
import moment from 'moment';
import { AppStorage } from '../../../../Core/Basic/storage-service';

export const NotesScreenContext = createContext();

export const NotesProvider = (props) => {
    const { showToastError, showToastSuccess } = useToast();
    let checkhaveAccess = AppStorage.getAllowAcces();
    const [state, setState] = useState({
        show: false,
        showDelete: false,
        showLoader: true,
        NoteList: [],
        noteID: null,
        noteTitle: props.notesTitle === 'Defects' ? 'Defect notes' : props.notesTitle === 'BreakDown' ? 'Breakdown notes' : '',
        noteDescription: '',
        noteActive: true,
        errors: {},
        noteVehicleID: null,
        noteRefsID: null,
        noteStickyDisplayType: '',
        NotesButtons: [],
        noteStickyDisplayFromDate: '',
        noteStickyDisplayEndDate: '',
        noteStickyDisplayType: 'A',
        noteSendSMSNumber: props.notesTitle === 'Defects' || 'BreakDown' ? props.DisplayData : '',
        noteSendSMS: false,
        noteSendSMSReported: false,
        noteSendSMSReportedNo: props.notesTitle === 'Defects' || 'BreakDown' ? props.reportedPhone : '',
        driverName: props.driverName,
        reportedBy: props.reportedBy
    });
    const pullNoteAndUpdateState = async () => {
        let respons;
        let userBasePermi = true;
        if (props.frompage === 'Vehicles') {
            respons = `note/notelist?notefrompage=${props.frompage}&VehicleID=${props.NoteVehicleID}`;
            userBasePermi = checkhaveAccess?.CanUpdateVehicleNotes;
        }
        if (props.frompage === 'Defects') {
            respons = `VehicleDefectNotes?DefectID=${props.defectID}`;
        }
        if (props.frompage === 'Drivers') {
            respons = `note/notelist?notefrompage=${props.frompage}&DriverID=${props.NoteDriverID}`;
            userBasePermi = checkhaveAccess?.CanUpdateDriverNotes;
        }
        if (props.frompage === 'ServiceAgents') {
            respons = `note/notelist?notefrompage=${props.frompage}&ServiceAgentID=${props.NoteServiceAgID}`;
            userBasePermi = checkhaveAccess?.CanUpdateServiceAgentNotes;
        }
        if (props.frompage === 'Customers') {
            respons = `note/notelist?notefrompage=${props.frompage}&customerID=${props.customerID}`;
            userBasePermi = checkhaveAccess?.CanUpdateCustomerNotes;
        }
        if (props.frompage === 'Jobs') {
            respons = `note/notelist?notefrompage=${props.frompage}&JobID=${props.JobID}`;
        }

        setState((w) => ({ ...w, response: respons, userBasePermi: userBasePermi }));

        let res2 = await getNotesStickButtons();
        if (res2.success) {
            setState((st) => ({ ...st, NotesButtons: res2.data.stickyDisPlayType }));
        }
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    const [btnLoader, setBtnLoader] = useState(false);

    useEffect(async () => {
        if (props.frompage) {
            setState((st) => ({ ...st, noteVehicleID: props.NoteVehicleID, noteRefsID: props.noteRefsID, noteRefsGuidID: props.noteRefsGuidID }));
        }
        if (props.NoteID) {
            let res = await getNoteById(props.NoteID, props.frompage, props.NoteVehicleID, props.noteRefsID);
            const Notedata = res.data;
            setState((st) => ({
                ...st,
                noteID: Notedata.noteID,
                noteActive: Notedata.noteActive,
                noteDescription: Notedata.noteDescription,
                noteRefsID: Notedata.noteRefsID || props.noteRefsID,
                noteRefsGuidID: Notedata.noteRefsGuidID || props.noteRefsGuidID,
                noteTitle: Notedata.noteTitle,
                noteStickyDisplayType: Notedata.noteStickyDisplayType,
                noteStickyDisplayFromDate: Notedata.noteStickyDisplayFromDate ? moment(Notedata.noteStickyDisplayFromDate).format('YYYY-MM-DDTHH:mm') : '-',
                noteStickyDisplayEndDate: Notedata.noteStickyDisplayEndDate ? moment(Notedata.noteStickyDisplayEndDate).format('YYYY-MM-DDTHH:mm') : '-'
            }));
        }
        pullNoteAndUpdateState();
    }, [props.NoteVehicleID]);

    const handleAdd = (record) => {
        setState((st) => ({
            ...st,
            show: true,
            noteID: record
        }));
    };

    const handleDelete = (record) => {
        setState((st) => ({
            ...st,
            showDelete: true,
            noteID: record
        }));
    };

    const closeNoteModal = (res) => {
        setState((st) => ({
            ...st,
            show: false,
            showDelete: false
        }));
        res && pullNoteAndUpdateState();
    };

    const validations = () => {
        const { noteTitle, noteDescription, noteSendSMSReportedNo, noteSendSMS, noteSendSMSNumber, noteSendSMSReported } = state;
        let formIsValid = true;
        let errors = {};
        if (!/[^\s]/.test(noteDescription)) {
            errors.noteDescription = 'The description field is required';
            formIsValid = false;
        }
        if (!/[^\s]/.test(noteTitle)) {
            errors.noteTitle = 'The title field is required';
            formIsValid = false;
        }
        if (noteSendSMS) {
            if (!noteSendSMSNumber) {
                errors.noteSendSMSNumber = 'Phone Number is required';
                formIsValid = false;
            }
        }
        if (noteSendSMSReported) {
            if (!noteSendSMSReportedNo) {
                errors.noteSendSMSReportedNo = 'Phone Number is required';
                formIsValid = false;
            }
        }
        setState((st) => ({ ...st, errors: errors }));
        return formIsValid;
    };

    const handleSubmit = async (isCreateNote) => {
        if (validations()) {
            let formData;
            formData = {
                noteTitle: state.noteTitle,
                noteDescription: state.noteDescription,
                noteActive: state.noteActive,
                noteVehicleID: state.noteVehicleID,
                noteRefsGuidID: state.noteRefsGuidID,
                noteRefsID: state.noteRefsID,
                noteID: state.noteID,
                notefrompage: props.frompage,
                noteStickyDisplayType: state.noteStickyDisplayType,
                noteStickyDisplayFromDate: state.noteStickyDisplayType === 'D' ? state.noteStickyDisplayFromDate : '',
                noteStickyDisplayEndDate: state.noteStickyDisplayType === 'D' ? state.noteStickyDisplayEndDate : ''
            };
            let number = '';
            if (state.noteSendSMS && state.noteSendSMSReported) {
                number = state.noteSendSMSReportedNo + ',' + state.noteSendSMSNumber;
            } else if (state.noteSendSMSReported) {
                number = state.noteSendSMSReportedNo;
            } else if (state.noteSendSMS) {
                number = state.noteSendSMSNumber;
            }
            if (props.frompage === 'Defects') {
                formData['noteSendSMSNumber'] = number;
                formData['noteSendSMS'] = state.noteSendSMS || state.noteSendSMSReported;
            }
            setBtnLoader(true);
            let res = await addUpdateNote(formData);
            if (res.success) {
                showToastSuccess('Notes Added Successfully!');
                props.onFormSubmit(true);
            } else {
                showToastError(res.message);
            }
            setBtnLoader(false);
        }
    };

    const handleDeleteSubmit = async (data) => {
        const formData = {
            noteTitle: state.noteTitle,
            noteDescription: state.noteDescription,
            noteActive: false,
            noteVehicleID: state.noteVehicleID,
            noteRefsID: state.noteRefsID,
            noteID: state.noteID,
            notefrompage: props.frompage
        };
        let res = await addUpdateNote(formData);
        if (res.success) {
            showToastSuccess('Notes Deleted Successfully!');
            props.onFormCancel(true);
        } else {
            showToastError(res.message);
        }
    };

    const inputChange = useCallback((e) => {
        const { name, value, type } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    }, []);

    const handleCheckbox = (e) => {
        const { name, checked } = e.target;
        setState((state) => {
            const nst = { ...state, [name]: checked };
            return nst;
        });
    };

    const resetInput = (name) => {
        setState((st) => ({ ...st, [name]: '' }));
    };

    const title = `${state.noteID ? 'Update' : 'Add'} ${props.frompage === 'ServiceAgents' ? 'Service Agent' : props.frompage} Notes`;
    const deleteTitle = `Do You Want Delete ${props.frompage === 'ServiceAgents' ? 'Service Agent' : props.frompage} Note?`;
    return (
        <NotesScreenContext.Provider
            value={{
                state,
                handleAdd,
                handleDelete,
                closeNoteModal,
                title,
                deleteTitle,
                handleSubmit,
                handleDeleteSubmit,
                inputChange,
                handleCheckbox,
                resetInput,
                btnLoader
            }}
        >
            {props.children}
        </NotesScreenContext.Provider>
    );
};
