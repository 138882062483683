import React, { useEffect, useState, useMemo } from 'react';
import { getAllCustomer } from '../../../../Core/Services/InternalUser/ControllerService/customerService';
import { useToast } from '../../../../Core/Hooks/useToast';
import { EditButton, DataGrid, BreadCrumbs, formatters } from '../../../../Core/Controls';
import AddEditCustomer from './addCustomer';
import '../../../commonStyle.scss';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import MsgAllCustomersModal from './MsgAllCustomersModal';
import { Modal } from '@material-ui/core';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import ChatBubble from '@material-ui/icons/ChatBubble';
import GijgoGrid from '../../../../Core/Controls/GijgoGrid';
import Grid from '@material-ui/core/Grid';
import ReactDOM from 'react-dom';
import CustomerDetails from '../DetailsModal/customerDetails.jsx';
import { AppStorage } from '../../../../Core/Basic/storage-service';

const baseColumns = [
    {
        key: 'action',
        name: 'Action',
        width: '7%',
        addFileUploaderIcon: true
    },
    { key: 'customerCompanyName', name: 'Name', width: '15%' },
    {
        key: 'vehicles',
        name: 'Vehicles',
        // headerRenderer: formatters.ColumnHeaderAlignRight,
        formatter: formatters.ColumnTextAlignRight,
        width: '5%'
    },
    {
        key: 'breakdowns',
        name: 'Breakdowns',
        // headerRenderer: formatters.ColumnHeaderAlignRight,
        formatter: formatters.ColumnTextAlignRight,
        width: '7%'
    },
    {
        key: 'defects',
        name: 'Defects',
        // headerRenderer: formatters.ColumnHeaderAlignRight,
        formatter: formatters.ColumnTextAlignRight,
        width: '4%'
    },
    // { key: 'customerCity', name: 'City' },
    // { key: 'customerPostCode', name: 'Post Code' },
    {
        key: 'customerEmail',
        name: 'Email',
        width: '20%'
    },
    { key: 'customerTelephone', name: 'Phone', width: '10%' },
    {
        key: 'customerAddress',
        name: 'Address',
        formatter: (data) => formatters.addressFormatter(data.row?.customerAddress1, data.row?.customerCity, data.row?.customerPostCode)
    }
];

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Customers', path: '/', active: true }
];

const CustomerScreen = (props) => {
    const { showToastError } = useToast();
    let history = useHistory();
    let checkhaveAccess = AppStorage.getAllowAcces();

    const [state, setState] = useState({
        show: false,
        showMsgModal: false,
        showLoader: true,
        customerList: [],
        customerID: null
    });

    const pullCustomerAndUpdateState = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    // useEffect(async () => {
    //     pullCustomerAndUpdateState();
    // }, []);

    const handleAdd = (record) => {
        setState((st) => ({
            ...st,
            show: true
            // customerID: record.id
        }));
    };

    const onMsgClick = () => {
        setState((st) => ({
            ...st,
            showMsgModal: true
        }));
    };

    const closeModals = (res) => {
        setState((st) => ({
            ...st,
            show: false,
            showMsgModal: false
        }));
        res && pullCustomerAndUpdateState();
    };
    const handleEditClick = (val) => {
        props.history.push({
            pathname: `/controller/editcustomer/${val.customerId}/${val.customerCompanyName}`
        });
    };

    const dataFormatter = (val, row) => {
        return (
            <Grid item>
                <EditButton toolTipTitle="Edit Customer" onClick={() => handleEditClick(row)} />
            </Grid>
        );
    };

    const renderEmail = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = <div style={{ cursor: 'pointer', color: '#f50057' }}>{formatters.mailFormatter(row.customerEmail)}</div>;
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const handelRowCick = (row, name) => {
        setState((st) => ({
            ...st,
            selectedRowData: row,
            [name]: true
        }));
    };

    const renderCustomerName = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handelRowCick(row, 'showCustomerModal')} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.customerCompanyName}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const handleClose = () => {
        setState((st) => ({
            ...st,
            open: false,
            showCustomerModal: false
        }));
    };

    const renderPhone = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = <div style={{ cursor: 'pointer', color: '#f50057' }}>{formatters.phoneNumberFormatter(row.customerTelephone)}</div>;
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.key === 'action');
        let emailCol = tempCols.find((element) => element.key === 'customerEmail');
        let phoneCol = tempCols.find((element) => element.key === 'customerTelephone');
        let customerNameCol = tempCols.find((element) => element.key === 'customerCompanyName');
        if (phoneCol) {
            phoneCol.formatter = renderPhone;
        }
        if (emailCol) {
            emailCol.formatter = renderEmail;
        }
        if (actionCol) {
            actionCol.formatter = dataFormatter;
        }
        if (customerNameCol) {
            customerNameCol.formatter = renderCustomerName;
        }
        return tempCols;
    }, []);

    return (
        <div className="screen">
            {state.showMsgModal ? (
                <DialogComp title={'Send Message To All Customers'} onClose={() => closeModals(true)} maxWidth="lg">
                    <MsgAllCustomersModal onFormSubmit={() => closeModals(true)} />
                </DialogComp>
            ) : null}

            {state.show ? (
                <AddEditCustomer customerID={state.customerID} onFormSubmit={() => closeModals(true)} />
            ) : (
                <>
                    <BreadCrumbs crumbs={crumbs} />

                    <GijgoGrid
                        btnTitle="Add Customer"
                        onBtnClick={handleAdd}
                        onSecondBtnClick={onMsgClick}
                        secondBtnIcon={ChatBubble}
                        secondBtnTitle={`${checkhaveAccess?.CanSendMessageToCustomers ? 'Send Message To All Active Customers' : ''}`}
                        secondBtnLabel="Send Message"
                        getUrl={`customers`}
                        columns={columns}
                        idFieldName="customerId"
                        fileUploaderConfigName="CustomerScreen"
                        dataConverter={(res) => ({
                            records: res.data.list,
                            total: res.data.total
                        })}
                        isReload={state.isReload}
                    />
                </>
            )}
            {state.showCustomerModal ? (
                <DialogComp open={true} onClose={handleClose} title={'Customer Details'}>
                    <CustomerDetails customerID={state.selectedRowData.customerId} onFormSubmit={handleClose} />
                </DialogComp>
            ) : null}
        </div>
    );
};
export default CustomerScreen;
