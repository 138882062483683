import { createContext, useEffect, useState, useCallback } from 'react';
import { useToast } from '../../../../../Core/Hooks/useToast';
import { addDepot, updateDepot, getDepotById, getDropdown } from '../../../../../Core/Services/InternalUser/ControllerService/depotsService';
import Grid from '@material-ui/core/Grid';
import { FormHelperText, InputLabel, Typography } from '@material-ui/core';
import { SelectBox, SecondaryButton, SecondaryCheckbox, BreadCrumbs, MandatoryField, SimpleField, YesButton, NoButton } from '../../../../../Core/Controls';
import { emailValidation, validatePostCode, validatePostCodeWothCountryCode } from '../../../../../Core/Basic/StaticService';
import { isNull } from 'lodash';
import Paper from '@material-ui/core/Paper';

export const DepotScreenContext = createContext();

let invoiceInfo = {
    depotUseAddressAsInvoiceAddress: false,
    depotInvoiceAddress1: '',
    depotInvoiceAddress2: '',
    depotInvoiceAddressCity: '',
    depotInvoiceAddressCounty: '',
    depotInvoiceAddressPostCode: '',
    depotInvoiceAddressCountryCode: 'GB',
    depotInvoiceEmailAddress: '',
    depotEmailInvoices: false
};

export const DepotProvider = (props) => {
    const { showToastSuccess, showToastError } = useToast();
    const [btnLoader, setBtnLoader] = useState(false);
    const [depotFields, setDepotFields] = useState({
        name: '',
        address1: '',
        address2: '',
        depotTown: '',
        depotCounty: '',
        depotCountryCode: '',
        depotEmail: '',
        depotTelephone: '',
        depotPostCode: '',
        depotNotInUse: true,
        depotCustomersRef: '',
        serviceAgentID: '',
        firstDayofWeek: '',
        scheduleBookingModeList: [],
        depotVORMobileNumbers: '',
        depotContact: '',
        depotServiceAgentID: '',
        depotAgentSecondaryAgentID: '',
        serviceAgentsList: [],
        errors: {},
        customerList: [],
        depotID: null,
        depotCustomerID: '',
        mergeSchedules: true,
        displayISOWeek: true,
        addPaperworkReceived: true,
        trafficStyleColourCoding: true,
        mergeRange: '',
        sunday: false,
        monday: true,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        depotAdditionalPlanningEmails: '',
        cPCHolderEmail: '',
        cPCHolderName: '',
        secondaryEmail: '',
        depotSecondaryContact: '',
        depotPCNEmail: '',
        depotAccountName: '',
        depotAccountEmail: '',
        depotThirdPartyPlannerEmailAddress: '',
        depotBreakdownNotificationEmail: '',
        depotTimedVehicleStatusUpdateEmail: '',
        depotSchedulePlannerEmail: '',
        depotDefectNotificationEmail: '',
        depotNotes: '',
        depotScheduleBookingMode: '',
        depotIsSendJobApprovalRequired: false,
        depotIsOrderConfirmation: false,
        depotIsBookingEmail: false,
        depotIsJobUpdateEmail: false,
        depotIsSendQueryResponseReceived: false,
        depotIsSendBreakdownEmail: false,
        depotIsSendDefectEmail: false
    });

    const [invoiceFields, setInvoiceFields] = useState({
        ...invoiceInfo,
        errors: {}
    });

    const [optionsState, setOptionState] = useState({
        scheduleBookingModeList: [],
        serviceAgentsList: [],
        customerList: []
    });

    const [tempOptions, setTempOptions] = useState({
        depotServiceAgentValue: null,
        depotAgentSecondaryAgentValue: null,
        depotCustomerValue: null,
        depotScheduleBookingModeValue: null
    });

    const setVal = (s) => {
        setDepotFields((st) => ({
            ...st,
            address1: s.addressLine1,
            address2: s.addressLine2,
            depotTown: s.town,
            depotCounty: s.county,
            depotPostCode: s.postCode
        }));
    };

    useEffect(async () => {
        let res = await getDropdown();
        const scheduleBookingModeList = res.data.scheduleBookingModes.map((item) => ({
            id: item.scheduleBookingModeCode,
            name: item.scheduleBookingModeDescription
        }));
        const List = res.data.customers.map((item) => ({
            id: item.customerID,
            name: item.customerCompanyName
        }));
        setOptionState((st) => ({
            ...st,
            scheduleBookingModeList: scheduleBookingModeList,
            serviceAgentsList: res.data.serviceAgents,
            customerList: List
        }));
        if (props.depotID) {
            let res = await getDepotById(props.depotID);
            const data = res.data.details[0];
            setDepotFields((st) => ({
                ...st,
                name: data.depotName,
                address1: data.depotAddress1,
                address2: data.depotAddress2,
                depotCounty: data.depotCounty,
                depotTown: data.depotTown,
                depotCountryCode: data.depotCountryCode,
                depotEmail: data.depotEmail,
                depotCustomersRef: data.depotCustomersRef,
                scheduleBookingModeID: data.scheduleBookingModeID,
                depotPostCode: data.depotPostCode,
                depotTelephone: data.depotTelephone,
                depotContact: data.depotContact,
                depotNotInUse: data.depotNotInUse,
                firstDayofWeek: data.depotFirstDayofWeek,
                depotVORMobileNumbers: data.depotVORMobileNumbers,
                depotServiceAgentID: data.depotPrimaryAgentID,
                depotAgentSecondaryAgentID: data.depotAgentSecondaryAgentID,
                depotID: data.depotID,
                depotCustomerID: data.depotCustomerID,
                scheduleBookingModeID: data.scheduleBookingModeID,
                MergeSchedules: data.depotPlannerMergeSchedules,
                displayISOWeek: data.depotPlannerDisplayISOWeek,
                addPaperworkReceived: data.depotPlannerAddPaperworkReceived,
                trafficStyleColourCoding: data.depotPlannerTrafficStyleColourCoding,
                mergeRange: data.depotPlannerMergeRange,
                sunday: data.depotBookingPreferenceSunday,
                monday: data.depotBookingPreferenceMonday,
                tuesday: data.depotBookingPreferenceTuesday,
                wednesday: data.depotBookingPreferenceWednesday,
                thursday: data.depotBookingPreferenceThursday,
                friday: data.depotBookingPreferenceFriday,
                saturday: data.depotBookingPreferenceSaturday,
                depotAdditionalPlanningEmails: data.depotAdditionalPlanningEmails,
                cPCHolderEmail: data.depotCPCHolderEmail,
                cPCHolderName: data.depotCPCHolderName,
                secondaryEmail: data.depotSecondaryEmail,
                depotSecondaryContact: data.depotSecondaryContact,
                depotPCNEmail: data.depotPCNEmail,
                depotAccountName: data.depotAccountName,
                depotAccountEmail: data.depotAccountEmail,
                depotContactMobile: data.depotContactMobile,
                depotThirdPartyPlannerEmailAddress: data.depotThirdPartyPlannerEmailAddress,
                depotScheduleBookingMode: data.depotScheduleBookingMode,
                depotBreakdownNotificationEmail: data.depotBreakdownNotificationEmail,
                depotTimedVehicleStatusUpdateEmail: data.depotTimedVehicleStatusUpdateEmail,
                depotSchedulePlannerEmail: data.depotSchedulePlannerEmail,
                depotDefectNotificationEmail: data.depotDefectNotificationEmail,
                depotNotes: data.depotNotes,
                depotVehicleStatusUpdateEmail: data.depotVehicleStatusUpdateEmail,
                depotIsSendJobApprovalRequired: data.depotIsSendJobApprovalRequired,
                depotSendJobApprovalRequiredEmail: data.depotSendJobApprovalRequiredEmail,
                depotIsOrderConfirmation: data.depotIsOrderConfirmation,
                depotOrderConfirmationEmail: data.depotOrderConfirmationEmail,
                depotIsBookingEmail: data.depotIsBookingEmail,
                depotBookingEmail: data.depotBookingEmail,
                depotIsJobUpdateEmail: data.depotIsJobUpdateEmail,
                depotJobUpdateEmail: data.depotJobUpdateEmail,
                depotIsSendQueryResponseReceived: data.depotIsSendQueryResponseReceived,
                depotSendQueryResponseReceivedEmail: data.depotSendQueryResponseReceivedEmail,
                depotIsSendBreakdownEmail: data.depotIsSendBreakdownEmail,
                depotBreakdownNotificationEmail: data.depotBreakdownNotificationEmail,
                depotIsSendDefectEmail: data.depotIsSendDefectEmail,
                depotDefectNotificationEmail: data.depotDefectNotificationEmail,
                customer: data.customer,
                fileCount: data.fileCount
            }));
            setInvoiceFields((dt) => ({
                ...dt,
                depotUseAddressAsInvoiceAddress: data.depotUseAddressAsInvoiceAddress,
                depotInvoiceAddress1: data.depotInvoiceAddress1,
                depotInvoiceAddress2: data.depotInvoiceAddress2,
                depotInvoiceAddressCity: data.depotInvoiceAddressCity,
                depotInvoiceAddressCounty: data.depotInvoiceAddressCounty,
                depotInvoiceAddressPostCode: data.depotInvoiceAddressPostCode,
                depotInvoiceAddressCountryCode: data.depotInvoiceAddressCountryCode,
                depotInvoiceEmailAddress: data.depotInvoiceEmailAddress,
                depotEmailInvoices: data.depotEmailInvoices
            }));
        }
    }, []);

    useEffect(() => {
        let depotServiceAgentValue = null;
        let depotAgentSecondaryAgentValue = null;
        let depotCustomerValue = null;
        let depotScheduleBookingModeValue = null;

        if (depotFields.depotServiceAgentID) {
            depotServiceAgentValue = optionsState.serviceAgentsList.find(({ id }) => id === depotFields.depotServiceAgentID);
        }
        if (depotFields.depotAgentSecondaryAgentID) {
            depotAgentSecondaryAgentValue = optionsState.serviceAgentsList.find(({ id }) => id === depotFields.depotAgentSecondaryAgentID);
        }
        if (depotFields.depotCustomerID) {
            depotCustomerValue = optionsState.customerList.find(({ id }) => id === depotFields.depotCustomerID);
        }
        if (depotFields.depotScheduleBookingMode) {
            depotScheduleBookingModeValue = optionsState.scheduleBookingModeList.find(({ id }) => id === depotFields.depotScheduleBookingMode);
        }

        setTempOptions((st) => ({
            ...st,
            depotCustomerValue: depotCustomerValue,
            depotServiceAgentValue: depotServiceAgentValue,
            depotAgentSecondaryAgentValue: depotAgentSecondaryAgentValue,
            depotScheduleBookingModeValue: depotScheduleBookingModeValue
        }));
    }, [depotFields.depotServiceAgentID, depotFields.depotAgentSecondaryAgentID, depotFields.depotCustomerID, depotFields.scheduleBookingModeID]);

    const singleSelectChange = (nm) => (ev, val) => {
        setTempOptions((st) => ({ ...st, [nm]: val }));
    };

    const handledepotFields = (e) => {
        const { name, value } = e.target;
        setDepotFields((st) => ({ ...st, [name]: value }));
    };

    const handleActive = (val) => {
        setDepotFields((field) => ({
            ...field,
            depotNotInUse: val
        }));
    };

    const handleCheckbox = useCallback((e) => {
        const { name, checked } = e.target;
        setDepotFields((st) => {
            const nst = { ...st, [name]: checked };
            return nst;
        });
    }, []);
    const resetInput = (name) => {
        setDepotFields((st) => ({ ...st, [name]: '' }));
    };

    const setInvoiceVal = (s) => {
        setInvoiceFields((st) => ({
            ...st,
            depotInvoiceAddress1: s.addressLine1,
            depotInvoiceAddress2: s.addressLine2,
            depotInvoiceAddressCity: s.town,
            depotInvoiceAddressCounty: s.county,
            depotInvoiceAddressPostCode: s.postCode
        }));
    };
    const invoiceFieldsChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (value === 'true' && type.toLowerCase() === 'radio') {
            setInvoiceFields((st) => ({ ...st, ...invoiceInfo, [name]: true }));
        } else if (value === 'false' && type?.toLowerCase() === 'radio') {
            setInvoiceFields((st) => ({ ...st, [name]: false }));
        } else {
            setInvoiceFields((st) => ({ ...st, [name]: value }));
        }
    };
    const invoiceCheckBox = (e) => {
        const { name, checked } = e.target;
        setInvoiceFields((st) => ({ ...st, [name]: checked }));
    };

    const resetInvoiceInput = (name) => {
        setInvoiceFields((st) => ({ ...st, [name]: '' }));
    };

    const validations = () => {
        const {
            name,
            address1,
            address2,
            depotCounty,
            depotTown,
            depotPostCode,
            depotCountryCode,
            depotServiceAgentID,
            depotAgentSecondaryAgentID,
            depotEmail,
            depotTelephone,
            depotContact,
            depotVehicleStatusUpdateEmail,
            depotIsSendJobApprovalRequired,
            depotSendJobApprovalRequiredEmail,
            depotIsOrderConfirmation,
            depotOrderConfirmationEmail,
            depotIsBookingEmail,
            depotBookingEmail,
            depotIsJobUpdateEmail,
            depotJobUpdateEmail,
            depotIsSendQueryResponseReceived,
            depotSendQueryResponseReceivedEmail,
            depotIsSendBreakdownEmail,
            depotBreakdownNotificationEmail,
            depotIsSendDefectEmail,
            depotDefectNotificationEmail
        } = depotFields;
        const { depotCustomerValue, depotServiceAgentValue, depotAgentSecondaryAgentValue, depotScheduleBookingModeValue } = tempOptions;
        const { depotInvoiceEmailAddress, depotEmailInvoices } = invoiceFields;

        let formIsValid = true;
        let errors = {};
        if (!/[^\s]/.test(name)) {
            errors.name = 'Depot Name is required.';
            formIsValid = false;
        }
        if (!/[^\s]/.test(address1)) {
            errors.address1 = 'Depot Address1 is required.';
            formIsValid = false;
        }
        if (!depotServiceAgentValue) {
            errors.depotServiceAgentID = 'Primary Service Agent is required.';
            formIsValid = false;
        }
        if (!depotAgentSecondaryAgentValue) {
            errors.depotAgentSecondaryAgentID = 'Secondary Service Agent is required.';
            formIsValid = false;
        }
        if (!/[^\s]/.test(depotCounty)) {
            errors.depotCounty = 'Depot county is required.';
            formIsValid = false;
        }
        if (!depotCountryCode) {
            errors.depotCountryCode = 'Country code is required.';
            formIsValid = false;
        }
        if (depotFields.depotCountryCode === 'GB') {
            if (depotCountryCode && !validatePostCodeWothCountryCode(depotCountryCode, depotPostCode)) {
                errors.depotPostCode = `Please fill valid post code`;
                formIsValid = false;
            }
        }
        if (!depotCustomerValue) {
            errors.depotCustomerID = 'Customer is required.';
            formIsValid = false;
        }
        if (depotIsSendJobApprovalRequired && !emailValidation(depotSendJobApprovalRequiredEmail) && !isNull(depotSendJobApprovalRequiredEmail)) {
            errors.depotSendJobApprovalRequiredEmail = 'Please Enter a valid Email ID';
            formIsValid = false;
        }
        if (depotIsOrderConfirmation && !emailValidation(depotOrderConfirmationEmail) && !isNull(depotOrderConfirmationEmail)) {
            errors.depotOrderConfirmationEmail = 'Please Enter a valid Email ID';
            formIsValid = false;
        }

        if (depotIsBookingEmail && !emailValidation(depotBookingEmail) && !isNull(depotBookingEmail)) {
            errors.depotBookingEmail = 'Please Enter a valid Email ID';
            formIsValid = false;
        }
        if (depotIsJobUpdateEmail && !emailValidation(depotJobUpdateEmail) && !isNull(depotJobUpdateEmail)) {
            errors.depotJobUpdateEmail = 'Please Enter a valid Email ID';
            formIsValid = false;
        }
        if (depotIsSendQueryResponseReceived && !emailValidation(depotSendQueryResponseReceivedEmail) && !isNull(depotSendQueryResponseReceivedEmail)) {
            errors.depotSendQueryResponseReceivedEmail = 'Please Enter a valid Email ID';
            formIsValid = false;
        }
        if (depotIsSendBreakdownEmail && !emailValidation(depotBreakdownNotificationEmail) && !isNull(depotBreakdownNotificationEmail)) {
            errors.depotBreakdownNotificationEmail = 'Please Enter a valid Email ID';
            formIsValid = false;
        }
        if (depotIsSendDefectEmail && !emailValidation(depotDefectNotificationEmail) && !isNull(depotDefectNotificationEmail)) {
            errors.depotDefectNotificationEmail = 'Please Enter a valid Email ID';
            formIsValid = false;
        }
        if (depotEmailInvoices && (!/[^\s]/.test(depotInvoiceEmailAddress) || isNull(depotInvoiceEmailAddress))) {
            errors.depotInvoiceEmailAddress = 'Email can not be empty for checked flag';
            formIsValid = false;
        }
        setDepotFields((st) => ({ ...st, errors: errors }));
        return formIsValid;
    };

    const handleSubmit = async () => {
        if (validations()) {
            const depotData = {
                depotID: depotFields.depotID,
                depotCustomerID: tempOptions.depotCustomerValue?.id, //depotFields.depotCustomerID,
                depotName: depotFields.name,
                depotAddress1: depotFields.address1,
                depotAddress2: depotFields.address2,
                depotCounty: depotFields.depotCounty,
                depotTown: depotFields.depotTown,
                depotCountryCode: depotFields.depotCountryCode,
                depotEmail: depotFields.depotEmail,
                depotCustomersRef: depotFields.depotCustomersRef,
                depotPostCode: depotFields.depotPostCode,
                depotTelephone: depotFields.depotTelephone,
                depotContact: depotFields.depotContact,
                depotNotInUse: depotFields.depotNotInUse,
                depotPrimaryAgentID: tempOptions.depotServiceAgentValue?.id, //depotFields.depotServiceAgentID,
                depotAgentSecondaryAgentID: tempOptions.depotAgentSecondaryAgentValue?.id, //depotFields.depotAgentSecondaryAgentID,
                depotVORMobileNumbers: depotFields.depotVORMobileNumbers,
                depotPlannerMergeSchedules: depotFields.mergeSchedules,
                depotPlannerDisplayISOWeek: depotFields.displayISOWeek,
                depotPlannerAddPaperworkReceived: depotFields.addPaperworkReceived,
                depotPlannerTrafficStyleColourCoding: depotFields.trafficStyleColourCoding,
                depotPlannerMergeRange: depotFields.mergeRange,
                depotBookingPreferenceSunday: depotFields.sunday,
                depotBookingPreferenceMonday: depotFields.monday,
                depotBookingPreferenceTuesday: depotFields.tuesday,
                depotBookingPreferenceWednesday: depotFields.wednesday,
                depotBookingPreferenceThursday: depotFields.thursday,
                depotBookingPreferenceFriday: depotFields.friday,
                depotBookingPreferenceSaturday: depotFields.saturday,
                depotAdditionalPlanningEmails: depotFields.depotAdditionalPlanningEmails,
                depotCPCHolderEmail: depotFields.cPCHolderEmail,
                depotCPCHolderName: depotFields.cPCHolderName,
                depotSecondaryEmail: depotFields.secondaryEmail,
                depotSecondaryContact: depotFields.depotSecondaryContact,
                depotPCNEmail: depotFields.depotPCNEmail,
                depotAccountName: depotFields.depotAccountName,
                depotContactMobile: depotFields.depotContactMobile,
                depotAccountEmail: depotFields.depotAccountEmail,
                depotThirdPartyPlannerEmailAddress: depotFields.depotThirdPartyPlannerEmailAddress,
                depotScheduleBookingMode: tempOptions.depotScheduleBookingModeValue?.id, //depotFields.depotScheduleBookingMode,
                depotBreakdownNotificationEmail: depotFields.depotBreakdownNotificationEmail,
                depotTimedVehicleStatusUpdateEmail: depotFields.depotTimedVehicleStatusUpdateEmail,
                depotVehicleStatusUpdateEmail: depotFields.depotVehicleStatusUpdateEmail,
                depotSchedulePlannerEmail: depotFields.depotSchedulePlannerEmail,
                depotDefectNotificationEmail: depotFields.depotDefectNotificationEmail,
                depotfirstDayofWeek: depotFields.depotFirstDayofWeek,
                depotNotes: depotFields.depotNotes,
                depotIsSendJobApprovalRequired: depotFields.depotIsSendJobApprovalRequired,
                depotSendJobApprovalRequiredEmail: depotFields.depotSendJobApprovalRequiredEmail,
                depotIsOrderConfirmation: depotFields.depotIsOrderConfirmation,
                depotOrderConfirmationEmail: depotFields.depotOrderConfirmationEmail,
                depotIsBookingEmail: depotFields.depotIsBookingEmail,
                depotBookingEmail: depotFields.depotBookingEmail,
                depotIsJobUpdateEmail: depotFields.depotIsJobUpdateEmail,
                depotJobUpdateEmail: depotFields.depotJobUpdateEmail,
                depotIsSendQueryResponseReceived: depotFields.depotIsSendQueryResponseReceived,
                depotSendQueryResponseReceivedEmail: depotFields.depotSendQueryResponseReceivedEmail,
                depotIsSendBreakdownEmail: depotFields.depotIsSendBreakdownEmail,
                depotBreakdownNotificationEmail: depotFields.depotBreakdownNotificationEmail,
                depotIsSendDefectEmail: depotFields.depotIsSendDefectEmail,
                depotDefectNotificationEmail: depotFields.depotDefectNotificationEmail,
                depotUseAddressAsInvoiceAddress: invoiceFields.depotUseAddressAsInvoiceAddress,
                depotInvoiceAddress1: invoiceFields.depotInvoiceAddress1 || '',
                depotInvoiceAddress2: invoiceFields.depotInvoiceAddress2 || '',
                depotInvoiceAddressCity: invoiceFields.depotInvoiceAddressCity || '',
                depotInvoiceAddressCounty: invoiceFields.depotInvoiceAddressCounty || '',
                depotInvoiceAddressPostCode: invoiceFields.depotInvoiceAddressPostCode || '',
                depotInvoiceAddressCountryCode: invoiceFields.depotInvoiceAddressCountryCode || '',
                depotInvoiceEmailAddress: invoiceFields.depotInvoiceEmailAddress || '',
                depotEmailInvoices: invoiceFields.depotEmailInvoices
            };
            setBtnLoader(true);
            let res;
            if (props.depotID) {
                res = await updateDepot(depotData, props.depotID);
            } else {
                res = await addDepot(depotData);
            }
            if (res.success) {
                showToastSuccess('Depots saved successfully');
                props.onFormClose(true);
            } else {
                showToastError(res.message);
            }
            setBtnLoader(false);
        }
    };
    return (
        <DepotScreenContext.Provider
            value={{
                handleSubmit,
                handleCheckbox,
                handledepotFields,
                depotFields,
                handleActive,
                resetInput,
                setVal,
                optionsState,
                tempOptions,
                singleSelectChange,
                btnLoader,
                invoiceFieldsChange,
                invoiceFields,
                setInvoiceVal,
                resetInvoiceInput,
                invoiceCheckBox
            }}
        >
            {props.children}
        </DepotScreenContext.Provider>
    );
};
