import * as Components from '../../../../Components';
import { TypeOfAuth } from '../menu-auth-type';
import ScheduleIcon from '@material-ui/icons/Schedule';
import HealingIcon from '@material-ui/icons/Healing';

export default [
    {
        icon: <ScheduleIcon />,
        label: 'Dashboard',
        path: '/',
        exact: true,
        component: () => {
            return <Components.TechnicianSchedule status="5" />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Repair Required',
        path: '/inspection_repair_required',
        exact: true,
        component: () => {
            return <Components.VehicleInspection status={1} />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Awaiting Sign Off',
        path: '/inspection_completed',
        exact: true,
        component: () => {
            return <Components.VehicleInspection status={3} />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Repair In Progress',
        path: '/in_progress',
        exact: true,
        component: () => {
            return <Components.VehicleInspection status={2} />;
        },
        authType: TypeOfAuth.Auth
    },
    {
        label: 'Signed Off',
        path: '/signed_off',
        exact: true,
        component: () => {
            return <Components.VehicleInspection status={4} />;
        },
        authType: TypeOfAuth.Auth
    }
];
