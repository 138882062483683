import React, { createContext, useEffect, useState } from 'react';
import { getRecallByID } from '../../../../../Core/Services/InternalUser/Vehicle/vehicleRecallService';
import { useToast } from '../../../../../Core/Hooks/useToast';
import { postRecalls, postRecallLog, getRecallLogByID } from '../../../../../Core/Services/InternalUser/Vehicle/vehicleRecallService';
import { formatters } from '../../../../../Core/Controls';

export const RecallScreenContext = createContext();

export const RecallProvider = (props) => {
    const [state, setState] = useState({
        recallVehicleID: props.VehicleID,
        showNote: false,
        showEdit: false,
        showLoader: true,
        error: {},
        isReload: null
    });
    const { showToastSuccess, showToastError } = useToast();

    const validation = () => {
        let error = {};
        let isValid = true;
        const { recallNumber, recallDate, recallDescription, recallExpiryDate } = state;
        if (!recallNumber) {
            error.recallNumber = 'Recall number is required';
            isValid = false;
        }
        if (!recallDate) {
            error.recallDate = 'Recall date is required';
            isValid = false;
        }
        if (!recallDescription) {
            error.recallDescription = 'Description number is required';
            isValid = false;
        }
        if (!recallExpiryDate) {
            error.recallExpiryDate = 'ExpiryDate number is required';
            isValid = false;
        }
        setState((st) => ({
            ...st,
            error
        }));

        return isValid;
    };

    const handleSubmit = async (fromScreen) => {
        if (validation()) {
            let data = {
                recallID: state.recallID || null,
                recallVehicleID: state.recallVehicleID || null,
                recallNumber: state.recallNumber || null,
                recallDate: state.recallDate || null,
                recallExpiryDate: state.recallExpiryDate || null,
                recallDescription: state.recallDescription || null
            };

            let res = await postRecalls(data);
            if (res.success) {
                showToastSuccess('Vehicle Recall added successfully');
                if (state.recallID) {
                    handleEditForm();
                } else {
                    props.onClose(fromScreen, res?.data?.recallID, true);
                }
            } else {
                showToastError('Vehicle Recall Not added');
            }
        }
    };

    useEffect(() => {
        if (props.reCallId) {
            getRecallDetails();
            pullrecallLog();
        }
    }, [state.isReload]);

    const isRefreshDetails = () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    const getRecallDetails = async () => {
        let res = await getRecallByID(props.reCallId);
        const data = res.data?.list[0];
        setState((st) => ({
            ...st,
            recallID: data.recallID || null,
            recallVehicleID: data.recallVehicleID || null,
            recallNumber: data.recallNumber || null,
            recallDate: formatters.DateFormatter(data.recallDate),
            recallExpiryDate: formatters.DateFormatter(data.recallExpiryDate),
            recallDescription: data.recallDescription || null,
            recallDateCreated: data.recallDateCreated || null,
            serviceAgent: data.serviceAgent,
            recallStatusID: data.recallStatusID,
            recallDateBooked: data.recallDateBooked,
            recallDateOnSite: data.recallDateOnSite,
            recallDateWorkshop: data.recallDateWorkshop,
            recallDateCompleted: data.recallDateCompleted,
            fileCount: data.fileCount
        }));
    };
    const inputChange = (e) => {
        let { name, value } = e.target;
        setState((st) => ({
            ...st,
            [name]: value
        }));
    };

    const handleNote = () => {
        setState((st) => ({ ...st, showNote: !st.showNote, recallLogNotes: '', error: {} }));
    };

    const logvalidation = () => {
        let error = {};
        let isValid = true;
        if (!state.recallLogNotes) {
            error.recallLogNotes = 'Log note is required';
            isValid = false;
        }
        setState((st) => ({
            ...st,
            error: error
        }));

        return isValid;
    };

    const handleSubmitLog = async () => {
        if (logvalidation()) {
            let data = {
                recallID: state.recallID,
                recallLogNotes: state.recallLogNotes
            };
            let res = await postRecallLog(data);
            if (res.success) {
                if (res.success) {
                    showToastSuccess('Vehicle Recall Log added successfully');
                    handleNote();
                    pullrecallLog();
                } else {
                    showToastError('Vehicle Recall Log Not added');
                }
            }
        }
    };

    const pullrecallLog = async () => {
        if (props.reCallId) {
            let res = await getRecallLogByID(props.reCallId);
            setState((st) => ({ ...st, logList: res.data?.list, showLoader: false }));
        }
    };

    const handleEditForm = () => {
        setState((st) => ({ ...st, showEdit: !st.showEdit }));
    };

    return (
        <RecallScreenContext.Provider
            value={{
                state,
                handleNote,
                inputChange,
                handleSubmit,
                handleSubmitLog,
                handleEditForm,
                isRefreshDetails
            }}
        >
            {props.children}
        </RecallScreenContext.Provider>
    );
};
