import React, { useEffect, useState, useMemo, createContext, useCallback } from 'react';
import {
    getAllFuelPurchase,
    getFuelPurchaseById,
    getFuelPurchaseScreenDropdowns,
    addFuelPurchase
} from '../../../../Core/Services/InternalUser/ControllerService/fuelPurchaseService';
import { useToast } from '../../../../Core/Hooks/useToast';
import moment from 'moment';

export const FuelPurchaseScreenContext = createContext();

export const FuelPurchaseProvider = (props) => {
    const { showToastError, showToastSuccess } = useToast();

    const [state, setState] = useState({
        show: false,
        showLoader: true,
        fuelPurchaseActive: false,
        FuelPurchaseList: [],
        purchaseDate: '',
        paymentMethodList: [],
        fuelID: null,
        liters: '',
        mileage: '',
        errors: {},
        cost: '',
        paymentMethod: '',
        notes: ''
    });

    const pullFuelPurchaseAndUpdateState = async () => {
        let res1 = await getFuelPurchaseScreenDropdowns();
        if (res1) {
            setState((st) => ({ ...st, showLoader: false, paymentMethodList: res1.data.fuelPaymentMethods }));
        }
    };

    const handleCheckbox = useCallback((e) => {
        const { name, checked } = e.target;
        setState((state) => {
            const nst = { ...state, [name]: checked };
            return nst;
        });
    });

    useEffect(async () => {
        if (props.fuelID) {
            let res = await getFuelPurchaseById(props.VehicleID, props.fuelID);
            const data = res.data;
            setState((st) => ({
                ...st,
                fuelID: data.fuelPurchaseID,
                fuelPurchaseActive: data.fuelPurchaseActive,
                mileage: data.fuelPurchaseOdometer,
                liters: data.fuelPurchaseLitres,
                cost: data.fuelPurchaseCost,
                paymentMethod: data.fuelPurchasePaymentMethodID,
                notes: data.fuelPurchaseNotes,
                fuelPurchaseActive: data.fuelPurchaseActive,
                fuelPurchaseVehicleID: data.fuelPurchaseVehicleID,
                fuelPurchaseDriverID: data.fuelPurchaseDriverID,
                purchaseDate: data.fuelPurchaseDate
            }));
        }
        pullFuelPurchaseAndUpdateState();
    }, []);

    const validations = () => {
        const { liters, cost, paymentMethod, purchaseDate } = state;
        let formIsValid = true;
        let errors = {};
        if (!/[^\s]/.test(liters)) {
            errors.liters = 'The litres field is required';
            formIsValid = false;
        }
        if (!/[^\s]/.test(cost)) {
            errors.cost = 'The cost field is required';
            formIsValid = false;
        }
        if (!/[^\s]/.test(paymentMethod)) {
            errors.paymentMethod = 'The payment method field is required';
            formIsValid = false;
        }
        if (!/[^\s]/.test(purchaseDate)) {
            errors.purchaseDate = 'The purchase date is required';
            formIsValid = false;
        }
        setState((st) => ({ ...st, errors: errors }));
        return formIsValid;
    };

    const handleSubmit = async () => {
        if (validations()) {
            let formData;
            formData = {
                fuelPurchaseID: state.fuelID,
                fuelPurchaseLitres: state.liters,
                fuelPurchaseOdometer: state.mileage,
                fuelPurchaseCost: state.cost,
                fuelPurchasePaymentMethodID: state.paymentMethod,
                fuelPurchaseNotes: state.notes,
                fuelPurchaseActive: state.fuelPurchaseActive,
                fuelPurchaseVehicleID: props.VehicleID,
                fuelPurchaseDriverID: null,
                fuelPurchaseDate: state.purchaseDate
            };
            let res = await addFuelPurchase(formData);
            if (res.success) {
                showToastSuccess('Fuel Purchase Saved Successfully!');
                props.onFormSubmit(true);
            } else {
                showToastError(res.message);
            }
        }
    };

    const inputChange = useCallback((e) => {
        const { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    }, []);

    const dateFieldsChange = (e) => {
        const { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    return (
        <FuelPurchaseScreenContext.Provider
            value={{
                state,
                handleSubmit,
                inputChange,
                handleCheckbox
            }}
        >
            {props.children}
        </FuelPurchaseScreenContext.Provider>
    );
};
