import { FormHelperText, Grid, InputLabel, Typography } from '@material-ui/core';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { DatePicker, DateTimePicker, MandatoryField, SecondaryButton, SelectBox } from '../../../../../Core/Controls';
import { useToast } from '../../../../../Core/Hooks/useToast';
import {
    getScheduleRequestBookingById,
    getAllDropDowns,
    getScheduleBookingSectionById
} from '../../../../../Core/Services/InternalUser/ControllerService/scheduleVehicleService';
import { setBookingDate } from '../../../../../Core/Services/InternalUser/ControllerService/jobDetailsService';
import { getAllDelayReason } from '../../../../../Core/Services/InternalUser/bookingDelayReason';

const ScheduleRevise = (props) => {
    const [errors, setErrors] = useState({});
    const { showToastSuccess, showToastError } = useToast();
    const [state, setState] = useState({
        // bookingDate: props.rowData.bookingDate || '',
        bookingDate:props.rowData.bookingDate ? moment(props.rowData.bookingDate).format('YYYY-MM-DDTHH:mm') : '',
        scheduleDateDue: '',
        scheduleEventName: '',
        scheduleID: null,
        schedulePrepDate: '',
        serviceAgentID: '',
        bookingReasonID:'',
        dropList: [],
        jobReasonList:[]
    });

    const [requestBookingData, setRequestBookingData] = useState({
        scheduleRequestedBookingDate: 'N/A',
        scheduleRequestedBookingTimeSlot: 'N/A',
        scheduleNightBooking: 'N/A',
        scheduleCollectionAndDeliverID: null,
        scheduleCollectionAndDeliver: 'N/A',
        scheduleDeliverBefore: 'N/A',
        scheduleCollectAfter: 'N/A'
    });

    const formatTime = (value) => {
        let formattedTime = value ? moment(value, 'HH:mm:ss').format('hh:mm A') : 'N/A';
        return formattedTime;
    };

    const pullRequestBookingData = async () => {
        let res = await getScheduleRequestBookingById(props.scheduleId);
        let bookingData = res.data;
        setRequestBookingData((st) => ({
            ...st,
            scheduleCollectAfter: formatTime(bookingData.scheduleCollectAfter),
            scheduleCollectionAndDeliverID: bookingData.scheduleCollectionAndDeliverID || null,
            scheduleDeliverBefore: formatTime(bookingData.scheduleDeliverBefore),
            scheduleNightBooking: bookingData.scheduleNightBooking ? 'Yes' : bookingData.scheduleNightBooking === null ? 'N/A' : 'No',
            scheduleRequestedBookingDate: bookingData.scheduleRequestedBookingDate
                ? moment(bookingData.scheduleRequestedBookingDate).format('DD/MM/YYYY')
                : 'N/A',
            scheduleRequestedBookingTimeSlot: formatTime(bookingData.scheduleRequestedBookingTimeSlot)
        }));
        // setState((st) => ({
        //     ...st,
        //     bookingDate: bookingData.scheduleRequestedBookingDate || ''
        // }));
    };
    const pullBookingData = async () => {
        let listReason = await getAllDelayReason();
        let jobReasonList = listReason?.data?.list.map((m) => ({
            id: m.bookingDateDelayReasonID,
            name: m.bookingDateDelayReasonDescription
        }));

        setState((st) => ({
            ...st,
            jobReasonList: jobReasonList||[]
        }));
        let DataList = await getAllDropDowns();
        setState((st) => ({
            ...st,
            dropList: DataList.data
        }));
        let res = await getScheduleBookingSectionById(props.scheduleId);
        let bookingData = res.data;
        setState((st) => ({
            ...st,
            vehicleID: bookingData.vehicleID || null,
            scheduleDateDue: moment(bookingData.scheduleDateDue).format('DD/MM/YYYY') || '',
            scheduleEventName: bookingData.scheduleEventName,
            scheduleID: bookingData.scheduleID,
            schedulePrepDate: bookingData.schedulePrepDate || '',
            serviceAgentID: bookingData.scheduleServiceAgentID || ''
        }));
    };
    useEffect(() => {
        pullBookingData();
        pullRequestBookingData();
    }, []);

    const inputChange = useCallback((e) => {
        const { name, value, type } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    }, []);

    const valueChanged = (ev) => {
        let temp = ev.target;
        setState((st) => ({
            ...st,
            [temp.name]: temp.value
        }));
        setErrors({});
    };
    const validate = () => {
        let noErrors = true;
        let err = {};
        if (!/[^\s]/.test(state.serviceAgentID)) {
            err.serviceAgentID = true;
            noErrors = false;
        }
        if (!/[^\s]/.test(state.bookingDate)) {
            err.bookingDate = true;
            noErrors = false;
        }
        setErrors(err);
        return noErrors;
    };
    const handleData = async (ev) => {
        if (validate()) {
            let response = await setBookingDate({
                jobID: props?.rowData?.scheduleJobID,
                serviceAgentID: state.serviceAgentID,
                bookingDate: state.bookingDate,
                bookingNotes: state.bookingNotes,
                bookingReasonID: state.bookingReasonID
            });
            if (response.success) {
                showToastSuccess(response?.data?.message || 'Something went wrong');
                props.onClose(true);
            } else {
                showToastError('Something went wrong');
            }
        }
    };
    return (
        <div>
            <Grid container spacing={2}>
                <Grid xs={6} sm={4} item className="typo">
                    <FormHelperText>Event</FormHelperText>
                    <Typography>{state.scheduleEventName}</Typography>
                </Grid>
                <Grid xs={6} sm={4} item className="typo">
                    <FormHelperText>Date Due</FormHelperText>
                    <Typography>{state.scheduleDateDue || 'No Date Assigned'}</Typography>
                </Grid>
                <Grid item container xs={12} style={{ padding: 0 }}>
                    <Grid xs={6} sm={4} item className="typo">
                        <FormHelperText>Requested Booking Date</FormHelperText>
                        <Typography>{requestBookingData.scheduleRequestedBookingDate}</Typography>
                    </Grid>
                    <Grid xs={6} sm={4} item className="typo">
                        <FormHelperText>Requested Booking Time Slot</FormHelperText>
                        <Typography>{requestBookingData.scheduleRequestedBookingTimeSlot}</Typography>
                    </Grid>
                    <Grid xs={6} sm={4} item className="typo">
                        <FormHelperText>Is Night Booking?</FormHelperText>
                        <Typography>{requestBookingData.scheduleNightBooking}</Typography>
                    </Grid>
                    <Grid xs={6} sm={4} item className="typo">
                        <FormHelperText>Collection And Delivery</FormHelperText>
                        <Typography>{requestBookingData.scheduleCollectionAndDeliver}</Typography>
                    </Grid>
                    <Grid xs={6} sm={4} item className="typo">
                        <FormHelperText>Deliver Before</FormHelperText>
                        <Typography>{requestBookingData.scheduleDeliverBefore}</Typography>
                    </Grid>
                    <Grid xs={6} sm={4} item className="typo">
                        <FormHelperText>Collect After</FormHelperText>
                        <Typography>{requestBookingData.scheduleCollectAfter}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <br />
            <Grid container spacing={2}>
                <Grid item sm={12} xs={12} className="mandatory-fields">
                    <InputLabel shrink>Service Agent</InputLabel>
                    <SelectBox
                        name="serviceAgentID"
                        value={state.serviceAgentID || ''}
                        // onChange={valueChanged}
                        List={state.dropList.serviceAgents || []}
                    />
                    <FormHelperText error>{errors.serviceAgentID ? 'This field is required' : null}</FormHelperText>
                </Grid>
                <Grid item xs={12} className="mandatory-fields">
                    <InputLabel shrink>New Booking Date</InputLabel>
                    <DateTimePicker placeholder="Date" value={state.bookingDate} onChange={valueChanged} name="bookingDate" />
                    <FormHelperText error>{errors.bookingDate ? 'This field is required' : null}</FormHelperText>
                </Grid>
                <Grid item xs={12} className="mandatory-fields">
                    <InputLabel required shrink>
                        Select Reason
                    </InputLabel>
                    <SelectBox name="bookingReasonID" value={state.bookingReasonID || ' '} onChange={valueChanged} List={state.jobReasonList} />
                    <FormHelperText error>{errors.bookingReasonID}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={12} className="mandatory-fields">
                    <MandatoryField
                        inputLabel="Comment"
                        multiline
                        rows={3}
                        placeholder="Comment"
                        value={state.bookingNotes}
                        onChange={inputChange}
                        name="bookingNotes"
                        errors={errors.bookingNotes}
                    />
                </Grid>
                <Grid item xs={6} className="submit_btn">
                    <SecondaryButton onClick={handleData}>Change Booking Date</SecondaryButton>
                </Grid>
                <Grid item xs={6} className="submit_btn">
                    <SecondaryButton onClick={() => props.onClose(false)}>Cancel</SecondaryButton>
                </Grid>
            </Grid>
        </div>
    );
};

export default ScheduleRevise;
