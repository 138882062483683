import React, { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormHelperText, InputLabel } from '@material-ui/core';
import {
    InputText,
    SecondaryButton,
    SelectBox,
    DatePicker,
    BreadCrumbs,
    Accordion,
    MandatoryField,
    SimpleField,
    SingleSelect
} from '../../../../Core/Controls';
import { VehicleProvider as StateProvider, VehicleScreenContext } from './VehicleProvider';
import OtherVehicleDetails from './more/otherVehicleField';
import JobFieldsModal from './more/jobFields';
import DateFieldsModal from './more/dateFields';
import FlagFieldsModal from './more/flagDetails';
import '../controllerStyle.scss';
import { useLoginInfo } from '../../../../Core/Hooks/useLoginInfo';
import { AppStorage } from '../../../../Core/Basic/storage-service';

const AddEditVehicle = (props) => {
    const crumbs = [
        { name: 'Home', path: '/' },
        { name: 'Vehicles', active: true, onClick: props.onFormSubmit },
        { name: 'Add Vehicle', active: true }
    ];
    let checkhaveAccess = AppStorage.getAllowAcces();

    const {
        state,
        handleSubmit,
        inputChange,
        otherFields,
        otherFieldsChange,
        jobFields,
        jobFieldsChange,
        handleCheckbox,
        dateFieldsChange,
        dateFields,
        handleTabPress,
        singleSelectChange,
        tempOptions,
        btnLoader
    } = useContext(VehicleScreenContext);
    const isCustomer = useLoginInfo().userRoles.includes('customer');
    const checkToshowBreadCrumbs = props.customerID || !!props.VehicleID;
    return (
        <div className={`${!checkToshowBreadCrumbs && 'screen'}` + ' controller-screen'}>
            {!checkToshowBreadCrumbs ? <BreadCrumbs crumbs={crumbs} className="edit-Customer-btn" /> : null}
            <Grid container spacing={1} overflow="auto">
                <Grid item xs={12} sm={6} md={4} lg={3} className="mandatory-fields">
                    <MandatoryField
                        inputLabel="Reg Number"
                        placeholder="Reg Number"
                        value={state.regNumber?.toUpperCase()}
                        onChange={inputChange}
                        onKeyDown={handleTabPress}
                        name="regNumber"
                        errors={state.errors.regNumber}
                    />
                </Grid>

                {!isCustomer && (
                    <Grid item xs={12} sm={6} md={4} lg={3} className="mandatory-fields">
                        <InputLabel required shrink>
                            Customer
                        </InputLabel>
                        {/* <SelectBox disabled={props.customerID} name="customerID" value={state.customerID} onChange={inputChange} List={state.customerList} /> */}
                        <SingleSelect
                            options={state.customerList}
                            value={tempOptions.customerIDValue}
                            onChange={singleSelectChange('customerIDValue')}
                            disabled={props.customerID}
                        />
                        <FormHelperText error>{state.errors.customerID}</FormHelperText>
                    </Grid>
                )}
                <Grid item xs={12} sm={6} md={4} lg={3} className="mandatory-fields">
                    <InputLabel required shrink>
                        Depot
                    </InputLabel>
                    {/* <SelectBox name="depotsID" value={state.depotsID} onChange={inputChange} List={state.depotWithCustList} /> */}
                    <SingleSelect options={state.depotWithCustList} value={tempOptions.depotsIDValue} onChange={singleSelectChange('depotsIDValue')} />
                    <FormHelperText error>{state.errors.depotsID}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <InputLabel shrink>End User</InputLabel>
                    <SingleSelect
                        options={state.vehicleEndUserIDList}
                        value={tempOptions.vehicleEndUserIDValue}
                        onChange={singleSelectChange('vehicleEndUserIDValue')}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <InputLabel shrink>Make</InputLabel>
                    {/* <SelectBox name="makeID" value={state.makeID} onChange={inputChange} List={state.makeList} /> */}
                    <SingleSelect options={state.makeList} value={tempOptions.makeIDValue} onChange={singleSelectChange('makeIDValue')} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <InputLabel shrink>Model</InputLabel>
                    {/* <SelectBox name="modelID" value={state.modelID} onChange={inputChange} List={state.ModelAsPerMake} /> */}
                    <SingleSelect options={state.ModelAsPerMake} value={tempOptions.modelIDValue} onChange={singleSelectChange('modelIDValue')} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <InputLabel shrink>Fuel Type</InputLabel>
                    {/* <SelectBox name="fuelType" value={state.fuelType} onChange={inputChange} List={state.fuelTypeList} /> */}
                    <SingleSelect options={state.fuelTypeList} value={tempOptions.fuelTypeValue} onChange={singleSelectChange('fuelTypeValue')} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} className="mandatory-fields">
                    <InputLabel required shrink>
                        Primary Service Agent
                    </InputLabel>
                    {/* <SelectBox
                        name="vehiclePrimaryServiceAgentID"
                        value={state.vehiclePrimaryServiceAgentID}
                        onChange={inputChange}
                        List={state.vehiclePrimaryServiceAgentIDList}
                    /> */}
                    <SingleSelect
                        options={state.vehiclePrimaryServiceAgentIDList}
                        value={tempOptions.vehiclePrimaryServiceAgentIDValue}
                        onChange={singleSelectChange('vehiclePrimaryServiceAgentIDValue')}
                    />
                    <FormHelperText error>{state.errors.vehiclePrimaryServiceAgentID}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} className="mandatory-fields">
                    <InputLabel shrink>Secondary Service Agent</InputLabel>
                    {/* <SelectBox
                        name="vehicleSecondaryServiceAgentID"
                        value={state.vehicleSecondaryServiceAgentID}
                        onChange={inputChange}
                        List={state.vehiclePrimaryServiceAgentIDList}
                    /> */}
                    <SingleSelect
                        options={state.vehiclePrimaryServiceAgentIDList}
                        value={tempOptions.vehicleSecondaryServiceAgentIDValue}
                        onChange={singleSelectChange('vehicleSecondaryServiceAgentIDValue')}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} className="mandatory-fields">
                    <InputLabel required shrink>
                        Vehicle Category
                    </InputLabel>
                    {/* <SelectBox name="vehicleCategoryID" value={state.vehicleCategoryID} onChange={inputChange} List={state.vehicleCategoryList} /> */}
                    <SingleSelect
                        options={state.vehicleCategoryList}
                        value={tempOptions.vehicleCategoryIDValue}
                        onChange={singleSelectChange('vehicleCategoryIDValue')}
                    />
                    <FormHelperText error>{state.errors.vehicleCategoryID}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} className="mandatory-fields">
                    <InputLabel required shrink>
                        Vehicle Type
                    </InputLabel>
                    {/* <SelectBox name="vehicleTypeID" value={state.vehicleTypeID} onChange={inputChange} List={state.vehicleAsPerCategoryList} /> */}
                    <SingleSelect
                        options={state.vehicleAsPerCategoryList}
                        value={tempOptions.vehicleTypeIDValue}
                        onChange={singleSelectChange('vehicleTypeIDValue')}
                    />
                    <FormHelperText error>{state.errors.vehicleTypeID}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <SimpleField
                        inputLabel="Contract Number"
                        placeholder="Contract Number"
                        value={jobFields.vehicleMaintenanceRef}
                        onChange={jobFieldsChange}
                        name="vehicleMaintenanceRef"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <SimpleField
                        inputLabel="Chassis Number"
                        placeholder="Chassis Number"
                        value={state.chassisNumber}
                        onChange={inputChange}
                        name="chassisNumber"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <SimpleField
                        inputLabel="Job Admin Fee"
                        placeholder="Job Admin Fee"
                        value={jobFields.jobAdminFee}
                        onChange={jobFieldsChange}
                        name="jobAdminFee"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <SimpleField
                        inputLabel="Engine Capacity"
                        type="number"
                        placeholder="Engine Capacity"
                        value={state.capacity}
                        onChange={inputChange}
                        name="capacity"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <SimpleField
                        inputLabel="CO2 Emission"
                        type="number"
                        placeholder="CO2 Emission"
                        value={state.co2Emission}
                        onChange={inputChange}
                        name="co2Emission"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <InputLabel shrink>Cost Centre</InputLabel>
                    {/* <SelectBox name="costCentreID" value={state.costCentreID} onChange={inputChange} List={state.costCentreList} /> */}
                    <SingleSelect options={state.costCentreList} value={tempOptions.costCentreIDValue} onChange={singleSelectChange('costCentreIDValue')} />
                    <FormHelperText error>{state.errors.costCentreID}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <InputLabel shrink>Ownership Type</InputLabel>
                    {/* <SelectBox name="ownershipTypeID" value={state.ownershipTypeID} onChange={inputChange} List={state.ownershipTypeList} /> */}
                    <SingleSelect
                        options={state.ownershipTypeList}
                        value={tempOptions.ownershipTypeIDValue}
                        onChange={singleSelectChange('ownershipTypeIDValue')}
                    />
                    <FormHelperText error>{state.errors.ownershipTypeID}</FormHelperText>
                </Grid>
                <OtherVehicleDetails otherFields={otherFields} otherFieldsChange={otherFieldsChange} />
                <Grid item xs={12}>
                    <Accordion
                        header="Important Dates"
                        accordianBody={
                            <DateFieldsModal
                                dateFields={dateFields}
                                otherFields={otherFields}
                                dateFieldsChange={dateFieldsChange}
                                otherFieldsChange={otherFieldsChange}
                                state={state}
                            />
                        }
                    />
                </Grid>
                {/* <Grid item xs={12}>
                    <Accordion header="Job Details" accordianBody={<OtherVehicleDetails otherFields={otherFields} otherFieldsChange={otherFieldsChange} />} />
                </Grid> */}
                <Grid item xs={12}>
                    <Accordion header="Vehicle Flags" accordianBody={<FlagFieldsModal jobFields={jobFields} handleCheckbox={handleCheckbox} />} />
                </Grid>
                <Grid item xs={12}>
                    <Accordion
                        header="Additional Details"
                        accordianBody={<JobFieldsModal jobFields={jobFields} jobFieldsChange={jobFieldsChange} handleCheckbox={handleCheckbox} />}
                    />
                </Grid>

                <Grid item xs={6}>
                    <SecondaryButton
                        onClick={handleSubmit}
                        fullWidth
                        className="submit_btn"
                        isBtnLoader={btnLoader}
                        disabled={!checkhaveAccess?.CanUpdateVehicles}
                    >
                        Save
                    </SecondaryButton>
                </Grid>
                <Grid item xs={6}>
                    <SecondaryButton onClick={() => props.onFormSubmit(false)} fullWidth className="submit_btn">
                        Cancel
                    </SecondaryButton>
                </Grid>
            </Grid>
        </div>
    );
};

const Screen = (props) => {
    return (
        <StateProvider {...props}>
            <AddEditVehicle {...props} />
        </StateProvider>
    );
};
export default Screen;
