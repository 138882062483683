import React, { useEffect, useMemo, useState } from 'react';
import DataGrid from '../../../Core/Controls/Grid';
import { getAllDefects, getAllVehicleDefects } from '../../../Core/Services/InternalUser/Maintenance/DefectsService';
import { EditButton, BreadCrumbs, formatters } from '../../../Core/Controls';

const modalgridStyle = {
    formatterEditBtn: { textAlign: 'center' }
};

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Defects', path: '/', active: true }
];

const baseColumns = [
    {
        key: 'defectDate',
        name: 'Date ',
        formatter: formatters.Date_DDMMYYYY,
        width: '8%'
    },
    { key: 'defectNumberGenerated', name: 'Defect No', width: '6%' },
    { key: 'defectDescription', name: 'Defect Details' },
    { key: 'defectContactName', name: 'Reported By', width: '8%' },
    { key: 'defectJobStatusDescription', name: 'Status', width: '13%' }
];

const DefetctStatusGrid = (props) => {
    const [state, setState] = useState({ modalToggle: false, defectID: null, List: [], showLoader: true, fromPage: 'Defects' });

    useEffect(async () => {
        if (props.frompage === 'Vehicles') {
            setState((st) => ({
                ...st,
                fromPage: props.frompage
            }));
        }
        pullDefects();
    }, []);

    const pullDefects = async () => {
        if (props.VehicleID) {
            let res2 = await getAllVehicleDefects(props.VehicleID);
            setState((st) => ({
                ...st,
                List: [...res2.data.list],
                showLoader: false
            }));
        } else {
            let res = await getAllDefects();
            setState((st) => ({
                ...st,
                List: [...res.data.list],
                showLoader: false
            }));
        }
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];

        if (!props.VehicleID) {
            tempCols.splice(1, 0, { key: 'defectRegNumber', name: 'Reg Number', width: '8%' });
        }
        return tempCols;
    }, []);

    return (
        <div className="screen">
            <BreadCrumbs crumbs={crumbs} />
            <DataGrid
                columns={columns}
                className="custom-scroll"
                rows={state.List}
                enableSearch={true}
                loadingData={state.showLoader}
                isRowHovered={true}
                paginate
                offset={210}
            />
        </div>
    );
};

export default DefetctStatusGrid;
