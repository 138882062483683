import React, { useEffect, useState, useMemo } from 'react';
import { useToast } from '../../../../../Core/Hooks/useToast';
import { DataGrid, EditButton, formatters } from '../../../../../Core/Controls';
import { getUpcomingEvents, mergeJobToMaster } from '../../../../../Core/Services/InternalUser/ControllerService/jobDetailsService';
import { CustomIconButton } from '../../../../../Core/Controls/Inputs/Buttons/ButtonModal';
import LockIcon from '@material-ui/icons/Lock';
import { Grid } from '@material-ui/core';
import MergeTypeIcon from '@material-ui/icons/MergeType';

const UpcomingEvents = (props) => {
    const dataFormatter = (data, row) => {
        return (
            <Grid container justify="center">
                <CustomIconButton icon={MergeTypeIcon} toolTipTitle="Add to the current job" forceEnabled={true} onClick={() => jobMerge(data.row)} />
            </Grid>
        );
    };

    const columns = [
        { key: 'dateDue', name: 'Date', formatter: formatters.Date_DDMMMYYYY, width: 150 },
        { key: 'scheduleName', name: 'Event Name' },

        {
            key: 'scheduleID',
            name: 'Action',
            formatter: dataFormatter,
            width: 20
        }
    ];

    if (props.fromPage !== 'jobItem') {
        columns.splice(2, 0, { key: 'bookingStatus', name: 'Status' });
    }

    const { showToastError, showToastSuccess } = useToast();

    const [state, setState] = useState({
        showLoader: true,
        upcomingEventsList: []
    });

    const jobMerge = async (jobData) => {
        let data = {
            scheduleID: jobData.scheduleID,
            jobID: props.jobID
        };
        let res = await mergeJobToMaster(data);
        if (res.success) {
            showToastSuccess('Successfully added to the job');
            pullUpcomingEventsAndUpdateState();
        }
        if (!res.success) {
            showToastError(res.message);
        }
    };

    const pullUpcomingEventsAndUpdateState = async () => {
        let res = await getUpcomingEvents(props.vehicleID);
        if (res.success) {
            setState((st) => ({ ...st, showLoader: false, upcomingEventsList: res.data.vehicleUncompletedSchedulesList }));
        }
        if (!res.success) {
            showToastError(res.message);
        }
    };

    useEffect(async () => {
        pullUpcomingEventsAndUpdateState();
    }, [props.refreshDependancy]);
    return (
        <div>
            <DataGrid rows={state.upcomingEventsList} columns={columns} loadingData={state.showLoader} height={props.height || 300} />
        </div>
    );
};
export default UpcomingEvents;
