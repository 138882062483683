import { FormHelperText, Grid, InputLabel, Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CustomChip, DatePicker, InputText, SimpleField, DataGrid, SecondaryButton, formatters } from '../../../../../Core/Controls';
import FileUploaderButton from '../../../../../Core/Controls/GenericFileUploader';
import { RecallProvider as StateProvider, RecallScreenContext } from './reCallProvider';
import CustomizedRecallSteppers from './RecallStepper/recallStepper';
import AddIcon from '@material-ui/icons/Add';
import DialogComp from '../../../../../Core/Dialog/DialogComp';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import { Widgets } from '@material-ui/icons';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    inputContainer: {
        padding: '5px',
        marginBottom: '5px',
        '& .MuiOutlinedInput-input': {
            padding: '4px 4px'
        }
    },
    textAreaContainer: {
        padding: '5px',
        marginBottom: '5px',
        height: '400px'
    }
}));

const JobSingleNote = props => {
    return <div className="single-note">
        <div className="msg" style={{ fontSize: 14, fontStyle: 'italic' }}>{props.note.notes}</div>
        <div className="footer" style={{ fontSize: 12, color: 'grey', fontStyle: 'italic', textAlign: 'right' }}>
            {props.note.createdBy} @ {moment(props.note.recallLogDateCreated).format("LLL")}
        </div>
    </div>
}

const columns = [
    {
        key: 'recallLogDateCreated',
        name: 'Date',
        formatter: formatters.Date_DDMMYYYY,
        width: 100
    },
    {
        key: 'notes',
        name: 'Log'
    },
    {
        key: 'createdBy',
        name: 'Created By',
        width: 170
    }
];

const RecallDashboard = (props) => {
    const classes = useStyles();

    const { state, inputChange, handleNote, handleSubmitLog, handleEditForm, handleSubmit, isRefreshDetails } = useContext(RecallScreenContext);

    return (
        <div>
            <Grid container spacing={1} style={{ padding: '1px' }}>
                <Grid item xs={12}>
                    <CustomizedRecallSteppers stepData={state} isRefreshDetails={isRefreshDetails} />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Grid container item xs={12} justify="space-between" style={{ paddingTop: '4px' }} className="grid-style grey-grid">
                        <Grid item>
                            <Typography variant="h5" color="secondary">
                                Recall Details
                                <span style={{ position: 'relative', top: '-7px', left: '7px' }}>
                                    <FileUploaderButton
                                        recordId={state.recallID}
                                        fileUploaderConfigName="Recalls"
                                        fileCount={state.fileCount}
                                        gridRelaod={isRefreshDetails}
                                    />
                                </span>
                            </Typography>
                        </Grid>
                        <Grid item>
                            {!state.showEdit ? (
                                <CustomChip
                                    icon={EditIcon}
                                    label="Edit"
                                    onClick={handleEditForm}
                                    style={{ fontWeight: 'bold', height: '29px', marginRight: 10 }}
                                />
                            ) : (
                                <>
                                    <CustomChip
                                        icon={DoneIcon}
                                        label="Update"
                                        onClick={() => handleSubmit('edit')}
                                        style={{ fontWeight: 'bold', height: '29px', marginRight: 10 }}
                                    />
                                    <CustomChip
                                        icon={ClearIcon}
                                        label="cancel"
                                        onClick={handleEditForm}
                                        style={{ fontWeight: 'bold', height: '29px', marginRight: 10 }}
                                    />
                                </>
                            )}
                        </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                        <Grid container item className="grid-style grey-grid">
                            <Grid item xs={4} className={classes.inputContainer}>
                                <InputLabel shrink>Recall Number</InputLabel>
                                {state.showEdit ? (
                                    <SimpleField onChange={inputChange} name="recallNumber" value={state.recallNumber} autoFocus={true} />
                                ) : (
                                    <Typography variant="button">{state.recallNumber}</Typography>
                                )}
                            </Grid>
                            <Grid item xs={4} className={classes.inputContainer}>
                                <InputLabel shrink>Recall Date</InputLabel>
                                {state.showEdit ? (
                                    <DatePicker onChange={inputChange} name="recallDate" value={state.recallDate} />
                                ) : (
                                    <Typography variant="button">{formatters.DateOnlyFormatter(state.recallDate)}</Typography>
                                )}
                            </Grid>
                            <Grid item xs={4} className={classes.inputContainer}>
                                <InputLabel shrink>Expiry Date </InputLabel>
                                {state.showEdit ? (
                                    <DatePicker onChange={inputChange} name="recallExpiryDate" value={state.recallExpiryDate} />
                                ) : (
                                    <Typography variant="button">{formatters.DateOnlyFormatter(state.recallExpiryDate)}</Typography>
                                )}
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            item
                            className="grid-style grey-grid "
                        // style={{ height: 'calc(100vh - 556px)', overflow: state.showEdit ? '' : 'auto' }}
                        >
                            <Grid item xs={12} className={classes.textAreaContainer}>
                                <InputLabel shrink>Description</InputLabel>
                                {state.showEdit ? (
                                    <InputText multiline rows={18} onChange={inputChange} name="recallDescription" value={state.recallDescription} />
                                ) : (
                                    <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>
                                        {state.recallDescription}
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                    <Grid container item xs={12} justify="space-between"
                        style={{ paddingTop: '4px', outlineStyle: 'solid', outlineWidth: '1px', outlineColor: 'rgba(224, 224, 224, 1)' }}>
                        <Grid item>
                            <Typography variant="h5" color="secondary" style={{ paddingLeft: '5px' }}>
                                Recall Logs
                            </Typography>
                        </Grid>
                        <Grid item style={{ paddingTop: 1, paddingBottom: 3, paddingRight: 3 }}>
                            {
                                <CustomChip
                                    icon={AddIcon}
                                    label="Add Log"
                                    onClick={handleNote}
                                    style={{ fontWeight: 'bold', height: '29px', marginRight: 10 }}
                                />
                            }
                        </Grid>
                        <Grid item xs={12} style={{ padding: '5px', minHeight: '460px' }} className="grid-style">
                            {/* <DataGrid
                                rows={state.logList}
                                height={460}
                                // offset={435}
                                columns={columns}
                                loadingData={state.showLoader}
                            // paginate //to add pagination to the datagrid
                            /> */}

                            {state.logList?.map((n, i) =>
                                <>
                                    <JobSingleNote key={i} note={n} />
                                    <hr style={{ opacity: '30%' }} />
                                </>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {state.showNote ? (
                <DialogComp title={`Add Log (${props.vehicleDesc})`} onClose={handleNote} maxWidth="lg">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <InputLabel shrink error>
                                Log
                            </InputLabel>
                            <SimpleField
                                onChange={inputChange}
                                multiline
                                rows={4}
                                name="recallLogNotes"
                                value={state.recallLogNotes}
                                autoFocus={true}
                                placeholder="log notes"
                            />
                            <FormHelperText error>{state.error.recallLogNotes}</FormHelperText>
                        </Grid>
                        <Grid item xs={6}>
                            <SecondaryButton onClick={handleSubmitLog}> Submit</SecondaryButton>
                        </Grid>
                        <Grid item xs={6}>
                            <SecondaryButton onClick={handleNote}> Cancel</SecondaryButton>
                        </Grid>
                    </Grid>
                </DialogComp>
            ) : null}
        </div>
    );
};

const Screen = (props) => {
    return (
        <StateProvider {...props}>
            <RecallDashboard {...props} />
        </StateProvider>
    );
};
export default Screen;
