import { postWithAuth, getWithAuth, putWithAuth } from "../../../Basic/HttpService";

export const getAllDepots = async (custID) => {
    let res = await getWithAuth(`depots?CustomerID=${custID}`);
    return res;
};

export const getDepots = async () => {
    let res = await getWithAuth(`depots`);
    return res;
};

export const getDepotById = async (Id) => {
    let res = await getWithAuth(`depots/${Id}`);
    return res;
};

export const getDropdown = async () => {
    let res = await getWithAuth(`depots/DepotsDropdowns`);
    return res;
};

export const addDepot = async (data) => {
    let res = await postWithAuth("depots", data);
    return res;
};

export const updateDepot = async (data, id) => {
    let res = await putWithAuth(`depots/${id}`, data);
    return res;
};
