import React, { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import { SecondaryButton } from '../../../../Core/Controls';
import '../controllerStyle.scss';
import { VehicleProvider, VehicleScreenContext } from './DepotsProvidor';
import DepotsDetails from './depoDetails';
import ScheduleDetails from './scheduleDetails';
import InvoiceDetails from './invoiceFields';
import { AppStorage } from '../../../../Core/Basic/storage-service';

const AddEditDepots = (props) => {
    let getAccessParam = AppStorage.getAllowAcces();
    const {
        scheduleFields,
        handleSubmit,
        scheduleDetailsChange,
        handleCheckbox,
        closeModal,
        handledepotFields,
        handledepotCheckbox,
        depotFields,
        handleActive,
        invoiceFieldsChange,
        invoiceFields,
        resetInput,
        setVal,
        invoiceCheckBox
    } = useContext(VehicleScreenContext);

    return (
        <div className="controller-screen" style={{ marginTop: 8 }}>
            <Grid container spacing={1}>
                <DepotsDetails
                    handleActive={handleActive}
                    handledepotFields={handledepotFields}
                    handledepotCheckbox={handledepotCheckbox}
                    depotFields={depotFields}
                />
                <InvoiceDetails
                    invoiceCheckBox={invoiceCheckBox}
                    invoiceFieldsChange={invoiceFieldsChange}
                    invoiceFields={invoiceFields}
                    setVal={setVal}
                    depotFields={depotFields}
                    resetInput={resetInput}
                />
                <ScheduleDetails
                    scheduleDetailsChange={scheduleDetailsChange}
                    handleCheckbox={handleCheckbox}
                    scheduleFields={scheduleFields}
                    depotFields={depotFields}
                    handleActive={handleActive}
                />
                <Grid item xs={6}>
                    <SecondaryButton onClick={handleSubmit} className="submit_btn" disabled={!getAccessParam?.CanUpdateDepots}>
                        {scheduleFields.depotID ? 'Save' : 'Add'}
                    </SecondaryButton>
                </Grid>
                <Grid item xs={6}>
                    <SecondaryButton onClick={closeModal} className="submit_btn">
                        Cancel
                    </SecondaryButton>
                </Grid>
            </Grid>
        </div>
    );
};

const Screen = (props) => {
    return (
        <VehicleProvider {...props}>
            <AddEditDepots {...props} />
        </VehicleProvider>
    );
};
export default Screen;
