import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { SecondaryButton, MandatoryField, SimpleField } from '../../../../Core/Controls';
import { getCostCategoriesById, postCostCategoriesData } from '../../../../Core/Services/InternalUser/costCategoriesService';
import { SecondaryCheckbox } from '../../../../Core/Controls';
import { useToast } from '../../../../Core/Hooks/useToast';
import '../../.././commonStyle.scss';

function CostCategoriesModal(props) {
    const [state, setState] = useState({
        costCategoryID: null,
        costCategory: '',
        costCategoryLedgerCode: '',
        active: true,
        errors: {}
    });

    const { showToastSuccess, showToastError } = useToast();

    useEffect(async () => {
        if (props.costCategoryID) {
            let res = await getCostCategoriesById(props.costCategoryID);
            if (res.success) {
                setState((state) => ({
                    ...state,
                    costCategoryID: res.data.list[0].costCategoryID,
                    costCategory: res.data.list[0].costCategory,
                    costCategoryLedgerCode: res.data.list[0].costCategoryLedgerCode,
                    active: res.data.list[0].active,
                    code: res.data.list[0].costCategoryID
                }));
            }
        }
    }, []);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setState((state) => ({
            ...state,
            [name]: value
        }));
    };
    const handleCheckbox = (e) => {
        const { name, checked } = e.target;
        setState((state) => {
            const nst = { ...state, [name]: checked };
            return nst;
        });
    };
    const validations = () => {
        const { costCategory } = state;
        let emptyStrigPattern = /[^\s]/;
        let formIsValid = true;
        let errors = {};
        if (!emptyStrigPattern.test(costCategory)) {
            errors.costCategory = 'Cost Category is required';
            formIsValid = false;
        }
        setState((state) => ({ ...state, errors: errors }));
        return formIsValid;
    };

    const submitHandler = async () => {
        if (validations()) {
            const AllData = {
                costCategoryID: state.costCategoryID,
                costCategory: state.costCategory,
                costCategoryLedgerCode: state.costCategoryLedgerCode,
                active: state.active
            };
            let res = await postCostCategoriesData(AllData);
            if (res.success) {
                showToastSuccess('Cost Categories Saved Successfully');
                props.onClose(true);
            } else {
                showToastError(res.message);
            }
        }
    };

    return (
        <Grid container direction="row" spacing={2}>
            <Grid item xs={12} className="mandatory-fields">
                <MandatoryField
                    inputLabel="Cost Category"
                    name="costCategory"
                    placeholder="Cost Category"
                    onChange={handleInputChange}
                    value={state.costCategory}
                    errors={state.errors.costCategory}
                />
            </Grid>
            <Grid item xs={12}>
                <SimpleField
                    inputLabel="GL Code"
                    name="costCategoryLedgerCode"
                    placeholder="GL Code"
                    onChange={handleInputChange}
                    value={state.costCategoryLedgerCode}
                    disabled={state.code ? true : false}
                />
            </Grid>
            <Grid item xs={12}>
                <SecondaryCheckbox checked={state.active} name="active" onChange={handleCheckbox} label="Is Active ?" />
            </Grid>

            <Grid item xs={12} className="submit_btn">
                <SecondaryButton onClick={submitHandler}>{props.costCategoryID ? 'Update' : 'Add'}</SecondaryButton>
            </Grid>
        </Grid>
    );
}

export default CostCategoriesModal;
