import { Grid } from '@material-ui/core';
import React from 'react';

import car_repair_amberLogo from '../../../../Marker/car_repair_amber.png';
import car_repair_redLogo from '../../../../Marker/car_repair_red.png';
import car_repair_greenLogo from '../../../../Marker/car_repair_green.png';

const Mapheader = () => {
    return (
        <Grid container justify="space-between">
            <Grid item>Service Agents Map</Grid>
            <Grid item>
                <Grid container spacing={2}>
                    <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={car_repair_greenLogo} width="25px" />
                        <span style={{ fontSize: '12px' }}>&nbsp;{`<= 10 miles`}</span>
                    </Grid>
                    <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={car_repair_amberLogo} width="25px" />
                        <span style={{ fontSize: '12px' }}>&nbsp;{`<= 20 miles`}</span>
                    </Grid>
                    <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={car_repair_redLogo} width="25px" />
                        <span style={{ fontSize: '12px' }}>&nbsp;{`> 30 miles`}</span>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
export default Mapheader;
