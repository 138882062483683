import React, { useState, useEffect, useMemo } from 'react';
import DataGrid from '../../../../Core/Controls/Grid/index';
import { getAllJob } from '../../../../Core/Services/InternalUser/JOB/jobService';
import { BreadCrumbs, formatters } from '../../../../Core/Controls';
import { CustomIconButton } from '../../../../Core/Controls/Inputs/Buttons/ButtonModal';
import WorkIcon from '@material-ui/icons/Work';
import { Link } from 'react-router-dom';
import { useLoginInfo } from '../../../../Core/Hooks/useLoginInfo';
import GijgoGrid from '../../../../Core/Controls/GijgoGrid';
import Grid from '@material-ui/core/Grid';
import { useHistory } from 'react-router-dom';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import JobQueryModal from './JobStatusBtn/JobQuery';
import ReactDOM from 'react-dom';
import CustomerDetails from '../DetailsModal/customerDetails';
import ServiceAgentDetails from '../DetailsModal/serviceAgent';
import DepotDetails from '../DetailsModal/depotDetails';
import { AppStorage } from '../../../../Core/Basic/storage-service';

const base_columns = [
    { key: 'action', name: 'Action', width: '120', addFileUploaderIcon: true },
    { key: 'jobDate', name: 'Date', formatter: formatters.Date_DDMMYYYY, width: 100 },
    // { key: 'agentOrderNo', name: 'Job Ref', width: 145 },
    { key: 'vehicleRegNumber', name: 'Reg No', width: 100 },
    { key: 'jobDescription', name: 'Job Description', width: 150 },
    { key: 'partsTotal', name: 'Parts', formatter: formatters.NumberWithPound, align: 'right', width: 110 },
    { key: 'labourTotal', name: 'Labour', formatter: formatters.NumberWithPound, align: 'right', width: 110 },
    { key: 'orderTotal', name: 'Total', formatter: formatters.NumberWithPound, align: 'right', width: 110 },
    { key: 'serviceAgentName', name: 'Service Agent', width: 200 },
    // { key: 'customerCompanyName', name: 'Customer' },
    { key: 'depotName', name: 'Depots', width: 200 }
];

const getCrumbs = (code) => {
    let temp = {};
    let final = [
        { name: 'Home', path: '/' },
        { name: 'Jobs', active: true }
    ];
    if (code == 'AR') {
        temp = { name: 'Awaiting Approvals', active: true };
    } else if (code == 'DD') {
        temp = { name: 'Driver Defects', active: true };
    } else if (code == 'EP') {
        temp = { name: 'Estimates Pending', active: true };
    } else if (code == 'ER') {
        temp = { name: 'Estimates Revised', active: true };
    } else if (code == 'CQ') {
        temp = { name: 'Customer Query', active: true };
    } else if (code == 'JQ') {
        temp = { name: 'Query Job', active: true };
    } else if (code == 'FR') {
        temp = { name: 'Awating Review', active: true };
    } else if (code == 'AQ') {
        temp = { name: 'Agent Query', active: true };
    } else if (code == 'QJ') {
        temp = { name: 'Jobs In Query', active: true };
    } else if (code == 'AU') {
        temp = { name: 'Jobs Unassigned', active: true };
    } else if (code == 'CA') {
        temp = { name: 'Approved', active: true };
    } else if (code == 'EC') {
        temp = { name: 'Estimate Under Customer Review', active: true };
    } else if (code == 'EA') {
        temp = { name: 'Estimates Accepted', active: true };
    } else if (code == 'EQ') {
        temp = { name: 'Estimates Queried', active: true };
    } else {
        temp = { name: 'Open Jobs', active: true };
    }
    final.push(temp);
    return final;
};

const InternalUserJobsScreen = (props) => {
    const [state, setState] = useState({ showLoader: true, open: false, rows: [], isReload: null });
    const history = useHistory();
    let checkhaveAccess = AppStorage.getAllowAcces();

    const pullApiData = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date(),
            showLoader: false
        }));
    };

    const handleEditClick = (val) => {
        history.push({
            pathname: `/controller/jobTabScreen/${val?.jobID}/${val?.vehicleID}`,
            search: '',
            state: 'jobs'
        });
    };

    const handleShowQueries = (val) => {
        setState((state) => ({ ...state, showJobQuery: true, jobID: val?.jobID }));
    };

    const closeTheDialog = (val) => {
        setState((state) => ({ ...state, showJobQuery: false, jobID: '' }));
    };

    const ActionButtons = (data, row) => {
        return (
            <div style={{ textAlign: 'center' }}>
                {/* <Link to={`/controller/jobTabScreen/${data.row?.jobID}/${data.row?.jobVehicleID}`}> */}
                <CustomIconButton
                    icon={SpeakerNotesIcon}
                    className="modal-btn-update btn_space"
                    toolTipTitle="Show Queries"
                    onClick={() => handleShowQueries(row)}
                />
                {checkhaveAccess?.CanViewJobs && (
                    <CustomIconButton icon={WorkIcon} className="modal-btn-update btn_space" toolTipTitle="Edit Job" onClick={() => handleEditClick(row)} />
                )}
                {/* </Link>  */}
            </div>
        );
    };
    const isCustomer = useLoginInfo().userRoles.includes('customer');

    const handelRowCick = (row, name) => {
        setState((st) => ({
            ...st,
            selectedRowData: row,
            [name]: true
        }));
    };

    const renderRegNumberEdit = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handleEditRegClick(row)} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.vehicleRegNumber}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const renderAgentName = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handelRowCick(row, 'showSaModal')} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.serviceAgentName}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const renderDepotName = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handelRowCick(row, 'showDepotModal')} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.depotName}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const renderCustomerName = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handelRowCick(row, 'showCustomerModal')} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.customerCompanyName}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const handleEditRegClick = (val) => {
        history.push({
            pathname: `/controller/editVehicle/${val.vehicleID}`,
            search: '',
            state: { fileCount: val.fileCount }
        });
    };

    const handleClose = () => {
        setState((st) => ({
            ...st,
            open: false,
            showSaModal: false,
            showCustomerModal: false,
            showDepotModal: false
        }));
    };
    const renderjobDate = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = <div style={{ textAlign: 'center' }}>{row.bookingDate ? row.bookingDate : row.jobDate}</div>;
        ReactDOM.render(lnk, spn);
        return spn;
    };
    const columns = useMemo(() => {
        let newColumn = [...base_columns];
        let actionCol = newColumn.find((item) => item.key === 'action');
        let actionjobDateCol = newColumn.find((item) => item.key === 'jobDate');
        let agentNameCol = newColumn.find((element) => element.key === 'serviceAgentName');
        let depotNameCol = newColumn.find((element) => element.key === 'depotName');
        let regNumberCol = newColumn.find((element) => element.key === 'vehicleRegNumber');
        if (actionjobDateCol || true) {
            actionjobDateCol.formatter = ({ row, col }, grid) => {
                const spn = document.createElement('span');
                const lnk = (
                    <div style={{ textAlign: 'center' }}>
                        {row.bookingDate ? formatters.YearFormatter(row.bookingDate) : formatters.YearFormatter(row.jobDate)}
                    </div>
                );
                ReactDOM.render(lnk, spn);
                return spn;
            };
        }
        if (regNumberCol) {
            regNumberCol.formatter = renderRegNumberEdit;
        }
        if (depotNameCol) {
            depotNameCol.formatter = renderDepotName;
        }
        if (!isCustomer) {
            newColumn.splice(7, 0, { key: 'customerCompanyName', name: 'Customer', formatter: renderCustomerName, width: 150 });
        }
        if (isCustomer || props.screenCode == 'AR') {
            newColumn.splice(3, 0, { key: 'jobOrderNo', name: 'Order No', width: 145 });
        } else {
            newColumn.splice(3, 0, { key: 'agentOrderNo', name: 'Job Ref', width: 145 });
        }
        if (agentNameCol) {
            agentNameCol.formatter = renderAgentName;
        }
        if (actionCol) {
            actionCol.formatter = ActionButtons;
        }
        // if (props.screenCode == 'EP') {
        //     newColumn.splice(3, 0, {
        //         key: 'jobEstimatedCost',
        //         name: 'Estimate',
        //         formatter: formatters.ColumnTextAlignRight,
        //         headerRenderer: formatters.ColumnHeaderAlignRight
        //     });
        //     newColumn.splice(7, 2);
        // }
        return newColumn;
    }, [state]);

    const crumbs = getCrumbs(props.screenCode);
    let getUrl = `VehicleJob/?jobstatusid=${props.screenCode ? props.screenCode : ''}`;
    return (
        <div className="screen">
            <BreadCrumbs crumbs={crumbs} />
            <GijgoGrid
                columns={columns}
                dataConverter={(res) => ({
                    records: res.data.list,
                    total: res.data.total
                })}
                isReload={state.isReload}
                getUrl={getUrl}
                idFieldName={`${checkhaveAccess?.CanViewJobs && checkhaveAccess?.CanViewDocuments ? 'jobID' : null}`}
                fileUploaderConfigName={`${checkhaveAccess?.CanViewJobs && checkhaveAccess?.CanViewDocuments ? 'JobScreen' : null}`}
            />
            {state.showJobQuery && (
                <DialogComp title="Job Queries" maxWidth="sm" onClose={closeTheDialog}>
                    <JobQueryModal title="" jobQueryJobID={state.jobID} onClose={closeTheDialog} />
                </DialogComp>
            )}
            {state.showSaModal ? (
                <DialogComp open={true} onClose={handleClose} title={'Service Agent Details'}>
                    <ServiceAgentDetails serviceAgentID={state.selectedRowData.jobServiceAgentID} onFormSubmit={handleClose} />
                </DialogComp>
            ) : null}
            {state.showCustomerModal ? (
                <DialogComp open={true} onClose={handleClose} title={'Customer Details'}>
                    <CustomerDetails customerID={state.selectedRowData.customerID} onFormSubmit={handleClose} />
                </DialogComp>
            ) : null}
            {state.showDepotModal ? (
                <DialogComp open={true} onClose={handleClose} title={'Depot Details'}>
                    <DepotDetails customerID={state.selectedRowData.jobDepotID} onFormSubmit={handleClose} />
                </DialogComp>
            ) : null}
        </div>
    );
};
export default InternalUserJobsScreen;
