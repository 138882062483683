import { createContext, useEffect, useState, useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import { InputLabel, Typography, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { getContracts, getContractsDropDown, postContracts } from '../../../../../Core/Services/InternalUser/Vehicle/contractVechicleService';
import moment from 'moment';
import { useToast } from '../../../../../Core/Hooks/useToast';

export const ContractContext = createContext();

export const ContractProvider = (props) => {
    console.log(props);
    const [state, setState] = useState({
        errors: {},
        contractID: props.vehicleContractID,
        contractVehicleID: props.vehicleID,
        showLoader: props.vehicleContractID ? true : false
    });
    const [dropdown, setDropdown] = useState({
        contractDurationTypes: [],
        contractTypes: [],
        agreementTypes: [],
        maintenanceProviders: [],
        contractStatus: [],
        serviceAgents: []
    });
    const { showToastSuccess, showToastError } = useToast();

    useEffect(async () => {
        pullEditData();
    }, []);

    const pullEditData = async () => {
        if (state.contractID) {
            let res = await getContracts(state.contractID);
            let data = res.data?.details[0];
            if (res.success) {
                setState((st) => ({
                    ...st,
                    contractID: data.contractID,
                    contractVehicleID: data.contractVehicleID,
                    contractStatusID: data.contractStatusID,
                    contractTypeID: data.contractTypeID,
                    contractReference: data.contractReference,
                    contractNominalCode: data.contractNominalCode,
                    contractDurationTypeID: data.contractDurationTypeID,
                    contractAgreementTypeID: data.contractAgreementTypeID,
                    contractTerm: data.contractTerm,
                    contractStartDate: data.contractStartDate,
                    contractEndDate: data.contractEndDate,
                    contractExtendedDate: data.contractExtendedDate,
                    contractAnticipatedRenewalDate: data.contractAnticipatedRenewalDate,
                    contractPurchasePrice: data.contractPurchasePrice,
                    contractPurchaseDate: data.contractPurchaseDate,
                    contractFinalPayment: data.contractFinalPayment,
                    contractAdminCost: data.contractAdminCost,
                    contractRAndMIncluded: data.contractRAndMIncluded,
                    contractRAndMCost: data.contractRAndMCost,
                    contractTyresIncluded: data.contractTyresIncluded,
                    contractTyresCost: data.contractTyresCost,
                    contractSubstituteIncluded: data.contractSubstituteIncluded,
                    contractSubstituteCost: data.contractSubstituteCost,
                    contractBreakdownIncluded: data.contractBreakdownIncluded,
                    contractBreakdownCost: data.contractBreakdownCost,
                    contractInspectionsIncluded: data.contractInspectionsIncluded,
                    contractInspectionsCost: data.contractInspectionsCost,
                    contractTachographIncluded: data.contractTachographIncluded,
                    contractTachographCost: data.contractTachographCost,
                    contractRFLIncluded: data.contractRFLIncluded,
                    contractRFLCost: data.contractRFLCost,
                    contractAncEquipmentIncluded: data.contractAncEquipmentIncluded,
                    contractAncEquipmentCost: data.contractAncEquipmentCost,
                    contractReSellValue: data.contractReSellValue,
                    contractRAndMStartDate: data.contractRAndMStartDate,
                    contractRAndMEndDate: data.contractRAndMEndDate,
                    contractMileage: data.contractMileage,
                    contractExcessMileage: data.contractExcessMileage,
                    contractP11DValue: data.contractP11DValue,
                    contractInitialMileage: data.contractInitialMileage,
                    contractAnticipatedAnnualMileage: data.contractAnticipatedAnnualMileage,
                    contractFundingCompanyName: data.contractFundingCompanyName,
                    contractFundingCompanyTelephone: data.contractFundingCompanyTelephone,
                    contractInitialPayment: data.contractInitialPayment,
                    contractMonthlyPayment: data.contractMonthlyPayment,
                    contractBroker: data.contractBroker,
                    contractTotalCostOfFunding: data.contractTotalCostOfFunding,
                    contractInclusiveofMaintenance: data.contractInclusiveofMaintenance,
                    contractMaintenanceCost: data.contractMaintenanceCost,
                    contractMaintenanceProviderId: data.contractMaintenanceProviderId,
                    contractTyreSupplier: data.contractTyreSupplier,
                    showLoader: false
                }));
            }
        }
    };

    // DropDown Api side
    useEffect(async () => {
        let res = await getContractsDropDown();
        if (res.success) {
            setDropdown((st) => ({
                ...st,
                contractDurationTypes: res.data.contractDurationTypes.map((m) => ({
                    id: m.contractDurationTypeID,
                    name: m.contractDurationTypeDescription
                })),
                contractTypes: res.data.contractTypes.map((m) => ({
                    id: m.contractTypeID,
                    name: m.contractTypeDescription
                })),
                agreementTypes: res.data.agreementTypes.map((m) => ({
                    id: m.agreementTypeID,
                    name: m.agreementTypeDescription
                })),
                maintenanceProviders: res.data.maintenanceProviders.map((m) => ({
                    id: m.maintenanceProviderID,
                    name: m.maintenanceProviderName
                })),
                contractStatus: res.data.contractStatus.map((m) => ({
                    id: m.contractStatusID,
                    name: m.contractStatusDescription
                })),
                serviceAgents: res.data.serviceAgents.map((m) => ({
                    id: m.id,
                    name: m.name
                }))
            }));
        }
    }, []);

    const inputChange = useCallback((e) => {
        const { name, value, type } = e.target;
        if (value === 'true' && type.toLowerCase() === 'radio') {
            setState((st) => ({ ...st, [name]: true }));
        } else if (value === 'false' && type.toLowerCase() === 'radio') {
            setState((st) => ({ ...st, [name]: false }));
        } else {
            setState((st) => ({ ...st, [name]: value }));
        }
    }, []);

    const validation = () => {
        let isValid = true;
        let errors = {};
        let {
            contractStartDate,
            contractMaintenanceCost,
            contractReSellValue,
            contractRAndMCost,
            contractSubstituteCost,
            contractBreakdownCost,
            contractInspectionsCost,
            contractTyresCost,
            contractTachographCost,
            contractRFLCost,
            contractAncEquipmentCost,
            contractAdminCost
        } = state;

        if (!contractMaintenanceCost) {
            isValid = false;
            errors.contractMaintenanceCost = 'Contract Maintenance Cost is required';
        }
        if (!contractReSellValue) {
            isValid = false;
            errors.contractReSellValue = 'Contract ReSell Value is required';
        }
        if (!contractRAndMCost) {
            isValid = false;
            errors.contractRAndMCost = 'Contract RAndM Cost is required';
        }
        if (!contractSubstituteCost) {
            isValid = false;
            errors.contractSubstituteCost = 'Contract Substitute Cost is required';
        }
        if (!contractBreakdownCost) {
            isValid = false;
            errors.contractBreakdownCost = 'Contract Breakdown Cost is required';
        }
        if (!contractInspectionsCost) {
            isValid = false;
            errors.contractInspectionsCost = 'Contract Inspections Cost is required';
        }
        if (!contractTyresCost) {
            isValid = false;
            errors.contractTyresCost = 'Contract Tyres Cost is required';
        }
        if (!contractTachographCost) {
            isValid = false;
            errors.contractTachographCost = 'Contract Tachograph Cost is required';
        }
        if (!contractRFLCost) {
            isValid = false;
            errors.contractRFLCost = 'Contract RFL Cost is required';
        }
        if (!contractAncEquipmentCost) {
            isValid = false;
            errors.contractAncEquipmentCost = 'Contract Anc Equipment Cost is required';
        }
        if (!contractAdminCost) {
            isValid = false;
            errors.contractAdminCost = 'Contract Admin Cost is required';
        }
        if (!contractStartDate) {
            isValid = false;
            errors.contractStartDate = 'Contract Start Date is required';
        }

        setState((st) => ({
            ...st,
            errors: errors
        }));
        return isValid;
    };

    // submit data
    const submitData = async () => {
        if (validation()) {
            let data = {
                contractID: state.contractID || null,
                contractVehicleID: state.contractVehicleID || null,
                contractStatusID: state.contractStatusID || null,
                contractTypeID: state.contractTypeID || null,
                contractReference: state.contractReference || null,
                contractNominalCode: state.contractNominalCode || null,
                contractDurationTypeID: state.contractDurationTypeID || null,
                contractAgreementTypeID: state.contractAgreementTypeID || null,
                contractTerm: state.contractTerm || null,
                contractStartDate: state.contractStartDate || null,
                contractEndDate: state.contractEndDate || null,
                contractExtendedDate: state.contractExtendedDate || null,
                contractAnticipatedRenewalDate: state.contractAnticipatedRenewalDate || null,
                contractPurchasePrice: state.contractPurchasePrice || null,
                contractPurchaseDate: state.contractPurchaseDate || null,
                contractFinalPayment: state.contractFinalPayment || null,
                contractAdminCost: state.contractAdminCost || null,
                contractRAndMIncluded: state.contractRAndMIncluded,
                contractRAndMCost: state.contractRAndMCost || null,
                contractTyresIncluded: state.contractTyresIncluded,
                contractTyresCost: state.contractTyresCost || null,
                contractSubstituteIncluded: state.contractSubstituteIncluded,
                contractSubstituteCost: state.contractSubstituteCost || null,
                contractBreakdownIncluded: state.contractBreakdownIncluded,
                contractBreakdownCost: state.contractBreakdownCost || null,
                contractInspectionsIncluded: state.contractInspectionsIncluded,
                contractInspectionsCost: state.contractInspectionsCost || null,
                contractTachographIncluded: state.contractTachographIncluded,
                contractTachographCost: state.contractTachographCost || null,
                contractRFLIncluded: state.contractRFLIncluded,
                contractRFLCost: state.contractRFLCost || null,
                contractAncEquipmentIncluded: state.contractAncEquipmentIncluded,
                contractAncEquipmentCost: state.contractAncEquipmentCost || null,
                contractReSellValue: state.contractReSellValue || null,
                contractRAndMStartDate: state.contractRAndMStartDate || null,
                contractRAndMEndDate: state.contractRAndMEndDate || null,
                contractMileage: state.contractMileage || null,
                contractExcessMileage: state.contractExcessMileage || null,
                contractP11DValue: state.contractP11DValue || null,
                contractInitialMileage: state.contractInitialMileage || null,
                contractAnticipatedAnnualMileage: state.contractAnticipatedAnnualMileage || null,
                contractFundingCompanyName: state.contractFundingCompanyName || null,
                contractFundingCompanyTelephone: state.contractFundingCompanyTelephone || null,
                contractInitialPayment: state.contractInitialPayment || null,
                contractMonthlyPayment: state.contractMonthlyPayment || null,
                contractBroker: state.contractBroker || null,
                contractTotalCostOfFunding: state.contractTotalCostOfFunding || null,
                contractInclusiveofMaintenance: state.contractInclusiveofMaintenance || null,
                contractMaintenanceCost: state.contractMaintenanceCost || null,
                contractMaintenanceProviderId: state.contractMaintenanceProviderId || null,
                contractTyreSupplier: state.contractTyreSupplier || null
            };

            let message = !state.contractID ? 'Contract Added Successfully' : 'Contract Update Successfully';

            let res = await postContracts(data);
            if (res.success) {
                showToastSuccess(message);
                props.pullDetails();
            } else {
                showToastError(res.message);
            }
        }
    };

    useEffect(() => {
        if (state.contractTerm) {
            let changeDate = moment(state.contractStartDate).add(state.contractTerm, 'months').format('YYYY-MM-DD');
            setState((st) => ({
                ...st,
                contractEndDate: changeDate
            }));
        }
    }, [state.contractTerm]);

    const YesNoRadio = (props) => {
        return (
            <Grid container>
                <Grid container item spacing={1} style={{ marginLeft: '2px' }}>
                    <RadioGroup row aria-label={props.name} name={props.name} value={props.value} onChange={props.onChange}>
                        <FormControlLabel checked={props.value === true} value={true} control={<Radio />} label="Yes" />
                        <FormControlLabel checked={props.value === false} value={false} control={<Radio />} label="No" />
                    </RadioGroup>
                </Grid>
            </Grid>
        );
    };

    return (
        <ContractContext.Provider
            value={{
                state,
                YesNoRadio,
                dropdown,
                inputChange,
                submitData
            }}
        >
            {props.children}
        </ContractContext.Provider>
    );
};
