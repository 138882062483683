import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import SignaturePad from 'react-signature-canvas';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import moment from 'moment';
import {
    Tooltip,
    InputLabel,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    FormHelperText,
    Typography,
    CircularProgress,
    Hidden
} from '@material-ui/core';
import {
    SecondaryButton,
    InputText,
    FailButton,
    PassButton,
    NAButton,
    MButton,
    RButton,
    DatePicker,
    SecondaryCheckbox,
    UploadWithBadge,
    PassAllbutton
} from '../../../../../Core/Controls';
import { getChecksAddTabByID, postChecksData, PostImage, getImageByID } from '../../../../../Core/Services/InternalUser/Vehicle/vehicleTabChecks';
import { useToast } from '../../../../../Core/Hooks/useToast';
import { useLoginInfo } from '../../../../../Core/Hooks/useLoginInfo';
import DeleteIcon from '@material-ui/icons/Delete';
import { getVehicleById } from '../../../../../Core/Services/InternalUser/ControllerService/vehicleService';
import Axle from './vehicleAxel';
import { AppStorage } from '../../../../../Core/Basic/storage-service';
import CreateIcon from '@material-ui/icons/Create';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import '../../controllerStyle.scss';
import ImageModal from '../DriverDailyChecks/imageModal';
import ConfirmationModal from '../../../../../Core/Modal/ConfirmationModal';

import { getTitleData } from '../../../../../Core/Services/InternalUser/ControllerService/vehicleService';
import DialogComp from '../../../../../Core/Dialog/DialogComp';
const StyledTableCell = withStyles((theme) => ({
    head: {
        fontSize: 16,
        padding: '8px 20px',
        color: '#f50057'
    },
    body: {
        fontSize: 14,
        padding: '8px 10px'
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: '#F5F5F5'
        }
    }
}))(TableRow);

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 15
    },
    table: {
        minWidth: '100%'
    },
    loader: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 300
    },

    title: {
        color: '#f50057',
        marginLeft: 8,
        marginTop: 8,
        marginBottom: 8
    },
    paper: {
        padding: theme.spacing(2),
        color: theme.palette.text.secondary,
        border: '1px solid rgba(0,0,0,0.2)'
    },
    axelTitles: {
        textAlign: 'center',
        color: '#f50057',
        width: '100%'
    },
    headings: {
        color: '#f50057',
        margin: 0,
        marginLeft: -4,
        marginBottom: 20
    },
    loader: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 300
    }
}));

const CheckButtons = {
    D: FailButton,
    M: MButton,
    N: NAButton,
    S: PassButton,
    R: RButton
};

const VehicleDailyChecks = (props) => {
    const classes = useStyles();
    const { displayName } = useLoginInfo();
    const [btnLoader, setBtnLoader] = useState(false);

    const [state, setState] = useState({
        checksList: [],
        buttonList: [],
        passVal: false,
        imageUpload: [],
        date: moment().format('YYYY-MM-DD'),
        inspectionSheetAxlesVM: [],
        VehicleDailyCheckResultsVM: [],
        vehicleInspectionID: null,
        vehicleInspectionScheduleID: props.sheduleID,
        vehicleDailyCheckCheckerName: displayName,
        vehicleInspectionStatusID: null,
        vehicleInspectionDateInspected: moment().format('YYYY-MM-DD'),
        vehicleInspectionResult: null,
        vehicleInspectionSignedOffComment: null,
        vehicleInspectionSignedOffName: null,
        vehicleInspectionSignedOffSignature: null,
        vehicleInspectionSignedOffDate: null,
        vehicleInspectionSignedOffIPAddress: null,
        loader: true,
        signAvailable: AppStorage.getSignature() === 'absent' ? false : true,
        windowWidth: window.innerWidth,
        axleErrors: {},
        showTost: false,
        showConfirmMessage: false
    });

    const { showToastSuccess, showToastError } = useToast();

    const handleInput = (e) => {
        const { name, value } = e.target;
        if (name === 'vehicleInspectionBrakeTestTypeID') {
            setState((state) => ({ ...state, [name]: +value }));
        } else {
            setState((state) => ({ ...state, [name]: value }));
        }
    };

    useEffect(async () => {
        if (props.sheduleVechicleID) {
            let vehicleInformation = await getTitleData(props.sheduleVechicleID);

            if (vehicleInformation.success) {
                setState((st) => ({
                    ...st,
                    vehicleInfo: vehicleInformation.data.details[0]?.vehicleDescription
                }));
            }
            let res2 = await getVehicleById(props.sheduleVechicleID);
            if (res2.success) {
                const data = res2.data;
                setState((st) => ({
                    ...st,
                    regNumber: data.vehicleRegNumber,
                    modelID: data.vehicleModel,
                    makeID: data.vehicleMake
                }));
            }
            let res = await getChecksAddTabByID(props.sheduleVechicleID);
            if (res.success) {
                let tempArray = [];
                res.data.inspectionSheetSectionsVM?.map((q) => {
                    q.inspectionSheetChecksVM?.map((r) => {
                        r.inspectionSheetCheckResultCheckID = r.inspectionSheetCheckID;
                        r.inspectionSheetCheckResultOptionCode = null;
                        r.inspectionSheetCheckResultNotes = null;
                        r.inspectionSheetCheckResultIMReference = null;
                        r.inspectionSheetCheckResultDefectID = null;
                        tempArray.push(r);
                    });
                });

                setState((st) => ({
                    ...st,
                    checksList: res.data.inspectionSheetSectionsVM,
                    buttonList: res.data.inspectionSheetCheckOptionsVM,
                    VehicleDailyCheckResultsVM: tempArray,
                    loader: false
                }));
            }
        }
    }, []);

    const signCanvas = useRef({});

    const clear = () => {
        if (state.imgtechniciansign) {
            setState((st) => ({
                ...st,
                imgtechniciansign: false,
                techniciansign: null
            }));
        } else {
            signCanvas.current.clear();
        }
    };

    const GetTechnicianSign = () => {
        setState((st) => {
            const nst = {
                ...st,
                techniciansign: signCanvas.current.toDataURL()
            };
            return nst;
        });
    };

    const useWindowSize = () => {
        const [size, setSize] = useState([0, 0]);
        useLayoutEffect(() => {
            const updateSize = () => {
                setSize([window.innerWidth, window.innerHeight]);
            };
            window.addEventListener('resize', updateSize);
            updateSize();
            return () => window.removeEventListener('resize', updateSize);
        }, []);
        return size;
    };
    const [widths, heights] = useWindowSize();

    const imageHandler = (inspectioncheckID) => {
        let changedArray = [...state.VehicleDailyCheckResultsVM];
        let objIndex = state.VehicleDailyCheckResultsVM.findIndex((x) => x.inspectionSheetCheckID === inspectioncheckID);
        setState((st) => ({ ...st, showImageModal: true, inspectioncheckID: inspectioncheckID, selectedImages: changedArray[objIndex].images || [] }));
    };
    const onImageChange = (imageList, addUpdateIndex) => {
        // let tempArray = [];
        let changedArray = [...state.VehicleDailyCheckResultsVM];
        let FilterimagesList;
        let objIndex = state.VehicleDailyCheckResultsVM.findIndex((x) => x.inspectionSheetCheckID === state.inspectioncheckID);

        if (`${objIndex}`) {
            FilterimagesList = imageList.map((m) => (m.data_url ? m.data_url : m));
            changedArray[objIndex].images = FilterimagesList;
        }

        let tempArray = [...state.imageUpload];
        tempArray.push({ inspectionSheetCheckCheckID: state.inspectioncheckID, inspectionSheetCheckPhotos: FilterimagesList });

        // let tempImgUploadArray = { inspectionSheetCheckCheckID: state.inspectioncheckID, inspectionSheetCheckPhotos: [...FilterimagesList] };
        // tempArray.push(tempImgUploadArray);
        setState((st) => ({ ...st, VehicleDailyCheckResultsVM: changedArray, selectedImages: FilterimagesList, imageUpload: tempArray }));
    };

    const changeState = (buttonVal, checkId) => {
        let changedArray = [...state.VehicleDailyCheckResultsVM];
        let objIndex = state.VehicleDailyCheckResultsVM.findIndex((x) => x.inspectionSheetCheckID === checkId);
        changedArray[objIndex].inspectionSheetCheckResultOptionCode = buttonVal;
        setState((st) => ({ ...st, VehicleDailyCheckResultsVM: changedArray }));
    };

    const changeNotesState = (id, des) => (e) => {
        let changedArray = [...state.VehicleDailyCheckResultsVM];
        let objIndex = state.VehicleDailyCheckResultsVM.findIndex((x) => x.inspectionSheetCheckID === id);
        changedArray[objIndex].inspectionSheetCheckResultNotes = e.target.value;
        setState((st) => ({ ...st, VehicleDailyCheckResultsVM: changedArray }));
    };

    const changeAllChildChecks = (buttonId, condition, groupLabel) => () => {
        const grp = state.checksList.find((l) => l.inspectionSheetSectionDescription === groupLabel);
        if (grp.state === true) {
            grp.state = false;
        } else {
            grp.state = condition;
        }
        if (grp) {
            const sheetsCheckIds = grp.inspectionSheetChecksVM.map((p) => p.inspectionSheetCheckID);
            setState((st) => {
                const newSt = { ...st };
                sheetsCheckIds.forEach((id) => {
                    const sheetsCheck = newSt.VehicleDailyCheckResultsVM.find((c) => c.inspectionSheetCheckID === id);
                    if (sheetsCheck) {
                        sheetsCheck.inspectionSheetCheckResultOptionCode = grp.state ? buttonId : null;
                        newSt[id] = grp.state ? buttonId : null;
                    }
                });
                return { ...newSt, VehicleDailyCheckResultsVM: [...newSt.VehicleDailyCheckResultsVM] };
            });
        }
    };

    const handleInputs = (axelNo) => (e) => {
        let showError = {};
        const { name, value } = e.target;
        let showTost = false;
        setState((st) => {
            let ax = st.inspectionSheetAxlesVM.find((a) => a.axleNumber === axelNo);
            if (!ax) {
                ax = { axleNumber: axelNo };
                st.inspectionSheetAxlesVM.push(ax);
            }
            ax[name] = value;
            switch (name) {
                case 'osTreadDepth':
                    showError.message = (value > 0 && value > 20) || value < 0 ? `Axle ${axelNo} ${name}  must be Smaller than 20 and greater than 0` : null;
                    showTost = true;
                    break;
                case 'osFrontTreadDepth':
                    showError.message = (value > 0 && value > 20) || value < 0 ? `Axle ${axelNo} ${name}  must be Smaller than 20 and greater than 0` : null;
                    showTost = true;
                    break;
                case 'nsTreadDepth':
                    showError.message = (value > 0 && value > 20) || value < 0 ? `Axle ${axelNo} ${name}  must be Smaller than 20 and greater than 0` : null;
                    showTost = true;
                    break;
                case 'nsFrontTreadDepth':
                    showError.message = (value > 0 && value > 20) || value < 0 ? `Axle ${axelNo} ${name}  must be Smaller than 20 and greater than 0` : null;
                    showTost = true;
                    break;
                case 'oSTyrePressure':
                    showError.message = (value > 0 && value > 70) || value < 0 ? `Axle ${axelNo} ${name}  must be Smaller than 70 and greater than 0` : null;
                    showTost = true;
                    break;
                case 'oSFrontTyrePressure':
                    showError.message = (value > 0 && value > 70) || value < 0 ? `Axle ${axelNo} ${name}  must be Smaller than 70 and greater than 0` : null;
                    showTost = true;
                    break;
                case 'nSTyrePressure':
                    showError.message = (value > 0 && value > 70) || value < 0 ? `Axle ${axelNo} ${name}  must be Smaller than 70 and greater than 0` : null;
                    showTost = true;
                    break;
                case 'nSFrontTyrePressure':
                    showError.message = (value > 0 && value > 70) || value < 0 ? `Axle ${axelNo} ${name}  must be Smaller than 70 and greater than 0` : null;
                    showTost = true;
                    break;
                case 'nsBrakeLineRemaining':
                    showError.message = (value > 0 && value > 100) || value < 0 ? `Axle ${axelNo} ${name}  must be Smaller than 100 and greater than 0` : null;
                    showTost = true;
                    break;
                case 'osBrakeLineRemaining':
                    showError.message = (value > 0 && value > 100) || value < 0 ? `Axle ${axelNo} ${name}  must be Smaller than 100 and greater than 0` : null;
                    showTost = true;
                    break;

                default:
                    showError.message = null;
                    showTost = false;
                    break;
            }

            return { ...st, inspectionSheetAxlesVM: [...st.inspectionSheetAxlesVM], axleErrors: showError, showTost: showTost };
        });
    };

    const validations = () => {
        let formIsValid = true;
        let errors = {};
        let checksOption = state.VehicleDailyCheckResultsVM.filter((x) => {
            return x.inspectionSheetCheckResultOptionCode === null;
        });

        let checksNotes = state.VehicleDailyCheckResultsVM.filter((x) => {
            return (
                (x.inspectionSheetCheckResultOptionCode === 'D' && x.inspectionSheetCheckResultNotes == null) ||
                (x.inspectionSheetCheckResultOptionCode === 'M' && x.inspectionSheetCheckResultNotes == null)
            );
        });
        let axel1Validation = state.inspectionSheetAxlesVM.find((a) => a.axleNumber === 1);
        let axel2Validation = state.inspectionSheetAxlesVM.find((a) => a.axleNumber === 2);
        let Axeloneval = 1;
        let Axeltwoval = 1;

        if (axel1Validation) {
            Axeloneval = Object.keys(axel1Validation).length;
        }
        if (axel2Validation) {
            Axeltwoval = Object.keys(axel2Validation).length;
        }

        if (!state.vehicleDailyCheckMileage) {
            errors.vehicleDailyCheckMileage = `Odometer Reading  is required`;
            formIsValid = false;
        }
        if (checksOption.length > 0) {
            errors.message = 'This field is required';
            formIsValid = false;
        }
        if (Axeloneval <= 6) {
            errors.axelone = 'Axel 1 fields are required';
            formIsValid = false;
        }

        if (Axeltwoval <= 10) {
            errors.axeltwo = 'Axel 2 fields are required';
            formIsValid = false;
        }

        if (checksNotes.length > 0) {
            errors.note = 'Note is required';
            formIsValid = false;
        }

        if (!state.techniciansign) {
            errors.techniciansign = 'Signature is required';
            formIsValid = false;
        }
        if (!state.vehicleInspectionInspectionComment) {
            errors.vehicleInspectionInspectionComment = 'Vehicle checker Comment is required';
            formIsValid = false;
        }
        setState((st) => ({
            ...st,
            errors: errors
        }));
        return formIsValid;
    };
    const useDefaultSig = () => {
        setState((st) => ({ ...st, imgtechniciansign: true, techniciansign: AppStorage.getSignature() }));
    };
    const handleConfirmPopup = () => {
        if (validations()) {
            setState((st) => ({
                ...st,
                showConfirmMessage: !st.showConfirmMessage
            }));
        }
    };

    const submitData = async () => {
        let resultVM = state.VehicleDailyCheckResultsVM.map((e) => ({
            inspectionSheetCheckResultCheckID: e.inspectionSheetCheckResultCheckID,
            inspectionSheetCheckResultOptionCode: e.inspectionSheetCheckResultOptionCode,
            inspectionSheetCheckResultNotes: e.inspectionSheetCheckResultNotes,
            inspectionSheetCheckResultIMReference: e.inspectionSheetCheckResultIMReference,
            inspectionSheetCheckResultDefectID: e.inspectionSheetCheckResultDefectID
        }));

        let data = {
            vehicleInspectionScheduleID: state.vehicleInspectionScheduleID,
            vehicleInspectionStatusID: state.vehicleInspectionStatusID,
            vehicleInspectionDateInspected: state.vehicleInspectionDateInspected,
            vehicleInspectionResult: state.vehicleInspectionResult,
            vehicleInspectionMileage: state.vehicleDailyCheckMileage,
            vehicleInspectionInspectorName: state.vehicleDailyCheckCheckerName,
            vehicleInspectionInspectorSignature: state.techniciansign,
            vehicleInspectionInspectionComment: state.vehicleInspectionInspectionComment,
            vehicleInspectionSignedOffName: state.vehicleInspectionSignedOffName,
            vehicleInspectionSignedOffSignature: state.vehicleInspectionSignedOffSignature,
            vehicleInspectionSignedOffDate: state.vehicleInspectionSignedOffDate,
            vehicleInspectionSignedOffIPAddress: state.vehicleInspectionSignedOffIPAddress,
            vehicleInspectionBrakeTestTypeID: state.vehicleInspectionBrakeTestTypeID,
            vehicleInspectionBrakeTestNumber: state.vehicleInspectionBrakeTestNumber,
            vehicleInspectionRoadCondition: state.vehicleInspectionRoadCondition,
            vehicleInspectionServiceBrakePerformance: state.vehicleInspectionServiceBrakePerformance,
            vehicleInspectionSecondaryBrakePerformance: state.vehicleInspectionSecondaryBrakePerformance,
            vehicleInspectionParkingBrakePerformance: state.vehicleInspectionParkingBrakePerformance,
            vehicleInspectionID: state.vhicleInspectionID,
            vehicleInspectionSignedOffComment: state.vehicleInspectionSignedOffComment,
            inspectionSheetCheckResultsVM: resultVM,
            inspectionSheetAxlesVM: state.inspectionSheetAxlesVM,
            inspectionSheetCheckPhotoVM: state.imageUpload
        };
        setBtnLoader(true);
        let res = await postChecksData(data);
        if (res.success) {
            showToastSuccess('Vehicle Checks added successfully');
            props.onClose(true);
        } else {
            showToastError(res.message);
        }
        setBtnLoader(false);
    };

    const sumbitImage = () => {
        if (state.selectedImages.length > 0) {
            showToastSuccess('Images Uploaded successfully');
            let changedArray = [...state.VehicleDailyCheckResultsVM];
            let objIndex = state.VehicleDailyCheckResultsVM.findIndex((x) => x.inspectionSheetCheckID === state.inspectioncheckID);
            changedArray[objIndex].basicCount = changedArray[objIndex].images.length; // changedArray[objIndex].inspectionSheetCheckResultNotes = e.target.value;
            setState((st) => ({ ...st, VehicleDailyCheckResultsVM: changedArray }));
        }
        setState((st) => ({ ...st, showImageModal: false }));
    };

    return (
        <div className={classes.root}>
            {state.loader ? (
                <div className={classes.loader}>
                    <CircularProgress color="secondary" />
                </div>
            ) : (
                <Grid container spacing={2}>
                    {props.frompage ? (
                        <Grid item xs={12} sm={12} md={10} style={{ padding: '0px 8px' }}>
                            <Typography variant={'h5'} style={{ fontSize: widths < 500 ? 17 : '' }}>
                                {state.vehicleInfo?.toUpperCase() || ''}
                            </Typography>
                        </Grid>
                    ) : null}
                    <Grid item xs={12} sm={12} md={props.frompage ? 2 : 12} style={{ padding: '0px 8px' }}>
                        <Typography variant={'h5'} style={{ textAlign: props.frompage ? 'end' : 'start', marginRight: 10, fontSize: widths < 500 ? 15 : '' }}>
                            {state.date}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Paper style={{ padding: 10, border: '1px solid #c5bfbf' }}>
                            <Hidden only={['xs', 'sm']}>
                                <Grid container spacing={1} justify={widths >= 960 ? 'space-evenly' : 'flex-start'}>
                                    {state.buttonList?.map((k) => {
                                        const Comp = CheckButtons[k.inspectionSheetCheckOptionCode];
                                        return (
                                            <Grid item container xs={6} sm={6} md={2} lg={2} maxwidth="sm">
                                                {/* <Grid item sm={9} maxwidth="sm" style={{ marginTop: 5 }}></Grid> */}
                                                <Grid item sm={12} style={{ textAlign: 'center' }}>
                                                    <Comp state={true} />
                                                    <small style={{ fontWeight: 600 }}> {k.inspectionSheetCheckOptionDescription}</small>
                                                </Grid>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </Hidden>
                            <Hidden only={['md', 'lg', 'xl']}>
                                <Grid container spacing={1}>
                                    {state.buttonList?.map((k) => {
                                        const Comp = CheckButtons[k.inspectionSheetCheckOptionCode];
                                        return (
                                            <Grid item md={6} sm={6} xs={6} container maxwidth="sm">
                                                {/* <Grid item sm={9} maxwidth="sm" style={{ marginTop: 5 }}></Grid> */}
                                                <Grid item style={{ textAlign: 'center' }}>
                                                    <Comp state={true} />
                                                </Grid>
                                                &nbsp;&nbsp;
                                                <Grid item style={{ textAlign: 'center' }}>
                                                    <small style={{ fontWeight: 600 }}> {k.inspectionSheetCheckOptionDescription}</small>
                                                </Grid>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </Hidden>
                        </Paper>
                    </Grid>
                    {state.checksList?.map((m) => (
                        <Grid item xs={12} md={12} lg={12}>
                            <TableContainer component={Paper}>
                                <div
                                    style={{
                                        border: '1px solid rgba(0,0,0,0.2)'
                                    }}
                                >
                                    <h3 className={classes.title}>{m.inspectionSheetSectionDescription}</h3>
                                </div>
                                <Table aria-label="simple table" className={classes.paper}>
                                    <TableHead>
                                        {/* ....For xl and lg view... */}
                                        <Hidden only={['md', 'sm', 'xs']}>
                                            <TableRow>
                                                <StyledTableCell width={widths <= 879 ? '30%' : '30%'}>Check</StyledTableCell>
                                                <StyledTableCell align="left" width={widths <= 896 ? '18%' : '20%'}>
                                                    <PassAllbutton
                                                        state={m.state}
                                                        onClick={changeAllChildChecks('S', true, m.inspectionSheetSectionDescription)}
                                                        style={{ float: 'left', cursor: 'pointer', margin: '0px 10px 0px -10px' }}
                                                        // toolTipTitle={'Pass All'}
                                                    />
                                                    Action{' '}
                                                </StyledTableCell>
                                                <StyledTableCell align="center" width={'5%'}>
                                                    Upload
                                                </StyledTableCell>
                                                <StyledTableCell width={widths >= 1268 ? '40%' : '35%'}>Defect</StyledTableCell>
                                            </TableRow>
                                        </Hidden>
                                        {/* ....only for md view... */}
                                        <Hidden only={['sm', 'xs', 'lg', 'xl']}>
                                            <TableRow>
                                                <StyledTableCell width={'50%'}>Check</StyledTableCell>
                                                <StyledTableCell align="left" width={'50%'}>
                                                    <PassAllbutton
                                                        state={m.state}
                                                        onClick={changeAllChildChecks('S', true, m.inspectionSheetSectionDescription)}
                                                        style={{ float: 'left', cursor: 'pointer', margin: '0px 10px 0px -10px' }}
                                                        // toolTipTitle={'Pass All'}
                                                    />
                                                    Actions{' '}
                                                </StyledTableCell>
                                                {/* <StyledTableCell align="center" width={'5%'}>
                                                    Upload
                                                </StyledTableCell>
                                                <StyledTableCell width={widths >= 1268 ? '40%' : '35%'}>Defect</StyledTableCell> */}
                                            </TableRow>
                                        </Hidden>
                                        {/* ....for sm and xs view.... */}
                                        <Hidden only={['md', 'lg', 'xl']}>
                                            <TableRow>
                                                {/* <StyledTableCell width={widths <= 879 ? '30%' : '30%'}>Check</StyledTableCell> */}
                                                <StyledTableCell align="left" width={'100%'}>
                                                    <PassAllbutton
                                                        state={m.state}
                                                        onClick={changeAllChildChecks('S', true, m.inspectionSheetSectionDescription)}
                                                        style={{ float: 'left', cursor: 'pointer', margin: '0px 10px 0px -10px' }}
                                                        // toolTipTitle={'Pass All'}
                                                    />
                                                    Check{' '}
                                                </StyledTableCell>
                                                {/* <StyledTableCell align="center" width={'5%'}>
                                                    Upload
                                                </StyledTableCell>
                                                <StyledTableCell width={widths >= 1268 ? '40%' : '35%'}>Defect</StyledTableCell> */}
                                            </TableRow>
                                        </Hidden>
                                    </TableHead>
                                    <TableBody>
                                        {m.inspectionSheetChecksVM?.map((p) => {
                                            return (
                                                <>
                                                    <Hidden only={['md', 'sm', 'xs']}>
                                                        <StyledTableRow>
                                                            <StyledTableCell component="th">
                                                                {p.inspectionSheetCheckDescription}
                                                                <br />
                                                                {!p.inspectionSheetCheckResultOptionCode && state.errors?.message ? (
                                                                    <FormHelperText error>{state.errors?.message}</FormHelperText>
                                                                ) : null}
                                                            </StyledTableCell>

                                                            <StyledTableCell align="left">
                                                                <Grid container justify="flex-start" spacing={1}>
                                                                    {state.buttonList.map((b) => {
                                                                        const Comp = CheckButtons[b.inspectionSheetCheckOptionCode] || Button;
                                                                        return (
                                                                            <Grid item>
                                                                                <Comp
                                                                                    onClick={() =>
                                                                                        changeState(b.inspectionSheetCheckOptionCode, p.inspectionSheetCheckID)
                                                                                    }
                                                                                    state={
                                                                                        p.inspectionSheetCheckResultOptionCode ===
                                                                                        b.inspectionSheetCheckOptionCode
                                                                                    }
                                                                                />
                                                                            </Grid>
                                                                        );
                                                                    })}
                                                                </Grid>
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                <UploadWithBadge
                                                                    onClick={() => imageHandler(p.inspectionSheetCheckID)}
                                                                    count={p.basicCount}
                                                                    state={p.basicCount > 0}
                                                                />
                                                            </StyledTableCell>

                                                            <StyledTableCell align="left">
                                                                <InputText
                                                                    error={
                                                                        p.inspectionSheetCheckResultOptionCode === 'D' ||
                                                                        p.inspectionSheetCheckResultOptionCode === 'M'
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    style={
                                                                        {
                                                                            // border: `1px solid ${
                                                                            //     p.inspectionSheetCheckResultOptionCode === 'D' ||
                                                                            //     p.inspectionSheetCheckResultOptionCode === 'M'
                                                                            //         ? 'red'
                                                                            //         : 'lightgrey'
                                                                            // }`
                                                                        }
                                                                    }
                                                                    placeholder="Defect description"
                                                                    name="inspectionSheetCheckResultNotes"
                                                                    onChange={changeNotesState(p.inspectionSheetCheckID, m.inspectionSheetSectionDescription)}
                                                                    value={state.inspectionSheetCheckResultNotes}
                                                                />
                                                                {/* {p.inspectionSheetCheckResultOptionCode === 'D' || p.inspectionSheetCheckResultOptionCode === 'M' ? ( */}
                                                                <FormHelperText
                                                                    error
                                                                    style={{
                                                                        visibility:
                                                                            p.inspectionSheetCheckResultOptionCode === 'D' ||
                                                                            p.inspectionSheetCheckResultOptionCode === 'M'
                                                                                ? ''
                                                                                : 'hidden'
                                                                    }}
                                                                >
                                                                    {state.errors?.note}
                                                                </FormHelperText>
                                                                {/* ) : null} */}
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    </Hidden>
                                                    <Hidden only={['sm', 'xs', 'lg', 'xl']}>
                                                        <StyledTableRow>
                                                            <StyledTableCell component="th">
                                                                {p.inspectionSheetCheckDescription}
                                                                <br />
                                                                {!p.inspectionSheetCheckResultOptionCode && state.errors?.message ? (
                                                                    <FormHelperText error>{state.errors?.message}</FormHelperText>
                                                                ) : null}
                                                            </StyledTableCell>

                                                            <StyledTableCell align="left">
                                                                <Grid container justify="flex-start" spacing={1}>
                                                                    {state.buttonList.map((b) => {
                                                                        const Comp = CheckButtons[b.inspectionSheetCheckOptionCode] || Button;
                                                                        return (
                                                                            <Grid item>
                                                                                <Comp
                                                                                    onClick={() =>
                                                                                        changeState(b.inspectionSheetCheckOptionCode, p.inspectionSheetCheckID)
                                                                                    }
                                                                                    state={
                                                                                        p.inspectionSheetCheckResultOptionCode ===
                                                                                        b.inspectionSheetCheckOptionCode
                                                                                    }
                                                                                />
                                                                            </Grid>
                                                                        );
                                                                    })}
                                                                    <Grid item>
                                                                        <UploadWithBadge
                                                                            onClick={() => imageHandler(p.inspectionSheetCheckID)}
                                                                            count={p.basicCount}
                                                                            state={p.basicCount > 0}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <InputText
                                                                            error={
                                                                                p.inspectionSheetCheckResultOptionCode === 'D' ||
                                                                                p.inspectionSheetCheckResultOptionCode === 'M'
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            style={
                                                                                {
                                                                                    // border: `1px solid ${
                                                                                    //     p.inspectionSheetCheckResultOptionCode === 'D' ||
                                                                                    //     p.inspectionSheetCheckResultOptionCode === 'M'
                                                                                    //         ? 'red'
                                                                                    //         : 'lightgrey'
                                                                                    // }`
                                                                                }
                                                                            }
                                                                            placeholder="Defect description"
                                                                            name="inspectionSheetCheckResultNotes"
                                                                            onChange={changeNotesState(
                                                                                p.inspectionSheetCheckID,
                                                                                m.inspectionSheetSectionDescription
                                                                            )}
                                                                            value={state.inspectionSheetCheckResultNotes}
                                                                            // autoFocus={
                                                                            //     p.inspectionSheetCheckResultOptionCode === 'D' ||
                                                                            //     p.inspectionSheetCheckResultOptionCode === 'M'
                                                                            // }
                                                                        />
                                                                        {/* {p.inspectionSheetCheckResultOptionCode === 'D' || p.inspectionSheetCheckResultOptionCode === 'M' ? ( */}
                                                                        {/* <FormHelperText
                                                                            error
                                                                            style={{
                                                                                visibility:
                                                                                    p.inspectionSheetCheckResultOptionCode === 'D' ||
                                                                                    p.inspectionSheetCheckResultOptionCode === 'M'
                                                                                        ? ''
                                                                                        : 'hidden'
                                                                            }}
                                                                        >
                                                                            {state.errors?.note}
                                                                        </FormHelperText> */}
                                                                    </Grid>
                                                                </Grid>
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    </Hidden>
                                                    <Hidden only={['md', 'lg', 'xl']}>
                                                        <StyledTableRow>
                                                            <StyledTableCell component="th">
                                                                <Grid container>
                                                                    <Grid item xs={12} sm={6}>
                                                                        {p.inspectionSheetCheckDescription}
                                                                        <br />
                                                                        {!p.inspectionSheetCheckResultOptionCode && state.errors?.message ? (
                                                                            <FormHelperText error>{state.errors?.message}</FormHelperText>
                                                                        ) : null}
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={6}>
                                                                        <InputLabel shrink style={{ marginTop: '4px' }}>
                                                                            Action
                                                                        </InputLabel>
                                                                        <Grid container justify="flex-start" spacing={1}>
                                                                            {state.buttonList.map((b) => {
                                                                                const Comp = CheckButtons[b.inspectionSheetCheckOptionCode] || Button;
                                                                                return (
                                                                                    <Grid item>
                                                                                        <Comp
                                                                                            onClick={() =>
                                                                                                changeState(
                                                                                                    b.inspectionSheetCheckOptionCode,
                                                                                                    p.inspectionSheetCheckID
                                                                                                )
                                                                                            }
                                                                                            state={
                                                                                                p.inspectionSheetCheckResultOptionCode ===
                                                                                                b.inspectionSheetCheckOptionCode
                                                                                            }
                                                                                        />
                                                                                    </Grid>
                                                                                );
                                                                            })}
                                                                            <Grid item>
                                                                                <UploadWithBadge
                                                                                    onClick={() => imageHandler(p.inspectionSheetCheckID)}
                                                                                    count={p.basicCount}
                                                                                    state={p.basicCount > 0}
                                                                                />
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <InputLabel
                                                                            shrink
                                                                            error={
                                                                                p.inspectionSheetCheckResultOptionCode === 'D' ||
                                                                                p.inspectionSheetCheckResultOptionCode === 'M'
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            style={{ marginTop: '10px' }}
                                                                        >
                                                                            Defect
                                                                        </InputLabel>
                                                                        <InputText
                                                                            error={
                                                                                p.inspectionSheetCheckResultOptionCode === 'D' ||
                                                                                p.inspectionSheetCheckResultOptionCode === 'M'
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            placeholder="Defect description"
                                                                            name="inspectionSheetCheckResultNotes"
                                                                            onChange={changeNotesState(
                                                                                p.inspectionSheetCheckID,
                                                                                m.inspectionSheetSectionDescription
                                                                            )}
                                                                            value={state.inspectionSheetCheckResultNotes}
                                                                        />
                                                                        {/* {p.inspectionSheetCheckResultOptionCode === 'D' || p.inspectionSheetCheckResultOptionCode === 'M' ? ( */}
                                                                        <FormHelperText
                                                                            error
                                                                            style={{
                                                                                visibility:
                                                                                    p.inspectionSheetCheckResultOptionCode === 'D' ||
                                                                                    p.inspectionSheetCheckResultOptionCode === 'M'
                                                                                        ? ''
                                                                                        : 'hidden'
                                                                            }}
                                                                        >
                                                                            {state.errors?.note}
                                                                        </FormHelperText>
                                                                    </Grid>
                                                                </Grid>
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    </Hidden>
                                                </>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    ))}
                    {/* Tyres Checks. */}
                    <Grid item container direction="row" xs={12} sm={12}>
                        <Paper className={classes.paper}>
                            <Grid container xs={12}>
                                <h3 className={classes.headings}>Tyres Checks</h3>
                            </Grid>
                            <Axle state={state} handleInputs={handleInputs} classes={classes} handleInput={handleInput} />
                        </Paper>
                    </Grid>

                    <Grid item direction="row" xs={12} sm={12} md={12}>
                        <Paper className={classes.paper}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={3} md={3}>
                                            <InputLabel shrink style={{ marginTop: '11px' }}>
                                                Type of brake test
                                            </InputLabel>
                                        </Grid>
                                        <Grid item xs={9}>
                                            <RadioGroup
                                                row
                                                aria-label="vehicleInspectionBrakeTestTypeID"
                                                name="vehicleInspectionBrakeTestTypeID"
                                                value={+state.vehicleInspectionBrakeTestTypeID}
                                                onChange={handleInput}
                                            >
                                                <FormControlLabel value={1} control={<Radio />} label="Roller Test" />
                                                <FormControlLabel value={2} control={<Radio />} label="Decelerometer" />
                                                <FormControlLabel value={3} control={<Radio />} label="Road Test" />
                                            </RadioGroup>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            <InputLabel shrink>Brake Test Number</InputLabel>
                                            <InputText
                                                placeholder="Brake test number"
                                                name="vehicleInspectionBrakeTestNumber"
                                                value={state.vehicleInspectionBrakeTestNumber}
                                                onChange={handleInput}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6}>
                                            {/* <InputLabel shrink>Upload </InputLabel>
                                            <SecondaryButton> upload</SecondaryButton> */}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={6}>
                                    {' '}
                                    <InputLabel shrink style={{ marginTop: '4px' }}>
                                        Road Conditions
                                    </InputLabel>
                                    <InputText
                                        multiline
                                        rows={3}
                                        placeholder="Road conditions"
                                        name="vehicleInspectionRoadCondition"
                                        value={state.vehicleInspectionRoadCondition}
                                        onChange={handleInput}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item container direction="row" xs={12} sm={12} md={6} lg={6}>
                        <Grid item xs={12} md={12} lg={12}>
                            <TableContainer component={Paper}>
                                <div
                                    style={{
                                        border: '1px solid rgba(0,0,0,0.2)'
                                    }}
                                >
                                    <h3 className={classes.title}> Brake Performance</h3>
                                </div>
                                <Table aria-label="simple table" className={classes.paper}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width="15%">IM Reference</TableCell>
                                            <TableCell align="left" width="50%">
                                                Item Inspected
                                            </TableCell>
                                            <TableCell align="left" width="20%">
                                                Efficiency
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell> 71</TableCell>
                                            <TableCell> Service Brake Performance</TableCell>
                                            <TableCell>
                                                <InputText
                                                    placeholder="%"
                                                    name="vehicleInspectionServiceBrakePerformance"
                                                    value={state.vehicleInspectionServiceBrakePerformance}
                                                    onChange={handleInput}
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell> 72</TableCell>
                                            <TableCell> Secondary Brake Performance</TableCell>
                                            <TableCell>
                                                <InputText
                                                    placeholder="%"
                                                    name="vehicleInspectionSecondaryBrakePerformance"
                                                    value={state.vehicleInspectionSecondaryBrakePerformance}
                                                    onChange={handleInput}
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell> 73</TableCell>
                                            <TableCell> Parking Brake Performance</TableCell>
                                            <TableCell>
                                                <InputText
                                                    placeholder="%"
                                                    name="vehicleInspectionParkingBrakePerformance"
                                                    value={state.vehicleInspectionParkingBrakePerformance}
                                                    onChange={handleInput}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                    <Grid item container direction="row" xs={12} sm={12} md={6} lg={6}>
                        <TableContainer component={Paper}>
                            <div
                                style={{
                                    border: '1px solid rgba(0,0,0,0.2)'
                                }}
                            >
                                <h3 className={classes.title}>
                                    Road Test &nbsp;<small>(Brake Temperature Assessment)</small>
                                </h3>
                            </div>
                            <Table aria-label="simple table" className={classes.paper}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell width="20%">Side</TableCell>
                                        <TableCell align="left">Axle 1</TableCell>
                                        <TableCell align="left">Axle 2</TableCell>
                                        <TableCell align="left">Axle 3</TableCell>
                                        <TableCell align="left">Axle 4</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell component="th">
                                            <b>O/S</b>
                                        </TableCell>

                                        <TableCell>
                                            <InputText
                                                placeholder="°C"
                                                name="osBrakeTemperatureAxle"
                                                value={state.osBrakeTemperatureAxle}
                                                onChange={handleInputs(1)}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <InputText
                                                placeholder="°C"
                                                name="osBrakeTemperatureAxle"
                                                value={state.osBrakeTemperatureAxle}
                                                onChange={handleInputs(2)}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <InputText
                                                placeholder="°C"
                                                npm
                                                start
                                                name="osBrakeTemperatureAxle"
                                                value={state.osBrakeTemperatureAxle}
                                                onChange={handleInputs(3)}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <InputText
                                                placeholder="°C"
                                                name="osBrakeTemperatureAxle"
                                                value={state.osBrakeTemperatureAxle}
                                                onChange={handleInputs(4)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th">
                                            <b>N/S</b>
                                        </TableCell>

                                        <TableCell>
                                            <InputText
                                                placeholder="°C"
                                                name="nsBrakeTemperatureAxle"
                                                value={state.nsBrakeTemperatureAxle}
                                                onChange={handleInputs(1)}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <InputText
                                                placeholder="°C"
                                                name="nsBrakeTemperatureAxle"
                                                value={state.nsBrakeTemperatureAxle}
                                                onChange={handleInputs(2)}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <InputText
                                                placeholder="°C"
                                                name="nsBrakeTemperatureAxle"
                                                value={state.nsBrakeTemperatureAxle}
                                                onChange={handleInputs(3)}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <InputText
                                                placeholder="°C"
                                                name="nsBrakeTemperatureAxle"
                                                value={state.nsBrakeTemperatureAxle}
                                                onChange={handleInputs(4)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    <TableRow></TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>

                    {/* Sign Off */}
                    <Grid item xs={12} md={12}>
                        <Paper className={classes.paper}>
                            <Grid container xs={12}>
                                <h3 className={classes.headings}>Vehicle Inspector Sign-off</h3>
                            </Grid>
                            <Grid container spacing={1} justify="space-between">
                                <Grid item container spacing={2} xs={12} lg={6}>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <InputLabel shrink error>
                                            Vehicle Checker Comment
                                        </InputLabel>
                                        <InputText
                                            multiline
                                            rows={3}
                                            placeholder="Vehicle checker comment"
                                            name="vehicleInspectionInspectionComment"
                                            onChange={handleInput}
                                            value={state.vehicleInspectionInspectionComment}
                                        />
                                        <FormHelperText error>{state.errors?.vehicleInspectionInspectionComment}</FormHelperText>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid spacing={2} container direction="row">
                                            <Grid item xs={12} sm={12} md={12} lg={4}>
                                                <InputLabel shrink>Date Of Check</InputLabel>
                                                <DatePicker placeholder="Date Of Check" name="date" onChange={handleInput} value={state.date} disabled />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={4}>
                                                <InputLabel shrink>Check carried out by</InputLabel>
                                                <InputText
                                                    placeholder="Check carried out by"
                                                    name="vehicleDailyCheckCheckerName"
                                                    value={state.vehicleDailyCheckCheckerName}
                                                    onChange={handleInput}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={4} className="mandatory-fields">
                                                <InputLabel shrink>Current Mileage</InputLabel>
                                                <InputText
                                                    type="number"
                                                    placeholder="Current Mileage"
                                                    name="vehicleDailyCheckMileage"
                                                    onChange={handleInput}
                                                    value={state.vehicleDailyCheckMileage}
                                                />
                                                <FormHelperText error>{state.errors?.vehicleDailyCheckMileage}</FormHelperText>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item spacing={2} xs={12} lg={6}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} className="mandatory-fields">
                                        <div style={{ position: 'relative' }}>
                                            <InputLabel shrink>
                                                Signature{' '}
                                                {state.signAvailable ? (
                                                    <Tooltip title="Use default signature">
                                                        <BorderColorIcon onClick={useDefaultSig} style={{ cursor: 'pointer', margin: '0px 0px -5px 5px' }} />
                                                    </Tooltip>
                                                ) : null}
                                            </InputLabel>
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    right: 0
                                                }}
                                            >
                                                <Tooltip title="Clear Signature">
                                                    <DeleteIcon
                                                        onClick={clear}
                                                        style={{
                                                            cursor: 'pointer'
                                                        }}
                                                    />
                                                </Tooltip>
                                            </div>

                                            {state.imgtechniciansign ? (
                                                <div className="sigContainer">
                                                    <img
                                                        src={state.techniciansign}
                                                        alt="my signature"
                                                        style={{
                                                            display: 'block',
                                                            width: 500
                                                        }}
                                                    />
                                                </div>
                                            ) : (
                                                <div className="sigContainer">
                                                    <SignaturePad
                                                        ref={signCanvas}
                                                        canvasProps={{ className: 'sigPad' }}
                                                        // canvasProps={{ width: 770, height: 130, className: "sigCanvas" }}
                                                        onEnd={() => {
                                                            GetTechnicianSign();
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        <FormHelperText error>{state.errors?.techniciansign}</FormHelperText>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SecondaryButton onClick={handleConfirmPopup} isBtnLoader={btnLoader}>
                            Submit{' '}
                        </SecondaryButton>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SecondaryButton onClick={() => props.onClose()}>Cancel</SecondaryButton>
                    </Grid>
                </Grid>
            )}
            {state.showImageModal ? (
                // <DialogComp title="Upload Image" onClose={() => setState((st) => ({ ...st, show: false }))} maxWidth="lg">
                <ImageModal
                    images={state.selectedImages}
                    onImageChange={onImageChange}
                    submitImage={sumbitImage}
                    onClose={() => setState((st) => ({ ...st, showImageModal: false }))}
                    maxNumber={10}
                />
            ) : // </DialogComp>
            null}

            {state.showConfirmMessage ? (
                <DialogComp title={'Confirm Vehicle Inspector Sign-Off'} onClose={handleConfirmPopup} maxWidth="lg">
                    <ConfirmationModal
                        message={
                            'Please note that you will not be able to make any changes once submitted. Click the Confirm button to proceed or Cancel to abort.'
                        }
                        handleCancel={handleConfirmPopup}
                        handleSubmit={submitData}
                        title={'Confirm Vehicle Inspector Sign-Off'}
                    />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default VehicleDailyChecks;
