import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormHelperText, Typography } from '@material-ui/core';
import { getDepotById } from '../../../../Core/Services/InternalUser/ControllerService/depotsService';
import { FromLoader } from '../../../../Core/Loader/formLoader';
import { CircularProgress } from '@material-ui/core';
import { formatters } from '../../../../Core/Controls';

function DepotDetails(props) {
    const [state, setState] = useState({
        name: null,
        address1: null,
        address2: null,
        depotCounty: null,
        depotPostCode: null,
        depotTelephone: null,
        depotEmail: null,
        loader: false
    });

    useEffect(async () => {
        if (props.customerID) {
            let res = await getDepotById(props.customerID);
            const data = res.data.details[0];
            setState((st) => ({
                ...st,
                name: data.depotName,
                address1: data.depotAddress1,
                address2: data.depotAddress2,
                depotCounty: data.depotCounty,
                depotTown: data.depotTown,
                depotCountyCode: data.depotCountryCode,
                depotEmail: data.depotEmail,
                depotPostCode: data.depotPostCode,
                depotTelephone: data.depotTelephone,
                loader: true
            }));
        }
    }, []);

    return (
        state.loader ? (
            <Grid container direction="row" spacing={2} style={{ minWidth: '600px', minHeight: '300px' }}>
                <Grid xs={12} item className="typo">
                    <FormHelperText>Depot Name</FormHelperText>
                    <Typography>{state.name}</Typography>
                </Grid>
                <Grid xs={12} item className="typo">
                    <FormHelperText>Address</FormHelperText>
                    <Typography>{formatters.addressFormatter(state.address1, state.address2, state.depotCounty, state.depotPostCode)}</Typography>
                </Grid>
                <Grid xs={12} item className="typo">
                    <FormHelperText>Email</FormHelperText>
                    <Typography variant="button">{formatters.mailFormatter(state.depotEmail)}</Typography>
                </Grid>
                <Grid item xs={12} className="typo">
                    <FormHelperText>Phone</FormHelperText>
                    <Typography variant="button">{formatters.phoneNumberFormatter(state.depotTelephone)}</Typography>
                </Grid>
            </Grid>
        ) : (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '500px', height: '300px' }}>
                <CircularProgress style={{ margin: 'auto' }} />
            </div>
        )
    );
}

export default DepotDetails;
