import { FormHelperText, Grid, Typography, InputLabel } from '@material-ui/core';
import React, { useEffect, useState, useMemo } from 'react';
import { formatters } from '../../../../../Core/Controls';
import '../../../../commonStyle.scss';
import '../../controllerStyle.scss';
import { getJobDetailsById } from '../../../../../Core/Services/InternalUser/ControllerService/jobDetailsService';
import Alert from '@material-ui/lab/Alert';
import InfoIcon from '@material-ui/icons/Info';
import '../../Notes/NotesandSticky.scss';
import { useHistory } from 'react-router-dom';

const alertBoxColorCode = {
    AA: 'warning',
    EP: 'warning',
    JP: 'warning',
    AU: 'error',
    JQ: 'error',
    FR: 'error',
    JD: 'error',
    JR: 'error',
    CA: 'success',
    JC: 'success',
    JA: 'success'
};

const JobDetail = (props) => {
    const history = useHistory();

    const [jobDetails, setJobDetails] = useState({
        jobDescription: '',
        jobDepotID: '',
        jobServiceAgentID: '',
        vehicleMake: '',
        vehicleModel: '',
        jobRegNumber: ''
    });

    useEffect(async () => {
        let res = await getJobDetailsById(props.scheduleJobID);
        let res1 = res.data.list;
        let data = res1?.length > 0 ? res.data?.list[0] : res.data?.list;
        if (data) {
            setJobDetails((st) => ({
                ...st,
                jobAgentOrderNo: data.jobAgentOrderNo,
                jobOrderDate: formatters.DateOnlyFormatter(data.jobDate),
                jobOrderTotal: data.orderTotal,
                jobCustomerOrderNo: data.jobCustomerOrderNo || '',
                jobEstimatedCost: data.jobEstimatedCost || '',
                jobProformaInvNet: data.jobProformaInvNet || '',
                jobLimit: data.jobLimit || '',
                jobAuthorityRef: data.jobAuthorityRef || '',
                jobMileageDate: data.jobMileageDate || '',
                jobMileage: data.jobMileage || '',
                jobDepotID: data.jobDepotID || '',
                jobServiceAgentID: data.jobServiceAgentID || '',
                jobVehicleID: data.jobVehicleID,
                jobRegNumber: data.jobRegNumber,
                vehicleMake: data.vehicleMake,
                vehicleModel: data.vehicleModel,
                vehicleJobStatus: data.vehicleJobStatus,
                customerName: data.customerCompanyName,
                jobDescription: data.jobDescription,
                jobStatusID: data.jobStatusID,
                jobOrderNo: data.jobOrderNo
            }));
        }
    }, [props.refresherFlag]);
    const getMakeModel =
        jobDetails.vehicleMake || jobDetails.vehicleModel
            ? ` (${jobDetails.vehicleMake ? jobDetails.vehicleMake : ''} ${jobDetails.vehicleModel ? jobDetails.vehicleModel : ''})`
            : '';
    const handleEditRegClick = (val) => {
        history.push({
            pathname: `/controller/editVehicle/${props.VehicleID}`,
            search: ''
        });
    };
    return (
        <div className="controller-screen">
            <Grid container spacing={0} className="mt_10">
                <Grid item xs={12} lg={12}>
                    <Grid item container xs={12} justify="flex-start" style={{ backgroundColor: '#f5f9f9', padding: '0 5px' }}>
                        {jobDetails?.jobOrderNo ? (
                            <Grid item xs={12} sm={6} md={2}>
                                <FormHelperText shrink>Final Order No </FormHelperText>
                                <Typography variant="button">
                                    {jobDetails?.jobOrderNo || ''}({jobDetails?.jobAgentOrderNo})
                                </Typography>
                            </Grid>
                        ) : (
                            <Grid item xs={12} sm={6} md={2}>
                                <FormHelperText shrink>Job Reference</FormHelperText>
                                <Typography variant="button">{jobDetails?.jobAgentOrderNo || ''}</Typography>
                            </Grid>
                        )}
                        <Grid item xs={12} sm={6} md={2}>
                            <FormHelperText shrink>Order Date</FormHelperText>
                            <Typography variant="button">{jobDetails.jobOrderDate}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <FormHelperText shrink>Customer</FormHelperText>
                            <Typography variant="button">{jobDetails.customerName}</Typography>
                        </Grid>
                        {/* <Grid item xs={12} sm={3}>
                            <FormHelperText shrink>Order Type</FormHelperText>
                            <Typography variant="button">Test Ask</Typography>
                        </Grid> */}
                        <Grid item xs={12} sm={6} md={3}>
                            <FormHelperText shrink>Vehicle Detail</FormHelperText>
                            <Typography variant="button">
                                <span onClick={handleEditRegClick} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                                    {jobDetails.jobRegNumber}
                                </span>
                                {getMakeModel}
                            </Typography>
                        </Grid>
                        {/* <Grid item xs={12} sm={6}>
                            <FormHelperText shrink>Job Status</FormHelperText>
                            <Typography variant="button">{jobDetails.vehicleJobStatus}</Typography>
                        </Grid> */}
                        <Grid item xs={12} sm={12} md={2}>
                            {' '}
                            <div style={{ width: '100%', textAlign: 'right' }}>
                                <FormHelperText style={{ textAlign: 'end' }}>Total</FormHelperText>
                                <Typography variant="button" style={{ fontSize: 19, fontWeight: 'bold', color: 'black' }}>
                                    £{jobDetails.jobOrderTotal?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                </Typography>
                            </div>
                        </Grid>
                        {/* <Grid item xs={6} sm={3}>
                            <FormHelperText shrink>Reg Number</FormHelperText>
                            <Typography variant="button">{jobDetails.jobRegNumber}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <FormHelperText shrink>Make</FormHelperText>
                            <Typography variant="button">{jobDetails.vehicleMake}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <FormHelperText shrink>Model</FormHelperText>
                            <Typography variant="button">{jobDetails.vehicleModel}</Typography>
                        </Grid> */}
                    </Grid>
                </Grid>
                {/* <Grid item xs={12} style={{ marginTop: 3 }}>
                    <Alert icon={<InfoIcon fontSize="inherit" />} severity="warning" className="alert-items">
                        {jobDetails.jobDescription}
                    </Alert>
                </Grid> */}
                <Grid item xs={12} style={{ marginTop: 3 }}>
                    <Grid container spacing={1} className="alert-container">
                        <Grid item xs={12} sm={6} md={4}>
                            <Alert icon={false} severity={alertBoxColorCode[jobDetails.jobStatusID]} className="alert-items">
                                <span style={{ display: 'block', fontSize: '12px' }}>Status</span>
                                <span style={{ fontWeight: 'bold' }}>{jobDetails.vehicleJobStatus}</span>
                            </Alert>
                        </Grid>
                        <Grid item xs={12} sm={6} md={8}>
                            <Alert icon={<InfoIcon fontSize="inherit" />} severity="warning" className="alert-items">
                                <span style={{ display: 'block', fontSize: '12px' }}>Job Description</span>
                                <span style={{ fontWeight: 'bold' }}>{jobDetails.jobDescription}</span>
                            </Alert>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};
export default JobDetail;
