import React, { useEffect, useState, useMemo } from 'react';
import { EditButton, BreadCrumbs, DataGrid, formatters, SecondaryButton } from '../../../Core/Controls';
import { CustomChip } from '../../../Core/Controls';
import AddNewEnquiries from './AddNewEnquiries';
import Grid from '@material-ui/core/Grid';
import { getAllEnquiry } from '../../../Core/Services/InternalUser/ControllerService/EnquiriService';
import GijgoGrid from '../../../Core/Controls/GijgoGrid';
import { useHistory } from 'react-router-dom';

const crumbsObj = { 1: 'New Enquiries', 2: 'Live Enquiries', 3: 'Successful Enquiries', 4: 'Lost Enquiries' };
const setEnquiry = { 1: 'new_enquiry', 2: 'live_enquiry', 3: 'successful_enquiry', 4: 'lost_enquiry' };
const basecolumns = [
    { key: 'action', name: 'Action', width: '5%', addFileUploaderIcon: true },
    { key: 'leadCompanyName', name: 'Company Name' },
    { key: 'leadMainContactName', name: 'Contact Name' },
    { key: 'leadMainContactPhone', name: 'Contact Phone' },
    { key: 'leadMainContactEmail', name: 'Contact Email' },
    { key: 'leadCity', name: 'City' },
    { key: 'leadReferrerName', name: 'Referrer Name' }
];

const EnquiriesScreen = (props) => {
    const [state, setState] = useState({
        modalToggle: false,
        EnquiriesID: null,
        enquiriesList: [],
        showLoader: true,
        isReload: null
    });

    const crumbs = [
        { name: 'Home', path: '/' },
        { name: crumbsObj[props.id], active: true }
    ];

    let history = useHistory();

    const pullEnquiriesData = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    const actionButton = (data, row) => {
        return (
            <div className="Enquiries-btn-update" style={{ textAlign: 'center' }}>
                <EditButton onClick={() => handleEnquiriesUpdate(row?.leadID)} toolTipTitle="Edit Enquiries" />
            </div>
        );
    };

    const columns = useMemo(() => {
        const tempCols = [...basecolumns];
        let actionCol = tempCols.find((e) => e.key === 'action');
        if (actionCol) {
            actionCol.formatter = actionButton;
        }
        return tempCols;
    }, []);

    const handleEnquiriesUpdate = (params) => {
        history.push(`edit/${params}?${setEnquiry[props.id]}`);
    };

    const handleEnquiriesAdd = () => {
        history.push(`add/0`);
    };

    const closeAddDialog = (res) => {
        setState((state) => ({ ...state, modalToggle: false }));
        res && pullEnquiriesData();
    };

    let getUrl = `Leads?LeadStatusID=${props.id}`;

    return (
        <div className="screen">
            {state.modalToggle ? (
                <AddNewEnquiries onClose={closeAddDialog} />
            ) : (
                <>
                    {!props.VehicleID && <BreadCrumbs crumbs={crumbs} />}

                    <GijgoGrid
                        dataConverter={(res) => ({
                            records: res.data.list,
                            total: res.data.total
                        })}
                        columns={columns}
                        getUrl={getUrl}
                        fileUploaderConfigName="Enquiries"
                        idFieldName="EnquiriesID"
                        btnTitle={'Add Enquiry'}
                        onBtnClick={handleEnquiriesAdd}
                        isReload={state.isReload}
                    />
                </>
            )}
        </div>
    );
};

export default EnquiriesScreen;
