import React, { useEffect, useState, useMemo } from 'react';
import {
    EditButton,
    BreadCrumbs,
    MandatoryField,
    Multiselect,
    SimpleField,
    SecondaryButton,
    formatters,
    SelectBox,
    SecondaryCheckbox,
    YesButton,
    NoButton
} from '../../../Core/Controls';
import DataGrid from '../../../Core/Controls/Grid';
import '../../commonStyle.scss';
import { SketchPicker } from 'react-color';
import { useToast } from '../../../Core/Hooks/useToast';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import { InputLabel, Typography } from '@material-ui/core';
import { getPortalSettings, addPortalSettings } from '../../../Core/Services/Admin/Settings';
import { useHistory } from 'react-router-dom';
import '../../commonStyle.scss';

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Settings', path: '/', active: true }
];

const Settings = (props) => {
    const { showToastError, showToastSuccess } = useToast();

    const [state, setState] = useState({
        Title: '',
        CallUs: '',
        OpeningTimes: '',
        EmailUs: '',
        Technician: '',
        SignOff: '',
        CustomerSignOff: '',
        Driver: '',
        DefectManager: '',
        HeaderTextColorCode: '',
        HeaderBackgroundColorCode: '',
        MenubarTextColorCode: '',
        MenubarBackgroundColorCode: '',
        productLogo: '',
        labelLogo: '',
        bannerLogo: '',
        navigationMenuActive: '',
        refreshTime: '',
        bannerImage: '',
        documentsLogo: '',
        Id: ''
    });

    const history = useHistory();

    const pullSettingsAndUpdateState = async () => {
        let res = await getPortalSettings();

        const data = res.data.details[0];
        if (res.success) {
            setState((st) => ({
                ...st,
                Id: data.portalSettingId,
                Title: data.portalSettingTitle,
                CallUs: data.portalSettingCallUs,
                OpeningTimes: data.portalSettingOpeningTime,
                EmailUs: data.portalSettingEmailUs,
                Technician: data.portalSettingTechnicianDeclaration,
                SignOff: data.portalSettingSignOffManagerDeclaration,
                Driver: data.portalSettingDriverDeclaration,
                DefectManager: data.portalSettingDefectManagerDeclaration,
                CustomerSignOff: data.portalSettingSignOffCustomerDeclaration,
                HeaderTextColorCode: data.portalHeaderTextColour,
                HeaderBackgroundColorCode: data.portalHeaderBackgroundColour,
                MenubarTextColorCode: data.portalMenuBarTextColour,
                MenubarBackgroundColorCode: data.portalMenuBarBackgroundColour,
                navigationMenuActive: data.portalSettingDisplayTopNavCounters,
                refreshTime: data.portalSettingDashboardRefreshTime,
                productLogo: data.portalSettingMainLogo,
                bannerLogo: data.portalSettingBanner,
                labelLogo: data.portalSettingAdditionalLogo,
                bannerImage: data.portalSettingBannerImage,
                documentsLogo: data.portalSettingDocumentsLogo
            }));
        }
        if (!res.success) {
            showToastError(res.message);
        }
    };

    let handleImage = (bannerLogo) => (event) => {
        let reader = new FileReader();
        reader.onload = (e) => {
            const { result } = e.target;
            setState((st) => ({
                ...st,
                [bannerLogo]: result
            }));
        };
        reader.readAsDataURL(event.target.files[0]);
    };

    const clearImage = (name) => (e) => {
        setState((st) => ({ ...st, [name]: null }));
    };

    const modelgridStyle = {
        formatterImageContainer: { width: '100%', textAlign: 'left' },
        formatterImageProductContainer: { maxHeight: 50, maxHeight: 173, width: '100%', textAlign: 'left', backgroundColor: state.MenubarBackgroundColorCode },
        //  formatterImage: { width: '40%', maxHeight: 60, backgroundSize: 'cover' },
        formatterImage: { width: 120, maxHeight: 60, backgroundSize: 'cover' }
    };

    const handleClick = (name, value) => {
        setState((st) => {
            const nst = { ...st, [name]: value };
            return nst;
        });
    };

    const handleFieldChange = (e) => {
        const { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    const handleSubmit = async () => {
        const data = {
            portalSettingId: state.Id,
            portalSettingCallUs: state.CallUs,
            portalSettingOpeningTime: state.OpeningTimes,
            portalSettingEmailUs: state.EmailUs,
            portalSettingTitle: state.Title,
            portalHeaderBackgroundColour: state.HeaderBackgroundColorCode,
            portalHeaderTextColour: state.HeaderTextColorCode,
            portalMenuBarBackgroundColour: state.MenubarBackgroundColorCode,
            portalMenuBarTextColour: state.MenubarTextColorCode,
            portalSettingBannerImage: state.bannerImage,
            portalSettingTechnicianDeclaration: state.Technician,
            portalSettingSignOffManagerDeclaration: state.SignOff,
            portalSettingDriverDeclaration: state.Driver,
            portalSettingDefectManagerDeclaration: state.DefectManager,
            portalSettingSignOffCustomerDeclaration: state.CustomerSignOff,
            portalSettingDisplayTopNavCounters: state.navigationMenuActive,
            portalSettingMainLogo: state.productLogo,
            portalSettingBanner: state.bannerLogo,
            portalSettingAdditionalLogo: state.labelLogo,
            portalSettingDocumentsLogo: state.documentsLogo,
            portalSettingDashboardRefreshTime: state.refreshTime
        };

        let res = await addPortalSettings(data);
        if (res.success) {
            showToastSuccess('Porting setting saved successfully');
            history.goBack();
        } else {
            showToastError(res.message);
        }
    };

    useEffect(() => {
        pullSettingsAndUpdateState();
    }, []);

    return (
        <div className="screen">
            <BreadCrumbs crumbs={crumbs} />
            <Grid container spacing={2}>
                <Grid item xs={12} md={3} sm={6}>
                    <SimpleField inputLabel="Portal Title" placeholder="Portal Title" value={state.Title} onChange={handleFieldChange} name="Title" />
                </Grid>
                <Grid item xs={12} md={3} sm={6}>
                    <SimpleField inputLabel="Call Us" placeholder="Number" value={state.CallUs} onChange={handleFieldChange} name="CallUs" />
                </Grid>
                <Grid item xs={12} md={3} sm={6}>
                    <SimpleField
                        inputLabel="Opening Times"
                        placeholder="Opening Times"
                        value={state.OpeningTimes}
                        onChange={handleFieldChange}
                        name="OpeningTimes"
                    />
                </Grid>
                <Grid item xs={12} md={3} sm={6}>
                    <SimpleField inputLabel="Email us" placeholder="Email us" value={state.EmailUs} onChange={handleFieldChange} name="EmailUs" />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Typography variant="h5" color="secondary">
                        Declarations
                    </Typography>
                    <hr />
                </Grid>
                <Grid item xs={12}>
                    <SimpleField inputLabel="Technician" placeholder="Technician" value={state.Technician} onChange={handleFieldChange} name="Technician" />
                </Grid>
                <Grid item xs={12}>
                    <SimpleField
                        inputLabel="Sign Off Manager"
                        placeholder="Sign Off Manager"
                        value={state.SignOff}
                        onChange={handleFieldChange}
                        name="SignOff"
                    />
                </Grid>
                <Grid item xs={12}>
                    <SimpleField inputLabel="Driver" placeholder="Driver" value={state.Driver} onChange={handleFieldChange} name="Driver" />
                </Grid>
                <Grid item xs={12}>
                    <SimpleField
                        inputLabel="Defect Manager"
                        placeholder="Defect Manager"
                        value={state.DefectManager}
                        onChange={handleFieldChange}
                        name="DefectManager"
                    />
                </Grid>
                <Grid item xs={12}>
                    <SimpleField
                        inputLabel="Customer Sign Off with Outstanding Defects"
                        placeholder="Customer Sign Off with Outstanding Defects"
                        value={state.CustomerSignOff}
                        onChange={handleFieldChange}
                        name="CustomerSignOff"
                    />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Typography variant="h5" color="secondary">
                        Colours
                    </Typography>
                    <hr />
                </Grid>
                <Grid container spacing={0} justify="space-around">
                    <Grid item container spacing={4} xs={12} md={3} sm={6}>
                        <Grid item xs={12} sm={12}>
                            <FormLabel component="legend">
                                <b>Header Text</b>
                            </FormLabel>
                        </Grid>
                        <Grid item xs={12} md={3} sm={6}>
                            <SketchPicker
                                color={state.HeaderTextColorCode}
                                onChange={(updatedColor) =>
                                    setState((state) => ({
                                        ...state,
                                        HeaderTextColorCode: updatedColor.hex
                                    }))
                                }
                            />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={4} xs={12} md={3} sm={6}>
                        <Grid item xs={12} sm={12}>
                            <FormLabel component="legend">
                                <b>Header Background</b>
                            </FormLabel>
                        </Grid>
                        <Grid item xs={12} md={3} sm={6}>
                            <SketchPicker
                                color={state.HeaderBackgroundColorCode}
                                onChange={(updatedColor) =>
                                    setState((state) => ({
                                        ...state,
                                        HeaderBackgroundColorCode: updatedColor.hex
                                    }))
                                }
                            />
                        </Grid>
                    </Grid>
                    <Grid item container spacing={4} xs={12} md={3} sm={6}>
                        <Grid item xs={12} sm={12}>
                            <FormLabel component="legend">
                                <b>Menu bar Text</b>
                            </FormLabel>
                        </Grid>
                        <Grid item xs={12} md={3} sm={6}>
                            <SketchPicker
                                color={state.MenubarTextColorCode}
                                onChange={(updatedColor) =>
                                    setState((state) => ({
                                        ...state,
                                        MenubarTextColorCode: updatedColor.hex
                                    }))
                                }
                            />
                        </Grid>
                    </Grid>

                    <Grid item container spacing={4} xs={12} md={3} sm={6}>
                        <Grid item xs={12} sm={12}>
                            <FormLabel component="legend">
                                <b>Menu bar Background</b>
                            </FormLabel>
                        </Grid>
                        <Grid item xs={12} md={3} sm={6}>
                            <SketchPicker
                                color={state.MenubarBackgroundColorCode}
                                onChange={(updatedColor) =>
                                    setState((state) => ({
                                        ...state,
                                        MenubarBackgroundColorCode: updatedColor.hex
                                    }))
                                }
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="flex-start" style={{ padding: '15px' }}>
                    <Grid container spacing={2} item xs={12} sm={12} md={7} lg={4}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Typography variant="h5" color="secondary">
                                Logos
                            </Typography>
                            <hr />
                        </Grid>
                        <Grid item xs={12} lg={6} sm={6} style={{ textAlign: 'left' }}>
                            <InputLabel shrink>Product Logo</InputLabel>
                            {state.productLogo ? (
                                <SecondaryButton onClick={clearImage('productLogo')}>Remove Logo</SecondaryButton>
                            ) : (
                                <input type="file" name="productLogo" onChange={handleImage('productLogo')} accept="image/*" />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6}>
                            <InputLabel shrink>{'\u{2800}'}</InputLabel>
                            <img
                                src={state.productLogo?.includes('base64') ? state.productLogo : 'data:image/png;base64,' + state.productLogo}
                                alt={'Product Logo'}
                                style={modelgridStyle.formatterImageProductContainer}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6} sm={6} style={{ textAlign: 'left' }}>
                            <InputLabel shrink>White Label Logo</InputLabel>
                            {state.labelLogo ? (
                                <SecondaryButton onClick={clearImage('labelLogo')}>Remove Logo</SecondaryButton>
                            ) : (
                                <input type="file" name="labelLogo" onChange={handleImage('labelLogo')} accept="image/*" />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6} style={modelgridStyle.formatterImageContainer}>
                            <InputLabel shrink>{'\u{2800}'}</InputLabel>
                            <img
                                src={state.labelLogo?.includes('base64') ? state.labelLogo : 'data:image/png;base64,' + state.labelLogo}
                                alt={'logo'}
                                style={modelgridStyle.formatterImage}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6} sm={6} style={{ textAlign: 'left' }}>
                            <InputLabel shrink style={{ paddingRight: '60px' }}>
                                Banner Image
                            </InputLabel>
                            {state.bannerLogo ? (
                                <SecondaryButton onClick={clearImage('bannerLogo')}>Remove Logo</SecondaryButton>
                            ) : (
                                <input type="file" name="bannerLogo" onChange={handleImage('bannerLogo')} accept="image/*" />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6} style={modelgridStyle.formatterImageContainer}>
                            <InputLabel shrink>{'\u{2800}'}</InputLabel>
                            <img
                                src={state.bannerLogo?.includes('base64') ? state.bannerLogo : 'data:image/png;base64,' + state.bannerLogo}
                                alt={'logo'}
                                style={modelgridStyle.formatterImage}
                            />
                        </Grid>
                        <Grid item xs={12} lg={6} sm={6} style={{ textAlign: 'left' }}>
                            <InputLabel shrink style={{ paddingRight: '60px' }}>
                                Documents Logo
                            </InputLabel>
                            {state.documentsLogo ? (
                                <SecondaryButton onClick={clearImage('documentsLogo')}>Remove Logo</SecondaryButton>
                            ) : (
                                <input type="file" name="documentsLogo" onChange={handleImage('documentsLogo')} accept="image/*" />
                            )}
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6} style={modelgridStyle.formatterImageContainer}>
                            <InputLabel shrink>{'\u{2800}'}</InputLabel>
                            <img
                                src={state.documentsLogo?.includes('base64') ? state.documentsLogo : 'data:image/png;base64,' + state.documentsLogo}
                                alt={'logo'}
                                style={modelgridStyle.formatterImage}
                            />
                        </Grid>
                    </Grid>

                    <Grid container item xs={12} sm={12} md={5} lg={8}>
                        <Grid item xs={12} md={12} lg={12} style={{ top: 0 }}>
                            <Typography variant="h5" color="secondary">
                                Settings
                            </Typography>
                            <hr />
                        </Grid>
                        <Grid xs={12} container spacing={1}>
                            <Grid item xs={12}>
                                Display Top Navigation Counter?
                            </Grid>
                            <Grid item xs={4} sm={3}>
                                <YesButton
                                    state={state.navigationMenuActive}
                                    name="navigationMenuActive"
                                    onClick={() => handleClick('navigationMenuActive', true)}
                                />
                            </Grid>
                            <Grid item xs={4} sm={3}>
                                <NoButton
                                    state={state.navigationMenuActive}
                                    name="navigationMenuActive"
                                    onClick={() => handleClick('navigationMenuActive', false)}
                                />
                            </Grid>
                            <Grid item sm={12} xs={12} />
                            <Grid Item xs={4} sm={6}>
                                <SimpleField
                                    inputLabel="Dashboard Refresh Time"
                                    placeholder="Dashboard Refresh Time"
                                    value={state.refreshTime}
                                    onChange={handleFieldChange}
                                    name="refreshTime"
                                    type="number"
                                />
                            </Grid>
                            <Grid item xs={8} sm={6} className="submit_btn" style={{ marginTop: -3 }}>
                                <InputLabel shrink>{'\u{2800}'}</InputLabel>
                                <SecondaryButton onClick={handleSubmit}> {props.customerID ? 'Save' : 'Save'}</SecondaryButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default Settings;
