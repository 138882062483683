import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormHelperText, InputLabel } from '@material-ui/core';
import { useToast } from '../../../../../Core/Hooks/useToast';
import { Postcode, SimpleField, SelectBox, SecondaryButton, MandatoryField } from '../../../../../Core/Controls';
import { postEndUser, getEndUserByID } from '../../../../../Core/Services/InternalUser/ControllerService/endUserService';

const AddEditEndUser = (props) => {
    const { showToastSuccess, showToastError } = useToast();
    const [btnLoader, setBtnLoader] = useState(false);
    const [state, setState] = useState({
        endUserCountryCode: 'GB',
        endUserID: null,
        endUserCustomerID: props.customerID,
        errors: {}
    });
    const setVal = (s) => {
        setState((st) => ({
            ...st,
            endUserAddress1: s.addressLine1,
            endUserAddress2: s.addressLine2,
            endUserTown: s.town,
            endUserCounty: s.county,
            endUserPostCode: s.postCode
        }));
    };

    const countryList = [
        { id: 'GB', name: 'GB' },
        { id: 'IE', name: 'IE' }
    ];

    const inputChange = (e) => {
        const { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    useEffect(async () => {
        if (props.endUserId) {
            let res = await getEndUserByID(props.endUserId);
            let data = res.data.list[0];
            if (res.success) {
                setState((st) => ({
                    ...st,
                    endUserID: data.endUserID || null,
                    endUserCustomerID: data.endUserCustomerID || null,
                    endUserName: data.endUserName || null,
                    endUserAddress1: data.endUserAddress1 || null,
                    endUserAddress2: data.endUserAddress2 || null,
                    endUserTown: data.endUserTown || null,
                    endUserCounty: data.endUserCounty || null,
                    endUserCountryCode: data.endUserCountryCode || null,
                    endUserPostCode: data.endUserPostCode || null,
                    endUserContactName: data.endUserContactName || null,
                    endUserContactPhone: data.endUserContactPhone || null,
                    endUserContactEmail: data.endUserContactEmail || null
                }));
            }
        }
    }, []);

    let validation = () => {
        let errors = {};
        let isValid = true;
        let { endUserName } = state;
        if (!endUserName) {
            errors.endUserName = 'Name is required';
            isValid = false;
        }
        setState((st) => ({
            ...st,
            errors
        }));
        return isValid;
    };

    const handleSubmit = async () => {
        if (validation()) {
            setBtnLoader(true);
            let data = {
                endUserID: state.endUserID || null,
                endUserCustomerID: state.endUserCustomerID || null,
                endUserName: state.endUserName || null,
                endUserAddress1: state.endUserAddress1 || null,
                endUserAddress2: state.endUserAddress2 || null,
                endUserTown: state.endUserTown || null,
                endUserCounty: state.endUserCounty || null,
                endUserCountryCode: state.endUserCountryCode || null,
                endUserPostCode: state.endUserPostCode || null,
                endUserContactName: state.endUserContactName || null,
                endUserContactPhone: state.endUserContactPhone || null,
                endUserContactEmail: state.endUserContactEmail || null
            };
            let res = await postEndUser(data);
            if (res.success) {
                showToastSuccess(`End User ${props.endUserId ? `Updated` : `Added`} Successfully`);
                props.onClose();
            } else {
                showToastError(`Fail to ${props.endUserId ? `Updated` : `Added`} End User`);
            }
            setBtnLoader(false);
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={4} sm={6} lg={12} className="mandatory-fields">
                <MandatoryField
                    inputLabel="Name"
                    placeholder="Name"
                    value={state.endUserName}
                    onChange={inputChange}
                    name="endUserName"
                    errors={state.errors.endUserName}
                />
            </Grid>
            <Grid item xs={12} md={4} sm={6} lg={4}>
                <Postcode value={state.endUserPostCode} onChange={inputChange} name="endUserPostCode" setVal={setVal} />
            </Grid>
            <Grid item xs={12} md={4} sm={6} lg={4}>
                <SimpleField inputLabel="Address1" placeholder="Address1" value={state.endUserAddress1} onChange={inputChange} name="endUserAddress1" />
            </Grid>
            <Grid item xs={12} md={4} sm={6} lg={4}>
                <SimpleField inputLabel="Address2" placeholder="Address2" value={state.endUserAddress2} onChange={inputChange} name="endUserAddress2" />
            </Grid>
            <Grid item xs={12} md={4} sm={6} lg={4}>
                <SimpleField inputLabel="City" placeholder="City" value={state.endUserTown} onChange={inputChange} name="endUserTown" />
            </Grid>
            <Grid item xs={12} md={4} sm={6} lg={4}>
                <SimpleField inputLabel="County" placeholder="County" value={state.endUserCounty} onChange={inputChange} name="endUserCounty" />
            </Grid>{' '}
            <Grid item xs={12} md={4} sm={6} lg={4}>
                <InputLabel shrink>Country Code</InputLabel>
                <SelectBox name="endUserCountryCode" value={state.endUserCountryCode} onChange={inputChange} List={countryList} />
            </Grid>
            <Grid item xs={12} md={4} sm={6} lg={4}>
                <SimpleField
                    inputLabel="Contact Name"
                    placeholder="Contact Name"
                    value={state.endUserContactName}
                    onChange={inputChange}
                    name="endUserContactName"
                />
            </Grid>
            <Grid item xs={12} md={4} sm={6} lg={4}>
                <SimpleField
                    type="number"
                    inputLabel="Contact Phone"
                    placeholder="Contact Phone"
                    value={state.endUserContactPhone}
                    onChange={inputChange}
                    name="endUserContactPhone"
                />
            </Grid>{' '}
            <Grid item xs={12} md={4} sm={6} lg={4}>
                <SimpleField
                    type="email"
                    inputLabel="Contact Email"
                    placeholder="Contact Email"
                    value={state.endUserContactEmail}
                    onChange={inputChange}
                    name="endUserContactEmail"
                />
            </Grid>
            <Grid item container xs={12} spacing={1} justify="space-between" style={{ marginTop: 8 }}>
                <Grid item xs={6}>
                    <SecondaryButton onClick={handleSubmit} className="submit_btn" isBtnLoader={btnLoader}>
                        {props.endUserId ? 'Update' : 'Submit'}
                    </SecondaryButton>
                </Grid>
                <Grid item xs={6}>
                    <SecondaryButton className="submit_btn" onClick={props.onClose}>
                        Cancel
                    </SecondaryButton>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default AddEditEndUser;
