import React, { useState, useEffect, useMemo } from 'react';
import DataGrid from '../../../../Core/Controls/Grid/index';
import { EditButton, formatters, BreadCrumbs } from '../../../../Core/Controls';
import DialogBox from '../../../InternalUser/Controllers/Vehicles/ScheduleVehicle/scheduleVehicleEdit';
import ScheduleNotes from '../../../InternalUser/Controllers/Vehicles/ScheduleVehicle/scheduleVehicleNotes';
import ScheduleBookingStatus from '../../../InternalUser/Controllers/Vehicles/ScheduleVehicle/scheduleBookingStatus';
import ScheduleBookingSection from '../../../InternalUser/Controllers/Vehicles/ScheduleVehicle/scheduleBookingSection';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import NoteIcon from '@material-ui/icons/Note';
import EventIcon from '@material-ui/icons/Event';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import WorkIcon from '@material-ui/icons/Work';
import '../../../commonStyle.scss';
import '../../../InternalUser/Controllers/Vehicles/ScheduleVehicle/scheduleVehicleStyle.scss';
import { Link, useHistory } from 'react-router-dom';
import { useLoginInfo } from '../../../../Core/Hooks/useLoginInfo';

import { getAllVehicleSchedules } from '../../../../Core/Services/InternalUser/Maintenance/scheduleService';
import { CustomIconButton } from '../../../../Core/Controls/Inputs/Buttons/ButtonModal';
import moment from 'moment';
import GijgoGrid from '../../../../Core/Controls/GijgoGrid';
import { AppStorage } from '../../../../Core/Basic/storage-service';
import ReactDOM from 'react-dom';
import ServiceAgentDetails from '../../../InternalUser/Controllers/DetailsModal/serviceAgent';
import PostAddIcon from '@material-ui/icons/PostAdd';
import RequiredDocumentsModal from '../../../ServiceAgent/Schedules/requiredDocumentsModal';

const crumbsServiceAgent = [
    { name: 'Home', path: '/' },
    { name: 'Schedules', active: true }
];

const baseCrumbs = [
    { name: 'Home', path: '/' },
    // { name: "Maintenance", path: "/", active: true },
    { name: 'Schedules', active: true }
];

const basecolumns = [
    { key: 'action', name: 'Action', width: 105, addFileUploaderIcon: true },
    { key: 'vehicleRegNumber', name: 'Reg Number', width: 120 },
    { key: 'scheduleEventName', name: 'Event', width: 140 },
    {
        key: 'scheduleDateDue',
        name: 'Due',
        width: 100
    },
    {
        key: 'scheduleRequestedBookingDate',
        name: 'Requested',
        formatter: formatters.Date_DDMMYYYY,
        width: 90
        // width: 150
    },
    {
        key: 'bookingDate',
        name: 'Booked',
        formatter: formatters.Date_DDMMYYYY,
        width: 120
    },
    // {
    //     key: 'schedulePrepDate',
    //     name: 'Prep Date',
    //     formatter: formatters.Date_DDMMYYYY
    // },
    {
        key: 'scheduleCompletionDate',
        name: 'Completed',
        formatter: formatters.Date_DDMMYYYY,
        width: 140
    },
    { key: 'depotName', name: 'Depot' },
    {
        key: 'agentName',
        name: 'Agent'
    },
    { key: 'scheduleBookingStatus', name: 'Status' }
];

const CustomerAllVehicleSchedules = (props) => {
    let checkhaveAccess = AppStorage.getAllowAcces();
    const [state, setState] = useState({
        open: false,
        showLoader: true,
        selectedRowData: {},
        isAdd: true,
        isReload: null
    });

    const { userRoles } = useLoginInfo();
    let history = useHistory();
    const isServiceAgent = userRoles.includes('serviceagent');
    const pos = isServiceAgent ? 's' : 'm';

    const [openDialog, setOpenDialog] = useState({});
    useEffect(async () => {
        pullApiData();
    }, [props.match.params?.all]);

    const pullApiData = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
        // let res = await getAllVehicleSchedules(props.match.params?.all);
        // setState((st) => ({
        //     ...st,
        //     rows: [...(res.data.schedules || [])],
        //     showLoader: false
        // }));
    };

    const handleEditRegClick = (val) => {
        history.push({
            pathname: `/controller/editVehicle/${val.scheduleVehicleID}`,
            search: '',
            state: { fileCount: val.fileCount }
        });
    };
    const renderEdit = (ev, row) => {
        return (
            <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'space-between' }}>
                {row?.scheduleBookingStatusID == 5 && checkhaveAccess?.CanViewDocuments && (
                    <CustomIconButton
                        icon={PostAddIcon}
                        className="modal-btn-update "
                        onClick={() => {
                            checkButton(row, 'showRequiredDocumentsModal');
                        }}
                        toolTipTitle="Required Documents"
                    />
                )}
                {row?.displayNotes === 'Y' && (
                    <CustomIconButton
                        icon={NoteIcon}
                        color="primary"
                        className="modal-btn-update "
                        onClick={() => {
                            checkButton(row, 'notes');
                        }}
                        toolTipTitle="Notes"
                    />
                )}

                {row?.displayBookingRequest === 'Y' && (
                    <CustomIconButton
                        icon={EventIcon}
                        className="modal-btn-update "
                        onClick={() => {
                            checkButton(row, 'status');
                        }}
                        toolTipTitle="Update Booking Request"
                    />
                )}
            </div>
        );
    };

    const dateFormatter = (date) => {
        return date ? moment(date).format('DD/MM/YYYY') : '--:--';
    };

    const handelRowCick = (row, name) => {
        setState((st) => ({
            ...st,
            selectedRowData: row,
            [name]: true
        }));
    };

    const renderRegNumberEdit = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div style={{ cursor: 'pointer', fontWeight: 'bold' }} onClick={() => handleEditRegClick(row)}>
                {row.vehicleRegNumber}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const renderAgentName = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handelRowCick(row, 'showSaModal')} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.agentName}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const dueDateFormatter = ({ row, column }) => {
        let textColor = 'black';
        let textWight = '';
        const dueDate = row.scheduleDateDue;
        const completionDate = row.scheduleCompletionDate;

        const dtDueIn = moment(dueDate);
        const daysPast = moment().diff(dtDueIn, 'days');

        if (!completionDate && daysPast > 0) {
            textColor = 'red';
            textWight = 'bold';
        }
        return `<div style="color: ${textColor}; font-weight: ${textWight};">${dateFormatter(dueDate)}</div>`;
    };

    const columns = useMemo(() => {
        const tempCols = [...basecolumns];
        let actionCol = tempCols.find((e) => e.key === 'action');
        let dateDueCol = tempCols.find((e) => e.key === 'scheduleDateDue');
        let regNumberCol = tempCols.find((element) => element.key === 'vehicleRegNumber');
        let agentCol = tempCols.find((element) => element.key === 'agentName');
        if (actionCol) {
            actionCol.formatter = renderEdit;
        }
        if (dateDueCol) {
            dateDueCol.formatter = dueDateFormatter;
        }
        if (regNumberCol) {
            regNumberCol.formatter = renderRegNumberEdit;
        }
        if (agentCol) {
            agentCol.formatter = renderAgentName;
        }
        return tempCols;
    }, []);

    const crumbsMaintenance = useMemo(() => {
        const tempCrums = [...baseCrumbs];
        if (props.match.params?.all === 'M') {
            tempCrums.push({ name: 'MOTs' });
        } else if (props.match.params?.all === 'I') {
            tempCrums.push({ name: 'Overdue Inspections' });
        } else if (props.match.params?.all === 'S') {
            tempCrums.push({ name: 'Overdue Services' });
        } else if (props.match.params?.all === 'O') {
            tempCrums.push({ name: 'Overdue Other Schedules' });
        } else {
            tempCrums.push({ name: 'All Schedules', active: true });
        }
        return tempCrums;
    }, [props.match.params?.all]);

    const addBtnClicked = () => {
        setState((st) => ({
            ...st,
            open: true,
            isAdd: true,
            selectedRowData: {}
        }));
    };
    const handleClose = (res) => {
        setState((st) => ({
            ...st,
            open: false,
            selectedRowData: {}
        }));
        res && pullApiData();
    };
    const closeTheDialog = (res) => {
        setOpenDialog({});
        res && pullApiData();
    };

    const checkButton = (row, label) => {
        setOpenDialog({ [label]: true });
        setState((st) => ({
            ...st,
            selectedRowData: row,
            scheduleVehicleID: row.scheduleVehicleID
        }));
    };

    // const handleCloseModal = () => {
    //     setState((st) => ({
    //         ...st,
    //         showSaModal: false
    //     }));
    // };

    const handleCloseModal = (res) => {
        setState((st) => ({
            ...st,
            open: false,
            showSaModal: false,
            showCustomerModal: false,
            showDepotModal: false
        }));
        res && pullApiData();
    };

    let getUrl = `Schedules?mode=${props.match.params?.all}`;

    return (
        <div className="screen">
            <BreadCrumbs crumbs={isServiceAgent ? crumbsServiceAgent : crumbsMaintenance} />
            {/* <DataGrid
                rows={state.rows}
                columns={columns}
                enableSearch={true}
                loadingData={state.showLoader}
                idFieldName="scheduleID" 
                fileUploaderConfigName="ScheduleDocumentsRequired"
                paginate
                offset={210} 
            /> */}

            <GijgoGrid
                columns={columns}
                idFieldName={`${checkhaveAccess?.CanViewSchedules && checkhaveAccess?.CanViewDocuments ? 'scheduleID' : null}`}
                fileUploaderConfigName={`${checkhaveAccess?.CanViewSchedules && checkhaveAccess?.CanViewDocuments ? 'ScheduleDocumentsRequired' : null}`}
                dataConverter={(res) => ({
                    records: res.data.schedules,
                    total: res.data.total
                })}
                isReload={state.isReload}
                getUrl={getUrl}
                downloadBtnName="All Schedules"
            />

            {state.open ? (
                <DialogComp
                    open={state.open}
                    onClose={() => {
                        handleClose(false);
                    }}
                    title={state.isAdd ? 'Add Schedule' : 'Edit Schedule'}
                    maxWidth="sm"
                    name="addEdit"
                >
                    <DialogBox
                        data={state}
                        isAdd={state.isAdd}
                        scheduleId={state.selectedRowData.scheduleID}
                        vehicleId={state.scheduleVehicleID}
                        onClose={() => {
                            handleClose(true);
                        }}
                    />
                </DialogComp>
            ) : null}
            {openDialog.notes ? (
                <DialogComp title="Schedule Notes" maxWidth="md" onClose={closeTheDialog}>
                    <ScheduleNotes vehicleId={state.scheduleVehicleID} scheduleId={state.selectedRowData.scheduleID} onClose={closeTheDialog} />
                </DialogComp>
            ) : null}
            {openDialog.status ? (
                <DialogComp title="Customer Booking Request" maxWidth="md" onClose={closeTheDialog}>
                    <ScheduleBookingStatus vehicleId={state.scheduleVehicleID} scheduleId={state.selectedRowData.scheduleID} onClose={closeTheDialog} />
                </DialogComp>
            ) : null}
            {openDialog.section ? (
                <DialogComp title="Booking Section" maxWidth="md" onClose={closeTheDialog}>
                    <ScheduleBookingSection vehicleId={state.scheduleVehicleID} scheduleId={state.selectedRowData.scheduleID} onClose={closeTheDialog} />
                </DialogComp>
            ) : null}
            {state.showSaModal ? (
                <DialogComp open={true} onClose={handleCloseModal} title={'Service Agent Details'}>
                    <ServiceAgentDetails serviceAgentID={state.selectedRowData.serviceAgentID} onFormSubmit={handleCloseModal} />
                </DialogComp>
            ) : null}
            {openDialog.showRequiredDocumentsModal ? (
                <DialogComp open={true} onClose={() => closeTheDialog(false)} title={'Required Documents'} maxWidth="sm" fullWidth>
                    <RequiredDocumentsModal rowData={state.selectedRowData} onFormSubmit={closeTheDialog}/>
                </DialogComp>
            ) : null}
        </div>
    );
};

export default CustomerAllVehicleSchedules;
