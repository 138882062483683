import { FormHelperText, Grid, InputLabel } from '@material-ui/core';
import React, { useState } from 'react';
import { DatePicker, formatters, SecondaryButton } from '../../../../Core/Controls';
import '../../../commonStyle.scss';
import { postScheduleReceived } from '../../../../Core/Services/InternalUser/ControllerService/scheduleVehicleService';
import { useEffect } from 'react';
import { useToast } from '../../../../Core/Hooks/useToast';

const ScheduleSetReceivedModal = (props) => {
    const [state, setState] = useState({
        dateReceived: ''
    });
    const [error, setError] = useState({});
    const { showToastSuccess, showToastError } = useToast();

    const validate = () => {
        let errors = {};
        let isValid = true;
        const { dateReceived } = state;
        if (!/[^\s]/.test(dateReceived)) {
            errors.dateReceived = 'Received date is required';
            isValid = false;
        }
        setError(errors);
        return isValid;
    };

    const handleData = async () => {
        if (validate()) {
            let res = await postScheduleReceived({
                dateReceived: state.dateReceived,
                scheduleDocID: props?.val?.scheduleDocID
            });
            if (res.success) {
                showToastSuccess(res.message);
            } else {
                showToastError(res.message);
            }
            props.onFormSubmit(true);
        }
    };

    const inputChange = (event) => {
        const { name, value } = event.target;
        setState((state) => ({
            ...state,
            [name]: value
        }));
    };

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                    <InputLabel required shrink>
                        Required Date
                    </InputLabel>
                    <DatePicker disabled={true} name="dateReceived" value={formatters.DateFormatter(props?.val?.dateRequired)} />
                    <FormHelperText error>{error.dateReceived}</FormHelperText>
                </Grid>
                <Grid item xs={12} md={6} className="mandatory-fields">
                    <InputLabel required shrink>
                        Received Date
                    </InputLabel>
                    <DatePicker name="dateReceived" value={state.dateReceived} onChange={inputChange} />
                    <FormHelperText error>{error.dateReceived}</FormHelperText>
                </Grid>

                <Grid xs={6} item className="submit_btn">
                    <SecondaryButton onClick={handleData}>Mark as received</SecondaryButton>
                </Grid>
                <Grid xs={6} item className="submit_btn">
                    <SecondaryButton onClick={() => props.onFormSubmit(false)}>Cancel</SecondaryButton>
                </Grid>
            </Grid>
        </>
    );
};

export default ScheduleSetReceivedModal;
