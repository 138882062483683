import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { Table, TableBody, TableContainer, TableRow, Paper, TableCell, Typography, FormHelperText, InputLabel } from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { Link } from 'react-router-dom';
import { InputText, SecondaryButton, SelectBox, CustomChip, MandatoryField, DatePicker } from '../../../../../Core/Controls';
import { postDriverMilege, getDriverList } from '../../../../../Core/Services/InternalUser/Vehicle/driverInfoService';
import { useToast } from '../../../../../Core/Hooks/useToast';

const gridStyle = {
    title: {
        color: '#f50057'
    },
    header: {
        border: '1px solid rgba(0,0,0,0.2)'
    },
    tbHead: {
        padding: '5px 0px 6px 8px'
    },
    tbContent: {
        margin: '8px 0px 14px 0px;'
    }
};

export default function DriverVehicleModal(props) {
    const [state, setState] = useState({
        customerID: null,
        driverList: [],
        vehicleDriverHistoryID: null,
        historyList: null,
        vehicleDriverHistoryDriverID: null
    });
    const [error, setError] = useState({});

    useEffect(async () => {
        if (props.vehicleID) {
            let res = await getDriverList(props.vehicleID);
            if (res.success) {
                setState((st) => ({
                    ...st,
                    historyList: res.data.vehicleDriverHistory[0]?.vehicleDriverHistoryID,
                    driverList: res.data.details.map((m) => ({
                        id: m.driverID,
                        name: m.driver
                    }))
                }));
            }
        }
    }, []);

    const { showToastSuccess, showToastError } = useToast();

    const validate = () => {
        let errors = {};
        let isValid = true;

        if (!state.vehicleDriverHistoryDriverID) {
            errors.driver = 'Driver is Required';
            isValid = false;
        }
        if (!state.date) {
            errors.date = 'Date Change is required';
            isValid = false;
        }
        if (!state.mileage) {
            errors.mileage = 'Mileage is required';
            isValid = false;
        }
        setError(errors);
        return isValid;
    };

    const inputChange = (event) => {
        const { name, value } = event.target;
        setState((state) => ({
            ...state,
            [name]: value
        }));
    };
    const handelSubmit = async () => {
        if (validate()) {
            let data = {
                vehicleDriverHistoryID: state.historyList ? state.historyList : null,
                vehicleDriverHistoryVehicleID: props.vehicleID,
                vehicleDriverHistoryDriverID: state.vehicleDriverHistoryDriverID,
                vehicleDriverHistoryDateAssigned: state.date,
                vehicleDriverHistoryMileageAssigned: state.mileage
            };
            let submutRes = await postDriverMilege(data);
            if (submutRes.success) {
                showToastSuccess('Vehicle Driver Added Successfully');
                props.onClose();
            } else {
                showToastError(submutRes.message);
                // props.onClose();
            }
        }
    };
    return (
        <div>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <InputLabel required shrink error>
                        Driver
                    </InputLabel>
                    <SelectBox name="vehicleDriverHistoryDriverID" value={state.vehicleDriverHistoryDriverID} onChange={inputChange} List={state.driverList} />
                    <FormHelperText error>{error.driver}</FormHelperText>
                </Grid>
                <Grid item xs={6}>
                    <InputLabel required shrink error>
                        Date of Change
                    </InputLabel>
                    <DatePicker inputLabel="Date Of Change" placeholder="date of change" name="date" value={state.date} onChange={inputChange} />
                    <FormHelperText error>{error.date}</FormHelperText>
                </Grid>
                <Grid item xs={6} className="mandatory-fields">
                    <MandatoryField
                        inputLabel="Mileage of Change"
                        placeholder="Odometer Reading"
                        type="number"
                        name="mileage"
                        value={state.mileage}
                        onChange={inputChange}
                        errors={error.mileage}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography> If this driver is assigned to another vehicle, they will be removed from that vehicle </Typography>
                </Grid>
                <Grid item xs={12} style={{ marginBottom: 20 }}>
                    <SecondaryButton onClick={handelSubmit}>Link Driver</SecondaryButton>
                </Grid>
            </Grid>
        </div>
    );
}
