import React, { useState, useEffect, useMemo } from 'react';
import EditDriverDailyChecks from '../../../Components/InternalUser/Controllers/Vehicles/DriverDailyChecks/editDriverChecks'; //'./editDriverChecks';
import { BreadCrumbs, formatters, EditButton } from '../../../Core/Controls';
import '../../../Components/commonStyle.scss';
import { DataGrid } from '../../../Core/Controls';
import { getChecksByQuery } from '../../../Core/Services/InternalUser/DailyChecksInternalUser/dailyChecksInternalUserService';
import GijgoGrid from '../../../Core/Controls/GijgoGrid';
import { getVechicleIDByRegNO } from '../../../Core/Services/InternalUser/ServiceAgent/breakdownService';
import DriverChecks from '../../../Components/Driver/Maintenance/driverCheck';
import { useHistory } from 'react-router-dom';
import CustomerDetails from '../Controllers/DetailsModal/customerDetails';
import DepotDetails from '../Controllers/DetailsModal/depotDetails';
import ReactDOM from 'react-dom';
import DialogComp from '../../../Core/Dialog/DialogComp';

const basecolumns = [
    { key: 'action', name: 'Action', width: 80 },
    { key: 'checked', name: 'Checked', formatter: formatters.Date_DDMMMYYYY_HHMM, width: 150 },
    { key: 'regNumber', name: 'Reg Number', width: 90 },
    { key: 'result', name: 'Result', width: 55 },
    // { key: 'checkType', name: 'Check Type' },
    { key: 'status', name: 'Status' },
    { key: 'driverName', name: 'Driver Name' },
    // { key: 'mileage', name: 'Mileage' },
    { key: 'checkerName', name: 'Checker Name' },
    { key: 'checkerComment', name: 'Checker Comment', width: 220 },
    { key: 'customer', name: 'Customer', width: 100 },
    { key: 'depotName', name: 'Depot' },
    { key: 'displayName', name: 'Created By' }
].map((x) => {
    return { ...x, resizable: true };
});

const DailyCheckInternalUser = (props) => {
    const [state, setState] = useState({
        open: false,
        showLoader: true,
        selectedRowData: {},
        title: '',
        btnFlag: '',
        rows: [],
        editChecksShow: false,
        show: false,
        showAddCheck: false,
        filterVal: ''
    });

    let history = useHistory();

    useEffect(async () => {
        if (state.filterVal.length > 4) {
            let resBrekDown = await getVechicleIDByRegNO(state.filterVal);
            setState((st) => {
                let newSt = { ...st };
                if (resBrekDown.data?.list) {
                    newSt.show = resBrekDown.success;
                    newSt.filterVehicleID = resBrekDown.data?.list[0].vehicleID;
                    newSt.regNumber = state.filterVal;
                } else {
                    newSt.show = false;
                }
                return newSt;
            });
        } else {
            setState((st) => ({ ...st, show: false }));
        }
        pullApiData();
    }, [state.filterVal]);

    const editChecksScreen = (id) => {
        setState((st) => ({
            ...st,
            editChecksShow: true,
            checksID: id.checkID
        }));
    };

    const editClose = () => {
        setState((st) => ({
            ...st,
            editChecksShow: false,
            showAddCheck: false,
            show: false,
            filterVal: ''
        }));
        pullApiData();
    };

    const actionButton = (data, row) => {
        return (
            <div style={{ textAlign: 'center' }}>
                <EditButton onClick={() => editChecksScreen(row)} toolTipTitle="Edit Check" />
            </div>
        );
    };

    const handelRowCick = (row, name) => {
        setState((st) => ({
            ...st,
            selectedRowData: row,
            [name]: true
        }));
    };

    const renderRegNumberEdit = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handleEditRegClick(row)} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.regNumber}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const renderCustomerName = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handelRowCick(row, 'showCustomerModal')} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.customer}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const renderDepotName = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handelRowCick(row, 'showDepotModal')} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.depotName}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const handleEditRegClick = (val) => {
        history.push({
            pathname: `/controller/editVehicle/${val.vehicleID}`,
            search: '',
            state: { fileCount: val.fileCount }
        });
    };

    const handleClose = () => {
        setState((st) => ({
            ...st,
            open: false,
            showCustomerModal: false,
            showDepotModal: false
        }));
    };

    const columns = useMemo(() => {
        const tempCols = [...basecolumns];
        let actionCol = tempCols.find((e) => e.key === 'action');
        let customerNameCol = tempCols.find((element) => element.key === 'customer');
        let depotNameCol = tempCols.find((element) => element.key === 'depotName');
        let regNumberCol = tempCols.find((element) => element.key === 'regNumber');
        if (regNumberCol) {
            regNumberCol.formatter = renderRegNumberEdit;
        }
        if (customerNameCol) {
            customerNameCol.formatter = renderCustomerName;
        }
        if (depotNameCol) {
            depotNameCol.formatter = renderDepotName;
        }
        if (actionCol) {
            actionCol.formatter = actionButton;
        }
        return tempCols;
    }, []);

    const pullApiData = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    const addCheck = (val) => {
        setState((st) => ({
            ...st,
            showAddCheck: true
        }));
    };

    let dataUrl = `DriverDailyCheck/VehicleDailyChecksList?Result=${props.resultCode}&Status=${props.statusCode}`;

    const crumbs = useMemo(() => {
        return [
            { name: 'Home', path: '/' },
            { name: 'Daily Checks', active: true },
            { name: props.fromPage, active: true, onClick: editClose }
        ];
    }, [props.fromPage]);

    const inputChange = (e) => {
        const value = e.target.value;
        setState((st) => ({
            ...st,
            filterVal: value
        }));
    };

    useEffect(async () => {}, []);

    const gridBtnTitle = state.show ? 'Add Checks' : '';

    return (
        <div>
            {state.showAddCheck ? (
                <div>
                    <DriverChecks filterVehicleID={state.filterVehicleID} regNumber={state.regNumber} onClose={editClose} />
                </div>
            ) : (
                <div className="screen">
                    {props.role === 'DriveRole' ? (
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', alignContent: 'center', position: 'relative', top: 0 }}>
                            <div style={{ width: 'auto' }}>
                                <BreadCrumbs crumbs={crumbs} />
                            </div>
                            &nbsp;&nbsp;
                            <div style={{ width: 'auto' }}>
                                <input type="text" onChange={inputChange} placeholder="Enter Reg Number" value={state.filterVal} style={{ padding: 5 }} />
                            </div>
                        </div>
                    ) : (
                        <BreadCrumbs crumbs={crumbs} />
                    )}
                    {state.editChecksShow ? (
                        <EditDriverDailyChecks onClose={editClose} chekcsID={state.checksID} />
                    ) : (
                        <GijgoGrid
                            dataConverter={(res) => ({
                                records: res.data.list,
                                total: res.data.total
                            })}
                            columns={columns}
                            getUrl={dataUrl}
                            btnTitle={gridBtnTitle}
                            onBtnClick={addCheck}
                            isReload={state.isReload}
                        />
                    )}
                    {state.showCustomerModal ? (
                        <DialogComp open={true} onClose={handleClose} title={'Customer Details'}>
                            <CustomerDetails customerID={state.selectedRowData.customerID} onFormSubmit={handleClose} />
                        </DialogComp>
                    ) : null}
                    {state.showDepotModal ? (
                        <DialogComp open={true} onClose={handleClose} title={'Depot Details'}>
                            <DepotDetails customerID={state.selectedRowData.vehicleDepotID} onFormSubmit={handleClose} />
                        </DialogComp>
                    ) : null}
                </div>
            )}
        </div>
    );
};

export default DailyCheckInternalUser;
