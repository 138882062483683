import React from 'react';
import Grid from '@material-ui/core/Grid';
import { FormHelperText, InputLabel } from '@material-ui/core';
import { InputText, SelectBox, SecondaryCheckbox, YesButton, NoButton, SimpleField } from '../../../../Core/Controls';
import Paper from '@material-ui/core/Paper';
import '../controllerStyle.scss';

const ScheduleDetails = (props) => {
    const { depotFields, scheduleDetailsChange, handleCheckbox, scheduleFields, handleActive } = props;

    return (
        <>
            <Grid item xs={12}>
                <span>Schedule Planner Options</span>
            </Grid>
            <Grid item xs={12} md={12} lg={12} className="grid-paper-shadow">
                <Paper className="controller-container" style={{ padding: '8px' }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={3} className="checkbox-font">
                            <SecondaryCheckbox
                                checked={scheduleFields.mergeSchedules}
                                name="mergeSchedules"
                                onChange={handleCheckbox}
                                label="Merge Schedule?"
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} className="checkbox-font">
                            <SecondaryCheckbox
                                checked={scheduleFields.displayISOWeek}
                                name="displayISOWeek"
                                onChange={handleCheckbox}
                                label="Display ISO Week Number?"
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} className="checkbox-font">
                            <SecondaryCheckbox
                                checked={scheduleFields.addPaperworkReceived}
                                name="addPaperworkReceived"
                                onChange={handleCheckbox}
                                label="Add Paperwork Received?"
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} className="checkbox-font">
                            <SecondaryCheckbox
                                checked={scheduleFields.trafficStyleColourCoding}
                                name="trafficStyleColourCoding"
                                onChange={handleCheckbox}
                                label="Traffic Style Colour Coding ?"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <SimpleField
                                inputLabel="Max Schedule Merge Range"
                                placeholder="Weeks"
                                type="number"
                                value={scheduleFields.mergeRange}
                                onChange={scheduleDetailsChange}
                                name="mergeRange"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <SimpleField
                                inputLabel="Defect Notification Email"
                                placeholder="Defect Notification Email"
                                value={scheduleFields.depotDefectNotificationEmail}
                                onChange={scheduleDetailsChange}
                                name="depotDefectNotificationEmail"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <SimpleField
                                inputLabel="Schedule Planner Email"
                                placeholder="Planner Email"
                                type="email"
                                value={scheduleFields.depotSchedulePlannerEmail || ''}
                                onChange={scheduleDetailsChange}
                                name="depotSchedulePlannerEmail"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputLabel shrink>Schedule Booking Mode</InputLabel>
                            <SelectBox
                                name="depotScheduleBookingMode"
                                value={scheduleFields.depotScheduleBookingMode}
                                onChange={scheduleDetailsChange}
                                List={depotFields.scheduleBookingModeList}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <SimpleField
                                inputLabel="Third Party Planner Email Address"
                                placeholder="Third Party Planner Email Address"
                                name="depotThirdPartyPlannerEmailAddress"
                                value={scheduleFields.depotThirdPartyPlannerEmailAddress}
                                onChange={scheduleDetailsChange}
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <span>Booking Preference</span>
            </Grid>
            <Grid item xs={12} md={12} lg={12} className="grid-paper-shadow">
                <Paper className="controller-container">
                    <Grid container spacing={1} item>
                        <Grid item xs={6} sm={3} md={1} className="checkbox-font">
                            <SecondaryCheckbox checked={scheduleFields.sunday} name="sunday" onChange={handleCheckbox} label="Sunday" />
                        </Grid>
                        <Grid item xs={6} sm={3} md={1} className="checkbox-font">
                            <SecondaryCheckbox checked={scheduleFields.monday} name="monday" onChange={handleCheckbox} label="Monday" />
                        </Grid>
                        <Grid item xs={6} sm={3} md={1} className="checkbox-font">
                            <SecondaryCheckbox checked={scheduleFields.tuesday} name="tuesday" onChange={handleCheckbox} label="Tuesday" />
                        </Grid>
                        <Grid item xs={6} sm={3} md={1} className="checkbox-font">
                            <SecondaryCheckbox checked={scheduleFields.wednesday} name="wednesday" onChange={handleCheckbox} label="Wednesday" />
                        </Grid>
                        <Grid item xs={6} sm={3} md={1} className="checkbox-font">
                            <SecondaryCheckbox checked={scheduleFields.thursday} name="thursday" onChange={handleCheckbox} label="Thursday" />
                        </Grid>
                        <Grid item xs={6} sm={3} md={1} className="checkbox-font">
                            <SecondaryCheckbox checked={scheduleFields.friday} name="friday" onChange={handleCheckbox} label="Friday" />
                        </Grid>
                        <Grid item xs={6} sm={3} md={1} className="checkbox-font">
                            <SecondaryCheckbox checked={scheduleFields.saturday} name="saturday" onChange={handleCheckbox} label="Saturday" />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <span>Contact Details</span>
            </Grid>
            <Grid item xs={12} md={12} lg={12} className="grid-paper-shadow">
                <Paper className="controller-container">
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={4}>
                            <SimpleField
                                inputLabel="Additional Planning Emails"
                                placeholder="Additional Planning Emails"
                                type="email"
                                value={scheduleFields.depotAdditionalPlanningEmails}
                                onChange={scheduleDetailsChange}
                                name="depotAdditionalPlanningEmails"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <SimpleField
                                inputLabel="CPC Holder Email"
                                placeholder="CPC Holder Email"
                                type="email"
                                value={scheduleFields.cPCHolderEmail}
                                onChange={scheduleDetailsChange}
                                name="cPCHolderEmail"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <SimpleField
                                inputLabel="CPC Holder Name"
                                placeholder="CPC Holder Name"
                                value={scheduleFields.cPCHolderName}
                                onChange={scheduleDetailsChange}
                                name="cPCHolderName"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <SimpleField
                                inputLabel="Secondary Email"
                                placeholder="Secondary Email"
                                type="email"
                                value={scheduleFields.secondaryEmail}
                                onChange={scheduleDetailsChange}
                                name="secondaryEmail"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <SimpleField
                                inputLabel="Contact Mobile"
                                placeholder="Contact Mobile"
                                value={scheduleFields.depotContactMobile}
                                onChange={scheduleDetailsChange}
                                name="depotContactMobile"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <SimpleField
                                inputLabel="Secondary Contact Number"
                                placeholder="Secondary Contact Number"
                                value={scheduleFields.depotSecondaryContact}
                                onChange={scheduleDetailsChange}
                                name="depotSecondaryContact"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <SimpleField
                                inputLabel="PCN Email"
                                placeholder="PCN Email"
                                type="email"
                                value={scheduleFields.depotPCNEmail}
                                onChange={scheduleDetailsChange}
                                name="depotPCNEmail"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <SimpleField
                                inputLabel="Account Name"
                                placeholder="Account Name"
                                value={scheduleFields.depotAccountName}
                                onChange={scheduleDetailsChange}
                                name="depotAccountName"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <SimpleField
                                inputLabel="Account Email"
                                placeholder="Account Email"
                                type="email"
                                value={scheduleFields.depotAccountEmail}
                                onChange={scheduleDetailsChange}
                                name="depotAccountEmail"
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <span>Notification Update</span>
            </Grid>
            <Grid item xs={12} md={12} lg={12} className="grid-paper-shadow">
                <Paper className="controller-container">
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={4}>
                            <SimpleField
                                inputLabel="Breakdown Notification Email"
                                placeholder="Breakdown Notification Email"
                                type="email"
                                value={scheduleFields.depotBreakdownNotificationEmail}
                                onChange={scheduleDetailsChange}
                                name="depotBreakdownNotificationEmail"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <SimpleField
                                inputLabel="Vehicle Status Update Email"
                                placeholder="Vehicle Status Update Email"
                                type="email"
                                value={scheduleFields.depotVehicleStatusUpdateEmail}
                                onChange={scheduleDetailsChange}
                                name="depotVehicleStatusUpdateEmail"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <SimpleField
                                inputLabel="Timed Job Status Update Email"
                                placeholder=" Timed Job Status Update Email"
                                type="email"
                                value={scheduleFields.depotTimedVehicleStatusUpdateEmail}
                                onChange={scheduleDetailsChange}
                                name="depotTimedVehicleStatusUpdateEmail"
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <span>Active</span>
            </Grid>
            <Grid item xs={12} md={12} lg={12} className="grid-paper-shadow">
                <Paper className="controller-container">
                    <Grid item container spacing={2}>
                        <Grid item xs={1}>
                            <YesButton state={depotFields.depotNotInUse} onClick={() => handleActive(true)}></YesButton>
                        </Grid>
                        <Grid item xs={1}>
                            <NoButton state={depotFields.depotNotInUse} onClick={() => handleActive(false)}></NoButton>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </>
    );
};

export default ScheduleDetails;
