// import React from 'react';
import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { Table, TableBody, TableContainer, TableRow, Paper, TableCell, Typography, TableHead } from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { CustomChip } from '../../../../../Core/Controls';
import ContractField from './contractTab';
import { useLoginInfo } from '../../../../../Core/Hooks/useLoginInfo';
import { AppStorage } from '../../../../../Core/Basic/storage-service';

const gridStyle = {
    title: {
        color: '#f50057'
    },
    header: {
        border: '1px solid rgba(0,0,0,0.2)'
    },
    tbHead: {
        padding: '5px 0px 6px 8px'
    },
    tbContent: {
        margin: '8px 0px 14px 0px;'
    }
};

const ContractDash = (props) => {
    let checkhaveAccess = AppStorage.getAllowAcces();
    const isCustomer = useLoginInfo().userRoles.includes('customer');
    const [show, setShow] = useState(false);
    useEffect(() => {
        if (props.vehicleContractID) {
            setShow(true);
        }
    }, []);

    const hadleScreen = () => {
        setShow(!show);
    };

    return (
        <div style={{ marginTop: 10 }}>
            {/* <ContractField onClose={hadleScreen} vehicleID={props.VehicleID} vehicleContractID={props.vehicleContractID} /> */}

            <Grid container>
                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <div style={gridStyle.header}>
                            <div style={gridStyle.title}>
                                <Grid container style={{ alignItems: 'center', padding: '0px 6px' }}>
                                    <Grid xs={4} lg={4} item style={{ alignItems: 'center' }}>
                                        <Typography variant="h6" color="secondary" style={gridStyle.tbHead}>
                                            Contract Details
                                        </Typography>
                                    </Grid>

                                    <Grid xs={8} lg={8} container item justify="flex-end">
                                        {!isCustomer && (
                                            <div style={{ float: 'right', display: props.vehicleContractID ? 'none' : 'block' }}>
                                                {!show && (
                                                    <CustomChip
                                                        style={{ marginRight: 17 }}
                                                        icon={AssignmentIcon}
                                                        label={props.vehicleContractID ? 'Contract Detail' : 'Set contract'}
                                                        onClick={hadleScreen}
                                                    />
                                                )}
                                            </div>
                                        )}
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                        <Table aria-label="simple table" size="medium">
                            <TableBody>
                                <TableRow key={1}>
                                    <TableCell style={{ color: 'inherit' }} width="75%">
                                        {!show ? (
                                            <div style={{ textAlign: 'center' }}>
                                                {' '}
                                                Contract detail is not set up for this vehicle.&nbsp;
                                                {/* <Link to="/contractField"> Click here to set contract details.</Link> */}
                                                {!isCustomer && (
                                                    <span onClick={hadleScreen} style={{ cursor: 'pointer', color: '#f50057' }}>
                                                        Click here to set contract details.
                                                    </span>
                                                )}
                                            </div>
                                        ) : (
                                            <ContractField
                                                onClose={hadleScreen}
                                                vehicleID={props.VehicleID}
                                                vehicleContractID={props.vehicleContractID}
                                                pullDetails={props.pullDetails}
                                            />
                                        )}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </div>
    );
};

export default ContractDash;
