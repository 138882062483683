import React, { useEffect, useMemo, useState } from 'react';
import { BreadCrumbs, CustomChip, TabView } from '../../../../Core/Controls';
import JobBookingDetail from '../Vehicles/ScheduleVehicle/scheduleJobDetail';
import JobItems from './JOBItems/JobItemsScreen';
import JobNotes from '../../Controllers/Notes/index';
import JobDetail from './JOBItems/JobDetails';
import '../controllerStyle.scss';
import JobMessages from './JOBItems/JobMessages';
import InvoiceScreen from '../../Invoices';
import { useLoginInfo } from '../../../../Core/Hooks/useLoginInfo';
import InternalJobDetail from './JobDetails/Internal';
import SalesInvoiceScreen from '../../Invoices/salesInvoice';
import { AppStorage } from '../../../../Core/Basic/storage-service';
import BuildIcon from '@material-ui/icons/Build';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import AddVor from '../VOR/AddEditVor';
import { useHistory } from 'react-router-dom';
import InternalJobItem from './JobDetails/Internal/jobItemscreen';
import CustomerJobItemScreen from './JobDetails/Customer/jobItemscreen';
import AgentJobItemsScreen from './JobDetails/ServiceAgent/jobItemscreen';
function JobTabScreen(props) {
    let checkhaveAccess = AppStorage.getAllowAcces();
    const history = useHistory();
    const id = props.match.params.id;
    const vehicleID = props.match.params?.vehicleID;
    const tabIndex = props.match?.params?.tabIndex;

    let { state } = props.location;
    const [index, setIndex] = useState(undefined);
    const [value, setValue] = useState({});
    const [modalData, setModalData] = useState({});

    const crumbs = [
        { name: 'Home', path: '/' },
        { name: 'Jobs', active: true }
    ];
    const { userRoles } = useLoginInfo();

    const isServiceAgent = userRoles.includes('serviceagent');

    const isCustomer = userRoles.includes('customer');
    const isinternal = userRoles.includes('internal');

    useEffect(() => {
        if (props.match?.params?.tabIndex) {
            var d = new Date();
            var n = d.getTime();
            setValue((st) => ({ ...st, timeDependency: n }));
            setIndex(+props.match?.params?.tabIndex || undefined);
        }
    }, [props.match?.params?.tabIndex]);

    const tabs = [
        ...(isinternal
            ? [
                  ...(checkhaveAccess?.CanViewJobs
                      ? [
                            {
                                label: 'Job Details',
                                body: <InternalJobDetail scheduleJobID={id} vehicleID={vehicleID} />
                            },
                            {
                                label: 'Job Items',
                                body: <InternalJobItem scheduleJobID={id} VehicleID={vehicleID} checkhaveAccess={checkhaveAccess} />
                            }
                        ]
                      : []),
                  ...(checkhaveAccess?.CanViewInvoices
                      ? [
                            {
                                label: 'Purchase Invoices',
                                body: <InvoiceScreen VehicleID={vehicleID} JobID={id} fromPage="job" />
                            },
                            {
                                label: 'Sales Invoices',
                                body: <SalesInvoiceScreen VehicleID={vehicleID} JobID={id} />
                            }
                        ]
                      : [])
              ]
            : []),

        ...(checkhaveAccess?.CanViewJobs
            ? [
                  ...(isCustomer
                      ? [
                            {
                                label: 'Job Items',
                                body: <CustomerJobItemScreen scheduleJobID={id} VehicleID={vehicleID} checkhaveAccess={checkhaveAccess} />
                            }
                        ]
                      : [])
              ]
            : [])
    ];

    const baseTabs = useMemo(() => {
        let newColumn = [...tabs];
        if (!isServiceAgent) {
            newColumn.splice(
                4,
                0,
                {
                    label: 'Job Notes',
                    body: (
                        <>
                            <JobDetail scheduleJobID={id} VehicleID={vehicleID} /> <JobNotes frompage="Jobs" fromTab={true} JobID={id} noteRefsGuidID={id} />
                        </>
                    )
                },
                {
                    label: 'Messages',
                    body: (
                        <>
                            <JobDetail scheduleJobID={id} VehicleID={vehicleID} /> <JobMessages scheduleJobID={id} />
                        </>
                    )
                }
            );
        } else {
            newColumn.splice(0, 0, {
                label: 'Job Items',
                body: <AgentJobItemsScreen scheduleJobID={id} VehicleID={vehicleID} checkhaveAccess={checkhaveAccess} />
            });
        }
        return newColumn;
    }, []);

    const handleAddPopUp = () => {
        setModalData((st) => ({ ...st, showVor: true }));
    };

    const closeAddPopUp = (flag, name) => {
        setModalData((st) => ({ ...st, showVor: false }));
        flag && history.replace({ state: { ...state, vorVehicleID: '' } });
    };

    return (
        <div className="screen">
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', alignContent: 'center', position: 'relative', top: 1 }}>
                <div style={{ width: 'auto' }}>
                    <BreadCrumbs crumbs={crumbs} m={5} />
                </div>
                &nbsp;&nbsp;
                <div style={{ width: 'auto' }}>
                    {isinternal && state?.vorVehicleID && (
                        <CustomChip color="secondary" className="btn-m" icon={BuildIcon} label="VOR" onClick={handleAddPopUp} />
                    )}
                </div>
                {/* &nbsp;&nbsp;
                <div style={{ width: 'auto', marginBottom: 10 }}>
                    <FileUploaderButton recordId={id} fileUploaderConfigName="JobScreen" />
                </div> */}
            </div>
            <TabView selectedIndex={index} tabList={baseTabs} id="testing_tabs" timeDependency={value.timeDependency} />
            {modalData.showVor ? (
                <DialogComp
                    title={`${state?.vorVehicleID ? 'Update' : 'Add'} VOR `}
                    // title={`Add Vor`}
                    onClose={() => closeAddPopUp(false)}
                    maxWidth="lg"
                    overflow="auto"
                >
                    <AddVor VehicleID={vehicleID} update={state?.vorVehicleID} vorVehicleID={state?.vorVehicleID} onClose={closeAddPopUp} />
                </DialogComp>
            ) : null}
        </div>
    );
}

export default JobTabScreen;
