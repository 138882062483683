import React, { useContext, useState, useEffect, useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormControlLabel, FormHelperText, InputLabel, Typography } from '@material-ui/core';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import { InputText, SecondaryButton, MandatoryField, SimpleField, formatters, DataGrid, EditButton } from '../../../../Core/Controls';
import { CustomIconButton } from '../../../../Core/Controls/Inputs/Buttons/ButtonModal';
import NoteIcon from '@material-ui/icons/Note';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { SelectBox } from '../../../../Core/Controls';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import JobStepperScreen from '../BreackdownDefectStepper/addJobStepper';
import CustomizedSteppers from '../BreackdownDefectStepper/defectBreackDownStepper';
import AddEditNotes from '../../Controllers/Notes/progressReminder'; //'./addEditNotes';
import AddEditNewNotes from '../../Controllers/Notes/jobNotes'; //'./addEditNotes';

import { useToast } from '../../../../Core/Hooks/useToast';
import { getAllNotes } from '../../../../Core/Services/InternalUser/ControllerService/noteService';
import '.././BreackdownDefectStepper/defect.scss';
import { CustomChip } from '../../../../Core/Controls';
import FileUploaderButton from '../../../../Core/Controls/GenericFileUploader';
import AddVor from '../../Controllers/VOR/AddEditVor';
import { useLoginInfo } from '../../../../Core/Hooks/useLoginInfo';
import moment from 'moment';
import DepotDetails from '../../Controllers/DetailsModal/depotDetails';
import DeleteJobModal from '../../Controllers/JOBS/deleteJobModal';
import DeleteIcon from '@material-ui/icons/Delete';
import { ChipWithoutLable } from '../../../../Core/Controls/Inputs/DatePicker';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { AppStorage } from '../../../../Core/Basic/storage-service';
import AddDefectBreakEditJobsNotes from '../../Controllers/Notes/defectBreakdownNotes';

const JobSingleNote = (props) => {
    const [state, setState] = useState({ show: false });
    const { note, createdBy, dateCreated, dateClosed, closedBy, noteID, rd } = props.note;
    let checkIsClosed = dateClosed || closedBy;
    const closeModal = (res) => {
        setState({ show: false });
        res && props.onFormSubmit(true);
    };
    const showModal = () => {
        if (!checkIsClosed) {
            setState({ show: true });
        }
    };
    return (
        <>
            {state.show ? (
                <AddEditNewNotes
                    DisplayData={props.DisplayData}
                    noteRefsGuidID={props.noteRefsGuidID}
                    defectID={props.defectID}
                    NoteID={noteID}
                    frompage="Defects"
                    notesTitle="BreakDown"
                    driverName={props.driverName}
                    onClose={() => closeModal(false)}
                    reportedBy={props.reportedBy}
                    titleModal="Progress Update Notes"
                    reportedPhone={props.reportedPhone}
                    onFormSubmit={() => closeModal(true)}
                />
            ) : null}
            <div className="single-note">
                <div
                    className="msg"
                    style={{ fontSize: 14, fontStyle: 'italic', ...(!checkIsClosed && { color: '#f50057', cursor: 'pointer' }) }}
                    onClick={showModal}
                >
                    {note}
                </div>
                <div style={{ fontSize: 12, color: 'grey', fontStyle: 'italic', textAlign: 'left', float: 'left' }}>{moment(rd).format('LLL')}</div>
                <div className="footer" style={{ fontSize: 12, color: 'grey', fontStyle: 'italic', textAlign: 'right', float: 'right' }}>
                    {createdBy} @ {moment(dateCreated).format('LLL')}
                    {dateClosed && closedBy && ` (Closed by: ${closedBy} @ ${moment(dateClosed).format('LLL')} )`}
                </div>
                <br />
                <hr style={{ opacity: '30%' }} />
            </div>
        </>
    );
};

const BreakDownEditDetails = (props) => {
    const data = props.data;
    const history = useHistory();
    let checkhaveAccess = AppStorage.getAllowAcces();

    const [state, setState] = useState({ errors: {} });
    const { showToastSuccess, showToastError } = useToast();
    const { userRoles } = useLoginInfo();
    const isDriver = userRoles.includes('driver');
    const isCustomer = userRoles.includes('customer');
    const isServiceAgent = userRoles.includes('serviceagent');
    const isInternal = userRoles.includes('internal');

    const pullNotes = async () => {
        let res = await getAllNotes('Defects', props.NoteVehicleID, props.NoteDriverID, props.NoteServiceAgID, props.customerID, props.JobID, data.defectID);
        if (res.success) {
            setState((st) => ({ ...st, NoteList: res.data.list }));
        }
        if (!res.success) {
            showToastError(res.message);
        }
    };

    useEffect(async () => {
        if (data.defectID) {
            pullNotes();
        }
    }, [data.defectID, data.isRefresh]);

    const closeNoteModal = (res) => {
        setState((st) => ({
            ...st,
            show: false,
            showDefectBreakNotes: false
        }));
        res && pullNotes();
    };

    const addNotes = (show) => {
        setState((st) => ({
            ...st,
            [show]: true
        }));
    };

    const closeVORModal = (res) => {
        setState((st) => ({
            ...st,
            showVORPopup: false
        }));
        res && props.pullBreackdownById();
    };
    const showVorModal = (res) => {
        setState((st) => ({
            ...st,
            showVORPopup: true
        }));
    };
    const showModalHandler = (screenName) => {
        setModalState((st) => ({
            ...st,
            [screenName]: true
        }));
    };

    function isValYesNo(val) {
        return val ? 'Yes' : 'No';
    }

    const handleClose = () => {
        setModalState((st) => ({
            ...st,
            depotModal: false
        }));
    };

    const [modalState, setModalState] = useState({
        depotModal: false
    });

    const renderBold = (toRender, modalName) => {
        return toRender ? (
            <div onClick={() => handleRowClick(modalName)} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {toRender}
            </div>
        ) : (
            ''
        );
    };

    const handleRowClick = (toTurnOn) => {
        setModalState((st) => ({
            ...st,
            [toTurnOn]: true
        }));
    };

    const closeDeleteDefectModal = (res) => {
        setModalState((st) => ({
            ...st,
            showJobDeleteModal: false
        }));
        if (res) {
            history.push({
                pathname: `/maintenance/breakdowns/:all`,
                search: '',
                state: ''
            });
        }
    };

    const closetModal = (res) => {
        setModalState({ showJobStepperScreen: false });
        res && props.pullBreackdownById();
    };

    const handleDefetcBreakDownReporting = (val) => {
        history.push({
            pathname: `/maintenance/breakdown/reporting/${data.customerID}`,
            state: 'Edit'
        });
    };

    return (
        <>
            {modalState.depotModal ? (
                <DialogComp open={true} onClose={handleClose} title={'Depot Details'}>
                    <DepotDetails customerID={data.defectReportingDepotID} onFormSubmit={handleClose} />
                </DialogComp>
            ) : null}

            <Grid container item xs={12} spacing={1}>
                <Grid item xs={12} md={12} lg={isServiceAgent ? 12 : 6}>
                    {/* <Grid container xs={12} className="grid-style white-grid" style={{ justifyContent: 'space-between' }}>
                        <Grid item className="grid-title-row" style={{ width: '100%', padding: '5px 10px' }}>
                            <Typography variant="h5" color="secondary" style={{ width: '100%' }}>
                                Breakdown Details
                                <span style={{ float: 'right', marginBottom: 5 }}>
                                    <FileUploaderButton
                                        recordId={data.defectID}
                                        fileUploaderConfigName="BreakdownScreen"
                                        // fileCount={props.fileCount ? props.fileCount : 0}
                                    />
                                </span>
                            </Typography>
                        </Grid>
                        {!data.vorVehicleID && <CustomChip icon={AddIcon} label="Add VOR" onClick={showVorModal} style={{ fontWeight: 'bold' }} />}
                        //  <Grid item className="grid-title-row">
                        //     <SecondaryButton onClick={props.onClose}>Cancel</SecondaryButton>
                        // </Grid>
                    </Grid> */}
                    <Grid container item xs={12} justify="space-between" style={{ padding: '5px 10px' }} className="grid-style grey-grid">
                        <Grid item>
                            <Typography variant="h5" color="secondary">
                                Breakdown Details
                                <span style={{ position: 'relative', top: '-7px', left: '7px' }}>
                                    <FileUploaderButton recordId={data.defectID} fileUploaderConfigName="BreakdownScreen" fileCount={data.fileCount} />
                                </span>
                            </Typography>
                        </Grid>
                        <Grid item style={{ paddingTop: 1, paddingBottom: 3 }}>
                            {!data.vorVehicleID && !isDriver && !isCustomer && !isServiceAgent && (
                                <CustomChip icon={AddIcon} label="Add VOR" onClick={showVorModal} style={{ fontWeight: 'bold' }} />
                            )}
                            {!isDriver && !isCustomer && !isServiceAgent && (
                                <>
                                    {data.defectNumberGenerated && !props.isAddJobBtnShow ? (
                                        <CustomChip
                                            icon={AddIcon}
                                            label="Add Repair"
                                            onClick={() => showModalHandler('showJobStepperScreen')}
                                            style={{ fontWeight: 'bold' }}
                                        />
                                    ) : null}
                                    <CustomChip
                                        icon={DeleteIcon}
                                        label="Delete Breakdown"
                                        onClick={() => showModalHandler('showJobDeleteModal')}
                                        style={{ fontWeight: 'bold' }}
                                    />
                                </>
                            )}
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} style={{ backgroundColor: '#f5f9f9' }}>
                        <Grid container className="grid-style gray-grid">
                            <Grid item xs={12}>
                                {' '}
                                {/* style={{ overflowY: 'auto', minHeight: '60px' }} */}
                                <FormHelperText shrink>Problem Reported</FormHelperText>
                                <Typography variant="button">{data.problemReported || 'N\\A'}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container className="grid-style white-grid">
                            <Grid item xs={6} sm={4}>
                                <FormHelperText shrink>Breakdown Type</FormHelperText>
                                <Typography variant="button">{isValYesNo(data.breakDownType)}</Typography>
                            </Grid>
                            <Grid item xs={6} sm={4}>
                                <FormHelperText shrink>Reporting Depot</FormHelperText>
                                <Typography variant="button">{renderBold(data.reportingDepotName, 'depotModal') || 'N\\A'}</Typography>
                            </Grid>
                            <Grid item xs={6} sm={4}>
                                <FormHelperText shrink>Breakdown Location</FormHelperText>
                                <Typography variant="button">{data.breakDownLocation || 'N\\A'}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container className="grid-style gray-grid">
                            <Grid item xs={6} sm={4}>
                                <FormHelperText shrink>Mileage</FormHelperText>
                                <Typography variant="button">{data.mileage || 'N\\A'}</Typography>
                            </Grid>
                            <Grid item xs={6} sm={4}>
                                <FormHelperText shrink>Reported By</FormHelperText>
                                <Typography variant="button">{data.reportedBy || 'N\\A'}</Typography>
                            </Grid>
                            <Grid item xs={6} sm={4}>
                                <FormHelperText shrink>Reported Phone</FormHelperText>
                                <Typography variant="button">{formatters.phoneNumberFormatter(data.reportedPhone) || 'N\\A'}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container className="grid-style white-grid">
                            <Grid item xs={6} sm={4}>
                                <FormHelperText shrink>Driver Name</FormHelperText>
                                <Typography variant="button">{data.driverName || 'N\\A'}</Typography>
                            </Grid>
                            <Grid item xs={6} sm={4}>
                                <FormHelperText shrink>Driver Phone</FormHelperText>
                                <Typography variant="button">{formatters.phoneNumberFormatter(data.driverPhone) || 'N\\A'}</Typography>
                            </Grid>
                            <Grid item xs={6} sm={4}>
                                <FormHelperText shrink>Call Out Required</FormHelperText>
                                <Typography variant="button">{isValYesNo(data.callOutRequired)}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container className="grid-style gray-grid">
                            <Grid item xs={6} sm={4}>
                                <FormHelperText shrink>Urgent Call Out</FormHelperText>
                                <Typography variant="button">{isValYesNo(data.urgentCallOut)}</Typography>
                            </Grid>
                            <Grid item xs={6} sm={4}>
                                <FormHelperText shrink>Put On VOR</FormHelperText>
                                <Typography variant="button">{isValYesNo(data.defectVOR)}</Typography>
                            </Grid>
                            <Grid item xs={6} sm={4}>
                                <FormHelperText shrink>Replacement vehicle Required</FormHelperText>
                                <Typography variant="button">{isValYesNo(data.replacementvehicleRequired)}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container className="grid-style white-grid">
                            <Grid item xs={6} sm={4}>
                                <FormHelperText shrink>Passengers Involved</FormHelperText>
                                <Typography variant="button">{isValYesNo(data.passengersInvolved)}</Typography>
                            </Grid>
                            <Grid item xs={6} sm={4}>
                                <FormHelperText shrink>Number Of Passenger</FormHelperText>
                                <Typography variant="button">{data.numberOfPassenger || 'N\\A'}</Typography>
                            </Grid>
                            <Grid item xs={6} sm={4}>
                                <FormHelperText shrink>Accident Damage?</FormHelperText>
                                <Typography variant="button">{isValYesNo(data.accDamage)}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container className="grid-style gray-grid">
                            <Grid item xs={6} sm={4}>
                                <FormHelperText shrink>Insurance Reference</FormHelperText>
                                <Typography variant="button">{data.iNSREF || 'N\\A'}</Typography>
                            </Grid>

                            <Grid item xs={6} sm={4}>
                                <FormHelperText shrink>Police On Scene?</FormHelperText>
                                <Typography variant="button">{isValYesNo(data.policeOnScene)}</Typography>
                            </Grid>
                            <Grid item xs={6} sm={4}>
                                <FormHelperText shrink>Vehicle Loaded?</FormHelperText>
                                <Typography variant="button">{isValYesNo(data.vehicleLoaded)}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container className="grid-style white-grid">
                            <Grid item xs={6} sm={4}>
                                <FormHelperText shrink>Load Type</FormHelperText>
                                <Typography variant="button">{data.vehicleLoadTypeDescription}</Typography>
                            </Grid>
                            <Grid item xs={6} sm={4}>
                                <FormHelperText shrink>Mobility Requested?</FormHelperText>
                                <Typography variant="button">{isValYesNo(data.defectMobilityRequested)}</Typography>
                            </Grid>
                            <Grid item xs={6} sm={4}>
                                <FormHelperText shrink>Mobility Used?</FormHelperText>
                                <Typography variant="button">{isValYesNo(data.defectMobilityUsed)}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container className="grid-style gray-grid">
                            <Grid item xs={6} sm={4}>
                                <FormHelperText shrink>PG9 Issued?</FormHelperText>
                                <Typography variant="button">{isValYesNo(data.defectPG9Issued)}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {!isServiceAgent && (
                    <Grid item xs={12} md={12} lg={6}>
                        <Grid
                            container
                            xs={12}
                            className="grid-style white-grid"
                            justify="space-between"
                            style={{ paddingBottom: '5px', paddingTop: '5px', paddingRight: '5px' }}
                        >
                            <Grid item>
                                <Typography variant="h5" color="secondary">
                                    Job Progress
                                </Typography>
                            </Grid>
                            <Grid item>
                                {/* {!isServiceAgent && (
                                    <CustomChip
                                        icon={EditIcon}
                                        iconFontSize={16}
                                        label="Breakdown Reporting"
                                        style={{ fontWeight: 'bold' }}
                                        onClick={handleDefetcBreakDownReporting}
                                    />
                                )} */}
                                {isInternal && checkhaveAccess?.CanViewNotes && (
                                    <CustomChip style={{ fontWeight: 'bold' }} icon={AddIcon} label="Add Progress Reminder" onClick={() => addNotes('show')} />
                                )}
                                {isInternal && checkhaveAccess?.CanViewNotes && (
                                    <CustomChip
                                        style={{ fontWeight: 'bold' }}
                                        icon={AddIcon}
                                        label="Add Notes"
                                        onClick={() => addNotes('showDefectBreakNotes')}
                                    />
                                )}
                            </Grid>
                        </Grid>
                        {/* <Grid item xs={12}>
                        <DataGrid columns={columns} rows={state.NoteList} offset={480} />
                    </Grid> */}
                        <Grid item xs={12} style={{ padding: '5px', minHeight: '430px' }} className="grid-style">
                            {state.NoteList?.map((n, i) => (
                                <>
                                    <JobSingleNote
                                        key={i}
                                        note={n}
                                        DisplayData={data.driverPhone}
                                        driverName={data.driverName}
                                        reportedBy={data.reportedBy}
                                        reportedPhone={data.reportedPhone}
                                        onFormSubmit={() => closeNoteModal(true)}
                                        noteRefsGuidID={data.defectID}
                                        defectID={data.defectID}
                                    />
                                </>
                            ))}
                        </Grid>
                    </Grid>
                )}
            </Grid>
            {state.show ? (
                <AddEditNotes
                    DisplayData={data.driverPhone}
                    noteRefsGuidID={data.defectID}
                    defectID={data.defectID}
                    frompage="Defects"
                    notesTitle="BreakDown"
                    driverName={data.driverName}
                    onClose={() => closeNoteModal(false)}
                    reportedBy={data.reportedBy}
                    reportedPhone={data.reportedPhone}
                    onFormSubmit={() => closeNoteModal(true)}
                />
            ) : null}
            {state.showDefectBreakNotes ? (
                <AddDefectBreakEditJobsNotes
                    DisplayData={data.driverPhone}
                    noteRefsGuidID={data.defectID}
                    defectID={data.defectID}
                    frompage="Defects"
                    notesTitle="BreakDown"
                    driverName={data.driverName}
                    onClose={() => closeNoteModal(false)}
                    reportedBy={data.reportedBy}
                    reportedPhone={data.reportedPhone}
                    onFormSubmit={() => closeNoteModal(true)}
                />
            ) : null}
            {state.showVORPopup ? (
                <DialogComp title={`${data.vorVehicleID ? 'Update' : 'Add'} VOR`} onClose={() => closeVORModal(false)} maxWidth="lg" overflow="auto">
                    <AddVor
                        VehicleID={data.vehicleID}
                        update={data.vorVehicleID}
                        vorVehicleID={data.vorVehicleID}
                        onClose={closeVORModal}
                        defectID={data.defectID}
                        fromPage="Breakdown"
                    />
                </DialogComp>
            ) : null}
            {modalState.showJobDeleteModal ? (
                <DialogComp open={true} onClose={() => closeDeleteDefectModal(false)} title={'Delete Breakdown'} maxWidth="sm" fullWidth>
                    <DeleteJobModal deleteID={data.defectID} fromPage="Defect" onClose={closeDeleteDefectModal} />
                </DialogComp>
            ) : null}
            {modalState.showJobStepperScreen ? (
                <DialogComp title="Add Repair" onClose={() => closetModal(false)} maxWidth="sm">
                    <JobStepperScreen onClose={closetModal} userData={data} />
                </DialogComp>
            ) : null}
        </>
    );
};

export default BreakDownEditDetails;
