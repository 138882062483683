import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { SecondaryButton, MandatoryField, SimpleField } from '../../../../Core/Controls';
import { getCostTypesById, postCostTypesData } from '../../../../Core/Services/InternalUser/costTypeService';
import { SecondaryCheckbox } from '../../../../Core/Controls';
import { useToast } from '../../../../Core/Hooks/useToast';
import '../../.././commonStyle.scss';

function CostTypesModal(props) {
    const [state, setState] = useState({
        costTypeCode: '',
        costTypeDescription: '',
        costTypeActive: true,
        costTypeRechargeToCustomer: true,
        costTypePaySupplier: true,
        IsAdd: true,
        errors: {}
    });

    const { showToastSuccess, showToastError } = useToast();
    
    const [btnLoader, setBtnLoader] = useState(false);

    useEffect(async () => {
        if (props.data) {
            let res = await getCostTypesById(props.data.costTypeCode);
            const data = res.data?.list[0];
            setState((state) => ({
                ...state,

                costTypeCode: data.costTypeCode,
                costTypeDescription: data.costTypeDescription,
                costTypeActive: data.costTypeActive,
                costTypeRechargeToCustomer: data.costTypeRechargeToCustomer,
                costTypePaySupplier: data.costTypePaySupplier,
                code: data.costTypeCode,
                IsAdd: data.IsAdd
            }));
        }
    }, []);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setState((state) => ({
            ...state,
            [name]: value
        }));
    };
    const handleCheckbox = (e) => {
        const { name, checked } = e.target;
        setState((state) => {
            const nst = { ...state, [name]: checked };
            return nst;
        });
    };

    const validations = () => {
        const { costTypeDescription } = state;
        let emptyStrigPattern = /[^\s]/;
        let formIsValid = true;
        let errors = {};
        if (!emptyStrigPattern.test(costTypeDescription)) {
            errors.costTypeDescription = 'Cost Type Description is required';
            formIsValid = false;
        }
        setState((state) => ({ ...state, errors: errors }));
        return formIsValid;
    };

    const submitHandler = async () => {
        if (validations()) {
            const AllData = {
                costTypeCode: state.costTypeCode,
                costTypeDescription: state.costTypeDescription,
                costTypeActive: state.costTypeActive,
                costTypeRechargeToCustomer: state.costTypeRechargeToCustomer,
                costTypePaySupplier: state.costTypePaySupplier,
                isAdd: state.code ? false : true
            };
            setBtnLoader(true);
            let res = await postCostTypesData(AllData);
            if (res.success) {
                showToastSuccess('Cost Type Saved Successfully');
                props.onClose(true);
            } else {
                showToastError(res.message);
            }
            setBtnLoader(false);
        }
    };

    return (
        <Grid container direction="row" spacing={2}>
            <Grid item xs={12} className="mandatory-fields">
                <MandatoryField
                    inputLabel="Description"
                    name="costTypeDescription"
                    placeholder="Description"
                    onChange={handleInputChange}
                    value={state.costTypeDescription}
                    errors={state.errors.costTypeDescription}
                />
            </Grid>
            <Grid item xs={12}>
                <SimpleField
                    inputLabel="Cost Type"
                    name="costTypeCode"
                    placeholder="Cost Type"
                    onChange={handleInputChange}
                    value={state.costTypeCode}
                    errors={state.errors.costTypeCode}
                    disabled={!!state.code}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <SecondaryCheckbox
                    checked={state.costTypeRechargeToCustomer}
                    name="costTypeRechargeToCustomer"
                    onChange={handleCheckbox}
                    label="Recharge To Customer?"
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <SecondaryCheckbox checked={state.costTypePaySupplier} name="costTypePaySupplier" onChange={handleCheckbox} label="Pay to Supplier?" />
            </Grid>
            <Grid item xs={12} md={6}>
                <SecondaryCheckbox checked={state.costTypeActive} name="costTypeActive" onChange={handleCheckbox} label="Is Active?" />
            </Grid>

            <Grid item xs={12} className="submit_btn">
                <SecondaryButton isBtnLoader={btnLoader} onClick={submitHandler}>
                    {props.data ? 'Update' : 'Add'}
                </SecondaryButton>
            </Grid>
        </Grid>
    );
}

export default CostTypesModal;
