import React, { useEffect, useMemo, useState } from 'react';
import DataGrid from '../../../Core/Controls/Grid';
import { Link } from 'react-router-dom';
import AddEditServiceAgent from './addEditServiceAgent';
import { getAllServiceAgents, postServiceAgentRating, getMapNewData } from '../../../Core/Services/InternalUser/ServiceAgent/serviceAgentService';
import DialogComp from '../../../Core/Dialog/DialogComp';
import { AddButton, EditButton, BreadCrumbs, formatters, SecondaryButton, SimpleRating, CustomChip } from '../../../Core/Controls';
import '../Controllers/controllerStyle.scss';
import { useLoginInfo } from '../../../Core/Hooks/useLoginInfo';
import { getByDisplayValue } from '@testing-library/react';
import './serviceAgent.scss';
import RoomIcon from '@material-ui/icons/Room';
import GetAgentLocation from '../Maintenance/BreakDown/getLocations';
import ConfirmationModal from '../../../Core/Modal/ConfirmationModal';
import { useToast } from '../../../Core/Hooks/useToast';
import GijgoGrid from '../../../Core/Controls/GijgoGrid';
import { Grid } from '@material-ui/core';
import ReactDOM from 'react-dom';
import { ChipWithoutLable } from '../../../Core/Controls/Inputs/DatePicker';
import SyncIcon from '@material-ui/icons/Sync';
import Mapheader from '../Maintenance/BreakDown/locationHeaderComp';
import { AppStorage } from '../../../Core/Basic/storage-service';

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Service Agents', path: '/', active: true }
];

const modalgridStyle = {
    formatterImageContainer: { width: '100%', textAlign: 'center' },
    formatterImage: { width: 90, height: 'auto', backgroundSize: 'cover' },
    formatterEditBtn: { textAlign: 'center' },
    dialogTitleStyle: { paddingLeft: '50px' }
};

const baseColumns = [
    { key: 'action', name: 'Action', width: 80, addFileUploaderIcon: true },
    { key: 'serviceAgentName', name: 'Agent Name', width: '15%' },
    // { key: 'serviceAgentPostCode', name: 'Post Code' },
    { key: 'facilities', name: 'Service Agent Facilities' },
    { key: 'serviceAgentPhone', name: 'Phone', width: '11%' },
    // { key: 'serviceAgentEmail', name: 'Email', width: '18%' },
    {
        key: 'serviceAgentAddress',
        name: 'Address'
    },
    { key: 'serviceAgentRating', name: 'Rating ', width: '18%', filterable: 'no' }
];

const ServiceAgent = (props) => {
    const [state, setState] = useState({
        show: false,
        showEditPopup: false,
        List: [],
        showLoader: true,
        serviceAgentID: null,
        gooleSearchValue: null,
        showGoogleValue: false
    });
    const { showToastSuccess, showToastError } = useToast();
    const { userRoles } = useLoginInfo();
    const isAdmin = userRoles.includes('admin');
    const isInternal = userRoles.includes('internal');
    let checkhaveAccess = AppStorage.getAllowAcces();
    // useEffect(async () => {
    //     pullAllServiceAgent();
    // }, []);

    const handleEditClick = (val) => {
        props.history.push({
            pathname: `/controller/editServiceAgent/${val.serviceAgentID}`,
            state: { fileCount: val.fileCount }
        });
    };

    const renderEdit = (data, row) => {
        return (
            <div style={modalgridStyle.formatterEditBtn}>
                <EditButton toolTipTitle="Edit Service Agent" onClick={() => handleEditClick(row)} />
            </div>
        );
    };

    const renderRating = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <Grid container justify="center">
                <Grid>
                    <SimpleRating
                        onRatingHandle={showRatingModal(row)}
                        ratingValue={row.serviceAgentRating}
                        name={`tempName${row.serviceAgentID}`}
                        isDisabled={!checkhaveAccess?.CanUpdateServiceAgentRating || !isInternal}
                    />
                </Grid>
            </Grid>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const renderAgentAddress = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => rowSelctedValues(row)} style={{ cursor: 'pointer', color: '#f50057' }}>
                {row.serviceAgentAddress}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const renderPhone = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = <div style={{ cursor: 'pointer', color: '#f50057' }}>{formatters.phoneNumberFormatter(row.serviceAgentPhone)}</div>;
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let ratingCol = tempCols.find((element) => element.key === 'serviceAgentRating');
        let agentAddressCol = tempCols.find((element) => element.key === 'serviceAgentAddress');
        let actionCol = tempCols.find((element) => element.key === 'action');
        let phoneCol = tempCols.find((element) => element.key === 'serviceAgentPhone');

        if (phoneCol) {
            phoneCol.formatter = renderPhone;
        }
        if (actionCol) {
            actionCol.formatter = renderEdit;
        }
        if (ratingCol) {
            ratingCol.formatter = renderRating;
        }
        if (agentAddressCol) {
            agentAddressCol.formatter = renderAgentAddress;
        }

        return tempCols;
    }, []);

    const rowSelctedValues = (row) => {
        setState((st) => ({
            ...st,
            gooleSearchValue: row.serviceAgentName,
            showGoogleValue: true
        }));
    };

    const closeGoogleSerach = () => {
        setState((st) => ({
            ...st,
            showGoogleValue: false,
            showMap: false
        }));
    };

    const pullAllServiceAgent = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    const handleAdd = () => {
        setState((st) => ({
            ...st,
            show: true
        }));
    };

    const closeServiceAgentModal = (res) => {
        setState((st) => ({
            ...st,
            show: false
        }));
        res && pullAllServiceAgent();
    };

    const handleUpdateLocation = (location) => {
        setState((st) => ({ ...st, breakDownLocation: location, showMap: false }));
    };

    const handleShowLocation = (id) => {
        setState((st) => ({ ...st, showMap: true }));

        //  history.push(`/controller/getagentLocation/${state.breakDownLocation}`);
    };

    const showRatingModal = (data) => (name, value) => {
        setState((st) => ({
            ...st,
            showRating: true,
            selectedRatingValue: value,
            serviceAgentID: data.serviceAgentID
        }));
    };

    const closeRatingModal = (value) => {
        setState((st) => ({
            ...st,
            showRating: false
        }));
        value && pullAllServiceAgent();
    };

    const ratingSubmit = async (value) => {
        let ratingData = {
            serviceAgentID: state.serviceAgentID,
            serviceAgentRating: state.selectedRatingValue
        };
        let res = await postServiceAgentRating(ratingData);
        if (res.success) {
            showToastSuccess(res.data.message);
            closeRatingModal(true);
        } else {
            showToastError(res.data.message);
        }
    };

    const reGenrateMap = async () => {
        let res = await getMapNewData();
        if (res.data.success) {
            showToastSuccess(res.data.message);
        } else {
            showToastError(res.data.message);
        }
    };

    let getUrl = `ServiceAgents`;
    const title = state.subCategoryId ? 'Update Service Agent' : 'Add Service Agent';
    const btnTitle = checkhaveAccess?.CanUpdateServiceAgents ? 'Add Service Agent' : null;
    const mapBtnTitle = !isAdmin ? 'Map' : null;
    return (
        <div className="screen">
            <Grid container spacing={1}>
                <Grid item>
                    <BreadCrumbs crumbs={crumbs} className="edit-Customer-btn" />
                </Grid>
                <Grid item className="height-45">
                    <CustomChip
                        icon={RoomIcon}
                        iconFontSize={16}
                        label="Service Agents Map"
                        onClick={handleShowLocation}
                        className="btn-m"
                        style={{ fontWeight: 'bold' }}
                    />
                    {/* <ChipWithoutLable icon={RoomIcon} onClick={handleShowLocation} style={{ fontWeight: 'bold' }} toolTipTitle={'Map'} /> */}
                </Grid>
                {isAdmin && (
                    <Grid item className="height-45">
                        <CustomChip
                            icon={SyncIcon}
                            iconFontSize={16}
                            label="Regenerate Map File"
                            onClick={reGenrateMap}
                            className="btn-m"
                            style={{ fontWeight: 'bold' }}
                        />
                        {/* <ChipWithoutLable icon={SyncIcon} onClick={reGenrateMap} style={{ fontWeight: 'bold' }} toolTipTitle={'Re-generate map data'} /> */}
                    </Grid>
                )}
            </Grid>
            {state.show ? (
                // <DialogComp title={title} onClose={() => closeServiceAgentModal(false)} overflow="auto" maxWidth="lg">
                <AddEditServiceAgent serviceAgentID={state.serviceAgentID} onFormSubmit={() => closeServiceAgentModal(true)} />
            ) : !state.showGoogleValue ? (
                <GijgoGrid
                    dataConverter={(res) => ({
                        records: res.data.list,
                        total: res.data.total
                    })}
                    columns={columns}
                    getUrl={getUrl}
                    btnTitle={btnTitle}
                    onBtnClick={handleAdd}
                    isReload={state.isReload}
                    idFieldName="serviceAgentID"
                    fileUploaderConfigName="ServiceAgentScreen"
                // mapBtnToolTipTitle={mapBtnTitle}
                // mapBtnIcon={RoomIcon}
                // mapBtnLabel={mapBtnTitle}
                // onMapBtnClick={handleShowLocation}
                />
            ) : (
                <>
                    <DialogComp
                        title={state.gooleSearchValue}
                        onClose={closeGoogleSerach}
                        maxWidth={false}
                        height="750"
                        titleStyle={modalgridStyle.dialogTitleStyle}
                    >
                        <div style={{ width: '1500px', height: '750px' }}>
                            <iframe
                                src={`https://www.google.co.uk/search?q=${state.gooleSearchValue}&igu=1`}
                                frameborder="0"
                                width="100%"
                                height="100%"
                                style={{ border: '1px solid #d4d4d4' }}
                                allowfullscreen="true"
                                aria-hidden="false"
                                tabindex="0"
                            ></iframe>
                        </div>
                    </DialogComp>
                </>
            )}
            {state.showMap ? (
                <DialogComp title={<Mapheader />} onClose={() => closeGoogleSerach(false)} maxWidth="lg" fullScreen>
                    <GetAgentLocation />
                </DialogComp>
            ) : null}
            {state.showRating ? (
                <DialogComp title="Rating" onClose={() => closeRatingModal(false)} maxWidth="sm">
                    <ConfirmationModal message={`Are you sure you want to set a rating of ${state.selectedRatingValue}?`} handleSubmit={ratingSubmit} />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default ServiceAgent;
