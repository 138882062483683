import React, { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormHelperText, InputLabel } from '@material-ui/core';
import {
    SecondaryButton,
    BreadCrumbs,
    SelectBox,
    SecondaryCheckbox,
    DatePicker,
    MandatoryField,
    SimpleField,
    Postcode,
    SingleSelect
} from '../../../../Core/Controls';
import { DriverProvider, DriverScreenContext } from './DriverProvider';
import '../controllerStyle.scss';
import { useLoginInfo } from '../../../../Core/Hooks/useLoginInfo';
import { AppStorage } from '../../../../Core/Basic/storage-service';
const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Drivers', active: true },
    { name: 'Add Driver', active: true }
];
const AddEditDriver = (props) => {
    const { state, handleSubmit, inputChange, handleClose, handleCheckbox, setVal, optionsState, singleSelectChange, tempOptions, btnLoader } =
        useContext(DriverScreenContext);
    const isCustomer = useLoginInfo().userRoles.includes('customer');
    let checkhaveAccess = AppStorage.getAllowAcces();
    return (
        <div style={{ overflow: 'hidden' }} className="controller-screen">
            {!state.driverID ? <BreadCrumbs crumbs={crumbs} className="edit-Customer-btn" /> : null}
            <Grid container spacing={2}>
                {!isCustomer && (
                    <Grid item xs={12} sm={6} md={4} className="mandatory-fields">
                        <InputLabel required shrink>
                            Customer
                        </InputLabel>
                        <SingleSelect
                            options={optionsState.customerList}
                            value={tempOptions.customerIDValue}
                            onChange={singleSelectChange('customerIDValue')}
                        />
                        <FormHelperText error>{state.errors.customerID}</FormHelperText>
                    </Grid>
                )}
                <Grid item xs={12} sm={6} md={4} className="mandatory-fields">
                    <InputLabel required shrink>
                        Depot
                    </InputLabel>
                    <SingleSelect options={optionsState.depotWithCustList} value={tempOptions.depotsIDValue} onChange={singleSelectChange('depotsIDValue')} />
                    <FormHelperText error>{state.errors.depotsID}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={6} md={4} className="mandatory-fields">
                    <InputLabel required shrink>
                        Driver Type
                    </InputLabel>
                    <SingleSelect
                        options={optionsState.driverTypeList}
                        value={tempOptions.driverTypeIDValue}
                        onChange={singleSelectChange('driverTypeIDValue')}
                    />
                    <FormHelperText error>{state.errors.driverTypeID}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={6} md={4} className="mandatory-fields">
                    <InputLabel shrink required>
                        Cost Centre
                    </InputLabel>
                    <SingleSelect
                        options={optionsState.costCentreList}
                        value={tempOptions.costCentreIDValue}
                        onChange={singleSelectChange('costCentreIDValue')}
                    />
                    <FormHelperText error>{state.errors.costCentreID}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={6} md={4} className="mandatory-fields">
                    <InputLabel required shrink>
                        License Type
                    </InputLabel>
                    <SingleSelect
                        options={optionsState.licenseTypeList}
                        value={tempOptions.licenseTypeIDValue}
                        onChange={singleSelectChange('licenseTypeIDValue')}
                    />
                    <FormHelperText error>{state.errors.licenseTypeID}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={6} md={4} className="mandatory-fields">
                    <MandatoryField
                        inputLabel="First Name"
                        placeholder="First Name"
                        value={state.firstName}
                        onChange={inputChange}
                        name="firstName"
                        errors={state.errors.firstName}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <SimpleField inputLabel="Middle Name" placeholder="Middle Name" value={state.middleName} onChange={inputChange} name="middleName" />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <SimpleField inputLabel="Last Name" placeholder="Last Name" value={state.lastName} onChange={inputChange} name="lastName" />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <InputLabel shrink>Gender</InputLabel>
                    <SingleSelect options={optionsState.genderList} value={tempOptions.genderValue} onChange={singleSelectChange('genderValue')} />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <InputLabel shrink> Defect Manager</InputLabel>
                    <SingleSelect
                        options={optionsState.defectManagerList}
                        value={tempOptions.driverDefectManagerValue}
                        onChange={singleSelectChange('driverDefectManagerValue')}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <InputLabel shrink>Licence Check Frequency</InputLabel>
                    <SingleSelect
                        options={optionsState.licenceList}
                        value={tempOptions.driverLicenseCheckFrequencyValue}
                        onChange={singleSelectChange('driverLicenseCheckFrequencyValue')}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <InputLabel shrink>Fuel Card Provider</InputLabel>
                    <SingleSelect
                        options={optionsState.fuelCardProvidersList}
                        value={tempOptions.driverFuelCardProviderValue}
                        onChange={singleSelectChange('driverFuelCardProviderValue')}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <SimpleField
                        type="number"
                        inputLabel="Fuel Card Number"
                        placeholder="Fuel Card Number"
                        value={state.driverFuelCardNumber}
                        onChange={inputChange}
                        name="driverFuelCardNumber"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <SimpleField
                        type="number"
                        inputLabel="Fuel Card PIN"
                        placeholder="Fuel Card PIN"
                        value={state.driverFuelCardPIN}
                        onChange={inputChange}
                        name="driverFuelCardPIN"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <SimpleField
                        type="number"
                        inputLabel="License Number"
                        placeholder="License Number"
                        value={state.driverLicenseNumber}
                        onChange={inputChange}
                        name="driverLicenseNumber"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <SimpleField inputLabel="Address1" placeholder="Address1" value={state.address1} onChange={inputChange} name="address1" />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <SimpleField inputLabel="Address2" placeholder="Address2" value={state.address2} onChange={inputChange} name="address2" />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <SimpleField inputLabel="Address3" placeholder="Address3" value={state.address3} onChange={inputChange} name="address3" />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <SimpleField inputLabel="Town" placeholder="Town" value={state.town} onChange={inputChange} name="town" />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <SimpleField inputLabel="County" placeholder="County" value={state.county} onChange={inputChange} name="county" />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    {/* <SimpleField inputLabel="Post Code" placeholder="Post Code" value={state.postCode} onChange={inputChange} name="postCode" />
                    <FormHelperText error>{state.errors.postCode}</FormHelperText> */}
                    <Postcode value={state.postCode} onChange={inputChange} name="postCode" setVal={setVal} errors={state.errors.postCode} />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <SimpleField inputLabel="Telephone" placeholder="Telephone" value={state.telePhone} onChange={inputChange} name="telePhone" />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <SimpleField inputLabel="Driver Mobile" placeholder="Driver Mobile" value={state.driverMobile} onChange={inputChange} name="driverMobile" />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <SimpleField inputLabel="Job Title" placeholder="Job Title" value={state.jobTitle} onChange={inputChange} name="jobTitle" />
                </Grid>
                <Grid item xs={12} sm={6} md={4} className="mandatory-fields">
                    <InputLabel required shrink>
                        Date Of Birth
                    </InputLabel>
                    <DatePicker placeholder="Date Of Birth" value={state.dateOfBirth} onChange={inputChange} name="dateOfBirth" />
                    <FormHelperText error>{state.errors.dateOfBirth}</FormHelperText>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <SimpleField
                        inputLabel="Payroll Number"
                        placeholder="Payroll Number"
                        value={state.driverPayrollNumber}
                        onChange={inputChange}
                        name="driverPayrollNumber"
                    />
                </Grid>
                {isCustomer && <Grid item sm={6}></Grid>}
                <Grid item xs={12} sm={6} className="checkbox-font" mt={5}>
                    <SecondaryCheckbox
                        checked={state.driverReceiveEmailNotification}
                        name="driverReceiveEmailNotification"
                        onChange={handleCheckbox}
                        label="Receive Email Notification?"
                    />
                </Grid>
                <Grid item xs={12} sm={6} className="checkbox-font" mt={5}>
                    <SecondaryCheckbox checked={state.driverLoginEnabled} name="driverLoginEnabled" onChange={handleCheckbox} label="Login Enabled ?" />
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <SecondaryButton
                            onClick={handleSubmit}
                            fullWidth
                            className="submit_btn"
                            isBtnLoader={btnLoader}
                            disabled={!checkhaveAccess?.CanUpdateDepotDepartments}
                        >
                            Save
                        </SecondaryButton>
                    </Grid>
                    <Grid item xs={6}>
                        <SecondaryButton onClick={handleClose} fullWidth className="submit_btn">
                            Cancel
                        </SecondaryButton>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

const Screen = (props) => {
    return (
        <DriverProvider {...props}>
            <AddEditDriver />
        </DriverProvider>
    );
};
export default Screen;
