import { useReducer, useMemo, useEffect } from 'react';
import { formatters, BreadCrumbs } from '../../../Core/Controls';
import EditIcon from '@material-ui/icons/Edit';
import { reducer, initState } from './inspectionReducer';
import { CustomIconButton } from '../../../Core/Controls/Inputs/Buttons/ButtonModal';
import { HANDELCLOSE, PULLINSPECTIONAPIDATA, HANDELCHECK } from '../../../Action/actionName';
import GijgoGrid from '../../../Core/Controls/GijgoGrid';
import EditVehicleDailyChecks from '../Controllers/Vehicles/VehicleDailyChecks/editVehicleDailyCheck';

const basecolumns = [
    { key: 'action', name: 'Action', width: 60, addFileUploaderIcon: true },
    { key: 'vehicleRegNumber', name: 'Reg Number', width: 110 },
    { key: 'scheduleEventName', name: 'Event', width: 130 },
    { key: 'dueDateISOWeek', name: 'ISO Week', width: 90 },
    {
        key: 'scheduleDateDue',
        name: 'Due',
        formatter: formatters.Date_DDMMYYYY,
        width: 90
    },
    {
        key: 'bookingDate',
        name: 'Booked',
        formatter: formatters.Date_DDMMYYYY,
        width: 110
    },
    { key: 'customer', name: 'Customer', width: 140 },
    { key: 'depot', name: 'Depot', width: 140 },
    {
        key: 'agent',
        name: 'Agent',
        width: 150
    }
];
export default function VehicleInspection(props) {
    const [state, dispatch] = useReducer(reducer, initState);

    const renderEdit = (ev, row) => {
        return (
            <div style={{ textAlign: 'center' }}>
                <CustomIconButton
                    icon={EditIcon}
                    color="primary"
                    onClick={() => {
                        dispatch({ type: HANDELCHECK, payload: row });
                    }}
                    toolTipTitle="Edit Inspection Check"
                />
            </div>
        );
    };

    const columns = useMemo(() => {
        const tempCols = [...basecolumns];
        let actionCol = tempCols.find((e) => e.key === 'action');
        if (actionCol) {
            actionCol.formatter = renderEdit;
        }
        return tempCols;
    }, []);

    const getCrumbs = () => {
        let temp = {};
        let crumbs = [
            { name: 'Home', path: '/' },
            { name: 'Inspections', active: true }
        ];
        if (props.status == '1') {
            temp = { name: 'Repair Required', active: true };
        } else if (props.status == '2') {
            temp = { name: ' Repair In Progress', active: true };
        } else if (props.status == '3') {
            temp = { name: 'Awaiting Sign Off', active: true };
        } else if (props.status == '4') {
            temp = { name: 'Signed Off', active: true };
        }
        crumbs.push(temp);
        return crumbs;
    };

    return (
        <div className="screen">
            {state.showBred ? <BreadCrumbs crumbs={getCrumbs()} /> : null}
            {state.showEditChecks ? (
                <EditVehicleDailyChecks checkID={state.selectedRowData.vehicleInspectionID} onClose={() => dispatch({ type: HANDELCLOSE })} frompage={true} />
            ) : (
                <GijgoGrid
                    columns={columns}
                    dataConverter={(res) => ({
                        records: res.data.list,
                        total: res.data.total
                    })}
                    isReload={state.isReload}
                    getUrl={`VehicleInspection/GetInspections?status=${props.status}`}
                />
            )}
        </div>
    );
}
