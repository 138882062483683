import { FormHelperText, Grid, Typography, InputLabel } from '@material-ui/core';
import React, { useEffect, useState, useMemo } from 'react';
import { DatePicker, SelectBox, formatters, TabView, SimpleField, SingleSelect } from '../../../../../Core/Controls';
import { useToast } from '../../../../../Core/Hooks/useToast';
import '../../../../commonStyle.scss';
import './scheduleVehicleStyle.scss';
import '../../controllerStyle.scss';
import moment from 'moment';
import { getJobDetailsById, getAllJobDropDowns, postJobDetails } from '../../../../../Core/Services/InternalUser/ControllerService/jobDetailsService';
import JobHistory from './jobHistory';
import UpcomingEvents from './upcomingEvents';
import StickyNotes from '../../Notes/stickyNotes';
import { useLoginInfo } from '../../../../../Core/Hooks/useLoginInfo';
import StatusBasedButton from '../../JOBS/JobStatusBtn/statusBasedButtons';
import { useHistory } from 'react-router-dom';
import JobDetailsInfo from './showJobDetailsInfo';
import JobItemsScreen from '../../JOBS/JOBItems/JobItemsScreen';
import Alert from '@material-ui/lab/Alert';
import InfoIcon from '@material-ui/icons/Info';
import FileUploaderButton from '../../../../../Core/Controls/GenericFileUploader';
import { AppStorage } from '../../../../../Core/Basic/storage-service';

const JobBookedDetail = (props) => {
    let getAccessParam = AppStorage.getAllowAcces();

    const [jobDetails, setJobDetails] = useState({
        jobDepotID: '',
        jobTypeID: '',
        mileageRequired: false,
        jobServiceAgentID: '',
        vehicleMake: '',
        vehicleModel: '',
        jobRegNumber: ''
    });
    const [jobDropDowns, setJobDropDowns] = useState({ serviceAgents: [], depots: [], jobTypes: [] });
    const [errors, setErrors] = useState({});
    const { showToastSuccess, showToastError } = useToast();
    const history = useHistory();
    const [tempOptions, setTempOptions] = useState({
        jobServiceAgentIDValue: null,
        jobDepotIDValue: null,
        jobTypeIDValue: null
    });
    const pullJobDropDowns = async () => {
        let res = await getAllJobDropDowns(props.vehicleID);
        return res.data;
    };
    const { userRoles } = useLoginInfo();
    const isServiceAgent = userRoles.includes('serviceagent');

    const PullData = async () => {
        let res = await getJobDetailsById(props.scheduleJobID);
        let res1 = res.data.list;
        let data = res1?.length > 0 ? res.data.list[0] : res.data.list;
        if (data) {
            setJobDetails((st) => ({
                ...st,
                jobID: data.jobID,
                jobAgentOrderNo: data.jobAgentOrderNo,
                jobOrderDate: formatters.DateOnlyFormatter(data.jobDate),
                jobOrderTotal: data.orderTotal,
                jobCustomerOrderNo: data.jobCustomerOrderNo || '',
                jobEstimatedCost: data.jobEstimatedCost || '',
                jobProformaInvNet: data.jobProformaInvNet || '',
                jobLimit: data.jobLimit || '',
                jobAuthorityRef: data.jobAuthorityRef || '',
                jobMileageDate: data.jobMileageDate || '',
                jobMileage: data.jobMileage || '',
                jobDepotID: data.jobDepotID || '',
                jobTypeID: data.jobTypeID || '',
                jobServiceAgentID: data.jobServiceAgentID || '',
                jobVehicleID: data.jobVehicleID,
                jobRegNumber: data.jobRegNumber,
                vehicleMake: data.vehicleMake,
                vehicleModel: data.vehicleModel,
                vehicleJobStatus: data.vehicleJobStatus,
                jobStatusID: data.jobStatusID,
                jobApprovedDate: formatters.DateFormatter(data.jobApprovedDate),
                approvedBy: data.approvedBy,
                jobApprovedOrderNo: data.jobApprovedOrderNo,
                jobApproved: data.jobApproved,
                serviceAgentName: data.serviceAgentName,
                depotName: data.depotName,
                mileageRequired: data.mileageRequired,
                customerName: data.customerCompanyName,
                refreshDependancy: new Date(),
                jobDescription: data.jobDescription,
                fileCount: data.fileCount
            }));
        }
    };

    useEffect(async () => {
        PullData();
        let dropDowns = await pullJobDropDowns();
        setJobDropDowns(dropDowns);
    }, []);

    const refreshScreen = (res) => {
        res && setJobDetails((st) => ({ ...st, refreshDependancy: new Date() }));
        res && PullData();
    };

    useEffect(() => {
        let newSt = {};

        if (jobDetails.jobServiceAgentID && jobDropDowns.serviceAgents?.length > 0) {
            newSt.jobServiceAgentIDValue = jobDropDowns.serviceAgents.find(({ id }) => id === jobDetails.jobServiceAgentID);
        }
        if (jobDetails.jobDepotID && jobDropDowns.depots?.length > 0) {
            newSt.jobDepotIDValue = jobDropDowns.depots.find(({ id }) => id === jobDetails.jobDepotID);
        }
        if (jobDetails.jobTypeID && jobDropDowns.jobTypes?.length > 0) {
            newSt.jobTypeIDValue = jobDropDowns.jobTypes.find(({ id }) => id === jobDetails.jobTypeID);
        }
        setTempOptions((st) => ({
            ...st,
            ...newSt
        }));
    }, [jobDetails.jobServiceAgentID, jobDetails.jobDepotID, jobDropDowns.depots, jobDetails.jobTypeID, jobDropDowns.jobTypes, jobDropDowns.serviceAgents]);

    const tempTab = [
        {
            label: 'Upcoming Events',
            body: <UpcomingEvents vehicleID={props.vehicleID} refreshDependancy={jobDetails.refreshDependancy} />
        }
    ];

    const tabs = () => {
        const tempCols = [...tempTab];
        if (!isServiceAgent) {
            tempCols.splice(1, 0, {
                label: 'History',
                body: <JobHistory jobID={props.scheduleJobID} refreshDependancy={jobDetails.refreshDependancy} />
            });
        }
        return tempCols;
    };

    const validate = () => {
        let noErrors = true;
        let err = {};
        if (!tempOptions.jobDepotIDValue) {
            err.jobDepotID = true;
            noErrors = false;
        }
        if (!tempOptions.jobTypeIDValue) {
            err.jobTypeID = true;
            noErrors = false;
        }
        if (!tempOptions.jobServiceAgentIDValue) {
            err.jobServiceAgentID = true;
            noErrors = false;
        }
        // if (+jobDetails.jobEstimatedCost < 0) {
        //     err.jobEstimatedCost = true;
        //     noErrors = false;
        // }
        // if (+jobDetails.jobProformaInvNet < 0) {
        //     err.jobProformaInvNet = true;
        //     noErrors = false;
        // }
        if (+jobDetails.jobLimit < 0) {
            err.jobLimit = true;
            noErrors = false;
        }
        if (+jobDetails.jobMileage < 0) {
            err.jobMileage = true;
            noErrors = false;
        }

        setErrors(err);
        return noErrors;
    };

    const handleData = async () => {
        if (validate()) {
            let response = await postJobDetails({
                jobID: props.scheduleJobID,
                jobServiceAgentID: tempOptions.jobServiceAgentIDValue?.id,
                jobDepotID: tempOptions.jobDepotIDValue?.id,
                jobTypeID: tempOptions.jobTypeIDValue?.id,
                jobCustomerOrderNo: jobDetails.jobCustomerOrderNo || null,
                jobEstimatedCost: jobDetails.jobEstimatedCost || null,
                jobProformaInvNet: jobDetails.jobProformaInvNet || null,
                jobLimit: jobDetails.jobLimit || null,
                jobAuthorityRef: jobDetails.jobAuthorityRef || null,
                jobMileage: jobDetails.jobMileage || null,
                jobMileageDate: jobDetails.jobMileageDate || null
            });
            if (response) {
                response.success ? showToastSuccess('Updated Job Details Successfully') : showToastError(response.message || 'Something went wrong');
            } else {
                showToastError('Something went wrong');
            }
        }
    };

    const valueChanged = (ev) => {
        let temp = ev.target;
        setJobDetails((st) => ({
            ...st,
            [temp.name]: temp.value
        }));
        setErrors({});
    };

    const singleSelectChange = (nm) => (ev, val) => {
        setTempOptions((st) => ({ ...st, [nm]: val }));
    };

    const goPreviousScreen = () => {
        history.goBack();
    };
    const getMakeModel =
        jobDetails.vehicleMake || jobDetails.vehicleModel
            ? ` (${jobDetails.vehicleMake ? jobDetails.vehicleMake : ''} ${jobDetails.vehicleModel ? jobDetails.vehicleModel : ''})`
            : '';
            const handleEditRegClick = (val) => {
                history.push({
                    pathname: `/controller/editVehicle/${props.vehicleID}`,
                    search: ''
                });
            };
    return (
        <div className="controller-screen" style={{ padding: '1px' }}>
            <StickyNotes frompage="Jobs" noteRefsGuidID={props.scheduleJobID} />
            <Grid container className="mt_10">
                <Grid item xs={12} lg={12}>
                    <Grid item container xs={12} style={{ backgroundColor: '#f5f9f9', padding: '0 5px' }}>
                        <Grid item xs={6} sm={4} md={2}>
                            <FormHelperText shrink>Job Reference</FormHelperText>
                            <Typography variant="button">{jobDetails?.jobAgentOrderNo || ''}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}>
                            <FormHelperText shrink>Order Date</FormHelperText>
                            <Typography variant="button">{jobDetails.jobOrderDate}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} md={2}>
                            <FormHelperText shrink>Customer</FormHelperText>
                            <Typography variant="button">{jobDetails.customerName}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} md={2}>
                            <FormHelperText shrink>Vehicle Detail</FormHelperText>
                            <Typography variant="button">
                            <span onClick={handleEditRegClick} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                                    {jobDetails.jobRegNumber}
                                </span>
                                {getMakeModel}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}>
                            <FormHelperText shrink>Job Status</FormHelperText>
                            <Typography variant="button">{jobDetails.vehicleJobStatus}</Typography>
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}>
                            <FormHelperText>Total</FormHelperText>
                            <Typography variant="button" style={{ fontSize: 19, fontWeight: 'bold', color: 'black' }}>
                                £{jobDetails.jobOrderTotal?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 3 }}>
                    <Alert icon={<InfoIcon fontSize="inherit" />} severity="warning" className="alert-items">
                        {jobDetails.jobDescription}
                    </Alert>
                </Grid>
                <Grid container spacing={2} style={{ marginTop: 5 }}>
                    <Grid container item xs={12} lg={6}>
                        <Grid container item xs={12} justify="space-between" style={{ paddingTop: '4px' }} className="grid-style grey-grid">
                            <Grid item>
                                <Typography variant="h5" color="secondary">
                                    Job Details
                                    <span style={{ position: 'relative', top: '-7px', left: '7px' }}>
                                        <FileUploaderButton
                                            recordId={props.scheduleJobID}
                                            fileUploaderConfigName="JobScreen"
                                            fileCount={jobDetails.fileCount}
                                            gridRelaod={() => refreshScreen(true)}
                                        />
                                    </span>
                                </Typography>
                            </Grid>
                            <Grid item style={{ padding: '2px 5px 6px 2px' }}>
                                {jobDetails.vehicleJobStatus && (
                                    <StatusBasedButton
                                        refreshScreen={refreshScreen}
                                        handleData={handleData}
                                        vehicleID={props.vehicleID}
                                        position={props.position}
                                        status={jobDetails.jobStatusID}
                                        data={jobDetails}
                                        jobID={props.scheduleJobID ? props.scheduleJobID : jobDetails.jobID}
                                        goPreviousScreen={goPreviousScreen}
                                        showSave={true}
                                        btnType="chip"
                                        isShownHeadrer={true}
                                        otherOptionsBtnShow={true}
                                    />
                                )}
                            </Grid>
                        </Grid>

                        <Grid container style={{ paddingTop: '10px' }} className="grid-style grey-grid">
                            {jobDetails.jobStatusID == 'JC' && jobDetails.jobApproved ? (
                                <JobDetailsInfo jobDetails={jobDetails} />
                            ) : (
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={8} className="mandatory-fields">
                                        <InputLabel required shrink>
                                            Service Agent
                                        </InputLabel>
                                        <SingleSelect
                                            options={jobDropDowns.serviceAgents}
                                            value={tempOptions.jobServiceAgentIDValue}
                                            onChange={singleSelectChange('jobServiceAgentIDValue')}
                                            disabled={isServiceAgent && props.scheduleJobID}
                                        />
                                        <FormHelperText error>{errors.jobServiceAgentID ? 'This field is required' : null}</FormHelperText>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4} className="mandatory-fields">
                                        <InputLabel required shrink>
                                            Job Type
                                        </InputLabel>
                                        <SingleSelect
                                            options={jobDropDowns.jobTypes}
                                            value={tempOptions.jobTypeIDValue}
                                            onChange={singleSelectChange('jobTypeIDValue')}
                                        />
                                        <FormHelperText error>{errors.jobTypeID ? 'This field is required' : null}</FormHelperText>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={8} className="mandatory-fields">
                                        <InputLabel required shrink>
                                            Job Depot
                                        </InputLabel>
                                        <SingleSelect
                                            options={jobDropDowns.depots}
                                            value={tempOptions.jobDepotIDValue}
                                            onChange={singleSelectChange('jobDepotIDValue')}
                                        />
                                        <FormHelperText error>{errors.jobDepotID ? 'This field is required' : null}</FormHelperText>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <SimpleField
                                            inputLabel="Order Limit"
                                            type="number"
                                            value={jobDetails.jobLimit || ''}
                                            name="jobLimit"
                                            onChange={valueChanged}
                                            disabled={!getAccessParam['CanUpdateJobLimit']}
                                        />
                                        <FormHelperText error>{errors.jobLimit ? 'This value can not be negative' : null}</FormHelperText>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <InputLabel shrink>Job Mileage Date</InputLabel>
                                        <DatePicker
                                            inputProps={{
                                                min: new Date().toISOString().slice(0, 10)
                                            }}
                                            value={moment(jobDetails.jobMileageDate).format('YYYY-MM-DD')}
                                            name="jobMileageDate"
                                            onChange={valueChanged}
                                        />
                                        <FormHelperText error>{errors.jobMileageDate ? 'This value can not be negative' : null}</FormHelperText>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4}>
                                        <SimpleField
                                            inputLabel="Job Mileage"
                                            type="number"
                                            value={jobDetails.jobMileage}
                                            name="jobMileage"
                                            onChange={valueChanged}
                                        />
                                        <FormHelperText error>{errors.jobMileage ? 'This value can not be negative' : null}</FormHelperText>
                                    </Grid>
                                </Grid>
                            )}
                            <Grid container item xs={12} style={{ paddingBottom: '5px' }}>
                                {jobDetails.vehicleJobStatus && (
                                    <StatusBasedButton
                                        refreshScreen={refreshScreen}
                                        handleData={handleData}
                                        vehicleID={props.vehicleID}
                                        position={props.position}
                                        status={jobDetails.jobStatusID}
                                        data={jobDetails}
                                        jobID={props.scheduleJobID ? props.scheduleJobID : jobDetails.jobID}
                                        goPreviousScreen={goPreviousScreen}
                                        showSave={true}
                                        otherOptionsBtnShow={false}
                                    />
                                )}
                            </Grid>
                            {/* <br />
                            <Grid item xs={12}>
                                <JobItemsScreen scheduleJobID={props.scheduleJobID} />
                            </Grid> */}
                        </Grid>
                    </Grid>

                    <Grid item xs={12} lg={6} style={{ paddingTop: '0px' }}>
                        <Grid item xs={12}>
                            <TabView tabList={tabs()} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};
export default JobBookedDetail;
