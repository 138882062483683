import React from 'react';
import singleAxel from './VehicleDiagrams/images1.png';
import doubleAxelOne from './VehicleDiagrams/axImage01.png';
import doubleAxelTwo from './VehicleDiagrams/azImage02.png';
import doubleAxelThree from './VehicleDiagrams/axImage03.png';
import Grid from '@material-ui/core/Grid';
import { InputLabel, Typography, Paper } from '@material-ui/core';

const style = {
    typography: {
        padding: '1.5px 14px'
        // fontWeight: 600
        // width: '560px'
    },
    brakeTypography: {
        padding: '0px 35px'
        // fontWeight: 600
    },
    Ax2osOut: {
        marginTop: '-5px'
    },
    Ax2osin: {
        marginTop: 8
    },
    Ax2nsin: {
        marginTop: 150
    },
    axBreak: {
        paddingLeft: '0px'
    },
    axOsout: { marginTop: 50 },
    axNsout: { marginTop: 168 },
    axImage: { marginTop: 5, marginBottom: 5 }
};

const setValue = (val, unit) => {
    if (val) {
        return (
            <>
                {val}
                <small>{unit}</small>
            </>
        );
    } else {
        return 'N/A';
    }
};

const EditAxel = (props) => {
    const { state, classes } = props;

    return (
        <Grid container item xs={12}>
            <Paper className={classes.paper}>
                <Grid container>
                    <h2 className={classes.headings}>Tyres Checks</h2>
                </Grid>
                <Grid container spacing={4} xs={12} style={{ marginBottom: 5 }} justify="center">
                    {/* Axel 1...................... */}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                        <Grid item xs={12} sm={12}>
                            <h3 className={classes.axelTitles}>AXLE 1</h3>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Grid container xs={12} direction="row" justify="space-evenly">
                                <Grid item xs={2}>
                                    <InputLabel style={style.axOsout}>Depth</InputLabel>
                                    <Typography style={style.typography} variant="subtitle1">
                                        {setValue(state.tyreAxles[0]?.osTreadDepth, 'mm')}
                                    </Typography>

                                    <InputLabel style={style.axNsout}>Depth</InputLabel>
                                    <Typography style={style.typography} variant="subtitle1">
                                        {setValue(state.tyreAxles[0]?.nsTreadDepth, 'mm')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <InputLabel style={style.axBreak}>Brake lining</InputLabel>
                                    <Typography style={style.brakeTypography} variant="subtitle1">
                                        {setValue(state.tyreAxles[0]?.nsBrakeLineRemaining, '%')}
                                    </Typography>
                                    <img src={singleAxel} height="250px" width="100%" style={style.axImage} />
                                    <InputLabel style={style.axBreak}>Brake lining</InputLabel>
                                    <Typography style={style.brakeTypography} variant="subtitle1">
                                        {setValue(state.tyreAxles[0]?.osBrakeLineRemaining, '%')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <InputLabel style={style.axOsout}>Pressure</InputLabel>
                                    <Typography style={style.typography} variant="subtitle1">
                                        {setValue(state.tyreAxles[0]?.osTyrePressure, 'psi')}
                                    </Typography>
                                    <InputLabel style={style.axNsout}>Pressure</InputLabel>
                                    <Typography style={style.typography} variant="subtitle1">
                                        {setValue(state.tyreAxles[0]?.nsTyrePressure, 'psi')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* Axel 2 ..................... */}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                        <h3 className={classes.axelTitles}>AXLE 2</h3>
                        <Grid item xs={12} sm={12}>
                            <Grid container xs={12} direction="row" justify="space-evenly">
                                <Grid item xs={2}>
                                    <InputLabel style={style.Ax2osOut}>Depth</InputLabel>
                                    <Typography style={style.typography} variant="subtitle1">
                                        {setValue(state.tyreAxles[1]?.osTreadDepth, 'mm')}
                                    </Typography>
                                    <InputLabel style={style.Ax2osin}>Depth</InputLabel>
                                    <Typography style={style.typography} variant="subtitle1">
                                        {setValue(state.tyreAxles[1]?.osFrontTreadDepth, 'mm')}
                                    </Typography>
                                    <InputLabel style={style.Ax2nsin}>Depth</InputLabel>
                                    <Typography style={style.typography} variant="subtitle1">
                                        {setValue(state.tyreAxles[1]?.nsFrontTreadDepth, 'mm')}
                                    </Typography>
                                    <InputLabel style={style.Ax2osin}>Depth</InputLabel>
                                    <Typography style={style.typography} variant="subtitle1">
                                        {setValue(state.tyreAxles[1]?.nsTreadDepth, 'mm')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <img src={doubleAxelOne} height="100px" width="100%" />
                                    <InputLabel style={style.axBreak}>Brake lining</InputLabel>
                                    <Typography style={style.brakeTypography} variant="subtitle1">
                                        {setValue(state.tyreAxles[1]?.osBrakeLineRemaining, '%')}
                                    </Typography>

                                    <img src={doubleAxelTwo} height="50px" width="100%" />
                                    <InputLabel style={style.axBreak}>Brake lining</InputLabel>
                                    <Typography style={style.brakeTypography} variant="subtitle1">
                                        {setValue(state.tyreAxles[1]?.nsBrakeLineRemaining, '%')}
                                    </Typography>

                                    <img src={doubleAxelThree} height="100px" width="100%" />
                                </Grid>
                                <Grid item xs={2}>
                                    <InputLabel style={style.Ax2osOut}>Pressure</InputLabel>
                                    <Typography style={style.typography} variant="subtitle1">
                                        {setValue(state.tyreAxles[1]?.osTyrePressure, 'psi')}
                                    </Typography>
                                    <InputLabel style={style.Ax2osin}>Pressure</InputLabel>
                                    <Typography style={style.typography} variant="subtitle1">
                                        {setValue(state.tyreAxles[1]?.osFrontTyrePressure, 'psi')}
                                    </Typography>

                                    <InputLabel style={style.Ax2nsin}>Pressure</InputLabel>
                                    <Typography style={style.typography} variant="subtitle1">
                                        {setValue(state.tyreAxles[1]?.nsFrontTyrePressure, 'psi')}
                                    </Typography>

                                    <InputLabel style={style.Ax2osin}>Pressure</InputLabel>
                                    <Typography style={style.typography} variant="subtitle1">
                                        {setValue(state.tyreAxles[1]?.nsTyrePressure, 'psi')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Axel 3 ..................... */}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                        <h3 className={classes.axelTitles}>AXLE 3</h3>
                        <Grid item xs={12} sm={12}>
                            <Grid container xs={12} direction="row" justify="space-evenly">
                                <Grid item xs={2}>
                                    <InputLabel style={style.Ax2osOut}>Depth</InputLabel>
                                    <Typography style={style.typography} variant="subtitle1">
                                        {setValue(state.tyreAxles[2]?.osTreadDepth, 'mm')}
                                    </Typography>
                                    <InputLabel style={style.Ax2osin}>Depth</InputLabel>
                                    <Typography style={style.typography} variant="subtitle1">
                                        {setValue(state.tyreAxles[2]?.osFrontTreadDepth, 'mm')}
                                    </Typography>
                                    <InputLabel style={style.Ax2nsin}>Depth</InputLabel>
                                    <Typography style={style.typography} variant="subtitle1">
                                        {setValue(state.tyreAxles[2]?.nsFrontTreadDepth, 'mm')}
                                    </Typography>
                                    <InputLabel style={style.Ax2osin}>Depth</InputLabel>
                                    <Typography style={style.typography} variant="subtitle1">
                                        {setValue(state.tyreAxles[2]?.nsTreadDepth, 'mm')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <img src={doubleAxelOne} height="100px" width="100%" />
                                    <InputLabel style={style.axBreak}>Brake lining</InputLabel>
                                    <Typography style={style.brakeTypography} variant="subtitle1">
                                        {setValue(state.tyreAxles[2]?.osBrakeLineRemaining, '%')}
                                    </Typography>

                                    <img src={doubleAxelTwo} height="50px" width="100%" />
                                    <InputLabel style={style.axBreak}>Brake lining</InputLabel>
                                    <Typography style={style.brakeTypography} variant="subtitle1">
                                        {setValue(state.tyreAxles[2]?.nsBrakeLineRemaining, '%')}
                                    </Typography>

                                    <img src={doubleAxelThree} height="100px" width="100%" />
                                </Grid>
                                <Grid item xs={2}>
                                    <InputLabel style={style.Ax2osOut}>Pressure</InputLabel>
                                    <Typography style={style.typography} variant="subtitle1">
                                        {setValue(state.tyreAxles[2]?.osTyrePressure, 'psi')}
                                    </Typography>
                                    <InputLabel style={style.Ax2osin}>Pressure</InputLabel>
                                    <Typography style={style.typography} variant="subtitle1">
                                        {setValue(state.tyreAxles[2]?.osFrontTyrePressure, 'psi')}
                                    </Typography>

                                    <InputLabel style={style.Ax2nsin}>Pressure</InputLabel>
                                    <Typography style={style.typography} variant="subtitle1">
                                        {setValue(state.tyreAxles[2]?.nsFrontTyrePressure, 'psi')}
                                    </Typography>

                                    <InputLabel style={style.Ax2osin}>Pressure</InputLabel>
                                    <Typography style={style.typography} variant="subtitle1">
                                        {setValue(state.tyreAxles[2]?.nsTyrePressure, 'psi')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* Axel 4 ..................... */}
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={3}>
                        <h3 className={classes.axelTitles}>AXLE 4</h3>
                        <Grid item xs={12} sm={12}>
                            <Grid container xs={12} direction="row" justify="space-evenly">
                                <Grid item xs={2}>
                                    <InputLabel style={style.Ax2osOut}>Depth</InputLabel>
                                    <Typography style={style.typography} variant="subtitle1">
                                        {setValue(state.tyreAxles[3]?.osTreadDepth, 'mm')}
                                    </Typography>
                                    <InputLabel style={style.Ax2osin}>Depth</InputLabel>
                                    <Typography style={style.typography} variant="subtitle1">
                                        {setValue(state.tyreAxles[3]?.osFrontTreadDepth, 'mm')}
                                    </Typography>
                                    <InputLabel style={style.Ax2nsin}>Depth</InputLabel>
                                    <Typography style={style.typography} variant="subtitle1">
                                        {setValue(state.tyreAxles[3]?.nsFrontTreadDepth, 'mm')}
                                    </Typography>
                                    <InputLabel style={style.Ax2osin}>Depth</InputLabel>
                                    <Typography style={style.typography} variant="subtitle1">
                                        {setValue(state.tyreAxles[3]?.nsTreadDepth, 'mm')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <img src={doubleAxelOne} height="100px" width="100%" />
                                    <InputLabel style={style.axBreak}>Brake lining</InputLabel>
                                    <Typography style={style.brakeTypography} variant="subtitle1">
                                        {setValue(state.tyreAxles[3]?.osBrakeLineRemaining, '%')}
                                    </Typography>

                                    <img src={doubleAxelTwo} height="50px" width="100%" />
                                    <InputLabel style={style.axBreak}>Brake lining</InputLabel>
                                    <Typography style={style.brakeTypography} variant="subtitle1">
                                        {setValue(state.tyreAxles[3]?.nsBrakeLineRemaining, '%')}
                                    </Typography>

                                    <img src={doubleAxelThree} height="100px" width="100%" />
                                </Grid>
                                <Grid item xs={2}>
                                    <InputLabel style={style.Ax2osOut}>Pressure</InputLabel>
                                    <Typography style={style.typography} variant="subtitle1">
                                        {setValue(state.tyreAxles[3]?.osTyrePressure, 'psi')}
                                    </Typography>
                                    <InputLabel style={style.Ax2osin}>Pressure</InputLabel>
                                    <Typography style={style.typography} variant="subtitle1">
                                        {setValue(state.tyreAxles[3]?.osFrontTyrePressure, 'psi')}
                                    </Typography>

                                    <InputLabel style={style.Ax2nsin}>Pressure</InputLabel>
                                    <Typography style={style.typography} variant="subtitle1">
                                        {setValue(state.tyreAxles[3]?.nsFrontTyrePressure, 'psi')}
                                    </Typography>

                                    <InputLabel style={style.Ax2osin}>Pressure</InputLabel>
                                    <Typography style={style.typography} variant="subtitle1">
                                        {setValue(state.tyreAxles[3]?.nsTyrePressure, 'psi')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
};
export default EditAxel;
