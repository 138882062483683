import { postWithAuth, getWithAuth, putWithAuth } from '../../Basic/HttpService';
import { memoize } from 'lodash';

export const getUserPermissionsFromID = async (id) => {
    let res = await getWithAuth(`/api/userpermissions?userid=${id}`);
    return res;
};
export const setUserPermissions = async (data) => {
    let res = await postWithAuth(`/api/userpermissions`, data);
    return res;
};

export const getAllUsers = async () => {
    let res = await getWithAuth('/users');
    return res;
};

export const getUserById = async (Id) => {
    let res = await getWithAuth(`/users/${Id}`);
    return res;
};

export const getAssignPermission = async (id) => {
    let res = await getWithAuth(`/GetPermissionMenus/${id}`);
    return res;
};

export const savePermissionMenu = async (userId, data) => {
    let res = await postWithAuth(`/SavePermissionsMenus`, {
        PermissionMenuIds: data,
        UserId: userId
    });
    return res;
};

const checkArray = (data) => {
    let x = typeof data;
    return x === 'number' ? [data] : data;
};

export const Adduser = async (data) => {
    let res = await postWithAuth('/register', {
        displayName: data.name,
        userInitials: data.userInitials,
        password: data.password,
        confirmPassword: data.confirm,
        phoneNo: data.phone,
        isActive: data.isActive,
        isLocked: data.isLocked,
        email: data.email,
        userRole: data.userType,
        branchIds: data.branchIds,
        isAllBranch: data.allbranch,
        customerIds: checkArray(data.customerIds), //data.customerIds === [] ? [] : [data.customerIds],
        moduleIds: data.moduleIds,
        depotsIds: checkArray(data.depotIds),
        isReadOnly: data.isReadOnly,
        serviceAgentIds: data.serviceAgentId,
        driverId: data.driverId
    });
    return res;
};

export const UpdateUser = async (data) => {
    let res = await putWithAuth(`/users/${data.userID}`, {
        ...(data.isUpdateEmail && { password: data.password }),
        displayName: data.name,
        userInitials: data.userInitials,
        phoneNo: data.phone,
        isActive: data.isActive,
        isLocked: data.isLocked,
        email: data.email,
        userRole: data.userType,
        branchIds: data.branchIds,
        isAllBranch: data.allbranch,
        customerIds: checkArray(data.customerIds), //data.customerIds ? [data.customerIds] : [],
        moduleIds: data.moduleIds,
        depotsIds: checkArray(data.depotIds),
        isalldepots: data.alldepots,
        isReadOnly: data.isReadOnly,
        serviceAgentIds: data.serviceAgentId,
        driverId: data.driverId
    });
    return res;
};

export const getUserDropdown = memoize(async () => {
    let res = await getWithAuth('/usersScreenDropdowns');
    return res;
});

export const getDriverBasedOnCustndDepot = async (cust, depot) => {
    let res = await getWithAuth(`Drivers/ListBycustomerNDepot?CustomerID=${cust}&DepotID=${depot}`);
    return res;
};

export const ResetPassByAdmin = async (userId, newpassword, confirm) => {
    let res = await postWithAuth(`/overwritePassword/${userId}`, {
        newPassword: newpassword,
        confirmPassword: confirm
    });
    return res;
};
