import React, { useState, useContext } from 'react';
import { DatePicker, CustomChip, SpeedDials } from '../../../../../Core/Controls';
import CustomizedTables from '../../../../../Core/Controls/Table';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AddEditDepots from './addEditDepots';
import { DepotProvider, DepotScreenContext } from './depotProvider';
import FileUploaderButton from '../../../../../Core/Controls/GenericFileUploader';
import { formatters } from '../../../../../Core/Controls';
import { AppStorage } from '../../../../../Core/Basic/storage-service';

const EditDepotDash = (props) => {
    let getAccessParam = AppStorage.getAllowAcces();
    const {
        scheduleFields,
        handleSubmit,
        scheduleDetailsChange,
        handleCheckbox,
        closeModal,
        handledepotFields,
        handledepotCheckbox,
        depotFields,
        handleActive
    } = useContext(DepotScreenContext);

    const [state, setstate] = useState({});
    const handleAddPopUp = (id, name) => {
        setstate((st) => ({ ...st, [name]: true, VehicleID: id }));
    };

    const isValue = (val) => {
        return val ? val : 'N/A';
    };
    const isYesNo = (val) => {
        return val ? 'Yes' : 'No';
    };
    const tableData = [
        {
            name1: 'Customer',
            value1: isValue(depotFields.customer),
            name2: 'Telephone',
            value2: formatters.phoneNumberFormatter(depotFields.depotTelephone),
            name3: 'Contact Number',
            value3: formatters.phoneNumberFormatter(depotFields.depotContact),
            name4: 'Email',
            value4: formatters.mailFormatter(depotFields.depotEmail)
        },
        {
            name1: 'Address',
            value1: formatters.addressFormatter(
                depotFields.address1,
                depotFields.address2,
                depotFields.depotTown,
                depotFields.depotPostCode,
                depotFields.depotCounty
            ),
            // value1: depotFields.address1 + ", " + depotFields.address2 + ", " + depotFields.depotTown + ", " + depotFields.depotPostCode + ", " + depotFields.depotCounty,
            name2: 'Customers Ref',
            value2: isValue(depotFields.depotCustomersRef),
            name3: 'Primary Service Agent',
            value3: isValue(depotFields.PrimaryServiceAgent),
            name4: 'Display ISO Week Number?',
            value4: isYesNo(depotFields.displayISOWeek)
        },
        {
            name1: 'Max Schedule Merge Range',
            value1: isValue(depotFields.mergeRange),
            name2: 'Schedule Planner Email',
            value2: formatters.mailFormatter(depotFields.depotSchedulePlannerEmail),
            name3: 'Schedule Booking Mode',
            value3: isValue(depotFields.depotScheduleBookingMode),
            name4: 'Merge Schedule?',
            value4: isYesNo(depotFields.mergeSchedules)
        },
        // {
        //     name1: 'Sunday',
        //     value1: isYesNo(depotFields.depotBookingPreferenceSunday),
        //     name2: 'Monday',
        //     value2: isYesNo(depotFields.depotBookingPreferenceMonday),
        //     name3: 'Tuesday',
        //     value3: isYesNo(depotFields.depotBookingPreferenceTuesday),
        //     name4: 'Wednesday',
        //     value4: isYesNo(depotFields.depotBookingPreferenceWednesday),
        //     name5: 'Thursday',
        //     value5: isYesNo(depotFields.depotBookingPreferenceThursday),
        //     name6: 'Friday',
        //     value6: isYesNo(depotFields.depotBookingPreferenceFriday),
        //     name7: 'Saturday',
        //     value7: isYesNo(depotFields.depotBookingPreferenceSaturday)
        // }
        {
            name1: 'Third Party Planner Email Address',
            value1: formatters.mailFormatter(depotFields.depotThirdPartyPlannerEmailAddress),
            name2: 'Additional Planning Emails',
            value2: formatters.mailFormatter(depotFields.depotAdditionalPlanningEmails),
            name3: 'CPC Holder Email',
            value3: formatters.mailFormatter(depotFields.cPCHolderEmail),
            name4: 'CPC Holder Name',
            value4: isValue(depotFields.cPCHolderName)
        },
        {
            name1: 'Secondary Email',
            value1: formatters.mailFormatter(depotFields.secondaryEmail),
            name2: 'Contact Mobile',
            value2: formatters.phoneNumberFormatter(depotFields.depotContactMobile),
            name3: 'Secondary Contact Number',
            value3: formatters.phoneNumberFormatter(depotFields.depotSecondaryContact),
            name4: 'PCN Email',
            value4: formatters.mailFormatter(depotFields.depotPCNEmail)
        },
        {
            name1: 'Account Name',
            value1: isValue(depotFields.depotAccountName),
            name2: 'Account Email',
            value2: formatters.mailFormatter(depotFields.depotAccountEmail),
            name3: 'Add Paperwork Received?',
            value3: isYesNo(depotFields.addPaperworkReceived),
            name4: 'Traffic Style Colour Coding?',
            value4: isYesNo(depotFields.trafficStyleColourCoding)
        }
    ];
    const Header = () => {
        return (
            <Grid container style={{ alignItems: 'center', padding: '0px 6px' }}>
                <Grid xs={3} sm={4} md={6} item>
                    <Typography variant="h6" color="secondary">
                        Depot Details: {depotFields.name}
                    </Typography>
                </Grid>
                <Grid xs={9} sm={8} md={6} item>
                    <div className="dash-controller-btn" style={{ float: 'right' }}>
                        <FileUploaderButton recordId={props.depotID} fileCount={depotFields.fileCount} fileUploaderConfigName="DepotScreen" />
                        &nbsp;&nbsp;
                        <CustomChip
                            className="btn-m"
                            icon={AddIcon}
                            label="Edit Depot"
                            onClick={() => handleAddPopUp(null, 'showDepotScreen')}
                            // disabled={!getAccessParam['CanUpdateDepots']}
                        />
                    </div>
                </Grid>
            </Grid>
        );
    };
    return (
        <Grid className="controller-screen" style={{ marginTop: '10px' }}>
            {state.showDepotScreen ? (
                <AddEditDepots depotID={props.depotID} onFormClose={props.onFormClose} />
            ) : (
                <CustomizedTables tableHeader={Header()} tableData={tableData} />
            )}
        </Grid>
    );
};

const Screen = (props) => {
    return (
        <DepotProvider {...props}>
            <EditDepotDash {...props} />
        </DepotProvider>
    );
};
export default Screen;
