import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import React, { useEffect, useContext, useState } from 'react';
import { getAllServiceAgentDash } from '../../../Core/Services/ServiceAgent/ServiceAgent';
import LocalTaxiRoundedIcon from '@material-ui/icons/LocalTaxiRounded';
import BuildRoundedIcon from '@material-ui/icons/BuildRounded';
import LocalShippingRoundedIcon from '@material-ui/icons/LocalShippingRounded';
import WorkRoundedIcon from '@material-ui/icons/WorkRounded';
import ExploreRoundedIcon from '@material-ui/icons/ExploreRounded';
import PermDataSettingIcon from '@material-ui/icons/PermDataSetting';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
import { Link } from 'react-router-dom';
import '../ServiceCommon.scss';

const ServiceAgentDash = () => {
    const [state, setState] = useState({
        rows_new: [],
        tilesData: []
    });

    useEffect(async () => {
        let res = await getAllServiceAgentDash();
        const cards = res.data.dashboard;
        setState((st) => ({
            ...st,
            tilesData: cards
        }));
    }, []);

    return (
        <div className="customer-dashboard">
            <Container className="cardGrid" maxWidth="lg">
                <Grid container spacing={4}>
                    {state.tilesData.map((option, index) => (
                        <Grid item key={option.id} xs={12} sm={6} md={4} lg={3} className="upper">
                            <Link
                                style={{ textDecoration: 'none' }}
                                color="inherit"
                                to={
                                    option.code == 'BO'
                                        ? '/maintenance/breakdowns/typebo'
                                        : option.code == 'BR'
                                        ? '/maintenance/breakdowns/typebr'
                                        : option.code == 'DD' || option.code == 'SD'
                                        ? '/'
                                        : `/serviceagent/${option.code}`
                                }
                            >
                                {option.title == 'Awaiting Approvals' ? (
                                    <LocalTaxiRoundedIcon className="icon_Style" />
                                ) : option.title == 'Breakdown Onsite' ? (
                                    <BuildRoundedIcon className="red_iconStyle" />
                                ) : option.title == 'Breakdown Roadside' ? (
                                    <LocalShippingRoundedIcon className="red_iconStyle" />
                                ) : option.title == 'Driver Defects' ? (
                                    <BuildRoundedIcon className="icon_Style" />
                                ) : option.title == 'Estimates Submitted' ? (
                                    <ExploreRoundedIcon className="yellow_iconStyle" />
                                ) : option.title == 'Open Jobs' ? (
                                    <WorkRoundedIcon className="red_iconStyle" />
                                ) : option.title == 'Queried Jobs' ? (
                                    <AssignmentIcon className="yellow_iconStyle" />
                                ) : option.title == 'Schedule Defects' ? (
                                    <PermDataSettingIcon className="red_iconStyle" />
                                ) : option.title == 'Test123' ? (
                                    <AirportShuttleIcon className="purple_iconStyle" />
                                ) : option.title == 'TestTS' ? (
                                    <AccountTreeIcon className="purple_iconStyle" />
                                ) : null}
                                <Card className={`c${index}`}>
                                    <CardContent className="cardContent">
                                        <Typography className=" numaricData">{option.cnt}</Typography>
                                        <Typography component="h1">{option.title}</Typography>
                                    </CardContent>
                                </Card>
                            </Link>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </div>
    );
};
export default ServiceAgentDash;
