import React, { createContext, useEffect, useState } from 'react';
import { Adduser, UpdateUser, getUserById, getDriverBasedOnCustndDepot } from '../../../Core/Services/Admin/userService';
import { useToast } from '../../../Core/Hooks/useToast';
import { validatePassword, emailValidation, singleEmailValidation } from '../../../Core/Basic/StaticService';
import { getUserDropdown } from '../../../Core/Services/Admin/userService';

export const UserScreenContext = createContext();

export const UserProvider = (props) => {
    const { showToastSuccess, showToastError, showToastWarning } = useToast();

    const [state, setState] = useState({
        userType: '',
        email: '',
        name: '',
        userInitials: '',
        phone: '',
        password: '',
        confirm: '',
        allbranch: false,
        alldepots: false,
        isActive: true,
        isLocked: false,
        branchIds: [],
        moduleIds: [],
        customerIds: [],
        depotIds: [],
        matchDepoCustId: [],
        serviceAgentId: [],
        rows: [],
        errors: {},
        isUpdateEmail: false,
        userID: '',
        isReadOnly: false,
        driverList: [],
        driverId: null
    });

    const [btnLoader, setBtnLoader] = useState(false);

    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;
        if (!/[^\s]/.test(state.name)) {
            formIsValid = false;
            errors['name'] = 'User name cannot be empty';
        }
        if (!/[^\s]/.test(state.userInitials)) {
            formIsValid = false;
            errors['userInitials'] = 'User initials cannot be empty';
        }
        if (!singleEmailValidation(state.email)) {
            formIsValid = false;
            errors['email'] = 'Please Enter Valid Email';
        }
        if (!/[^\s]/.test(state.password)) {
            formIsValid = false;
            errors['password'] = 'Password field cannot be empty';
        }
        if (!/[^\s]/.test(state.confirm)) {
            formIsValid = false;
            errors['confirm'] = 'Confirm Password field cannot be empty';
        }
        if (!state.userType) {
            formIsValid = false;
            errors['userType'] = 'User Type cannot be empty';
        }
        setState((st) => ({
            ...st,
            errors: errors
        }));
        return formIsValid;
    };
    const updateValidation = () => {
        let errors = {};
        let formIsValid = true;
        if (!/[^\s]/.test(state.name)) {
            formIsValid = false;
            errors['name'] = 'User name cannot be empty';
        }
        if (!/[^\s]/.test(state.userInitials)) {
            formIsValid = false;
            errors['userInitials'] = 'User initials cannot be empty';
        }
        if (!singleEmailValidation(state.email)) {
            formIsValid = false;
            errors['email'] = 'Please Enter Valid Email';
        }
        if (!state.userType) {
            formIsValid = false;
            errors['userType'] = 'User Type cannot be empty';
        }
        if (state.isUpdateEmail && !/[^\s]/.test(state.password)) {
            formIsValid = false;
            errors['password'] = 'Password field cannot be empty';
        }
        if (state.isUpdateEmail && !/[^\s]/.test(state.confirm)) {
            formIsValid = false;
            errors['confirm'] = 'Confirm Password field cannot be empty';
        }
        setState((st) => ({
            ...st,
            errors: errors
        }));
        return formIsValid;
    };

    const [masterData, setMasterData] = useState({
        branchs: [],
        modules: [],
        customer: [],
        depot: [],
        serviceAgentList: []
    });

    const [selectedData, setSelectedData] = useState({
        branchs: [],
        modules: [],
        depots: [],
        serviceAgent: []
    });

    const updateState = (update) => {
        setState((st) => ({ ...st, ...update }));
    };

    const onSelectCheckbox = (event) => {
        const { name, checked } = event.target;
        if (checked) {
            setState((st) => {
                const nst = { ...st };
                nst[name] = checked;
                if (name === 'allbranch') {
                    nst.branch = [...masterData.branchs.map((c) => c.id)];
                } else if (name === 'alldepots') {
                    nst.depotIds = [...state.matchDepoCustId.map((c) => c.id)];
                } else {
                    nst[name] = checked;
                }
                return nst;
            });
        } else {
            setState((st) => {
                const nst = { ...st, [name]: checked };
                return nst;
            });
        }
    };

    const Updateuser = async (e) => {
        if (updateValidation()) {
            if (state.isUpdateEmail && state.password !== state.confirm) {
                showToastWarning('Password and Confirm Password should match.');
            } else {
                setBtnLoader(true);
                let res = await UpdateUser(state);
                if (res.success) {
                    showToastSuccess('User updated successfully');
                    props.onFormSubmit(true);
                } else {
                    showToastError(res.message);
                }
                setBtnLoader(false);
            }
        }
    };

    const handleSubmit = async (e) => {
        if (handleValidation()) {
            if (!validatePassword(state.password)) {
                showToastWarning('Password should be complex with 8 char minimum.');
            } else if (state.password !== state.confirm) {
                showToastWarning('Password and Confirm Password should match.');
            } else {
                setBtnLoader(true);
                let res = await Adduser(state);
                if (res.success) {
                    showToastSuccess('User added successfully');
                    props.onFormSubmit(true);
                } else {
                    showToastError(res.message);
                }
                setBtnLoader(false);
            }
        }
    };

    const ModuleChange = (e, value) => {
        setSelectedData((st) => ({ ...st, modules: value }));
        setState((st) => {
            const nst = { ...st, moduleIds: value.map((c) => c.id) };
            return nst;
        });
    };

    const BranchChange = (e, value) => {
        setSelectedData((st) => ({ ...st, branchs: value }));
        setState((st) => {
            const nst = { ...st, branchIds: value.map((c) => c.id) };
            return nst;
        });
    };

    const ServiceChange = (e, value) => {
        setSelectedData((st) => ({ ...st, serviceAgent: value }));
        setState((st) => {
            const nst = { ...st, serviceAgentId: value.map((c) => c.id) };
            return nst;
        });
    };
    const DepotChange = (e, value) => {
        setSelectedData((st) => ({ ...st, depots: value }));
        setState((st) => {
            const nst = { ...st, depotIds: value.map((c) => c.id) };
            return nst;
        });
    };

    const handleClose = (res) => {
        props.onFormSubmit(res);
    };

    const handleClick = (name, value) => {
        setState((st) => {
            const nst = { ...st, [name]: value };
            return nst;
        });
    };

    useEffect(async () => {
        let newMatchDepoCustId = [];
        if (state.customerIds) {
            masterData.depot.filter((obj) => {
                if (state.customerIds === obj.customerId) {
                    newMatchDepoCustId.push(obj);
                }
            });
            setState((st) => ({ ...st, matchDepoCustId: newMatchDepoCustId }));
        }
    }, [state.customerIds]);

    useEffect(() => {
        if (props.userID) {
            const selectedModule = masterData.modules.filter((w) => state.moduleIds.indexOf(w.id) > -1);
            const selectedBranch = masterData.branchs.filter((w) => state.branchIds.indexOf(w.id) > -1);
            // const selectedDepots = masterData.depot.filter((w) => state.depotIds.indexOf(w.id) > -1);
            const selectedServiceAgent = masterData.serviceAgentList.filter((w) => state.serviceAgentId.indexOf(w.id) > -1);
            setSelectedData((st) => ({
                ...st,
                modules: selectedModule,
                branchs: selectedBranch,
                //  depots: selectedDepots,
                serviceAgent: selectedServiceAgent
            }));
            if (masterData.branchs.length > 0 && masterData.branchs.length === selectedBranch.length) {
                setState((st) => ({ ...st, allbranch: true }));
            }
        }
    }, [
        // state.depotIds,
        state.branchIds,
        state.moduleIds,
        masterData.branchs,
        masterData.modules,
        masterData.depot,
        state.serviceAgentId,
        masterData.serviceAgentList
    ]);

    useEffect(async () => {
        let result = await getUserDropdown();
        if (result.success) {
            const customers = result.data.customers.map((w) => ({
                id: w.customerID,
                name: w.customerCompanyName
            }));
            const modules = result.data.modules.map((w) => ({
                id: w.moduleID,
                name: w.moduleName
            }));
            const depots = result.data.depots.map((w) => ({
                id: w.depotID,
                name: w.depotName,
                customerId: w.depotCustomerID
            }));
            setMasterData((st) => ({
                ...st,
                branchs: result.data.branches.map((w) => ({
                    id: w.branchID,
                    name: w.branchShortName
                }))
            }));
            setMasterData((st) => ({ ...st, customer: customers, modules: modules, depot: depots, serviceAgentList: result.data.serviceAgents }));
        }
        if (props.userID) {
            let res = await getUserById(props.userID);
            setState((st) => ({
                ...st,
                userID: res.data.id,
                name: res.data.displayName,
                userInitials: res.data.userInitials,
                phone: res.data.phoneNo,
                email: res.data.email,
                userType: res.data.userRole,
                isActive: res.data.isActive,
                isLocked: res.data.isLocked,
                branchIds: res.data.branchIds,
                moduleIds: res.data.moduleIds,
                customerIds: res.data.customerIds[0],
                isReadOnly: res.data.isReadOnly,
                depotIds: res.data.depotsIds[0],
                canOverrideMandatorySteps: res.data.canOverrideMandatorySteps,
                serviceAgentId: res.data.serviceAgentIds,
                driverId: res.data.driverId
            }));
        }
    }, []);

    useEffect(async () => {
        let res = await getDriverBasedOnCustndDepot(state.customerIds, state.depotIds);
        if (res.success) {
            if (res.data?.list) {
                const driver = res?.data?.list.map((w) => ({
                    id: w.driverID,
                    name: w.driver
                }));
                setState((st) => ({ ...st, driverList: driver || [] }));
            }
        }
    }, [state.customerIds, state.depotIds]);
    return (
        <UserScreenContext.Provider
            value={{
                state,
                updateState,
                masterData,
                selectedData,
                ModuleChange,
                BranchChange,
                onSelectCheckbox,
                handleClose,
                handleClick,
                DepotChange,
                Updateuser,
                handleSubmit,
                ServiceChange
            }}
        >
            {props.children}
        </UserScreenContext.Provider>
    );
};
