import React, { useEffect, useState } from 'react';
import { getAllvehiclecheckchecks, getVehicleCategory, getVehicleCheckSection } from '../../../../Core/Services/InternalUser/Vehicle/vehicleChecks';
import { BreadCrumbs, Accordion, TabView } from '../../../../Core/Controls';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import { BasicTable } from './tableCheckcheck';
import { Button, Grid } from '@material-ui/core';
import CheckSectionModal from '../CheckSection/checkSectionModal';
import AddIcon from '@material-ui/icons/Add';
import './vehicleStyle.scss';
import { CustomIconButton, SecondaryButton } from '../../../../Core/Controls/Inputs/Buttons/ButtonModal';

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Vehicle Checks', active: true },
    { name: 'Inspection Checks', active: true }
];

const VehicleCheckCheck = (props) => {
    const [state, setState] = useState({
        checkList: [],
        VehicleCategoryList: [],
        accOpen: false,
        tabs: [],
        accHeading: [],
        modalToggle: false,
        expandCollapsedAt: null
    });

    useEffect(async () => {
        pullcheckData();
    }, []);

    const pullcheckData = async () => {
        let pros = [];
        pros.push(getVehicleCategory(), getVehicleCheckSection());
        let responses = await Promise.all(pros);
        // accordion data.....
        const accHeading = responses[1].data.map((m) => ({
            id: m.inspectionSheetSectionVehicleCategoryID,
            heading: m.inspectionSheetSectionDescription,
            addid: m.inspectionSheetSectionID
        }));
        // Tabs data....
        const tabs = responses[0].data.list.map((item) => ({
            id: item.vehicleCategoryID,
            label: item.vehicleCategoryDescription,
            accData: accHeading.filter((data) => data.id === item.vehicleCategoryID)
        }));

        setState((st) => ({
            ...st,
            accHeading,
            tabs
        }));
    };

    const closeDialog = (res) => {
        setState((state) => ({ ...state, modalToggle: false }));
        res && pullcheckData();
    };

    const handleExpand = (value) => {
        setState((state) => ({
            ...state,
            expandCollapsedAt: new Date(),
            accOpen: value
        }));
    };
    const addCheckSections = () => {
        setState((st) => ({
            ...st,
            modalToggle: true
        }));
    };

    const title = 'Add Check Section';

    const checkChecks = state.tabs.map((tab) => {
        const heading = tab.accData.map((o) => o.heading);
        const filterAccordion = tab.accData.filter((o, index) => !heading.includes(o.heading, index + 1));
        return {
            label: tab.label,
            body: (
                <div className="accordion_comp">
                    <Grid container spacing={2}>
                        <Grid item xs={12} style={{ marginTop: 8 }}>
                            <SecondaryButton variant="contained" style={{ width: 'auto' }} color="primary" onClick={() => addCheckSections()}>
                                <AddIcon /> &nbsp; Add Section
                            </SecondaryButton>
                        </Grid>
                        <Grid item xs={12}>
                            {filterAccordion.map((ad) => (
                                <Accordion
                                    header={ad.heading}
                                    changedAt={state.expandCollapsedAt}
                                    accordianBody={<BasicTable checksMainID={ad.id} checksID={ad.addid} accName={ad.heading} />}
                                    Expand={state.accOpen}
                                />
                            ))}
                        </Grid>
                    </Grid>
                </div>
            )
        };
    });

    return (
        <div className="screen">
            <BreadCrumbs crumbs={crumbs} />
            <div className="add_btn">
                <Button
                    variant="contained"
                    onClick={() => {
                        handleExpand(true);
                    }}
                    size="small"
                    color="secondary"
                    style={{ textTransform: 'capitalize' }}
                >
                     Expand All
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        handleExpand(false);
                    }}
                    size="small"
                    color="secondary"
                    style={{ marginLeft: 2, textTransform: 'capitalize' }}
                >
                     Collapse All
                </Button>
            </div>
            <br />
            <TabView tabList={checkChecks} id="check_check" />
            <br />

            {state.modalToggle ? (
                <DialogComp title={title} onClose={() => closeDialog(false)}>
                    <CheckSectionModal onClose={() => closeDialog(true)} />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default VehicleCheckCheck;
