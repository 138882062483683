import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { useLoginInfo } from '../../../../../../Core/Hooks/useLoginInfo';
import { SecondaryButton } from '../../../../../../Core/Controls/Inputs/Buttons/ButtonModal';
import DialogComp from '../../../../../../Core/Dialog/DialogComp';
import JobEstimateModal from '../../JobStatusBtn/JobEstimate';
import JobQueryModal from '../../JobStatusBtn/JobQuery';
import { useHistory } from 'react-router-dom';
import { CustomChip } from '../../../../../../Core/Controls';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Cancel';
import HelpIcon from '@material-ui/icons/Help';

const CancelButton = (props) => {
    return <SecondaryButton onClick={props.goPreviousScreen}>Cancel</SecondaryButton>;
};

function CuststatusBasedButton(props) {
    const [state, setState] = useState({
        showJobQuery: false,
        showEstimate: false,
        title: ''
    });

    const { userRoles } = useLoginInfo();
    const isCustomer = userRoles.includes('customer');
    const { status, vehicleID, data } = props;
    const handelPopup = async (title, setShow) => {
        setState((st) => ({
            ...st,
            title: title,
            [setShow]: true
        }));
    };

    const closeTheDialog = async (res) => {
        setState((st) => ({
            ...st,
            showJobQuery: false,
            showEstimate: false
        }));
        res && props.refreshScreen(res);
    };

    const CostomButton = (btnLable, modalName, icon) => {
        return (
            <>
                {props.btnType == 'chip' ? (
                    <Grid item>
                        <CustomChip
                            icon={icon}
                            label={`${btnLable}`}
                            onClick={() => {
                                handelPopup(`${btnLable}`, `${modalName}`);
                            }}
                            style={{ fontWeight: 'bold' }}
                        />
                    </Grid>
                ) : (
                    <SecondaryButton
                        onClick={() => {
                            handelPopup(`${btnLable}`, `${modalName}`);
                        }}
                    >
                        {`${btnLable}`}
                    </SecondaryButton>
                )}
            </>
        );
    };
    console.log(status, 'sta');
    return (
        <Grid container item spacing={0} className="controller-screen">
            {isCustomer ? (
                <Grid container item xs={12} spacing={1} className="btn-hw">
                    {props.isShownHeadrer ? null : (
                        <CancelButton
                            vehicleID={vehicleID}
                            jobRegNumber={data.jobRegNumber}
                            position={props.position}
                            goPreviousScreen={props.goPreviousScreen}
                        />
                    )}
                    {(status == 'AQ' || status == 'IQ') && props.otherOptionsBtnShow ? CostomButton('Reply Query', 'showJobQuery', AddIcon) : null}
                    {status == 'AR' && props.otherOptionsBtnShow ? CostomButton('Approve Order', 'showEstimate', CheckIcon) : null}
                    {(status == 'AA' ||
                        status == 'JP' ||
                        status == 'AU' ||
                        status == 'ER' ||
                        status == 'EP' ||
                        status == 'CQ' ||
                        status == 'AR' ||
                        status == 'EC') &&
                    props.otherOptionsBtnShow
                        ? CostomButton('Query Job', 'showJobQuery', HelpIcon)
                        : null}
                    {/* {status == 'EP' && props.otherOptionsBtnShow ? CostomButton('Estimate Accept', 'showEstimate', AddIcon) : null} */}
                    {/* {status == 'AR' && props.otherOptionsBtnShow ? CostomButton('Send for customer approval', 'showEstimate', AddIcon) : null} */}
                    {status == 'EC' && props.otherOptionsBtnShow ? CostomButton('Accept Estimate', 'showEstimate', CheckIcon) : null}
                </Grid>
            ) : null}
            {state.showJobQuery && (
                <DialogComp title={state.title} maxWidth="sm" onClose={() => closeTheDialog(false)}>
                    <JobQueryModal title={state.title} jobQueryJobID={props.jobID} onClose={closeTheDialog} />
                </DialogComp>
            )}
            {state.showEstimate && (
                <DialogComp title={state.title} maxWidth="sm" onClose={() => closeTheDialog(false)}>
                    <JobEstimateModal
                        title={state.title}
                        jobOrderTotal={data.jobOrderTotal}
                        jobEstimatedCost={data.jobEstimatedCost}
                        jobOrderDate={data.jobOrderDate}
                        jobAgentOrderNo={data.jobAgentOrderNo}
                        jobQueryJobID={props.jobID}
                        jobRegNumber={data.jobRegNumber}
                        onClose={closeTheDialog}
                        jobOrderNo={data.jobOrderNo}
                    />
                </DialogComp>
            )}
        </Grid>
    );
}

export default CuststatusBasedButton;
