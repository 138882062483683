import React, { useContext, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormHelperText, InputLabel, Typography } from '@material-ui/core';
import { SecondaryButton, BreadCrumbs, MandatoryField, SimpleField, Multiselect, SingleSelect } from '../../../Core/Controls';
import { EnquiriesProvider, EnquiriesScreenContext } from './EnquirieProvider';
import EnquiryStepper from './EnquiryStepper/EnquiryStepper';
import { Postcode } from '../../../Core/Controls';
import { useHistory } from 'react-router-dom';

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Enquiries', path: '/Enquiries' },
    { name: 'Add New Enquiry', active: true }
];

const AddEnquiries = (props) => {
    const { state, handleSubmit, inputChange, pullEnquiryByID, pullNotes, setVal, singleSelectChange, optionsState, tempOptions, typeOfVehicleChange } =
        useContext(EnquiriesScreenContext);
    const [data, setData] = useState({});

    const history = useHistory();

    const closeNoteModal = (res) => {
        setData((st) => ({
            ...st,
            show: false
        }));
        res && pullEnquiryByID();
        res && pullNotes();
    };
    return (
        <div className={`${!state.customerID && 'screen'}` + ' controller-screen'}>
            {!props.customerID ? <BreadCrumbs crumbs={crumbs} className="edit-Customer-btn" /> : null}
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <EnquiryStepper
                        data={state}
                        leadStatusID={state.leadStatusID}
                        leadLostReasonsList={optionsState.leadLostReasonsList}
                        onClose={closeNoteModal}
                    />
                </Grid>
                <Grid item container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Typography variant="h5" color="secondary">
                            Company Details
                        </Typography>
                        <hr />
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} sm={6} lg={3} className="mandatory-fields">
                                <MandatoryField
                                    inputLabel="Company Name"
                                    placeholder="Company Name"
                                    value={state.leadCompanyName}
                                    onChange={inputChange}
                                    name="leadCompanyName"
                                />
                                <FormHelperText error>{state.errors.leadCompanyName}</FormHelperText>
                            </Grid>
                            <Grid item xs={12} md={6} sm={6} lg={3}>
                                <Postcode
                                    value={state.leadPostcode || ''}
                                    onChange={inputChange}
                                    name="leadPostcode"
                                    setVal={setVal}
                                    errors={state.errors.leadPostcode}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} sm={6} lg={3}>
                                <SimpleField
                                    inputLabel="Address"
                                    placeholder="Address"
                                    value={state.leadAddress1 || ''}
                                    onChange={inputChange}
                                    name="leadAddress1"
                                />
                                <FormHelperText error>{state.errors.leadAddress1}</FormHelperText>
                            </Grid>
                            <Grid item xs={12} md={6} sm={6} lg={3}>
                                <SimpleField
                                    inputLabel="Address2"
                                    placeholder="Address2"
                                    value={state.leadAddress2 || ''}
                                    onChange={inputChange}
                                    name="leadAddress2"
                                />
                            </Grid>
                            <Grid item xs={12} md={6} sm={6} lg={3}>
                                <SimpleField inputLabel="City" placeholder="City" value={state.leadCity || ''} onChange={inputChange} name="leadCity" />
                            </Grid>
                            <Grid item xs={12} md={6} sm={6} lg={3}>
                                <SimpleField inputLabel="County" placeholder="County" value={state.leadCounty || ''} onChange={inputChange} name="leadCounty" />
                            </Grid>
                            <Grid item xs={12} md={6} sm={6} lg={3}>
                                <SimpleField
                                    inputLabel="Country Code"
                                    placeholder="Country Code"
                                    value={state.leadCountryCode || ''}
                                    onChange={inputChange}
                                    name="leadCountryCode"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Typography variant="h5" color="secondary">
                            Main Contact
                        </Typography>
                        <hr />
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} sm={6} lg={4} className="mandatory-fields">
                                <MandatoryField
                                    inputLabel="Name"
                                    placeholder="Name"
                                    value={state.leadMainContactName || ''}
                                    onChange={inputChange}
                                    name="leadMainContactName"
                                    errors={state.errors.leadMainContactName}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} sm={6} lg={4} className="mandatory-fields">
                                <MandatoryField
                                    inputLabel="Phone Number"
                                    placeholder="Phone Number"
                                    value={state.leadMainContactPhone || ''}
                                    onChange={inputChange}
                                    name="leadMainContactPhone"
                                    errors={state.errors.leadMainContactPhone}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} sm={6} lg={4} className="mandatory-fields">
                                <MandatoryField
                                    inputLabel="Email"
                                    placeholder="Email"
                                    value={state.leadMainContactEmail || ''}
                                    onChange={inputChange}
                                    name="leadMainContactEmail"
                                    errors={state.errors.leadMainContactEmail}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Typography variant="h5" color="secondary">
                            Fleet Info
                        </Typography>
                        <hr />
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} sm={6} lg={4}>
                                <SimpleField
                                    inputLabel="Fleet Size"
                                    placeholder="Fleet Size"
                                    type="number"
                                    value={state.leadFleetSize || ''}
                                    onChange={inputChange}
                                    name="leadFleetSize"
                                />
                            </Grid>
                            <Grid item xs={12} md={6} sm={6} lg={4}>
                                <InputLabel shrink>Type Of Business</InputLabel>
                                <SingleSelect
                                    options={optionsState.leadTypeOfBusinessList}
                                    value={tempOptions.leadTypeOfBusiness}
                                    onChange={singleSelectChange('leadTypeOfBusiness')}
                                />
                                <FormHelperText error>{state.errors.leadTypeOfBusiness}</FormHelperText>
                            </Grid>
                            <Grid item xs={12} md={6} sm={6} lg={4}>
                                <InputLabel shrink>Type of Vehicles</InputLabel>
                                <Multiselect
                                    style={{ marginTop: -8 }}
                                    options={optionsState.typeOfVehicleOption}
                                    value={tempOptions.typeOfVehicleValue}
                                    onChange={typeOfVehicleChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Typography variant="h5" color="secondary">
                            Additional Information
                        </Typography>
                        <hr />
                        <Grid item xs={12} md={12} sm={12} lg={12}>
                            <SimpleField
                                multiline
                                rows={6}
                                inputLabel="Additional Information"
                                placeholder="Additional Information"
                                value={state.leadAdditionalInformation || ''}
                                onChange={inputChange}
                                name="leadAdditionalInformation"
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Typography variant="h5" color="secondary">
                            Lead Contact
                        </Typography>
                        <hr />
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4} sm={6} lg={3} className="mandatory-fields">
                                <MandatoryField
                                    inputLabel="Name"
                                    placeholder="Name"
                                    value={state.leadReferrerName}
                                    onChange={inputChange}
                                    name="leadReferrerName"
                                    errors={state.errors.leadReferrerName}
                                />
                            </Grid>
                            <Grid item xs={12} md={4} sm={6} lg={3}>
                                <InputLabel shrink>Branch</InputLabel>
                                <SingleSelect
                                    options={optionsState.branchList}
                                    value={tempOptions.leadReferrerBranchID}
                                    onChange={singleSelectChange('leadReferrerBranchID')}
                                />
                                <FormHelperText error>{state.errors.leadReferrerBranchID}</FormHelperText>
                            </Grid>
                            <Grid item xs={12} md={4} sm={6} lg={3}>
                                <SimpleField
                                    inputLabel="Email"
                                    placeholder="Email"
                                    value={state.leadReferrerEmail || ''}
                                    onChange={inputChange}
                                    name="leadReferrerEmail"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6} className="submit_btn">
                    <SecondaryButton onClick={handleSubmit}>{state.leadID ? 'Update' : 'Add'}</SecondaryButton>
                </Grid>
                <Grid item xs={6} className="submit_btn">
                    <SecondaryButton
                        forceEnabled={true}
                        onClick={() => {
                            //   props.history.push(`Enquiries`);
                            history.goBack();
                        }}
                    >
                        Cancel
                    </SecondaryButton>
                </Grid>
            </Grid>
        </div>
    );
};

const Screen = (props) => {
    return (
        <EnquiriesProvider {...props}>
            <AddEnquiries {...props} />
        </EnquiriesProvider>
    );
};
export default Screen;
