import React, { useEffect, useMemo, useState } from 'react';
import '../../../../Components/commonStyle.scss';
import { BreadCrumbs, DataGrid, EditButton, formatters } from '../../../../Core/Controls';
import { useToast } from '../../../../Core/Hooks/useToast';
import { getAllReports } from '../../../../Core/Services/InternalUser/ReportsService';
import AddReportScreen from './AddReportScreen';
import { Link } from 'react-router-dom';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AssignmentIcon from '@material-ui/icons/Assignment';
import GijgoGrid from '../../../../Core/Controls/GijgoGrid';
import Grid from '@material-ui/core/Grid';

const baseColumns = [
    {
        key: 'action',
        name: 'Action',
        width: 70
    },
    {
        key: 'userReportName',
        name: 'Report Name'
    },
    {
        key: 'reportCatalogueName',
        name: 'Catalogue Name'
    },
    {
        key: 'reportScheduleTitle',
        name: 'Schedule'
    },
    {
        key: 'userReportDateCreated',
        name: 'Date Created',
        formatter: formatters.Date_DDMMMYYYY_HHMM
    },
    {
        key: 'userReportSendEmail',
        name: 'Send Email'
    }
];

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Reports', path: '/', active: true },
    { name: 'My Reports', path: '/', active: true }
];

const ReportsListScreen = (props) => {
    const { showToastError } = useToast();

    const [state, setState] = useState({
        modalToggle: false,
        showLoader: true,

        reportsList: [],
        userReportID: null,
        isReload: null
    });

    const pullReportsAndUpdateState = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    const handleAdd = (record) => {
        setState((st) => ({
            ...st,
            modalToggle: true,
            userReportID: record
        }));
    };

    const closeReportModal = (res) => {
        setState((st) => ({
            ...st,
            modalToggle: false
        }));
        res && pullReportsAndUpdateState();
    };

    const handleEditClick = (val) => {
        props.history.push({
            pathname: `/reports/reportlist/${val.userReportName}/${val.userReportID}`
        });
    };

    const dataFormatter = (val, row) => {
        return (
            <Grid container justify="space-around" alignItems="center">
                <Grid item>
                    <EditButton onClick={() => handleAdd(row.userReportID)} toolTipTitle="Edit Report" />
                </Grid>
                <Grid item>
                    <AssignmentIcon
                        color="secondary"
                        toolTipTitle="Edit Report"
                        onClick={() => handleEditClick(row)}
                        style={{ marginTop: '8px', cursor: 'pointer' }}
                    />
                </Grid>
            </Grid>
        );
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.key === 'action');
        if (actionCol) {
            actionCol.formatter = dataFormatter;
        }
        return tempCols;
    }, []);

    const newCrumbs = useMemo(() => {
        const tempCols = [...crumbs];
        let actionCol = tempCols.find((element) => element.name === 'Reports List');
        if (actionCol) {
            actionCol.onClick = closeReportModal;
        }
        return tempCols;
    }, []);

    return (
        <div className="screen">
            <BreadCrumbs crumbs={newCrumbs} />

            {state.modalToggle ? (
                <>
                    <br />
                    <AddReportScreen onClose={closeReportModal} userReportID={state.userReportID} />
                </>
            ) : (
                <>
                    <GijgoGrid
                        dataConverter={(res) => ({
                            records: res.data.list,
                            total: res.data.total
                        })}
                        columns={columns}
                        getUrl="UserReports"
                        btnTitle="Add Report"
                        onBtnClick={() => handleAdd(null)}
                        isReload={state.isReload}
                    />
                </>
            )}
        </div>
    );
};
export default ReportsListScreen;
