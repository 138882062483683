import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { InputLabel, FormHelperText, MenuItem, TextField } from '@material-ui/core';
import { InputText, SecondaryButton, MandatoryField, SelectBox, YesButton, NoButton, SimpleField, SingleSelect } from '../../../Core/Controls';
import { getMenuItemsById, postMenuItems, getMenuItemsDropdown } from '../../../Core/Services/InternalUser/menuItemService';
import { useToast } from '../../../Core/Hooks/useToast';
import '../../commonStyle.scss';

function MenuModal(props) {
    const [state, setState] = useState({
        navigationMenuID: null,
        leftNavigationMenuURL: '',
        leftNavigationMenuComponent: '',
        leftNavigationMenuAuthType: '',
        navigationMenuSequence: '',
        iconIDValue: null,
        leftNavigationMenuIconName: '',
        menuIconList: [],
        navigationMenuRoleID: null,
        menuRoleList: [],
        screenList: [],
        flatScreenList: [],
        navigationMenuParentMenuID: null,
        parentMenuList: [],
        navigationMenuLabel: '',
        navigationMenuActive: true,
        navigationScreenID: null,
        errors: {}
    });

    const [tempOptions, setTempOptions] = useState({
        navigationMenuIconIDValue: null
    });

    const { showToastSuccess, showToastError } = useToast();

    useEffect(async () => {
        if (props.tabID) {
            setState((st) => ({ ...st, navigationMenuRoleID: props.tabID }));
        }
        let dropDwonData = await getMenuItemsDropdown(props.tabID, props.navigationMenuID);
        if (dropDwonData.success) {
            const parent_MenuList = dropDwonData.data.navigationMenus.map((item) => ({
                id: item.navigationMenuID,
                name: item.navigationMenuLabel
            }));
            const screen_List = dropDwonData.data.screens.map((item) => ({
                id: item.id,
                name: item.name
            }));
            setState((st) => ({
                ...st,
                menuIconList: dropDwonData.data.icons,
                menuRoleList: dropDwonData.data.roles,
                parentMenuList: parent_MenuList,
                screenList: screen_List,
                flatScreenList: dropDwonData.data.screens
            }));
        }
        if (props.navigationMenuID) {
            let res = await getMenuItemsById(props.navigationMenuID);
            let data = res.data.list[0];
            if (res.success) {
                setState((state) => ({
                    ...state,
                    navigationMenuID: data.navigationMenuID,
                    navigationMenuRoleID: data.navigationMenuRoleID,
                    leftNavigationMenuURL: data.leftNavigationMenuURL,
                    leftNavigationMenuComponent: data.leftNavigationMenuComponent,
                    leftNavigationMenuAuthType: data.leftNavigationMenuAuthType,
                    navigationMenuSequence: data.navigationMenuSequence,
                    navigationMenuLabel: data.navigationMenuLabel,
                    iconIDValue: data.navigationMenuIconID,
                    navigationMenuActive: data.navigationMenuActive,
                    leftNavigationMenuIconName: data.leftNavigationMenuIconName,
                    navigationMenuParentMenuID: data.navigationMenuParentMenuID,
                    navigationScreenID: data.navigationScreenID
                }));
            }
        }
    }, []);

    useEffect(() => {
        let MenuId = null;
        if (state.iconIDValue) {
            MenuId = state.menuIconList.find(({ id }) => id === +state.iconIDValue);
        }
        setTempOptions((st) => ({
            ...st,
            navigationMenuIconIDValue: MenuId
        }));
    }, [state.iconIDValue]);

    const abcd = async (value) => {
        let obj = state.flatScreenList.find((x) => x.id === value);
        setState((st) => {
            const nst = { ...st };
            nst.navigationMenuLabel = obj?.name;
            nst.navigationMenuIconID = obj?.screenIconID;
            return nst;
        });
    };

    const singleSelectChange = (nm) => (ev, val) => {
        setTempOptions((st) => ({ ...st, [nm]: val }));
    };

    const handleInputChange2 = (event) => {
        const { name, value } = event.target;
        setState((st) => {
            const nst = { ...st };
            nst[name] = value;
            nst.navigationMenuLabel = '';
            nst.navigationMenuIconID = '';

            return nst;
        });
        abcd(value);
    };
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setState((st) => {
            const nst = { ...st };
            nst[name] = value;

            return nst;
        });
    };

    const handleClick = (name, value) => {
        setState((st) => {
            const nst = { ...st, [name]: value };
            return nst;
        });
    };

    const validations = () => {
        const { navigationMenuLabel } = state;
        let emptyStrigPattern = /[^\s]/;
        let formIsValid = true;
        let errors = {};
        if (!emptyStrigPattern.test(navigationMenuLabel)) {
            errors.navigationMenuLabel = ' This field is required';
            formIsValid = false;
        }
        setState((state) => ({ ...state, errors: errors }));
        return formIsValid;
    };

    const submitHandler = async () => {
        if (validations()) {
            const data = {
                navigationMenuID: state.navigationMenuID,
                navigationMenuRoleID: state.navigationMenuRoleID,
                navigationMenuSequence: state.navigationMenuSequence,
                navigationMenuLabel: state.navigationMenuLabel,
                navigationMenuIconID: tempOptions.navigationMenuIconIDValue?.id,
                navigationMenuActive: state.navigationMenuActive,
                navigationMenuParentMenuID: state.navigationMenuParentMenuID,
                navigationScreenID: state.navigationScreenID
            };
            let res = await postMenuItems(data);
            if (res.success) {
                showToastSuccess('Menu Item Saved Successfully');
                props.onClose(true);
            } else {
                showToastError(res.message);
            }
        }
    };

    return (
        <Grid container direction="row" spacing={2}>
            <Grid item xs={6} className="mandatory-fields">
                <MandatoryField inputLabel="Role" name="navigationMenuLabel" value={props.roleName} disabled />
            </Grid>
            <Grid item xs={6} className="mandatory-fields">
                <InputLabel required shrink>
                    Screens
                </InputLabel>
                <SelectBox name="navigationScreenID" value={state.navigationScreenID || ' '} onChange={handleInputChange2} List={state.screenList} />
                <FormHelperText error>{state.errors.navigationScreenID}</FormHelperText>
            </Grid>
            <Grid item xs={6} className="mandatory-fields">
                <MandatoryField
                    inputLabel="Label"
                    name="navigationMenuLabel"
                    value={state.navigationMenuLabel}
                    onChange={handleInputChange}
                    errors={state.errors.navigationMenuLabel}
                />
            </Grid>
            <Grid item xs={6}>
                <InputLabel shrink>Menu Icons</InputLabel>
                <SingleSelect
                    value={tempOptions.navigationMenuIconIDValue}
                    onChange={singleSelectChange('navigationMenuIconIDValue')}
                    options={state.menuIconList}
                />
                <FormHelperText error>{state.errors.iconIDValue}</FormHelperText>
            </Grid>

            <Grid item xs={6}>
                <InputLabel shrink>Parent Menu</InputLabel>
                <SelectBox
                    name="navigationMenuParentMenuID"
                    value={+state.navigationMenuParentMenuID || ' '}
                    onChange={handleInputChange}
                    List={state.parentMenuList}
                />
            </Grid>
            <Grid item xs={6}>
                <SimpleField
                    inputLabel="Sequence"
                    type="number"
                    name="navigationMenuSequence"
                    placeholder="Sequence"
                    value={state.navigationMenuSequence}
                    onChange={handleInputChange}
                />
            </Grid>

            <Grid xs={12} container style={{ marginLeft: 8, marginBottom: 5 }}>
                <Grid xs={12} container spacing={1}>
                    <Grid item xs={12}>
                        Is Active ?
                    </Grid>
                    <Grid item xs={6}>
                        <YesButton state={state.navigationMenuActive} name="navigationMenuActive" onClick={() => handleClick('navigationMenuActive', true)} />
                    </Grid>
                    <Grid item xs={6}>
                        <NoButton state={state.navigationMenuActive} name="navigationMenuActive" onClick={() => handleClick('navigationMenuActive', false)} />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} className="submit_btn">
                <SecondaryButton onClick={submitHandler}>{props.navigationMenuID ? 'Update' : 'Add'}</SecondaryButton>
            </Grid>
        </Grid>
    );
}

export default MenuModal;
