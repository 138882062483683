import { getWithAuth, postWithAuth } from '../../../Basic/HttpService';
import { memoize } from 'lodash';
import Multiselect from './../../../Controls/Autocomplete/index';

export const getVehicleBreakdownDropdowns = async (id) => {
    let res = await getWithAuth(`VehicleBreakdowns/screenDropDowns?VehicleID=${id}`);
    return res;
};

export const getBreakDownDropDown = memoize(async () => {
    let res = await getWithAuth(`VehicleBreakdowns/screenDropDowns`);
    return res;
});

export const getVehicleBreakdowns = async () => {
    let res = await getWithAuth(`VehicleBreakdowns`);
    return res;
};

export const getAllVehicleBreakdowns = async (id) => {
    let res = await getWithAuth(`VehicleBreakdowns?VehicleID=${id}`);
    return res;
};

export const getCustomerBreakdowns = async (id) => {
    let res = await getWithAuth(`VehicleBreakdowns?customerID=${id}`);
    return res;
};
export const getVehicleBreakdownsbyID = async (id) => {
    let res = await getWithAuth(`VehicleBreakdowns/${id}`);
    return res;
};

export const getVechicleIDByRegNO = async (id) => {
    let res = await getWithAuth(`Vehicles/ValidateReg/${id}`);
    return res;
};

export const postVehicleBreakdown = async (data, state) => {
    let res = await postWithAuth(`VehicleBreakdowns`, {
        defectID: data.defectID,
        defectVehicleID: data.vehicleID,
        defectTypeCode: data.breakDownType,
        defectMileage: data.mileage,
        defectReportingDepotID: data.reportingDepotValue?.id,
        defectLocation: data.breakDownLocation,
        defectContactNumber: data.reportedPhone,
        defectContactName: data.reportedBy,
        defectDriverName: data.driverName,
        defectDriverTelephone: data.driverPhone,
        defectDescription: data.problemReported,
        defectCallOutRequired: data.callOutRequired,
        defectUrgentCallOut: data.defectUrgentCallOut,
        defectVOR: data.defectVOR,
        defectPoliceOnScene: data.policeOnScene,
        defectPassengersInvolved: data.passengersInvolved,
        defectNumberOfPassengers: data.numberOfPassenger,
        defectAccidentDamage: data.accDamage,
        defectInsuranceReference: data.iNSREF,
        defectReplacementVehicle: data.replacementvehicleRequired,
        domainName: data.domainName,
        defectVehicleLoaded: data.vehicleLoaded,
        defectVehicleLoadTypeID: data.loadTypeValue?.id,
        defectMobilityRequested: data.mobilityRequested,
        defectMobilityUsed: data.mobilityUsed,
        defectPG9Issued: data.defectPG9Issued,
        faultIds: state.modules.map(({ id }) => id)
    });

    return res;
};
