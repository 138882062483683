import React, { useState, useEffect, useMemo } from 'react';
import { getAllVehicleJob, getAllJob } from '../../../../../Core/Services/InternalUser/JOB/jobService';
import DataGrid from '../../../../../Core/Controls/Grid';
import DialogComp from '../../../../../Core/Dialog/DialogComp';
import { EditButton, BreadCrumbs, formatters } from '../../../../../Core/Controls';
// import JobScreenModal from './JobScreenModal';
import { Link } from 'react-router-dom';
import WorkIcon from '@material-ui/icons/Work';
import { CustomIconButton } from '../../../../../Core/Controls/Inputs/Buttons/ButtonModal';
import GijgoGrid from '../../../../../Core/Controls/GijgoGrid';
import { useHistory } from 'react-router-dom';
import { AppStorage } from '../../../../../Core/Basic/storage-service';

const base_columns = [
    { key: 'action', name: 'Action', width: '5%' },
    { key: 'jobDate', name: 'Date', formatter: formatters.Date_DDMMYYYY },
    { key: 'jobAgentOrderNo', name: 'Job Ref' },
    { key: 'serviceAgentName', name: 'Service Agent' },
    { key: 'customerCompanyName', name: 'Customer' },
    { key: 'depotName', name: 'Depots' },
    { key: 'jobPartsTotal', name: 'Parts', formatter: formatters.NumberWithPound, align: 'right' },
    { key: 'jobLabourTotal', name: 'Labour', formatter: formatters.NumberWithPound, align: 'right' },
    { key: 'jobOrderTotal', name: 'Total', formatter: formatters.NumberWithPound, align: 'right' }
];

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Unassigned Jobs', active: true }
];

function UnassignedJob(props) {
    const history = useHistory();
    const [state, setState] = useState({
        jobList: [],
        loader: true,
        open: false
    });
    let checkhaveAccess = AppStorage.getAllowAcces();

    useEffect(async () => {
        pullJobList();
    }, []);
    const pullJobList = async () => {
        // let res = await getAllJob();
        // let data = res.data.vehicleJobDetails.filter((unassign) => unassign.jobStatusID == 'AU');
        setState((st) => ({
            ...st,
            // jobList: data,
            loader: false
        }));
    };
    const jobHandler = (params) => {
        setState((state) => ({ ...state, open: true }));
    };

    const handleEditClick = (val) => {
        history.push({
            pathname: `/controller/jobTabScreen/${val?.jobID}/${props.VehicleID ? props.VehicleID : val?.vehicleID}`,
            search: '',
            state: 'jobs'
        });
    };

    const ActionButtons = (data, row) => {
        return (
            <div style={{ textAlign: 'center' }}>
                {checkhaveAccess?.CanViewJobs && (
                    <CustomIconButton icon={WorkIcon} className="modal-btn-update btn_space" toolTipTitle="Edit Job" onClick={() => handleEditClick(row)} />
                )}
            </div>
        );
    };

    const columns = useMemo(() => {
        let newColumn = [...base_columns];
        let actionCol = newColumn.find((item) => item.key === 'action');
        if (actionCol) {
            actionCol.formatter = ActionButtons;
        }
        return newColumn;
    });

    const closeModal = (res) => {
        setState((state) => ({ ...state, open: false }));
        res && pullJobList();
    };
    const gridBtnTitle = !props.VehicleID ? '' : 'Add Job';
    const getUrl = 'VehicleJob?jobStatusID=AU';
    return (
        <div className="screen">
            <>
                <BreadCrumbs crumbs={crumbs} />
                {/* <DataGrid
                    columns={columns}
                    rows={state.jobList}
                    isRowHovered={true}
                    enableSearch={true}
                    loadingData={state.loader}
                    btnTitle={gridBtnTitle}
                    onBtnClick={jobHandler}
                    paginate
                    offset={210}
                /> */}
                <GijgoGrid
                    columns={columns}
                    btnTitle={gridBtnTitle}
                    onBtnClick={jobHandler}
                    dataConverter={(res) => ({
                        records: res.data.list,
                        total: res.data.total
                    })}
                    isReload={state.isReload}
                    getUrl={getUrl}
                />
            </>
        </div>
    );
}
export default UnassignedJob;
