import { postWithAuth, getWithAuth, putWithAuth } from '../../../Basic/HttpService';

export const getVehicleMessages = async (id) => {
    let res = await getWithAuth(`VehicleMessages?VehicleID=${id}`);
    return res;
};
export const postMessage = async (data) => {
    let res = await postWithAuth(`VehicleMessages/SendMessage`, data);
    return res;
};
