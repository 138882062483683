import { createContext, useEffect, useState, useCallback } from 'react';
import { useToast } from '../../../Core/Hooks/useToast';
import { useLoginInfo } from '../../../Core/Hooks/useLoginInfo';
import {
    getServiceAgentById,
    postServiceAgentData,
    getServiceAgentFacilities,
    getServiceAgentsDropDown
} from '../../../Core/Services/InternalUser/ServiceAgent/serviceAgentService';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { emailValidation } from '../../../Core/Basic/StaticService';
import { isNull } from 'lodash';
import { formatters } from '../../../Core/Controls';
import { AppStorage } from '../../../Core/Basic/storage-service';

export const ServiceAgentScreenContext = createContext();

export const ServiceAgentProvider = (props) => {
    let history = useHistory();
    const { userRoles } = useLoginInfo();
    const isAdmin = userRoles.includes('admin');
    const [btnLoader, setBtnLoader] = useState(false);
    const { showToastSuccess, showToastError } = useToast();
    let checkhaveAccess = AppStorage.getAllowAcces();
    const [state, setState] = useState({
        errors: {},
        serviceAgentID: '',
        serviceAgentName: '',
        serviceAgentAddress1: '',
        serviceAgentAddress2: '',
        serviceAgentCounty: '',
        serviceAgentCity: '',
        serviceAgentPostCode: '',
        serviceAgentPhone: '',
        serviceAgentFax: '',
        serviceAgentEmail: '',
        serviceAgentWebSite: '',
        serviceAgentStartDate: '',
        serviceAgentMaintenanceContractRef: '',
        serviceAgentAccountCentreID: '',
        serviceAgentOrderEmail: '',
        serviceAgentCurrencyID: '',
        serviceAgentLabourRate: '',
        serviceAgentOutOfHoursLabourRate: '',
        serviceAgentOrderConfirmationName: '',
        serviceAgentOrderConfirmationPhone: '',
        serviceAgentSendOrderConfirmation: false,
        serviceAgentOrderConfirmationEmail: '',
        serviceAgentBookingName: '',
        serviceAgentBookingPhone: '',
        serviceAgentSendBookingEmail: false,
        serviceAgentBookingEmail: '',
        serviceAgentSendJobUpdateEmail: false,
        serviceAgentJobUpdateEmail: '',
        serviceAgentSendQueryResponseReceived: false,
        serviceAgentQueryResponseReceivedEmail: '',
        serviceAgentSendJobApprovalReceived: false,
        serviceAgentobApprovalReceivedEmail: '',
        serviceAgentNominalCode: '',
        serviceAgentServiceManagerName: '',
        serviceAgentServiceManagerPhone: '',
        serviceAgentServiceManagerEmail: '',
        handlingDefects: false,
        serviceAgentActive: true,
        hasInspectionSheetControl: false,
        serviceAgentOpenMonday: true,
        serviceAgentMondayOpeningTime: '',
        serviceAgentMondayClosingTime: '',
        serviceAgentOpenTuesday: true,
        serviceAgentTuesdayOpeningTime: '',
        serviceAgentTuesdayClosingTime: '',
        serviceAgentOpenWednesday: true,
        serviceAgentWednesdayOpeningTime: '',
        serviceAgentWednesdayClosingTime: '',
        serviceAgentOpenThursday: true,
        serviceAgentThursdayOpeningTime: '',
        serviceAgentThursdayClosingTime: '',
        serviceAgentOpenFriday: true,
        serviceAgentFridayOpeningTime: '',
        serviceAgentFridayClosingTime: '',
        serviceAgentOpenSaturday: true,
        serviceAgentSaturdayOpeningTime: '',
        serviceAgentSaturdayClosingTime: '',
        serviceAgentOpenSunday: true,
        serviceAgentSundayOpeningTime: '',
        serviceAgentSundayClosingTime: '',
        serviceAgentOpenBankHoliday: true,
        serviceAgentBankHolidayOpeningTime: '',
        serviceAgentBankHolidayClosingTime: '',
        serviceAgentFacilitieslist: [],
        serviceAgentFacilities: [],
        serviceAgentFacilitiId: [],
        isDisable: false,
        serviceAgentCurrencyIDList: [],
        serviceAgentCurrencyIDValue: null,
        serviceAgentPaymentDays: null,
        serviceAgentPaymentDaysValue: null,
        serviceAgentPaymentDaysList: [
            { name: '30 Days', id: 30 },
            { name: '45 Days', id: 45 },
            { name: '60 Days', id: 60 },
            { name: '90 Days', id: 90 }
        ]
    });

    const [selectedData, setSelectedData] = useState({
        modules: []
    });

    function pad(d) {
        return d < 10 ? '0' + d.toFixed(2).replace('.', ':') : d.toFixed(2).replace('.', ':');
    }

    function padValue(val) {
        if (val) {
            return pad(val);
        }
        return '';
    }

    useEffect(async () => {
        if (props.serviceAgentID) {
            let res = await getServiceAgentById(props.serviceAgentID);
            const data = res.data.details[0];
            const facilitiesData = res.data;
            setState((st) => ({
                ...st,
                serviceAgentID: data.serviceAgentID,
                serviceAgentName: data.serviceAgentName,
                serviceAgentAddress1: data.serviceAgentAddress1,
                serviceAgentAddress2: data.serviceAgentAddress2,
                serviceAgentCounty: data.serviceAgentCounty,
                serviceAgentCity: data.serviceAgentCity,
                serviceAgentPostCode: data.serviceAgentPostCode,
                serviceAgentPhone: data.serviceAgentPhone,
                serviceAgentFax: data.serviceAgentFax,
                serviceAgentEmail: data.serviceAgentEmail,
                serviceAgentWebSite: data.serviceAgentWebSite,
                serviceAgentStartDate: formatters.DateFormatter(data.serviceAgentStartDate),
                serviceAgentMaintenanceContractRef: data.serviceAgentMaintenanceContractRef,
                serviceAgentAccountCentreID: data.serviceAgentAccountCentreID,
                serviceAgentOrderEmail: data.serviceAgentOrderEmail,
                serviceAgentCurrencyID: data.serviceAgentCurrencyID,
                serviceAgentLabourRate: data.serviceAgentLabourRate,
                serviceAgentOutOfHoursLabourRate: data.serviceAgentOutOfHoursLabourRate,
                serviceAgentOrderConfirmationName: data.serviceAgentOrderConfirmationName,
                serviceAgentOrderConfirmationPhone: data.serviceAgentOrderConfirmationPhone,
                serviceAgentSendOrderConfirmation: data.serviceAgentSendOrderConfirmation,
                serviceAgentOrderConfirmationEmail: data.serviceAgentOrderConfirmationEmail,
                serviceAgentBookingName: data.serviceAgentBookingName,
                serviceAgentBookingPhone: data.serviceAgentBookingPhone,
                serviceAgentSendBookingEmail: data.serviceAgentSendBookingEmail,
                serviceAgentBookingEmail: data.serviceAgentBookingEmail,
                serviceAgentSendJobUpdateEmail: data.serviceAgentSendJobUpdateEmail,
                serviceAgentJobUpdateEmail: data.serviceAgentJobUpdateEmail,
                serviceAgentSendQueryResponseReceived: data.serviceAgentSendQueryResponseReceived,
                serviceAgentQueryResponseReceivedEmail: data.serviceAgentQueryResponseReceivedEmail,
                serviceAgentSendJobApprovalReceived: data.serviceAgentSendJobApprovalReceived,
                serviceAgentobApprovalReceivedEmail: data.serviceAgentobApprovalReceivedEmail,
                serviceAgentNominalCode: data.serviceAgentNominalCode,
                serviceAgentServiceManagerName: data.serviceAgentServiceManagerName,
                serviceAgentServiceManagerPhone: data.serviceAgentServiceManagerPhone,
                serviceAgentServiceManagerEmail: data.serviceAgentServiceManagerEmail,
                handlingDefects: data.handlingDefects,
                serviceAgentActive: data.serviceAgentActive,
                hasInspectionSheetControl: data.hasInspectionSheetControl,
                serviceAgentRating: data.serviceAgentRating,
                serviceAgentFacilitiId: facilitiesData.serviceAgentFacilities,

                serviceAgentOpenMonday: data.serviceAgentOpenMonday,
                serviceAgentMondayOpeningTime: padValue(data.serviceAgentMondayOpeningTime),
                serviceAgentMondayClosingTime: padValue(data.serviceAgentMondayClosingTime),
                serviceAgentOpenTuesday: data.serviceAgentOpenTuesday,
                serviceAgentTuesdayOpeningTime: padValue(data.serviceAgentTuesdayOpeningTime),
                serviceAgentTuesdayClosingTime: padValue(data.serviceAgentTuesdayClosingTime),
                serviceAgentOpenWednesday: data.serviceAgentOpenWednesday,
                serviceAgentWednesdayOpeningTime: padValue(data.serviceAgentWednesdayOpeningTime),
                serviceAgentWednesdayClosingTime: padValue(data.serviceAgentWednesdayClosingTime),
                serviceAgentOpenThursday: data.serviceAgentOpenThursday,
                serviceAgentThursdayOpeningTime: padValue(data.serviceAgentThursdayOpeningTime),
                serviceAgentThursdayClosingTime: padValue(data.serviceAgentThursdayClosingTime),
                serviceAgentOpenFriday: data.serviceAgentOpenFriday,
                serviceAgentFridayOpeningTime: padValue(data.serviceAgentFridayOpeningTime),
                serviceAgentFridayClosingTime: padValue(data.serviceAgentFridayClosingTime),
                serviceAgentOpenSaturday: data.serviceAgentOpenSaturday,
                serviceAgentSaturdayOpeningTime: padValue(data.serviceAgentSaturdayOpeningTime),
                serviceAgentSaturdayClosingTime: padValue(data.serviceAgentSaturdayClosingTime),
                serviceAgentOpenSunday: data.serviceAgentOpenSunday,
                serviceAgentSundayOpeningTime: padValue(data.serviceAgentSundayOpeningTime),
                serviceAgentSundayClosingTime: padValue(data.serviceAgentSundayClosingTime),
                serviceAgentOpenBankHoliday: data.serviceAgentOpenBankHoliday,
                serviceAgentBankHolidayOpeningTime: padValue(data.serviceAgentBankHolidayOpeningTime),
                serviceAgentBankHolidayClosingTime: padValue(data.serviceAgentBankHolidayClosingTime),
                serviceAgentPaymentDays: data.serviceAgentPaymentDays,
                serviceAgentAccountCode: data.serviceAgentAccountCode,

                //
                isDisable: !checkhaveAccess?.CanUpdateServiceAgents
            }));
        }

        let dropDownlist = await getServiceAgentsDropDown();
        setState((st) => ({
            ...st,
            serviceAgentFacilitieslist: dropDownlist.data.serviceAgentFacilities,
            serviceAgentCurrencyIDList: dropDownlist.data.currencies
        }));
    }, []);

    useEffect(() => {
        if (state.serviceAgentFacilitiId) {
            const selectedServiceAgent = state.serviceAgentFacilitieslist.filter((w) => state.serviceAgentFacilitiId.indexOf(w.id) > -1);
            setSelectedData((st) => ({
                ...st,
                modules: selectedServiceAgent
            }));
        }
    }, [state.serviceAgentFacilitiId, state.serviceAgentFacilitieslist]);

    useEffect(() => {
        let serviceAgentCurrencyIDValue = null;
        let serviceAgentPaymentDaysValue = null;
        if (state.serviceAgentPaymentDays) {
            serviceAgentPaymentDaysValue = state.serviceAgentPaymentDaysList.find(({ id }) => id === state.serviceAgentPaymentDays);
        }
        if (state.serviceAgentCurrencyID && state.serviceAgentCurrencyIDList.length > 0) {
            serviceAgentCurrencyIDValue = state.serviceAgentCurrencyIDList.find(({ id }) => id === state.serviceAgentCurrencyID);
        } else {
            serviceAgentCurrencyIDValue = state.serviceAgentCurrencyIDList[0];
        }
        setState((st) => ({
            ...st,
            serviceAgentCurrencyIDValue: serviceAgentCurrencyIDValue,
            serviceAgentPaymentDaysValue: serviceAgentPaymentDaysValue
        }));
    }, [state.serviceAgentCurrencyID, state.serviceAgentCurrencyIDList]);

    const checkNull = (param) => {
        if (/[^\s]/.test(param)) {
            return param;
        } else {
            return null;
        }
    };
    const resetInput = (name) => {
        setState((st) => ({ ...st, [name]: '' }));
    };

    const inputChange = useCallback((e) => {
        const { name, value, type } = e.target;
        if (value === 'true' && type.toLowerCase() === 'radio') {
            setState((st) => ({ ...st, [name]: true }));
        } else if (value === 'false' && type.toLowerCase() === 'radio') {
            setState((st) => ({ ...st, [name]: false }));
        } else {
            setState((st) => ({ ...st, [name]: value }));
        }
    }, []);

    const singleSelectChange = (nm) => (ev, val) => {
        setState((st) => ({ ...st, [nm]: val }));
    };

    const setAllTime = (isOpenClose) => {
        if (isOpenClose === 'open') {
            setState((st) => ({
                ...st,
                serviceAgentTuesdayOpeningTime: state.serviceAgentMondayOpeningTime,
                serviceAgentWednesdayOpeningTime: state.serviceAgentMondayOpeningTime,
                serviceAgentThursdayOpeningTime: state.serviceAgentMondayOpeningTime,
                serviceAgentFridayOpeningTime: state.serviceAgentMondayOpeningTime,
                serviceAgentSaturdayOpeningTime: state.serviceAgentMondayOpeningTime,
                serviceAgentSundayOpeningTime: state.serviceAgentMondayOpeningTime,
                serviceAgentBankHolidayOpeningTime: state.serviceAgentMondayOpeningTime
            }));
        }
        if (isOpenClose === 'close') {
            setState((st) => ({
                ...st,
                serviceAgentTuesdayClosingTime: state.serviceAgentMondayClosingTime,
                serviceAgentWednesdayClosingTime: state.serviceAgentMondayClosingTime,
                serviceAgentThursdayClosingTime: state.serviceAgentMondayClosingTime,
                serviceAgentFridayClosingTime: state.serviceAgentMondayClosingTime,
                serviceAgentSaturdayClosingTime: state.serviceAgentMondayClosingTime,
                serviceAgentSundayClosingTime: state.serviceAgentMondayClosingTime,
                serviceAgentBankHolidayClosingTime: state.serviceAgentMondayClosingTime
            }));
        }
    };

    const ModuleChange = (e, value) => {
        setSelectedData((st) => ({ ...st, modules: value }));
        setState((st) => {
            const nst = { ...st, serviceAgentFacilitiId: value.map((c) => c.id) };
            return nst;
        });
    };

    const selectHandler = (e) => {
        const { name, checked } = e.target;
        setState((state) => ({ ...state, [name]: checked }));
    };

    const setVal = (s) => {
        setState((st) => ({
            ...st,
            serviceAgentAddress1: s.addressLine1,
            serviceAgentAddress2: s.addressLine2,
            serviceAgentCity: s.town,
            serviceAgentCounty: s.county,
            serviceAgentPostCode: s.postCode
        }));
    };

    const validations = () => {
        const {
            serviceAgentAddress1,
            serviceAgentCity,
            serviceAgentPostCode,
            serviceAgentCurrencyIDValue,
            serviceAgentEmail,
            serviceAgentName,
            serviceAgentSendJobApprovalReceived,
            serviceAgentobApprovalReceivedEmail,
            serviceAgentSendQueryResponseReceived,
            serviceAgentQueryResponseReceivedEmail,
            serviceAgentSendOrderConfirmation,
            serviceAgentOrderConfirmationEmail,
            serviceAgentSendBookingEmail,
            serviceAgentBookingEmail,
            serviceAgentSendJobUpdateEmail,
            serviceAgentJobUpdateEmail,
            serviceAgentServiceManagerEmail
        } = state;
        const re =
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let formIsValid = true;
        let errors = {};
        if (!emailValidation(serviceAgentEmail)) {
            errors.serviceAgentEmail = 'Please Enter a valid Email ID';
            formIsValid = false;
        } else if (!re.test(serviceAgentEmail)) {
            errors.serviceAgentEmail = "Emai must be contain '@'";
            formIsValid = false;
        }
        if (!serviceAgentAddress1) {
            errors.serviceAgentAddress1 = 'Address1 is required';
            formIsValid = false;
        }
        if (!serviceAgentCity) {
            errors.serviceAgentCity = 'City is required';
            formIsValid = false;
        }
        if (!serviceAgentPostCode) {
            errors.serviceAgentPostCode = 'Post Code is required';
            formIsValid = false;
        }
        if (!serviceAgentCurrencyIDValue) {
            errors.serviceAgentCurrencyIDValue = 'Currency is required';
            formIsValid = false;
        }
        if (!serviceAgentName) {
            errors.serviceAgentName = 'Name is required';
            formIsValid = false;
        }
        if (!emailValidation(serviceAgentServiceManagerEmail) && /[^\s]/.test(serviceAgentServiceManagerEmail) && !isNull(serviceAgentServiceManagerEmail)) {
            errors.serviceAgentServiceManagerEmail = 'Please Enter a valid Email ID';
            formIsValid = false;
        }

        if (serviceAgentSendJobApprovalReceived && !emailValidation(serviceAgentobApprovalReceivedEmail) && !isNull(serviceAgentobApprovalReceivedEmail)) {
            errors.serviceAgentobApprovalReceivedEmail = 'Please Enter a valid Email ID';
            formIsValid = false;
        }
        if (serviceAgentSendOrderConfirmation && !emailValidation(serviceAgentOrderConfirmationEmail) && !isNull(serviceAgentOrderConfirmationEmail)) {
            errors.serviceAgentOrderConfirmationEmail = 'Please Enter a valid Email ID';
            formIsValid = false;
        }

        if (serviceAgentSendBookingEmail && !emailValidation(serviceAgentBookingEmail) && !isNull(serviceAgentBookingEmail)) {
            errors.serviceAgentBookingEmail = 'Please Enter a valid Email ID';
            formIsValid = false;
        }
        if (serviceAgentSendJobUpdateEmail && !emailValidation(serviceAgentJobUpdateEmail) && !isNull(serviceAgentJobUpdateEmail)) {
            errors.serviceAgentJobUpdateEmail = 'Please Enter a valid Email ID';
            formIsValid = false;
        }
        if (
            serviceAgentSendQueryResponseReceived &&
            !emailValidation(serviceAgentQueryResponseReceivedEmail) &&
            !isNull(serviceAgentQueryResponseReceivedEmail)
        ) {
            errors.serviceAgentQueryResponseReceivedEmail = 'Please Enter a valid Email ID';
            formIsValid = false;
        }

        setState((st) => ({ ...st, errors: errors }));
        return formIsValid;
    };

    const submitHandler = async () => {
        if (validations()) {
            const formData = {
                serviceAgentID: state.serviceAgentID,
                serviceAgentName: state.serviceAgentName,
                serviceAgentAddress1: state.serviceAgentAddress1,
                serviceAgentAddress2: state.serviceAgentAddress2,
                serviceAgentCounty: state.serviceAgentCounty, //state change kar
                serviceAgentCity: state.serviceAgentCity,
                serviceAgentPostCode: state.serviceAgentPostCode,
                serviceAgentPhone: state.serviceAgentPhone,
                serviceAgentFax: state.serviceAgentFax,
                serviceAgentEmail: state.serviceAgentEmail,
                serviceAgentWebSite: state.serviceAgentWebSite,
                serviceAgentStartDate: state.serviceAgentStartDate,
                serviceAgentMaintenanceContractRef: state.serviceAgentMaintenanceContractRef,
                serviceAgentAccountCentreID: state.serviceAgentAccountCentreID,
                serviceAgentOrderEmail: state.serviceAgentOrderEmail,
                serviceAgentCurrencyID: state.serviceAgentCurrencyIDValue?.id,
                serviceAgentLabourRate: state.serviceAgentLabourRate,
                serviceAgentOutOfHoursLabourRate: state.serviceAgentOutOfHoursLabourRate,
                serviceAgentOrderConfirmationName: state.serviceAgentOrderConfirmationName,
                serviceAgentOrderConfirmationPhone: state.serviceAgentOrderConfirmationPhone,
                serviceAgentSendOrderConfirmation: state.serviceAgentSendOrderConfirmation,
                serviceAgentOrderConfirmationEmail: state.serviceAgentOrderConfirmationEmail,
                serviceAgentBookingName: state.serviceAgentBookingName,
                serviceAgentBookingPhone: state.serviceAgentBookingPhone,
                serviceAgentSendBookingEmail: state.serviceAgentSendBookingEmail,
                serviceAgentBookingEmail: state.serviceAgentBookingEmail,
                serviceAgentSendJobUpdateEmail: state.serviceAgentSendJobUpdateEmail,
                serviceAgentJobUpdateEmail: state.serviceAgentJobUpdateEmail,
                serviceAgentSendQueryResponseReceived: state.serviceAgentSendQueryResponseReceived,
                serviceAgentQueryResponseReceivedEmail: state.serviceAgentQueryResponseReceivedEmail,
                serviceAgentSendJobApprovalReceived: state.serviceAgentSendJobApprovalReceived,
                serviceAgentobApprovalReceivedEmail: state.serviceAgentobApprovalReceivedEmail,
                serviceAgentNominalCode: state.serviceAgentNominalCode,
                serviceAgentServiceManagerName: state.serviceAgentServiceManagerName,
                serviceAgentServiceManagerPhone: state.serviceAgentServiceManagerPhone,
                serviceAgentServiceManagerEmail: state.serviceAgentServiceManagerEmail,
                handlingDefects: state.handlingDefects,
                serviceAgentActive: state.serviceAgentActive,
                hasInspectionSheetControl: state.hasInspectionSheetControl,
                serviceAgentPaymentDays: state.serviceAgentPaymentDaysValue?.id,
                serviceAgentAccountCode: state.serviceAgentAccountCode,
                serviceAgentFacilities: state.serviceAgentFacilitiId,

                serviceAgentOpenMonday: state.serviceAgentOpenMonday,
                serviceAgentMondayOpeningTime: state.serviceAgentMondayOpeningTime ? state.serviceAgentMondayOpeningTime.replace(':', '.') : '',
                serviceAgentMondayClosingTime: state.serviceAgentMondayClosingTime ? state.serviceAgentMondayClosingTime.replace(':', '.') : '',
                serviceAgentOpenTuesday: state.serviceAgentOpenTuesday,
                serviceAgentTuesdayOpeningTime: state.serviceAgentTuesdayOpeningTime ? state.serviceAgentTuesdayOpeningTime.replace(':', '.') : '',
                serviceAgentTuesdayClosingTime: state.serviceAgentTuesdayClosingTime ? state.serviceAgentTuesdayClosingTime.replace(':', '.') : '',
                serviceAgentOpenWednesday: state.serviceAgentOpenWednesday,
                serviceAgentWednesdayOpeningTime: state.serviceAgentWednesdayOpeningTime ? state.serviceAgentWednesdayOpeningTime.replace(':', '.') : '',
                serviceAgentWednesdayClosingTime: state.serviceAgentWednesdayClosingTime ? state.serviceAgentWednesdayClosingTime.replace(':', '.') : '',
                serviceAgentOpenThursday: state.serviceAgentOpenThursday,
                serviceAgentThursdayOpeningTime: state.serviceAgentThursdayOpeningTime ? state.serviceAgentThursdayOpeningTime.replace(':', '.') : '',
                serviceAgentThursdayClosingTime: state.serviceAgentThursdayClosingTime ? state.serviceAgentThursdayClosingTime.replace(':', '.') : '',
                serviceAgentOpenFriday: state.serviceAgentOpenFriday,
                serviceAgentFridayOpeningTime: state.serviceAgentFridayOpeningTime ? state.serviceAgentFridayOpeningTime.replace(':', '.') : '',
                serviceAgentFridayClosingTime: state.serviceAgentFridayClosingTime ? state.serviceAgentFridayClosingTime.replace(':', '.') : '',
                serviceAgentOpenSaturday: state.serviceAgentOpenSaturday,
                serviceAgentSaturdayOpeningTime: state.serviceAgentSaturdayOpeningTime ? state.serviceAgentSaturdayOpeningTime.replace(':', '.') : '',
                serviceAgentSaturdayClosingTime: state.serviceAgentSaturdayClosingTime ? state.serviceAgentSaturdayClosingTime.replace(':', '.') : '',
                serviceAgentOpenSunday: state.serviceAgentOpenSunday,
                serviceAgentSundayOpeningTime: state.serviceAgentSundayOpeningTime ? state.serviceAgentSundayOpeningTime.replace(':', '.') : '',
                serviceAgentSundayClosingTime: state.serviceAgentSundayClosingTime ? state.serviceAgentSundayClosingTime.replace(':', '.') : '',
                serviceAgentOpenBankHoliday: state.serviceAgentOpenBankHoliday,
                serviceAgentBankHolidayOpeningTime: state.serviceAgentBankHolidayOpeningTime ? state.serviceAgentBankHolidayOpeningTime.replace(':', '.') : '',
                serviceAgentBankHolidayClosingTime: state.serviceAgentBankHolidayClosingTime ? state.serviceAgentBankHolidayClosingTime.replace(':', '.') : ''
            };
            setBtnLoader(true);
            let res = await postServiceAgentData(formData);
            if (res.success) {
                showToastSuccess(res.message);
                props.onFormSubmit(true);
                history.push(`/serviceagent`);
            } else {
                showToastError(res.message);
            }
            setBtnLoader(false);
        }
    };
    return (
        <ServiceAgentScreenContext.Provider
            value={{
                state,
                selectedData,
                submitHandler,
                inputChange,
                setAllTime,
                resetInput,
                selectHandler,
                ModuleChange,
                setVal,
                singleSelectChange
            }}
        >
            {props.children}
        </ServiceAgentScreenContext.Provider>
    );
};
