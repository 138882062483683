import { createContext, useEffect, useState } from 'react';
import { useToast } from '../../../../Core/Hooks/useToast';
import {
    getAllDropdowns,
    ReportsDetailsSave,
    getReportsById,
    ReportsColumnsGetDetails,
    ReportsColumnsSave,
    ReportSelectionsGetDetails,
    ReportSelectionsSave,
    UserReportSequenceSave,
    GetReportSequence,
    ReportCombinedSave
} from '../../../../Core/Services/InternalUser/ReportsService';

export const ReportsScreenContext = createContext();

export const ReportsProvider = (props) => {
    const { showToastSuccess, showToastError } = useToast();

    const [state, setState] = useState({
        activeStep: 0,
        userReportName: '',
        errors: {},
        userReportSendEmail: false,
        operator: [
            { id: '=', name: '=' },
            { id: '>', name: '>' },
            { id: '>=', name: '>=' },
            { id: '<', name: '<' },
            { id: '<=', name: '<=' }
        ],
        schedule: [],
        reportCatalogue: [],
        userReportID: null,
        selectionList: [],
        columnList: [],
        allColumnsList: [],
        userColumnId: null,
        selectedColumns: [],
        rows: [],
        showLoader: true,
        sequenceReportCatID: null,
        selectiondetails: [],
        sequenceColumList: [],
        sequenceList: [
            { id: 'asc', name: 'Ascending' },
            { id: 'desc', name: 'Descending' }
        ],
        getCol: false,
        sequenceVM: [],
        userReportSequenceOrder: '',
        sequenceReportCatID: null,
        saveReportFlag: false,
        AllsequenceColumList: []
    });

    useEffect(async () => {
        let res = await getAllDropdowns();
        let data = res.data;
        if (res.success) {
            setState((st) => ({
                ...st,
                // operator: [],
                reportCatalogue: data.reportCatalogues.map((m) => ({ id: m.reportCatalogueID, name: m.reportCatalogueName })),
                schedule: data.reportSchedules.map((m) => ({ id: m.reportScheduleID, name: m.reportScheduleTitle }))
            }));
        } else {
            showToastError(res.messages);
        }
        if (props.userReportID) {
            let res = await getReportsById(props.userReportID);
            let data = res?.data?.details[0];
            setState((st) => ({
                ...st,
                userReportUserID: data?.userReportUserID,
                userReportID: data?.userReportID,
                userReportName: data?.userReportName,
                userReportScheduleID: data?.userReportScheduleID,
                userReportSendEmail: data?.userReportSendEmail,
                userReportCatalogueID: data?.userReportCatalogueID
            }));
            getDetailsSelections(props.userReportID);
            getColumnsDetails(props.userReportID);
        }
    }, []);

    const getColumnsDetails = async (val) => {
        let res = await ReportsColumnsGetDetails(val);
        const data = res.data?.reportCatalogueColumns?.map((w) => ({
            id: w.reportCatalogueColumnID,
            name: w.reportCatalogueColumnDisplayName
        }));
        if (res.success) {
            setState((m) => ({
                ...m,
                columnList: data,
                allColumnsList: res.data?.reportCatalogueColumns,
                rows: res.data?.details?.map((m) => ({ ...m, reportCatalogueColumnID: m.userReportCatalogueColumnID })) || [],
                selectedColumns: res.data?.details?.map((w) => w.userReportCatalogueColumnID) || [],
                getCol: true
            }));
        }
    };

    const getFilteredRows = (id) => {
        let filteredRows = [...state.rows];
        let isAvi = state.selectedColumns.find((q) => q == id);
        if (!isAvi) {
            let temp = [...new Set([...state.selectedColumns, id])];
            let tuka = state.allColumnsList?.filter((i) => i.reportCatalogueColumnID == id);
            filteredRows = [...filteredRows, ...tuka];
            setState((st) => ({ ...st, rows: filteredRows, selectedColumns: temp, showLoader: false }));
        }
    };

    const handleDelete = (id) => {
        const newList = state.rows.filter((item) => item.reportCatalogueColumnID !== id);
        const newSelectedColumns = state.selectedColumns.filter((item) => item !== id);
        setState((st) => ({ ...st, rows: newList, selectedColumns: newSelectedColumns }));
    };

    const inputChange = (e) => {
        const { name, value, checked, type } = e.target;
        if (name == 'userColumnId' && value) {
            getFilteredRows(value);
        } else if (type?.toLowerCase() === 'checkbox') {
            setState((st) => ({ ...st, [name]: checked }));
        } else {
            setState((st) => ({ ...st, [name]: value }));
        }
    };
    const reportInputChange = (e) => {
        const { name, value, checked, type } = e.target;
        if (type?.toLowerCase() === 'checkbox') {
            setState((st) => ({ ...st, [name]: checked, saveReportFlag: true }));
        } else {
            setState((st) => ({ ...st, [name]: value, saveReportFlag: true }));
        }
    };

    const handleSubmit = async () => {
        const selectionList = state.selectionList.map((m) => ({
            userReportCatalogueColumnID: m?.reportCatalogueColumnID,
            userReportSelectionOperator: m?.userReportSelectionOperator,
            userReportFromValue: m?.userReportFromValue,
            userReportToValue: m?.userReportToValue
        }));

        const combinedData = {
            userReportID: state.userReportID,
            userReportCatalogueID: state.userReportCatalogueID,
            userReportName: state.userReportName,
            userReportScheduleID: state.userReportScheduleID,
            userReportSendEmail: state.userReportSendEmail,
            userReportColumns: state.selectedColumns,
            userReportSelectionsVM: selectionList.filter((o) => {
                if (o.userReportSelectionOperator) return o;
            }),
            userReportSequenceReportCatalogueColumnID: state.sequenceVM?.map((m) => ({
                userReportSequenceReportCatalogueColumnID: m.userReportSequenceReportCatalogueColumnID,
                userReportSequenceOrder: m.userReportSequenceOrder
            }))
        };

        let res = await ReportCombinedSave(combinedData);
        if (res.success) {
            showToastSuccess('Data Saved Successfully');
            props.onClose(true);
        } else {
            showToastError('Something went Wrong');
        }
    };

    const handlePreviousStep = (step) => {
        if (state.activeStep + step < 0 || state.activeStep + step > 3) return;
        setState((st) => ({
            ...st,
            activeStep: state.activeStep + step
        }));
    };

    const handleNextSteps = async (step) => {
        if (state.activeStep == 2) {
            if (validation()) {
                setState((st) => ({
                    ...st,
                    activeStep: state.activeStep + step
                }));
            }
        } else {
            if (state.activeStep + step < 0 || state.activeStep + step > 3) return;
            setState((st) => ({
                ...st,
                activeStep: state.activeStep + step
            }));
        }
        if (state.activeStep == 0 && state.saveReportFlag) {
            handelSaveReportDetails();
        }
    };

    const handelSaveReportDetails = async () => {
        const data = {
            userReportID: state.userReportID,
            userReportCatalogueID: state.userReportCatalogueID,
            userReportName: state.userReportName,
            userReportScheduleID: state.userReportScheduleID,
            userReportSendEmail: state.userReportSendEmail
        };
        let res = await ReportsDetailsSave(data);
        if (res.success) {
            setState((st) => ({
                ...st,
                userReportID: res.data?.userReportID,
                saveReportFlag: false
            }));
            getDetailsSelections(res.data.userReportID);
            getColumnsDetails(res.data.userReportID);
            showToastSuccess('Columns Saved SuccessFully');
        } else {
            showToastError(res.messages);
        }
    };

    const getSequenceById = async (val) => {
        let res = await GetReportSequence(val);
        if (res.success) {
            const temp = res?.data?.reportCatalogueColumns;
            const val = res?.data?.details;

            setState((st) => ({
                ...st,
                sequenceVM: val || [],
                AllsequenceColumList: temp
            }));
        }
    };

    useEffect(() => {
        const results = state.AllsequenceColumList.filter(
            ({ reportCatalogueColumnID: id1 }) => !state.sequenceVM?.some(({ userReportSequenceReportCatalogueColumnID: id2 }) => id2 === id1)
        );
        const data = results.map((w) => ({
            id: w.reportCatalogueColumnID,
            name: w.reportCatalogueColumnDisplayName
        }));
        setState((st) => ({
            ...st,
            sequenceColumList: data
        }));
    }, [state.sequenceVM]);

    const deleteSequence = (data) => {
        const newList = state.sequenceVM?.filter((item) => item.userReportSequenceReportCatalogueColumnID !== data.userReportSequenceReportCatalogueColumnID);
        setState((st) => ({ ...st, sequenceVM: newList }));
    };

    const validation = () => {
        let formIsValid = true;
        let errors = {};
        let checkOpCondition = state.selectionList.map((m) => {
            if (m.userReportSelectionOperator == '=') {
                if (!/[^\s]/.test(m.userReportFromValue) || !m.userReportFromValue) {
                    errors[`fromError${m.reportCatalogueColumnID}`] = 'From value is required';
                    formIsValid = false;
                }
                // if (!/[^\s]/.test(m.userReportToValue) || !m.userReportToValue) {
                //     errors[`toError${m.reportCatalogueColumnID}`] = 'To value is required';
                //     formIsValid = false;
                // }
            }
            if (m.userReportSelectionOperator == '>' || m.userReportSelectionOperator == '>=') {
                if (!m.userReportFromValue) {
                    errors[`fromError${m.reportCatalogueColumnID}`] = 'From value is required';
                    formIsValid = false;
                }
            }
            if (m.userReportSelectionOperator == '<' || m.userReportSelectionOperator == '<=') {
                if (!m.userReportToValue) {
                    errors[`toError${m.reportCatalogueColumnID}`] = 'To value is required';
                    formIsValid = false;
                }
            }
            if (!m.userReportSelectionOperator) {
                if (m.userReportFromValue || m.userReportFromValue) {
                    errors[`toOprator${m.reportCatalogueColumnID}`] = 'Oprator value is required';
                    formIsValid = false;
                }
            }
            return m;
        });
        setState((st) => ({
            ...st,
            selectionList: checkOpCondition,
            errors: errors
        }));
        return formIsValid;
    };

    const getDetailsSelections = async (val) => {
        let res = await ReportSelectionsGetDetails(val);
        if (res.success) {
            const temp = res?.data?.reportCatalogueColumns;
            const val = res?.data?.details;
            let selectionDetailsList = [];
            for (let i = 0; i < temp.length; i++) {
                selectionDetailsList.push({
                    ...temp[i],
                    ...val?.find((itmInner) => itmInner.userReportCatalogueColumnID === temp[i].reportCatalogueColumnID)
                });
            }
            setState((st) => ({
                ...st,
                selectionList: selectionDetailsList
            }));
        }
    };

    const seqValidations = () => {
        const { sequenceReportCatID, userReportSequenceOrder } = state;
        let emptyStrigPattern = /[^\s]/;
        let formIsValid = true;
        let errors = {};
        if (!emptyStrigPattern.test(sequenceReportCatID)) {
            errors.sequenceReportCatID = 'Column is required';
            formIsValid = false;
        }
        // if (!emptyStrigPattern.test(userReportSequenceOrder)) {
        //     errors.userReportSequenceOrder = ' Order is required';
        //     formIsValid = false;
        // }
        if (!userReportSequenceOrder) {
            errors.userReportSequenceOrder = ' Order is required';
            formIsValid = false;
        }
        setState((state) => ({ ...state, errors: errors }));
        return formIsValid;
    };

    const handelAddSequence = () => {
        if (seqValidations()) {
            let colname = state.allColumnsList?.find(
                (itmInner) => itmInner.reportCatalogueColumnID == state.sequenceReportCatID
            ).reportCatalogueColumnDisplayName;
            const data = {
                reportCatalogueColumnDisplayName: colname,
                userReportSequenceReportCatalogueColumnID: state.sequenceReportCatID,
                userReportSequenceOrder: state.userReportSequenceOrder,
                seqDescription: state.userReportSequenceOrder === 'asc' ? 'Ascending' : 'Descending'
            };
            let temp = [...state.sequenceVM, data];
            setState((st) => ({ ...st, sequenceVM: temp, sequenceReportCatID: '', userReportSequenceOrder: null }));
        }
    };

    const selectionOnChange = (id) => (e) => {
        const { name, value } = e.target;
        let temp = [...state.selectionList];
        let objIndex = state.selectionList.findIndex((x) => x.reportCatalogueColumnID === id);
        temp[objIndex][`${name}`] = value;
        const op = temp[objIndex]['userReportSelectionOperator'];
        if (op == '>' || op == '>=') {
            temp[objIndex][`userReportToValue`] = '';
        }
        if (op == '<' || op == '<=') {
            temp[objIndex][`userReportFromValue`] = '';
        }
        setState((st) => ({ ...st, selectionList: temp }));
    };

    useEffect(() => {
        if (state.activeStep === 3) {
            getSequenceById(state.userReportID);
        }
    }, [state.activeStep]);

    return (
        <ReportsScreenContext.Provider
            value={{
                state,
                handleSubmit,
                inputChange,
                handlePreviousStep,
                handleNextSteps,
                selectionOnChange,
                handelAddSequence,
                handleDelete,
                reportInputChange,
                deleteSequence
            }}
        >
            {props.children}
        </ReportsScreenContext.Provider>
    );
};
