import React, { useEffect, useState, useMemo } from 'react';
import { useToast } from '../../../../Core/Hooks/useToast';
import { getDepots } from '../../../../Core/Services/InternalUser/ControllerService/depotsService';
import { EditButton, DataGrid, BreadCrumbs, formatters } from '../../../../Core/Controls';
import AddEditDepots from './addEditDepots';
import '../../../commonStyle.scss';
import GijgoGrid from '../../../../Core/Controls/GijgoGrid';

const baseColumns = [
    { key: 'action', name: 'Action', width: 80, addFileUploaderIcon: true },
    { key: 'depotName', name: 'Name' },
    {
        key: 'depotAddress',
        name: 'Address'
    },

    { key: 'depotCountryCode', name: 'Code', width: 80 },
    { key: 'depotEmail', name: 'Email', width: 250 }
];

const baseCrumbs = [
    { name: 'Home', path: '/' },

    { name: 'Depots', active: true }
];

const CustomerDepots = (props) => {
    const { showToastError } = useToast();

    const [state, setState] = useState({
        show: false,
        showLoader: true,
        depotsList: [],
        depotID: null,
        isReload: null
    });

    const handleAdd = (record) => {
        setState((st) => ({
            ...st,
            show: true,
            depotID: record
        }));
    };

    const pullDepotsAndUpdateState = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    useEffect(async () => {
        pullDepotsAndUpdateState();
    }, []);

    const dataFormatter = (data, row) => {
        return (
            <div
                style={{
                    textAlign: 'center'
                }}
            >
                <EditButton
                    toolTipTitle="Edit Depot"
                    onClick={() => {
                        handleAdd(row.depotID);
                    }}
                />
            </div>
        );
    };

    const closeDepotsModal = (res) => {
        setState((st) => ({
            ...st,
            show: false
        }));
        res && pullDepotsAndUpdateState();
    };
    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.key === 'action');
        if (actionCol) {
            actionCol.formatter = dataFormatter;
        }
        return tempCols;
    }, []);

    const crumbs = useMemo(() => {
        const tempCols = [...baseCrumbs];
        let actionCol = tempCols.find((element) => element.name === 'Depots');
        if (actionCol) {
            actionCol.onClick = closeDepotsModal;
        }
        return tempCols;
    }, []);

    let getUrl = `depots`;

    return (
        <div className="screen">
            <BreadCrumbs crumbs={crumbs} />
            {state.show ? (
                <AddEditDepots depotID={state.depotID} onFormClose={closeDepotsModal} />
            ) : (
                <>
                    <GijgoGrid
                        dataConverter={(res) => ({
                            records: res.data.list,
                            total: res.data.total
                        })}
                        columns={columns}
                        getUrl={getUrl}
                        onBtnClick={() => handleAdd(null)}
                        btnTitle="Add Depot"
                        isReload={state.isReload}
                        idFieldName="depotID"
                        fileUploaderConfigName="DepotScreen"
                    />
                </>
            )}
        </div>
    );
};

export default CustomerDepots;
