import React, { useState, useEffect, useMemo } from 'react';
import { getAllJobItems, createInvoice } from '../../../../../Core/Services/InternalUser/JOB/jobItemsService';
import { getJobDetailsById, getJobRequiredDocuments } from '../../../../../Core/Services/InternalUser/ControllerService/jobDetailsService';

import DataGrid from '../../../../../Core/Controls/Grid';
import { AddButton, CustomChip, DateTimePicker, EditButton, formatters, PrimaryCheckbox } from '../../../../../Core/Controls';
import AddLabourModal from './addJobItem';
import '../../../../commonStyle.scss';
import '../../Notes/NotesandSticky.scss';
import JobDetail from './JobDetails';
import DialogComp from '../../../../../Core/Dialog/DialogComp';
import Grid from '@material-ui/core/Grid';
import { FormHelperText, IconButton, InputLabel, Tooltip, Typography } from '@material-ui/core';
import { SecondaryButton, DatePicker, MandatoryField, SecondaryCheckbox } from '../../../../../Core/Controls';
import { Table, TableBody, TableRow, TableCell, TableHead } from '@material-ui/core';
import { useToast } from '../../../../../Core/Hooks/useToast';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import { some } from 'lodash';
import { useLoginInfo } from '../../../../../Core/Hooks/useLoginInfo';
import { useHistory } from 'react-router-dom';
import StatusBasedButton from '../JobDetails/ServiceAgent/statusBtn';
import SellerInvoiceModal from './JobInvoiceLines/sellerInvoice';
import CustStatusBasedButton from '../JobDetails/Customer/statusBtn';
import moment from 'moment';
import PurchaseInvoice from './JobInvoiceLines/purchaseInvoice';
import Alert from '@material-ui/lab/Alert';
import InfoIcon from '@material-ui/icons/Info';
import JobItemHeaderDates from './jobItemHeaderDates';
import FileUploaderButton from '../../../../../Core/Controls/GenericFileUploader';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import JobQueriesScreen from '../jobQueriesScreen';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import UpdateIcon from '@material-ui/icons/Update';

import UpcomingEvents from '../../Vehicles/ScheduleVehicle/upcomingEvents';
import { CustomIconButton } from '../../../../../Core/Controls/Inputs/Buttons/ButtonModal';
import { AppStorage } from '../../../../../Core/Basic/storage-service';

// Assigned AA, Estimate EP, JP In Progress -> Amber
// Unassigned AU, Job Query JQ, FR Awaiting Cost Review, JD Job Deleted, JR Job Rejected -> Red
// Costs Approved CA, JC Job Completed, JA Job Accepted -> Green

const alertBoxColorCode = {
    AA: 'warning',
    EP: 'warning',
    JP: 'warning',
    AU: 'error',
    JQ: 'error',
    FR: 'error',
    JD: 'error',
    JR: 'error',
    CA: 'success',
    JC: 'success',
    JA: 'success'
};

function JobItemsScreen(props) {
    let checkhaveAccess = AppStorage.getAllowAcces();
    const { userRoles } = useLoginInfo();
    const isCustomer = userRoles.includes('customer');
    const [state, setState] = useState({
        jobItemsList: [],
        loader: true,
        open: false,
        jobStatusID: '',
        showInvoice: false,
        refresherFlag: new Date(),
        allApprovedJob: [],
        errors: {},
        showOk: true,
        showSellerBtn: true,
        showSellerInvoice: false,
        showCreate: false
    });
    const history = useHistory();

    const dataReload = () => {
        setState((state) => ({ ...state, refresherFlag: new Date() }));
    };

    const base_columns = [
        {
            key: 'action',
            name: 'Action',
            width: 130
        },
        {
            key: 'jobItemDescription',
            name: 'Description',
            width: '40%',
            formatter: (params) => {
                return (
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            {params.row.jobItemDescription}
                        </Grid>
                        <Grid item xs={12} style={{ fontSize: '13px' }}>
                            {params.row.vmrs}
                        </Grid>
                    </Grid>
                );
            },
            cellClass: 'react-data-grid-cell-line-height'
        }
    ];

    useEffect(async () => {
        pullJobDetails();
        pullJobItems();
    }, [state.refresherFlag]);

    const [jobDetails, setJobDetails] = useState({
        jobDescription: '',
        jobDepotID: '',
        jobServiceAgentID: '',
        vehicleMake: '',
        vehicleModel: '',
        jobRegNumber: '',
        refreshDependancy: ''
    });

    const [jobdocument, setJobDocument] = useState({
        list: []
    });

    const pullJobDetails = async () => {
        let res = await getJobDetailsById(props.scheduleJobID);
        let res1 = res.data.list;
        let data = res1?.length > 0 ? res.data?.list[0] : res.data?.list;
        if (data) {
            setJobDetails((st) => ({
                ...st,
                jobID: data.jobID,
                jobAgentOrderNo: data.jobAgentOrderNo,
                jobOrderDate: formatters.DateOnlyFormatter(data.jobDate),
                jobOrderTotal: data.orderTotal,
                //jobCustomerOrderNo: data.jobCustomerOrderNo || '',
                jobEstimatedCost: data.jobEstimatedCost || '',
                // jobProformaInvNet: data.jobProformaInvNet || '',
                jobServiceAgentID: data.jobServiceAgentID || '',
                jobVehicleID: data.jobVehicleID,
                jobRegNumber: data.jobRegNumber,
                vehicleMake: data.vehicleMake,
                vehicleModel: data.vehicleModel,
                vehicleJobStatus: data.vehicleJobStatus,
                jobStatusID: data.jobStatusID,
                // jobApprovedDate: formatters.DateFormatter(data.jobApprovedDate),
                jobApprovedOrderNo: data.jobApprovedOrderNo,
                //  jobApproved: data.jobApproved,
                mileageRequired: data.mileageRequired,
                customerName: data.customerCompanyName,
                jobDescription: data.jobDescription,
                bookingDate: data.bookingDate,
                completionDate: data.completionDate,
                vehicleArrived: data.vehicleArrived,
                jobMileageDate: data.jobMileageDate || '',
                jobMileage: data.jobMileage || '',
                refreshDependancy: new Date(),
                jobCustomerOrderTotal: data.jobCustomerOrderTotal || 0,
                jobOrderNo: data.jobOrderNo,
                jobIsEstimate: data.jobIsEstimate,
                islabourRateSet: data.labourRate == 0 ? false : true,
                labourRate: data.labourRate,
                jobInAgentQuery: data.jobInAgentQuery
            }));
        }
    };
    const goPreviousScreen = () => {
        history.goBack();
    };

    useEffect(async () => {
        if (jobDetails.jobID) {
            let res = await getJobRequiredDocuments(jobDetails.jobID);
            setJobDocument((state) => ({ ...state, list: res.data.list }));
        }
    }, [jobDetails.jobID]);

    const pullJobItems = async () => {
        let res = await getAllJobItems(props.scheduleJobID);
        let res2 = '';
        if (isCustomer) {
            res2 = res.data.vehicleJobItems.map((m) => {
                let Labour = (m.jobItemCustomerLabHourRate * m.jobItemCustomerLabourHours * (100 - m.jobItemCustomerLabourDiscount)) / 100;
                return {
                    totalLabour: Labour,
                    total: Labour + m.jobItemCustomerPartsPrice,
                    ...m
                };
            });
        } else {
            res2 = res.data.vehicleJobItems.map((m) => {
                let Labour = (m.jobItemLabHourRate * m.jobItemLabourHours * (100 - m.jobItemLabourDiscount)) / 100;
                return {
                    totalLabour: Labour,
                    total: Labour + m.jobItemPartsprice,
                    ...m
                };
            });
        }

        setState((state) => {
            const nst = { ...state };
            nst.jobItemsList = res2;
            nst.loader = false;
            nst.checkApprovedJob = some(res.data.vehicleJobItems, (r) => r.jobItemStatusID == 'A');
            nst.allSellerJob = res2
                ?.filter(function (p) {
                    return p.jobItemStatusID === 'A' && p.chargeCustomer == true;
                })
                .map(function (i) {
                    return {
                        ...i,
                        salesInvoiceItemDescription: i.jobItemDescription,
                        salesInvoiceItemVAT: null,
                        salesInvoiceItemVATRateID: null,
                        salesInvoiceItemNet: i.total,
                        isSelected: true
                    };
                });
            nst.allApprovedJob = res2
                ?.filter(function (p) {
                    return p.jobItemStatusID === 'A' && p.paySupplier == true;
                })
                .map(function (i) {
                    return { ...i, isSelected: true };
                });

            return nst;
        });
    };

    useEffect(() => {
        let getSelectedJob = some(state.allApprovedJob, (r) => r.isSelected);
        let getSellerJOb = some(state.allSellerJob, (r) => r.isSelected);
        setState((state) => {
            const nst = { ...state, showCreate: getSelectedJob, showOk: getSelectedJob, showSellerBtn: getSellerJOb };
            return nst;
        });
    }, [state.allApprovedJob, state.allSellerJob]);

    const btnHandler = (params) => {
        setState((state) => ({ ...state, open: true, jobItemID: params.jobItemID, vehicleID: params.vehicleID, vehicleRegNumber: params.vehicleRegNumber }));
    };

    const createInvoiceHandler = (name) => {
        setState((state) => ({ ...state, [name]: true }));
    };

    const ActionButtons = (data) => {
        return (
            <div style={{ textAlign: 'left', marginLeft: '10px' }}>
                <EditButton toolTipTitle="Edit Job Item" onClick={() => btnHandler(data.row)} />
            </div>
        );
    };

    let checkStatus = jobDetails.jobStatusID === 'AA' || jobDetails.jobStatusID === 'JP' || jobDetails.jobStatusID === 'JQ';
    const isServiceAgent = userRoles.includes('serviceagent');
    const columns = useMemo(() => {
        let newColumn = [...base_columns];
        let actionCol = newColumn.find((item) => item.key === 'action');
        if (actionCol) {
            actionCol.formatter = ActionButtons;
            actionCol.headerRenderer = () => {
                if (checkStatus) {
                    return (
                        <Grid container alignItems="center">
                            <AddButton toolTipTitle="Add Job Item" onClick={btnHandler} />
                            {/* <Tooltip title="Click here set Booking Date">
                                <IconButton color="secondary" aria-label="delete" style={{ padding: '0px' }}>
                                    <UpdateIcon onClick={() => createInvoiceHandler('showUpComingEvents')} />
                                </IconButton>
                            </Tooltip> */}
                            {checkhaveAccess?.canAddScheduleItemstoJob && (
                                <CustomIconButton
                                    icon={UpdateIcon}
                                    className="modal-btn-update "
                                    onClick={() => createInvoiceHandler('showUpComingEvents')}
                                    toolTipTitle="Upcoming Events"
                                />
                            )}
                            <span style={{ marginBottom: '10px', marginLeft: '5px' }}>Action</span>
                        </Grid>
                    );
                } else {
                    return null;
                }
            };
        }
        if (isCustomer) {
            newColumn.splice(
                2,
                0,

                { key: 'jobItemCustomerPartsPrice', name: 'Parts', formatter: formatters.NumberWithPound, headerRenderer: formatters.ColumnHeaderAlignRight },
                { key: 'totalLabour', name: 'Labour', formatter: formatters.NumberWithPound, headerRenderer: formatters.ColumnHeaderAlignRight },
                { key: 'total', name: 'Total', formatter: formatters.NumberWithPound, headerRenderer: formatters.ColumnHeaderAlignRight }
            );
        } else {
            newColumn.splice(
                2,
                0,

                { key: 'jobItemPartsprice', name: 'Parts', formatter: formatters.NumberWithPound, headerRenderer: formatters.ColumnHeaderAlignRight },
                { key: 'totalLabour', name: 'Labour', formatter: formatters.NumberWithPound, headerRenderer: formatters.ColumnHeaderAlignRight },
                { key: 'total', name: 'Total', formatter: formatters.NumberWithPound, headerRenderer: formatters.ColumnHeaderAlignRight }
            );
        }
        if (!isServiceAgent || !isCustomer) {
            newColumn.splice(4, 0, { key: 'jobItemStatusDescription', name: 'Status' });
        }
        return newColumn;
    }, [checkStatus]);

    const closeModal = (res) => {
        setState((state) => ({ ...state, open: false, jobItemID: null, showInvoice: false, showSellerInvoice: false, showUpComingEvents: false }));
        res && refreshScreen();
    };

    const refreshScreen = () => {
        setJobDetails((st) => ({ ...st, refreshDependancy: new Date() }));
        pullJobItems();
        pullJobDetails();
    };

    const isInternal = useLoginInfo().userRoles.includes('internal');

    const getMakeModel =
        jobDetails.vehicleMake || jobDetails.vehicleModel
            ? ` (${jobDetails.vehicleMake ? jobDetails.vehicleMake : ''} ${jobDetails.vehicleModel ? jobDetails.vehicleModel : ''})`
            : '';

    return (
        <div className="controller-screen">
            {/* <JobDetail scheduleJobID={props.scheduleJobID} refresherFlag={state.refresherFlag} /> */}
            <div className="controller-screen">
                <Grid container spacing={0} className="mt_10">
                    <Grid item xs={12} lg={12}>
                        <Grid item container justify="flex-start" xs={12} style={{ backgroundColor: '#f5f9f9', padding: '0 5px' }}>
                            <Grid item xs={12} sm={6} md={2}>
                                <FormHelperText shrink>Job Reference</FormHelperText>
                                <Typography variant="button">{jobDetails?.jobAgentOrderNo || ''}</Typography>
                            </Grid>
                            {jobDetails?.jobOrderNo ? (
                                <Grid item xs={12} sm={6} md={2}>
                                    <FormHelperText shrink>Final Order No </FormHelperText>
                                    <Typography variant="button">{jobDetails?.jobOrderNo || ''}</Typography>
                                </Grid>
                            ) : null}
                            <Grid item xs={12} sm={6} md={2}>
                                <FormHelperText shrink>Order Date</FormHelperText>
                                <Typography variant="button">{jobDetails.jobOrderDate}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <FormHelperText shrink>Customer</FormHelperText>
                                <Typography variant="button">{jobDetails.customerName}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={2}>
                                <FormHelperText shrink>Vehicle Detail</FormHelperText>
                                <Typography variant="button">
                                    {jobDetails.jobRegNumber} {getMakeModel}
                                </Typography>
                            </Grid>
                            {/* <Grid item xs={6} sm={2}>
                                <FormHelperText shrink>Job Status</FormHelperText>
                                <Typography variant="button">{jobDetails.vehicleJobStatus}</Typography>
                            </Grid> */}
                            <Grid item xs={12} sm={12} md={2}>
                                <div style={{ width: '100%', textAlign: 'right' }}>
                                    <FormHelperText style={{ textAlign: 'end' }}>Total</FormHelperText>
                                    <Typography variant="button" style={{ fontSize: 19, fontWeight: 'bold', color: 'black' }}>
                                        £
                                        {isCustomer
                                            ? jobDetails.jobCustomerOrderTotal?.toFixed(2).replace(/(\d)(?=(\d0})+(?!\d))/g, '$1,')
                                            : jobDetails.jobOrderTotal?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                                    </Typography>
                                </div>
                                {/* <FormHelperText>Total</FormHelperText> */}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={jobdocument.list.length > 0 ? 6 : 12} md={jobdocument.list.length > 0 ? 7 : 12} style={{ marginTop: 3 }}>
                                <Grid container spacing={1} className="alert-container">
                                    <Grid item xs={12} sm={6} md={4}>
                                        <Alert icon={false} severity={alertBoxColorCode[jobDetails.jobStatusID]} className="alert-items">
                                            <span style={{ display: 'block', fontSize: '12px' }}>Status</span>
                                            <span style={{ fontWeight: 'bold' }}>{jobDetails.vehicleJobStatus}</span>
                                        </Alert>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={8}>
                                        <Alert icon={<InfoIcon fontSize="inherit" />} severity="warning" className="alert-items">
                                            <span style={{ display: 'block', fontSize: '12px' }}>Job Description</span>
                                            <span style={{ fontWeight: 'bold' }}>{jobDetails.jobDescription}</span>
                                        </Alert>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {jobdocument.list.length > 0 && (
                                <Grid item xs={12} sm={6} md={5} style={{ marginTop: 3 }}>
                                    <Grid
                                        container
                                        alignItems="center"
                                        style={{
                                            height: '95%',
                                            backgroundColor: '#f5f9f9',
                                            padding: '6px 16px'
                                        }}
                                    >
                                        {jobdocument.list.map((m) => {
                                            return (
                                                <Grid item>
                                                    {false ? (
                                                        <div style={{ paddingBottom: '5px' }}>
                                                            {m.docName}{' '}
                                                            <span style={{ position: 'relative', top: '7px', left: '2px', marginRight: '15px' }}>
                                                                <CheckCircleIcon style={{ color: 'green' }} />
                                                            </span>
                                                        </div>
                                                    ) : (
                                                        <div style={{ paddingTop: '5px' }}>
                                                            {m.docName}
                                                            <span style={{ position: 'relative', top: '-7px', left: '7px', marginRight: '15px' }}>
                                                                <FileUploaderButton
                                                                    recordId={m.scheduleDocID}
                                                                    fileUploaderConfigName="ScheduleDocumentsRequired"
                                                                    fileCount={m.fileCount}
                                                                    gridRelaod={dataReload}
                                                                />
                                                            </span>
                                                        </div>
                                                    )}
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            {state.showSellerInvoice && (
                <DialogComp onClose={() => closeModal(false)} title="Create Sales Invoice" maxWidth="lg" fullScreen>
                    <SellerInvoiceModal onClose={closeModal} scheduleJobID={props.scheduleJobID} allSellerJob={state.allSellerJob} />
                </DialogComp>
            )}
            {state.showInvoice && (
                <DialogComp onClose={() => closeModal(false)} title="Create Purchase Invoice" maxWidth="md">
                    <PurchaseInvoice onClose={closeModal} scheduleJobID={props.scheduleJobID} allApprovedJob={state.allApprovedJob} />
                </DialogComp>
            )}
            {state.open ? (
                <AddLabourModal
                    onClose={closeModal}
                    vehicleID={state.vehicleID}
                    jobItemID={state.jobItemID}
                    Status={jobDetails.jobStatusID}
                    vehicleRegNumber={state.vehicleRegNumber}
                    jobItemJobID={props.scheduleJobID}
                    isCustomer={isCustomer}
                    islabourRateSet={jobDetails.islabourRateSet}
                    labourRate={jobDetails.labourRate}
                />
            ) : (
                <div style={{ marginTop: 8 }}>
                    {isServiceAgent && (
                        <Grid container item xs={12}>
                            <StatusBasedButton
                                refreshScreen={refreshScreen}
                                vehicleID={props.vehicleID}
                                position={props.position}
                                status={jobDetails.jobStatusID}
                                data={jobDetails}
                                jobID={props.scheduleJobID}
                                goPreviousScreen={goPreviousScreen}
                                btnType="chip"
                                otherOptionsBtnShow={props.checkhaveAccess?.CanUpdateJobs}
                            />
                        </Grid>
                    )}
                    {isCustomer && (
                        <Grid container item xs={12}>
                            <CustStatusBasedButton
                                refreshScreen={refreshScreen}
                                vehicleID={props.vehicleID}
                                position={props.position}
                                status={jobDetails.jobStatusID}
                                data={jobDetails}
                                jobID={props.scheduleJobID}
                                goPreviousScreen={goPreviousScreen}
                                btnType="chip"
                                otherOptionsBtnShow={props.checkhaveAccess?.CanUpdateJobs}
                                isShownHeadrer={true}
                                jobOrderNo={jobDetails.jobOrderNo}
                            />
                        </Grid>
                    )}

                    {jobDetails.jobStatusID == 'CA' && isInternal && (
                        <Grid container spacing={1}>
                            <Grid item>
                                <CustomChip
                                    icon={AddIcon}
                                    label="Create Purchase Invoice"
                                    onClick={() => createInvoiceHandler('showInvoice')}
                                    style={{ fontWeight: 'bold' }}
                                />
                            </Grid>
                            <Grid item>
                                <CustomChip
                                    icon={AddIcon}
                                    label="Create Sales Invoice"
                                    onClick={() => createInvoiceHandler('showSellerInvoice')}
                                    style={{ fontWeight: 'bold' }}
                                />
                            </Grid>
                        </Grid>
                    )}
                    <Grid container xs={12} sm={12} md={12} lg={7}>
                        <JobItemHeaderDates jobID={jobDetails.jobID} jobDetails={jobDetails} refreshScreen={refreshScreen} />
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={7}>
                            <DataGrid
                                columns={columns}
                                rows={state.jobItemsList}
                                isRowHovered={true}
                                // enableSearch={true}
                                rowHeight={55}
                                loadingData={state.loader}
                                // btnTitle={jobDetails.jobStatusID == 'CA' ? 'Create Purchase Invoice' : null}
                                // secondBtnTitle={jobDetails.jobStatusID == 'CA' ? 'Create Sales Invoice' : null}
                                // onBtnClick={() => createInvoiceHandler('showInvoice')}
                                // onSecondBtnClick={() => createInvoiceHandler('showSellerInvoice')}
                                height={475}
                                // offset={270}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={5} style={{ paddingRight: '10px' }}>
                            <JobQueriesScreen
                                VehicleID={props.VehicleID}
                                JobID={props.scheduleJobID}
                                fromPage="job"
                                refreshDependancy={jobDetails.refreshDependancy}
                            />
                        </Grid>
                    </Grid>
                    {state.showUpComingEvents ? (
                        <DialogComp title={'Upcoming Events'} onClose={() => closeModal(false)} maxWidth="md" fullWidth>
                            <UpcomingEvents vehicleID={jobDetails.jobVehicleID} fromPage="jobItem" />
                        </DialogComp>
                    ) : null}
                </div>
            )}
        </div>
    );
}
export default JobItemsScreen;
