import React, { useState, useEffect, useMemo } from 'react';
import { getCostTypes } from '../../../../Core/Services/InternalUser/costTypeService';
import DataGrid from '../../../../Core/Controls/Grid';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import CostTypesModal from './CostTypesModal';
import { BreadCrumbs, EditButton, formatters } from '../../../../Core/Controls';
import GijgoGrid from '../../../../Core/Controls/GijgoGrid';
import '../../.././commonStyle.scss';

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Jobs', active: true },
    { name: 'Cost Types', active: true }
];
const base_columns = [
    { key: 'action', name: 'Action', width: '5%' },
    { key: 'costTypeCode', name: 'Code', width: '6%' },
    { key: 'costTypeDescription', name: 'Description' },
    { key: 'costTypeRechargeToCustomer', name: 'Recharge To Customer?', width: '18%' },
    { key: 'costTypePaySupplier', name: 'Pay to Supplier?', width: '18%' },
    { key: 'costTypeActive', name: 'Is Active ?', width: '9%' }
];

function CostTypes(props) {
    const [state, setState] = useState({
        costTypesList: [],
        loader: true,
        open: false,
        data: null
    });

    const pullAllData = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    const submitHandler = (params) => {
        setState((state) => ({ ...state, open: true, data: params }));
    };

    const ActionButtons = (data, row) => {
        return (
            <div style={{ textAlign: 'center' }}>
                <EditButton onClick={() => submitHandler(row)} toolTipTitle="Edit Fuel Types" />
            </div>
        );
    };

    const columns = useMemo(() => {
        let newColumn = [...base_columns];
        let actionCol = newColumn.find((item) => item.key === 'action');
        if (actionCol) {
            actionCol.formatter = ActionButtons;
        }
        return newColumn;
    });

    const title = state.data ? 'Update Cost Type' : 'Add Cost Type';
    const closeModal = (res) => {
        setState((state) => ({ ...state, open: false, data: null }));
        res && pullAllData();
    };

    return (
        <div className="screen">
            <BreadCrumbs crumbs={crumbs} />

            <GijgoGrid
                columns={columns}
                getUrl="CostTypes"
                dataConverter={(res) => ({
                    records: res.data.list,
                    total: res.data.total
                })}
                isReload={state.isReload}
                btnTitle="Add Cost Type"
                onBtnClick={() => submitHandler(null)}
            />
            {state.open ? (
                <DialogComp title={title} onClose={() => closeModal(false)} maxWidth="sm">
                    <CostTypesModal data={state.data} onClose={() => closeModal(true)} />
                </DialogComp>
            ) : null}
        </div>
    );
}
export default CostTypes;
