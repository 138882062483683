import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { SecondaryButton } from '../../../../Core/Controls';
import { MandatoryField } from '../../../../Core/Controls';
import { postMessageToAllCustomers } from '../../../../Core/Services/InternalUser/messageCustomer';
import { InputLabel, Typography } from '@material-ui/core';
import { InputText } from '../../../../Core/Controls';
import { FormHelperText } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const MsgAllCustomersModal = (props) => {
    const [state, setState] = useState({
        errors: {},
        message: '',
    });

    const inputChange = (e) => {
        const { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    const validations = () => {
        const { message } = state;
        let formIsValid = true;
        let errors = {};
        if (!message) {
            errors.message = 'Message is required';
            formIsValid = false;
        }
        setState((st) => ({ ...st, errors: errors }));
        return formIsValid;
    };

    const handleSubmit = async () => {
        if (validations()) {
            let res = await postMessageToAllCustomers({ message: state.message });
            if (res.success) {
                props.onFormSubmit();
            }
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={12} className="mandatory-fields">
                <Alert severity="warning" className="alert-items">
                    Clicking the submit button will send a message to all active customers.
                </Alert>
            </Grid>

            <Grid item xs={12} md={12} className="mandatory-fields">
                <InputLabel shrink required>Message</InputLabel>
                <InputText
                    multiline
                    rows={10}
                    placeholder="Message"
                    name="message"
                    onChange={inputChange}
                    value={state.message}
                />
                <FormHelperText error>{state.errors.message}</FormHelperText>
            </Grid>
            <Grid item xs={12}>
                <SecondaryButton onClick={handleSubmit} fullWidth className="submit_btn">
                    Submit
                </SecondaryButton>
            </Grid>
        </Grid>
    );
};

export default MsgAllCustomersModal;
