import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { SecondaryButton, PrimaryButton } from '../../../../../../Core/Controls/Inputs/Buttons/ButtonModal';
import DialogComp from '../../../../../../Core/Dialog/DialogComp';
import JobEstimateModal from '../../JobStatusBtn/JobEstimate';
import JobQueryModal from '../../JobStatusBtn/JobQuery';
import AcceptEstimateModal from '../../JobStatusBtn/AcceptEstimateModal';
import SubmitWarnngModal from '../../JobStatusBtn/SubmitWarning';
import DeleteJobModal from '../../deleteJobModal';
import { useHistory } from 'react-router-dom';
import { CustomChip } from '../../../../../../Core/Controls';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { ChipWithoutLable } from '../../../../../../Core/Controls/Inputs/DatePicker';
import { AppStorage } from '../../../../../../Core/Basic/storage-service';
import HelpIcon from '@material-ui/icons/Help';
import CheckIcon from '@material-ui/icons/Check';

function InternalstatusBasedButton(props) {
    const [state, setState] = useState({
        showJobQuery: false,
        showEstimate: false,
        showSubmitCostModal: false,
        title: ''
    });
    let checkhaveAccess = AppStorage.getAllowAcces();

    const { status, vehicleID, handleData, data } = props;
    const history = useHistory();

    const handelPopup = async (title, setShow) => {
        if (data.jobOrderTotal != 0 && title == 'Submit Cost') {
            setShow = 'showSubmitCostModal';
        }
        setState((st) => ({
            ...st,
            title: title,
            [setShow]: true
        }));
    };
    const showSubmitModal = (res) => {
        setState((st) => ({
            ...st,
            showSubmitCostModal: true,
            showSubmit: false
        }));
    };
    const closeTheDialog = async (res) => {
        setState((st) => ({
            ...st,
            showJobQuery: false,
            showEstimate: false,
            showSubmitCostModal: false,
            showSubmit: false
        }));
        res && props.refreshScreen(res);
    };

    const closeDeleteDefectModal = (res) => {
        setState((st) => ({
            ...st,
            showJobDeleteModal: false
        }));
        if (res) {
            history.goBack();
        }
    };

    const CostomButton = (btnLable, modalName, icon) => {
        return (
            <>
                {props.btnType == 'chip' ? (
                    <Grid item>
                        <CustomChip
                            icon={icon}
                            label={`${btnLable}`}
                            onClick={() => {
                                handelPopup(`${btnLable}`, `${modalName}`);
                            }}
                            style={{ fontWeight: 'bold' }}
                        />
                    </Grid>
                ) : (
                    <SecondaryButton
                        onClick={() => {
                            handelPopup(`${btnLable}`, `${modalName}`);
                        }}
                    >
                        {`${btnLable}`}
                    </SecondaryButton>
                )}
            </>
        );
    };

    return (
        <Grid container item spacing={0} className="controller-screen">
            <Grid container item xs={12} className="btn-hw">
                {props.isShownHeadrer ? null : (
                    <>
                        {(status == 'AU' ||
                            status == 'AA' ||
                            status == 'JP' ||
                            status == 'ER' ||
                            status == 'AQ' ||
                            status == 'JQ' ||
                            status == 'AR' ||
                            status == 'EA') &&
                        props.showSave ? (
                            <PrimaryButton className="btn-text" onClick={handleData}>
                                Save
                            </PrimaryButton>
                        ) : null}
                        <SecondaryButton onClick={props.goPreviousScreen}>Cancel</SecondaryButton>
                    </>
                )}
                {status == 'JQ' && data.jobInCustomerQuery && props.otherOptionsBtnShow ? CostomButton('Reply Customer', 'showJobQuery', AddIcon) : null}
                {/* {status == 'JQ' && props.otherOptionsBtnShow ? CostomButton('Query Agent', 'showJobQuery', AddIcon) : null} */}
                {/* {(status == 'AA' || status == 'JP') && props.otherOptionsBtnShow ? CostomButton('Request Estimate', 'showEstimate', AddIcon) : null} */}
                {(status == 'EP' || status == 'ER' || status == 'FR' || status == 'JQ') && props.otherOptionsBtnShow
                    ? CostomButton('Query Agent', 'showJobQuery', HelpIcon)
                    : null}
                {(data.jobOrderTotal <= data.jobLimit || checkhaveAccess?.CanUpdateJobLimit) && (status == 'EP' || status == 'ER') && props.otherOptionsBtnShow
                    ? CostomButton('Accept Estimate', 'showEstimate', CheckIcon)
                    : null}
                {(status == 'EP' || status == 'ER') && props.otherOptionsBtnShow ? CostomButton('Send To Customer', 'showEstimate', AddIcon) : null}
                {(status == 'AR' || status == 'AA' || status == 'JP') && props.otherOptionsBtnShow
                    ? CostomButton('Approve Order', 'showEstimate', AddIcon)
                    : null}
                {status == 'FR' && props.otherOptionsBtnShow ? CostomButton('Send for customer approval', 'showEstimate', AddIcon) : null}
                {/* {status != 'CA' && status != 'AR' && status != 'FR' && status != 'JC' && status != 'JD' && props.otherOptionsBtnShow
                    ? CostomButton('Delete Job', 'showJobDeleteModal', DeleteIcon)
                    : null} */}

                {status != 'CA' && status != 'AR' && status != 'FR' && status != 'JC' && status != 'JD' && props.otherOptionsBtnShow ? (
                    <ChipWithoutLable
                        icon={DeleteIcon}
                        style={{ fontWeight: 'bold' }}
                        iconFontSize={16}
                        onClick={() => {
                            handelPopup(`Delete Job`, `showJobDeleteModal`);
                        }}
                        className="btn-m"
                        toolTipTitle="Delete Job"
                    />
                ) : null}
            </Grid>
            {state.showSubmit && (
                <DialogComp title="Are you sure to submit order with zero cost?" maxWidth="sm" onClose={() => closeTheDialog(false)}>
                    <SubmitWarnngModal title={state.title} onYes={showSubmitModal} onClose={closeTheDialog} />
                </DialogComp>
            )}
            {state.showJobQuery && (
                <DialogComp title={state.title} maxWidth="sm" onClose={() => closeTheDialog(false)}>
                    <JobQueryModal title={state.title} jobQueryJobID={props.jobID} onClose={closeTheDialog} />
                </DialogComp>
            )}
            {state.showSubmitCostModal && (
                <DialogComp title={state.title} maxWidth="sm" onClose={() => closeTheDialog(false)}>
                    <AcceptEstimateModal
                        title={state.title}
                        mileageIsRequired={data.mileageRequired}
                        jobOrderTotal={data.jobOrderTotal}
                        jobEstimatedCost={data.jobEstimatedCost}
                        jobOrderDate={data.jobOrderDate}
                        jobAgentOrderNo={data.jobAgentOrderNo}
                        jobQueryJobID={props.jobID}
                        jobRegNumber={data.jobRegNumber}
                        jobMileage={data.jobMileage}
                        jobMileageDate={data.jobMileageDate}
                        onClose={closeTheDialog}
                    />
                </DialogComp>
            )}
            {state.showEstimate && (
                <DialogComp
                    title={state.title == 'Send for customer approval' ? 'Customer Approval' : state.title}
                    maxWidth="sm"
                    onClose={() => closeTheDialog(false)}
                >
                    <JobEstimateModal
                        title={state.title}
                        jobOrderTotal={data.jobOrderTotal}
                        jobEstimatedCost={data.jobEstimatedCost}
                        jobOrderDate={data.jobOrderDate}
                        jobAgentOrderNo={data.jobAgentOrderNo}
                        jobQueryJobID={props.jobID}
                        jobRegNumber={data.jobRegNumber}
                        onClose={closeTheDialog}
                        jobOrderNo={data.jobOrderNo}
                    />
                </DialogComp>
            )}
            {/* //handleSubmitCost */}
            {state.showJobDeleteModal ? (
                <DialogComp open={true} onClose={() => closeDeleteDefectModal(false)} title={'Delete Job'} maxWidth="sm" fullWidth>
                    <DeleteJobModal deleteID={props.jobID} fromPage="Job" onClose={closeDeleteDefectModal} />
                </DialogComp>
            ) : null}
        </Grid>
    );
}

export default InternalstatusBasedButton;
