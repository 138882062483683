import React, { useEffect, useState, useContext } from 'react';
import { EditButton, SimpleRating } from '../../../Core/Controls';
import Typography from '@material-ui/core/Typography';
import { ServiceAgentProvider as StateProvider, ServiceAgentScreenContext } from './ServiceAgentProvider';
import AddEditServiceAgent from './addEditServiceAgent';
import Grid from '@material-ui/core/Grid';
import EditIcon from '@material-ui/icons/Edit';
import { Button } from '@material-ui/core';
import './serviceAgent.scss';
import StickyNotes from '../Controllers/Notes/stickyNotes';
import { CustomIconButton } from '../../../Core/Controls/Inputs/Buttons/ButtonModal';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { CustomChip, formatters } from '../../../Core/Controls';
import FileUploaderButton from '../../../Core/Controls/GenericFileUploader';

export const ServiceAgentDashboard = (props) => {
    const { state, selectedData } = useContext(ServiceAgentScreenContext);

    const [data, setData] = useState({
        show: false,
        serviceAgentID: null
    });

    useEffect(() => {
        if (props.serviceAgentID) {
            setData((st) => ({
                ...st,
                serviceAgentID: props.serviceAgentID
            }));
        }
    }, []);

    const handleAdd = (record) => {
        setData((st) => ({
            ...st,
            show: true,
            serviceAgentID: record
        }));
    };

    const closeVehicleModal = () => {
        setData((st) => ({
            ...st,
            show: !data.show
        }));
    };

    const isNullCheck = (val) => {
        if (val) {
            return val;
        }
        return 'N/A';
    };

    return (
        <div className="controller-screen">
            {data.show ? (
                <AddEditServiceAgent serviceAgentID={data.serviceAgentID} onFormSubmit={closeVehicleModal} />
            ) : (
                <>
                    <StickyNotes frompage="ServiceAgent" NoteServiceAgID={props.serviceAgentID} noteRefsID={props.serviceAgentID} />
                    <Grid container className="mt_10 grid-style">
                        <Grid container className="white-grid" style={{ justifyContent: 'space-between' }}>
                            <Grid item container xs={6} alignItems="center" spacing={1}>
                                <Grid item>
                                    <Typography variant="h5" color="secondary">
                                        Service Agent Details
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <SimpleRating ratingValue={state.serviceAgentRating} name={`tempName${state.serviceAgentID}`} isDisabled={true} />
                                </Grid>
                            </Grid>
                            <Grid item container xs={6} justify="flex-end" alignItems="center">
                                <div className="edit-controller-btn mt_10">
                                    <FileUploaderButton
                                        recordId={props.serviceAgentID}
                                        fileUploaderConfigName="ServiceAgentScreen"
                                        fileCount={props.fileCount}
                                    />
                                    &nbsp;&nbsp;
                                    {!data.show ? (
                                        <CustomChip
                                            icon={EditIcon}
                                            iconFontSize={16}
                                            label="Edit"
                                            onClick={() => handleAdd(data.serviceAgentID)}
                                            className="btn-m"
                                            style={{ fontWeight: 'bold' }}
                                        />
                                    ) : null}
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container className="gray-grid">
                            <Grid item xs={6} sm={4}>
                                <div className="controller-label">
                                    <Typography variant="caption">Name</Typography>
                                </div>
                                <Typography variant="button">{isNullCheck(state.serviceAgentName)}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <div className="controller-label">
                                    <Typography variant="caption">Address </Typography>
                                </div>
                                <Typography variant="button">
                                    {`
                                     ${state.serviceAgentAddress1 ? state.serviceAgentAddress1 : ''}
                                    ${state.serviceAgentAddress2 ? state.serviceAgentAddress2 : ''}
                                    ${state.serviceAgentCity ? state.serviceAgentCity : ''}
                                    ${state.serviceAgentPostCode ? state.serviceAgentPostCode : ''}`}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sm={4}>
                                <div className="controller-label">
                                    <Typography variant="caption">Labour Rate</Typography>
                                </div>
                                <Typography variant="button">{isNullCheck(state.serviceAgentLabourRate)}</Typography>
                                {/* <Typography variant="button">{state.serviceAgentLabourRate}</Typography> */}
                            </Grid>
                        </Grid>
                        <Grid container className="white-grid">
                            <Grid item xs={6} sm={4}>
                                <div className="controller-label">
                                    <Typography variant="caption">Out Of Hour Labour Rate</Typography>
                                </div>
                                <Typography variant="button">{isNullCheck(state.serviceAgentOutOfHoursLabourRate)}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <div className="controller-label">
                                    <Typography variant="caption">Phone</Typography>
                                </div>
                                <Typography variant="button">{formatters.phoneNumberFormatter(state.serviceAgentPhone)}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}></Grid>
                        </Grid>
                        <Grid container className="gray-grid">
                            <Grid item xs={6} sm={4}>
                                <div className="controller-label">
                                    <Typography variant="caption">E-mail</Typography>
                                </div>
                                <Typography variant="button">{formatters.mailFormatter(state.serviceAgentEmail)}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <div className="controller-label">
                                    <Typography variant="caption">Service Manager Name</Typography>
                                </div>
                                <Typography variant="button">{isNullCheck(state.serviceAgentServiceManagerName)}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <div className="controller-label">
                                    <Typography variant="caption">Service Manager Phone</Typography>
                                </div>
                                <Typography variant="button">{formatters.phoneNumberFormatter(state.serviceAgentServiceManagerPhone)}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container className="white-grid">
                            <Grid item xs={12} sm={4}>
                                <div className="controller-label">
                                    <Typography variant="caption">Service Manager Email</Typography>
                                </div>
                                <Typography variant="button">{formatters.mailFormatter(state.serviceAgentServiceManagerEmail)}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <div className="controller-label">
                                    <Typography variant="caption">Order Confirmation</Typography>
                                </div>
                                <Typography variant="button">{isNullCheck(state.serviceAgentOrderConfirmationName)}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <div className="controller-label">
                                    <Typography variant="caption">Order Confirmation Phone</Typography>
                                </div>
                                <Typography variant="button">{formatters.phoneNumberFormatter(state.serviceAgentOrderConfirmationPhone)}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container className="gray-grid">
                            <Grid item xs={12} sm={4}>
                                <div className="controller-label">
                                    <Typography variant="caption">Order Confirmation Email</Typography>
                                </div>
                                <Typography variant="button">{formatters.mailFormatter(state.serviceAgentOrderConfirmationEmail)}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <div className="controller-label">
                                    <Typography variant="caption">Service Agent Facilities</Typography>
                                </div>
                                <Typography variant="button">{selectedData.modules.map((p) => `${p.name}`).join(', ')}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <br />

                    <Grid item xs={12} sm={12} className="grid-style">
                        <Grid item xs={12} sm={12}>
                            <Typography variant="h5" color="secondary" style={{ paddingTop: '5px' }}>
                                Opening Times
                            </Typography>
                            <hr />
                        </Grid>
                        <Grid container item spacing={1} xs={12} className="gray-grid">
                            <Grid item xs={3} sm={2}></Grid>
                            <Grid item xs={3} sm={2}>
                                <div className="controller-label">
                                    <Typography variant="caption">Open</Typography>
                                </div>
                            </Grid>
                            <Grid container item xs={3} sm={4} justify="space-between">
                                <div className="controller-label">
                                    <Typography variant="caption">Opening Time</Typography>
                                </div>
                            </Grid>
                            <Grid container item xs={3} sm={4} justify="space-between">
                                <div className="controller-label">
                                    <Typography variant="caption">Closing Time</Typography>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container item spacing={1} xs={12} className="white-grid">
                            <Grid item xs={3} sm={2}>
                                Monday
                            </Grid>
                            <Grid item xs={3} sm={2}>
                                <Typography variant="body2">
                                    {state.serviceAgentOpenMonday ? <CheckIcon style={{ color: 'green' }} /> : <ClearIcon color="secondary" />}
                                </Typography>
                            </Grid>
                            <Grid item xs={3} sm={4}>
                                <Typography variant="body2"> {state.serviceAgentMondayOpeningTime} </Typography>
                            </Grid>
                            <Grid item xs={3} sm={4}>
                                <Typography variant="body2">{state.serviceAgentMondayClosingTime} </Typography>
                            </Grid>
                        </Grid>
                        <Grid container item spacing={1} xs={12} className="gray-grid">
                            <Grid item xs={3} sm={2}>
                                Tuesday
                            </Grid>
                            <Grid item xs={3} sm={2}>
                                <Typography variant="body2">
                                    {state.serviceAgentOpenTuesday ? <CheckIcon style={{ color: 'green' }} /> : <ClearIcon color="secondary" />}
                                </Typography>
                            </Grid>
                            <Grid item xs={3} sm={4}>
                                <Typography variant="body2"> {state.serviceAgentTuesdayOpeningTime} </Typography>
                            </Grid>
                            <Grid item xs={3} sm={4}>
                                <Typography variant="body2">{state.serviceAgentTuesdayClosingTime} </Typography>
                            </Grid>
                        </Grid>
                        <Grid container item spacing={1} xs={12} className="white-grid">
                            <Grid item xs={3} sm={2}>
                                Wednesday
                            </Grid>
                            <Grid item xs={3} sm={2}>
                                <Typography variant="body2">
                                    {state.serviceAgentOpenWednesday ? <CheckIcon style={{ color: 'green' }} /> : <ClearIcon color="secondary" />}
                                </Typography>
                            </Grid>
                            <Grid item xs={3} sm={4}>
                                <Typography variant="body2"> {state.serviceAgentWednesdayOpeningTime} </Typography>
                            </Grid>
                            <Grid item xs={3} sm={4}>
                                <Typography variant="body2">{state.serviceAgentWednesdayClosingTime} </Typography>
                            </Grid>
                        </Grid>
                        <Grid container item spacing={1} xs={12} className="gray-grid">
                            <Grid item xs={3} sm={2}>
                                Thursday
                            </Grid>
                            <Grid item xs={3} sm={2}>
                                <Typography variant="body2">
                                    {state.serviceAgentOpenThursday ? <CheckIcon style={{ color: 'green' }} /> : <ClearIcon color="secondary" />}
                                </Typography>
                            </Grid>
                            <Grid item xs={3} sm={4}>
                                <Typography variant="body2"> {state.serviceAgentThursdayOpeningTime} </Typography>
                            </Grid>
                            <Grid item xs={3} sm={4}>
                                <Typography variant="body2">{state.serviceAgentThursdayClosingTime} </Typography>
                            </Grid>
                        </Grid>
                        <Grid container item spacing={1} xs={12} className="white-grid">
                            <Grid item xs={3} sm={2}>
                                Friday
                            </Grid>
                            <Grid item xs={3} sm={2}>
                                <Typography variant="body2">
                                    {state.serviceAgentOpenFriday ? <CheckIcon style={{ color: 'green' }} /> : <ClearIcon color="secondary" />}
                                </Typography>
                            </Grid>
                            <Grid item xs={3} sm={4}>
                                <Typography variant="body2"> {state.serviceAgentFridayOpeningTime} </Typography>
                            </Grid>
                            <Grid item xs={3} sm={4}>
                                <Typography variant="body2">{state.serviceAgentFridayClosingTime} </Typography>
                            </Grid>
                        </Grid>
                        <Grid container item spacing={1} xs={12} className="gray-grid">
                            <Grid item xs={3} sm={2}>
                                Saturday
                            </Grid>
                            <Grid item xs={3} sm={2}>
                                <Typography variant="body2">
                                    {state.serviceAgentOpenSaturday ? <CheckIcon style={{ color: 'green' }} /> : <ClearIcon color="secondary" />}
                                </Typography>
                            </Grid>
                            <Grid item xs={3} sm={4}>
                                <Typography variant="body2"> {state.serviceAgentSaturdayOpeningTime} </Typography>
                            </Grid>
                            <Grid item xs={3} sm={4}>
                                <Typography variant="body2">{state.serviceAgentSaturdayClosingTime} </Typography>
                            </Grid>
                        </Grid>
                        <Grid container item spacing={1} xs={12} className="white-grid">
                            <Grid item xs={3} sm={2}>
                                Sunday
                            </Grid>
                            <Grid item xs={3} sm={2}>
                                <Typography variant="body2">
                                    {state.serviceAgentOpenSunday ? <CheckIcon style={{ color: 'green' }} /> : <ClearIcon color="secondary" />}
                                </Typography>
                            </Grid>
                            <Grid item xs={3} sm={4}>
                                <Typography variant="body2"> {state.serviceAgentSundayOpeningTime} </Typography>
                            </Grid>
                            <Grid item xs={3} sm={4}>
                                <Typography variant="body2">{state.serviceAgentSundayClosingTime} </Typography>
                            </Grid>
                        </Grid>
                        <Grid container item spacing={1} xs={12} className="gray-grid">
                            <Grid item xs={3} sm={2}>
                                Bank Holiday
                            </Grid>
                            <Grid item xs={3} sm={2}>
                                <Typography variant="body2">
                                    {state.serviceAgentOpenBankHoliday ? <CheckIcon style={{ color: 'green' }} /> : <ClearIcon color="secondary" />}
                                </Typography>
                            </Grid>
                            <Grid item xs={3} sm={4}>
                                <Typography variant="body2"> {state.serviceAgentBankHolidayOpeningTime} </Typography>
                            </Grid>
                            <Grid item xs={3} sm={4}>
                                <Typography variant="body2">{state.serviceAgentBankHolidayClosingTime} </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            )}
        </div>
    );
};

const Screen = (props) => {
    return (
        <StateProvider {...props}>
            <ServiceAgentDashboard {...props} />
        </StateProvider>
    );
};
export default Screen;
