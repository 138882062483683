import React, { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import { InputLabel, Typography, FormHelperText } from '@material-ui/core';
import { ContractProvider as StateProvider, ContractContext } from './contractProvider';
import { SecondaryButton, SimpleField, SelectBox, DatePicker } from '../../../../../Core/Controls';
import moment from 'moment';
import { FromLoader } from '../../../../../Core/Loader/formLoader';
import { AppStorage } from '../../../../../Core/Basic/storage-service';

const ContractField = (props) => {
    const { state, YesNoRadio, dropdown, inputChange, submitData } = useContext(ContractContext);
    let checkhaveAccess = AppStorage.getAllowAcces();
    return (
        <>
            {state.showLoader ? (
                <FromLoader />
            ) : (
                <div>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6} md={4} lg={2}>
                            <InputLabel shrink error>
                                Start date
                            </InputLabel>
                            <DatePicker
                                value={state.contractStartDate && moment(state.contractStartDate).format('YYYY-MM-DD')}
                                onChange={inputChange}
                                name="contractStartDate"
                            />
                            <FormHelperText error>{state.errors.contractStartDate}</FormHelperText>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={2}>
                            <InputLabel shrink>Contract Term (Months)</InputLabel>
                            <SimpleField onChange={inputChange} name="contractTerm" value={state.contractTerm} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={2}>
                            <InputLabel shrink>End date</InputLabel>
                            <DatePicker
                                value={state.contractEndDate && moment(state.contractEndDate).format('YYYY-MM-DD')}
                                onChange={inputChange}
                                name="contractEndDate"
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={2}>
                            <InputLabel shrink> Extended Date</InputLabel>
                            <DatePicker
                                value={state.contractExtendedDate && moment(state.contractExtendedDate).format('YYYY-MM-DD')}
                                onChange={inputChange}
                                name="contractExtendedDate"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={2}>
                            <InputLabel shrink>Contract mileage</InputLabel>
                            <SimpleField onChange={inputChange} name="contractMileage" value={state.contractMileage} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={2}>
                            <InputLabel shrink> Access Mileage (p/mile)</InputLabel>
                            <SimpleField onChange={inputChange} name="contractExcessMileage" value={state.contractExcessMileage} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={2}>
                            <InputLabel shrink> Purchase Date</InputLabel>
                            <DatePicker
                                value={state.contractPurchaseDate && moment(state.contractPurchaseDate).format('YYYY-MM-DD')}
                                onChange={inputChange}
                                name="contractPurchaseDate"
                            />
                        </Grid>{' '}
                        <Grid item xs={12} sm={6} md={4} lg={2}>
                            <InputLabel shrink>Purchase Price</InputLabel>
                            <SimpleField onChange={inputChange} name="contractPurchasePrice" value={state.contractPurchasePrice} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={2}>
                            <InputLabel shrink>Contract type</InputLabel>
                            <SelectBox onChange={inputChange} List={dropdown.contractTypes} name="contractTypeID" value={state.contractTypeID} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={2}>
                            <InputLabel shrink>Duration Type</InputLabel>
                            <SelectBox
                                onChange={inputChange}
                                List={dropdown.contractDurationTypes}
                                name="contractDurationTypeID"
                                value={state.contractDurationTypeID}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={2}>
                            <InputLabel shrink>Contract Status</InputLabel>
                            <SelectBox onChange={inputChange} List={dropdown.contractStatus} name="contractStatusID" value={state.contractStatusID} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={2}>
                            <InputLabel shrink>Nominal Code</InputLabel>
                            <SimpleField onChange={inputChange} name="contractNominalCode" value={state.contractNominalCode} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={2}>
                            <InputLabel shrink>P11D Value</InputLabel>
                            <SimpleField onChange={inputChange} name="contractP11DValue" value={state.contractP11DValue} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={2}>
                            <InputLabel shrink>Initial mileage</InputLabel>
                            <SimpleField onChange={inputChange} name="contractInitialMileage" value={state.contractInitialMileage} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={2}>
                            <InputLabel shrink>Anticipated annual mileage</InputLabel>
                            <SimpleField onChange={inputChange} name="contractAnticipatedAnnualMileage" value={state.contractAnticipatedAnnualMileage} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={2}>
                            <InputLabel shrink>Anticipated renewal date</InputLabel>
                            <DatePicker
                                value={state.contractAnticipatedRenewalDate && moment(state.contractAnticipatedRenewalDate).format('YYYY-MM-DD')}
                                onChange={inputChange}
                                name="contractAnticipatedRenewalDate"
                            />
                        </Grid>
                    </Grid>
                    <Typography variant="h5" color="secondary">
                        Contract Funding
                    </Typography>
                    <hr />
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6} md={4} lg={2}>
                            <InputLabel shrink>Agreement type</InputLabel>
                            <SelectBox
                                onChange={inputChange}
                                List={dropdown.agreementTypes}
                                name="contractAgreementTypeID"
                                value={+state.contractAgreementTypeID}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={2}>
                            <InputLabel shrink>Agreement ref number</InputLabel>
                            <SimpleField onChange={inputChange} name="contractReference" value={state.contractReference} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={2}>
                            <InputLabel shrink>Funding company name</InputLabel>
                            <SimpleField onChange={inputChange} name="contractFundingCompanyName" value={state.contractFundingCompanyName} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={2}>
                            <InputLabel shrink>Funding company telephone</InputLabel>
                            <SimpleField onChange={inputChange} name="contractFundingCompanyTelephone" value={state.contractFundingCompanyTelephone} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={2}>
                            <InputLabel shrink>Initial payment</InputLabel>
                            <SimpleField onChange={inputChange} name="contractInitialPayment" value={state.contractInitialPayment} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={2}>
                            <InputLabel shrink>Monthly payment</InputLabel>
                            <SimpleField onChange={inputChange} name="contractMonthlyPayment" value={state.contractMonthlyPayment} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={2}>
                            <InputLabel shrink>Final payment</InputLabel>
                            <SimpleField onChange={inputChange} name="contractFinalPayment" value={state.contractFinalPayment} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={2}>
                            <InputLabel shrink error>
                                Contract resell value
                            </InputLabel>
                            <SimpleField onChange={inputChange} name="contractReSellValue" value={state.contractReSellValue} />
                            <FormHelperText error>{state.errors.contractReSellValue}</FormHelperText>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={2}>
                            <InputLabel shrink>Broker</InputLabel>
                            <SimpleField onChange={inputChange} name="contractBroker" value={state.contractBroker} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={2}>
                            <InputLabel shrink>Total cost of funding </InputLabel>
                            <SimpleField onChange={inputChange} name="contractTotalCostOfFunding" value={state.contractTotalCostOfFunding} />
                        </Grid>
                    </Grid>
                    <Typography variant="h5" color="secondary">
                        Contract Costing
                    </Typography>
                    <hr />
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6} md={4} lg={2}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={12}>
                                    <InputLabel shrink>Inclusive of Maintenance?</InputLabel>
                                    <YesNoRadio
                                        name="contractInclusiveofMaintenance"
                                        value={state.contractInclusiveofMaintenance || false}
                                        onChange={inputChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <InputLabel shrink error>
                                        {' '}
                                        Maintenance cost
                                    </InputLabel>
                                    <SimpleField onChange={inputChange} name="contractMaintenanceCost" value={state.contractMaintenanceCost} />
                                    <FormHelperText error>{state.errors.contractMaintenanceCost}</FormHelperText>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={2}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={12}>
                                    <InputLabel shrink>Inclusive of R&M?</InputLabel>
                                    <YesNoRadio name="contractRAndMIncluded" value={state.contractRAndMIncluded || false} onChange={inputChange} />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <InputLabel shrink error>
                                        {' '}
                                        R&M cost
                                    </InputLabel>
                                    <SimpleField onChange={inputChange} name="contractRAndMCost" value={state.contractRAndMCost} />
                                    <FormHelperText error>{state.errors.contractRAndMCost}</FormHelperText>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={2}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={12}>
                                    <InputLabel shrink>Inclusive of Substitude?</InputLabel>
                                    <YesNoRadio name="contractSubstituteIncluded" value={state.contractSubstituteIncluded || false} onChange={inputChange} />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <InputLabel shrink error>
                                        {' '}
                                        Substitude cost
                                    </InputLabel>
                                    <SimpleField onChange={inputChange} name="contractSubstituteCost" value={state.contractSubstituteCost} />
                                    <FormHelperText error>{state.errors.contractSubstituteCost}</FormHelperText>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={2}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={12}>
                                    <InputLabel shrink>Inclusive of Breakdown?</InputLabel>
                                    <YesNoRadio name="contractBreakdownIncluded" value={state.contractBreakdownIncluded || false} onChange={inputChange} />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <InputLabel shrink error>
                                        Breakdown cost
                                    </InputLabel>
                                    <SimpleField onChange={inputChange} name="contractBreakdownCost" value={state.contractBreakdownCost} />
                                    <FormHelperText error>{state.errors.contractBreakdownCost}</FormHelperText>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={2}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={12}>
                                    <InputLabel shrink>Inclusive of Inspections?</InputLabel>
                                    <YesNoRadio name="contractInspectionsIncluded" value={state.contractInspectionsIncluded || false} onChange={inputChange} />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <InputLabel shrink error>
                                        Inspections cost
                                    </InputLabel>
                                    <SimpleField onChange={inputChange} name="contractInspectionsCost" value={state.contractInspectionsCost} />
                                    <FormHelperText error>{state.errors.contractInspectionsCost}</FormHelperText>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={2}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={12}>
                                    <InputLabel shrink>Inclusive of Tyre?</InputLabel>
                                    <YesNoRadio name="contractTyresIncluded" value={state.contractTyresIncluded || false} onChange={inputChange} />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <InputLabel shrink error>
                                        Tyre Cost
                                    </InputLabel>
                                    <SimpleField onChange={inputChange} name="contractTyresCost" value={state.contractTyresCost} />
                                    <FormHelperText error>{state.errors.contractTyresCost}</FormHelperText>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={2}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={12}>
                                    <InputLabel shrink>Inclusive of Tachograph?</InputLabel>
                                    <YesNoRadio name="contractTachographIncluded" value={state.contractTachographIncluded || false} onChange={inputChange} />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <InputLabel shrink error>
                                        Tachograph cost
                                    </InputLabel>
                                    <SimpleField onChange={inputChange} name="contractTachographCost" value={state.contractTachographCost} />
                                    <FormHelperText error>{state.errors.contractTachographCost}</FormHelperText>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={2}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={12}>
                                    <InputLabel shrink>Inclusive of RFL?</InputLabel>
                                    <YesNoRadio name="contractRFLIncluded" value={state.contractRFLIncluded || false} onChange={inputChange} />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <InputLabel shrink error>
                                        RFL cost
                                    </InputLabel>
                                    <SimpleField onChange={inputChange} name="contractRFLCost" value={state.contractRFLCost} />
                                    <FormHelperText error>{state.errors.contractRFLCost}</FormHelperText>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={2}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={12}>
                                    <InputLabel shrink>Inclusive of AncEquipment?</InputLabel>
                                    <YesNoRadio
                                        name="contractAncEquipmentIncluded"
                                        value={state.contractAncEquipmentIncluded || false}
                                        onChange={inputChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <InputLabel shrink error>
                                        AncEquipment cost
                                    </InputLabel>
                                    <SimpleField onChange={inputChange} name="contractAncEquipmentCost" value={state.contractAncEquipmentCost} />
                                    <FormHelperText error>{state.errors.contractAncEquipmentCost}</FormHelperText>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={2}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={12} style={{ visibility: 'hidden' }}>
                                    {' '}
                                    <InputLabel>{'\u{2800}'}</InputLabel>
                                    <YesNoRadio />
                                    {/* <||>{'\u{2800}'}</|| falseInputLabel>
                            <InputLabel>{'\u{2800}'}</InputLabel> */}
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <InputLabel shrink error>
                                        Admin cost
                                    </InputLabel>
                                    <SimpleField onChange={inputChange} name="contractAdminCost" value={state.contractAdminCost} />
                                    <FormHelperText error>{state.errors.contractAdminCost}</FormHelperText>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container spacing={1}>
                        <Grid item xs={12} md={12} lg={8}>
                            <Typography variant="h5" color="secondary">
                                Maintenance Contract
                            </Typography>
                            <hr />
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={6} md={4}>
                                    <InputLabel shrink>R&M Start Date</InputLabel>
                                    <DatePicker
                                        value={state.contractRAndMStartDate && moment(state.contractRAndMStartDate).format('YYYY-MM-DD')}
                                        onChange={inputChange}
                                        name="contractRAndMStartDate"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <InputLabel shrink>R&M End Date</InputLabel>
                                    <DatePicker
                                        value={state.contractRAndMEndDate && moment(state.contractRAndMEndDate).format('YYYY-MM-DD')}
                                        onChange={inputChange}
                                        name="contractRAndMEndDate"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <InputLabel shrink>Maintainance provider</InputLabel>
                                    <SelectBox
                                        onChange={inputChange}
                                        List={dropdown.maintenanceProviders}
                                        name="contractMaintenanceProviderId"
                                        value={state.contractMaintenanceProviderId}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={12} lg={4}>
                            <Typography variant="h5" color="secondary">
                                Contract Tyre
                            </Typography>
                            <hr />
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={12}>
                                    <InputLabel shrink>Tyre supplier</InputLabel>
                                    <SelectBox
                                        onChange={inputChange}
                                        List={dropdown.serviceAgents}
                                        name="contractTyreSupplier"
                                        value={state.contractTyreSupplier}
                                    />
                                    {/* <SimpleField onChange={inputChange} name="contractTyreSupplier" value={state.contractTyreSupplier} /> */}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <br />
                    <Grid container spacing={1}>
                        <Grid item xs={props.vehicleContractID ? 12 : 6} className="submit_btn">
                            <SecondaryButton onClick={submitData} disabled={!checkhaveAccess?.CanUpdateVehicleContracts}>
                                {props.vehicleContractID ? 'Update' : 'Submit'}
                            </SecondaryButton>
                        </Grid>
                        <Grid item xs={6} className="submit_btn" style={{ display: props.vehicleContractID ? 'none' : 'block' }}>
                            <SecondaryButton onClick={props.onClose} forceEnabled={true}>
                                Cancel
                            </SecondaryButton>
                        </Grid>
                    </Grid>
                </div>
            )}
        </>
    );
};
const Screen = (props) => {
    return (
        <StateProvider {...props}>
            <ContractField {...props} />
        </StateProvider>
    );
};

export default Screen;
