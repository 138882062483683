import React, { useState, useEffect, useMemo } from 'react';
import { formatters, BreadCrumbs, DataGrid } from '../../Core/Controls';
import '../commonStyle.scss';
import '../InternalUser/Controllers/Vehicles/ScheduleVehicle/scheduleVehicleStyle.scss';
import { getScheduleByStatus } from '../../Core/Services/InternalUser/ControllerService/scheduleVehicleService';
import { CustomIconButton } from '../../Core/Controls/Inputs/Buttons/ButtonModal';
import BuildIcon from '@material-ui/icons/Build';
import EditIcon from '@material-ui/icons/Edit';
import VehicleDailyChecks from '../InternalUser/Controllers/Vehicles/VehicleDailyChecks/vehicleDailyChecks';
import EditVehicleDailyChecks from '../InternalUser/Controllers/Vehicles/VehicleDailyChecks/editVehicleDailyCheck';
import { AppStorage } from '../../Core/Basic/storage-service';

const basecolumns = [
    { key: 'action', name: 'Action', width: 110, addFileUploaderIcon: true },
    { key: 'vehicleRegNumber', name: 'Reg Number', width: 120 },
    {
        key: 'scheduleDateDue',
        name: 'Due',
        formatter: formatters.Date_DDMMYYYY,
        width: 100
    },
    {
        key: 'scheduleRequestedBookingDate',
        name: 'Booked',
        formatter: formatters.Date_DDMMYYYY,
        width: 120
    },

    { key: 'dueDateISOWeek', name: 'ISO Week', width: 100 },
    { key: 'scheduleEventName', name: 'Event' },
    {
        key: 'agentName',
        name: 'Agent'
    },
    { key: 'scheduleBookingStatus', name: 'Status', width: 200 }
];
const TechnicianSchedule = (props) => {
    const [state, setState] = useState({
        open: false,
        showLoader: true,
        selectedRowData: {},
        isAdd: true,
        showChecks: false,
        showEditChecks: false
    });
    let checkhaveAccess = AppStorage.getAllowAcces();

    const getCrumbs = () => {
        return [
            { name: 'Home', path: '/' },
            { name: 'Confirmed Bookings', active: true }
        ];
    };
    useEffect(async () => {
        pullApiData();
    }, []);

    const handleCheck = (ev, val) => {
        setState((st) => {
            let newSt = { ...st };
            newSt.selectedRowData = ev.row;
            if (val === 'Ad') {
                newSt.showChecks = true;
            }
            if (val === 'Ed') {
                newSt.showEditChecks = true;
            }
            return newSt;
        });
    };

    const pullApiData = async () => {
        let res = await getScheduleByStatus(props.status);

        setState((st) => ({
            ...st,
            rows: [...(res.data.schedules || [])],
            showLoader: false
        }));
    };
    const renderEdit = (ev, param) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {ev.row?.vehicleInspectionID ? null : ev.row?.displayCheck ? (
                    <CustomIconButton
                        icon={BuildIcon}
                        color="primary"
                        onClick={() => {
                            handleCheck(ev, 'Ad');
                        }}
                        toolTipTitle="Add Inspection Check"
                    />
                ) : null}
                {ev.row?.vehicleInspectionID ? (
                    <CustomIconButton
                        icon={BuildIcon}
                        color="secondary"
                        onClick={() => {
                            handleCheck(ev, 'Ed');
                        }}
                        toolTipTitle="Edit Inspection Check"
                    />
                ) : null}
            </div>
        );
    };
    const columns = useMemo(() => {
        const tempCols = [...basecolumns];
        let actionCol = tempCols.find((e) => e.key === 'action');
        if (actionCol) {
            actionCol.formatter = renderEdit;
        }
        return tempCols;
    }, []);

    const handleClose = (res) => {
        setState((st) => ({
            ...st,
            open: false,
            showChecks: false,
            showEditChecks: false,
            selectedRowData: {}
        }));
        res && pullApiData();
    };

    return (
        <div className="screen">
            <BreadCrumbs crumbs={getCrumbs()} />
            {state.showChecks ? (
                <VehicleDailyChecks
                    sheduleVechicleID={state.selectedRowData.scheduleVehicleID}
                    sheduleID={state.selectedRowData.scheduleID}
                    onClose={handleClose}
                    frompage={true}
                />
            ) : state.showEditChecks ? (
                <EditVehicleDailyChecks checkID={state.selectedRowData.vehicleInspectionID} onClose={handleClose} />
            ) : (
                <DataGrid
                    rows={state.rows}
                    columns={columns}
                    enableSearch={true}
                    loadingData={state.showLoader}
                    idFieldName={`${checkhaveAccess?.CanUploadScheduleDocuments ? 'scheduleID' : null}`}
                    fileUploaderConfigName={`${checkhaveAccess?.CanUploadScheduleDocuments ? 'ScheduleDocumentsRequired' : null}`}
                />
            )}
        </div>
    );
};

export default TechnicianSchedule;
