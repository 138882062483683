import { FormHelperText, Grid, InputLabel, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { DatePicker, InputText, NoButton, SecondaryButton, SelectBox, YesButton } from '../../../../../Core/Controls';
import { useToast } from '../../../../../Core/Hooks/useToast';
import '../../../../commonStyle.scss';
import './scheduleVehicleStyle.scss';
import {
    getScheduleBookingSectionById,
    getAllDropDowns,
    postScheduleBookingSection,
    getScheduleRequestBookingById
} from '../../../../../Core/Services/InternalUser/ControllerService/scheduleVehicleService';

let domainName = window.location.host;
let trimedhost = domainName.includes('localhost') ? 'localhost' : domainName;
const ScheduleBookingSection = (props) => {
    const [state, setState] = useState({
        scheduleBookingDate: '',
        scheduleDateDue: '',
        scheduleEventName: '',
        scheduleID: null,
        schedulePrepDate: '',
        scheduleServiceAgentID: '',
        dropList: []
    });
    const [requestBookingData, setRequestBookingData] = useState({
        scheduleRequestedBookingDate: 'N/A',
        scheduleRequestedBookingTimeSlot: 'N/A',
        scheduleNightBooking: 'N/A',
        scheduleCollectionAndDeliverID: null,
        scheduleCollectionAndDeliver: 'N/A',
        scheduleDeliverBefore: 'N/A',
        scheduleCollectAfter: 'N/A'
    });
    const [errors, setErrors] = useState({});
    const { showToastSuccess, showToastError } = useToast();

    const formatTime = (value) => {
        let formattedTime = value ? moment(value, 'HH:mm:ss').format('hh:mm A') : 'N/A';
        return formattedTime;
    };

    const pullRequestBookingData = async () => {
        let res = await getScheduleRequestBookingById(props.scheduleId);
        let bookingData = res.data;
        setRequestBookingData((st) => ({
            ...st,
            scheduleCollectAfter: formatTime(bookingData.scheduleCollectAfter),
            scheduleCollectionAndDeliverID: bookingData.scheduleCollectionAndDeliverID || null,
            scheduleDeliverBefore: formatTime(bookingData.scheduleDeliverBefore),
            scheduleNightBooking: bookingData.scheduleNightBooking ? 'Yes' : bookingData.scheduleNightBooking === null ? 'N/A' : 'No',
            scheduleRequestedBookingDate: bookingData.scheduleRequestedBookingDate
                ? moment(bookingData.scheduleRequestedBookingDate).format('DD/MM/YYYY')
                : 'N/A',
            scheduleRequestedBookingTimeSlot: formatTime(bookingData.scheduleRequestedBookingTimeSlot)
        }));
        setState((st) => ({
            ...st,
            scheduleBookingDate: bookingData.scheduleRequestedBookingDate || ''
        }));
    };

    const pullBookingData = async () => {
        let DataList = await getAllDropDowns();
        setState((st) => ({
            ...st,
            dropList: DataList.data
        }));
        let res = await getScheduleBookingSectionById(props.scheduleId);
        let bookingData = res.data;
        setState((st) => ({
            ...st,
            vehicleID: bookingData.vehicleID || null,
            // scheduleBookingDate: bookingData.scheduleBookingDate || bookingData.scheduleRequestedBookingDate || '',
            scheduleDateDue: moment(bookingData.scheduleDateDue).format('DD/MM/YYYY') || '',
            scheduleEventName: bookingData.scheduleEventName,
            scheduleID: bookingData.scheduleID,
            schedulePrepDate: bookingData.schedulePrepDate || '',
            scheduleServiceAgentID: bookingData.scheduleServiceAgentID || ''
        }));
    };
    useEffect(() => {
        pullBookingData();
        pullRequestBookingData();
    }, []);
    useEffect(() => {
        let collName = state.dropList.collectionAndDelivery?.filter((x) => x.id == requestBookingData.scheduleCollectionAndDeliverID).map((z) => z.name)[0];
        setRequestBookingData((st) => ({
            ...st,
            scheduleCollectionAndDeliver: collName || 'N/A'
        }));
    }, [state.dropList, requestBookingData.scheduleCollectionAndDeliverID]);
    const validate = () => {
        let noErrors = true;
        let err = {};
        // if (!/[^\s]/.test(state.schedulePrepDate)) {
        //     err.schedulePrepDate = true;
        //     noErrors = false;
        // }
        if (!/[^\s]/.test(state.scheduleServiceAgentID)) {
            err.scheduleServiceAgentID = true;
            noErrors = false;
        }
        if (!/[^\s]/.test(state.scheduleBookingDate)) {
            err.scheduleBookingDate = true;
            noErrors = false;
        }
        setErrors(err);
        return noErrors;
    };
    const checkNull = (param) => {
        if (/[^\s]/.test(param)) {
            return param;
        } else {
            return null;
        }
    };
    const handleData = async (ev) => {
        if (validate()) {
            let response = await postScheduleBookingSection({
                vehicleID: props.vehicleId,
                scheduleID: state.scheduleID,
                scheduleBookingDate: checkNull(state.scheduleBookingDate),
                scheduleServiceAgentsID: state.scheduleServiceAgentID,
                domainName: trimedhost,
                schedulePrepDate: checkNull(state.schedulePrepDate)
            });
            if (response) {
                response.success ? showToastSuccess('Job Booked Successfully') : showToastError(response.message || 'Something went wrong');
            } else {
                showToastError('Something went wrong');
            }
            props.onClose(true);
        }
    };
    const valueChanged = (ev) => {
        let temp = ev.target;
        setState((st) => ({
            ...st,
            [temp.name]: temp.value
        }));
        setErrors({});
    };
    const yesNoChanged = (ev) => {
        if (ev.target.innerText == 'YES') {
            setState((st) => ({
                ...st,
                scheduleNightBooking: true
            }));
        } else {
            setState((st) => ({
                ...st,
                scheduleNightBooking: false
            }));
        }
    };
    return (
        <div>
            <Grid container spacing={2}>
                <Grid xs={6} sm={4} item className="typo">
                    <FormHelperText>Event</FormHelperText>
                    <Typography>{state.scheduleEventName}</Typography>
                </Grid>
                <Grid xs={6} sm={4} item className="typo">
                    <FormHelperText>Date Due</FormHelperText>
                    <Typography>{state.scheduleDateDue || 'No Date Assigned'}</Typography>
                </Grid>
                <Grid item container xs={12} style={{ padding: 0 }}>
                    <Grid xs={6} sm={4} item className="typo">
                        <FormHelperText>Requested Booking Date</FormHelperText>
                        <Typography>{requestBookingData.scheduleRequestedBookingDate}</Typography>
                    </Grid>
                    <Grid xs={6} sm={4} item className="typo">
                        <FormHelperText>Requested Booking Time Slot</FormHelperText>
                        <Typography>{requestBookingData.scheduleRequestedBookingTimeSlot}</Typography>
                    </Grid>
                    <Grid xs={6} sm={4} item className="typo">
                        <FormHelperText>Is Night Booking?</FormHelperText>
                        <Typography>{requestBookingData.scheduleNightBooking}</Typography>
                    </Grid>
                    <Grid xs={6} sm={4} item className="typo">
                        <FormHelperText>Collection And Delivery</FormHelperText>
                        <Typography>{requestBookingData.scheduleCollectionAndDeliver}</Typography>
                    </Grid>
                    <Grid xs={6} sm={4} item className="typo">
                        <FormHelperText>Deliver Before</FormHelperText>
                        <Typography>{requestBookingData.scheduleDeliverBefore}</Typography>
                    </Grid>
                    <Grid xs={6} sm={4} item className="typo">
                        <FormHelperText>Collect After</FormHelperText>
                        <Typography>{requestBookingData.scheduleCollectAfter}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <br />
            <Grid container spacing={2}>
                <Grid item sm={6} xs={12} className="mandatory-fields">
                    <InputLabel shrink>Booking Date</InputLabel>
                    <DatePicker name="scheduleBookingDate" value={moment(state.scheduleBookingDate).format('YYYY-MM-DD') || ''} onChange={valueChanged} />
                    <FormHelperText error>{errors.scheduleBookingDate ? 'This field is required' : null}</FormHelperText>
                </Grid>
                <Grid item sm={6} xs={12}>
                    <InputLabel shrink>Prep Date</InputLabel>
                    <DatePicker name="schedulePrepDate" value={moment(state.schedulePrepDate).format('YYYY-MM-DD') || ''} onChange={valueChanged} />
                    <FormHelperText error>{errors.schedulePrepDate ? 'This field is required' : null}</FormHelperText>
                </Grid>
                <Grid item sm={12} xs={12} className="mandatory-fields">
                    <InputLabel shrink>Service Agent</InputLabel>
                    <SelectBox
                        name="scheduleServiceAgentID"
                        value={state.scheduleServiceAgentID || ''}
                        onChange={valueChanged}
                        List={state.dropList.serviceAgents || []}
                    />
                    <FormHelperText error>{errors.scheduleServiceAgentID ? 'This field is required' : null}</FormHelperText>
                </Grid>
                <Grid item xs={6} className="submit_btn">
                    <SecondaryButton onClick={handleData}>Book Job</SecondaryButton>
                </Grid>
                <Grid item xs={6} className="submit_btn">
                    <SecondaryButton onClick={() => props.onClose(false)}>Cancel</SecondaryButton>
                </Grid>
            </Grid>
        </div>
    );
};
export default ScheduleBookingSection;
