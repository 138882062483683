import { postWithAuth, getWithAuth } from '../../../Basic/HttpService';

export const getContracts = async (id) => {
    let res = await getWithAuth(`Contracts/${id}`);
    return res;
};

export const getContractsDropDown = async () => {
    let res = await getWithAuth(`Contracts/screenDropdowns`);
    return res;
};

export const postContracts = async (data) => {
    let res = await postWithAuth(`Contracts`, data);
    return res;
};
