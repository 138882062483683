import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import SignaturePad from 'react-signature-canvas';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
    Tooltip,
    InputLabel,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    FormHelperText,
    Typography,
    CircularProgress,
    Modal,
    Hidden
} from '@material-ui/core';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import {
    SecondaryButton,
    InputText,
    FailButton,
    PassButton,
    NAButton,
    MButton,
    RButton,
    CheckCustomdBtn,
    SecondaryCheckbox,
    CustomChip,
    UploadWithBadge
} from '../../../../../Core/Controls';
import { getChecksEditByID, postChecksData, PostImage, getImageByID } from '../../../../../Core/Services/InternalUser/Vehicle/vehicleTabChecks';
import { useToast } from '../../../../../Core/Hooks/useToast';
import DeleteIcon from '@material-ui/icons/Delete';
import EditAxle from './editAxel';
import { AppStorage } from '../../../../../Core/Basic/storage-service';
import { getTitleData } from '../../../../../Core/Services/InternalUser/ControllerService/vehicleService';
import { useWindowSize } from '../../../../../Core/Hooks/useWindowSize';
import '../../controllerStyle.scss';
import EditIcon from '@material-ui/icons/Edit';
import ActionTakenModal from './actionTakenModal';
import DialogComp from '../../../../../Core/Dialog/DialogComp';
import ConfirmationModal from '../../../../../Core/Modal/ConfirmationModal';
import { usePortalSettings } from '../../../../../Core/Hooks/usePortalSettings';
import ImageModal from '../DriverDailyChecks/imageModal';

const StyledTableCell = withStyles((theme) => ({
    head: {
        fontSize: 16,
        padding: '8px 20px',
        color: '#f50057'
    },
    body: {
        fontSize: 14,
        padding: '8px 20px'
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: '#F5F5F5'
        }
    }
}))(TableRow);

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 15
    },
    table: {
        minWidth: '100%'
    },

    title: {
        color: '#f50057',
        marginLeft: 8,
        marginTop: 8,
        marginBottom: 8
    },
    loader: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 300
    },
    paper: {
        padding: theme.spacing(2),
        // color: theme.palette.text.secondary,
        border: '1px solid rgba(0,0,0,0.2)',
        width: '100%'
    },
    axelTitles: {
        textAlign: 'center',
        color: '#f50057',
        width: '100%'
    },
    headings: {
        color: '#f50057',
        margin: 0,
        marginLeft: -4,
        marginBottom: 20
    }
}));

const CheckButtons = {
    S: PassButton,
    D: FailButton,
    M: MButton,
    N: NAButton,
    R: RButton
};

const StatusButtons = {
    1: { name: 'Unreviewed', btnName: 'U' },
    2: { name: 'Satisfactory', btnName: 'S' },
    3: { name: 'Safety Item Defect', btnName: 'SD' },
    4: { name: 'Repair Required', btnName: 'R' },
    5: { name: 'Monitor', btnName: 'M' },
    6: { name: 'Customer Declined', btnName: 'C' }
};

const brakeTest = {
    1: 'Roller Test',
    2: 'Decelerometer',
    3: 'Road Test'
};

const setValue = (val, unit) => {
    if (val) {
        return (
            <>
                {val}
                <small>{unit}</small>
            </>
        );
    } else {
        return 'N/A';
    }
};

const EditVehicleDailyChecks = (props) => {
    let checkhaveAccess = AppStorage.getAllowAcces();
    const classes = useStyles();
    const [widths, heights] = useWindowSize();
    const portalSettings = usePortalSettings();

    const [state, setState] = useState({
        vehicleDailyChecks: [],
        tyreAxles: [],
        buttonList: [],
        load: true,
        showbtn: true,
        signAvailable: AppStorage.getSignature() === 'absent' ? false : true,
        isSignOff: false,
        isCustomerSignOff: false,
        submitArray: []
    });

    const [isModal, setIsModal] = useState({ actionTaken: false, selectedCheck: null, showConfirm: false });

    const { showToastSuccess, showToastError } = useToast();

    const handleInput = (e) => {
        const { name, value } = e.target;
        setState((state) => ({ ...state, [name]: value }));
    };

    useEffect(() => {
        getCheckDetails();
    }, []);

    const getCheckDetails = async () => {
        if (props.checkID) {
            let isSignOff = false;
            let isCustomerSignOff = false;
            let res = await getChecksEditByID(props.checkID);
            if (res.success) {
                res.data.vehicleDailyChecksResult.map((m, i) => {
                    if (!m.inspectionSheetCheckResultStatusID) {
                        m.inspectionSheetCheckResultStatusID = 1;
                    }
                });
                let TempData = [...res.data.vehicleDailyChecksResult];

                let checksList = TempData.map((m, i) => {
                    let checksObject;
                    if (TempData.length > 0) {
                        checksObject = TempData.filter((p) => {
                            if (m.inspectionSheetSectionDescription === p.inspectionSheetSectionDescription) {
                                if (p.defectStatusID == 3) {
                                    isCustomerSignOff = true;
                                } else if (p.defectStatusID == 1) {
                                    isSignOff = true;
                                }
                                return m;
                            }
                        });
                    }
                    checksObject.name = m.inspectionSheetSectionDescription;
                    return checksObject;
                });

                let vehicleChecksList = Object.values(checksList.reduce((acc, cur) => Object.assign(acc, { [cur.name]: cur }), {}));

                let TempAxel = [];
                if (res.data.tyreAxles) {
                    let IsAxel1 = res.data.tyreAxles.find((x) => x.axleNumber === 1);
                    let IsAxel2 = res.data.tyreAxles.find((x) => x.axleNumber === 2);
                    let IsAxel3 = res.data.tyreAxles.find((x) => x.axleNumber === 3);
                    let IsAxel4 = res.data.tyreAxles.find((x) => x.axleNumber === 4);
                    if (!IsAxel1) {
                        IsAxel1 = { axleNumber: 1 };
                    }
                    if (!IsAxel2) {
                        IsAxel2 = { axleNumber: 2 };
                    }
                    if (!IsAxel3) {
                        IsAxel3 = { axleNumber: 3 };
                    }
                    if (!IsAxel4) {
                        IsAxel4 = { axleNumber: 4 };
                    }
                    TempAxel = [IsAxel1, IsAxel2, IsAxel3, IsAxel4];
                }

                setState((st) => {
                    if (res.success) {
                        return {
                            ...st,
                            load: false,
                            vehicleDailyChecks: vehicleChecksList,
                            tyreAxles: [...TempAxel],
                            buttonList: res.data.vehicleDailyCheckOptions,
                            ChecksObj: res.data.vehicleDailyChecksResult,
                            statusList: res.data.inspectionSheetCheckResultStatus,
                            isSignOff: isSignOff,
                            isCustomerSignOff: isCustomerSignOff
                        };
                    }
                });
                res.data.vehicleDailyChecks.map((item) => {
                    if (item.vehicleInspectionSignedOffSignature) {
                        setState((st) => ({
                            ...st,
                            showbtn: false
                        }));
                    }

                    setState((st) => ({
                        ...st,
                        vehicleID: item.vehicleID,
                        vehicleRegNumber: item.vehicleRegNumber,
                        date: moment(item.vehicleInspectionDateInspected).format('YYYY-MM-DD'),
                        makeID: item.vehicleMake,
                        modelID: item.vehicleModel,
                        imgtechniciansign: item.vehicleInspectionInspectorSignature,
                        vehicleInspectionScheduleID: item.vehicleInspectionScheduleID,
                        vehicleInspectionStatusID: item.vehicleInspectionStatusID,
                        vehicleInspectionDateInspected: moment(item.vehicleInspectionDateInspected).format('YYYY-MM-DD hh:mm'),
                        vehicleInspectionResult: item.vehicleInspectionResult,
                        vehicleDailyCheckMileage: item.vehicleInspectionMileage,
                        vehicleDailyCheckCheckerName: item.vehicleInspectionInspectorName,
                        vehicleInspectionInspectionComment: item.vehicleInspectionInspectionComment,
                        vehicleInspectionSignedOffName: item.vehicleInspectionSignedOffName,
                        vehicleInspectionSignedOffSignature: item.vehicleInspectionSignedOffSignature,
                        vehicleInspectionSignedOffDate: item.vehicleInspectionSignedOffDate,
                        vehicleInspectionSignedOffIPAddress: item.vehicleInspectionSignedOffIPAddress,
                        vehicleInspectionID: item.vehicleInspectionID,
                        vehicleInspectionSignedOffComment: item.vehicleInspectionSignedOffComment,

                        vehicleInspectionBrakeTestTypeID: item.vehicleInspectionBrakeTestTypeID, // 2,
                        vehicleInspectionBrakeTestNumber: item.vehicleInspectionBrakeTestNumber, // "12345",
                        vehicleInspectionRoadCondition: item.vehicleInspectionRoadCondition, // "Test Road Conditions",
                        vehicleInspectionServiceBrakePerformance: item.vehicleInspectionServiceBrakePerformance, // 71,
                        vehicleInspectionSecondaryBrakePerformance: item.vehicleInspectionSecondaryBrakePerformance, // 72,
                        vehicleInspectionParkingBrakePerformance: item.vehicleInspectionParkingBrakePerformance // 73
                    }));
                });
            }
        }
    };

    useEffect(async () => {
        if (state.vehicleID) {
            getTitle();
        }
    }, [state.vehicleID]);

    const getTitle = async () => {
        let vehicleInformation = await getTitleData(state.vehicleID);

        if (vehicleInformation.success) {
            setState((st) => ({
                ...st,
                vehicleInfo: vehicleInformation.data.details[0]?.vehicleDescription
            }));
        }
    };

    const signCanvas = useRef({});
    const clear = () => {
        if (state.vehicleInspectionSignedOffSignature) {
            setState((st) => ({
                ...st,
                vehicleInspectionSignedOffSignature: null
            }));
        } else {
            signCanvas.current.clear();
        }
    };

    const GetTechnicianSign = () => {
        let fields = state.fields;
        setState((st) => {
            const nst = {
                ...st,
                vehicleInspectionSignedOffSignature: signCanvas.current.toDataURL(),
                fields: fields
            };
            return nst;
        });
    };

    const changeState = (buttonVal, checkId) => {
        let changedArray = [...state.ChecksObj];
        let objIndex = state.ChecksObj.findIndex((x) => x.inspectionSheetCheckID === checkId);
        changedArray[objIndex].inspectionSheetCheckResultStatusID = buttonVal;

        setState((st) => ({ ...st, ChecksObj: changedArray }));
    };

    // const handleInputs = (axelNo) => (e) => {
    //     const { name, value } = e.target;
    //     setState((st) => {
    //         let ax = st.tyreAxles.find((a) => a.axleNumber === axelNo);
    //         if (!ax) {
    //             ax = { axleNumber: axelNo };
    //             st.tyreAxles.push(ax);
    //         }
    //         ax[name] = value;

    //         return { ...st, tyreAxles: [...st.tyreAxles] };
    //     });
    // };

    const imageHandler = async (inspectionCheckResultID) => {
        let res = await getImageByID(inspectionCheckResultID);
        if (!res.data.list) {
            let changedArray = [...state.ChecksObj];
            let objIndex = state.ChecksObj.findIndex((x) => x.inspectionSheetCheckResultID === inspectionCheckResultID);
            setState((st) => ({
                ...st,
                showImageModal: true,
                inspectionCheckResultID: inspectionCheckResultID,
                selectedImages: changedArray[objIndex].images || []
            }));
        } else {
            setState((st) => ({
                ...st,
                showImageModal: true,
                inspectionCheckResultID: inspectionCheckResultID,
                selectedImages: res.data.list.map((m) => m.vehicleInspectionsPhotoObject) || []
            }));
        }
    };

    const onImageChange = (imageList, addUpdateIndex) => {
        let changedArray = [...state.ChecksObj];
        let FilterimagesList;
        let objIndex = state.ChecksObj.findIndex((x) => x.inspectionSheetCheckResultID === state.inspectionCheckResultID);

        if (`${objIndex}`) {
            FilterimagesList = imageList.map((m) => (m.data_url ? m.data_url : m));
            changedArray[objIndex].images = FilterimagesList;
        }
        let uniqueImages = FilterimagesList.filter((g) => {
            let isImage = state.selectedImages.find((w) => w == g);
            if (!isImage) {
                return g;
            }
        });

        setState((st) => ({ ...st, ChecksObj: changedArray, selectedImages: FilterimagesList, submitArray: [...state.submitArray, ...uniqueImages] }));
    };

    const useDefaultSig = () => {
        setState((st) => ({ ...st, img: true, vehicleInspectionSignedOffSignature: AppStorage.getSignature() }));
    };

    const validations = () => {
        let formIsValid = true;
        let errors = {};

        if (!state.vehicleInspectionSignedOffComment) {
            errors.vehicleInspectionSignedOffComment = `Signed Off Comment is required`;
            formIsValid = false;
        }
        if (!state.vehicleInspectionSignedOffSignature) {
            errors.vehicleInspectionSignedOffSignature = `Signature is required`;
        }
        setState((st) => ({
            ...st,
            errors: errors
        }));
        return formIsValid;
    };

    const submitData = async () => {
        let resultVM = state.ChecksObj.map((e) => ({
            inspectionSheetCheckResultCheckID: e.inspectionSheetCheckResultCheckID,
            inspectionSheetCheckResultOptionCode: e.inspectionSheetCheckResultOptionCode,
            inspectionSheetCheckResultNotes: e.inspectionSheetCheckResultNotes,
            inspectionSheetCheckResultIMReference: e.inspectionSheetCheckResultIMReference,
            inspectionSheetCheckResultDefectID: e.inspectionSheetCheckResultDefectID,
            inspectionSheetCheckResultStatusID: e.inspectionSheetCheckResultStatusID
        }));

        // if (validations()) {
        let data = {
            vehicleInspectionScheduleID: state.vehicleInspectionScheduleID,
            vehicleInspectionStatusID: state.vehicleInspectionStatusID,
            vehicleInspectionDateInspected: state.vehicleInspectionDateInspected,
            vehicleInspectionResult: state.vehicleInspectionResult,
            vehicleInspectionMileage: state.vehicleDailyCheckMileage,
            vehicleInspectionInspectorName: state.vehicleDailyCheckCheckerName,
            vehicleInspectionInspectorSignature: state.techniciansign,
            vehicleInspectionInspectionComment: state.vehicleInspectionInspectionComment,
            vehicleInspectionSignedOffName: state.vehicleInspectionSignedOffName, //loged user name
            vehicleInspectionSignedOffSignature: state.vehicleInspectionSignedOffSignature, //current date and time
            vehicleInspectionSignedOffDate: state.vehicleInspectionSignedOffDate,
            vehicleInspectionSignedOffIPAddress: state.vehicleInspectionSignedOffIPAddress,
            vehicleInspectionID: state.vehicleInspectionID,
            vehicleInspectionBrakeTestTypeID: state.vehicleInspectionBrakeTestTypeID,
            vehicleInspectionBrakeTestNumber: state.vehicleInspectionBrakeTestNumber,
            vehicleInspectionRoadCondition: state.vehicleInspectionRoadCondition,
            vehicleInspectionServiceBrakePerformance: state.vehicleInspectionServiceBrakePerformance,
            vehicleInspectionSecondaryBrakePerformance: state.vehicleInspectionSecondaryBrakePerformance,
            vehicleInspectionParkingBrakePerformance: state.vehicleInspectionParkingBrakePerformance,
            vehicleInspectionSignedOffComment: state.vehicleInspectionSignedOffComment,
            inspectionSheetCheckResultsVM: resultVM,
            inspectionSheetAxlesVM: state.tyreAxles
        };

        let res = await postChecksData(data);
        if (res.success) {
            showToastSuccess('Inspection Check signed Off successfully');
            props.onClose();
        } else {
            showToastError(res.message);
        }
        // }
    };

    const actionTakenHandle = (check) => {
        setIsModal((st) => ({
            ...st,
            actionTaken: true,
            selectedCheck: check
        }));
    };

    const closeActionTakenhandle = (res) => {
        setIsModal((st) => ({
            ...st,
            actionTaken: false,
            showConfirm: false
        }));
        res && getCheckDetails();
    };

    const confirmHandle = () => {
        if (validations()) {
            setIsModal((st) => ({
                ...st,
                showConfirm: true
            }));
        }
    };

    const sumbitImage = async () => {
        let tempArray = [...state.submitArray];
        let data = {
            vehicleInspectionsPhotoID: null,
            vehicleInspectionsPhotoResultID: state.inspectionCheckResultID,
            vehicleInspectionsPhotoObject: tempArray
        };
        let res = await PostImage(data);
        if (res.success) {
            let changedArray = [...state.ChecksObj];
            let objIndex = state.ChecksObj.findIndex((x) => x.inspectionSheetCheckResultID === state.inspectionCheckResultID);
            changedArray[objIndex].basicCount = changedArray[objIndex].images.length;
            showToastSuccess('image Uploaded successfully');
            setState((st) => ({
                ...st,
                showImageModal: false,
                ChecksObj: changedArray
            }));
        }
    };

    return (
        <div className={classes.root}>
            {!state.load ? (
                <Grid container spacing={2}>
                    {props.frompage ? (
                        <Grid item xs={12} sm={12} md={10} style={{ padding: '0px 8px' }}>
                            <Typography variant={'h5'} style={{ fontSize: widths < 500 ? 17 : '' }}>
                                {state.vehicleInfo?.toUpperCase() || ''}
                            </Typography>
                        </Grid>
                    ) : null}
                    <Grid item xs={12} sm={12} md={props.frompage ? 2 : 12} style={{ padding: '0px 8px' }}>
                        <Typography variant={'h5'} style={{ textAlign: props.frompage ? 'end' : 'start', fontSize: widths < 500 ? 15 : '' }}>
                            {state.date}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        {widths >= 960 ? null : <div style={{ fontWeight: 550 }}>Result</div>}
                        <Paper style={{ padding: 10, border: '1px solid #c5bfbf' }}>
                            <Hidden only={['xs', 'sm']}>
                                <Grid container spacing={1} justify={widths >= 960 ? 'space-evenly' : 'flex-start'}>
                                    {state.buttonList?.map((k, i) => {
                                        const Comp = CheckButtons[k.inspectionSheetCheckOptionCode];
                                        return (
                                            <Grid item key={i} container xs={6} sm={6} md={2} lg={2} maxwidth="sm">
                                                {/* <Grid item sm={9} maxwidth="sm" style={{ marginTop: 5 }}></Grid> */}
                                                <Grid item sm={12} style={{ textAlign: 'center' }}>
                                                    <Comp state={true} />
                                                    <small style={{ fontWeight: 600 }}> {k.inspectionSheetCheckOptionDescription}</small>
                                                </Grid>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </Hidden>
                            <Hidden only={['md', 'lg', 'xl']}>
                                <Grid container spacing={1}>
                                    {state.buttonList?.map((k, i) => {
                                        const Comp = CheckButtons[k.inspectionSheetCheckOptionCode];
                                        return (
                                            <Grid key={i} item md={6} sm={6} xs={6} container maxwidth="sm">
                                                {/* <Grid item sm={9} maxwidth="sm" style={{ marginTop: 5 }}></Grid> */}
                                                <Grid item style={{ textAlign: 'center' }}>
                                                    <Comp state={true} />
                                                </Grid>
                                                &nbsp;&nbsp;
                                                <Grid item style={{ textAlign: 'center' }}>
                                                    <small style={{ fontWeight: 600 }}> {k.inspectionSheetCheckOptionDescription}</small>
                                                </Grid>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </Hidden>
                        </Paper>
                    </Grid>

                    {state.vehicleDailyChecks.map((m, i) => (
                        <Grid item xs={12} md={12} lg={12} key={i}>
                            <TableContainer component={Paper}>
                                <div
                                    style={{
                                        border: '1px solid rgba(0,0,0,0.2)'
                                    }}
                                >
                                    <h2 className={classes.title}>{m.name}</h2>
                                </div>
                                <Table aria-label="simple table" className={classes.paper}>
                                    <TableHead>
                                        <Hidden only={['md', 'sm', 'xs']}>
                                            <TableRow>
                                                <StyledTableCell width="50%">Check</StyledTableCell>
                                                <StyledTableCell align="center" width="5%">
                                                    Result
                                                </StyledTableCell>
                                                <StyledTableCell align="center" width="5%">
                                                    Upload
                                                </StyledTableCell>
                                                <StyledTableCell align="left" width="40%">
                                                    Defect Found
                                                </StyledTableCell>
                                            </TableRow>
                                        </Hidden>
                                        <Hidden only={['lg', 'xl']}>
                                            <TableRow>
                                                <StyledTableCell width="50%">Check</StyledTableCell>
                                            </TableRow>
                                        </Hidden>
                                    </TableHead>
                                    <TableBody>
                                        {m.map((p, i) => (
                                            <>
                                                <Hidden only={['md', 'sm', 'xs']}>
                                                    <StyledTableRow
                                                        style={{
                                                            backgroundColor:
                                                                p.inspectionSheetCheckResultOptionCode == 'D'
                                                                    ? '#f58a88'
                                                                    : p.inspectionSheetCheckResultOptionCode == 'M'
                                                                    ? '#FFD65C'
                                                                    : ''
                                                        }}
                                                    >
                                                        <StyledTableCell component="th">
                                                            {p.inspectionSheetCheckDescription}
                                                            {/* <br /> */}
                                                            {/* <FormHelperText error>{p.inspectionSheetCheckResultNotes} </FormHelperText> */}
                                                        </StyledTableCell>
                                                        {state.buttonList.map((b) => {
                                                            {
                                                                const Comp = CheckButtons[b.inspectionSheetCheckOptionCode] || ' Button';
                                                                const checks = p.inspectionSheetCheckResultOptionCode;

                                                                if (checks === b.inspectionSheetCheckOptionCode) {
                                                                    return (
                                                                        <StyledTableCell align="center">
                                                                            <Comp
                                                                                state={
                                                                                    p.inspectionSheetCheckResultOptionCode === b.inspectionSheetCheckOptionCode
                                                                                }
                                                                                disabled={true}
                                                                            ></Comp>
                                                                        </StyledTableCell>
                                                                    );
                                                                }
                                                            }
                                                        })}
                                                        <StyledTableCell align="center">
                                                            {' '}
                                                            <UploadWithBadge
                                                                onClick={() => imageHandler(p.inspectionSheetCheckResultID)}
                                                                count={p.photosUploaded || p.basicCount}
                                                                state={p.photosUploaded > 0 || p.basicCount > 0}
                                                            />
                                                        </StyledTableCell>

                                                        <StyledTableCell align="center">
                                                            {/* {p.inspectionSheetCheckResultOptionCode !== 'S' && p.inspectionSheetCheckResultOptionCode !== 'N' && ( */}
                                                            <Grid container justify="space-between" alignItems="center" spacing={1}>
                                                                <Grid item>
                                                                    <Typography variant="caption" display="block" gutterBottom>
                                                                        {' '}
                                                                        {p.inspectionSheetCheckResultNotes ? p.inspectionSheetCheckResultNotes : 'N/A'}{' '}
                                                                    </Typography>
                                                                </Grid>
                                                                {p.defectStatusID && (
                                                                    <Grid item>
                                                                        <CustomChip
                                                                            icon={EditIcon}
                                                                            iconFontSize={16}
                                                                            label={p.defectStatusID !== 1 ? `${p.resultStatus} by ${p.rectifiedBy}` : 'Action'}
                                                                            onClick={() => actionTakenHandle(p)}
                                                                            className="btn-m"
                                                                            style={{
                                                                                fontWeight: 'bold',
                                                                                backgroundColor:
                                                                                    p.defectStatusID && p.rectifiedBy ? `${p.backgroundColour}` : ''
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                )}
                                                            </Grid>
                                                            {/* )} */}
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                </Hidden>
                                                <Hidden only={['lg', 'xl']}>
                                                    <StyledTableRow
                                                        style={{
                                                            backgroundColor:
                                                                p.inspectionSheetCheckResultOptionCode == 'D'
                                                                    ? '#f58a88'
                                                                    : p.inspectionSheetCheckResultOptionCode == 'M'
                                                                    ? '#FFD65C'
                                                                    : ''
                                                        }}
                                                    >
                                                        <StyledTableCell component="th">
                                                            <Grid container spacing={1}>
                                                                <Grid item md={10} sm={9} xs={12}>
                                                                    {' '}
                                                                    {p.inspectionSheetCheckDescription}
                                                                </Grid>
                                                                <Grid item container spacing={2} md={2} sm={3} xs={12}>
                                                                    {state.buttonList.map((b) => {
                                                                        {
                                                                            const Comp = CheckButtons[b.inspectionSheetCheckOptionCode] || ' Button';
                                                                            const checks = p.inspectionSheetCheckResultOptionCode;

                                                                            if (checks === b.inspectionSheetCheckOptionCode) {
                                                                                return (
                                                                                    <Grid item align="center">
                                                                                        <Comp
                                                                                            state={
                                                                                                p.inspectionSheetCheckResultOptionCode ===
                                                                                                b.inspectionSheetCheckOptionCode
                                                                                            }
                                                                                            disabled={true}
                                                                                        ></Comp>
                                                                                    </Grid>
                                                                                );
                                                                            }
                                                                        }
                                                                    })}
                                                                    <Grid item align="center">
                                                                        <UploadWithBadge
                                                                            onClick={() => imageHandler(p.inspectionSheetCheckResultID)}
                                                                            count={p.photosUploaded || p.basicCount}
                                                                            state={p.photosUploaded > 0 || p.basicCount > 0}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item container xs={12}>
                                                                    <Grid item>
                                                                        {/* <InputLabel shrink>Defect</InputLabel> */}
                                                                        <Typography variant="caption" display="block" gutterBottom>
                                                                            {' '}
                                                                            Defect Found:{' '}
                                                                            {p.inspectionSheetCheckResultNotes ? p.inspectionSheetCheckResultNotes : 'N/A'}{' '}
                                                                            {p.defectStatusID && (
                                                                                <CustomChip
                                                                                    icon={EditIcon}
                                                                                    iconFontSize={16}
                                                                                    label={
                                                                                        p.defectStatusID !== 1
                                                                                            ? `${p.resultStatus} by ${p.rectifiedBy}`
                                                                                            : 'Action'
                                                                                    }
                                                                                    onClick={() => actionTakenHandle(p)}
                                                                                    className="btn-m"
                                                                                    style={{
                                                                                        fontWeight: 'bold',
                                                                                        backgroundColor:
                                                                                            p.defectStatusID && p.rectifiedBy ? `${p.backgroundColour}` : ''
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                </Hidden>
                                            </>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    ))}
                    {/* Tyre checks column */}
                    <Grid item container direction="row" xs={12} sm={12}>
                        <EditAxle state={state} classes={classes} />
                    </Grid>
                    <Grid item container direction="row" xs={12} sm={12} md={12}>
                        <Paper className={classes.paper}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                    <Grid container>
                                        <Grid item xs={12} sm={3} md={3}>
                                            <InputLabel shrink> Type of brake test </InputLabel>
                                            <Typography variant="button"> {brakeTest[state.vehicleInspectionBrakeTestTypeID] || 'N/A'}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={3} md={3}>
                                            <InputLabel shrink> Brake Test Number </InputLabel>
                                            <Typography variant="button"> {state.vehicleInspectionBrakeTestNumber || 'N/A'}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={3} md={6}>
                                            <InputLabel shrink> Road Conditions </InputLabel>
                                            <Typography variant="button"> {state.vehicleInspectionRoadCondition || 'N/A'}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item container direction="row" xs={12} sm={12} md={6} lg={6}>
                        <Grid item xs={12} md={12} lg={12}>
                            <TableContainer component={Paper}>
                                <div
                                    style={{
                                        border: '1px solid rgba(0,0,0,0.2)',
                                        padding: '3px 0px'
                                    }}
                                >
                                    <h3 className={classes.title}> Brake Performance</h3>
                                </div>
                                <Table aria-label="simple table" className={classes.paper}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width="15%">IM Reference</TableCell>
                                            <TableCell align="left" width="50%">
                                                Item Inspected
                                            </TableCell>
                                            <TableCell align="left" width="20%">
                                                Efficiency
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell> 71</TableCell>
                                            <TableCell> Service Brake Performance</TableCell>
                                            <TableCell>
                                                <Typography variant="button"> {setValue(state.vehicleInspectionServiceBrakePerformance, '%')}</Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell> 72</TableCell>
                                            <TableCell> Secondary Brake Performance</TableCell>
                                            <TableCell>
                                                <Typography variant="button"> {setValue(state.vehicleInspectionSecondaryBrakePerformance, '%')}</Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell> 73</TableCell>
                                            <TableCell> Parking Brake Performance</TableCell>
                                            <TableCell>
                                                <Typography variant="button"> {setValue(state.vehicleInspectionParkingBrakePerformance, '%')}</Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                    <Grid item container direction="row" xs={12} sm={12} md={6} lg={6}>
                        <TableContainer component={Paper}>
                            <div
                                style={{
                                    border: '1px solid rgba(0,0,0,0.2)'
                                }}
                            >
                                <h3 className={classes.title}>
                                    Road Test &nbsp;<small>(Brake Temperature Assessment)</small>
                                </h3>
                            </div>
                            <Table aria-label="simple table" className={classes.paper}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell width="20%">Side</TableCell>
                                        <TableCell align="left">Axle 1</TableCell>
                                        <TableCell align="left">Axle 2</TableCell>
                                        <TableCell align="left">Axle 3</TableCell>
                                        <TableCell align="left">Axle 4</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell component="th">
                                            <b>O/S</b>
                                        </TableCell>

                                        <TableCell>
                                            <Typography variant="button"> {setValue(state.tyreAxles[0]?.osBrakeTemperatureAxle, '°C')}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="button"> {setValue(state.tyreAxles[1]?.osBrakeTemperatureAxle, '°C')}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="button"> {setValue(state.tyreAxles[2]?.osBrakeTemperatureAxle, '°C')}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="button"> {setValue(state.tyreAxles[3]?.osBrakeTemperatureAxle, '°C')}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell component="th">
                                            <b>N/S</b>
                                        </TableCell>

                                        <TableCell>
                                            <Typography variant="button"> {setValue(state.tyreAxles[0]?.nsBrakeTemperatureAxle, '°C')}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="button"> {setValue(state.tyreAxles[1]?.nsBrakeTemperatureAxle, '°C')}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="button"> {setValue(state.tyreAxles[2]?.nsBrakeTemperatureAxle, '°C')}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="button"> {setValue(state.tyreAxles[3]?.nsBrakeTemperatureAxle, '°C')}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow></TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    {/* Vehicle SignOff page */}
                    <Grid item container xs={12}>
                        <Paper className={classes.paper}>
                            <Grid container xs={12}>
                                <h3 className={classes.headings}>Vehicle Inspector Sign Off</h3>
                            </Grid>
                            <Grid container spacing={1} justify="space-between">
                                <Grid item container spacing={1} xs={12} md={6}>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <InputLabel shrink>Vehicle Checker Comment</InputLabel>
                                        <Typography variant="button">{state.vehicleInspectionInspectionComment}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid spacing={1} container direction="row">
                                            <Grid item xs={12} sm={12} md={12} lg={4}>
                                                <InputLabel shrink>Current Mileage</InputLabel>
                                                <Typography variant="button">{state.vehicleDailyCheckMileage}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item spacing={1} xs={12} md={6}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <div style={{ position: 'relative' }}>
                                            <InputLabel shrink>Signature</InputLabel>
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    right: 0
                                                }}
                                            ></div>
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    right: 0,
                                                    bottom: 0,
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'flex-end'
                                                }}
                                            >
                                                <Typography variant="h6">{state.vehicleDailyCheckCheckerName}</Typography>
                                                <Typography variant="button">{state.vehicleInspectionDateInspected}</Typography>
                                            </div>

                                            {state.imgtechniciansign ? (
                                                <div className="sigContainer">
                                                    <img src={state.imgtechniciansign} style={{ width: '100%', height: '100%' }} alt="my signature" />
                                                </div>
                                            ) : (
                                                <div className="sigContainer">
                                                    <Typography variant="button">No Signature Found</Typography>
                                                </div>
                                            )}
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item container xs={12}>
                        <Paper className={classes.paper}>
                            <Grid container xs={12}>
                                <h3 className={classes.headings}>Sign Off</h3>
                            </Grid>
                            <Grid container spacing={1} justify="space-between">
                                <Grid item xs={12} sm={12} className="mandatory-fields">
                                    <InputLabel style={{ fontWeight: 'bold' }}>
                                        {/* I consider that the above defects have been rectified satisfactorily and this vehicle is now in a safe and roadworthy
                                        condition. */}
                                        {!state.isSignOff && state.isCustomerSignOff
                                            ? portalSettings.portalSettingSignOffCustomerDeclaration
                                            : portalSettings.portalSettingSignOffManagerDeclaration}
                                    </InputLabel>
                                    {/* <Typography variant="body2"> {state.defectDetail}</Typography> */}
                                </Grid>

                                <Grid item xs={12} md={6} style={{ marginBottom: state.vehicleInspectionStatusID === 2 ? 38 : 2 }} className="mandatory-fields">
                                    <InputLabel shrink>Comment *</InputLabel>
                                    {state.vehicleInspectionStatusID === 2 ? (
                                        <Typography variant="h6">{state.vehicleInspectionSignedOffComment} </Typography>
                                    ) : (
                                        <InputText
                                            multiline
                                            rows={2}
                                            placeholder="Comment"
                                            name="vehicleInspectionSignedOffComment"
                                            value={state.vehicleInspectionSignedOffComment}
                                            onChange={handleInput}
                                        />
                                    )}
                                    <FormHelperText error>{state.errors?.vehicleInspectionSignedOffComment}</FormHelperText>
                                </Grid>
                                <Grid item spacing={2} xs={12} md={6}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <div style={{ position: 'relative' }}>
                                            <InputLabel shrink error>
                                                Signature
                                                {state.signAvailable && state.vehicleInspectionStatusID !== 2 ? (
                                                    <Tooltip title="Use default signature">
                                                        <BorderColorIcon onClick={useDefaultSig} style={{ cursor: 'pointer', margin: '0px 0px -5px 5px' }} />
                                                    </Tooltip>
                                                ) : null}
                                            </InputLabel>
                                            {state.showbtn ? (
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        right: 0
                                                    }}
                                                >
                                                    <Tooltip title="Clear Signature">
                                                        <DeleteIcon
                                                            onClick={clear}
                                                            style={{
                                                                cursor: 'pointer'
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </div>
                                            ) : null}

                                            {state.vehicleInspectionStatusID === 2 ? (
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        right: 0,
                                                        bottom: 0
                                                    }}
                                                >
                                                    <Typography variant="h6">{state.vehicleInspectionSignedOffName}</Typography>
                                                    <Typography variant="button">
                                                        {moment(state.vehicleInspectionSignedOffDate).format('DD-MM-yyyy HH:MM')}
                                                    </Typography>
                                                </div>
                                            ) : null}
                                            <div className="workflow-job-details">
                                                {state.vehicleInspectionSignedOffSignature ? (
                                                    <div className="sigContainer">
                                                        <img
                                                            src={state.vehicleInspectionSignedOffSignature}
                                                            style={{ width: '100%', height: '100%' }}
                                                            alt="my signature"
                                                        />
                                                    </div>
                                                ) : (
                                                    <div className="sigContainer">
                                                        <SignaturePad
                                                            ref={signCanvas}
                                                            canvasProps={{ className: 'sigPad' }}
                                                            onEnd={() => {
                                                                GetTechnicianSign();
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                            <FormHelperText error>{state.errors?.vehicleInspectionSignedOffSignature}</FormHelperText>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item container spacing={2} xs={12} lg={12}>
                        {state.isSignOff || state.vehicleInspectionStatusID === 2 ? null : (
                            <Grid item xs={12} sm={6}>
                                {state.isCustomerSignOff ? (
                                    <SecondaryButton onClick={confirmHandle} disabled={checkhaveAccess?.CanUpdateVehicleInspections}>
                                        Customer Sign Off
                                    </SecondaryButton>
                                ) : (
                                    <SecondaryButton onClick={confirmHandle} disabled={state.isSignOff}>
                                        Sign Off
                                    </SecondaryButton>
                                )}
                            </Grid>
                        )}
                        <Grid item xs={12} sm={state.isSignOff || state.vehicleInspectionStatusID === 2 ? 12 : 6}>
                            <SecondaryButton onClick={() => props.onClose()}>Cancel</SecondaryButton>
                        </Grid>
                    </Grid>
                </Grid>
            ) : (
                <div className={classes.loader}>
                    <CircularProgress color="secondary" />
                </div>
            )}
            {/* Checks Table */}

            {isModal.actionTaken ? (
                <DialogComp title="Defect Action" onClose={() => closeActionTakenhandle(false)} maxWidth="md" overflow="auto" fullWidth>
                    <ActionTakenModal selectedCheck={isModal.selectedCheck} onClose={closeActionTakenhandle} />
                </DialogComp>
            ) : null}

            {isModal.showConfirm ? (
                <DialogComp title={`Confirm ${state.isCustomerSignOff ? 'Customer' : ''} Sign Off`} onClose={() => closeActionTakenhandle(false)} maxWidth="sm">
                    <ConfirmationModal
                        message={`Please note that you will not be able to make any changes once submitted. Click the Confirm button to proceed or Cancel to abort.`}
                        handleSubmit={submitData}
                        handleCancel={() => closeActionTakenhandle(false)}
                        title={`Confirm ${state.isCustomerSignOff ? 'Customer' : ''} Sign Off`}
                    />
                </DialogComp>
            ) : null}
            {state.showImageModal ? (
                // <DialogComp title="Upload Image" onClose={() => setState((st) => ({ ...st, show: false }))} maxWidth="lg">
                <ImageModal
                    images={state.selectedImages}
                    onImageChange={onImageChange}
                    submitImage={sumbitImage}
                    disabled={state.isSignOff || state.vehicleInspectionStatusID === 2}
                    onClose={() => setState((st) => ({ ...st, showImageModal: false }))}
                    maxNumber={10}
                />
            ) : // </DialogComp>
            null}
        </div>
    );
};

export default EditVehicleDailyChecks;
