import { TextField } from '@material-ui/core';
import { useLoginInfo } from '../../Hooks/useLoginInfo';
import { memo } from 'react';

export const TextBox = (props) => {
    const { forceEditable, ...rest } = props;
    let { isReadOnlyUser } = useLoginInfo();
    forceEditable && (isReadOnlyUser = false);
    return <TextField size="small" variant="outlined" disabled={isReadOnlyUser} autoComplete="off" {...rest} fullWidth />;
};

const InputTextWithMemo = memo(TextBox);
export default InputTextWithMemo;
