import React, { useEffect, useState, useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import { Divider, FormHelperText, InputLabel, Typography } from '@material-ui/core';
import {
    addUpdateNote,
    getNoteById,
    getNotesStickButtons,
    getAllNotes,
    getServiceAgentJobTracking
} from '../../../../Core/Services/InternalUser/ControllerService/noteService';
import { DialogTitle } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';
import { useToast } from '../../../../Core/Hooks/useToast';
import moment from 'moment';
import {
    InputText,
    SecondaryButton,
    DateTimePicker,
    DataGrid,
    formatters,
    SecondaryCheckbox,
    SimpleField,
    CustomChip,
    DialogWithoutStaticHeader
} from '../../../../Core/Controls';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import ServiceAgentDetails from '../DetailsModal/serviceAgent';
import ConfirmationModal from '../../../../Core/Modal/ConfirmationModal';

const notecolumns = [
    { key: 'dateCreated', name: 'Created', width: 160, formatter: formatters.Date_DDMMMYYYY_HHMM },
    { key: 'createdBy', name: 'Created By', width: 130 },
    { key: 'note', name: 'Notes' }
];
const serviceAgentJobTrackingListcolumns = [
    { key: 'actionDate', name: 'Date', width: 160, formatter: formatters.Date_DDMMMYYYY_HHMM },
    { key: 'notes', name: 'Reminder Information' },
    { key: 'regNumber', name: 'Reg Number', width: 120 },
    { key: 'number', name: 'Number', width: 100 },
    { key: 'type', name: 'Type', width: 150 }
];

const FlaggedBox = (props) => {
    return (
        <>
            <InputLabel shrink>{props.label}</InputLabel>
            <Grid container alignItems="flex-end">
                <Grid item xs={1}>
                    <SecondaryCheckbox checked={props.chkValue} onChange={props.onCheckChange} name={props.chkName} disabled={props.disabled} />
                </Grid>
                <Grid item xs={11}>
                    <SimpleField
                        placeholder={props.label}
                        value={props.txtValue}
                        onChange={props.inputChange}
                        name={props.txtName}
                        disabled={props.disabled || !props.chkValue}
                        type={props.fieldType || 'text'}
                    />
                </Grid>
            </Grid>
        </>
    );
};

const AddEditJobsNotes = (props) => {
    const { showToastError, showToastSuccess } = useToast();

    const [btnLoader, setBtnLoader] = useState({ newBtnLoader: false, extBtnLoader: false });

    const [state, setState] = useState({
        show: false,
        showLoader: true,
        NoteList: [],
        noteID: null,
        noteTitle: props.notesTitle === 'Defects' ? 'Defect notes' : props.notesTitle === 'BreakDown' ? 'Breakdown notes' : '',
        noteDescription: '',
        noteActive: true,
        errors: {},
        newErrors: {},
        noteVehicleID: null,
        noteRefsID: null,
        noteStickyDisplayType: '',
        noteStickyDisplayFromDate: '',
        noteStickyDisplayEndDate: moment().format('YYYY-MM-DDTHH:mm'),
        noteStickyDisplayType: 'A',
        newNoteTitle: props.notesTitle === 'Defects' ? 'Defect notes' : props.notesTitle === 'BreakDown' ? 'Breakdown notes' : '',
        newNoteDescription: '',
        newNoteRefsGuidID: null,
        newNoteRefsID: null,
        newNoteStickyDisplayType: '',
        newNoteStickyDisplayFromDate: '',
        newNoteStickyDisplayEndDate: '',
        noteSendSMSReported: false,
        noteSendSMSReportedNo: props.notesTitle === 'Defects' || 'BreakDown' ? props.reportedPhone : '',
        driverName: props.driverName || '',
        newNoteSendSMS: false,
        newNoteSendSMSNumber: props.DisplayData || '',
        reportedBy: props.reportedBy || '',
        newNoteSendSMSReported: false,
        newNoteSendSMSReportedNo: props.reportedPhone || '',
        noteDescriptionOne: ''
    });

    const pullNoteAndUpdateState = async () => {
        let res = await getAllNotes('Defects', 0, 0, 0, 0, 0, props.defectID);
        if (res.success) {
            setState((st) => ({ ...st, NoteList: res.data.list }));
        }
        if (!res.success) {
            showToastError(res.message);
        }
    };

    const pullSAGridList = async () => {
        let res = await getServiceAgentJobTracking(props.agentNameID, props.NoteID);
        if (res.success) {
            setState((st) => ({ ...st, serviceAgentJobTrackingList: res.data.list }));
        }
        if (!res.success) {
            showToastError(res.message);
        }
    };

    useEffect(async () => {
        if (props.frompage) {
            setState((st) => ({ ...st, noteVehicleID: props.NoteVehicleID, noteRefsID: props.noteRefsID, noteRefsGuidID: props.noteRefsGuidID }));
        }
        if (props.NoteID) {
            let res = await getNoteById(props.NoteID, props.frompage, props.NoteVehicleID, props.noteRefsID);
            const Notedata = res.data;
            setState((st) => ({
                ...st,
                noteID: Notedata.noteID,
                noteActive: Notedata.noteActive,
                noteDescription: Notedata.noteDescription,
                noteTitle: Notedata.noteTitle,
                noteStickyDisplayType: Notedata.noteStickyDisplayType,
                noteStickyDisplayFromDate: Notedata.noteStickyDisplayFromDate ? moment(Notedata.noteStickyDisplayFromDate).format('YYYY-MM-DDTHH:mm') : '',
                noteStickyDisplayEndDate: Notedata.noteStickyDisplayEndDate
                    ? moment(Notedata.noteStickyDisplayEndDate).format('YYYY-MM-DDTHH:mm')
                    : moment().format('YYYY-MM-DDTHH:mm'),
                driverName: Notedata.driverName,
                noteSendSMSNumber: Notedata.driverPhone,
                newNoteSendSMSNumber: Notedata.driverPhone,
                reportedBy: Notedata.reportedBy,
                noteSendSMSReportedNo: Notedata.reportedPhone,
                newNoteSendSMSReportedNo: Notedata.reportedPhone
            }));
        }
    }, [props.NoteVehicleID]);

    const validations = () => {
        const { noteTitle, noteDescriptionOne, noteStickyDisplayEndDate, noteSendSMS, noteSendSMSNumber, noteSendSMSReported, noteSendSMSReportedNo } = state;
        let formIsValid = true;
        let errors = {};
        if (!/[^\s]/.test(noteDescriptionOne)) {
            errors.noteDescriptionOne = 'The progress result field is required';
            formIsValid = false;
        }
        if (!/[^\s]/.test(noteTitle)) {
            errors.noteTitle = 'The title field is required';
            formIsValid = false;
        }
        if (state.noteID && !/[^\s]/.test(noteStickyDisplayEndDate)) {
            errors.noteStickyDisplayEndDate = 'End Date is required';
            formIsValid = false;
        }
        if (noteSendSMS) {
            if (!noteSendSMSNumber) {
                errors.noteSendSMSNumber = 'Phone Number is required';
                formIsValid = false;
            }
        }
        if (noteSendSMSReported) {
            if (!noteSendSMSReportedNo) {
                errors.noteSendSMSReportedNo = 'Phone Number is required';
                formIsValid = false;
            }
        }
        setState((st) => ({ ...st, errors: errors }));
        return formIsValid;
    };

    const newValidations = () => {
        const { newNoteTitle, newNoteDescription, noteSendSMS, noteSendSMSNumber, newNoteStickyDisplayFromDate, noteSendSMSReported, noteSendSMSReportedNo } =
            state;
        let formIsValid = true;
        let errors = {};
        if (!/[^\s]/.test(newNoteDescription)) {
            errors.newNoteDescription = 'The reminder note field is required';
            formIsValid = false;
        }
        if (!/[^\s]/.test(newNoteTitle)) {
            errors.newNoteTitle = 'The title field is required';
            formIsValid = false;
        }
        if (!/[^\s]/.test(newNoteStickyDisplayFromDate)) {
            errors.newNoteStickyDisplayFromDate = 'The reminder start date field is required';
            formIsValid = false;
        }
        if (noteSendSMS) {
            if (!noteSendSMSNumber) {
                errors.noteSendSMSNumber = 'Phone Number is required';
                formIsValid = false;
            }
        }
        if (noteSendSMSReported) {
            if (!noteSendSMSReportedNo) {
                errors.noteSendSMSReportedNo = 'Phone Number is required';
                formIsValid = false;
            }
        }
        if (!validations()) {
            formIsValid = false;
        }
        setState((st) => ({ ...st, newErrors: errors }));
        return formIsValid;
    };

    const handleCheckbox = (e) => {
        const { name, checked } = e.target;
        setState((state) => {
            const nst = { ...state, [name]: checked };
            return nst;
        });
    };

    const resetInput = (name) => {
        setState((st) => ({ ...st, [name]: '' }));
    };
    const handleSubmit = async () => {
        let formData;
        formData = {
            noteTitle: state.noteTitle,
            noteDescription: state.noteDescription + '\n\n' + state.noteDescriptionOne,
            noteActive: state.noteActive,
            noteVehicleID: state.noteVehicleID,
            noteRefsGuidID: state.noteRefsGuidID,
            noteRefsID: state.noteRefsID,
            noteID: state.noteID,
            notefrompage: props.frompage,
            noteStickyDisplayType: 'D',
            noteStickyDisplayFromDate: state.noteStickyDisplayFromDate || '',
            noteStickyDisplayEndDate: state.noteStickyDisplayEndDate || ''
        };
        let number = '';
        if (state.noteSendSMS && state.noteSendSMSReported) {
            number = state.noteSendSMSReportedNo + ',' + state.noteSendSMSNumber;
        } else if (state.noteSendSMSReported) {
            number = state.noteSendSMSReportedNo;
        } else if (state.noteSendSMS) {
            number = state.noteSendSMSNumber;
        }
        if (props.frompage === 'Defects') {
            formData['noteSendSMSNumber'] = number;
            formData['noteSendSMS'] = state.noteSendSMS || state.noteSendSMSReported;
        }
        setBtnLoader((st) => ({ ...st, extBtnLoader: true }));
        let res = await addUpdateNote(formData);
        if (res.success) {
            showToastSuccess('Notes Added Successfully!');
            props.onFormSubmit(true);
        } else {
            showToastError(res.message);
        }
        setBtnLoader((st) => ({ ...st, extBtnLoader: false }));
    };

    const handleNewNoteSubmit = async () => {
        if (newValidations()) {
            handleSubmit();
            let formData;
            formData = {
                noteTitle: state.newNoteTitle,
                noteDescription: state.newNoteDescription,
                noteActive: state.noteActive,
                noteRefsGuidID: state.noteRefsGuidID,
                noteRefsID: state.noteRefsID,
                noteID: null,
                notefrompage: props.frompage,
                noteStickyDisplayType: 'D',
                noteStickyDisplayFromDate: state.newNoteStickyDisplayFromDate || '',
                noteStickyDisplayEndDate: state.newNoteStickyDisplayEndDate || ''
            };
            let number = '';
            if (state.newNoteSendSMS && state.newNoteSendSMSReported) {
                number = state.newNoteSendSMSReportedNo + ',' + state.newNoteSendSMSNumber;
            } else if (state.newNoteSendSMSReported) {
                number = state.newNoteSendSMSReportedNo;
            } else if (state.newNoteSendSMS) {
                number = state.newNoteSendSMSNumber;
            }
            if (props.frompage === 'Defects') {
                formData['noteSendSMSNumber'] = number;
                formData['noteSendSMS'] = state.newNoteSendSMS || state.newNoteSendSMSReported;
            }
            setBtnLoader((st) => ({ ...st, newBtnLoader: true }));
            let res = await addUpdateNote(formData);
            if (res.success) {
                showToastSuccess('Notes Added Successfully!');
                props.onFormSubmit(true);
            } else {
                showToastError(res.message);
            }
            setBtnLoader((st) => ({ ...st, newBtnLoader: false }));
        }
    };

    const inputChange = useCallback((e) => {
        const { name, value, type } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    }, []);

    const getAllDefectNotes = () => {
        pullNoteAndUpdateState();
        setState((st) => ({
            ...st,
            showAllNotes: true
        }));
    };
    const getAllSAList = () => {
        pullSAGridList();
        setState((st) => ({
            ...st,
            showSAList: true
        }));
    };

    const handleClose = () => {
        setState((st) => ({
            ...st,
            showSaModal: false
        }));
    };

    const serviceAgentDetailModal = () => {
        setState((st) => ({
            ...st,
            showSaModal: true
        }));
    };

    const closeConfirmationModal = (res) => {
        setState((st) => ({
            ...st,
            showConfirm: false
        }));
    };
    const confirmationModalHandler = (res) => {
        if (validations()) {
            setState((st) => ({
                ...st,
                showConfirm: true
            }));
        }
    };

    const RenderTitle = () => {
        return (
            <Grid item container xs={12} spacing={2}>
                <Grid item xs={6} container justify="space-between">
                    <Grid item>
                        <DialogTitle style={{ padding: 0 }}>
                            <span>
                                {props.titleModal}
                                <CustomChip style={{ marginLeft: 8 }} icon={AssignmentIcon} label="History" onClick={getAllDefectNotes} />
                                {props.agentName && <CustomChip style={{ marginLeft: 8 }} icon={AssignmentIcon} label="Other Jobs" onClick={getAllSAList} />}
                            </span>
                        </DialogTitle>
                    </Grid>
                </Grid>
                <Grid item xs={6} container justify="space-between">
                    <Grid item>
                        <DialogTitle style={{ padding: 0 }}>Next Progress Reminder</DialogTitle>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={props.onClose} style={{ marginRight: 7, padding: 0 }}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
        );
    };
    const JobSingleNote = (props) => {
        return (
            <div className="single-note">
                <div className="msg" style={{ fontSize: 14, fontStyle: 'italic' }}>
                    {props.note.note}
                </div>
                <div className="footer" style={{ fontSize: 12, color: 'grey', fontStyle: 'italic', textAlign: 'right' }}>
                    {props.note.createdBy} @ {moment(props.note.dateCreated).format('LLL')}
                </div>
            </div>
        );
    };
    return (
        <DialogWithoutStaticHeader title={RenderTitle()} onClose={props.onClose} aria-labelledby="simple-dialog-title" fullWidth maxWidth="lg">
            {state.showAllNotes && (
                <DialogComp
                    open={true}
                    onClose={() =>
                        setState((st) => ({
                            ...st,
                            showAllNotes: false
                        }))
                    }
                    title={'All Notes'}
                    maxWidth="lg"
                >
                    {state.NoteList?.map((n, i) => (
                        <>
                            <JobSingleNote key={i} note={n} />
                            <hr style={{ opacity: '30%' }} />
                        </>
                    ))}
                </DialogComp>
            )}
            {state.showSAList && (
                <DialogComp
                    open={true}
                    onClose={() =>
                        setState((st) => ({
                            ...st,
                            showSAList: false
                        }))
                    }
                    title={`All Notes - ${props.agentName}`}
                    maxWidth="lg"
                    fullWidth
                >
                    <DataGrid columns={serviceAgentJobTrackingListcolumns} rows={state.serviceAgentJobTrackingList || []} enableSearch={true} offset={435} />
                </DialogComp>
            )}
            <Grid container spacing={2} alignItems="flex-start" style={{ overflow: 'hidden', height: 'none' }}>
                <Grid item container xs={12} sm={6} spacing={2}>
                    {props.agentName && (
                        <Grid item xs={12} sm={12} className="mandatory-fields">
                            <InputLabel shrink>Service Agent</InputLabel>
                            <Typography
                                variant="body1"
                                style={{ whiteSpace: 'pre-line', cursor: 'pointer', fontWeight: 'bold' }}
                                onClick={serviceAgentDetailModal}
                            >
                                {props.agentName}
                            </Typography>
                        </Grid>
                    )}
                    <Grid item xs={12} sm={12} className="mandatory-fields">
                        <InputLabel shrink>{props.NoteID ? 'Existing' : ''} Reminder Notes</InputLabel>
                        <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>
                            {state.noteDescription}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} className="mandatory-fields">
                        <InputLabel shrink>Progress Result</InputLabel>
                        <InputText
                            multiline
                            rows={3}
                            placeholder="Progress Result"
                            value={state.noteDescriptionOne}
                            onChange={inputChange}
                            name="noteDescriptionOne"
                            autoFocus={true}
                        />
                        <FormHelperText error>{state.errors.noteDescriptionOne}</FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <InputLabel shrink>Reminder Start Date & Time</InputLabel>
                        <DateTimePicker
                            placeholder="Start Date & Time"
                            value={state.noteStickyDisplayFromDate}
                            onChange={inputChange}
                            name="noteStickyDisplayFromDate"
                            disabled={props.NoteID}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <InputLabel shrink>{props.NoteID ? 'Existing' : ''} Reminder End Date & Time</InputLabel>
                        <DateTimePicker
                            placeholder={`${props.NoteID ? 'Existing' : ''} End Date & Time`}
                            value={state.noteStickyDisplayEndDate}
                            onChange={inputChange}
                            name="noteStickyDisplayEndDate"
                            InputProps={{ inputProps: { min: `${state.noteStickyDisplayFromDate}` } }}
                        />
                        <FormHelperText error>{state.errors.noteStickyDisplayEndDate}</FormHelperText>
                    </Grid>
                    {props.frompage == 'Defects' && !props.NoteID && (
                        <>
                            {state.driverName && (
                                <Grid item xs={12}>
                                    <FlaggedBox
                                        label={`Send text to ${state.driverName} (Driver)`}
                                        chkName="noteSendSMS"
                                        chkValue={state.noteSendSMS}
                                        onCheckChange={handleCheckbox}
                                        txtName="noteSendSMSNumber"
                                        txtValue={state.noteSendSMSNumber}
                                        inputChange={inputChange}
                                        resetInput={resetInput}
                                        disabled={state.isDisable}
                                        fieldType="number"
                                    />
                                    <FormHelperText error>{state.errors.noteSendSMSNumber}</FormHelperText>
                                </Grid>
                            )}
                            {state.reportedBy && (
                                <Grid item xs={12}>
                                    <FlaggedBox
                                        label={`Send text to ${state.reportedBy} (Reported By)`}
                                        chkName="noteSendSMSReported"
                                        chkValue={state.noteSendSMSReported}
                                        onCheckChange={handleCheckbox}
                                        txtName="noteSendSMSReportedNo"
                                        txtValue={state.noteSendSMSReportedNo}
                                        inputChange={inputChange}
                                        resetInput={resetInput}
                                        disabled={state.isDisable}
                                        // fieldType="number"
                                    />
                                    <FormHelperText error>{state.errors.noteSendSMSReportedNo}</FormHelperText>
                                </Grid>
                            )}
                        </>
                    )}
                </Grid>
                <Divider orientation="vertical" flexItem style={{ marginRight: '-1px' }} />
                <Grid item container xs={12} sm={6} spacing={2}>
                    <Grid item xs={12} sm={12} className="mandatory-fields">
                        <InputLabel shrink>New Reminder Notes</InputLabel>
                        <InputText
                            multiline
                            rows={3}
                            placeholder="New Reminder Notes"
                            value={state.newNoteDescription}
                            onChange={inputChange}
                            name="newNoteDescription"
                            // autoFocus={state.noteTitle ? true : false}
                        />
                        <FormHelperText error>{state.newErrors.newNoteDescription}</FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={12} className="mandatory-fields">
                        <InputLabel shrink>Reminder Start Date & Time</InputLabel>
                        <DateTimePicker
                            placeholder="Reminder Start Date & Time"
                            value={state.newNoteStickyDisplayFromDate}
                            onChange={inputChange}
                            name="newNoteStickyDisplayFromDate"
                        />
                        <FormHelperText error>{state.newErrors.newNoteStickyDisplayFromDate}</FormHelperText>
                    </Grid>
                    {props.frompage == 'Defects' && (
                        <>
                            {state.driverName && (
                                <Grid item xs={12} style={{ marginBottom: 8 }}>
                                    <FlaggedBox
                                        label={`Send text to ${state.driverName} (Driver)`}
                                        chkName="newNoteSendSMS"
                                        chkValue={state.newNoteSendSMS}
                                        onCheckChange={handleCheckbox}
                                        txtName="newNoteSendSMSNumber"
                                        txtValue={state.newNoteSendSMSNumber}
                                        inputChange={inputChange}
                                        resetInput={resetInput}
                                        disabled={state.isDisable}
                                    />
                                    <FormHelperText error>{state.newErrors.newNoteSendSMSNumber}</FormHelperText>
                                </Grid>
                            )}
                            {state.reportedBy && (
                                <Grid item xs={12}>
                                    <FlaggedBox
                                        label={`Send text to ${state.reportedBy} (Reported By)`}
                                        chkName="newNoteSendSMSReported"
                                        chkValue={state.newNoteSendSMSReported}
                                        onCheckChange={handleCheckbox}
                                        txtName="newNoteSendSMSReportedNo"
                                        txtValue={state.newNoteSendSMSReportedNo}
                                        inputChange={inputChange}
                                        resetInput={resetInput}
                                        disabled={state.isDisable}
                                        // fieldType="number"
                                    />
                                    <FormHelperText error>{state.newErrors.newNoteSendSMSReportedNo}</FormHelperText>
                                </Grid>
                            )}
                        </>
                    )}
                </Grid>
                <Grid item container xs={12} sm={6} spacing={2}>
                    <Grid item xs={12}>
                        <SecondaryButton isBtnLoader={btnLoader.extBtnLoader} onClick={confirmationModalHandler} fullWidth>
                            Close Reminder
                        </SecondaryButton>
                    </Grid>
                </Grid>
                <Grid item container xs={12} sm={6} spacing={2}>
                    <Grid item xs={12}>
                        <SecondaryButton isBtnLoader={btnLoader.newBtnLoader} disabled={!state.noteID} onClick={() => handleNewNoteSubmit(true)} fullWidth>
                            close & Create New Reminder
                        </SecondaryButton>
                    </Grid>
                </Grid>
            </Grid>
            {state.showSaModal ? (
                <DialogComp open={true} onClose={handleClose} title={'Service Agent Details'}>
                    <ServiceAgentDetails serviceAgentID={props.agentNameID} onFormSubmit={handleClose} />
                </DialogComp>
            ) : null}
            {state.showConfirm ? (
                <DialogComp title={`Close Reminder`} onClose={() => closeConfirmationModal(false)} maxWidth="sm">
                    <ConfirmationModal
                        message={`Are you sure to close remind and not create a new reminder?`}
                        handleSubmit={() => handleSubmit(false)}
                        handleCancel={() => closeConfirmationModal(false)}
                    />
                </DialogComp>
            ) : null}
        </DialogWithoutStaticHeader>
    );
};

export default AddEditJobsNotes;
