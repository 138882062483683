import { FormHelperText, Grid, InputLabel } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { DatePicker, SecondaryButton, SelectBox, SingleSelect } from '../../../../Core/Controls';
import { useToast } from '../../../../Core/Hooks/useToast';
import {
    getCustomersListDropdowns,
    getSchedulePlannerData,
    getSchedulePlannerDropdowns,
    getVehicleScreenDropdowns
} from '../../../../Core/Services/InternalUser/ControllerService/vehicleService';

const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
const endOfMonth = moment().endOf('month').format('YYYY-MM-DD');

const SchedulePlannerScreen = () => {
    const [state, setState] = useState({
        customerList: [],
        depotWithCustList: [],
        endUserWithCustList: [],
        serviceAgentsWithCustList: [],
        pdfData: '',
        fromDate: startOfMonth,
        toDate: endOfMonth,
        depotsID: '',
        customerID: '',
        scheduleEndUserIDValue: '',
        scheduleDepotsIDValue: '',
        scheduleCustomerIDValue: '',
        scheduleServiceAgentIDValue: '',
        isDisabled: false,
        endUserWildCard: ''
    });
    const [errors, setErrors] = useState({});
    const { showToastSuccess, showToastError } = useToast();

    useEffect(async () => {
        let resCust = await getCustomersListDropdowns();
        setState((st) => ({
            ...st,
            customerList: resCust.data.customer.table
        }));
    }, []);

    useEffect(async () => {
        let Depots = [];
        let endUsers = [];
        let serviceAgents = [];
        if (state.scheduleCustomerIDValue?.id) {
            let resCust = await getSchedulePlannerDropdowns(state.scheduleCustomerIDValue?.id);
            Depots = resCust.data.depots;
            endUsers = resCust.data.endUsers;
            serviceAgents = resCust.data.serviceAgents;
            setState((st) => ({
                ...st,
                depotWithCustList: Depots,
                scheduleDepotsIDValue: '',
                endUserWithCustList: endUsers,
                serviceAgentsWithCustList: serviceAgents
            }));
        } else {
            setState((st) => ({
                ...st,
                depotWithCustList: [],
                scheduleDepotsIDValue: '',
                endUserWithCustList: endUsers,
                scheduleEndUserIDValue: '',
                serviceAgentsWithCustList: serviceAgents,
                scheduleServiceAgentIDValue: ''
            }));
        }
    }, [state.scheduleCustomerIDValue?.id]);

    const downloadPdf = async (fileType) => {
        let res = await getSchedulePlannerData(
            state.fromDate,
            state.toDate,
            state.scheduleCustomerIDValue?.id || '',
            state.scheduleDepotsIDValue?.id || '',
            state.scheduleServiceAgentIDValue?.id || '',
            state.scheduleEndUserIDValue?.id || '',
            fileType,
            state.endUserWildCard
        );
        if (res) {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.getElementById('tempDownloadPDFLink') || document.createElement('a');
            link.id = 'tempDownloadPDFLink';
            link.href = url;
            link.setAttribute('download', `SchedulePlanner${moment().format('DD-MMM-YYYY HH:mm')}.${fileType}`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            return true;
        }
    };

    const inputChange = (e) => {
        const { name, value } = e.target;
        setState((st) => {
            let newSt = { ...st, [name]: value };
            // newSt.isDisabled = newSt.customerID || false;
            return newSt;
        });
    };

    const singleSelectChange = (nm) => (ev, val) => {
        setState((st) => {
            let newSt = { ...st };
            if (nm == 'scheduleEndUserIDValue' && typeof val != 'object') {
                newSt.endUserWildCard = val;
            } else {
                newSt[nm] = val;
            }
            newSt.isDisabled = newSt.scheduleCustomerIDValue || false;
            return newSt;
        });
    };

    return (
        <div className="screen">
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={10} lg={8} xl={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={8} className="mandatory-fields">
                            <InputLabel required shrink>
                                Select Customer
                            </InputLabel>
                            <SingleSelect
                                options={state.customerList}
                                value={state.scheduleCustomerIDValue}
                                onChange={singleSelectChange('scheduleCustomerIDValue')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <InputLabel shrink>Select Depots</InputLabel>
                            <SingleSelect
                                options={state.depotWithCustList}
                                value={state.scheduleDepotsIDValue}
                                onChange={singleSelectChange('scheduleDepotsIDValue')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={8}>
                            <InputLabel shrink>Service Agent</InputLabel>
                            <SingleSelect
                                options={state.serviceAgentsWithCustList}
                                value={state.scheduleServiceAgentIDValue}
                                onChange={singleSelectChange('scheduleServiceAgentIDValue')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <InputLabel shrink>End User</InputLabel>
                            <SingleSelect
                                options={state.endUserWithCustList}
                                value={state.scheduleEndUserIDValue}
                                freeSolo={true}
                                onChange={singleSelectChange('scheduleEndUserIDValue')}
                            />
                        </Grid>
                        <Grid item className="date_range" xs={12} sm={6} md={4}>
                            <InputLabel shrink>From</InputLabel>
                            <DatePicker
                                placeholder="From"
                                value={state.fromDate}
                                onChange={inputChange}
                                name="fromDate"
                                // InputProps={{ inputProps: { max: `${state.toDate}` } }}
                                focused={state.fromDate ? true : false}
                            />
                        </Grid>
                        <Grid item className="date_range" xs={12} sm={6} md={4}>
                            <InputLabel shrink>To</InputLabel>
                            <DatePicker
                                placeholder="To"
                                value={state.toDate}
                                onChange={inputChange}
                                name="toDate"
                                InputProps={{ inputProps: { min: `${state.fromDate}` } }}
                                focused={state.toDate ? true : false}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '10px' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <SecondaryButton onClick={() => downloadPdf('pdf')} disabled={!state.isDisabled}>
                                    Get Schedule planner PDF
                                </SecondaryButton>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <SecondaryButton onClick={() => downloadPdf('xlsx')} disabled={!state.isDisabled}>
                                    Get Schedule planner Excel
                                </SecondaryButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {/* <Grid item xs={12} sm={6}>
                    <Grid container alignItems="center">
                        <SecondaryButton onClick={downloadPdf} disabled={!state.isDisabled}>
                            Get Schedule planner
                        </SecondaryButton>
                    </Grid>
                </Grid> */}
            </Grid>
        </div>
    );
};

export default SchedulePlannerScreen;
