import { SimpleField, SecondaryButton } from '../../../../../Core/Controls';
import { useToast } from '../../../../../Core/Hooks/useToast';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Grid, Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import { postArchiveCustomerLicence } from '../../../../../Core/Services/Admin/licenceCheck';
import { FromLoader } from '../../../../../Core/Loader/formLoader';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
const StyledTableCell = withStyles((theme) => ({
    head: {
        fontSize: 16,
        padding: '8px 20px',
        color: '#f50057'
    },
    body: {
        fontSize: 14,
        padding: '8px 20px'
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: '#F5F5F5'
        }
    }
}))(TableRow);

export const CreditTopup = (props) => {
    const { showToastSuccess, showToastError } = useToast();
    const submitArchive = async () => {
        let res = await postArchiveCustomerLicence(props.accountCode);
        if (res.success) {
            showToastSuccess('Company Remove from successfully');
            props.onClose(true);
        } else {
            showToastError(res.message);
        }
    };
    return (
        <Grid item container spacing={2} md={12} xs={12} lg={12}>
            <Grid item xs={12}>
                Are you sure to archive the account in licence check system? You will not be able to restore the account.
            </Grid>
            <Grid item xs={6}>
                <SecondaryButton fullWidth className="submit_btn" onClick={submitArchive}>
                    Confirm
                </SecondaryButton>
            </Grid>
            <Grid item xs={6}>
                <SecondaryButton onClick={() => props.onClose(false)} fullWidth className="submit_btn">
                    Cancel
                </SecondaryButton>
            </Grid>
        </Grid>
    );
};

export const LicenceResponseTable = (props) => {
    return (
        <div>
            {!props.showLoader ? (
                <div>
                    <TableContainer component={Paper} className="custom-scroll">
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell width="30%">Depots</StyledTableCell>
                                    <StyledTableCell width="30%"> Status</StyledTableCell>
                                    <StyledTableCell width="30%"> Location</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.resList.davisDepots.length > 0 &&
                                    props.resList.davisDepots.map((m) => {
                                        return (
                                            <StyledTableRow>
                                                <StyledTableCell>{m.depotName}</StyledTableCell>
                                                <StyledTableCell>
                                                    {m.davissuccess ? (
                                                        <span style={{ color: 'green' }}>Depot Added Successfully</span>
                                                    ) : (
                                                        <span style={{ color: 'red' }}> Depot Fail To Add </span>
                                                    )}
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    {m.setLicenceCheckLocationIdSuccess ? (
                                                        <span style={{ color: 'green' }}>Location Added Successfully</span>
                                                    ) : (
                                                        <span style={{ color: 'red' }}> Location Fail to Add </span>
                                                    )}
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <br />
                    <TableContainer component={Paper} className="custom-scroll">
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell width="50%">Department</StyledTableCell>
                                    <StyledTableCell width="50%"> Status</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.resList.davisDepartmentResponse.length > 0 &&
                                    props.resList.davisDepartmentResponse.map((m) => {
                                        return (
                                            <StyledTableRow>
                                                <StyledTableCell>{m.departmentName}</StyledTableCell>
                                                <StyledTableCell>
                                                    {m.davissuccess ? (
                                                        <span style={{ color: 'green' }}> Department Successfully</span>
                                                    ) : (
                                                        <span style={{ color: 'red' }}> Department Fail To Add </span>
                                                    )}
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <br />
                </div>
            ) : (
                <div style={{ width: '100%' }}>
                    <FromLoader />
                </div>
            )}
            <SecondaryButton onClick={() => props.onClose(true, true)} fullWidth className="submit_btn">
                Cancel
            </SecondaryButton>
        </div>
    );
};
