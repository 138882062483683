import React, { useState, useEffect, useRef } from 'react';
import {
    FailButton,
    InputText,
    MandatoryField,
    NAButton,
    PassButton,
    SecondaryButton,
    SelectBox,
    MButton,
    UploadWithBadge
} from '../../../../../Core/Controls';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
    Grid,
    Paper,
    InputLabel,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Button,
    Typography,
    Tooltip,
    CircularProgress,
    FormHelperText,
    Badge
} from '@material-ui/core';
import { AppStorage } from '../../../../../Core/Basic/storage-service';
import CreateIcon from '@material-ui/icons/Create';
import SignaturePad from 'react-signature-canvas';
import '../../controllerStyle.scss';
import { getVehicleById } from '../../../../../Core/Services/InternalUser/ControllerService/vehicleService';
import { getvehiclecheckchecksById, postvehiclecheckchecksData } from '../../../../../Core/Services/InternalUser/Vehicle/driverVehicle';
import moment from 'moment';
import DeleteIcon from '@material-ui/icons/Delete';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { useLoginInfo } from '../../../../../Core/Hooks/useLoginInfo';
import { useToast } from '../../../../../Core/Hooks/useToast';
import { getTitleData } from '../../../../../Core/Services/InternalUser/ControllerService/vehicleService';
import { BreadCrumbs } from '../../../../../Core/Controls';
import ImageModal from './imageModal';
import { useWindowSize } from '../../../../../Core/Hooks/useWindowSize';
import { DeleteImageByID } from '../../../../../Core/Services/InternalUser/Vehicle/driverVehicle';

const StyledTableCell = withStyles((theme) => ({
    head: {
        fontSize: 16,
        padding: '8px 20px',
        color: '#f50057'
    },
    body: {
        fontSize: 14,
        padding: '8px 20px'
    }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: '#F5F5F5'
        }
    }
}))(TableRow);
const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: '25px',
        color: '#f50057',
        fontWeight: 600,
        paddingLeft: 9
    },
    loader: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 300
    },
    sign: {
        border: '1px solid grey',
        height: 240
    }
}));

const CheckButtons = {
    1: PassButton,
    2: FailButton,
    3: NAButton,
    5: MButton,
    6: UploadWithBadge
};

const DriverDailyChecks = (props) => {
    const [width, height] = useWindowSize();
    const maxNumber = 69;
    const { showToastSuccess, showToastError } = useToast();
    const { displayName } = useLoginInfo();
    const classes = useStyles();
    const [state, setState] = useState({
        vehicleID: props.VehicleID,
        date: moment().format('DD-MM-YYYY'),
        vehicleDailyCheckDateStarted: moment().format('YYYY-MM-DD HH:mm:ss'),
        vehicleDailyCheckCheckTypeID: 1,
        vehicleDailyCheckStatusId: null,
        vehicleDailyCheckDateExpire: null,
        vehicleDailyCheckDriverComment: '',
        vehicleDailyCheckDriverId: null,
        vehicleDailyCheckId: null,
        selectAll: false,
        signAvailable: AppStorage.getSignature() === 'absent' ? false : true,
        vehicleDailyCheckCheckerName: displayName,
        desc: '',
        imageUpload: []
    });

    const { userRoles } = useLoginInfo();
    // const isDriver = userRoles.includes('driver');

    useEffect(async () => {
        if (props.VehicleID) {
            let res2 = await getVehicleById(props.VehicleID);
            if (res2.success) {
                const data = res2.data;
                setState((st) => ({
                    ...st,
                    regNumber: data.vehicleRegNumber,
                    modelID: data.vehicleModel,
                    makeID: data.vehicleMake
                }));
            }
            let res = await getvehiclecheckchecksById(props.VehicleID);
            if (res.success) {
                const reportedUser = res.data.reportedUser.map((x) => ({
                    id: x.id,
                    name: x.name
                }));
                const checkType = res.data.dailyCheckTypes.map((m) => ({
                    id: m.id,
                    name: m.name
                }));
                const checkQueList = res.data.driverDailyCheckCheckSections;
                const checkOptions = res.data.driverDailyCheckOptions;

                setState((st) => ({
                    ...st,
                    checklist: checkQueList,
                    checkOptions: checkOptions,
                    checkType: checkType,
                    reportedUser: reportedUser
                }));
            }
        }
        pullDetails();
    }, []);

    const pullDetails = async () => {
        let res = await getTitleData(props.VehicleID);
        if (res.success) {
            let data = res.data.details[0];
            setState((st) => ({
                ...st,
                desc: data?.vehicleDescription
                // vorID: data?.vorVehicleID,
                // loader: false,
                // regNumber: data?.vehicleDescription.split('-')[0].trim()
            }));
        }
    };

    const handleButton = (id) => {
        setState((st) => ({
            ...st,
            vehicleDailyCheckCheckTypeID: id
        }));
    };

    const signCanvas = useRef({});
    const clear = () => {
        if (state.imgtechniciansign) {
            setState((st) => ({
                ...st,
                imgtechniciansign: false,
                techniciansign: null
            }));
        } else {
            signCanvas.current.clear();
        }
    };

    const GetTechnicianSign = () => {
        let fields = state.fields;
        setState((st) => {
            const nst = {
                ...st,
                techniciansign: signCanvas.current.toDataURL(),
                fields: fields
            };
            return nst;
        });
    };

    const handleInputs = (e) => {
        const { name, value } = e.target;
        setState((st) => ({
            ...st,
            [name]: value
        }));
    };

    const checkAll = (passId) => (e) => {
        const c = e.target.checked;
        setState((st) => {
            const newSt = { ...st };
            newSt.selectAll = true;
            newSt.checklist.map((x) => {
                x.vehicleDailyCheckResultsVehicleDailyCheckOptionId = c ? passId : undefined;
            });

            newSt.checklist = [...newSt.checklist];
            return newSt;
        });
    };

    const handleButtonChange = (buttonVal, checkId) => {
        let changedArray = [...state.checklist];
        let objIndex = state.checklist.findIndex((x) => x.dailyCheckSectionID === checkId);
        changedArray[objIndex].vehicleDailyCheckResultsVehicleDailyCheckOptionId = buttonVal;
        let val = changedArray.filter((c) => c.vehicleDailyCheckResultsVehicleDailyCheckOptionId !== 1).length === 0;
        setState((st) => ({ ...st, checklist: changedArray, selectAll: val }));
    };

    const handleNote = (id) => (e) => {
        let changedArray = [...state.checklist];
        let objIndex = state.checklist.findIndex((x) => x.dailyCheckSectionID === id);
        changedArray[objIndex][e.target.name] = e.target.value;
        setState((st) => ({ ...st, checklist: changedArray }));
    };

    const useDefaultSig = () => {
        setState((st) => ({ ...st, imgtechniciansign: true, techniciansign: AppStorage.getSignature() }));
    };

    const validations = () => {
        let formIsValid = true;
        let errors = {};
        if (!state.vehicleDailyCheckReportedUserID) {
            errors.vehicleDailyCheckReportedUserID = 'Reported User is required';
            formIsValid = false;
        }
        if (!state.vehicleDailyCheckMileage) {
            errors.vehicleDailyCheckMileage = `Odometer Reading  is required`;
            formIsValid = false;
        }
        if (!state.vehicleDailyCheckDriverName) {
            errors.vehicleDailyCheckDriverName = 'Driver Name is required';
            formIsValid = false;
        }
        if (!state.techniciansign) {
            errors.techniciansign = 'Driver/Checker Signature is required';
            formIsValid = false;
        }
        setState((st) => ({
            ...st,
            errors: errors
        }));
        return formIsValid;
    };

    const handleSubmit = async () => {
        let driverDailyCheckResultsVM = state.checklist.map((m) => ({
            vehicleDailyCheckSectionID: m.dailyCheckSectionID ? m.dailyCheckSectionID : null,
            vehicleDailyCheckResultsVehicleDailyCheckOptionId: m.vehicleDailyCheckResultsVehicleDailyCheckOptionId
                ? m.vehicleDailyCheckResultsVehicleDailyCheckOptionId
                : null,
            vehicleDailyCheckResultsNotes: m.vehicleDailyCheckResultsNotes ? m.vehicleDailyCheckResultsNotes : null,
            vehicleDailyCheckResultsAssesementNotes: m.vehicleDailyCheckResultsAssesementNotes ? m.vehicleDailyCheckResultsAssesementNotes : null,
            vehicleDailyCheckResultsDefectID: m.vehicleDailyCheckResultsDefectID ? m.vehicleDailyCheckResultsDefectID : null,
            vehicleDailyCheckResultsStatusId: m.vehicleDailyCheckResultsStatusId ? m.vehicleDailyCheckResultsStatusId : null
        }));
        if (validations()) {
            let formData = {
                vehicleID: state.vehicleID,
                vehicleDailyCheckId: state.vehicleDailyCheckId,
                vehicleDailyCheckStatusId: state.vehicleDailyCheckStatusId,
                vehicleDailyCheckCheckTypeID: state.vehicleDailyCheckCheckTypeID,
                vehicleDailyCheckDateStarted: state.vehicleDailyCheckDateStarted,
                vehicleDailyCheckDateExpire: state.vehicleDailyCheckDateExpire,
                vehicleDailyCheckReportedUserID: state.vehicleDailyCheckReportedUserID,
                vehicleDailyCheckDriverName: state.vehicleDailyCheckDriverName,
                vehicleDailyCheckDriverId: state.vehicleDailyCheckDriverId,
                vehicleDailyCheckMileage: state.vehicleDailyCheckMileage,
                vehicleDailyCheckDriverComment: state.vehicleDailyCheckDriverComment,
                vehicleDailyCheckCheckerName: state.vehicleDailyCheckCheckerName,
                driverDailyCheckResultsVM: driverDailyCheckResultsVM,
                vehicleDailyCheckCreatorSignature: state.techniciansign,
                driverDailyCheckPhotoVM: state.imageUpload
            };
            let res = await postvehiclecheckchecksData(formData);
            if (res.success) {
                showToastSuccess(res.message);
                props.onClose(true);
            } else {
                showToastError(res.message);
                // props.onClose(false);
            }
        }
    };

    const sumbitImage = () => {
        if (state.selectedImages.length > 0) {
            let changedArray = [...state.checklist];
            let objIndex = state.checklist.findIndex((x) => x.dailyCheckSectionID === state.checkSectionId);
            changedArray[objIndex].basicCount = changedArray[objIndex].images.length;
            showToastSuccess('image Uploaded successfully');
            setState((st) => ({
                ...st,
                show: false,
                checklist: changedArray
            }));
            showToastSuccess('Images Uploaded successfully');
        }
        // setState((st) => ({ ...st, show: false }));
    };

    const crumbs = [
        { name: 'Home', path: '/' },
        { name: `Vehicles`, onClick: props.onClose, path: '/', active: true },
        { name: `Add Checks`, path: '/', active: true }
    ];

    const imageHanlder = (checkSectionId) => {
        let changedArray = [...state.checklist];
        let objIndex = state.checklist.findIndex((x) => x.dailyCheckSectionID === checkSectionId);
        setState((st) => ({ ...st, show: true, checkSectionId: checkSectionId, selectedImages: changedArray[objIndex].images || [] }));
    };

    const onImageChange = (imageList, addUpdateIndex) => {
        let changedArray = [...state.checklist];
        let FilterimagesList;
        let objIndex = state.checklist.findIndex((x) => x.dailyCheckSectionID === state.checkSectionId);

        if (`${objIndex}`) {
            FilterimagesList = imageList.map((m) => (m.data_url ? m.data_url : m));
            changedArray[objIndex].images = FilterimagesList;
        }

        let tempArray = [...state.imageUpload];
        tempArray.push({ inspectionSheetCheckCheckID: state.checkSectionId, inspectionSheetCheckPhotos: FilterimagesList });

        setState((st) => ({ ...st, checklist: changedArray, selectedImages: FilterimagesList, imageUpload: tempArray }));
    };

    return (
        <div style={{ marginTop: 12 }}>
            {!props.VehicleID && <BreadCrumbs crumbs={crumbs} />}
            {state.desc ? (
                <Grid container spacing={3}>
                    {/* Disabled fields........... */}
                    {/* {isDriver ? (
                        <Grid item>
                            <Typography variant="h5">{state.desc?.toUpperCase() || ''}</Typography>
                        </Grid>
                    ) : null} */}

                    {/* Checks quetion table.......... */}
                    <Grid item xs={12} md={12}>
                        <TableContainer component={Paper}>
                            <div
                                style={{
                                    border: '1px solid rgba(0,0,0,0.2)',
                                    padding: '10px 11px'
                                }}
                            >
                                {/* <span className={classes.title}>Check Type</span> */}
                                {/* <span className={classes.title}>{state.desc?.toUpperCase() || ''}</span> */}

                                {state.checkType.map((m) => {
                                    return (
                                        <Button
                                            style={{ width: '10px', marginLeft: m.id == 1 ? 0 : 15, marginBottom: 4 }}
                                            variant={state.vehicleDailyCheckCheckTypeID === m.id ? 'contained' : 'outlined'}
                                            color="secondary"
                                            onClick={() => handleButton(m.id)}
                                        >
                                            {m.name}
                                        </Button>
                                    );
                                })}
                                <span className={classes.title} style={{ position: width < 381 ? 'static' : 'absolute', right: '46px' }}>
                                    {state.date}
                                </span>
                            </div>
                            <Table aria-label="simple table" className={classes.paper}>
                                <TableHead>
                                    <TableRow>
                                        {width > 560 && <StyledTableCell width="30%">Check</StyledTableCell>}
                                        {width < 771 ? (
                                            <StyledTableCell align="left">
                                                <input type="checkbox" onClick={checkAll(1)} />
                                                {width > 560 ? 'Action' : 'Checks'}
                                            </StyledTableCell>
                                        ) : (
                                            <>
                                                {state.checkOptions.map((m) => {
                                                    return (
                                                        <StyledTableCell
                                                            align="center"
                                                            width={m.vehicleDailyCheckOptionDescription.toLowerCase() === 'pass' ? '7%' : '6%'}
                                                        >
                                                            {m.vehicleDailyCheckOptionDescription.toLowerCase() === 'pass' ? (
                                                                <input type="checkbox" onClick={checkAll(m.vehicleDailyCheckOptionId)} />
                                                            ) : (
                                                                ''
                                                            )}
                                                            {m.vehicleDailyCheckOptionDescription}
                                                        </StyledTableCell>
                                                    );
                                                })}
                                                <StyledTableCell align="left" width="6%">
                                                    Upload
                                                </StyledTableCell>
                                                <StyledTableCell align="left" width="49%">
                                                    Notes/Defect
                                                </StyledTableCell>
                                            </>
                                        )}
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {state.checklist.map((x) => {
                                        return (
                                            <StyledTableRow>
                                                {width > 560 && <StyledTableCell component="th">{x.dailyCheckSectionDescription}</StyledTableCell>}

                                                {width < 771 ? (
                                                    <StyledTableCell>
                                                        <Grid container>
                                                            {width < 560 && (
                                                                <Grid item xs={12} style={{ display: 'flex' }}>
                                                                    {x.dailyCheckSectionDescription}
                                                                </Grid>
                                                            )}
                                                            <Grid item xs={12} style={{ display: 'flex' }}>
                                                                <>
                                                                    {state.checkOptions.map((m) => {
                                                                        const Comp = CheckButtons[m.vehicleDailyCheckOptionId] || Button;
                                                                        return (
                                                                            <Comp
                                                                                style={{ margin: '0px 3px' }}
                                                                                onClick={() =>
                                                                                    handleButtonChange(m.vehicleDailyCheckOptionId, x.dailyCheckSectionID)
                                                                                }
                                                                                state={
                                                                                    x.vehicleDailyCheckResultsVehicleDailyCheckOptionId ===
                                                                                    m.vehicleDailyCheckOptionId
                                                                                }
                                                                            >
                                                                                <Badge>{m.vehicleDailyCheckOptionDescription[0]}</Badge>
                                                                            </Comp>
                                                                        );
                                                                    })}
                                                                    <UploadWithBadge
                                                                        count={x.basicCount}
                                                                        state={x.basicCount > 0}
                                                                        onClick={() => imageHanlder(x.dailyCheckSectionID)}
                                                                        style={{ marginLeft: '2px' }}
                                                                    />
                                                                </>
                                                            </Grid>

                                                            <Grid item xs={12} style={{ marginTop: 5 }}>
                                                                {' '}
                                                                <InputText
                                                                    error={
                                                                        x.vehicleDailyCheckResultsVehicleDailyCheckOptionId === 2 ||
                                                                        x.vehicleDailyCheckResultsVehicleDailyCheckOptionId === 5
                                                                    }
                                                                    placeholder="Notes/Defect"
                                                                    name="vehicleDailyCheckResultsNotes"
                                                                    onChange={handleNote(x.dailyCheckSectionID)}
                                                                    value={x.vehicleDailyCheckResultsNotes}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </StyledTableCell>
                                                ) : (
                                                    <>
                                                        {state.checkOptions.map((m) => {
                                                            const Comp = CheckButtons[m.vehicleDailyCheckOptionId] || Button;
                                                            return (
                                                                <StyledTableCell align="center">
                                                                    <Comp
                                                                        onClick={() => handleButtonChange(m.vehicleDailyCheckOptionId, x.dailyCheckSectionID)}
                                                                        state={
                                                                            x.vehicleDailyCheckResultsVehicleDailyCheckOptionId === m.vehicleDailyCheckOptionId
                                                                        }
                                                                    >
                                                                        <Badge>{m.vehicleDailyCheckOptionDescription[0]}</Badge>
                                                                    </Comp>
                                                                </StyledTableCell>
                                                            );
                                                        })}

                                                        <StyledTableCell align="left">
                                                            <UploadWithBadge
                                                                count={x.basicCount}
                                                                state={x.basicCount > 0}
                                                                onClick={() => imageHanlder(x.dailyCheckSectionID)}
                                                            />
                                                        </StyledTableCell>
                                                        <StyledTableCell align="left">
                                                            <InputText
                                                                error={
                                                                    x.vehicleDailyCheckResultsVehicleDailyCheckOptionId === 2 ||
                                                                    x.vehicleDailyCheckResultsVehicleDailyCheckOptionId === 5
                                                                }
                                                                placeholder="Notes/Defect"
                                                                name="vehicleDailyCheckResultsNotes"
                                                                onChange={handleNote(x.dailyCheckSectionID)}
                                                                value={x.vehicleDailyCheckResultsNotes}
                                                            />
                                                        </StyledTableCell>
                                                    </>
                                                )}
                                            </StyledTableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    {/*driver details...................*/}
                    <Grid item xs={12} style={{ marginTop: 12 }}>
                        <Grid container spacing={3}>
                            <Grid item container spacing={1} xs={12} md={6}>
                                <Grid item xs={12} sm={12} md={12} maxwidth="sm">
                                    <InputLabel shrink error>
                                        Defect Reported to *
                                    </InputLabel>
                                    <SelectBox
                                        onChange={handleInputs}
                                        value={state.vehicleDailyCheckReportedUserID}
                                        name="vehicleDailyCheckReportedUserID"
                                        List={state.reportedUser}
                                    />
                                    <FormHelperText error>{state.errors?.vehicleDailyCheckReportedUserID}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} maxwidth="sm" className="mandatory-fields">
                                    <MandatoryField
                                        inputLabel="Odometer Reading"
                                        placeholder="Odometer Reading"
                                        name="vehicleDailyCheckMileage"
                                        value={state.vehicleDailyCheckMileage}
                                        onChange={handleInputs}
                                        errors={state.errors?.vehicleDailyCheckMileage}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} maxwidth="sm" className="mandatory-fields">
                                    <MandatoryField
                                        inputLabel="Driver Name"
                                        placeholder="Driver Name"
                                        name="vehicleDailyCheckDriverName"
                                        value={state.vehicleDailyCheckDriverName}
                                        onChange={handleInputs}
                                        errors={state.errors?.vehicleDailyCheckDriverName}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} maxwidth="sm">
                                    <InputLabel shrink>Checker Name</InputLabel>
                                    <InputText
                                        placeholder="Checker Name"
                                        name="vehicleDailyCheckCheckerName"
                                        value={state.vehicleDailyCheckCheckerName}
                                        onChange={handleInputs}
                                    />
                                </Grid>
                            </Grid>

                            <Grid item container xs={12} md={6}>
                                <Grid item xs={12} sm={12} md={12} className="mandatory-fields">
                                    <div>
                                        <div style={{ position: 'relative' }}>
                                            <InputLabel shrink>
                                                Driver/Checker Signature
                                                {state.signAvailable ? (
                                                    <Tooltip title="Use default signature">
                                                        <BorderColorIcon onClick={useDefaultSig} style={{ cursor: 'pointer', margin: '0px 0px -5px 5px' }} />
                                                    </Tooltip>
                                                ) : null}
                                            </InputLabel>
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    right: 0
                                                }}
                                            >
                                                <Tooltip title="Clear Signature">
                                                    <DeleteIcon
                                                        onClick={clear}
                                                        style={{
                                                            cursor: 'pointer',
                                                            marginTop: 5
                                                        }}
                                                    />
                                                </Tooltip>
                                            </div>
                                            {state.imgtechniciansign ? (
                                                <div className={classes.sign}>
                                                    <img src={state.techniciansign} alt="my signature" className="sigPad" />
                                                </div>
                                            ) : (
                                                <div className={classes.sign}>
                                                    <SignaturePad
                                                        ref={signCanvas}
                                                        canvasProps={{ className: 'sigPad' }}
                                                        onEnd={() => {
                                                            GetTechnicianSign();
                                                        }}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        <FormHelperText error>{state.errors?.techniciansign}</FormHelperText>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <SecondaryButton onClick={handleSubmit}>Submit</SecondaryButton>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SecondaryButton onClick={() => props.onClose()}>Cancel</SecondaryButton>
                    </Grid>
                </Grid>
            ) : (
                <div className={classes.loader}>
                    <CircularProgress color="secondary" />
                </div>
            )}
            {state.show ? (
                // <DialogComp title="Upload Image" onClose={() => setState((st) => ({ ...st, show: false }))} maxWidth="lg">
                <ImageModal
                    images={state.selectedImages}
                    onImageChange={onImageChange}
                    submitImage={sumbitImage}
                    onClose={() => setState((st) => ({ ...st, show: false }))}
                    maxNumber={maxNumber}
                />
            ) : // </DialogComp>
            null}
        </div>
    );
};

export default DriverDailyChecks;
