import React from 'react';
import Grid from '@material-ui/core/Grid';
import { InputLabel, FormHelperText } from '@material-ui/core';
import { DatePicker, SimpleField } from '../../../../../Core/Controls';
import '../../controllerStyle.scss';

const DateFieldsModal = (props) => {
    const { dateFields, dateFieldsChange, otherFieldsChange, otherFields, state } = props;

    const isDisabled = props.state.VehicleID ? true : false;

    return (
        <>
            <Grid item xs={12} md={12} lg={12} className="grid-paper-shadow">
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={4} lg={3} className="mandatory-fields">
                        <InputLabel required shrink>
                            Date Of Reg
                        </InputLabel>
                        <DatePicker
                            placeholder="Date Of Reg"
                            value={dateFields.dateOfReg}
                            onChange={dateFieldsChange}
                            name="dateOfReg"
                        />
                        <FormHelperText error>{otherFields.errors.dateOfReg}</FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} className="mandatory-fields">
                        <InputLabel required shrink>
                            Fleet on Date
                        </InputLabel>
                        <DatePicker
                            placeholder="Fleet on Date"
                            value={dateFields.fleetDate}
                            onChange={dateFieldsChange}
                            name="fleetDate"
                        />
                        <FormHelperText error>{otherFields.errors.fleetDate}</FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <InputLabel shrink>Tax Due</InputLabel>
                        <DatePicker
                            placeholder="Tax Due"
                            value={dateFields.taxDue}
                            onChange={dateFieldsChange}
                            name="taxDue" disabled={isDisabled}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <InputLabel shrink>MOT Expiry</InputLabel>
                        <DatePicker
                            placeholder="MOT Expiry"
                            value={dateFields.motExpiry}
                            onChange={dateFieldsChange}
                            name="motExpiry" disabled={isDisabled}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <InputLabel required shrink>
                            Next Inspection Date
                        </InputLabel>
                        <DatePicker
                            placeholder="Next Inspection Date"
                            value={dateFields.vehicleNextInspectionDate}
                            onChange={dateFieldsChange}
                            name="vehicleNextInspectionDate"
                            disabled={isDisabled}
                        />
                        <FormHelperText error>{otherFields.errors.vehicleNextInspectionDate}</FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <InputLabel shrink>Insurance Renewal Date</InputLabel>
                        <DatePicker
                            placeholder="Insurance Renewal Date"
                            value={dateFields.insuranceRenewalDate}
                            onChange={dateFieldsChange}
                            name="insuranceRenewalDate"
                            disabled={isDisabled}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <InputLabel required shrink>
                            Next Service Date Due
                        </InputLabel>
                        <DatePicker
                            placeholder="Next Service Date Due"
                            value={dateFields.vehicleNextServiceDateDue}
                            onChange={dateFieldsChange}
                            name="vehicleNextServiceDateDue"
                            disabled={isDisabled}
                        />
                        <FormHelperText error>{otherFields.errors.vehicleNextServiceDateDue}</FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <SimpleField
                            inputLabel="Next Service Mileage"
                            type="number"
                            placeholder="Next Service Mileage"
                            value={otherFields.vehicleNextServiceMileage}
                            onChange={otherFieldsChange}
                            name="vehicleNextServiceMileage"
                            disabled={isDisabled}
                        />
                        <FormHelperText error>{otherFields.errors.vehicleNextServiceMileage}</FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <InputLabel shrink>R&M Start Date</InputLabel>
                        <DatePicker
                            inputProps={{
                                max: dateFields.vehicleRMEndDate
                            }}
                            placeholder="RM Start Date"
                            value={dateFields.vehicleRMStartDate}
                            onChange={dateFieldsChange}
                            name="vehicleRMStartDate"
                            disabled={isDisabled}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <InputLabel shrink>R&M End Date</InputLabel>
                        <DatePicker
                            inputProps={{
                                min: dateFields.vehicleRMStartDate
                            }}
                            placeholder="RM End Date"
                            value={dateFields.vehicleRMEndDate}
                            onChange={dateFieldsChange}
                            name="vehicleRMEndDate"
                            disabled={isDisabled}
                        />
                    </Grid>
                    {/* <Grid item xs={12} sm={4}>
            <InputLabel shrink>VOR Start Date</InputLabel>
            <DatePicker placeholder="VOR Start Date" value={dateFields.VORStartDate} onChange={dateFieldsChange} name="VORStartDate" />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputLabel shrink>VOR End Date</InputLabel>
            <DatePicker placeholder="VOR End Date" value={dateFields.VOREndDate} onChange={dateFieldsChange} name="VOREndDate" />
          </Grid> */}
                </Grid>
            </Grid>
        </>
    );
};

export default DateFieldsModal;
