import React, { useEffect, useState, useMemo } from 'react';
import { useToast } from '../../../../../Core/Hooks/useToast';
import { DataGrid, formatters } from '../../../../../Core/Controls';
import { getJobHistory } from '../../../../../Core/Services/InternalUser/ControllerService/jobDetailsService';

const columns = [
    { key: 'dateStarted', name: 'Date', formatter: formatters.Date_DDMMMYYYY_HHMM, width: 150 },
    { key: 'startedBy', name: 'Name' },
    { key: 'status', name: 'Status' }
];

const JobHistory = (props) => {
    const { showToastError } = useToast();
    const [state, setState] = useState({
        showLoader: true,
        jobHistoryList: []
    });

    const pullHistoryAndUpdateState = async () => {
        let res = await getJobHistory(props.jobID);
        if (res.success) {
            setState((st) => ({ ...st, showLoader: false, jobHistoryList: res.data.jobHistoryList }));
        }
        if (!res.success) {
            showToastError(res.message);
        }
    };

    useEffect(async () => {
        pullHistoryAndUpdateState();
    }, [props.refreshDependancy]);

    return (
        <div>
            <DataGrid rows={state.jobHistoryList} columns={columns} loadingData={state.showLoader} height={props.height || 300} />
        </div>
    );
};
export default JobHistory;
