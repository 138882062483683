import { postWithAuth, getWithAuth } from '../../../Basic/HttpService';

export const getAllvehiclecheckchecks = async () => {
    let res = await getWithAuth(`vehiclecheckchecks`);
    return res;
};

export const getvehiclecheckchecksById = async (id) => {
    let res = await getWithAuth(`vehiclecheckchecks/${id}`);
    return res;
};

export const postvehiclecheckchecksData = async (data) => {
    let res = await postWithAuth(`vehiclecheckchecks`, data);
    return res;
};
export const getVehicleCategory = async (data) => {
    let res = await getWithAuth(`VehicleCategories`);
    return res;
};

export const getVehicleCheckSection = async (data) => {
    let res = await getWithAuth(`vehiclechecksection`);
    return res;
};
