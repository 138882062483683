import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormHelperText, InputLabel } from '@material-ui/core';
import { SecondaryCheckbox, InputText, SecondaryButton, SelectBox, MandatoryField } from '../../../../Core/Controls';
import {
    getVehicleCheckSectionById,
    postVehicleCheckSectionData,
    getAllVehicleCheckSectionDropDown
} from '../../../../Core/Services/InternalUser/vehicleCheckSection';
import { useToast } from '../../../../Core/Hooks/useToast';
import '../../../commonStyle.scss';

const CheckSectionModal = (props) => {
    const [state, setState] = useState({
        inspectionSheetSectionID: null,
        inspectionSheetSectionDescription: '',
        inspectionSheetSectionPosition: '',
        inspectionSheetSectionActive: true,
        vehicleCategoryDescription: '',
        errors: {},
        categoryList: []
    });

    const { showToastSuccess, showToastError } = useToast();

    useEffect(async () => {
        if (props.checkSectionID) {
            let res = await getVehicleCheckSectionById(props.checkSectionID);
            if (res.success) {
                setState((state) => ({
                    ...state,
                    inspectionSheetSectionID: res.data.inspectionSheetSectionID,
                    inspectionSheetSectionDescription: res.data.inspectionSheetSectionDescription,
                    inspectionSheetSectionPosition: res.data.inspectionSheetSectionPosition,
                    vehicleCategoryDescription: res.data.inspectionSheetSectionVehicleCategoryID,
                    inspectionSheetSectionActive: res.data.inspectionSheetSectionActive
                }));
            }
        }

        let res = await getAllVehicleCheckSectionDropDown();
        setState((state) => ({
            ...state,
            categoryList: res.data.list.map((item) => ({
                id: item.vehicleCategoryID,
                name: item.vehicleCategoryDescription
            }))
        }));
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setState((state) => ({ ...state, [name]: value }));
    };

    const handleCheckbox = (e) => {
        const { name, checked } = e.target;
        setState((state) => {
            const nst = { ...state, [name]: checked };
            return nst;
        });
    };

    const validations = () => {
        const { inspectionSheetSectionDescription, inspectionSheetSectionPosition, vehicleCategoryDescription } = state;
        let emptyStrigPattern = /[^\s]/;
        let formIsValid = true;
        let errors = {};
        if (!emptyStrigPattern.test(inspectionSheetSectionDescription)) {
            errors.inspectionSheetSectionDescription = 'This field is required';
            formIsValid = false;
        }
        if (!emptyStrigPattern.test(inspectionSheetSectionPosition)) {
            errors.inspectionSheetSectionPosition = 'This field is required';
            formIsValid = false;
        }
        if (!emptyStrigPattern.test(vehicleCategoryDescription)) {
            errors.vehicleCategoryDescription = 'This field is required';
            formIsValid = false;
        }
        setState((state) => ({ ...state, errors: errors }));
        return formIsValid;
    };

    const submitHandler = async () => {
        if (validations()) {
            const checkSectionData = {
                inspectionSheetSectionID: state.inspectionSheetSectionID,
                inspectionSheetSectionPosition: state.inspectionSheetSectionPosition,
                inspectionSheetSectionDescription: state.inspectionSheetSectionDescription,
                inspectionSheetSectionActive: state.inspectionSheetSectionActive,
                vehicleCategoryDescription: state.vehicleCategoryDescription,
                inspectionSheetSectionVehicleCategoryID: state.vehicleCategoryDescription
            };
            let res = await postVehicleCheckSectionData(checkSectionData);
            if (res.success) {
                showToastSuccess('Vehicle Check section added successfully');
                props.onClose(true);
            } else {
                showToastError(res.message);
                // props.onClose(false);
            }
        }
    };

    return (
        <Grid container direction="row" spacing={2}>
            <Grid item xs={6} className="mandatory-fields">
                <MandatoryField
                    inputLabel="Section Position"
                    name="inspectionSheetSectionPosition"
                    onChange={handleInputChange}
                    value={state.inspectionSheetSectionPosition}
                    errors={state.errors.inspectionSheetSectionPosition}
                />
            </Grid>
            <Grid item xs={6} className="mandatory-fields">
                <InputLabel required shrink>
                    Category
                </InputLabel>
                <SelectBox
                    name="vehicleCategoryDescription"
                    value={state.vehicleCategoryDescription || ' '}
                    onChange={handleInputChange}
                    List={state.categoryList}
                />
                <FormHelperText error>{state.errors.vehicleCategoryDescription}</FormHelperText>
            </Grid>
            <Grid item xs={12} className="mandatory-fields">
                <MandatoryField
                    inputLabel="Description"
                    name="inspectionSheetSectionDescription"
                    onChange={handleInputChange}
                    value={state.inspectionSheetSectionDescription}
                    errors={state.errors.inspectionSheetSectionDescription}
                />
            </Grid>
            <Grid item xs={12}>
                <SecondaryCheckbox
                    checked={state.inspectionSheetSectionActive}
                    name="inspectionSheetSectionActive"
                    onChange={handleCheckbox}
                    label="Is Active ?"
                />
            </Grid>
            <Grid item xs={12} className="submit_btn">
                <SecondaryButton onClick={submitHandler}>{props.checkSectionID ? 'Update' : 'Add'}</SecondaryButton>
            </Grid>
        </Grid>
    );
};

export default CheckSectionModal;
