import { Button, CircularProgress, Grid, IconButton, Typography } from '@material-ui/core';
import { CloudUpload, Delete, Edit, Check } from '@material-ui/icons';
import { useEffect, useMemo, useState } from 'react';
import formatters from '../Grid/Inputformatter';
import { InputText } from '../../Controls';
import DataGrid from '../Grid';

import { CustomIconButton, PrimaryButton, SecondaryButton } from '../Inputs/Buttons/ButtonModal';
import { getFilesForConfig, uploadFileUsignUploaderConfig, updateName, deleteUploadedFile, downloadUploadedFile } from '../../Services/FileUploaderService';
import { getConfigIdFromName } from './FileUploaderConfig';
import { useToast } from '../../Hooks/useToast';
import { useLoginInfo } from '../../Hooks/useLoginInfo';
import DialogComp from '../../Dialog/DialogComp';
import { AppStorage } from '../../Basic/storage-service';

const basicColumns = [
    {
        key: 'FileName',
        name: 'Name'
    },
    {
        key: 'FileExt',
        name: 'Type',
        width: 80
    },
    {
        key: 'FileSize',
        name: 'Size',
        width: 90
    },
    {
        key: 'DownloadCount',
        name: 'Downloads',
        width: 100
    },
    {
        key: 'UploadedBy',
        name: 'Uploaded By',
        width: 200
    },
    {
        key: 'UploadedAt',
        name: 'Uploaded At',
        width: 200,
        formatter: formatters.Date_DDMMMYYYY_HHMM
    }
];

const UploaderModal = ({ recordId, configName, useToast: ut, useLoginInfo: uli, gridRelaod, onClose, fileUploaderModalClose,docIDPassed }) => {
    let checkhaveAccess = AppStorage.getAllowAcces();
    let AccessConfig = {
        ScheduleDocuments: checkhaveAccess?.CanUploadScheduleDocuments,
        JobScreen: checkhaveAccess?.CanUploadJobDocuments,
        CustomerScreen: checkhaveAccess?.CanUploadCustomerDocuments
    };

    let getAccessValue = (screenName) => {
        if (['ScheduleDocuments', 'JobScreen', 'CustomerScreen'].includes(screenName)) {
            return AccessConfig[`${screenName}`];
        } else {
            return true;
        }
    };
    let useToastRef = useToast();
    let useLoginInfoRef = useLoginInfo();
    let { showToastSuccess, showToastError } = ut || useToastRef;

    let { isReadOnlyUser } = uli || useLoginInfoRef;
    const [state, setState] = useState({
        fileName: '',
        selectedFile: undefined,
        loading: true,
        uploading: false,
        inEdit: false,
        inDelete: false,
        fileToAction: undefined,
        currentFiles: [],
        fileUploaderModalClose: fileUploaderModalClose || false
    });
    const getFiles = (isReload) => {
        getFilesForConfig(getConfigIdFromName(configName), recordId,docIDPassed).then((res) => {
            if (res.success) {
                setState((st) => ({ ...st, loading: false, currentFiles: res.data }));
                isReload && gridRelaod && gridRelaod();
            }
        });
    };
    useEffect(() => {
        getFiles();
    }, []);

    const onFileSelect = (e) => {
        const { files } = e.target;
        if (files.length) {
            setState((st) => ({ ...st, fileName: files[0].name, selectedFile: files[0] }));
        }
    };

    const uploadSelectedFile = async () => {
        if (state.fileName && state.selectedFile) {
            setState((st) => ({ ...st, uploading: true }));
            const formData = new FormData();
            formData.append(state.fileName, state.selectedFile);
            let res = await uploadFileUsignUploaderConfig(getConfigIdFromName(configName), recordId, formData,docIDPassed);
            if (res.success) {
                showToastSuccess(res.message);
                setState((st) => ({ ...st, uploading: false, fileName: '', selectedFile: undefined }));
                !state.fileUploaderModalClose && getFiles(true);
                state.fileUploaderModalClose && onClose(true);
            } else {
                showToastError(res.message);
                setState((st) => ({ ...st, uploading: false }));
            }
        }
    };

    const showDeleteConfirm = (row) => (e) => {
        e.stopPropagation();
        setState((st) => ({
            ...st,
            inDelete: true,
            fileToAction: {
                uniqFileName: row.UniqueFileName,
                fileName: row.FileName
            }
        }));
    };

    const editName = (row) => (e) => {
        e.stopPropagation();
        setState((st) => ({
            ...st,
            inEdit: true,
            fileToAction: {
                uniqFileName: row.UniqueFileName,
                fileName: row.FileName
            }
        }));
    };

    const hideModal = (type) => () => {
        setState((st) => ({ ...st, [type]: false, fileToAction: undefined }));
    };

    const actionButtons = (params) => {
        return (
            <div>
                {getAccessValue(`${configName}`) ? <CustomIconButton icon={Edit} iconFontSize={18} onClick={editName(params.row)} /> : ''}
                {checkhaveAccess?.CanDeleteDocuments && <CustomIconButton icon={Delete} iconFontSize={18} onClick={showDeleteConfirm(params.row)} />}
            </div>
        );
    };

    const modifyCurrentFileName = (e) => {
        const { value } = e.target;
        setState((st) => ({ ...st, fileToAction: { ...st.fileToAction, fileName: value } }));
    };

    const updateFileToServer = async () => {
        let res = await updateName(getConfigIdFromName(configName), recordId, state.fileToAction.uniqFileName, state.fileToAction.fileName,docIDPassed);
        if (res.success) {
            // showToastSuccess(res.message);
            setState((st) => ({ ...st, inEdit: false, fileToAction: undefined }));
            getFiles(true);
        } else {
            showToastError(res.message);
        }
    };

    const confirmDelete = async () => {
        let res = await deleteUploadedFile(getConfigIdFromName(configName), recordId, state.fileToAction.uniqFileName);
        if (res.success) {
            // showToastSuccess(res.message);
            setState((st) => ({ ...st, inDelete: false, fileToAction: undefined }));
            getFiles(true);
        } else {
            showToastError(res.message);
        }
    };

    const downloadFile = (row) => async (e) => {
        let success = await downloadUploadedFile(getConfigIdFromName(configName), recordId, row.UniqueFileName, `${row.FileName}${row.FileExt}`,docIDPassed);
        if (success) {
            getFiles(true);
        } else {
            showToastError(`Something went wrong, can't download file.`);
        }
    };

    const gridColumns = useMemo(() => {
        const cols = [...basicColumns];
        if (!isReadOnlyUser) {
            cols.push({
                name: '',
                width: 90,
                formatter: actionButtons
            });
        }
        const fn = cols.find((c) => c.key === 'FileName');
        if (fn) {
            fn.formatter = (params) => {
                return (
                    <a href="javascript:0" onClick={downloadFile(params.row)}>
                        {params.row.FileName}
                    </a>
                );
            };
        }
        return cols;
    }, [isReadOnlyUser]);

    return (
        <div style={{ width: '80vw' }}>
            {!isReadOnlyUser && (
                <Grid container spacing={2}>
                    <Grid item xs={7}>
                        <InputText disabled value={state.fileName || 'No file selected'} label="File to Upload" />
                    </Grid>
                    <Grid item>
                        <Button color="secondary" variant="contained" component="label" disabled={!getAccessValue(`${configName}`)}>
                            Select File
                            <input
                                type="file"
                                style={{ display: 'none' }}
                                onChange={onFileSelect}
                                accept="image/*, application/pdf, application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            />
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button disabled={!state.selectedFile || state.uploading} onClick={uploadSelectedFile} color="secondary" variant="outlined">
                            {state.uploading ? <CircularProgress size={24} color="secondary" /> : <CloudUpload />}
                        </Button>
                    </Grid>
                </Grid>
            )}
            <br />
            <DataGrid loadingData={state.loading} rows={state.currentFiles} columns={gridColumns} />
            {state.inEdit && (
                <DialogComp title="Rename" onClose={hideModal('inEdit')}>
                    <InputText label="New Name" value={state.fileToAction.fileName} onChange={modifyCurrentFileName} autoFocus={true}></InputText>
                    <SecondaryButton className="mt-20" onClick={updateFileToServer}>
                        <Check /> Save
                    </SecondaryButton>
                </DialogComp>
            )}
            {state.inDelete && (
                <DialogComp title="Confirm Delete" onClose={hideModal('inDelete')}>
                    <Typography variant="h5" color="secondary">
                        Are you sure you want to delete?
                    </Typography>
                    <PrimaryButton className="mt-20" onClick={hideModal('inDelete')}>
                        No, Close
                    </PrimaryButton>
                    <SecondaryButton className="mt-20 mb-20" onClick={confirmDelete}>
                        <Delete /> Yes, Delete
                    </SecondaryButton>
                </DialogComp>
            )}
        </div>
    );
};

export default UploaderModal;
