import { FormHelperText, Grid, InputLabel } from '@material-ui/core';
import React, { useState } from 'react';
import { DatePicker, SecondaryButton } from '../../../../Core/Controls';
import { MandatoryPositiveInput } from '../../../../Core/Controls/Inputs/PositiveInput';
import '../../../commonStyle.scss';
import { postMileageLog } from '../../../../Core/Services/InternalUser/ControllerService/mileageLogService';
import { useEffect } from 'react';
import moment from 'moment';
import { useToast } from '../../../../Core/Hooks/useToast';

const MileageLogDialog = (props) => {
    const [state, setState] = useState({
        odometerReadingID: null,
        odometerReadingVehicleID: props.vehicleID,
        odometerReadingDate: '',
        odometerReading: ''
    });
    const [error, setError] = useState({});
    const { showToastSuccess, showToastError } = useToast();
    useEffect(() => {
        if (props.rowData.odometerReadingID) {
            setState((st) => ({
                ...st,
                odometerReadingID: props.rowData.odometerReadingID,
                odometerReadingVehicleID: props.rowData.odometerReadingVehicleID,
                odometerReadingDate: props.rowData.odometerReadingDate,
                odometerReading: props.rowData.odometerReading
            }));
        }
    }, []);

    const validate = () => {
        let errors = {};
        let isValid = true;
        const { odometerReadingDate, odometerReading } = state;
        if (!/[^\s]/.test(odometerReadingDate)) {
            errors.odometerReadingDate = 'Reading date is required';
            isValid = false;
        }
        if (+odometerReading < 0) {
            errors.odometerReading = 'Odometer reading can not be negative';
            isValid = false;
        }
        if (!/[^\s]/.test(odometerReading)) {
            errors.odometerReading = 'Odometer reading  is required';
            isValid = false;
        }
        setError(errors);
        return isValid;
    };

    const handleData = async () => {
        if (validate()) {
            let res = await postMileageLog({
                odometerReadingID: state.odometerReadingID,
                odometerReadingVehicleID: state.odometerReadingVehicleID,
                odometerReadingDate: state.odometerReadingDate,
                odometerReading: +state.odometerReading,
                odometerReadingCaptureSource: 'Manual Entry',
                odometerReadingActive: props.btnFlag == 'delete' ? false : true
            });
            if (res.success) {
                props.btnFlag == 'add'
                    ? showToastSuccess('Mileage Log added sucessfully')
                    : props.btnFlag == 'save'
                        ? showToastSuccess('Mileage Log updated sucessfully')
                        : props.btnFlag == 'delete'
                            ? showToastSuccess('Mileage Log deleted sucessfully')
                            : showToastError('Something went wrong!');
            } else {
                showToastError(res.message);
            }
            props.onClose();
        }
    };

    const inputChange = (event) => {
        const { name, value } = event.target;
        setState((state) => ({
            ...state,
            [name]: value
        }));
    };

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12} md={6} className="mandatory-fields">
                    <InputLabel required shrink>
                        Reading Date
                    </InputLabel>
                    <DatePicker
                        inputProps={{
                            max: new Date().toISOString().slice(0, 10)
                        }}
                        name="odometerReadingDate"
                        value={(state.odometerReadingDate && moment(state.odometerReadingDate).format('YYYY-MM-DD')) || ''}
                        onChange={inputChange}
                        disabled={props.btnFlag == 'delete' ? true : false}
                    />
                    <FormHelperText error>{error.odometerReadingDate}</FormHelperText>
                </Grid>
                <Grid item xs={12} md={6} className="mandatory-fields">
                    <MandatoryPositiveInput
                        inputLabel="Odometer Reading"
                        placeholder="Odometer Reading"
                        name="odometerReading"
                        value={state.odometerReading || ''}
                        onChange={inputChange}
                        errors={error.odometerReading}
                        disabled={props.btnFlag == 'delete' ? true : false}
                    />
                </Grid>
                <Grid xs={6} item className="submit_btn">
                    <SecondaryButton onClick={handleData}>{props.btnFlag}</SecondaryButton>
                </Grid>
                <Grid xs={6} item className="submit_btn">
                    <SecondaryButton onClick={props.onClose}>Cancel</SecondaryButton>
                </Grid>
            </Grid>
        </>
    );
};

export default MileageLogDialog;
