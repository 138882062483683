import React from 'react';
import { BreadCrumbs, TabView } from '../../../Core/Controls';
import ServiceAgentDashboard from './serviceAgentDash';
import ServiceAgentDetails from './serviceAgentDetail';
import NoteDashboard from './../Controllers/Notes';
import './serviceAgent.scss';
import { AppStorage } from '../../../Core/Basic/storage-service';

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Service Agents', path: '/serviceagent' },
    { name: 'Add/Update Service Agent', path: '/', active: true }
];

export default function EditServiceAgent(props) {
    const id = props.match.params.id;
    const fileCount = props.location.state.fileCount;
    let checkhaveAccess = AppStorage.getAllowAcces();

    const tabs = [
        {
            label: 'Dashboard',
            body: (
                <>
                    <ServiceAgentDashboard serviceAgentID={id} fileCount={fileCount} />
                </>
            )
        },
        ...(checkhaveAccess?.CanViewNotes
            ? [
                  {
                      label: 'Notes',
                      body: <NoteDashboard frompage="ServiceAgents" NoteServiceAgID={id} noteRefsID={id} />
                  }
              ]
            : [])
    ];

    return (
        <div className="screen">
            <BreadCrumbs crumbs={crumbs} className="edit-service-agent-btn" m={5} />
            <TabView tabList={tabs} id="testing_tabs" />
        </div>
    );
}
