import { postWithAuth, getWithAuth } from '../../Basic/HttpService';

export const getAllEmailTemplates = async () => {
    let res = await getWithAuth(`EmailTemplates`);
    return res;
};

export const getEmailTemplatesById = async (id) => {
    let res = await getWithAuth(`EmailTemplates/${id}`);
    return res;
};

export const postEmailTemplatesData = async (data) => {
    let res = await postWithAuth(`EmailTemplates`, data);
    return res;
};
