import { postWithAuth, getWithAuth } from '../../../Basic/HttpService';
import { memoize } from 'lodash';

export const getChecksAddTabByID = memoize(async (id) => {
    let res = await getWithAuth(`VehicleInspection/AddScreenDetails/${id}`);
    return res;
});

export const postChecksData = async (data) => {
    let res = await postWithAuth(`VehicleInspection`, data);
    return res;
};

export const getChecksEditByID = async (id) => {
    let res = await getWithAuth(`VehicleInspection/EditScreenDetails/${id}`);
    return res;
};

export const getChecks = async (id) => {
    let res = await getWithAuth(`VehicleInspection/list`);
    return res;
};

export const getSingleChecks = async (id) => {
    let res = await getWithAuth(`VehicleInspection/DefectDetails?ResultID=${id}`);
    return res;
};

export const postSingleChecksData = async (data) => {
    let res = await postWithAuth(`VehicleInspection/DefectDetails `, data);
    return res;
};

export const PostImage = async (data) => {
    let res = await postWithAuth(`VehicleInspectionsPhoto`, data);
    return res;
};
export const getImageByID = async (id) => {
    let res = await getWithAuth(`VehicleInspectionsPhoto/${id}`);
    return res;
};
