import { PULLINSPECTIONAPIDATA, HANDELCHECK, HANDELCLOSE } from '../../../Action/actionName';
export const initState = {
    open: false,
    selectedRowData: {},
    isAdd: true,
    showBred: true,
    isReload: null
};
export let reducer = function (state, action) {
    switch (action.type) {
        case PULLINSPECTIONAPIDATA:
            return { ...state, isReload: new Date() };
        case HANDELCHECK:
            return { ...state, showEditChecks: true, selectedRowData: action.payload, showBred: false };
        case HANDELCLOSE:
            return { ...state, showEditChecks: false, selectedRowData: {}, showBred: true };
        default:
            return {state };
    }
};
