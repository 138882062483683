import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useLoginInfo } from "../../Hooks/useLoginInfo";
const Multiselect = (props) => {
    let { isReadOnlyUser } = useLoginInfo();
    props.forceEditable && (isReadOnlyUser = false);

    return (
        <Autocomplete
            disabled={isReadOnlyUser}
            {...props}
            multiple
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.name === value.name}
            renderInput={(params) => (
                <TextField
                    margin="dense"
                    fullWidth
                    {...params}
                    variant="outlined"
                    placeholder={props.placeholder}
                />
            )}
        />
    );
};
export default Multiselect;
