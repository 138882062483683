import React, { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormHelperText, InputLabel, Typography } from '@material-ui/core';
import { InputText, SelectBox, SecondaryCheckbox, YesButton, NoButton, MandatoryField, SimpleField } from '../../../../Core/Controls';
import Paper from '@material-ui/core/Paper';
import '../controllerStyle.scss';

const DepotsDetails = (props) => {
    const { depotFields, handledepotFields, handledepotCheckbox, handleActive } = props;

    return (
        <Grid item xs={12} md={12} lg={12} className="grid-paper-shadow">
            <Paper className="controller-container" style={{ padding: '8px' }}>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={4} className="mandatory-fields">
                        <MandatoryField
                            inputLabel="Names"
                            placeholder="Depot Name"
                            value={depotFields.name}
                            onChange={handledepotFields}
                            name="name"
                            errors={depotFields.errors.name}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} className="mandatory-fields">
                        <InputLabel shrink></InputLabel>
                        <MandatoryField
                            inputLabel="Address 1"
                            placeholder="Address 1"
                            value={depotFields.address1 || ''}
                            onChange={handledepotFields}
                            name="address1"
                            errors={depotFields.errors.address1}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <SimpleField inputLabel="Address 2" placeholder="Address 2" value={depotFields.address2} onChange={handledepotFields} name="address2" />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <SimpleField inputLabel="Town" placeholder="Town" value={depotFields.depotTown} onChange={handledepotFields} name="depotTown" />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <SimpleField
                            inputLabel="Post Code"
                            placeholder="Post Code "
                            value={depotFields.depotPostCode}
                            onChange={handledepotFields}
                            name="depotPostCode"
                            // errors={depotFields.errors.depotPostCode}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} className="mandatory-fields">
                        <MandatoryField
                            inputLabel="County"
                            placeholder="County"
                            value={depotFields.depotCounty || ''}
                            onChange={handledepotFields}
                            name="depotCounty"
                            errors={depotFields.errors.depotCounty}
                        />{' '}
                    </Grid>
                    {/* <Grid item xs={12} sm={4} className="mandatory-fields">
                        <InputLabel shrink>Country Code</InputLabel>
                        <InputText placeholder="Country Code" value={depotFields.depotCountyCode || ''} onChange={handledepotFields} name="depotCountyCode" />
                        <FormHelperText error>{depotFields.errors.depotCountyCode}</FormHelperText>
                    </Grid> */}
                    <Grid item xs={12} sm={4}>
                        <SimpleField
                            inputLabel="Email"
                            placeholder="Email"
                            type="email"
                            value={depotFields.depotEmail || ''}
                            onChange={handledepotFields}
                            name="depotEmail"
                        />
                        {/* <FormHelperText error>{depotFields.errors.depotEmail}</FormHelperText> */}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <SimpleField
                            inputLabel="Customers Ref"
                            placeholder="Customers Ref "
                            value={depotFields.depotCustomersRef}
                            onChange={handledepotFields}
                            name="depotCustomersRef"
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <SimpleField
                            inputLabel="Telephone"
                            placeholder="Telephone"
                            type="tel"
                            value={depotFields.depotTelephone}
                            onChange={handledepotFields}
                            name="depotTelephone"
                        />
                        {/* <FormHelperText error>{depotFields.errors.depotTelephone}</FormHelperText> */}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <SimpleField
                            inputLabel="Contact Number"
                            placeholder="Contact Number"
                            value={depotFields.depotContact}
                            onChange={handledepotFields}
                            name="depotContact"
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <SimpleField
                            inputLabel="VOR Mobile Numbers"
                            placeholder="VOR Mobile Numbers"
                            value={depotFields.depotVORMobileNumbers}
                            onChange={handledepotFields}
                            name="depotVORMobileNumbers"
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <InputLabel shrink>Primary Service Agent</InputLabel>
                        <SelectBox
                            name="depotServiceAgentID"
                            value={depotFields.depotServiceAgentID}
                            onChange={handledepotFields}
                            List={depotFields.serviceAgentsList}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <InputLabel shrink>Secondary Service Agent</InputLabel>
                        <SelectBox
                            name="depotAgentSecondaryAgentID"
                            value={depotFields.depotAgentSecondaryAgentID}
                            onChange={handledepotFields}
                            List={depotFields.serviceAgentsList}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <InputLabel shrink>First Day of Week</InputLabel>
                        <SelectBox
                            placeholder="First Day of Week"
                            value={depotFields.firstDayofWeek}
                            onChange={handledepotFields}
                            name="firstDayofWeek"
                            List={[]}
                        />
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
};

export default DepotsDetails;
