import { useEffect, useState } from 'react';
import { Badge, IconButton, Tooltip } from '@material-ui/core';
import { PermMedia } from '@material-ui/icons';
import DialogComp from '../../Dialog/DialogComp';
import UploaderModal from './UploaderModal';
import { CustomIconButton } from '../Inputs/Buttons/ButtonModal';
import { Link } from 'react-router-dom';
import '../Inputs/Buttons/ButtonModal.scss';
import { useLoginInfo } from '../../Hooks/useLoginInfo';
import { useToast } from '../../Hooks/useToast';
import { AppStorage } from '../../Basic/storage-service';

const FileUploaderButton = ({ fileCount, recordId, fileUploaderConfigName, ut, uli, gridRelaod, fileUploaderModalClose,docIDPassed }) => {
    const [state, setState] = useState({
        showUploader: false,
        recordId,
        fileCount: `${fileCount || 0}`,
        configName: fileUploaderConfigName
    });
    let checkhaveAccess = AppStorage.getAllowAcces();
    useEffect(() => {
        if (fileCount) {
            setState((st) => ({
                fileCount: fileCount || 0
            }));
        }
    }, [fileCount]);

    const hideFileUploader = (res) => {
        setState((st) => ({ ...st, showUploader: false }));
        res && gridRelaod();
    };

    const showFileUploader = () => {
        setState((st) => ({ ...st, showUploader: true }));
    };

    return (
        <>
            {/* <CustomIconButton icon={PermMedia} onClick={showFileUploader}>
                <Badge style={{ marginTop: -10 }} className="mx-10" badgeContent={state.fileCount} />
            </CustomIconButton> */}
            {checkhaveAccess?.CanViewDocuments && (
                <Badge className="file-upload-badge" badgeContent={state.fileCount} color="secondary" max={999} showZero>
                    <PermMedia style={{ fontSize: 22, cursor: 'pointer' }} color="secondary" onClick={showFileUploader} />
                </Badge>
            )}
            {state.showUploader && (
                <DialogComp title="Document Manager" onClose={() => hideFileUploader(false)} maxWidth="xl">
                    <UploaderModal
                        recordId={recordId}
                        configName={fileUploaderConfigName}
                        useToast={ut}
                        useLoginInfo={uli}
                        gridRelaod={gridRelaod}
                        fileUploaderModalClose={fileUploaderModalClose || false}
                        onClose={hideFileUploader}
                        docIDPassed={docIDPassed}
                    />
                </DialogComp>
            )}
        </>
    );
};

export default FileUploaderButton;
