import React, { useEffect, useState } from 'react';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import { getUserPermissionsFromID, setUserPermissions } from '../../../../Core/Services/Admin/userService';
import { useToast } from '../../../../Core/Hooks/useToast';
import Grid from '@material-ui/core/Grid';
import { YesButton, NoButton, SecondaryButton, SecondaryCheckbox } from '../../../../Core/Controls';
import './../user.scss';
import { FormLabel } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { AppStorage } from '../../../../Core/Basic/storage-service';
import { getUserById } from '../../../../Core/Services/Admin/userService';
import { useLoginInfo } from '../../../../Core/Hooks/useLoginInfo';
import { ReactDOM } from 'react-dom';

const AssignPermissionScreen = (props) => {
    const [permissions, setPermissions] = useState({});
    const [userID, setUserID] = useState(null);
    const [btnLoader, setBtnLoader] = useState(false);

    const { showToastSuccess, showToastError } = useToast();

    const isInternal = props?.userRole == "internal";
    const isCustomer = props?.userRole == "customer";
    const isServiceAgent = props?.userRole == "serviceagent";

    useEffect(async () => {
        let res = await getUserPermissionsFromID(props.userId);
        console.log(res)

        const permissions = res.data.details[0];

        for (const key in permissions) {
            const perm = key.split('canView')[1];
            if (key.includes('canView')) {
                if (permissions['canDelete' + perm] || permissions['canUpdate' + perm] || permissions['canAuthorise' + perm] || permissions['canAdd' + perm]) {
                    permissions[key] = true;
                }
            }
        }

        setPermissions(() => ({
            ...permissions
        }));
    }, [props.userId]);

    useEffect(async () => {
        let res = await getUserById(props.userId);
        if (res.success) {
            setUserID(res?.data?.userRole);
        }
    }, []);

    useEffect(() => {

    }, []);

    const handleSubmit = async () => {
        setBtnLoader(true);

        const data = { ...permissions };
        data['userid'] = props.userId;

        let res = await setUserPermissions(data);

        if (res.success) {
            showToastSuccess(res.data.message);
            props.onFormSubmit(true);
        } else {
            showToastError(res.message);
        }

        setBtnLoader(false);
    };

    const handleClick = (val) => (e) => {
        const { name, checked } = e.target;
        let toCheck = name.includes('canDelete')
            ? 'canDelete'
            : name.includes('canUpdate')
                ? 'canUpdate'
                : name.includes('canAuthorise')
                    ? 'canAuthorise'
                    : name.includes('canAdd')
                        ? 'canAdd'
                        : name.includes('canBook')
                            ? 'canBook'
                            : name.includes('canUpload')
                                ? 'canUpload'
                                : null;

        if (name.includes(toCheck)) {
            const perm = 'canView' + name.split(toCheck)[1];
            if (perm in permissions) {
                setPermissions((st) => ({
                    ...st,
                    [perm]: true
                }));
            }
        }
        let newst = {};
        newst[name] = checked;
        if (val && checked) {
            newst[val] = true;
        }
        setPermissions((st) => ({
            ...st,
            ...newst
        }));
    };

    const Permission = (props) => {
        const shouldBeDisabled = (input) => {
            const perm = input.split('canView')[1];
            if (input.includes('canView')) {
                if (
                    permissions['canDelete' + perm] ||
                    permissions['canUpdate' + perm] ||
                    permissions['canAuthorise' + perm] ||
                    permissions['canBook' + perm] ||
                    permissions['canUpload' + perm] ||
                    permissions['canAdd' + perm]
                ) {
                    return true;
                }
            }
            return false;
        };

        return (
            props?.hidden ?
                null
                :
                <Grid container xs={6} s={4} md={4} lg={3} xl={3}>
                    <Grid item xs={1}>
                        <SecondaryCheckbox
                            name={props.name}
                            onChange={handleClick(props.DefaultState)}
                            checked={props.state}
                            disabled={shouldBeDisabled(props.name)}
                        />
                    </Grid>
                    <Grid item xs={11} style={{ paddingTop: '10px' }}>
                        <FormLabel>{props.displayName}</FormLabel>
                    </Grid>
                </Grid>
        );
    };

    return (
        <Grid container className="assign-permission-menu">
            <Grid item xs={12} md={12} lg={12}>
                <Typography variant="h5" color="secondary" style={{ marginTop: '15px', marginBottom: '15px' }}>
                    {`Assign User Permissions - ${props.displayName} - ${props.userRole}`}
                </Typography>
                <hr />
            </Grid>



            <Grid item xs={12} md={12} lg={12} style={{ paddingTop: '15px' }}>
                <Typography variant="h6" color="secondary">
                    Misc
                </Typography>
                <hr />
            </Grid>
            <Permission name={'canViewDocuments'} displayName={'Can View Documents?'} state={permissions.canViewDocuments} />
            <Permission name={'canDeleteDocuments'} displayName={'Can Delete Documents?'} state={permissions.canDeleteDocuments} />
            <Permission name={'canViewCompanyDetails'} displayName={'Can View Company Details?'} state={permissions.canViewCompanyDetails} hidden={isCustomer || isServiceAgent} />
            <Permission name={'canUpdateCompanyDetails'} displayName={'Can Update Company Details?'} state={permissions.canUpdateCompanyDetails} hidden={isCustomer || isServiceAgent} />
            <Permission name={'canViewInvoices'} displayName={'Can View Invoices?'} state={permissions.canViewInvoices} hidden={isServiceAgent} />
            <Permission name={'canViewNotes'} displayName={'Can View Notes?'} state={permissions.canViewNotes} />
            <Permission name={'canViewMessages'} displayName={'Can View Messages?'} state={permissions.canViewMessages} />
            <Permission name={'canImportFuelPurchases'} displayName={'Can Import Fuel Purchases?'} state={permissions.canImportFuelPurchases} hidden={isServiceAgent} />
            <Permission name={'canImportJourneys'} displayName={'Can Import Journeys?'} state={permissions.canImportJourneys} hidden={isServiceAgent} />
            <Permission name={'canImportMileages'} displayName={'Can Import Mileages?'} state={permissions.canImportMileages} hidden={isServiceAgent} />
            <Permission name={'canImportDepots'} displayName={'Can Import Depots?'} state={permissions.canImportDepots} hidden={isServiceAgent} />
            <Permission name={'canImportServiceAgents'} displayName={'Can Import Service Agents?'} state={permissions.canImportServiceAgents} hidden={isCustomer || isServiceAgent} />



            <Grid item xs={12} md={12} lg={12}>
                <Typography variant="h6" color="secondary" style={{ paddingTop: '15px' }}>
                    Schedules
                </Typography>
                <hr />
            </Grid>
            <Permission name={'canViewSchedules'} displayName={'Can View Schedules?'} state={permissions.canViewSchedules} />
            <Permission name={'canUpdateSchedules'} displayName={'Can Update Schedules?'} state={permissions.canUpdateSchedules} />
            <Permission name={'canDeleteSchedules'} displayName={'Can Delete Schedules?'} state={permissions.canDeleteSchedules} hidden={isCustomer || isServiceAgent} />
            <Permission name={'canBookSchedules'} displayName={'Can Book Schedules?'} state={permissions.canBookSchedules} />
            <Permission name={'canUpdateScheduleNotes'} displayName={'Can Update Schedule Notes?'} state={permissions.canUpdateScheduleNotes} DefaultState="canViewSchedules" />
            <Permission name={'canUploadScheduleDocuments'} displayName={'Can Upload Schedule Documents?'} state={permissions.canUploadScheduleDocuments} DefaultState="canViewSchedules" />



            <Grid item xs={12} md={12} lg={12} style={{ paddingTop: '15px' }}>
                <Typography variant="h6" color="secondary">
                    Jobs
                </Typography>
                <hr />
            </Grid>
            <Permission name={'canViewJobs'} displayName={'Can View Jobs?'} state={permissions.canViewJobs} />
            <Permission name={'canUpdateJobs'} displayName={'Can Update Jobs?'} state={permissions.canUpdateJobs} />
            <Permission name={'canAuthoriseJobs'} displayName={'Can Authorise Jobs?'} state={permissions.canAuthoriseJobs} hidden={isCustomer} />
            <Permission name={'canUploadJobDocuments'} displayName={'Can Upload Job Documents?'} DefaultState="canViewJobs" state={permissions.canUploadJobDocuments} />
            <Permission name={'canDeleteJobLines'} displayName={'Can Delete Job Lines?'} DefaultState="canViewJobs" state={permissions.canDeleteJobLines} hidden={isCustomer} />
            <Permission name={'canSendReplyJobQueries'} displayName={'Can Send Reply Job Queries?'} DefaultState="canViewJobs" state={permissions.canSendReplyJobQueries} />
            <Permission name={'canUpdateJobLimit'} displayName={'Can Update Job Limit?'} DefaultState="canViewJobs" state={permissions.canUpdateJobLimit} hidden={isCustomer || isServiceAgent} />
            <Permission name={'canAddScheduleItemstoJob'} displayName={'Can Add Schedule Items to Job?'} DefaultState="canViewJobs" state={permissions.canAddScheduleItemstoJob} />



            {!isServiceAgent && (
                <>
                    <Grid item xs={12} md={12} lg={12} style={{ paddingTop: '15px' }}>
                        <Typography variant="h6" color="secondary">
                            Depots
                        </Typography>
                        <hr />
                    </Grid>
                    <Permission name={'canViewDepots'} displayName={'Can View Depots?'} state={permissions.canViewDepots} hidden={isServiceAgent} />
                    <Permission name={'canUpdateDepots'} displayName={'Can Update Depots?'} state={permissions.canUpdateDepots} hidden={isServiceAgent} />
                    <Permission name={'canUpdateDepotDepartments'} displayName={'Can Update Depot Departments?'} DefaultState="canViewDepots" state={permissions.canUpdateDepotDepartments} hidden={isServiceAgent} />
                    <Permission name={'canUpdateDepotNotes'} displayName={'Can Update Depot Notes?'} DefaultState="canViewDepots" state={permissions.canUpdateDepotNotes} hidden={isServiceAgent} />
                </>
            )}


            {!isServiceAgent && (
                <>
                    <Grid item xs={12} md={12} lg={12} style={{ paddingTop: '15px' }}>
                        <Typography variant="h6" color="secondary">
                            Vehicles
                        </Typography>
                        <hr />
                    </Grid>
                    <Permission name={'canViewVehicles'} displayName={'Can View Vehicles?'} state={permissions.canViewVehicles} hidden={isServiceAgent} />
                    <Permission name={'canAddVehicles'} displayName={'Can Add Vehicles?'} state={permissions.canAddVehicles} hidden={isServiceAgent} />
                    <Permission name={'canUpdateVehicles'} displayName={'Can Update Vehicles?'} state={permissions.canUpdateVehicles} />
                    <Permission name={'canUpdateVehicleFuelRecords'} displayName={'Can Update Vehicle Fuel Records?'} DefaultState="canViewVehicles" state={permissions.canUpdateVehicleFuelRecords} hidden={isServiceAgent} />
                    <Permission name={'canUpdateVehicleContracts'} displayName={'Can Update Vehicle Contracts?'} DefaultState="canViewVehicles" state={permissions.canUpdateVehicleContracts} hidden={isServiceAgent} />
                    <Permission name={'canUpdateVehicleDailyChecks'} displayName={'Can Update Vehicle Daily Checks?'} DefaultState="canViewVehicles" state={permissions.canUpdateVehicleDailyChecks} hidden={isServiceAgent} />
                    <Permission name={'canUpdateVehicleJourneys'} displayName={'Can Update Vehicle Journeys?'} DefaultState="canViewVehicles" state={permissions.canUpdateVehicleJourneys} hidden={isServiceAgent} />
                    <Permission name={'canUpdateVehicleMileages'} displayName={'Can Update Vehicle Mileages?'} DefaultState="canViewVehicles" state={permissions.canUpdateVehicleMileages} hidden={isServiceAgent} />
                    <Permission name={'canUpdateVehicleNotes'} displayName={'Can Update Vehicle Notes?'} DefaultState="canViewVehicles" state={permissions.canUpdateVehicleNotes} hidden={isServiceAgent} />
                    <Permission name={'canUpdateVehicleRecalls'} displayName={'Can Update Vehicle Recalls?'} DefaultState="canViewVehicles" state={permissions.canUpdateVehicleRecalls} hidden={isServiceAgent} />
                </>
            )}


            {!isServiceAgent && (
                <>
                    <Grid item xs={12} md={12} lg={12} style={{ paddingTop: '15px' }}>
                        <Typography variant="h6" color="secondary">
                            Drivers
                        </Typography>
                        <hr />
                    </Grid>
                    <Permission name={'canViewDrivers'} displayName={'Can View Drivers?'} state={permissions.canViewDrivers} hidden={isServiceAgent} />
                    <Permission name={'canAddDrivers'} displayName={'Can Add Drivers?'} state={permissions.canAddDrivers} hidden={isServiceAgent} />
                    <Permission name={'canUpdateDrivers'} displayName={'Can Update Drivers?'} state={permissions.canUpdateDrivers} hidden={isServiceAgent} />
                    <Permission name={'canUpdateDriverNotes'} displayName={'Can Update Driver Notes?'} DefaultState="canViewDrivers" state={permissions.canUpdateDriverNotes} hidden={isServiceAgent} />
                    <Permission name={'canDoDriverLicenceChecks'} displayName={'Can Do Driver Licence Checks?'} DefaultState="canViewDrivers" state={permissions.canDoDriverLicenceChecks} hidden={isServiceAgent} />
                </>
            )}


            {!isServiceAgent && (
                <>
                    <Grid item xs={12} md={12} lg={12} style={{ paddingTop: '15px' }}>
                        <Typography variant="h6" color="secondary">
                            Customer
                        </Typography>
                        <hr />
                    </Grid>
                    <Permission name={'canViewCustomers'} displayName={'Can View Customers?'} state={permissions.canViewCustomers} hidden={isServiceAgent} />
                    <Permission name={'canUpdateCustomers'} displayName={'Can Update Customers?'} state={permissions.canUpdateCustomers} hidden={isServiceAgent} />
                    <Permission name={'canUploadCustomerDocuments'} displayName={'Can Upload Customer Documents?'} DefaultState="canViewCustomers" state={permissions.canUploadCustomerDocuments} hidden={isServiceAgent} />
                    <Permission name={'canSendMessageToCustomers'} displayName={'Can Send Message To Customers?'} DefaultState="canViewCustomers" state={permissions.canSendMessageToCustomers} hidden={isServiceAgent} />
                    <Permission name={'canUpdateCustomerCostCentres'} displayName={'Can Update Customer Cost Centres?'} DefaultState="canViewCustomers" state={permissions.canUpdateCustomerCostCentres} hidden={isServiceAgent} />
                    <Permission name={'canUpdateCustomerFleetInsurance'} displayName={'Can Update Customer Fleet Insurance?'} DefaultState="canViewCustomers" state={permissions.canUpdateCustomerFleetInsurance} hidden={isServiceAgent} />
                    <Permission name={'canUpdateCustomerNotes'} displayName={'Can Update Customer Notes?'} DefaultState="canViewCustomers" state={permissions.canUpdateCustomerNotes} hidden={isServiceAgent} />
                </>
            )}


            {(!isServiceAgent && !isCustomer) && (
                <>
                    <Grid item xs={12} md={12} lg={12} style={{ paddingTop: '15px' }}>
                        <Typography variant="h6" color="secondary">
                            Service Agents
                        </Typography>
                        <hr />
                    </Grid>
                    <Permission name={'canViewServiceAgents'} displayName={'Can View Service Agents?'} state={permissions.canViewServiceAgents} hidden={isCustomer || isServiceAgent} />
                    <Permission name={'canAddServiceAgents'} displayName={'Can Add Service Agents?'} state={permissions.canAddServiceAgents} hidden={isCustomer || isServiceAgent} />
                    <Permission name={'canUpdateServiceAgents'} displayName={'Can Update Service Agents?'} state={permissions.canUpdateServiceAgents} hidden={isCustomer || isServiceAgent} />
                    <Permission name={'canUpdateServiceAgentRating'} displayName={'Can Update Service Agent Rating?'} DefaultState="canViewServiceAgents" state={permissions.canUpdateServiceAgentRating} hidden={isCustomer || isServiceAgent} />
                    <Permission name={'canUpdateServiceAgentNotes'} displayName={'Can Update Service Agent Notes?'} DefaultState="canViewServiceAgents" state={permissions.canUpdateServiceAgentNotes} hidden={isCustomer || isServiceAgent} />

                </>
            )}

            <Grid item xs={12} md={12} lg={12} style={{ paddingTop: '15px' }}>
                <Typography variant="h6" color="secondary">
                    Daily Checks & Inspections
                </Typography>
                <hr />
            </Grid>
            <Grid container item lg={12}>
                <Permission name={'canViewDailyChecks'} displayName={'Can View Daily Checks?'} state={permissions.canViewDailyChecks} />
                <Permission name={'canAddDailyChecks'} displayName={'Can Add Daily Checks?'} state={permissions.canAddDailyChecks} />
            </Grid>
            <Grid container item lg={12}>
                <Permission name={'canViewInspections'} displayName={'Can View Inspections?'} state={permissions.canViewInspections} />
                <Permission name={'canAddInspections'} displayName={'Can Add Inspections?'} state={permissions.canAddInspections} />
                <Permission name={'canUpdateVehicleInspections'} displayName={'Can Update Vehicle Inspections?'} DefaultState="canViewInspections" state={permissions.canUpdateVehicleInspections} />
            </Grid>



            <Grid item xs={12} md={12} lg={12} style={{ paddingTop: '15px' }}>
                <Typography variant="h6" color="secondary">
                    Breakdowns, Defects and VOR
                </Typography>
                <hr />
            </Grid>
            <Grid container item lg={12}>
                <Permission name={'canViewBreakdowns'} displayName={'Can View Breakdowns?'} state={permissions.canViewBreakdowns} />
                <Permission name={'canAddBreakdowns'} displayName={'Can Add Breakdowns?'} state={permissions.canAddBreakdowns} />
                <Permission DefaultState="canViewBreakdowns" name={'canUpdateVehicleBreakdowns'} displayName={'Can Update Vehicle Breakdowns?'} state={permissions.canUpdateVehicleBreakdowns} />
            </Grid>
            <Grid container item lg={12}>
                <Permission name={'canViewDefects'} displayName={'Can View Defects?'} state={permissions.canViewDefects} />
                <Permission name={'canAddDefects'} displayName={'Can Add Defects?'} state={permissions.canAddDefects} />
                <Permission DefaultState="canViewDefects" name={'canUpdateVehicleDefects'} displayName={'Can Update Vehicle Defects?'} state={permissions.canUpdateVehicleDefects} />
            </Grid>
            <Permission name={'canViewVOR'} displayName={'Can View VOR?'} state={permissions.canViewVOR} />
            <Permission name={'canAddVOR'} displayName={'Can Add VOR?'} state={permissions.canAddVOR} />
            <Permission name={'canUpdateVOR'} displayName={'Can Update Vehicle VOR?'} state={permissions.canUpdateVOR} />


            {!isServiceAgent && (
                <>
                    <Grid item xs={12} md={12} lg={12} style={{ paddingTop: '15px' }}>
                        <Typography variant="h6" color="secondary">
                            Invoices
                        </Typography>
                        <hr />
                    </Grid>
                    <Permission name={'canViewPurchaseInvoices'} displayName={'Can View Purchase Invoices?'} state={permissions.canViewPurchaseInvoices} hidden={isServiceAgent} />
                    <Permission name={'canAddPurchaseInvoices'} displayName={'Can Add Purchase Invoices?'} state={permissions.canAddPurchaseInvoices} hidden={isServiceAgent} />
                    <Permission name={'canUpdatePurchaseInvoices'} displayName={'Can Update Purchase Invoices?'} state={permissions.canUpdatePurchaseInvoices} hidden={isCustomer || isServiceAgent} />
                    <Permission name={'canViewSalesInvoices'} displayName={'Can Add Sales Invoices?'} DefaultState="canViewPurchaseInvoices" state={permissions.canViewSalesInvoices} hidden={isCustomer || isServiceAgent} />
                    <Permission name={'canUpdateSalesInvoices'} displayName={'Can Update Sales Invoices?'} DefaultState="canViewPurchaseInvoices" state={permissions.canUpdateSalesInvoices} hidden={isCustomer || isServiceAgent} />
                </>
            )}

            <Grid item xs={12} className="submit_btn" style={{ paddingTop: '25px' }}>
                <SecondaryButton onClick={handleSubmit} isBtnLoader={btnLoader}>
                    Submit
                </SecondaryButton>
            </Grid>
        </Grid>
    );
};

export default AssignPermissionScreen;
