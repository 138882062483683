import { postWithAuth, getWithAuth, putWithAuth } from '../../../Basic/HttpService';

export const getDepartmentById = async (Id) => {
    let res = await getWithAuth(`Departments/${Id}`);
    return res;
};

export const postDepartment = async (data) => {
    let res = await postWithAuth('Departments ', data);
    return res;
};
