import React, { useState } from 'react';
import { Table, TableBody, TableContainer, TableRow, Paper, TableCell } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import '../controllerStyle.scss';
import { CustomIconButton } from '../../../../Core/Controls/Inputs/Buttons/ButtonModal';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import EventIcon from '@material-ui/icons/Event';
import moment from 'moment';
import ScheduleAdd from './ScheduleVehicle/scheduleVehicleAdd';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import SetVehicleMOTTaxExpiryDate from './setVehicleMOTTaxExpiryDate';
export const VehicleDetails = (props) => {
    const { dateFields, changeIndex, jobFields } = props;
    const [state, setSatate] = useState({ showScheduleAdd: false, showExpiryModal: false });

    const data = [
        {
            name1: 'MOT Expiry',
            value1: `${dateFields.motExpiry}`,
            showExpiryBtn: true
        },
        {
            name1: 'Tax Due Date',
            value1: `${dateFields.taxDue}`,
            showExpiryBtn: true
        },
        {
            name1: 'Insurance Renewal Date',
            value1: `${dateFields.insuranceRenewalDate}`
        },
        {
            name1: 'Next Inspection Date',
            value1: `${dateFields.vehicleNextInspectionDate}`
        },
        {
            name1: 'Next Service',
            value1: `${dateFields.vehicleNextServiceDateDue}`,
            value2: `${dateFields.vehicleNextServiceMileage}`,
            showPopup: true
        }
    ];

    const handlePopup = (name, val) => {
        setSatate((st) => ({ ...st, [name]: val }));
    };
    const closeModal = (res) => {
        setSatate((st) => ({ ...st, showExpiryModal: false }));
        res && props.onFormSubmit(res);
    };
    return (
        <div>
            <Grid container>
                <Grid item xs={12} >
                    <TableContainer style={{ border: '1px solid rgba(0,0,0,0.2)' }}>
                        {props.tableHeader}
                        <div style={{ maxHeight: `calc(100vh - 565px)`, overflow: 'auto' }}className="custom-scroll">
                            <Table aria-label="simple table" size="small">
                                <TableBody>
                                    <TableRow
                                        key={'2'}
                                        style={{ backgroundColor: dateFields.activeRecallCount ? 'rgba(255, 8, 0,0.6)' : '#6dcf71', color: 'white' }}
                                    >
                                        <TableCell style={{ color: 'inherit' }}>Recalls Outstanding</TableCell>
                                        <TableCell style={{ color: 'inherit' }}>
                                            {dateFields.activeRecallCount ? dateFields.activeRecallCount : 'None'}{' '}
                                        </TableCell>
                                        <TableCell align="right">
                                            <CustomIconButton
                                                icon={AccessTimeIcon}
                                                color="white"
                                                className="modal-btn-update btn_space"
                                                toolTipTitle="Recalls"
                                                onClick={() => {
                                                    changeIndex(13);
                                                }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                    {data?.map((row) => {
                                        let notifyColor = '';
                                        let textCol = 'black';
                                        let todayDate = moment().format();
                                        let date1 = new Date(todayDate);
                                        let date2 = new Date(row.value1);
                                        let diffDays = parseInt((date2 - date1) / (1000 * 60 * 60 * 24), 10);
                                        if (diffDays >= 14) {
                                            notifyColor = '#dff0d8';
                                        } else if (
                                            diffDays <= 0 ||
                                            (row.name1 === 'Next Service' && jobFields.vehicleLatestMileage > dateFields.vehicleNextServiceMileage)
                                        ) {
                                            notifyColor = 'rgba(255, 8, 0,0.6)';
                                            textCol = 'white';
                                        } else if (diffDays > 0) {
                                            notifyColor = 'rgba(255,191,0,0.6)';
                                            textCol = 'white';
                                        } else {
                                            textCol = 'white';
                                        }
                                        return (
                                            <TableRow
                                                key={row.scheduleName}
                                                style={{ backgroundColor: row.value1 ? notifyColor : 'rgba(255, 8, 0,0.6)', color: textCol }}
                                            >
                                                <TableCell style={{ color: 'inherit' }}>{row.name1}</TableCell>
                                                <TableCell style={{ color: 'inherit' }}>
                                                    {row.value1 ? (
                                                        <>
                                                            {moment(row.value1).format('DD/MM/YYYY')}
                                                            {row.value2 ? `/${row.value2}miles` : ''}
                                                        </>
                                                    ) : (
                                                        'No Date Found'
                                                    )}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {row.showExpiryBtn && (
                                                        <CustomIconButton
                                                            icon={EventIcon}
                                                            color={textCol}
                                                            className="modal-btn-update btn_space"
                                                            toolTipTitle="Refresh from DVLA"
                                                            onClick={() => handlePopup('showExpiryModal', true)}
                                                        />
                                                    )}
                                                    {row.showPopup ? (
                                                        <CustomIconButton
                                                            icon={AccessTimeIcon}
                                                            color={textCol}
                                                            className="modal-btn-update btn_space"
                                                            toolTipTitle="Schedules"
                                                            onClick={() => handlePopup('showScheduleAdd', true)}
                                                        />
                                                    ) : (
                                                        <CustomIconButton
                                                            icon={AccessTimeIcon}
                                                            color={textCol}
                                                            className="modal-btn-update btn_space"
                                                            toolTipTitle="Schedules"
                                                            onClick={() => {
                                                                changeIndex(5);
                                                            }}
                                                        />
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {data?.length <= 0 && (
                                        <TableRow key={1}>
                                            <TableCell align="center" rowSpan={3} style={{ color: 'inherit' }}>
                                                No upcoming events
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </div>
                    </TableContainer>
                </Grid>
            </Grid>
            {state.showScheduleAdd ? (
                <DialogComp
                    open={true}
                    onClose={() => handlePopup('showScheduleAdd', false)}
                    title="Add Schedule"
                    maxWidth="sm"
                    // name="addEdit"
                >
                    <ScheduleAdd
                        isAdd={true}
                        vehicleNextServiceMileage={dateFields.vehicleNextServiceMileage}
                        vehicleServiceScheduleID={dateFields.vehicleServiceScheduleID}
                        vehicleNextServiceDateDue={dateFields.vehicleNextServiceDateDue}
                        vehicleId={dateFields.vehicleID}
                        serviceAgentid={dateFields.serviceAgentID}
                        onClose={() => handlePopup('showScheduleAdd', false)}
                    />
                </DialogComp>
            ) : null}
            {state.showExpiryModal ? (
                <DialogComp open={true} onClose={() => handlePopup('showExpiryModal', false)} title="DVLA MOT & Tax Dates" maxWidth="sm">
                    <SetVehicleMOTTaxExpiryDate
                        onClose={() => handlePopup('showExpiryModal', false)}
                        vehicleId={dateFields.vehicleID}
                        onFormSubmit={closeModal}
                        regNo={props.regNo}
                    />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default VehicleDetails;
