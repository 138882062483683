import React, { useState, useEffect } from 'react';
import '../../../commonStyle.scss';
import { getAllJobQueryList } from '../../../../Core/Services/InternalUser/JOB/jobQueryService';
import JobDetail from './JOBItems/JobDetails';
import { Grid, Typography } from '@material-ui/core';
import moment from 'moment';

const JobSingleNote = (props) => {
    const { orderNo, name, dateCreated, message, dateReplied } = props.note;
    return (
        <>
            <div className="single-note">
                <div className="msg" style={{ fontSize: 14, fontStyle: 'italic' }}>
                    {message}
                </div>
                <div style={{ fontSize: 12, color: 'grey', fontStyle: 'italic', textAlign: 'left', float: 'left' }}>
                    {dateReplied ? moment(dateReplied).format('LLL') : '-'}
                </div>
                <div className="footer" style={{ fontSize: 12, color: 'grey', fontStyle: 'italic', textAlign: 'right', float: 'right' }}>
                    {name} @ {dateCreated ? moment(dateCreated).format('LLL') : '-'}
                </div>
                <br />
                <hr style={{ opacity: '30%' }} />
            </div>
        </>
    );
};
const JobQueriesScreen = (props) => {
    const [state, setState] = useState({
        open: false,
        showLoader: true,
        selectedRowData: {}
    });
    useEffect(async () => {
        pullApiData();
    }, [props.refreshDependancy]);

    const pullApiData = async () => {
        let res = await getAllJobQueryList(props.JobID);
        setState((st) => ({
            ...st,
            showLoader: false,
            list: res.data.list
        }));
    };

    return (
        <div>
            {/* {props.JobID && <JobDetail scheduleJobID={props.JobID} refresherFlag={state.refresherFlag} />} */}

            {!props.isHeaderNotShow && (
                <Grid
                    container
                    xs={12}
                    className="grid-style white-grid"
                    justify="space-between"
                    style={{ paddingBottom: '5px', paddingTop: '5px', paddingRight: '5px' }}
                >
                    <Grid item>
                        <Typography variant="h5" color="secondary">
                            Job Queries
                        </Typography>
                    </Grid>
                </Grid>
            )}

            <Grid
                item
                xs={12}
                style={{
                    padding: '5px',
                    // minHeight: '430px',
                    height: props.height || '430px',
                    overflow: 'auto'
                }}
                className="grid-style custom-scroll"
            >
                {state.list?.map((n, i) => (
                    <JobSingleNote key={i} note={n} />
                ))}
            </Grid>
        </div>
    );
};

export default JobQueriesScreen;
