import React, { useEffect } from 'react';
import { Tabs, Tab, Box } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import './tabStyle.scss';
const tabStyle = {
    boxShadow: '0px 1px rgba(0, 0, 0, 0.4)',
    tabWidth: { minWidth: 'auto' }
};

const getTabAndPanelId = (controlId, index) => {
    const id = controlId || 'dummy_tab';
    return {
        tabId: `${id}_${index}`,
        panelId: `${id}_tab_body_${index}`
    };
};

function TabPanel(props) {
    const { children, selectedTabIndex, index, controlId } = props;
    let { tabId, panelId } = getTabAndPanelId(controlId, index);
    return (
        <div role="tabpanel" hidden={selectedTabIndex !== index} id={panelId} aria-labelledby={tabId}>
            {selectedTabIndex === index && <Box p={0}>{children}</Box>}
        </div>
    );
}

export default function TabComponent(props) {
    const [state, setState] = React.useState({
        selectedIndex: undefined,
        tabList: []
    });
    useEffect(() => {
        setState((st) => ({ ...st, selectedIndex: props.selectedIndex }));
    }, [props.selectedIndex, props.timeDependency]);
    useEffect(() => {
        if (props.tabList) {
            setState((st) => {
                const nst = { ...st };
                nst.tabList = [...props.tabList];
                if (nst.selectedIndex !== undefined) {
                    if (nst.selectedIndex >= nst.tabList.length) {
                        nst.selectedIndex = nst.tabList - 1;
                    }
                } else if (nst.tabList.length) {
                    nst.selectedIndex = 0;
                } else {
                    nst.selectedIndex = undefined;
                }
                return nst;
            });
        }
    }, [props.tabList, state.selectedIndex]);

    const handleChange = (event, newValue) => {
        setState((st) => ({ ...st, selectedIndex: newValue }));
    };

    return (
        <div id={props.id} className="tab-component">
            <Tabs
                className="tab"
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                value={state.selectedIndex}
                onChange={handleChange}
                // style={{ width: 'calc(100vw - 292px)' }}
            >
                {state.tabList.map((m, i) => {
                    let { tabId, panelId } = getTabAndPanelId(props.id, i);
                    return <Tab key={i} label={m.label} id={tabId} aria-controls={panelId} style={tabStyle.tabWidth} />;
                })}
            </Tabs>
            {state.tabList.map((m, i) => {
                return (
                    <TabPanel key={i} selectedTabIndex={state.selectedIndex} index={i} controlId={props.id}>
                        {m.body}
                    </TabPanel>
                );
            })}
        </div>
    );
}
