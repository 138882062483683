import React, { useEffect, useState, useMemo } from 'react';
import { getAllVehicle } from '../../../../../Core/Services/InternalUser/ControllerService/vehicleService';
import { useToast } from '../../../../../Core/Hooks/useToast';
import { EditButton, DataGrid, formatters } from '../../../../../Core/Controls';
import AddEditVehicle from '../../Vehicles/addEditVehicle';
import { Link } from 'react-router-dom';
import '../../../../commonStyle.scss';
import GijgoGrid from '../../../../../Core/Controls/GijgoGrid';
import Grid from '@material-ui/core/Grid';
import { useHistory } from 'react-router-dom';

const baseColumns = [
    {
        key: 'action',
        name: 'Action',
        width: 110,
        addFileUploaderIcon: true
    },
    { key: 'vehicleRegNumber', name: 'Reg Number' },
    { key: 'vehicleMakeModel', name: 'Make/Model' },
    {
        key: 'vehicleDateOfRegistration',
        name: 'Date Of Reg',
        formatter: formatters.Date_DDMMYYYY
    },
    {
        key: 'vehicleMOTExpiry',
        name: 'MOT Expiry',
        formatter: formatters.Date_DDMMYYYY
    },
    {
        key: 'vehicleTaxDue',
        name: 'Tax Due',
        formatter: formatters.Date_DDMMYYYY
    }
];

const CustVehicleScreen = (props) => {
    const { showToastError } = useToast();
    const history = useHistory();

    const [state, setState] = useState({
        show: false,
        showEditPopup: false,
        showLoader: true,
        vehicleList: [],
        VehicleID: null
    });

    const pullVehicleAndUpdateState = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    useEffect(async () => {
        pullVehicleAndUpdateState();
    }, []);

    const handleAdd = (record) => {
        setState((st) => ({
            ...st,
            show: true
            // VehicleID: record.vehicleID
        }));
    };

    const closeVehicleModal = (res) => {
        setState((st) => ({
            ...st,
            show: false
        }));
        res && pullVehicleAndUpdateState();
    };

    const handleEditClick = (val) => {
        history.push({
            pathname: `/controller/editVehicle/${val.vehicleID}`
        });
    };

    const dataFormatter = (val, row) => {
        return (
            <Grid item>
                <EditButton toolTipTitle="Edit Vehicle" onClick={() => handleEditClick(row)} />
            </Grid>
        );
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.key === 'action');
        if (actionCol) {
            actionCol.formatter = dataFormatter;
        }
        return tempCols;
    }, []);
    // let setRow = (val) => {
    //     let filterRow = val.filter((m) => m.vehicleCustomerId == props.customerID);
    //     return filterRow;
    // };
    const title = state.VehicleID ? 'Update Vehicle' : 'Add Vehicle';
    return (
        <div>
            {state.show ? (
                <AddEditVehicle VehicleID={state.VehicleID} onFormSubmit={() => closeVehicleModal(true)} customerID={props.customerID} />
            ) : (
                <>
                    <GijgoGrid
                        btnTitle="Add Vehicle"
                        onBtnClick={handleAdd}
                        getUrl={`vehicles?customerID=${props.customerID}&mode=`}
                        columns={columns}
                        idFieldName="vehicleID"
                        fileUploaderConfigName="VehicleScreen"
                        dataConverter={(res) => ({
                            records: res.data.list,
                            total: res.data.total
                        })}
                        isReload={state.isReload}
                    />
                </>
            )}
        </div>
    );
};
export default CustVehicleScreen;
