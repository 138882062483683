import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { Button, FormHelperText, InputLabel } from "@material-ui/core";
import { InputText, SecondaryCheckbox, SelectBox, SecondaryButton, MandatoryField, SimpleField } from "../../../../Core/Controls";
import { getModelById, postModelData, getModelDropdownData } from "../../../../Core/Services/InternalUser/modelService";
import { useToast } from "../../../../Core/Hooks/useToast";
import { useLoader } from "../../../../Core/Hooks/useLoader";
import "../../../commonStyle.scss";

const DialogStyle = {
  dialogInput: { width: "100%" },
  mandatoryLabel: { color: "red" },
};

const AddEditModel = (props) => {
  const { showToastSuccess, showToastError } = useToast();
  const { showLoader, hideLoader } = useLoader();

  const [state, setState] = useState({
    modelActive: true,
    modelID: null,
    modelMargin: "",
    makeList: [],
    vehicleTypeList: [],
    modelWarrantyGroupCode: null,
    errors: {},
  });

  useEffect(async () => {
    if (props.modelId) {
      showLoader();
      let res = await getModelById(props.modelId);
      hideLoader();
      if (res.success) {
        setState((st) => ({
          ...st,
          makeDescription: res.data.makeDescription,
          modelActive: res.data.modelActive,
          modelCode: res.data.modelCode,
          modelDescription: res.data.modelDescription,
          modelID: res.data.modelID,
          modelMakeID: res.data.modelMakeID,
          modelMargin: res.data.modelMargin,
          modelPhoto: res.data.modelPhoto,
          modelWarrantyGroupCode: res.data.modelWarrantyGroupCode,
          vehicleTypeDescription: res.data.vehicleTypeDescription,
          modelVehicleTypeID: res.data.modelVehicleTypeID,
        }));
      }
    }
    let dropDwonRes = await getModelDropdownData();
    if (dropDwonRes.success) {
      const Makes = dropDwonRes.data.makes.map((w) => ({
        id: w.makeID,
        name: w.makeDescription,
      }));
      const vehicleType = dropDwonRes.data.vehicleTypes.map((w) => ({
        id: w.vehicleTypeID,
        name: w.vehicleTypeDescription,
      }));
      setState((st) => ({ ...st, makeList: Makes, vehicleTypeList: vehicleType }));
    }
  }, []);

  const inputChange = (e) => {
    const { name, value } = e.target;
    setState((st) => ({ ...st, [name]: value }));
  };

  let imagehandler = (event) => {
    let reader = new FileReader();
    reader.onload = (e) => {
      const { result } = e.target;
      setState((st) => ({ ...st, modelPhoto: result }));
    };
    reader.readAsDataURL(event.target.files[0]);
  };

  const clearImage = () => {
    if (state.modelPhoto) {
      setState((st) => ({ ...st, modelPhoto: null }));
    }
  };

  const validations = () => {
    const { modelCode, modelDescription, modelMakeID, modelMargin, modelVehicleTypeID } = state;
    const decimalPattern = /^\d+(\.\d{1,2})?$/;
    let formIsValid = true;
    let errors = {};
    if (!modelMakeID) {
      errors.modelMakeID = "This field is required";
      formIsValid = false;
    }
    if (!modelVehicleTypeID) {
      errors.modelVehicleTypeID = "This field is required";
      formIsValid = false;
    }
    if (!modelCode) {
      errors.modelCode = "This field is required";
      formIsValid = false;
    }
    if (!modelDescription) {
      errors.modelDescription = "This field is required";
      formIsValid = false;
    }
    if (!modelMargin.length && !decimalPattern.test(modelMargin)) {
      errors.modelMargin = `This field accepts only decimal value "1.0"`;
      formIsValid = false;
    }
    setState((st) => ({ ...st, errors: errors }));
    return formIsValid;
  };

  const selectHandler = (e) => {
    const { name, checked } = e.target;
    setState((state) => ({ ...state, [name]: checked }));
  };

  const submitHandler = async () => {
    if (validations()) {
      let formData = {
        modelID: state.modelID,
        modelCode: state.modelCode,
        modelDescription: state.modelDescription,
        modelMakeID: state.modelMakeID,
        modelPhoto: state.modelPhoto,
        modelVehicleTypeID: state.modelVehicleTypeID,
        modelWarrantyGroupCode: state.modelWarrantyGroupCode,
        modelMargin: state.modelMargin,
        modelActive: state.modelActive,
      };
      let res = await postModelData(formData);
      if (res.success) {
        showToastSuccess(res.message);
        props.onClose(true);
      } else {
        showToastError(res.message);
      }
    }
  };

  return (
    <Grid container direction={"row"} spacing={2}>
      <Grid item xs={12} sm={4} className="mandatory-fields">
        <MandatoryField
          inputLabel="Model Code"
          placeholder="Model Code"
          value={state.modelCode || ""}
          onChange={inputChange}
          name="modelCode"
          errors={state.errors.modelCode}
        />
      </Grid>
      <Grid item xs={12} sm={4} className="mandatory-fields">
        <MandatoryField
          inputLabel="Description"
          placeholder="Description"
          value={state.modelDescription || ""}
          onChange={inputChange}
          name="modelDescription"
          errors={state.errors.modelDescription}
        />
      </Grid>
      <Grid item xs={12} sm={4} className="mandatory-fields">
        <InputLabel shrink required>
          Make
        </InputLabel>
        <SelectBox name="modelMakeID" value={state.modelMakeID || ""} onChange={inputChange} List={state.makeList} />
        <FormHelperText error>{state.errors.modelMakeID}</FormHelperText>
      </Grid>
      <Grid item xs={12} sm={4} className="mandatory-fields">
        <InputLabel shrink required>
          Vehicle Type
        </InputLabel>
        <SelectBox name="modelVehicleTypeID" value={state.modelVehicleTypeID || ""} onChange={inputChange} List={state.vehicleTypeList} />
        <FormHelperText error>{state.errors.modelVehicleTypeID}</FormHelperText>
      </Grid>
      <Grid item xs={12} sm={4}>
        <SimpleField
          inputLabel="Warranty Group Code"
          placeholder="Warranty Group Code"
          value={state.modelWarrantyGroupCode || ""}
          onChange={inputChange}
          name="modelWarrantyGroupCode"
        />
      </Grid>
      <Grid item xs={12} sm={4} className="mandatory-fields">
        <MandatoryField
          inputLabel="Margin"
          placeholder="Margin"
          value={state.modelMargin || ""}
          onChange={inputChange}
          name="modelMargin"
          errors={state.errors.modelMargin}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        {state.modelPhoto ? (
          <SecondaryButton
            onClick={clearImage}
            style={{
              width: "50%",
              textTransform: "capitalize",
            }}
          >
            Remove Logo
          </SecondaryButton>
        ) : (
          <input type="file" name="image_value" onChange={imagehandler} accept="image/*" />
        )}
      </Grid>
      <Grid item xs={12} sm={4}>
        <SecondaryCheckbox checked={state.modelActive} name="modelActive" onChange={selectHandler} label="Is Active ?" />
      </Grid>
      <Grid item xs={12} className="submit_btn">
        <SecondaryButton onClick={submitHandler}>Submit</SecondaryButton>
      </Grid>
    </Grid>
  );
};

export default AddEditModel;
