import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { SecondaryButton, PrimaryButton, MandatoryField } from '../../../../Core/Controls';
import { getVehicleCategoriesById, postVehicleCategoriesData } from '../../../../Core/Services/InternalUser/vehicleCategoryService';
import { SecondaryCheckbox } from '../../../../Core/Controls';
import { useToast } from '../../../../Core/Hooks/useToast';
import ImageUploading from 'react-images-uploading';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import '../../.././commonStyle.scss';
import { Button, Tooltip } from '@material-ui/core';
import BackupIcon from '@material-ui/icons/Backup';
import ClearIcon from '@material-ui/icons/Clear';

const styles = {
    uplodbtn: {
        color: 'blue',
        cursor: 'pointer'
    },
    removebtn: {
        color: 'red',
        cursor: 'pointer'
    }
};

function VehicleCategoryModal(props) {
    const [state, setState] = useState({
        vehicleCategoryID: null,
        vehicleCategoryDescription: '',
        vehicleCategoryActive: true,
        errors: {},
        showBox: false,
        FilterimageList: []
    });
    const handleImagesBox = () => {
        setState((st) => ({
            ...st,
            showBox: !st.showBox
        }));
    };

    const { showToastSuccess, showToastError } = useToast();

    const [images, setImages] = useState([]);
    const maxNumber = 69;

    const onChange = (imageList, addUpdateIndex) => {
        // data for submit
        setImages(imageList);
        let FilterimagesList = imageList.map((m) => (m.data_url ? m.data_url : m));
        setState((m) => ({
            ...m,
            FilterimageList: FilterimagesList
        }));
    };

    useEffect(async () => {
        if (props.vehicleCategoryID) {
            let res = await getVehicleCategoriesById(props.vehicleCategoryID);
            if (res.success) {
                setState((state) => ({
                    ...state,
                    vehicleCategoryID: res.data.vehicleCategoryID,
                    vehicleCategoryDescription: res.data.vehicleCategoryDescription,
                    vehicleCategoryActive: res.data.vehicleCategoryActive
                }));

                setImages(res.data.vehicleCategoryImages);
            }
        }
    }, []);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setState((state) => ({
            ...state,
            [name]: value
        }));
    };
    const handleCheckbox = (e) => {
        const { name, checked } = e.target;
        setState((state) => {
            const nst = { ...state, [name]: checked };
            return nst;
        });
    };

    const validations = () => {
        const { vehicleCategoryDescription } = state;
        let emptyStrigPattern = /[^\s]/;
        let formIsValid = true;
        let errors = {};
        if (!emptyStrigPattern.test(vehicleCategoryDescription)) {
            errors.vehicleCategoryDescription = 'Vehicle Category Description  is required';
            formIsValid = false;
        }
        setState((state) => ({ ...state, errors: errors }));
        return formIsValid;
    };

    const submitHandler = async () => {
        if (validations()) {
            const ServiceAgentData = {
                vehicleCategoryID: state.vehicleCategoryID,
                vehicleCategoryDescription: state.vehicleCategoryDescription,
                vehicleCategoryActive: state.vehicleCategoryActive,
                vehicleCategoryImages: state.FilterimageList
            };

            let res = await postVehicleCategoriesData(ServiceAgentData);
            if (res.success) {
                showToastSuccess('Vehicle Category Saved Successfully');
                props.onClose(true);
            } else {
                showToastError(res.message);
            }
        }
    };

    return (
        <Grid container direction="row" spacing={2}>
            <Grid item xs={12} className="mandatory-fields">
                <MandatoryField
                    inputLabel="Vehicle Category Description"
                    name="vehicleCategoryDescription"
                    placeholder="Vehicle Category Description"
                    onChange={handleInputChange}
                    value={state.vehicleCategoryDescription}
                    errors={state.errors.vehicleCategoryDescription}
                />
            </Grid>
            <Grid item xs={12}>
                <SecondaryCheckbox checked={state.vehicleCategoryActive} name="vehicleCategoryActive" onChange={handleCheckbox} label="Is Active ?" />
            </Grid>
            <Button variant="contained" color="primary" onClick={handleImagesBox}>
                <BackupIcon /> &nbsp; upload
            </Button>

            <Grid item xs={12}>
                {state.showBox ? (
                    <DialogComp title={'Upload image'} onClose={handleImagesBox} maxWidth="lg">
                        <div style={{ width: 900, height: 900 }}>
                            <ImageUploading multiple value={images} onChange={onChange} maxNumber={maxNumber} dataURLKey="data_url">
                                {({ imageList, onImageUpload, onImageRemoveAll, onImageUpdate, onImageRemove }) => (
                                    // write your building UI
                                    <div className="upload__image-wrapper">
                                         <Grid container spacing={1}>
                                            <Grid item xs={12} md={3}>
                                                <Button variant="contained" color="primary" onClick={onImageUpload}>
                                                    <BackupIcon /> &nbsp; upload
                                                </Button>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Button variant="contained" color="secondary" onClick={onImageRemoveAll}>
                                                    Remove all images
                                                </Button>
                                            </Grid>
                                            </Grid>
                                        <Grid container spacing={2}>
                                            {imageList.map((image, index) => (
                                                <Grid item xs={2} key={index} className="image-item" style={{ marginTop: 30 }}>
                                                    <img src={image['data_url'] || image} alt="No images" width="100%" height="150px" />
                                                    <div className="image-item__btn-wrapper">
                                                        {/* <Tooltip title="Reupload">
                                                            <BackupIcon
                                                                fontSize="small"
                                                                onClick={onImageRemoveAll}
                                                                onClick={() => onImageUpdate(index)}
                                                                style={styles.uplodbtn}
                                                            />
                                                        </Tooltip> */}
                                                        <SecondaryButton onClick={onImageRemoveAll} onClick={() => onImageRemove(index)}>
                                                            Remove
                                                        </SecondaryButton>

                                                        {/* <Tooltip title="Remove">
                                                            <ClearIcon
                                                                fontSize="small"
                                                                onClick={onImageRemoveAll}
                                                                onClick={() => onImageRemove(index)}
                                                                style={styles.removebtn}
                                                            />
                                                        </Tooltip> */}
                                                    </div>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </div>
                                )}
                            </ImageUploading>
                        </div>
                    </DialogComp>
                ) : null}
            </Grid>

            <Grid item xs={12} className="submit_btn">
                <SecondaryButton onClick={submitHandler}>{props.vehicleCategoryID ? 'Update' : 'Add'}</SecondaryButton>
            </Grid>
        </Grid>
    );
}

export default VehicleCategoryModal;
