import React, { useEffect, useState, useMemo } from 'react';
import { getAllVehicle } from '../../../../Core/Services/InternalUser/ControllerService/vehicleService';
import { useToast } from '../../../../Core/Hooks/useToast';
import { AddButton, EditButton, DataGrid, BreadCrumbs, formatters } from '../../../../Core/Controls';
import AddEditVehicle from './addEditVehicle';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import EditVehicle from './editVehicle';
import { Link } from 'react-router-dom';
import moment from 'moment';
import '../../../commonStyle.scss';
import GijgoGrid from '../../../../Core/Controls/GijgoGrid';
import ReactDOM from 'react-dom';
import Grid from '@material-ui/core/Grid';
import CustomerDetails from '../DetailsModal/customerDetails';
import DepotDetails from '../DetailsModal/depotDetails';
import { useHistory } from 'react-router-dom';
import { AppStorage } from '../../../../Core/Basic/storage-service';
import Switch from '@material-ui/core/Switch';

const baseColumns = [
    {
        key: 'action',
        name: 'Action',
        width: 80,

        addFileUploaderIcon: true
    },
    { key: 'vehicleRegNumber', name: 'Reg Number', width: 90 },
    { key: 'vehicleMakeModel', name: 'Vehicle Description', width: 220 },
    {
        key: 'vehicleDateOfRegistration',
        name: 'Date Of Reg',
        formatter: formatters.Date_DDMMYYYY,
        width: 90
    },
    {
        key: 'vehicleMOTExpiry',
        name: 'MOT Expiry',
        formatter: formatters.dueDateFormatter,
        width: 90
    },
    {
        key: 'vehicleTaxDue',
        name: 'Tax Due',
        formatter: formatters.dueDateFormatter,
        width: 90
    },
    { key: 'mileage', name: 'Mileage', width: 90 },
    { key: 'driver', name: 'Driver', width: 90 },
    { key: 'customerCompanyName', name: 'Customer' },
    { key: 'depotName', name: 'Depot' },
    { key: 'endUser', name: 'End User' }
];

const baseCrumbs = [
    { name: 'Home', path: '/' },
    { name: 'Vehicles', path: '/controller/Vehicles/:all', active: true }
];

const VehicleScreen = (props) => {
    let checkhaveAccess = AppStorage.getAllowAcces();
    const { showToastError } = useToast();
    const [state, setState] = useState({
        show: false,
        showEditPopup: false,
        showLoader: true,
        vehicleList: [],
        VehicleID: null,
        isReload: null,

        activeToggle: true
    });

    let history = useHistory();

    const pullVehicleAndUpdateState = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    useEffect(async () => {
        let url = props.location.pathname;
        let urlIndex = url.lastIndexOf('/') + 1;
        let filterID = '';
        if (url[urlIndex] === ':' || url[urlIndex] === undefined || url[urlIndex] === null) {
            filterID = '';
        } else {
            filterID = url[urlIndex];
        }
        setState((st) => ({
            ...st,
            filterID: filterID
        }));
    }, [props.location.pathname]);

    const handleAdd = (record) => {
        setState((st) => ({
            ...st,
            show: true,
            VehicleID: record?.vehicleID
        }));
    };

    const closeVehicleModal = (res) => {
        setState((st) => ({
            ...st,
            show: false
        }));
        res && pullVehicleAndUpdateState();
    };

    const closeByBredCrumb = () => {
        setState((st) => ({
            ...st,
            show: false
        }));
    };

    const handleEditClick = (val) => {
        props.history.push({
            pathname: `/controller/editVehicle/${val.vehicleID}`,
            search: '',
            state: { fileCount: val.fileCount }
        });
    };

    const handelRowCick = (row, name) => {
        setState((st) => ({
            ...st,
            selectedRowData: row,
            [name]: true
        }));
    };

    const renderRegNumberEdit = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handleEditRegClick(row)} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.vehicleRegNumber}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const renderCustomerName = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handelRowCick(row, 'showCustomerModal')} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.customerCompanyName}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const renderDepotName = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handelRowCick(row, 'showDepotModal')} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.depotName}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const handleEditRegClick = (val) => {
        history.push({
            pathname: `/controller/editVehicle/${val.vehicleID}`,
            search: '',
            state: { fileCount: val.fileCount }
        });
    };

    const handleClose = () => {
        setState((st) => ({
            ...st,
            open: false,
            showCustomerModal: false,
            showDepotModal: false
        }));
    };

    const dataFormatter = (id, val) => {
        return (
            <Grid item>
                <EditButton toolTipTitle="Edit Vehicle" onClick={() => handleEditClick(val)} />
            </Grid>
        );
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.key === 'action');
        let customerNameCol = tempCols.find((element) => element.key === 'customerCompanyName');
        let depotNameCol = tempCols.find((element) => element.key === 'depotName');
        let regNumberCol = tempCols.find((element) => element.key === 'vehicleRegNumber');
        if (regNumberCol) {
            regNumberCol.formatter = renderRegNumberEdit;
        }
        if (actionCol) {
            actionCol.formatter = dataFormatter;
        }
        if (customerNameCol) {
            customerNameCol.formatter = renderCustomerName;
        }
        if (depotNameCol) {
            depotNameCol.formatter = renderDepotName;
        }
        return tempCols;
    }, []);

    const crumbs = useMemo(() => {
        const tempCrums = [...baseCrumbs];
        let url = props.location.pathname;
        let urlIndex = url.lastIndexOf('/') + 1;
        if (url[urlIndex] === 'T') {
            tempCrums.push({ name: 'Tax Due' });
        }
        if (url[urlIndex] === 'I') {
            tempCrums.push({ name: 'Insurance' });
        }
        return tempCrums;
    }, [props.location.pathname]);

    const title = state.VehicleID ? 'Update Vehicle' : 'Add Vehicle';

    const handleChange = (e) => {
        const { name, checked } = e.target;

        setState((st) => ({
            ...st,
            [name]: checked
        }));
    };

    return (
        <div className="screen">
            {state.show ? (
                <AddEditVehicle VehicleID={state.VehicleID} onFormSubmit={() => closeVehicleModal(true)} crumbClose={closeByBredCrumb} />
            ) : (
                <>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <BreadCrumbs crumbs={crumbs} />

                        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                            <span style={{ marginTop: '7px' }}>Archived</span>
                            <Switch checked={state.activeToggle} onChange={handleChange} value="activeToggle" name="activeToggle" />
                            <span style={{ marginTop: '7px' }}>Active</span>
                        </div>
                    </div>
                    <GijgoGrid
                        columns={columns}
                        getUrl={`vehicles?mode=${state.filterID}&Active=${state.activeToggle}`}
                        idFieldName="vehicleID"
                        fileUploaderConfigName="VehicleScreen"
                        dataConverter={(res) => ({
                            records: res.data.list,
                            total: res.data.total
                        })}
                        isReload={state.isReload}
                        onBtnClick={handleAdd}
                        btnTitle={`${checkhaveAccess?.CanUpdateVehicles ? 'Add Vehicle' : ''}`}
                    />
                </>
            )}
            {state.showEditPopup ? <EditVehicle /> : null}
            {state.showCustomerModal ? (
                <DialogComp open={true} onClose={handleClose} title={'Customer Details'}>
                    <CustomerDetails customerID={state.selectedRowData.vehicleCustomerId} onFormSubmit={handleClose} />
                </DialogComp>
            ) : null}
            {state.showDepotModal ? (
                <DialogComp open={true} onClose={handleClose} title={'Depot Details'}>
                    <DepotDetails customerID={state.selectedRowData.vehicleDepotID} onFormSubmit={handleClose} />
                </DialogComp>
            ) : null}
        </div>
    );
};
export default VehicleScreen;
