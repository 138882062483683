import { postWithAuth, getWithAuth } from '../../Basic/HttpService';

export const getCostTypes = async () => {
    let res = await getWithAuth(`CostTypes`);
    return res;
};

export const getCostTypesById = async (id) => {
    let res = await getWithAuth(`CostTypes/${id}`);
    return res;
};

export const postCostTypesData = async (data) => {
    let res = await postWithAuth(`CostTypes`, data);
    return res;
};
