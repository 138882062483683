import { postWithAuth, getWithAuth } from '../../Basic/HttpService';

export const getAllFilterCustomers = async () => {
    let res = await getWithAuth(`UserFilterCustomers`);
    return res;
};

export const postFilterCustomers = async (data) => {
    let res = await postWithAuth(`UserFilterCustomers`, data);
    return res;
};
