import { postWithAuth, getWithAuth } from '../../../Basic/HttpService';

export const postRecalls = async (data) => {
    let res = await postWithAuth(`recalls `, data);
    return res;
};

export const getRecallByID = async (id) => {
    let res = await getWithAuth(`recalls/${id}`);
    return res;
};

export const getRecallLogByID = async (id) => {
    let res = await getWithAuth(`recalls/RecallLog?RecallID=${id}`);
    return res;
};

export const postRecallLog = async (data) => {
    let res = await postWithAuth(`recalls/RecallLog`, data);
    return res;
};

export const postRecallBookedStep = async (data) => {
    let res = await postWithAuth(`recalls/AssignAgent`, data);
    return res;
};

export const postRecallOnsiteAndWorkedShop = async (data) => {
    let res = await postWithAuth(`recalls/AddOnSiteWorkShop`, data);
    return res;
};

export const postRecallCompleted = async (data) => {
    let res = await postWithAuth(`recalls/RecallComplete`, data);
    return res;
};
