import React, { useEffect, useState, useCallback } from 'react';
import { MarkerClusterer, SuperClusterAlgorithm } from '@googlemaps/markerclusterer';
import Geocode from 'react-geocode';
import Grid from '@material-ui/core/Grid';
import { CustomIconButton } from '../../../../Core/Controls/Inputs/Buttons/ButtonModal';
import InputAdornment from '@material-ui/core/InputAdornment';
import { InputText, SecondaryButton } from '../../../../Core/Controls';
import SearchIcon from '@material-ui/icons/Search';
import Checkbox from '@material-ui/core/Checkbox';
import { Typography } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import serviceLogo from '../../../../Marker/Service.png';
import recoveryLogo from '../../../../Marker/Recovery.png';
import closestLogo from '../../../../Marker/Closest.png';

import car_repair_amberLogo from '../../../../Marker/car_repair_amber.png';
import car_repair_redLogo from '../../../../Marker/car_repair_red.png';
import car_repair_greenLogo from '../../../../Marker/car_repair_green.png';

import locationLogo from '../../../../Marker/car_pin.png';
import hgvLogo from '../../../../Marker/hgv_pin.png';
import vanLogo from '../../../../Marker/van_pin.png';
import trailerLogo from '../../../../Marker/trailer_pin.png';
import busLogo from '../../../../Marker/bus_pin.png';
import redLogo from '../../../../Marker/redIcon.png';

let tempObj = {
    hgv: hgvLogo,
    van: vanLogo,
    trailer: trailerLogo,
    psv: busLogo,
    car: locationLogo
};

Geocode.setApiKey('AIzaSyC5WVAZAkE2SUN3G6aj4kvjhfWJjM_yC_w');
Geocode.setLanguage('en');
Geocode.setLocationType('ROOFTOP');
Geocode.enableDebug();

const GetAgentLocation2 = (props) => {
    let vehcileCategoriesLogo = '';

    if (props.vehcileCategories && tempObj[props.vehcileCategories.toLowerCase()]) {
        vehcileCategoriesLogo = tempObj[props.vehcileCategories.toLowerCase()];
    } else {
        vehcileCategoriesLogo = tempObj['car'];
    }

    const google = window.google;
    const [state, setState] = useState({
        mapLocation: [],
        FilterList: [],
        tempFilterList: [],
        filterRecoveryAgent: [],
        serviceagent: true,
        recoveryAgent: false,
        location: props.location,
        isOpen: false,
        activeMarker: {},
        showingInfoWindow: false,
        currentLat: 53.15296,
        currentLang: -2.11704,
        arr_facilities: [],
        facilitiId: [],
        zoom: props.location ? 12 : 6
    });

    const initMap = () => {
        let isLastEventIsClicked = false;
        const map = new google.maps.Map(document.getElementById('map'), {
            zoom: state.zoom,
            center: { lat: state.currentLat, lng: state.currentLang }
        });

        const infoWindow = new google.maps.InfoWindow({
            content: '',
            disableAutoPan: true
        });

        infoWindow.addListener('closeclick', () => {
            isLastEventIsClicked = false;
        });

        let markers = state.tempFilterList.map((position, i) => {
            const label = '';
            let locatinAddress = `${position[2]},${position[3]}\n${position[4]},${position[5]}(${position[6]})`;
            const contentString =
                '<div>' +
                `<div>
                        <h2>${position[1]}</h2>
                    </div>` +
                `<div>
                        <p>
                          <b>Address:-</b> <a href="http://maps.google.com?q=${locatinAddress}" target="_blank">${locatinAddress}</a>
                        </p>
                        <p><b>Tel:-</b> <a href="tel:${position[7]}">${position[7]}</a></p>
                    </div>` +
                '</div>';

            const marker = new google.maps.Marker({
                position: { lat: +position[8], lng: +position[9] },
                label,
                icon: { url: position[11], size: { width: 40, height: 80 }, anchor: { x: 15, y: 50 }, scaledSize: { width: 25, height: 45 } }
            });

            marker.addListener('click', () => {
                isLastEventIsClicked = true;
                infoWindow.setContent(contentString);
                infoWindow.open(map, marker);
            });

            marker.addListener('mouseover', function () {
                if (!isLastEventIsClicked) {
                    infoWindow.setContent(contentString);
                    infoWindow.open(map, marker);
                }
            });

            marker.addListener('mouseout', function () {
                if (!isLastEventIsClicked) {
                    infoWindow.close();
                }
            });

            return marker;
        });

        markers.push(
            new google.maps.Marker({
                position: { lat: state.currentLat, lng: state.currentLang },
                label: '',
                icon: { url: vehcileCategoriesLogo, size: { width: 50, height: 100 }, anchor: { x: 15, y: 50 }, scaledSize: { width: 40, height: 55 } }
            })
        );
        new MarkerClusterer({ markers, map });
    };

    useEffect(() => {
        let urls = ['/agent_facilities.json', '/latlang.json'];
        Promise.all(urls.map((url) => fetch(url).then((resp) => resp.text()))).then((texts) => {
            setState((st) => ({
                ...st,
                arr_facilities: eval(texts[0]),
                mapLocation: eval(texts[1])
            }));
        });
    }, []);

    useEffect(() => {
        fetchCurrentLocation();
    }, [state.mapLocation]);

    useEffect(() => {
        initMap();
    }, [state.tempFilterList]);

    function deg2rad(deg) {
        return deg * (Math.PI / 180);
    }

    const fetchCurrentLocation = () => {
        let FilterList = [];
        const tempArray = [...state.mapLocation];
        Geocode.fromAddress(state.location || 'CA81UR').then(
            (response) => {
                if (response) {
                    const { lat, lng } = response.results[0].geometry.location;
                    tempArray.forEach((l, i) => {
                        // var R = 6371; // Radius of the earth in km
                        var R = 3958; // Radius of the earth in miles
                        var dLat = deg2rad(l[8] - lat);
                        var dLon = deg2rad(l[9] - lng);
                        var a =
                            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                            Math.cos(deg2rad(lat)) * Math.cos(deg2rad(l[8])) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
                        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
                        var d = R * c; // Distance in miles
                        if (d < 10) {
                            l.splice(11, 0, car_repair_greenLogo);
                        } else if (d > 10 && d < 20) {
                            l.splice(11, 0, car_repair_amberLogo);
                        } else {
                            l.splice(11, 0, car_repair_redLogo);
                        }
                        if (d < 1000) {
                            FilterList.push(l);
                        }
                    });
                    setState((st) => ({ ...st, FilterList: FilterList, tempFilterList: FilterList, currentLat: lat, currentLang: lng }));
                }
            },
            (error) => {
                console.error(error);
            }
        );
    };

    const inputChange = (id) => (e) => {
        const { name, checked } = e.target;
        let tempServiceFacList = [...state.facilitiId];
        let tempFilterList = [];
        if (checked) {
            tempServiceFacList.push(id);
        } else {
            let tempIndex = tempServiceFacList.findIndex((q) => q == id);
            tempServiceFacList.splice(tempIndex, 1);
        }
        if (tempServiceFacList.length > 0) {
            state.FilterList.forEach(function (n) {
                let chekExist = tempServiceFacList.find((l) => l == n[10]?.split(',').find((p) => l == p));
                if (chekExist) {
                    tempFilterList.push(n);
                }
            });
        } else tempFilterList = [...state.FilterList];
        setState((st) => ({ ...st, [name]: checked, facilitiId: tempServiceFacList, tempFilterList: tempFilterList }));
    };

    const handleChange = useCallback((e) => {
        const { name, value, type } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    }, []);

    const handleEnter = (e) => {
        if (e.key === 'Enter') {
            fetchCurrentLocation();
            setState((st) => ({ ...st, zoom: 12 }));
        }
    };

    const getLocation = () => {
        props.updateLocation(state.location);
    };

    const searchHandle = () => {
        fetchCurrentLocation();
        setState((st) => ({ ...st, zoom: 12 }));
    };

    return (
        <div style={{ height: '100%' }}>
            <Grid container spacing={2} style={{ height: '100%' }}>
                <Grid xs={2} item>
                    <Grid container spacing={1}>
                        <Grid xs={12} item>
                            <InputText
                                autoFocus={true}
                                placeholder="Post Code"
                                value={state.location}
                                onChange={handleChange}
                                onKeyDown={handleEnter}
                                name="location"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <CustomIconButton toolTipTitle="Get location" onClick={searchHandle} icon={SearchIcon} />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                        {props.updateLocation && (
                            <Grid xs={12} item>
                                <SecondaryButton onClick={getLocation}>Submit</SecondaryButton>
                            </Grid>
                        )}
                    </Grid>

                    <Grid container>
                        <Typography variant="h6">Service Agent Facilities</Typography>
                        {state.arr_facilities.map((f) => {
                            return (
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={<Checkbox checked={state[f[1].trim()]} onChange={inputChange(f[0])} name={f[1].trim()} />}
                                        label={f[1]}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>
                <Grid xs={10} item>
                    <div id="map">Loading...</div>
                </Grid>
            </Grid>
        </div>
    );
};
export default GetAgentLocation2;
