import React from 'react';
import { BreadCrumbs, TabView } from '../../../../../Core/Controls';
import EditDepotDash from './depotDashboard';
import { useHistory } from 'react-router-dom';
import Department from './Department';

export default function EditDepot(props) {
    let history = useHistory();
    const id = props.match.params.id;

    // const name = props.match.params.name;

    const crumbs = [
        { name: 'Home', path: '/' },
        { name: 'Depots', path: '/controller/Depots' }
    ];
    const closeScreen = () => {
        history.push(`/controller/Depots`);
    };
    const tabs = [
        {
            label: 'Dashboard',
            body: <EditDepotDash depotID={id} onFormClose={closeScreen} />
        },
        {
            label: 'Department',
            body: <Department depotID={id} onFormClose={closeScreen} />
        }
    ];

    return (
        <div className="screen">
            <BreadCrumbs crumbs={crumbs} className="edit-vehicle-btn" m={5} />
            <TabView tabList={tabs} id="testing_tabs" />
        </div>
    );
}
