import React, { useEffect, useState, useMemo } from 'react';
import { useToast } from '../../../../../Core/Hooks/useToast';
import { getDepots } from '../../../../../Core/Services/InternalUser/ControllerService/depotsService';
import { EditButton, DataGrid, BreadCrumbs, formatters } from '../../../../../Core/Controls';
import AddEditDepots from './addEditDepots';
import EditDepot from './editDashboard';
import '../../../../commonStyle.scss';
import { Link } from 'react-router-dom';
import GijgoGrid from '../../../../../Core/Controls/GijgoGrid';
import ReactDOM from 'react-dom';
import DialogComp from '../../../../../Core/Dialog/DialogComp';
import CustomerDetails from '../../DetailsModal/customerDetails';
import { AppStorage } from '../../../../../Core/Basic/storage-service';

const baseColumns = [
    { key: 'action', name: 'Action', width: 80, addFileUploaderIcon: true },
    { key: 'depotName', name: 'Name' },
    { key: 'customer', name: 'Customer', width: 180 },
    // { key: 'depotAddress2', name: 'Address2' },
    // { key: 'depotTown', name: 'Town' },
    // { key: 'depotCounty', name: 'County' },
    // { key: 'depotCountryCode', name: 'Code', width: '4%' },
    { key: 'depotEmail', name: 'Email', width: 250 },
    {
        key: 'depotAddress',
        name: 'Address'
    }
];

const crumbs = [
    { name: 'Home', path: '/' },
    // { name: 'Vehicles', active: true },
    { name: 'Depots', active: true }
];

const VehicleDepots = (props) => {
    const { showToastError } = useToast();
    let getAccessParam = AppStorage.getAllowAcces();
    const [state, setState] = useState({
        show: false,
        showLoader: true,
        depotsList: [],
        depotID: null,
        isReload: null
    });

    const handleAdd = (record) => {
        setState((st) => ({
            ...st,
            show: true,
            depotID: record
        }));
    };

    const pullDepotsAndUpdateState = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    // useEffect(async () => {
    //     pullDepotsAndUpdateState();
    // }, []);

    const handleEditClick = (val) => {
        props.history.push({
            pathname: `/controller/Depots/${val.depotID}`,
            search: '',
            state: { fileCount: val.fileCount }
        });
    };

    const dataFormatter = (data, row) => {
        return (
            <div
                style={{
                    textAlign: 'center'
                }}
            >
                <EditButton toolTipTitle="Edit Depot" onClick={() => handleEditClick(row)} />
            </div>
        );
    };

    const closeDepotsModal = (res) => {
        setState((st) => ({
            ...st,
            show: false
        }));
        res && pullDepotsAndUpdateState();
    };

    const renderEmail = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = <div style={{ cursor: 'pointer', color: '#f50057' }}>{formatters.mailFormatter(row.depotEmail)}</div>;
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const handelRowCick = (row, name) => {
        setState((st) => ({
            ...st,
            selectedRowData: row,
            [name]: true
        }));
    };

    const renderCustomerName = ({ row, col }, grid) => {
        const spn = document.createElement('span');
        const lnk = (
            <div onClick={() => handelRowCick(row, 'showCustomerModal')} style={{ cursor: 'pointer', color: 'black', fontWeight: 'bold' }}>
                {row.customer}
            </div>
        );
        ReactDOM.render(lnk, spn);
        return spn;
    };

    const handleClose = () => {
        setState((st) => ({
            ...st,
            open: false,
            showCustomerModal: false
        }));
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.key === 'action');
        let emailCol = tempCols.find((element) => element.key === 'depotEmail');
        let customerNameCol = tempCols.find((element) => element.key === 'customer');
        if (emailCol) {
            emailCol.formatter = renderEmail;
        }
        if (actionCol) {
            actionCol.formatter = dataFormatter;
        }
        if (customerNameCol) {
            customerNameCol.formatter = renderCustomerName;
        }
        // if (window.innerWidth > 600) {
        //     tempCols.splice(
        //         3,
        //         0,
        //         { key: 'depotAddress1', name: 'Address1' },
        //         { key: 'depotAddress2', name: 'Address2' },
        //         { key: 'depotTown', name: 'Town' },
        //         { key: 'depotCounty', name: 'County' },
        //         { key: 'depotCountryCode', name: 'Code', width: '4%' }
        //     );
        // }
        return tempCols;
    }, []);

    let getUrl = `depots`;

    return (
        <div className="screen">
            {/* <EditDepot depotID={state.depotID} onFormClose={closeDepotsModal} /> */}
            {/* <AddEditDepots depotID={state.depotID} onFormClose={closeDepotsModal} /> */}
            {state.show ? (
                <AddEditDepots depotID={state.depotID} onFormClose={closeDepotsModal} />
            ) : (
                <>
                    <BreadCrumbs crumbs={crumbs} />
                    <GijgoGrid
                        dataConverter={(res) => ({
                            records: res.data.list,
                            total: res.data.total
                        })}
                        columns={columns}
                        getUrl={getUrl}
                        onBtnClick={() => handleAdd(null)}
                        btnTitle={getAccessParam['CanUpdateDepots'] ? 'Add Depots' : ''}
                        isReload={state.isReload}
                        idFieldName="depotID"
                        fileUploaderConfigName="DepotScreen"
                    />
                </>
            )}
            {state.showCustomerModal ? (
                <DialogComp open={true} onClose={handleClose} title={'Customer Details'}>
                    <CustomerDetails customerID={state.selectedRowData.customerID} onFormSubmit={handleClose} />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default VehicleDepots;
