import React from 'react';
import singleAxel from './VehicleDiagrams/images1.png';
import doubleAxelOne from './VehicleDiagrams/axImage01.png';
import doubleAxelTwo from './VehicleDiagrams/azImage02.png';
import doubleAxelThree from './VehicleDiagrams/axImage03.png';
import Grid from '@material-ui/core/Grid';
import { SimpleField, SecondaryCheckbox, InputText } from '../../../../../Core/Controls';
import { InputLabel, Typography, FormHelperText } from '@material-ui/core';

const style = {
    axOS: {
        marginTop: '45px'
    },
    axOS2: {
        marginTop: '152px'
    },
    ax2OS: {
        marginTop: '-17px'
    },
    ax2OS2: {
        marginTop: '8px'
    },
    ax2NS: { marginTop: '150px' },
    ax2Break: { marginBottom: '5px' },
    axBreakHeading: { textAlign: 'center' }
};

const Axel = (props) => {
    const { handleInputs, state, classes } = props;

    return (
        <>
            <Grid container spacing={4} xs={12} style={style.ax2Break} justify="space-evenly">
                {/* Axel 1...................... */}
                <Grid item xs={12} sm={6} md={6} lg={3}>
                    <Grid item xs={12} sm={12}>
                        <h3 className={classes.axelTitles}>AXLE 1</h3>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Grid container xs={12} direction="row" justify="space-evenly">
                            <Grid item xs={3}>
                                <InputLabel shrink error style={style.axOS}>
                                    Depth*
                                </InputLabel>

                                <SimpleField placeholder="mm" name="osTreadDepth" onChange={handleInputs(1)} value={state.osTreadDepth} />
                                <InputLabel shrink error style={style.axOS2}>
                                    Depth*
                                </InputLabel>

                                <SimpleField placeholder="mm" name="nsTreadDepth" onChange={handleInputs(1)} value={state.nsTreadDepth} />
                            </Grid>
                            <Grid item xs={3}>
                                <InputLabel shrink error>
                                    Brakelining*
                                </InputLabel>
                                <SimpleField placeholder="%" name="osBrakeLineRemaining" onChange={handleInputs(1)} value={state.nsTreadDepth} />
                                <img
                                    src={singleAxel}
                                    height="250px"
                                    width="100%"
                                    style={{
                                        marginTop: 5
                                    }}
                                />
                                <InputLabel shrink error>
                                    Brakelining*
                                </InputLabel>
                                <SimpleField placeholder="%" name="nsBrakeLineRemaining" onChange={handleInputs(1)} value={state.nsTreadDepth} />
                            </Grid>
                            <Grid item xs={3}>
                                <InputLabel shrink error style={style.axOS}>
                                    Pressure*
                                </InputLabel>
                                <SimpleField placeholder="psi" name="oSTyrePressure" onChange={handleInputs(1)} value={state.oSTyrePressure} />
                                <InputLabel shrink error style={style.axOS2}>
                                    Pressure*
                                </InputLabel>
                                <SimpleField placeholder="psi" name="nSTyrePressure" onChange={handleInputs(1)} value={state.nSTyrePressure} />
                            </Grid>
                        </Grid>
                        <FormHelperText error style={{ textAlign: 'center' }}>
                            {state.errors?.axelone}
                        </FormHelperText>
                    </Grid>
                </Grid>
                {/* Axel 2 ..................... */}
                <Grid item xs={12} sm={6} md={6} lg={3}>
                    <h3 className={classes.axelTitles}>AXLE 2</h3>
                    <Grid item xs={12} sm={12}>
                        <Grid container xs={12} direction="row" justify="space-evenly">
                            <Grid item xs={3}>
                                <InputLabel shrink style={style.ax2OS} error>
                                    Depth*
                                </InputLabel>
                                <SimpleField placeholder="mm" name="osTreadDepth" onChange={handleInputs(2)} value={state.osTreadDepth} />
                                <InputLabel shrink style={style.ax2OS2} error>
                                    Depth*
                                </InputLabel>
                                <SimpleField placeholder="mm" name="osFrontTreadDepth" onChange={handleInputs(2)} value={state.osFrontTreadDepth} />
                                <InputLabel shrink style={style.ax2NS} error>
                                    Depth*
                                </InputLabel>
                                <SimpleField placeholder="mm" name="nsFrontTreadDepth" onChange={handleInputs(2)} value={state.nsFrontTreadDepth} />
                                <InputLabel shrink style={style.ax2OS2} error>
                                    Depth*
                                </InputLabel>
                                <SimpleField placeholder="mm" name="nsTreadDepth" onChange={handleInputs(2)} value={state.nsTreadDepth} />
                            </Grid>
                            <Grid item xs={3}>
                                <img src={doubleAxelOne} height="100px" width="100%" />
                                <InputLabel shrink error style={style.axBreakHeading}>
                                    Brakelining*
                                </InputLabel>
                                <SimpleField placeholder="%" name="osBrakeLineRemaining" onChange={handleInputs(2)} value={state.osBrakeLineRemaining} />
                                <img src={doubleAxelTwo} height="45px" width="100%" />
                                <InputLabel shrink error style={style.axBreakHeading}>
                                    Brakelining*
                                </InputLabel>
                                <SimpleField
                                    placeholder="%"
                                    name="nsBrakeLineRemaining"
                                    onChange={handleInputs(2)}
                                    value={state.nsBrakeLineRemaining}
                                    style={style.ax2Break}
                                />
                                <img src={doubleAxelThree} height="100px" width="100%" />
                            </Grid>
                            <Grid item xs={3}>
                                <InputLabel shrink style={style.ax2OS} error>
                                    Pressure*
                                </InputLabel>
                                <SimpleField placeholder="psi" name="oSTyrePressure" onChange={handleInputs(2)} value={state.oSTyrePressure} />
                                <InputLabel shrink style={style.ax2OS2} error>
                                    Pressure*
                                </InputLabel>
                                <SimpleField placeholder="psi" name="oSFrontTyrePressure" onChange={handleInputs(2)} value={state.oSFrontTyrePressure} />
                                <InputLabel shrink style={style.ax2NS} error>
                                    Pressure*
                                </InputLabel>
                                <SimpleField placeholder="psi" name="nSFrontTyrePressure" onChange={handleInputs(2)} value={state.nSFrontTyrePressure} />
                                <InputLabel shrink style={style.ax2OS2} error>
                                    Pressure*
                                </InputLabel>
                                <SimpleField placeholder="psi" name="nSTyrePressure" onChange={handleInputs(2)} value={state.nSTyrePressure} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <FormHelperText error style={{ textAlign: 'center' }}>
                        {state.errors?.axeltwo}
                    </FormHelperText>
                </Grid>

                {/* Axel 3 ..................... */}
                <Grid item xs={12} sm={6} md={6} lg={3}>
                    <h3 className={classes.axelTitles}>AXLE 3</h3>
                    <Grid item xs={12} sm={12}>
                        <Grid container xs={12} direction="row" justify="space-evenly">
                            <Grid item xs={3}>
                                <InputLabel shrink style={style.ax2OS}>
                                    Depth
                                </InputLabel>
                                <SimpleField placeholder="mm" name="osTreadDepth" onChange={handleInputs(3)} value={state.osTreadDepth} />
                                <InputLabel shrink style={style.ax2OS2}>
                                    Depth
                                </InputLabel>
                                <SimpleField placeholder="mm" name="osFrontTreadDepth" onChange={handleInputs(3)} value={state.osFrontTreadDepth} />
                                <InputLabel shrink style={style.ax2NS}>
                                    Depth
                                </InputLabel>
                                <SimpleField placeholder="mm" name="nsFrontTreadDepth" onChange={handleInputs(3)} value={state.nsFrontTreadDepth} />
                                <InputLabel shrink style={style.ax2OS2}>
                                    Depth
                                </InputLabel>
                                <SimpleField placeholder="mm" name="nsTreadDepth" onChange={handleInputs(3)} value={state.nsTreadDepth} />
                            </Grid>
                            <Grid item xs={3}>
                                <img src={doubleAxelOne} height="100px" width="100%" />
                                <InputLabel shrink style={style.axBreakHeading}>
                                    Brakelining
                                </InputLabel>
                                <SimpleField placeholder="%" name="osBrakeLineRemaining" onChange={handleInputs(3)} value={state.osBrakeLineRemaining} />

                                <img src={doubleAxelTwo} height="45px" width="100%" />
                                <InputLabel shrink style={style.axBreakHeading}>
                                    Brakelining
                                </InputLabel>
                                <SimpleField
                                    placeholder="%"
                                    name="nsBrakeLineRemaining"
                                    onChange={handleInputs(3)}
                                    value={state.nsBrakeLineRemaining}
                                    style={style.ax2Break}
                                />

                                <img src={doubleAxelThree} height="100px" width="100%" />
                            </Grid>
                            <Grid item xs={3}>
                                <InputLabel shrink style={style.ax2OS}>
                                    Pressure
                                </InputLabel>
                                <SimpleField placeholder="psi" name="oSTyrePressure" onChange={handleInputs(3)} value={state.oSTyrePressure} />
                                <InputLabel shrink style={style.ax2OS2}>
                                    Pressure
                                </InputLabel>
                                <SimpleField placeholder="psi" name="oSFrontTyrePressure" onChange={handleInputs(3)} value={state.oSFrontTyrePressure} />
                                <InputLabel shrink style={style.ax2NS}>
                                    Pressure
                                </InputLabel>
                                <SimpleField placeholder="psi" name="nSFrontTyrePressure" onChange={handleInputs(3)} value={state.nSFrontTyrePressure} />
                                <InputLabel shrink style={style.ax2OS2}>
                                    Pressure
                                </InputLabel>
                                <SimpleField placeholder="psi" name="nSTyrePressure" onChange={handleInputs(3)} value={state.nSTyrePressure} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                {/* Axel 4 ..................... */}
                <Grid item xs={12} sm={6} md={6} lg={3}>
                    <h3 className={classes.axelTitles}>AXLE 4</h3>
                    <Grid item xs={12} sm={12}>
                        <Grid container xs={12} direction="row" justify="space-evenly">
                            <Grid item xs={3}>
                                <InputLabel shrink style={style.ax2OS}>
                                    Depth
                                </InputLabel>
                                <SimpleField placeholder="mm" name="osTreadDepth" onChange={handleInputs(4)} value={state.osTreadDepth} />
                                <InputLabel shrink style={style.ax2OS2}>
                                    Depth
                                </InputLabel>
                                <SimpleField placeholder="mm" name="osFrontTreadDepth" onChange={handleInputs(4)} value={state.osFrontTreadDepth} />
                                <InputLabel shrink style={style.ax2NS}>
                                    Depth
                                </InputLabel>
                                <SimpleField placeholder="mm" name="nsFrontTreadDepth" onChange={handleInputs(4)} value={state.nsFrontTreadDepth} />
                                <InputLabel shrink style={style.ax2OS2}>
                                    Depth
                                </InputLabel>
                                <SimpleField placeholder="mm" name="nsTreadDepth" onChange={handleInputs(4)} value={state.nsTreadDepth} />
                            </Grid>
                            <Grid item xs={3}>
                                <img src={doubleAxelOne} height="100px" width="100%" />
                                <InputLabel style={style.axBreakHeading} shrink>
                                    Brakelining
                                </InputLabel>
                                <SimpleField placeholder="%" name="nsBrakeLineRemaining" onChange={handleInputs(4)} value={state.nsBrakeLineRemaining} />

                                <img src={doubleAxelTwo} height="45px" width="100%" />
                                <InputLabel style={style.axBreakHeading} shrink>
                                    Brakelining
                                </InputLabel>
                                <SimpleField
                                    placeholder="%"
                                    name="osBrakeLineRemaining"
                                    onChange={handleInputs(4)}
                                    value={state.oSTyrePressure}
                                    style={style.ax2Break}
                                />

                                <img src={doubleAxelThree} height="100px" width="100%" />
                            </Grid>
                            <Grid item xs={3}>
                                <InputLabel shrink style={style.ax2OS}>
                                    Pressure
                                </InputLabel>
                                <SimpleField placeholder="psi" name="oSTyrePressure" onChange={handleInputs(4)} value={state.oSTyrePressure} />
                                <InputLabel shrink style={style.ax2OS2}>
                                    Pressure
                                </InputLabel>
                                <SimpleField placeholder="psi" name="oSFrontTyrePressure" onChange={handleInputs(4)} value={state.oSFrontTyrePressure} />
                                <InputLabel shrink style={style.ax2NS}>
                                    Pressure
                                </InputLabel>
                                <SimpleField placeholder="psi" name="nSFrontTyrePressure" onChange={handleInputs(4)} value={state.nSFrontTyrePressure} />
                                <InputLabel shrink style={style.ax2OS2}>
                                    Pressure
                                </InputLabel>
                                <SimpleField placeholder="psi" name="nSTyrePressure" onChange={handleInputs(4)} value={state.nSTyrePressure} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <FormHelperText error style={{ textAlign: 'center' }}>
                    {state.showTost ? state.axleErrors.message : ''}
                </FormHelperText>
            </Grid>
        </>
    );
};

export default Axel;
