import React, { useEffect, useState, useCallback } from 'react';
import { useToast } from '../../../../Core/Hooks/useToast';
import { addDepot, updateDepot, getDepotById, getDropdown } from '../../../../Core/Services/InternalUser/ControllerService/depotsService';
import Grid from '@material-ui/core/Grid';
import { FormHelperText, InputLabel, Typography } from '@material-ui/core';
import { SelectBox, SecondaryButton, SecondaryCheckbox, BreadCrumbs, MandatoryField, SimpleField, YesButton, NoButton } from '../../../../Core/Controls';
import { emailValidation, validatePostCode } from '../../../../Core/Basic/StaticService';
import { isNull } from 'lodash';
import Paper from '@material-ui/core/Paper';
import { Postcode } from '../../../../Core/Controls';
import '../../../InternalUser/Controllers/controllerStyle.scss';

const FlaggedBox = (props) => {
    useEffect(() => {
        !props.chkValue && props.resetInput(props.txtName);
    }, [props.chkValue]);
    return (
        <>
            <InputLabel shrink>{props.label}</InputLabel>
            <Grid container alignItems="flex-end">
                <Grid item xs={1}>
                    <SecondaryCheckbox checked={props.chkValue} onChange={props.onCheckChange} name={props.chkName} disabled={props.disabled} />
                </Grid>
                <Grid item xs={11}>
                    <SimpleField
                        placeholder={props.label}
                        value={props.txtValue}
                        onChange={props.inputChange}
                        name={props.txtName}
                        disabled={props.disabled || !props.chkValue}
                    />
                </Grid>
            </Grid>
        </>
    );
};

const ScheduleDetails = (props) => {
    const { showToastSuccess, showToastError } = useToast();

    // const crumbs = [
    //     { name: 'Home', path: '/' },
    //     { name: 'Depots', active: true }
    // ];

    const [depotFields, setDepotFields] = useState({
        name: '',
        address1: '',
        address2: '',
        depotTown: '',
        depotCounty: '',
        depotCountyCode: '',
        depotEmail: '',
        depotTelephone: '',
        depotPostCode: '',
        depotNotInUse: true,
        depotCustomersRef: '',
        serviceAgentID: '',
        firstDayofWeek: '',
        scheduleBookingModeList: [],
        depotVORMobileNumbers: '',
        depotContact: '',
        depotServiceAgentID: '',
        depotAgentSecondaryAgentID: '',
        serviceAgentsList: [],
        errors: {},
        customerList: [],
        depotID: null,
        depotCustomerID: '',
        mergeSchedules: true,
        displayISOWeek: true,
        addPaperworkReceived: true,
        trafficStyleColourCoding: true,
        mergeRange: '',
        sunday: false,
        monday: true,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        depotAdditionalPlanningEmails: '',
        cPCHolderEmail: '',
        cPCHolderName: '',
        secondaryEmail: '',
        depotSecondaryContact: '',
        depotPCNEmail: '',
        depotAccountName: '',
        depotAccountEmail: '',
        depotThirdPartyPlannerEmailAddress: '',
        depotBreakdownNotificationEmail: '',
        depotTimedVehicleStatusUpdateEmail: '',
        depotSchedulePlannerEmail: '',
        depotNotes: '',
        depotScheduleBookingMode: ''
    });

    // const [depotFields, setDepotFields] = useState({});

    useEffect(async () => {
        let res = await getDropdown();
        const scheduleBookingModeList = res.data.scheduleBookingModes.map((item) => ({
            id: item.scheduleBookingModeCode,
            name: item.scheduleBookingModeDescription
        }));

        let custRes = await getDropdown();
        const List = custRes.data.customers.map((item) => ({
            id: item.customerID,
            name: item.customerCompanyName
        }));
        setDepotFields((st) => ({
            ...st,
            scheduleBookingModeList: scheduleBookingModeList,
            serviceAgentsList: res.data.serviceAgents,
            customerList: List
        }));
        if (props.depotID) {
            let res = await getDepotById(props.depotID);
            const data = res.data?.details[0];

            setDepotFields((st) => ({
                ...st,
                name: data.depotName,
                address1: data.depotAddress1,
                address2: data.depotAddress2,
                depotCounty: data.depotCounty,
                depotTown: data.depotTown,
                depotCountyCode: data.depotCountryCode,
                depotEmail: data.depotEmail,
                depotCustomersRef: data.depotCustomersRef,
                scheduleBookingModeID: data.scheduleBookingModeID,
                depotPostCode: data.depotPostCode,
                depotTelephone: data.depotTelephone,
                depotContact: data.depotContact,
                depotNotInUse: data.depotNotInUse,
                firstDayofWeek: data.depotFirstDayofWeek,
                depotVORMobileNumbers: data.depotVORMobileNumbers,
                depotServiceAgentID: data.depotPrimaryAgentID,
                depotAgentSecondaryAgentID: data.depotAgentSecondaryAgentID,
                depotID: data.depotID,
                depotCustomerID: data.depotCustomerID,
                scheduleBookingModeID: data.scheduleBookingModeID,
                MergeSchedules: data.depotPlannerMergeSchedules,
                displayISOWeek: data.depotPlannerDisplayISOWeek,
                addPaperworkReceived: data.depotPlannerAddPaperworkReceived,
                trafficStyleColourCoding: data.depotPlannerTrafficStyleColourCoding,
                mergeRange: data.depotPlannerMergeRange,
                sunday: data.depotBookingPreferenceSunday,
                monday: data.depotBookingPreferenceMonday,
                tuesday: data.depotBookingPreferenceTuesday,
                wednesday: data.depotBookingPreferenceWednesday,
                thursday: data.depotBookingPreferenceThursday,
                friday: data.depotBookingPreferenceFriday,
                saturday: data.depotBookingPreferenceSaturday,
                depotAdditionalPlanningEmails: data.depotAdditionalPlanningEmails,
                cPCHolderEmail: data.depotCPCHolderEmail,
                cPCHolderName: data.depotCPCHolderName,
                secondaryEmail: data.depotSecondaryEmail,
                depotSecondaryContact: data.depotSecondaryContact,
                depotPCNEmail: data.depotPCNEmail,
                depotAccountName: data.depotAccountName,
                depotAccountEmail: data.depotAccountEmail,
                depotContactMobile: data.depotContactMobile,
                depotThirdPartyPlannerEmailAddress: data.depotThirdPartyPlannerEmailAddress,
                depotScheduleBookingMode: data.depotScheduleBookingMode,
                depotBreakdownNotificationEmail: data.depotBreakdownNotificationEmail,
                depotTimedVehicleStatusUpdateEmail: data.depotTimedVehicleStatusUpdateEmail,
                depotSchedulePlannerEmail: data.depotSchedulePlannerEmail,
                depotNotes: data.depotNotes,
                depotVehicleStatusUpdateEmail: data.depotVehicleStatusUpdateEmail,
                depotSendOverdueReminderEmail: depotFields.depotSendOverdueReminderEmail,
                depotOverdueReminderEmail: depotFields.depotOverdueReminderEmail
            }));
        }
    }, []);

    const handledepotFields = (e) => {
        const { name, value } = e.target;
        setDepotFields((st) => ({ ...st, [name]: value }));
    };

    const handleActive = (val) => {
        setDepotFields((field) => ({
            ...field,
            depotNotInUse: val
        }));
    };

    const handleCheckbox = useCallback((e) => {
        const { name, checked } = e.target;
        setDepotFields((st) => {
            const nst = { ...st, [name]: checked };
            return nst;
        });
    }, []);

    const validations = () => {
        const {
            name,
            address1,
            address2,
            depotCounty,
            depotTown,
            depotPostCode,
            depotCountyCode,
            depotEmail,
            depotTelephone,
            depotContact,
            depotVehicleStatusUpdateEmail
        } = depotFields;

        let formIsValid = true;
        let errors = {};
        if (!/[^\s]/.test(name)) {
            errors.name = 'Depot Name is required.';
            formIsValid = false;
        }
        if (!/[^\s]/.test(address1)) {
            errors.address1 = 'Depot Address1 is required.';
            formIsValid = false;
        }

        if (!/[^\s]/.test(depotCounty)) {
            errors.depotCounty = 'Depot county is required.';
            formIsValid = false;
        }

        // if (!validatePostCode(depotPostCode)) {
        //     errors.depotPostCode = `Please fill valid post code, "Ex: BB11AA"`;
        //     formIsValid = false;
        // }

        // if (!depotFields.depotCustomerID) {
        //     errors.depotCustomerID = 'Customer is required.';
        //     formIsValid = false;
        // }
        setDepotFields((st) => ({ ...st, errors: errors }));
        return formIsValid;
    };

    const handleSubmit = async () => {
        if (validations()) {
            const depotData = {
                depotID: depotFields.depotID,
                depotCustomerID: null,
                depotName: depotFields.name,
                depotAddress1: depotFields.address1,
                depotAddress2: depotFields.address2,
                depotCounty: depotFields.depotCounty,
                depotTown: depotFields.depotTown,
                depotCountryCode: depotFields.depotCountyCode,
                depotEmail: depotFields.depotEmail,
                depotCustomersRef: depotFields.depotCustomersRef,
                depotPostCode: depotFields.depotPostCode,
                depotTelephone: depotFields.depotTelephone,
                depotContact: depotFields.depotContact,
                depotNotInUse: depotFields.depotNotInUse,
                depotPrimaryAgentID: depotFields.depotServiceAgentID,
                depotAgentSecondaryAgentID: depotFields.depotAgentSecondaryAgentID,
                depotVORMobileNumbers: depotFields.depotVORMobileNumbers,
                depotPlannerMergeSchedules: depotFields.mergeSchedules,
                depotPlannerDisplayISOWeek: depotFields.displayISOWeek,
                depotPlannerAddPaperworkReceived: depotFields.addPaperworkReceived,
                depotPlannerTrafficStyleColourCoding: depotFields.trafficStyleColourCoding,
                depotPlannerMergeRange: depotFields.mergeRange,
                depotBookingPreferenceSunday: depotFields.sunday,
                depotBookingPreferenceMonday: depotFields.monday,
                depotBookingPreferenceTuesday: depotFields.tuesday,
                depotBookingPreferenceWednesday: depotFields.wednesday,
                depotBookingPreferenceThursday: depotFields.thursday,
                depotBookingPreferenceFriday: depotFields.friday,
                depotBookingPreferenceSaturday: depotFields.saturday,
                depotAdditionalPlanningEmails: depotFields.depotAdditionalPlanningEmails,
                depotCPCHolderEmail: depotFields.cPCHolderEmail,
                depotCPCHolderName: depotFields.cPCHolderName,
                depotSecondaryEmail: depotFields.secondaryEmail,
                depotSecondaryContact: depotFields.depotSecondaryContact,
                depotPCNEmail: depotFields.depotPCNEmail,
                depotAccountName: depotFields.depotAccountName,
                depotContactMobile: depotFields.depotContactMobile,
                depotAccountEmail: depotFields.depotAccountEmail,
                depotThirdPartyPlannerEmailAddress: depotFields.depotThirdPartyPlannerEmailAddress,
                depotScheduleBookingMode: depotFields.depotScheduleBookingMode,
                depotBreakdownNotificationEmail: depotFields.depotBreakdownNotificationEmail,
                depotTimedVehicleStatusUpdateEmail: depotFields.depotTimedVehicleStatusUpdateEmail,
                depotVehicleStatusUpdateEmail: depotFields.depotVehicleStatusUpdateEmail,
                depotSchedulePlannerEmail: depotFields.depotSchedulePlannerEmail,
                depotfirstDayofWeek: depotFields.depotFirstDayofWeek,
                depotNotes: depotFields.depotNotes,
                depotSendOverdueReminderEmail: depotFields.depotSendOverdueReminderEmail,
                depotOverdueReminderEmail: depotFields.depotOverdueReminderEmail
            };

            let res;
            if (props.depotID) {
                res = await updateDepot(depotData, props.depotID);
            } else {
                res = await addDepot(depotData);
            }
            if (res.success) {
                showToastSuccess('Depots saved successfully');
                props.onFormClose(true);
            } else {
                showToastError(res.message);
            }
        }
    };

    const resetInput = (name) => {
        setDepotFields((st) => ({ ...st, [name]: '' }));
    };

    return (
        <div className="controller-screen" style={{ marginTop: 2 }}>
            {/* <BreadCrumbs crumbs={crumbs} /> */}
            <br />
            <Grid container spacing={1}>
                <Grid item xs={12} md={12} lg={12} className="grid-paper-shadow">
                    {/* <Paper className="controller-container" style={{ padding: '8px' }}> */}
                    <Grid container spacing={1}>
                        {/* <Grid item xs={12} sm={4} className="mandatory-fields">
                            <InputLabel required shrink>
                                Customers
                            </InputLabel>
                            <SelectBox
                                name="depotCustomerID"
                                value={depotFields.depotCustomerID}
                                onChange={handledepotFields}
                                List={depotFields.customerList}
                            />
                            <FormHelperText error>{depotFields.errors.depotCustomerID}</FormHelperText>
                        </Grid> */}
                        <Grid item xs={12} sm={4} className="mandatory-fields">
                            <MandatoryField
                                inputLabel="Names"
                                placeholder="Depot Name"
                                value={depotFields.name}
                                onChange={handledepotFields}
                                name="name"
                                errors={depotFields.errors.name}
                            />
                            {/* <FormHelperText error>{depotFields.errors.name}</FormHelperText> */}
                        </Grid>
                        <Grid item xs={12} sm={4} className="mandatory-fields">
                            <MandatoryField
                                inputLabel="Address1"
                                placeholder="Address1"
                                value={depotFields.address1}
                                onChange={handledepotFields}
                                name="address1"
                                errors={depotFields.errors.address1}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <SimpleField
                                inputLabel="Address2"
                                placeholder="Address2"
                                value={depotFields.address2}
                                onChange={handledepotFields}
                                name="address2"
                                errors={depotFields.errors.address2}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <SimpleField
                                inputLabel="Town"
                                placeholder="Town"
                                value={depotFields.depotTown}
                                onChange={handledepotFields}
                                name="depotTown"
                                errors={depotFields.errors.depotTown}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Postcode
                                value={depotFields.depotPostCode}
                                onChange={handledepotFields}
                                name="depotPostCode"
                                // errors={depotFields.errors.depotPostCode}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} className="mandatory-fields">
                            <MandatoryField
                                inputLabel="County"
                                placeholder="County"
                                value={depotFields.depotCounty || ''}
                                onChange={handledepotFields}
                                name="depotCounty"
                                errors={depotFields.errors.depotCounty}
                            />{' '}
                        </Grid>
                        {/* <Grid item xs={12} sm={4} className="mandatory-fields">
                            <MandatoryField
                                inputLabel="Country Code"
                                placeholder="Country Code"
                                value={depotFields.depotCountyCode || ''}
                                onChange={handledepotFields}
                                name="depotCountyCode"
                                errors={depotFields.errors.depotCountyCode}
                            />
                        </Grid> */}
                        <Grid item xs={12} sm={4}>
                            <SimpleField
                                inputLabel="Email"
                                placeholder="Email"
                                type="email"
                                value={depotFields.depotEmail || ''}
                                onChange={handledepotFields}
                                name="depotEmail"
                                errors={depotFields.errors.depotEmail}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <SimpleField
                                inputLabel="Customers Ref"
                                placeholder="Customers Ref "
                                value={depotFields.depotCustomersRef}
                                onChange={handledepotFields}
                                name="depotCustomersRef"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <SimpleField
                                inputLabel="Telephone"
                                placeholder="Telephone"
                                type="tel"
                                value={depotFields.depotTelephone}
                                onChange={handledepotFields}
                                name="depotTelephone"
                                errors={depotFields.errors.depotTelephone}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <SimpleField
                                inputLabel="Contact Number"
                                placeholder="Contact Number"
                                value={depotFields.depotContact}
                                onChange={handledepotFields}
                                name="depotContact"
                                errors={depotFields.errors.depotContact}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <InputLabel shrink>VOR Mobile Numbers </InputLabel>
                            <SimpleField
                                placeholder="VOR Mobile Numbers"
                                value={depotFields.depotVORMobileNumbers}
                                onChange={handledepotFields}
                                name="depotVORMobileNumbers"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <InputLabel shrink>Primary Service Agent</InputLabel>
                            <SelectBox
                                name="depotServiceAgentID"
                                value={depotFields.depotServiceAgentID}
                                onChange={handledepotFields}
                                List={depotFields.serviceAgentsList}
                            />
                            <FormHelperText error>{depotFields.errors.depotServiceAgentID}</FormHelperText>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <InputLabel shrink>Secondary Service Agent</InputLabel>
                            <SelectBox
                                name="depotAgentSecondaryAgentID"
                                value={depotFields.depotAgentSecondaryAgentID}
                                onChange={handledepotFields}
                                List={depotFields.serviceAgentsList}
                            />
                            <FormHelperText error>{depotFields.errors.depotAgentSecondaryAgentID}</FormHelperText>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <InputLabel shrink>First Day of Week</InputLabel>
                            <SelectBox value={depotFields.firstDayofWeek} onChange={handledepotFields} name="firstDayofWeek" List={[]} />
                        </Grid>
                    </Grid>
                    {/* </Paper> */}
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5" color="secondary">
                        Schedule Planner Options
                    </Typography>
                    <hr />
                </Grid>
                <Grid item xs={12} md={12} lg={12} className="grid-paper-shadow">
                    {/* <Paper className="controller-container" style={{ padding: '8px' }}> */}
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={3} className="checkbox-font">
                            <SecondaryCheckbox checked={depotFields.mergeSchedules} name="mergeSchedules" onChange={handleCheckbox} label="Merge Schedule?" />
                        </Grid>
                        <Grid item xs={12} sm={3} className="checkbox-font">
                            <SecondaryCheckbox
                                checked={depotFields.displayISOWeek}
                                name="displayISOWeek"
                                onChange={handleCheckbox}
                                label="Display ISO Week Number?"
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} className="checkbox-font">
                            <SecondaryCheckbox
                                checked={depotFields.addPaperworkReceived}
                                name="addPaperworkReceived"
                                onChange={handleCheckbox}
                                label="Add Paperwork Received?"
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} className="checkbox-font">
                            <SecondaryCheckbox
                                checked={depotFields.trafficStyleColourCoding}
                                name="trafficStyleColourCoding"
                                onChange={handleCheckbox}
                                label="Traffic Style Colour Coding ?"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputLabel shrink>Max Schedule Merge Range</InputLabel>
                            <SimpleField placeholder="Weeks" type="number" value={depotFields.mergeRange} onChange={handledepotFields} name="mergeRange" />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <SimpleField
                                inputLabel="Schedule Planner Email"
                                placeholder="Planner Email"
                                type="email"
                                value={depotFields.depotSchedulePlannerEmail || ''}
                                onChange={handledepotFields}
                                name="depotSchedulePlannerEmail"
                                errors={depotFields.errors.depotSchedulePlannerEmail}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputLabel shrink>Schedule Booking Mode</InputLabel>
                            <SelectBox
                                name="depotScheduleBookingMode"
                                value={depotFields.depotScheduleBookingMode}
                                onChange={handledepotFields}
                                List={depotFields.scheduleBookingModeList}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputLabel shrink>Third Party Planner Email Address</InputLabel>
                            <SimpleField
                                placeholder="Third Party Planner Email Address"
                                name="depotThirdPartyPlannerEmailAddress"
                                value={depotFields.depotThirdPartyPlannerEmailAddress}
                                onChange={handledepotFields}
                            />
                        </Grid>
                    </Grid>
                    {/* </Paper> */}
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5" color="secondary">
                        Booking Preference
                    </Typography>
                    <hr />
                </Grid>
                <Grid item xs={12} md={12} lg={12} className="grid-paper-shadow">
                    {/* <Paper className="controller-container"> */}
                    <Grid container spacing={1} item>
                        <Grid item className="checkbox-font">
                            <SecondaryCheckbox checked={depotFields.sunday} name="sunday" onChange={handleCheckbox} label="Sunday" />
                        </Grid>
                        <Grid item className="checkbox-font">
                            <SecondaryCheckbox checked={depotFields.monday} name="monday" onChange={handleCheckbox} label="Monday" />
                        </Grid>
                        <Grid item className="checkbox-font">
                            <SecondaryCheckbox checked={depotFields.tuesday} name="tuesday" onChange={handleCheckbox} label="Tuesday" />
                        </Grid>
                        <Grid item className="checkbox-font">
                            <SecondaryCheckbox checked={depotFields.wednesday} name="wednesday" onChange={handleCheckbox} label="Wednesday" />
                        </Grid>
                        <Grid item className="checkbox-font">
                            <SecondaryCheckbox checked={depotFields.thursday} name="thursday" onChange={handleCheckbox} label="Thursday" />
                        </Grid>
                        <Grid item className="checkbox-font">
                            <SecondaryCheckbox checked={depotFields.friday} name="friday" onChange={handleCheckbox} label="Friday" />
                        </Grid>
                        <Grid item className="checkbox-font">
                            <SecondaryCheckbox checked={depotFields.saturday} name="saturday" onChange={handleCheckbox} label="Saturday" />
                        </Grid>
                    </Grid>
                    {/* </Paper> */}
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5" color="secondary">
                        Contact Details
                    </Typography>
                    <hr />
                </Grid>
                <Grid item xs={12} md={12} lg={12} className="grid-paper-shadow">
                    {/* <Paper className="controller-container"> */}
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={4}>
                            <InputLabel shrink></InputLabel>
                            <SimpleField
                                inputLabel="Additional Planning Emails"
                                placeholder="Additional Planning Emails"
                                type="email"
                                value={depotFields.depotAdditionalPlanningEmails}
                                onChange={handledepotFields}
                                name="depotAdditionalPlanningEmails"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <SimpleField
                                inputLabel="CPC Holder Email"
                                placeholder="CPC Holder Email"
                                type="email"
                                value={depotFields.cPCHolderEmail}
                                onChange={handledepotFields}
                                name="cPCHolderEmail"
                                errors={depotFields.errors.cPCHolderEmail}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <SimpleField
                                inputLabel="CPC Holder Name"
                                placeholder="CPC Holder Name"
                                value={depotFields.cPCHolderName}
                                onChange={handledepotFields}
                                name="cPCHolderName"
                                errors={depotFields.errors.cPCHolderName}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <SimpleField
                                inputLabel="Secondary Email"
                                placeholder="Secondary Email"
                                type="email"
                                value={depotFields.secondaryEmail}
                                onChange={handledepotFields}
                                name="secondaryEmail"
                                errors={depotFields.errors.secondaryEmail}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <SimpleField
                                inputLabel="Contact Mobile"
                                placeholder="Contact Mobile"
                                value={depotFields.depotContactMobile}
                                onChange={handledepotFields}
                                name="depotContactMobile"
                                errors={depotFields.errors.depotContactMobile}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <SimpleField
                                inputLabel="Secondary Contact Number"
                                placeholder="Secondary Contact Number"
                                value={depotFields.depotSecondaryContact}
                                onChange={handledepotFields}
                                name="depotSecondaryContact"
                                errors={depotFields.errors.depotSecondaryContact}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <SimpleField
                                inputLabel="PCN Email"
                                placeholder="PCN Email"
                                type="email"
                                value={depotFields.depotPCNEmail}
                                onChange={handledepotFields}
                                name="depotPCNEmail"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <SimpleField
                                inputLabel="Account Name"
                                placeholder="Account Name"
                                value={depotFields.depotAccountName}
                                onChange={handledepotFields}
                                name="depotAccountName"
                                errors={depotFields.errors.depotAccountName}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <SimpleField
                                inputLabel="Account Email"
                                placeholder="Account Email"
                                type="email"
                                value={depotFields.depotAccountEmail}
                                onChange={handledepotFields}
                                name="depotAccountEmail"
                                errors={depotFields.errors.depotAccountEmail}
                            />
                        </Grid>
                    </Grid>
                    {/* </Paper> */}
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5" color="secondary">
                        Notification Update
                    </Typography>
                    <hr />
                </Grid>
                <Grid item xs={12} md={12} lg={12} className="grid-paper-shadow">
                    {/* <Paper className="controller-container"> */}
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={4}>
                            <SimpleField
                                inputLabel="Breakdown Notification Email"
                                placeholder="Breakdown Notification Email"
                                type="email"
                                value={depotFields.depotBreakdownNotificationEmail}
                                onChange={handledepotFields}
                                name="depotBreakdownNotificationEmail"
                                errors={depotFields.errors.depotBreakdownNotificationEmail}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <SimpleField
                                inputLabel="Vehicle Status Update Email"
                                placeholder="Vehicle Status Update Email"
                                type="email"
                                value={depotFields.depotVehicleStatusUpdateEmail}
                                onChange={handledepotFields}
                                name="depotVehicleStatusUpdateEmail"
                                errors={depotFields.errors.depotVehicleStatusUpdateEmail}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <SimpleField
                                inputLabel="Timed Job Status Update Email"
                                placeholder=" Timed Job Status Update Email"
                                type="email"
                                value={depotFields.depotTimedVehicleStatusUpdateEmail}
                                onChange={handledepotFields}
                                name="depotTimedVehicleStatusUpdateEmail"
                            />
                        </Grid>
                        <Grid item xs={12} md={4} sm={6}>
                            <FlaggedBox
                                label="Send Overdue Reminder Email"
                                chkName="depotSendOverdueReminderEmail"
                                chkValue={depotFields.depotSendOverdueReminderEmail}
                                onCheckChange={handleCheckbox}
                                txtName="depotOverdueReminderEmail"
                                txtValue={depotFields.depotOverdueReminderEmail}
                                inputChange={handledepotFields}
                                resetInput={resetInput}
                                disabled={depotFields.isDisable}
                            />
                            <FormHelperText error>{depotFields.errors.depotOverdueReminderEmail}</FormHelperText>
                        </Grid>
                    </Grid>
                    {/* <Grid item xs={12} sm={4}></Grid> */}
                    <Grid item xs={12} style={{ marginTop: '1px' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h5" color="secondary">
                                    Active
                                </Typography>
                                <hr />
                            </Grid>
                            {/* <Grid item xs={2} style={{ marginTop: '10px' }}>
                                    <Typography>Active</Typography>
                                </Grid> */}
                            <Grid item xs={2}>
                                <YesButton state={depotFields.depotNotInUse} onClick={() => handleActive(true)}></YesButton>
                            </Grid>
                            <Grid item xs={2}>
                                <NoButton state={depotFields.depotNotInUse} onClick={() => handleActive(false)}></NoButton>
                            </Grid>
                        </Grid>

                        {/* <SecondaryCheckbox checked={depotFields.depotNotInUse} name="depotNotInUse" onChange={handleCheckbox} label="Not In Use?" /> */}
                    </Grid>
                    {/* </Paper> */}
                    <Grid item container xs={12} spacing={1} justify="space-between" style={{ marginTop: 8 }}>
                        <Grid item xs={6}>
                            <SecondaryButton onClick={handleSubmit} className="submit_btn">
                                Save
                            </SecondaryButton>
                        </Grid>
                        <Grid item xs={6}>
                            <SecondaryButton className="submit_btn" onClick={() => props.onFormClose()}>
                                Cancel
                            </SecondaryButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default ScheduleDetails;
