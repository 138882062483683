import React, { useEffect, useMemo, useState } from 'react';
import AddEditVmrsModal from './addEditVmrsSubCategoryModal';
import DataGrid from '../../../../../Core/Controls/Grid';
import { getAllVmrsSubCategorys } from '../../../../../Core/Services/InternalUser/VMRS/vmrsSubCategoryService';
import DialogComp from '../../../../../Core/Dialog/DialogComp';
import { AddButton, EditButton, BreadCrumbs, formatters } from '../../../../../Core/Controls';
import GijgoGrid from '../../../../../Core/Controls/GijgoGrid';
import '../../../../commonStyle.scss';

const modalgridStyle = {
    formatterImageContainer: { width: '100%', textAlign: 'center' },
    formatterImage: { width: 90, height: 'auto', backgroundSize: 'cover' },
    formatterEditBtn: { textAlign: 'center' }
};
const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Jobs', active: true },
    { name: 'VMRS Sub Categories', active: true }
];
const VmrsSubCategoryScreen = () => {
    const [state, setState] = useState({ modalToggle: false, subCategoryId: null, List: [], showLoader: true });

    const pullVmrsSubCategory = () => {
        setState((st) => ({ ...st, isReload: new Date() }));
    };

    const renderEdit = (data, row) => {
        return (
            <div style={modalgridStyle.formatterEditBtn}>
                <EditButton onClick={() => handleUpdate(row)} toolTipTitle="Edit VMRS SubCategory" />
            </div>
        );
    };

    const columns = [
        { key: 'action', name: 'Action', width: '5%', formatter: renderEdit },
        { key: 'vmrsSubCategoryDescription', name: 'Description' },
        { key: 'vmrsDescription', name: 'VMRS Category' },
        {
            key: 'vmrsTaskCodeID',
            name: 'Task Code',
            headerRenderer: formatters.ColumnHeaderAlignRight,
            formatter: formatters.ColumnTextAlignRight,
            width: '15%'
        }
    ];

    const handleUpdate = (params) => {
        setState((state) => ({ ...state, subCategoryId: params?.vmrsSubCategoryID, modalToggle: true }));
    };

    const closeModalHandler = (res) => {
        setState((state) => ({ ...state, modalToggle: false }));
        res && pullVmrsSubCategory();
    };

    const title = state.subCategoryId ? 'Update VMRS Sub Category' : 'Add VMRS Sub Category';

    return (
        <div className="screen">
            <BreadCrumbs crumbs={crumbs} />
            <GijgoGrid
                columns={columns}
                getUrl={`VMRSSubCategory`}
                dataConverter={(res) => ({
                    records: res.data.list,
                    total: res.data.total
                })}
                isReload={state.isReload}
                btnTitle="Add VMRS Sub Category"
                onBtnClick={() => handleUpdate(null)}
            />
            {state.modalToggle ? (
                <DialogComp title={title} onClose={() => closeModalHandler(false)}>
                    <AddEditVmrsModal subCategoryId={state.subCategoryId} onClose={() => closeModalHandler(true)} />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default VmrsSubCategoryScreen;
