import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormHelperText, InputLabel } from '@material-ui/core';
import { SecondaryButton, MandatoryField, SingleSelect } from '../../../../../Core/Controls';
import { getVmrsSubCategoryById, postVmrsSubCategoryData } from '../../../../../Core/Services/InternalUser/VMRS/vmrsSubCategoryService';
import { getAllVmrsCategorys } from '../../../../../Core/Services/InternalUser/VMRS/vmrsCategoryService';
import { useToast } from '../../../../../Core/Hooks/useToast';
import { useLoader } from '../../../../../Core/Hooks/useLoader';
import '../../../../commonStyle.scss';

const DialogStyle = {
    dialogInput: { width: '100%' },
    mandatoryLabel: { color: 'red' }
};

const AddEditVmrsModal = (props) => {
    const { showToastSuccess, showToastError } = useToast();
    const { showLoader, hideLoader } = useLoader();
    const [btnLoader, setBtnLoader] = useState(false);

    const [state, setState] = useState({
        vmrsSubCategoryID: null,
        vmrsCategoryValue: '',
        vmrsSubCategoryDescription: '',
        vmrsTaskCodeID: '',
        vmrsCategoryList: [],
        errors: {}
    });

    const [tempOptions, setTempOptions] = useState({
        vmrsCategoryValueID: null
    });
    useEffect(async () => {
        let resCategoryDropDown = await getAllVmrsCategorys();
        setState((st) => ({
            ...st,
            vmrsCategoryList: resCategoryDropDown.data.list.map((w) => ({
                id: w.vmrsCategoryID,
                name: w.vmrsDescription
            }))
        }));
        if (props.subCategoryId) {
            let res = await getVmrsSubCategoryById(props.subCategoryId);
            setState((st) => ({
                ...st,
                vmrsSubCategoryDescription: res.data.vmrsSubCategoryDescription,
                vmrsSubCategoryID: res.data.vmrsSubCategoryID,
                vmrsTaskCodeID: res.data.vmrsTaskCodeID,
                vmrsCategoryValue: res.data.vmrsCategory,
                vmrsDescription: res.data.vmrsDescription
            }));
        }
    }, []);

    useEffect(() => {
        let vmrsCategoryValueID = null;
        if (state.vmrsCategoryValue) {
            vmrsCategoryValueID = state.vmrsCategoryList.find(({ id }) => id === state.vmrsCategoryValue);
        }
        setTempOptions((st) => ({
            ...st,
            vmrsCategoryValueID: vmrsCategoryValueID
        }));
    }, [state.vmrsCategoryValue]);

    const inputChange = (e) => {
        const { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    const singleSelectChange = (nm) => (ev, val) => {
        setTempOptions((st) => ({ ...st, [nm]: val }));
    };

    const validations = () => {
        const { vmrsSubCategoryDescription, vmrsTaskCodeID } = state;
        let formIsValid = true;
        let errors = {};

        if (!/[^\s]/.test(vmrsSubCategoryDescription)) {
            errors.vmrsSubCategoryDescription = 'This field is required';
            formIsValid = false;
        }
        if (!/[^\s]/.test(vmrsTaskCodeID)) {
            errors.vmrsTaskCodeID = 'This field is required';
            formIsValid = false;
        } else if (!/^[0-9]*$/.test(vmrsTaskCodeID)) {
            errors.vmrsTaskCodeID = 'This field accepts number 1 - 0';
            formIsValid = false;
        }
        setState((st) => ({ ...st, errors: errors }));
        return formIsValid;
    };

    const submitHandler = async () => {
        if (validations()) {
            const formData = {
                vmrsSubCategoryDescription: state.vmrsSubCategoryDescription,
                vmrsSubCategoryID: state.vmrsSubCategoryID,
                vmrsTaskCodeID: state.vmrsTaskCodeID,
                vmrsCategory: tempOptions.vmrsCategoryValueID?.id
            };
            setBtnLoader(true);
            let res = await postVmrsSubCategoryData(formData);
            if (res.success) {
                showToastSuccess(res.message);
                props.onClose(true);
            } else {
                showToastError(res.message);
            }
            setBtnLoader(false);
        }
    };

    return (
        <Grid container direction={'row'} spacing={2} alignItems="center" justify="center">
            <Grid item xs={12} className="mandatory-fields">
                <InputLabel required shrink>
                    VMRS Category
                </InputLabel>
                <SingleSelect value={tempOptions.vmrsCategoryValueID} onChange={singleSelectChange('vmrsCategoryValueID')} options={state.vmrsCategoryList} />
                <FormHelperText error>{state.errors.vmrsCategory}</FormHelperText>
            </Grid>
            <Grid item xs={12} className="mandatory-fields">
                <MandatoryField
                    inputLabel="Description"
                    placeholder="Description"
                    value={state.vmrsSubCategoryDescription || ''}
                    onChange={inputChange}
                    name="vmrsSubCategoryDescription"
                    errors={state.errors.vmrsSubCategoryDescription}
                />
            </Grid>
            <Grid item xs={12} className="mandatory-fields">
                <MandatoryField
                    inputLabel="Task Code"
                    placeholder="Task Code"
                    value={state.vmrsTaskCodeID}
                    onChange={inputChange}
                    name="vmrsTaskCodeID"
                    errors={state.errors.vmrsTaskCodeID}
                />
            </Grid>
            <Grid item xs={12} className="submit_btn">
                <SecondaryButton isBtnLoader={btnLoader} onClick={submitHandler}>Submit</SecondaryButton>
            </Grid>
        </Grid>
    );
};

export default AddEditVmrsModal;
