import React, { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormControlLabel, FormHelperText, InputLabel, Radio, RadioGroup, Typography } from '@material-ui/core';
import { SecondaryButton, SelectBox, BreadCrumbs, MandatoryField, SimpleField, DatePicker, SingleSelect } from '../../../../Core/Controls';
import { PositiveInput } from '../../../../Core/Controls/Inputs/PositiveInput';
import { CustomerProvider, CustomerScreenContext } from './CustomerProvider';
import { SecondaryCheckbox } from '../../../../Core/Controls';
import Paper from '@material-ui/core/Paper';
import '../controllerStyle.scss';
import { useEffect } from 'react';
import moment from 'moment';
import { Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Postcode } from '../../../../Core/Controls';
import ColorPicker from '../../../../Core/Controls/ColorPicker';
import { AppStorage } from '../../../../Core/Basic/storage-service';

const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Customers', active: true },
    { name: 'Add/Update Customer', active: true }
];

const modelgridStyle = {
    gridContainer: { height: '600px' },
    formatterImageContainer: { width: '100%', textAlign: 'center' },
    formatterImage: { width: 120, maxHeight: 40, backgroundSize: 'cover' },
    formatterEditBtn: { textAlign: 'center' }
};

const FlaggedBox = (props) => {
    useEffect(() => {
        !props.chkValue && props.resetInput(props.txtName);
    }, [props.chkValue]);
    return (
        <>
            <InputLabel shrink>{props.label}</InputLabel>
            <Grid container alignItems="flex-end">
                <Grid item xs={1}>
                    <SecondaryCheckbox checked={props.chkValue} onChange={props.onCheckChange} name={props.chkName} />
                </Grid>
                <Grid item xs={11}>
                    <SimpleField
                        placeholder={props.label}
                        value={props.txtValue}
                        onChange={props.inputChange}
                        name={props.txtName}
                        disabled={!props.chkValue}
                    />
                </Grid>
            </Grid>
        </>
    );
};

const YesNoRadio = (props) => {
    return (
        <Grid container>
            <Grid container item xs={12}>
                <InputLabel shrink>{props.label}</InputLabel>
            </Grid>
            <Grid container item spacing={1} style={{ marginLeft: '2px' }}>
                <RadioGroup row aria-label={props.name} name={props.name} value={props.value} onChange={props.onChange}>
                    <FormControlLabel checked={props.value === true} value={true} control={<Radio />} label="Yes" />
                    <FormControlLabel checked={props.value === false} value={false} control={<Radio />} label="No" />
                </RadioGroup>
            </Grid>
        </Grid>
    );
};

const AddEditCustomer = (props) => {
    const {
        state,
        btnLoader,
        handleSubmit,
        inputChange,
        handleCheckbox,
        resetInput,
        imagehandler,
        clearImage,
        setVal,
        singleSelectChange,
        ISODays,
        countryList,
        optionsState,
        tempOptions,
        onColorChange,
        invoiceFields,
        setInvoiceVal,
        invoiceFieldsChange,
        invoiceCheckBox
    } = useContext(CustomerScreenContext);
    let checkhaveAccess = AppStorage.getAllowAcces();

    return (
        <div style={{ overflow: 'hidden' }} className={`${!state.customerID && 'screen'}` + ' controller-screen'}>
            {!props.customerID ? <BreadCrumbs crumbs={crumbs} className="edit-Customer-btn" /> : null}
            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    {/* <Paper className="controller-container"> */}
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={4} sm={6} lg={3} className="mandatory-fields">
                            <MandatoryField
                                inputLabel="Short Name"
                                placeholder="Short Name"
                                value={state.customerShortName}
                                onChange={inputChange}
                                name="customerShortName"
                            />
                            <FormHelperText error>{state.errors.customerShortName}</FormHelperText>
                        </Grid>
                        <Grid item xs={12} md={4} sm={6} lg={3} className="mandatory-fields">
                            <MandatoryField
                                inputLabel="Name"
                                placeholder="Customer Name"
                                value={state.customerCompanyName}
                                onChange={inputChange}
                                name="customerCompanyName"
                            />
                            <FormHelperText error>{state.errors.customerCompanyName}</FormHelperText>
                        </Grid>
                        <Grid item xs={12} md={4} sm={6} lg={3}>
                            <SimpleField
                                inputLabel="Account Code"
                                placeholder="Account Code"
                                value={state.customerAccountCode}
                                onChange={inputChange}
                                name="customerAccountCode"
                            />
                            <FormHelperText error>{state.errors.customerAccountCode}</FormHelperText>
                        </Grid>

                        <Grid item xs={12} md={4} sm={6} lg={3} className="mandatory-fields">
                            <InputLabel shrink required>
                                Status
                            </InputLabel>
                            <SingleSelect
                                options={optionsState.customerStatusList}
                                value={tempOptions.customerStatusIDValue}
                                onChange={singleSelectChange('customerStatusIDValue')}
                            />
                            {/* <MandatoryField
                                inputLabel="Status"
                                placeholder="Status"
                                value={state.customerStatusID}
                                onChange={inputChange}
                                name="customerStatusID"
                            /> */}
                            <FormHelperText error>{state.errors.customerStatusID}</FormHelperText>
                        </Grid>
                        <Grid item xs={12} md={4} sm={6} lg={3} className="mandatory-fields">
                            <InputLabel required shrink>
                                Country Code
                            </InputLabel>
                            <SelectBox name="customerCountryCode" value={state.customerCountryCode} onChange={inputChange} List={countryList} />
                            <FormHelperText error>{state.errors.countryCode}</FormHelperText>
                        </Grid>

                        <Grid item xs={12} md={4} sm={6} lg={3} className="mandatory-fields">
                            <MandatoryField
                                inputLabel="Address 1"
                                placeholder="Address 1"
                                value={state.customerAddress1 || ''}
                                onChange={inputChange}
                                name="customerAddress1"
                            />
                            <FormHelperText error>{state.errors.customerAddress1}</FormHelperText>
                        </Grid>
                        <Grid item xs={12} md={4} sm={6} lg={3}>
                            <SimpleField
                                inputLabel="Address 2"
                                placeholder="Address 2"
                                value={state.customerAddress2 || ''}
                                onChange={inputChange}
                                name="customerAddress2"
                            />
                        </Grid>

                        <Grid item xs={12} md={4} sm={6} lg={3}>
                            <SimpleField inputLabel="City" placeholder="City" value={state.customerCity || ''} onChange={inputChange} name="customerCity" />
                        </Grid>
                        <Grid item xs={12} md={4} sm={6} lg={3}>
                            <SimpleField
                                inputLabel="County"
                                placeholder="County"
                                value={state.customerCounty || ''}
                                onChange={inputChange}
                                name="customerCounty"
                            />
                        </Grid>
                        <Grid item xs={12} md={4} sm={6} lg={3} className="mandatory-fields">
                            {/* <MandatoryField
                                inputLabel="Post Code"
                                placeholder="Post Code"
                                value={state.customerPostCode || ''}
                                onChange={inputChange}
                                name="customerPostCode"
                            />
                            <FormHelperText error>{state.errors.customerPostCode}</FormHelperText> */}
                            <Postcode
                                value={state.customerPostCode || ''}
                                onChange={inputChange}
                                name="customerPostCode"
                                setVal={setVal}
                                errors={state.errors.customerPostCode}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} sm={6} lg={3}>
                            <SimpleField
                                inputLabel="Phone"
                                placeholder="Phone"
                                value={state.customerTelephone || ''}
                                onChange={inputChange}
                                name="customerTelephone"
                            />
                            <FormHelperText error>{state.errors.customerTelephone}</FormHelperText>
                        </Grid>
                        <Grid item xs={12} md={4} sm={6} lg={3}>
                            <SimpleField inputLabel="Email" placeholder="Email" value={state.customerEmail || ''} onChange={inputChange} name="customerEmail" />
                            <FormHelperText error>{state.errors.customerEmail}</FormHelperText>
                        </Grid>
                        <Grid item xs={12} md={4} sm={6} lg={3}>
                            <SimpleField
                                inputLabel="Website"
                                placeholder="Website"
                                value={state.customerWebSite || ''}
                                onChange={inputChange}
                                name="customerWebSite"
                            />
                        </Grid>
                        <Grid item xs={12} md={4} sm={6} lg={3}>
                            <InputLabel shrink>Account Manager</InputLabel>
                            <SingleSelect
                                options={optionsState.aCManagerList}
                                value={tempOptions.customerAccountManagerName}
                                onChange={singleSelectChange('customerAccountManagerName')}
                            />
                            <FormHelperText error>{state.errors.aCManager}</FormHelperText>
                        </Grid>
                        <Grid item xs={12} md={4} sm={6} lg={3}>
                            <YesNoRadio
                                label="Earned Recognition?"
                                name="customerEarnedRecognition"
                                value={state.customerEarnedRecognition}
                                onChange={inputChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} sm={6} lg={3}>
                            <InputLabel shrink>Earned Recognition Start Date</InputLabel>
                            <DatePicker
                                inputProps={{
                                    max: new Date().toISOString().slice(0, 10)
                                }}
                                value={state.customerERStartDate && moment(state.customerERStartDate).format('YYYY-MM-DD')}
                                onChange={inputChange}
                                name="customerERStartDate"
                            />
                        </Grid>
                        {/* <Grid item xs={12} md={4} sm={6} lg={3}>
                            <SimpleField
                                inputLabel="Licence Check Account Code"
                                placeholder="Licence Check Account Code"
                                value={state.customerLicenceCheckAccountCode || ''}
                                onChange={inputChange}
                                name="customerLicenceCheckAccountCode"
                                disabled={state.customerLicenceCheckAccountCode ? true : false}
                            />
                        </Grid> */}
                    </Grid>
                    {/* </Paper> */}
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} lg={12}>
                            {/* <Paper className="controller-container"> */}
                            <Typography variant="h5" color="secondary">
                                Invoice Details
                            </Typography>
                            <hr />
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={4} sm={6} lg={3}>
                                    <YesNoRadio
                                        name="customerSendInvoiceToDepot"
                                        value={invoiceFields.customerSendInvoiceToDepot}
                                        onChange={invoiceFieldsChange}
                                        label="Send Invoice To Depot?"
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} sm={6} lg={3}>
                                    <YesNoRadio
                                        name="customerUseAddressAsInvoiceAddress"
                                        value={invoiceFields.customerUseAddressAsInvoiceAddress}
                                        onChange={invoiceFieldsChange}
                                        label="Use Address As Invoice Address ?"
                                    />
                                </Grid>{' '}
                                <Grid item xs={12} md={4} sm={6} lg={6}>
                                    <FlaggedBox
                                        label="Send Invoice by Email"
                                        chkName="customerEmailInvoices"
                                        chkValue={invoiceFields.customerEmailInvoices}
                                        txtName="customerInvoiceEmailAddress"
                                        txtValue={invoiceFields.customerInvoiceEmailAddress}
                                        onCheckChange={invoiceCheckBox}
                                        inputChange={invoiceFieldsChange}
                                        resetInput={resetInput}
                                    />
                                    {/* <FormHelperText error>{invoiceFields.errors.customerDefectEmail}</FormHelperText> */}
                                </Grid>
                                <Grid item xs={12} md={4} sm={6} lg={3}>
                                    <Postcode
                                        value={invoiceFields.customerInvoiceAddressPostCode || ''}
                                        onChange={invoiceFieldsChange}
                                        name="customerInvoiceAddressPostCode"
                                        setVal={setInvoiceVal}
                                        disabled={invoiceFields.customerUseAddressAsInvoiceAddress}
                                    // errors={invoiceFields.errors.customerPostCode}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} sm={6} lg={3}>
                                    <MandatoryField
                                        inputLabel="Address1"
                                        placeholder="Address1"
                                        value={invoiceFields.customerInvoiceAddress1 || ''}
                                        onChange={invoiceFieldsChange}
                                        name="customerInvoiceAddress1"
                                        disabled={invoiceFields.customerUseAddressAsInvoiceAddress}
                                    />
                                    {/* <FormHelperText error>{invoiceFields.errors.customerAddress1}</FormHelperText> */}
                                </Grid>
                                <Grid item xs={12} md={4} sm={6} lg={3}>
                                    <SimpleField
                                        inputLabel="Address2"
                                        placeholder="Address2"
                                        value={invoiceFields.customerInvoiceAddress2 || ''}
                                        onChange={invoiceFieldsChange}
                                        disabled={invoiceFields.customerUseAddressAsInvoiceAddress}
                                    // name="customerInvoiceAddress2"
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} sm={6} lg={3}>
                                    <SimpleField
                                        inputLabel="City"
                                        placeholder="City"
                                        value={invoiceFields.customerInvoiceAddressCity || ''}
                                        onChange={invoiceFieldsChange}
                                        name="customerInvoiceAddressCity"
                                        disabled={invoiceFields.customerUseAddressAsInvoiceAddress}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} sm={6} lg={3}>
                                    <SimpleField
                                        inputLabel="County"
                                        placeholder="County"
                                        value={invoiceFields.customerInvoiceAddressCounty || ''}
                                        onChange={invoiceFieldsChange}
                                        name="customerInvoiceAddressCounty"
                                        disabled={invoiceFields.customerUseAddressAsInvoiceAddress}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} sm={6} lg={3}>
                                    <InputLabel required shrink>
                                        Country Code
                                    </InputLabel>
                                    <SelectBox
                                        name="customerInvoiceAddressCountryCode"
                                        value={invoiceFields.customerInvoiceAddressCountryCode}
                                        onChange={invoiceFieldsChange}
                                        List={countryList}
                                        disabled={invoiceFields.customerUseAddressAsInvoiceAddress}
                                    />
                                    {/* <FormHelperText error>{state.errors.countryCode}</FormHelperText> */}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} lg={12}>
                            {/* <Paper className="controller-container"> */}
                            <Typography variant="h5" color="secondary">
                                Contact Details
                            </Typography>
                            <hr />

                            <Grid container spacing={1}>
                                <Grid item xs={12} md={4} sm={6} lg={4}>
                                    <SimpleField
                                        inputLabel="Operation Manager Name"
                                        placeholder="Operation Manager Name"
                                        name="customerOperationsManagerName"
                                        value={state.customerOperationsManagerName}
                                        onChange={inputChange}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} sm={6} lg={4}>
                                    <SimpleField
                                        inputLabel="Operation Manager Phone"
                                        placeholder="Operation Manager Phone"
                                        name="customerOperationsManagerPhone"
                                        value={state.customerOperationsManagerPhone}
                                        onChange={inputChange}
                                    />
                                    <FormHelperText error>{state.errors.customerOperationsManagerPhone}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} md={4} sm={6} lg={4}>
                                    <SimpleField
                                        inputLabel="Operation Manager Email"
                                        placeholder="Operation Manager Email"
                                        name="customerOperationsManagerEmail"
                                        value={state.customerOperationsManagerEmail}
                                        onChange={inputChange}
                                    />
                                    <FormHelperText error>{state.errors.customerOperationsManagerEmail}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} md={4} sm={6} lg={4}>
                                    <SimpleField
                                        inputLabel="Finance Director Name"
                                        placeholder="Finance Director Name"
                                        name="customerFinanceDirectorName"
                                        value={state.customerFinanceDirectorName}
                                        onChange={inputChange}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} sm={6} lg={4}>
                                    <SimpleField
                                        inputLabel="Finance Director Phone"
                                        placeholder="Finance Director Phone"
                                        name="customerFinanceDirectorPhone"
                                        value={state.customerFinanceDirectorPhone}
                                        onChange={inputChange}
                                    />
                                    <FormHelperText error>{state.errors.customerFinanceDirectorPhone}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} md={4} sm={6} lg={4}>
                                    <SimpleField
                                        inputLabel="Finance Director Email"
                                        placeholder="Finance Director Email"
                                        name="customerFinanceDirectorEmail"
                                        value={state.customerFinanceDirectorEmail}
                                        onChange={inputChange}
                                    />
                                    <FormHelperText error>{state.errors.customerFinanceDirectorEmail}</FormHelperText>
                                </Grid>
                            </Grid>
                            {/* </Paper> */}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} lg={12}>
                            {/* <Paper className="controller-container"> */}
                            <Typography variant="h5" color="secondary">
                                Settings
                            </Typography>
                            <hr />
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={3} sm={6} lg={3}>
                                    <YesNoRadio
                                        name="customerHandlingDefects"
                                        value={state.customerHandlingDefects}
                                        onChange={inputChange}
                                        label="Handling Defects"
                                    />
                                </Grid>
                                <Grid item xs={12} md={3} sm={6} lg={3}>
                                    <YesNoRadio
                                        name="customerHandlingDefects"
                                        value={state.customerHandlingDefects}
                                        onChange={inputChange}
                                        label="Handling Breakdowns"
                                    />
                                </Grid>
                                <Grid item xs={12} md={3} sm={6} lg={3}>
                                    <YesNoRadio
                                        name="handlingOLicense"
                                        value={state.handlingOLicense}
                                        onChange={inputChange}
                                        label="Handling Olicense"
                                    />
                                </Grid>
                                <Grid item xs={12} md={3} sm={6} lg={3}>
                                    <FlaggedBox
                                        label="VOR Timed"
                                        chkName="customerVORTimed"
                                        chkValue={state.customerVORTimed}
                                        txtName="customerVORHours"
                                        txtValue={state.customerVORHours}
                                        onCheckChange={handleCheckbox}
                                        inputChange={inputChange}
                                        resetInput={resetInput}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} sm={6} lg={4}>
                                    <InputLabel shrink>Payment Terms</InputLabel>
                                    <SingleSelect
                                        options={optionsState.customerPaymentDaysList || []}
                                        value={tempOptions.customerPaymentDaysValue}
                                        onChange={singleSelectChange('customerPaymentDaysValue')}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} sm={6} lg={4}>
                                    <InputLabel shrink>Order Approval Limit</InputLabel>
                                    <PositiveInput
                                        placeholder="Order Approval Limit"
                                        name="customerAutoApprovalLimit"
                                        value={state.customerAutoApprovalLimit}
                                        onChange={inputChange}
                                    />
                                    <FormHelperText error>{state.errors.customerAutoApprovalLimit}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} md={4} sm={4} lg={4}>
                                    <InputLabel shrink>Recharge Markup %</InputLabel>
                                    <SimpleField
                                        type="number"
                                        placeholder="Recharge Markup %"
                                        name="customerRechargeMarkup"
                                        value={state.customerRechargeMarkup}
                                        onChange={inputChange}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} sm={6} lg={4}>
                                    <InputLabel shrink>ISO Week Start Day</InputLabel>
                                    <SingleSelect options={ISODays} value={tempOptions.ISODaysValue} onChange={singleSelectChange('ISODaysValue')} />
                                </Grid>
                                <Grid item xs={12} md={4} sm={6} lg={4}>
                                    <InputLabel shrink>Schedule Event</InputLabel>
                                    <SingleSelect
                                        options={optionsState.scheduleEventsList}
                                        value={tempOptions.customerInspectionEventIDValue}
                                        onChange={singleSelectChange('customerInspectionEventIDValue')}
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} sm={4} lg={2}>
                                    <SimpleField
                                        inputLabel="Labour Rate"
                                        placeholder="Labour Rate"
                                        name="customerLabourRate"
                                        value={state.customerLabourRate}
                                        onChange={inputChange}
                                    />
                                    <FormHelperText error>{state.errors.customerLabourRate}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} md={4} sm={4} lg={2}>
                                    <SimpleField
                                        inputLabel="Out Of Hours Labour Rate"
                                        placeholder="Out Of Hours Labour Rate"
                                        name="customerOutOfHoursLabourRate"
                                        value={state.customerOutOfHoursLabourRate}
                                        onChange={inputChange}
                                    />
                                    <FormHelperText error>{state.errors.customerOutOfHoursLabourRate}</FormHelperText>
                                </Grid>
                            </Grid>
                            {/* </Paper> */}
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} lg={12}>
                            {/* <Paper className="controller-container"> */}
                            <Typography variant="h5" color="secondary">
                                Notifications
                            </Typography>
                            <hr />
                            <Grid container spacing={1}>
                                <Grid item xs={12} md={4} sm={6} lg={3}>
                                    <YesNoRadio
                                        name="customerSendEmailNotifications"
                                        value={state.customerSendEmailNotifications}
                                        onChange={inputChange}
                                        label="Send Email Notification?"
                                    />
                                </Grid>
                                <Grid item xs={12} md={4} sm={6} lg={3}>
                                    <FlaggedBox
                                        label="Send Defect Email"
                                        chkName="customerSendDefectEmail"
                                        chkValue={state.customerSendDefectEmail}
                                        txtName="customerDefectEmail"
                                        txtValue={state.customerDefectEmail}
                                        onCheckChange={handleCheckbox}
                                        inputChange={inputChange}
                                        resetInput={resetInput}
                                    />
                                    <FormHelperText error>{state.errors.customerDefectEmail}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} md={4} sm={6} lg={3}>
                                    <FlaggedBox
                                        label="Send Breakdown Email"
                                        chkName="customerSendBreakdownEmail"
                                        chkValue={state.customerSendBreakdownEmail}
                                        txtName="customerBreakdownEmail"
                                        txtValue={state.customerBreakdownEmail}
                                        onCheckChange={handleCheckbox}
                                        inputChange={inputChange}
                                        resetInput={resetInput}
                                    />
                                    <FormHelperText error>{state.errors.customerBreakdownEmail}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} md={4} sm={6} lg={3}>
                                    <FlaggedBox
                                        label="Send VOR Email"
                                        chkName="customerSendVOREmail"
                                        chkValue={state.customerSendVOREmail}
                                        txtName="customerVOREmail"
                                        txtValue={state.customerVOREmail}
                                        onCheckChange={handleCheckbox}
                                        inputChange={inputChange}
                                        resetInput={resetInput}
                                    />
                                    <FormHelperText error>{state.errors.customerVOREmail}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} md={4} sm={6} lg={3}>
                                    <FlaggedBox
                                        label="Send Failed Daily Check Email"
                                        chkName="customerSendFailedDailyCheck"
                                        chkValue={state.customerSendFailedDailyCheck}
                                        txtName="customerFailedDailyCheckEmail"
                                        txtValue={state.customerFailedDailyCheckEmail}
                                        onCheckChange={handleCheckbox}
                                        inputChange={inputChange}
                                        resetInput={resetInput}
                                    />
                                    <FormHelperText error>{state.errors.customerFailedDailyCheckEmail}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} md={4} sm={6} lg={3}>
                                    <FlaggedBox
                                        label="Send Schedule Booking Confirmation"
                                        chkName="sendScheduleConfirmation"
                                        chkValue={state.sendScheduleConfirmation}
                                        txtName="customerScheduleConfirmationEmail"
                                        txtValue={state.customerScheduleConfirmationEmail}
                                        onCheckChange={handleCheckbox}
                                        inputChange={inputChange}
                                        resetInput={resetInput}
                                    />
                                    <FormHelperText error>{state.errors.customerScheduleConfirmationEmail}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} md={4} sm={6} lg={3}>
                                    <FlaggedBox
                                        label="Send Schedule Completion Email"
                                        chkName="customerSendScheduleCompletion"
                                        chkValue={state.customerSendScheduleCompletion}
                                        txtName="customerScheduleCompletionEmail"
                                        txtValue={state.customerScheduleCompletionEmail}
                                        onCheckChange={handleCheckbox}
                                        inputChange={inputChange}
                                        resetInput={resetInput}
                                    />
                                    <FormHelperText error>{state.errors.customerScheduleCompletionEmail}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} md={4} sm={6} lg={3}>
                                    <FlaggedBox
                                        label="Send Query Response Recieved"
                                        chkName="customerSendQueryResponseReceived"
                                        chkValue={state.customerSendQueryResponseReceived}
                                        txtName="customerQueryResponseReceivedEmail"
                                        txtValue={state.customerQueryResponseReceivedEmail}
                                        onCheckChange={handleCheckbox}
                                        inputChange={inputChange}
                                        resetInput={resetInput}
                                    />
                                    <FormHelperText error>{state.errors.customerQueryResponseReceivedEmail}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} md={4} sm={6} lg={3}>
                                    <FlaggedBox
                                        label="Send Job Approval Required"
                                        chkName="customerSendJobApprovalRequired"
                                        chkValue={state.customerSendJobApprovalRequired}
                                        txtName="customerJobApprovalRequiredEmail"
                                        txtValue={state.customerJobApprovalRequiredEmail}
                                        onCheckChange={handleCheckbox}
                                        inputChange={inputChange}
                                        resetInput={resetInput}
                                    />
                                    <FormHelperText error>{state.errors.customerJobApprovalRequiredEmail}</FormHelperText>
                                </Grid>
                                <Grid item xs={12} md={4} sm={6} lg={3}>
                                    <FlaggedBox
                                        label="Send Overdue Reminder Email"
                                        chkName="customerSendOverdueReminderEmail"
                                        chkValue={state.customerSendOverdueReminderEmail}
                                        txtName="customerOverdueReminderEmail"
                                        txtValue={state.customerOverdueReminderEmail}
                                        onCheckChange={handleCheckbox}
                                        inputChange={inputChange}
                                        resetInput={resetInput}
                                    />
                                    <FormHelperText error>{state.errors.customerOverdueReminderEmail}</FormHelperText>
                                </Grid>
                            </Grid>
                            {/* </Paper> */}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} lg={12}>
                            {/* <Paper className="controller-container"> */}
                            <Typography variant="h5" color="secondary">
                                Other Details
                            </Typography>
                            <hr />

                            <Grid container spacing={2} style={{ marginTop: 10 }}>
                                <Grid item container spacing={2} xs={12} sm={12} md={6} lg={4}>
                                    <InputLabel shrink style={{ paddingLeft: 10, width: '100% ' }}>
                                        Inspection Sheet Heading Colour
                                    </InputLabel>
                                    <Grid item xs={6} md={6} sm={6} lg={6} style={{ marginTop: -5 }}>
                                        <InputLabel shrink>Background</InputLabel>
                                        <ColorPicker
                                            style={{ paddingLeft: 10 }}
                                            height="15px"
                                            width="150px"
                                            name="customerInspectionSheetHeadingBackgroundColour"
                                            id="customerInspectionSheetHeadingBackgroundColour"
                                            color={state.customerInspectionSheetHeadingBackgroundColour}
                                            onChange={(m) => onColorChange(m, 'customerInspectionSheetHeadingBackgroundColour')}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={6} sm={6} lg={6} style={{ marginTop: -5 }}>
                                        <InputLabel shrink>Text</InputLabel>
                                        <ColorPicker
                                            height="15px"
                                            width="150px"
                                            name="customerInspectionSheetHeadingTextColour"
                                            id="customerInspectionSheetHeadingTextColour"
                                            color={state.customerInspectionSheetHeadingTextColour}
                                            onChange={(m) => onColorChange(m, 'customerInspectionSheetHeadingTextColour')}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item container spacing={2} xs={12} sm={12} md={6} lg={4}>
                                    <InputLabel shrink style={{ paddingLeft: 9, width: '100% ' }}>
                                        Table Header Colour
                                    </InputLabel>
                                    <Grid item xs={6} md={6} sm={6} lg={6} style={{ marginTop: -5 }}>
                                        <InputLabel shrink>Background</InputLabel>
                                        <ColorPicker
                                            height="15px"
                                            width="150px"
                                            name="customerTableHeaderBackgroundColour"
                                            id="customerTableHeaderBackgroundColour"
                                            color={state.customerTableHeaderBackgroundColour}
                                            onChange={(m) => onColorChange(m, 'customerTableHeaderBackgroundColour')}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={6} sm={6} lg={6} style={{ marginTop: -5 }}>
                                        <InputLabel shrink>Text</InputLabel>
                                        <ColorPicker
                                            height="15px"
                                            width="150px"
                                            name="customerTableHeaderColour"
                                            id="customerTableHeaderColour"
                                            color={state.customerTableHeaderColour}
                                            onChange={(m) => onColorChange(m, 'customerTableHeaderColour')}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item container spacing={2} xs={12} sm={12} md={6} lg={4}>
                                    <InputLabel shrink style={{ paddingLeft: 9, width: '100% ' }}>
                                        Odd Row Colour
                                    </InputLabel>
                                    <Grid item xs={6} md={6} sm={6} lg={6} style={{ marginTop: -5 }}>
                                        <InputLabel shrink>Background</InputLabel>
                                        <ColorPicker
                                            height="15px"
                                            width="150px"
                                            name="customerOddRowBackgroundColour"
                                            id="customerOddRowBackgroundColour"
                                            color={state.customerOddRowBackgroundColour}
                                            onChange={(m) => onColorChange(m, 'customerOddRowBackgroundColour')}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={6} sm={6} lg={6} style={{ marginTop: -5 }}>
                                        <InputLabel shrink>Text</InputLabel>
                                        <ColorPicker
                                            height="15px"
                                            width="150px"
                                            name="customerOddRowColour"
                                            id="customerOddRowColour"
                                            color={state.customerOddRowColour}
                                            onChange={(m) => onColorChange(m, 'customerOddRowColour')}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item container spacing={2} xs={12} sm={12} md={6} lg={4}>
                                    <InputLabel shrink style={{ paddingLeft: 10, width: '100% ' }}>
                                        Even Row Colour
                                    </InputLabel>
                                    <Grid item xs={6} md={6} sm={6} lg={6} style={{ marginTop: -5 }}>
                                        <InputLabel shrink>Background</InputLabel>
                                        <ColorPicker
                                            height="15px"
                                            width="150px"
                                            name="customerEvenRowBackgroundColour"
                                            id="customerEvenRowBackgroundColour"
                                            color={state.customerEvenRowBackgroundColour}
                                            onChange={(m) => onColorChange(m, 'customerEvenRowBackgroundColour')}
                                        />
                                    </Grid>
                                    <Grid item xs={6} md={6} sm={6} lg={6} style={{ marginTop: -5 }}>
                                        <InputLabel shrink>Text</InputLabel>
                                        <ColorPicker
                                            height="15px"
                                            width="150px"
                                            name="customerEvenRowColour"
                                            id="customerEvenRowColour"
                                            color={state.customerEvenRowColour}
                                            onChange={(m) => onColorChange(m, 'customerEvenRowColour')}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={4} sm={6} lg={3} style={{ position: 'relative' }}>
                                    <InputLabel shrink reuired>
                                        Customer Logo
                                    </InputLabel>
                                    {state.customerLogo && (
                                        <span
                                            style={{
                                                position: 'absolute',
                                                right: 0,
                                                top: 0
                                            }}
                                        >
                                            <Tooltip title="Remove Logo">
                                                <CloseIcon fontSize="small" onClick={clearImage} />
                                            </Tooltip>
                                        </span>
                                    )}

                                    <Grid container>
                                        <Grid item xs={6} style={{ marginTop: '8px' }}>
                                            <input type="file" name="image_value" onChange={imagehandler} accept="image/*" />
                                        </Grid>
                                        <Grid item xs={6} style={modelgridStyle.formatterImageContainer}>
                                            <img
                                                src={
                                                    state.customerLogo?.includes('base64') ? state.customerLogo : 'data:image/png;base64,' + state.customerLogo
                                                }
                                                alt={'logo'}
                                                style={modelgridStyle.formatterImage}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {checkhaveAccess?.CanUpdateCustomers && (
                    <Grid item xs={6} className="submit_btn">
                        <SecondaryButton isBtnLoader={btnLoader} onClick={handleSubmit}>
                            {props.customerID ? 'Save' : 'Add'}
                        </SecondaryButton>
                    </Grid>
                )}
                <Grid item xs={6} className="submit_btn">
                    <SecondaryButton forceEnabled={true} onClick={props.onFormSubmit}>
                        Cancel
                    </SecondaryButton>
                </Grid>
            </Grid>
        </div>
    );
};

const Screen = (props) => {
    return (
        <CustomerProvider {...props}>
            <AddEditCustomer {...props} />
        </CustomerProvider>
    );
};
export default Screen;
