import React, { useContext, useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormHelperText, InputLabel, Typography } from '@material-ui/core';
import { InputText, SecondaryButton, SecondaryCheckbox, DatePicker, DateTimePicker, SelectBox, SingleSelect } from '../../../../Core/Controls';
import SignaturePad from 'react-signature-canvas';
import './defect.scss';
import moment from 'moment';
import { getAllScheduleDropdowns } from '../../../../Core/Services/InternalUser/ControllerService/scheduleVehicleService';
import {
    postAgentArrivalTime,
    postTechnicianSignOff,
    postVehicalRecoveredTime,
    postAdministratorSignOff,
    postJobStepper
} from '../../../../Core/Services/InternalUser/Maintenance/DefectBreackDownStepper/DefectBreackDownStepper';
import { useToast } from '../../../../Core/Hooks/useToast';
const JobStepperScreen = (props) => {
    const [state, setState] = useState({ errors: {}, jobDescription: props.userData.defectDescription || props.userData.problemReported });
    const { showToastSuccess, showToastError } = useToast();

    useEffect(async () => {
        let res = await getAllScheduleDropdowns(props.userData.defectVehicleID || props.userData.vehicleID);
        setState((st) => ({
            ...st,
            serviceAgentsList: res.data.serviceAgents
        }));
    }, []);

    const dateFieldsChange = (e) => {
        const { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    };

    const singleSelectChange = (nm) => (ev, val) => {
        setState((st) => ({ ...st, [nm]: val }));
    };

    const SubmitHandler = async (isValue) => {
        let FormData = {
            defectID: props.userData.defectID || props.userData.vehicleID,
            serviceAgentID: state.serviceAgentIDValue?.id,
            jobDescription: state.jobDescription
        };

        let res = await postJobStepper(FormData);
        if (res.success) {
            showToastSuccess(`Job added successfully`);
            props.onClose(true);
        } else {
            showToastError(res.message);
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} className="mandatory-fields">
                <InputLabel shrink>Service Agent</InputLabel>
                {/* <SelectBox name="serviceAgentID" value={state.serviceAgentID || ''} onChange={dateFieldsChange} List={state.serviceAgentsList || []} /> */}
                <SingleSelect options={state.serviceAgentsList} value={state.serviceAgentIDValue} onChange={singleSelectChange('serviceAgentIDValue')} />
                <FormHelperText error>{state.errors.serviceAgentID ? 'This field is required' : null}</FormHelperText>
            </Grid>

            <Grid item xs={12}>
                <InputLabel shrink>Job Description</InputLabel>
                <InputText
                    name="jobDescription"
                    multiline
                    rows={3}
                    placeholder="Recovery location"
                    // defaultValue={state.jobDescription || ""}
                    value={state.jobDescription || ''}
                    onChange={dateFieldsChange}
                />
                <FormHelperText error>{state.errors.jobDescription ? 'Description Required' : null}</FormHelperText>
            </Grid>

            <Grid item xs={12}>
                <SecondaryButton fullWidth className="submit_btn" onClick={SubmitHandler}>
                    Add Job
                </SecondaryButton>
            </Grid>
        </Grid>
    );
};

export default JobStepperScreen;
