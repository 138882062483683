import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormHelperText, InputLabel, Typography, RadioGroup, Radio, FormControlLabel } from '@material-ui/core';
import { InputText, SelectBox, SecondaryCheckbox, MandatoryField, NoButton, SimpleField } from '../../../../Core/Controls';
import Paper from '@material-ui/core/Paper';
import '../controllerStyle.scss';
import { Postcode } from '../../../../Core/Controls';

const FlaggedBox = (props) => {
    useEffect(() => {
        !props.chkValue && props.resetInput(props.txtName);
    }, [props.chkValue]);
    return (
        <>
            <InputLabel shrink>{props.label}</InputLabel>
            <Grid container alignItems="flex-end">
                <Grid item xs={1}>
                    <SecondaryCheckbox checked={props.chkValue} onChange={props.onCheckChange} name={props.chkName} />
                </Grid>
                <Grid item xs={11}>
                    <SimpleField
                        placeholder={props.label}
                        value={props.txtValue}
                        onChange={props.inputChange}
                        name={props.txtName}
                        disabled={!props.chkValue}
                    />
                </Grid>
            </Grid>
        </>
    );
};

const YesNoRadio = (props) => {
    return (
        <Grid container>
            <Grid container item xs={12}>
                <InputLabel shrink>{props.label}</InputLabel>
            </Grid>
            <Grid container item spacing={1} style={{ marginLeft: '2px' }}>
                <RadioGroup row aria-label={props.name} name={props.name} value={props.value} onChange={props.onChange}>
                    <FormControlLabel checked={props.value === true} value={true} control={<Radio />} label="Yes" />
                    <FormControlLabel checked={props.value === false} value={false} control={<Radio />} label="No" />
                </RadioGroup>
            </Grid>
        </Grid>
    );
};
const countryList = [
    { id: 'GB', name: 'GB' },
    { id: 'IE', name: 'IE' }
];
const invoiceDetails = (props) => {
    const { invoiceFieldsChange, invoiceFields, setVal, resetInput, invoiceCheckBox, depotFields } = props;

    return (
        <>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                        {/* <Paper className="controller-container"> */}
                        <Typography variant="h5" color="secondary">
                            Invoice Details
                        </Typography>
                        <hr />
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={4} sm={6} lg={3}>
                                <YesNoRadio
                                    name="depotUseAddressAsInvoiceAddress"
                                    value={invoiceFields.depotUseAddressAsInvoiceAddress}
                                    onChange={invoiceFieldsChange}
                                    label="Use Address As Invoice Address?"
                                />
                            </Grid>
                            <Grid item xs={12} md={4} sm={6} lg={6}>
                                <FlaggedBox
                                    label="Send Invoice by Email"
                                    chkName="depotEmailInvoices"
                                    chkValue={invoiceFields.depotEmailInvoices}
                                    txtName="depotInvoiceEmailAddress"
                                    txtValue={invoiceFields.depotInvoiceEmailAddress}
                                    onCheckChange={invoiceCheckBox}
                                    inputChange={invoiceFieldsChange}
                                    resetInput={resetInput}
                                    disabled={invoiceFields.depotUseAddressAsInvoiceAddress}
                                />
                                <FormHelperText error>{depotFields.errors.depotInvoiceEmailAddress}</FormHelperText>
                            </Grid>
                            <Grid item xs={12} md={4} sm={6} lg={3}>
                                <Postcode
                                    value={invoiceFields.depotInvoiceAddressPostCode || ''}
                                    onChange={invoiceFieldsChange}
                                    name="depotInvoiceAddressPostCode"
                                    setVal={setVal}
                                    disabled={invoiceFields.depotUseAddressAsInvoiceAddress}
                                />
                            </Grid>
                            <Grid item xs={12} md={4} sm={6} lg={3}>
                                <SimpleField
                                    inputLabel="Address1"
                                    placeholder="Address1"
                                    value={invoiceFields.depotInvoiceAddress1 || ''}
                                    onChange={invoiceFieldsChange}
                                    name="depotInvoiceAddress1"
                                    disabled={invoiceFields.depotUseAddressAsInvoiceAddress}
                                />
                                <FormHelperText error>{invoiceFields.errors.depotInvoiceAddress1}</FormHelperText>
                            </Grid>
                            <Grid item xs={12} md={4} sm={6} lg={3}>
                                <SimpleField
                                    inputLabel="Address2"
                                    placeholder="Address2"
                                    value={invoiceFields.depotInvoiceAddress2 || ''}
                                    onChange={invoiceFieldsChange}
                                    name="depotInvoiceAddress2"
                                    disabled={invoiceFields.depotUseAddressAsInvoiceAddress}
                                />
                            </Grid>
                            <Grid item xs={12} md={4} sm={6} lg={3}>
                                <SimpleField
                                    inputLabel="City"
                                    placeholder="City"
                                    value={invoiceFields.depotInvoiceAddressCity || ''}
                                    onChange={invoiceFieldsChange}
                                    name="depotInvoiceAddressCity"
                                    disabled={invoiceFields.depotUseAddressAsInvoiceAddress}
                                />
                            </Grid>
                            <Grid item xs={12} md={4} sm={6} lg={3}>
                                <SimpleField
                                    inputLabel="County"
                                    placeholder="County"
                                    value={invoiceFields.depotInvoiceAddressCounty || ''}
                                    onChange={invoiceFieldsChange}
                                    name="depotInvoiceAddressCounty"
                                    disabled={invoiceFields.depotUseAddressAsInvoiceAddress}
                                />
                            </Grid>
                            <Grid item xs={12} md={4} sm={6} lg={3}>
                                <InputLabel shrink>Country Code</InputLabel>
                                <SelectBox
                                    name="depotInvoiceAddressCountryCode"
                                    value={invoiceFields.depotInvoiceAddressCountryCode}
                                    onChange={invoiceFieldsChange}
                                    List={countryList}
                                    disabled={invoiceFields.depotUseAddressAsInvoiceAddress}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default invoiceDetails;
