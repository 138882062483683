import React, { useEffect, useState, useMemo } from 'react';
import { BreadCrumbs, DataGrid, EditButton, formatters } from '../../../Core/Controls';
import { getVehicleBreakdowns, getAllVehicleBreakdowns, getVechicleIDByRegNO } from '../../../Core/Services/InternalUser/ServiceAgent/breakdownService';
import AddBreaddownModal from './addBreakdown';
import GijgoGrid from '../../../Core/Controls/GijgoGrid';
import Grid from '@material-ui/core/Grid';
import BuildIcon from '@material-ui/icons/Build';
import { CustomIconButton } from '../../../Core/Controls/Inputs/Buttons/ButtonModal';
import { useHistory } from 'react-router-dom';

const tempCrumb = [
    { name: 'Home', path: '/' },
    { name: 'Maintenance', active: true },
    { name: 'Breakdowns', path: '/', active: true }
];

const baseColumns = [
    { key: 'action', name: 'Action', width: 80, addFileUploaderIcon: true },
    // { key: 'customer', name: 'Customer Name', width: '10%' },
    { key: 'defectRegNumber', name: 'Reg Number', width: 100 },
    {
        key: 'defectDate',
        name: 'Date ',
        formatter: formatters.Date_DDMMYYYY,
        width: 90
    },
    { key: 'defectDescription', name: 'Details' },
    { key: 'defectContactName', name: 'Reported By', width: 100 },
    { key: 'serviceAgentName', name: 'Service Agent', width: 180 },
    { key: 'defectJobStatusDescription', name: 'Status', width: 200 }
];

const DriverBreakDownDashboard = (props) => {
    let history = useHistory();

    const [state, setState] = useState({
        breakdownList: [],
        loader: true,
        modalToggle: false,
        breakID: null,
        rowVehicleID: null,
        show: false,
        filterVal: ''
    });

    const handleBreakDown = (params, VehicleID, modalName) => {
        setState((st) => ({
            ...st,
            modalName: modalName,
            modalToggle: true,
            breakID: params,
            rowVehicleID: VehicleID
        }));
    };
    const closeModal = (res) => {
        setState((st) => ({
            ...st,
            modalToggle: false,
            filterVal: '',
            filterVehicleID: null,
            breakID: null,
            modalName: ''
        }));
        res && pullBreakDownAndUpdateState();
    };

    const inputChange = (e) => {
        const value = e.target.value;
        setState((st) => ({
            ...st,
            filterVal: value
        }));
    };

    useEffect(() => {
        pullBreakDownAndUpdateState();
    }, []);

    useEffect(async () => {
        if (!props.VehicleID) {
            if (state.filterVal.length > 4) {
                let resBrekDown = await getVechicleIDByRegNO(state.filterVal);
                setState((st) => {
                    let newSt = { ...st };
                    if (resBrekDown.data?.list) {
                        newSt.show = true;
                        newSt.filterVehicleID = resBrekDown.data?.list[0].vehicleID;
                        newSt.isReload = new Date();
                    } else {
                        newSt.show = false;
                    }
                    return newSt;
                });
            } else {
                setState((st) => ({ ...st, show: false }));
            }
        }
    }, [state.filterVal]);

    const pullBreakDownAndUpdateState = async () => {
        let defecttypecode = '';
        if (props.VehicleID) {
            defecttypecode = '';
        }
        if (props?.match?.params?.all == 'typebo') {
            defecttypecode = 'bo';
        }
        if (props?.match?.params?.all == 'typebr') {
            defecttypecode = 'br';
        }
        if (props?.match?.params?.all == ':all') {
            defecttypecode = '';
        }

        setState((st) => ({
            ...st,
            defecttypecode: defecttypecode
        }));
    };

    const handleEditBreakdown = (val) => {
        history.push({
            pathname: `/maintenance/breakdowns/Edit/${val?.defectID}/${val?.defectVehicleID}`,
            state: 'Edit'
        });
    };

    let urlForcondition = `VehicleBreakdowns/?${props.VehicleID ? `VehicleID=${props.VehicleID}` : ''}defecttypecode=${state.defecttypecode}`;

    const dataFormatter = (data, row) => {
        return (
            <Grid item>
                <CustomIconButton
                    icon={BuildIcon}
                    color="secondary"
                    className="modal-btn-update btn_space"
                    toolTipTitle="Edit Breakdown"
                    // onClick={() => handleBreakDown(row.defectID, row.defectVehicleID, 'Edit')}
                    onClick={() => handleEditBreakdown(row)}
                />
                {/* <EditButton toolTipTitle="Edit Breakdown" onClick={() => handleBreakDown(row.defectID, row.defectVehicleID, 'Edit')} /> */}
            </Grid>
        );
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.key === 'action');
        if (actionCol) {
            actionCol.formatter = dataFormatter;
        }
        return tempCols;
    }, []);

    const crumbs = useMemo(() => {
        const tempCols = [...tempCrumb];
        let actionCol = tempCols.find((element) => element.name === 'Breakdowns');
        if (actionCol) {
            actionCol.onClick = closeModal;
        }
        return tempCols;
    }, []);

    const gridBtnTitle = state.show ? 'Add Breakdown' : null;

    return (
        <div className={!props.frompage ? `screen` : ''}>
            {state.modalName === 'Edit' ? null : (
                <div style={{ width: '100%', display: 'flex', alignItems: 'center', alignContent: 'center', position: 'relative', top: 0 }}>
                    <div style={{ width: 'auto' }}>
                        <BreadCrumbs crumbs={crumbs} className="edit-service-agent-btn" />
                    </div>
                    &nbsp;&nbsp;
                    {state.modalName === 'Add' ? null : (
                        <div style={{ width: 'auto' }}>
                            <input type="text" onChange={inputChange} placeholder="Enter Reg Number" value={state.filterVal} style={{ padding: 5 }} />
                        </div>
                    )}
                </div>
            )}
            {state.modalToggle ? (
                <>
                    <br />
                    <AddBreaddownModal
                        VehicleID={props.VehicleID || state.rowVehicleID || state.filterVehicleID}
                        breakDownID={state.breakID}
                        isAddJobBtnShow={true}
                        onClose={() => closeModal(true)}
                        modalName={state.modalName}
                    />
                </>
            ) : (
                <>
                    <GijgoGrid
                        dataConverter={(res) => ({
                            records: res?.data?.list,
                            total: res?.data?.total
                        })}
                        columns={columns}
                        idFieldName="defectID"
                        fileUploaderConfigName="BreakdownScreen"
                        getUrl={urlForcondition}
                        btnTitle={gridBtnTitle}
                        onBtnClick={() => handleBreakDown(null, state.filterVehicleID, 'Add')}
                        isReload={state.isReload}
                    />
                </>
            )}
        </div>
    );
};

export default DriverBreakDownDashboard;
