import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableRow, TableHead, TableContainer, TableBody, TableCell, Button } from '@material-ui/core';
import { getAllvehiclecheckchecks } from '../../../../Core/Services/InternalUser/Vehicle/vehicleChecks';
import { AddButton, EditButton } from '../../../../Core/Controls';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import VehicleCheckCheckMdal from './vehicleCheckCheckModal';
import AddIcon from '@material-ui/icons/Add';
import './vehicleStyle.scss';

export const BasicTable = (props) => {
    const [state, setState] = useState({
        checkCheckList: [],
        modalToggle: false,
        userID: null,
        addID: null
    });

    useEffect(async () => {
        pullData();
    }, []);

    const pullData = async () => {
        let res = await getAllvehiclecheckchecks();
        setState((st) => ({
            ...st,
            checkCheckList: res.data.filter((data) => {
                return data.inspectionSheetSectionDescription === props.accName && data.vehicleCategoryID == props.checksMainID;
            })
        }));
    };
    const handleAddEditModal = (id, addID, accHeading) => {
        setState((st) => ({
            ...st,
            modalToggle: true,
            userID: id,
            addID: addID
        }));
    };

    const closeDialog = (res) => {
        setState((st) => ({
            ...st,
            modalToggle: false
        }));
        res && pullData();
    };

    const title = state.userID ? `Update Vehicle Check in ${props.accName}` : `Add Vehicle Check in ${props.accName}`;
    return (
        <div className="table_component">
            <TableContainer>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow hover={true} selected={true}>
                            <TableCell align="center" width="10%">
                                <span className="check-btn">
                                    <AddButton onClick={() => handleAddEditModal(null, props.checksID)}>{/* <AddIcon fontSize="small" /> */}</AddButton>
                                </span>
                            </TableCell>
                            <TableCell align="left" width="25%">
                                Title
                            </TableCell>
                            <TableCell align="left">IM Reference</TableCell>
                            <TableCell align="left">Position</TableCell>
                            <TableCell align="left">Legally Required</TableCell>
                            <TableCell align="left">Is Active ?</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {state.checkCheckList.map((m) => (
                            <TableRow key={m.inspectionSheetCheckID}>
                                <TableCell component="th" scope="row" align="center">
                                    <EditButton onClick={() => handleAddEditModal(m.inspectionSheetCheckID)} />
                                </TableCell>
                                <TableCell align="left">{m.inspectionSheetCheckDescription}</TableCell>
                                <TableCell align="left">{m.inspectionSheetIMReference}</TableCell>
                                <TableCell align="left">{m.inspectionSheetCheckPosition}</TableCell>
                                <TableCell align="left">{m.inspectionSheetCheckLegalRequirement ? 'Yes' : 'No'} </TableCell>
                                <TableCell align="left">{m.inspectionSheetCheckActive ? 'Yes' : 'No'}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {state.modalToggle ? (
                <DialogComp title={title} onClose={closeDialog}>
                    <VehicleCheckCheckMdal addID={state.addID} checkUserID={state.userID} onClose={closeDialog} />
                </DialogComp>
            ) : null}
        </div>
    );
};
