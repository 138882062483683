import React, { useEffect, useState, useMemo } from 'react';
import { getAllDepots } from '../../../../Core/Services/InternalUser/ControllerService/depotsService';
import { useToast } from '../../../../Core/Hooks/useToast';
import { EditButton, DataGrid, formatters } from '../../../../Core/Controls';
import AddEditDepots from './addEditDepots';
import '../../../commonStyle.scss';
import GijgoGrid from '../../../../Core/Controls/GijgoGrid';
import Grid from '@material-ui/core/Grid';
import { AppStorage } from '../../../../Core/Basic/storage-service';

const baseColumns = [
    { key: 'action', name: 'Action', width: '5%', addFileUploaderIcon: true },
    { key: 'depotName', name: 'Name', width: '15%' },
    { key: 'depotEmail', name: 'Email', width: '20%' },
    {
        key: 'depotAddress',
        name: 'Address'
    }
];

const DepotsScreen = (props) => {
    const { showToastError } = useToast();
    let getAccessParam = AppStorage.getAllowAcces();
    const [state, setState] = useState({
        show: false,
        showLoader: true,
        depotsList: [],
        depotID: null
    });

    const pullDepotsAndUpdateState = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    useEffect(async () => {
        pullDepotsAndUpdateState();
    }, []);

    const handleAdd = (record) => {
        setState((st) => ({
            ...st,
            show: true,
            depotID: record
        }));
    };

    const closeDepotsModal = (res) => {
        setState((st) => ({
            ...st,
            show: false
        }));
        res && pullDepotsAndUpdateState();
    };

    const dataFormatter = (data, row) => {
        return (
            <Grid item>
                <EditButton
                    toolTipTitle="Edit Depot"
                    onClick={() => {
                        handleAdd(row.depotID);
                    }}
                />
            </Grid>
        );
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.key === 'action');
        if (actionCol) {
            actionCol.formatter = dataFormatter;
        }
        if (!props.isCust) {
            tempCols.splice(2, 0, { key: 'customer', name: 'Customer Name', width: '20%' });
        }
        return tempCols;
    }, []);

    return (
        <div className="controller-screen">
            {state.show ? (
                <AddEditDepots depotID={state.depotID} onFormClose={closeDepotsModal} customerID={props.customerID} />
            ) : (
                <>
                    <GijgoGrid
                        btnTitle={getAccessParam?.CanUpdateDepots ? 'Add Depots' : ''}
                        onBtnClick={() => handleAdd(null)}
                        getUrl={`depots?CustomerID=${props.customerID}`}
                        columns={columns}
                        idFieldName="depotID"
                        fileUploaderConfigName="DepotScreen"
                        dataConverter={(res) => ({
                            records: res.data.list,
                            total: res.data.total
                        })}
                        isReload={state.isReload}
                    />
                </>
            )}
        </div>
    );
};
export default DepotsScreen;
