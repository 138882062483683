import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';

const closeIconStyle = {
    marginRight: 7
};

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}
let DialogComp = ({ title, children, footer, onClose, maxWidth = 'sm', overflow = 'hidden', height = 'none', ...rest }) => {
    return (
        <>
            <Dialog onClose={onClose} PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title" open={true} maxWidth={maxWidth} {...rest}>
                <Box display="flex" alignItems="center" style={{ position: title ? 'relative' : 'absolute', right: '0px' }}>
                    <Box flexGrow={1} style={{ cursor: 'move' }} id="draggable-dialog-title">
                        {title && <DialogTitle style={rest.titleStyle}>{title}</DialogTitle>}
                    </Box>
                    {!rest.hideCloseAction && (
                        <Box>
                            <IconButton onClick={onClose} style={closeIconStyle}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    )}
                </Box>
                <DialogContent style={{ overflow: overflow, height: height }}>{children}</DialogContent>
                {footer && <DialogActions>{footer}</DialogActions>}
            </Dialog>
        </>
    );
};

export default DialogComp;
