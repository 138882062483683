import React, { useState, useEffect, useMemo } from 'react';
import GijgoGrid from '../../../../Core/Controls/GijgoGrid';
import { CustomIconButton } from '../../../../Core/Controls/Inputs/Buttons/ButtonModal';
import PersonIcon from '@material-ui/icons/Person';
import DialogComp from '../../../../Core/Dialog/DialogComp';
import VehicleCheckOut from './checkOut';
import { BreadCrumbs } from '../../../../Core/Controls';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
const crumbs = [
    { name: 'Home', path: '/' },
    { name: 'Check Out', active: true }
];

const baseColumns = [
    {
        key: 'action',
        name: 'Action',
        width: 30,
        addFileUploaderIcon: true
    },
    { key: 'vehicleRegNumber', name: 'Reg Number', width: 100 },
    { key: 'vehicleMakeModel', name: 'Vehicle Description', width: 200 },
    { key: 'depotName', name: 'Depot', width: 100 }
];

const CheckOutScreen = (props) => {
    const [state, setState] = useState({
        open: false,
        showLoader: true,
        selectedRowData: {},
        isReload: null
    });
    useEffect(async () => {
        pullApiData();
    }, []);

    const pullApiData = async () => {
        // let res = await getAllJobReminders();
        setState((st) => ({
            ...st,
            isReload: new Date(),
            showLoader: false
        }));
    };

    const dataFormatter = (data, row) => {
        return (
            <div style={{ textAlign: 'center' }}>
                {/* <Button onClick={() => handleAdd(data.row)}>
                    <AddIcon />
                </Button> */}
                <CustomIconButton
                    icon={ExitToAppIcon}
                    color="secondary"
                    className="modal-btn-update btn_space"
                    toolTipTitle="Check Out Vehicle"
                    onClick={() =>
                        setState((st) => ({
                            ...st,
                            show: true,
                            selectedRowData: row
                        }))
                    }
                />
            </div>
        );
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.key === 'action');
        if (actionCol) {
            actionCol.formatter = dataFormatter;
        }
        return tempCols;
    }, []);

    const closeCheckOutModal = (res) => {
        setState((st) => ({
            ...st,
            show: false
        }));
        res && pullApiData();
    };

    return (
        <div className={`screen`}>
            <BreadCrumbs crumbs={crumbs} className="edit-service-agent-btn" />
            <GijgoGrid
                dataConverter={(res) => ({
                    records: res.data.list,
                    total: res.data.total
                })}
                columns={columns}
                getUrl={`Drivers/GetDriverVehicles`}
                // btnTitle={gridBtnTitle}
                // onBtnClick={() => handleBreakDown(null, null, 'Add')}
                isReload={state.isReload}
            />
            {state.show ? (
                <DialogComp title={'Vehicle Checkout'} onClose={() => closeCheckOutModal(false)} maxWidth="md">
                    <VehicleCheckOut data={state.selectedRowData} onClose={closeCheckOutModal} />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default CheckOutScreen;
