import React, { useEffect, useState, useMemo } from 'react';
import { getAllVehicle } from '../../../Core/Services/InternalUser/ControllerService/vehicleService';
import { useToast } from '../../../Core/Hooks/useToast';
import { EditButton, DataGrid, BreadCrumbs, formatters } from '../../../Core/Controls';
import AddEditVehicle from '../../InternalUser/Controllers/Vehicles/addEditVehicle';
import EditVehicle from '../../InternalUser/Controllers/Vehicles/editVehicle';

import '../../commonStyle.scss';
import GijgoGrid from '../../../Core/Controls/GijgoGrid';

const baseColumns = [
    {
        key: 'action',
        name: 'Action',
        width: 90,
        addFileUploaderIcon: true
    },
    { key: 'vehicleRegNumber', name: 'Reg Number', width: 110 },
    { key: 'vehicleMakeModel', name: 'Make/Model' },
    {
        key: 'vehicleDateOfRegistration',
        name: 'Date Of Reg',
        formatter: formatters.Date_DDMMYYYY,
        width: 110
    },
    {
        key: 'vehicleMOTExpiry',
        name: 'MOT Expiry',
        formatter: formatters.dueDateFormatter,
        width: 110
    },
    {
        key: 'vehicleTaxDue',
        name: 'Tax Due',
        formatter: formatters.dueDateFormatter,
        width: 110
    }
];

const baseCrumbs = [
    { name: 'Home', path: '/' },
    { name: 'Vehicles', active: true }
];

const CustomerVehicleScreen = (props) => {
    const { showToastError } = useToast();

    const [state, setState] = useState({
        show: false,
        showEditPopup: false,
        showLoader: true,
        vehicleList: [],
        VehicleID: null,
        id: props.match.params?.all
    });

    const pullVehicleAndUpdateState = async () => {
        setState((st) => ({
            ...st,
            isReload: new Date()
        }));
    };

    useEffect(async () => {
        pullVehicleAndUpdateState();
    }, [props.match.params?.all]);

    const handleAdd = (record) => {
        setState((st) => ({
            ...st,
            show: true
            // VehicleID: record.vehicleID
        }));
    };

    const closeVehicleModal = (res) => {
        setState((st) => ({
            ...st,
            show: false
        }));
        res && pullVehicleAndUpdateState();
    };
    const handleEditClick = (val) => {
        props.history.push({
            pathname: `/controller/editVehicle/${val.vehicleID}/${val.vehicleRegNumber}`
        });
    };

    const dataFormatter = (data, row) => {
        return (
            <div style={{ textAlign: 'center' }}>
                <EditButton toolTipTitle="Edit Vehicle" onClick={() => handleEditClick(row)} />
            </div>
        );
    };

    const columns = useMemo(() => {
        const tempCols = [...baseColumns];
        let actionCol = tempCols.find((element) => element.key === 'action');

        if (actionCol) {
            actionCol.formatter = dataFormatter;
        }

        return tempCols;
    }, []);

    const crumbs = useMemo(() => {
        const tempCrums = [...baseCrumbs];
        if (props.match.params?.all === 'T') {
            tempCrums.push({ name: 'Tax Due' });
        }
        if (props.match.params?.all === 'I') {
            tempCrums.push({ name: 'Insurance' });
        }
        return tempCrums;
    }, [props.match.params?.all]);

    const title = state.VehicleID ? 'Update Vehicle' : 'Add Vehicle';

    return (
        <div className="screen">
            {state.show ? (
                <AddEditVehicle VehicleID={state.VehicleID} onFormSubmit={() => closeVehicleModal(true)} />
            ) : (
                <>
                    <BreadCrumbs crumbs={crumbs} />
                    <GijgoGrid
                        columns={columns}
                        getUrl={`vehicles?mode=${state.id}`}
                        dataConverter={(res) => ({
                            records: res.data.list,
                            total: res.data.total
                        })}
                        isReload={state.isReload}
                        btnTitle="Add Vehicle"
                        onBtnClick={handleAdd}
                        idFieldName="vehicleID"
                        fileUploaderConfigName="VehicleScreen"
                    />
                </>
            )}
            {state.showEditPopup ? <EditVehicle /> : null}
        </div>
    );
};
export default CustomerVehicleScreen;
