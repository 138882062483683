import React, { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import { InputLabel, FormHelperText } from '@material-ui/core';
import { InputText, SelectBox, SecondaryCheckbox, SimpleField, SingleSelect } from '../../../../../Core/Controls';
import { VehicleProvider as StateProvider, VehicleScreenContext } from '../VehicleProvider';
import Paper from '@material-ui/core/Paper';
import '../../controllerStyle.scss';
import SimpleRating from '../../../../../Core/Controls/Rating';

const JobFieldsModal = (props) => {
    const {
        state,
        handleSubmit,
        inputChange,
        otherFields,
        otherFieldsChange,
        jobFields,
        jobFieldsChange,
        handleCheckbox,
        dateFieldsChange,
        dateFields,
        showRatingModal,
        handleTabPress,
        singleSelectChange,
        tempOptions
    } = useContext(VehicleScreenContext);

    return (
        <>
            <Grid item xs={12} md={12} lg={12} className="grid-paper-shadow">
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <SimpleField
                            inputLabel="Tank Capacity"
                            type="number"
                            placeholder="Tank Capacity"
                            value={jobFields.vehicleTankCapacity}
                            onChange={jobFieldsChange}
                            name="vehicleTankCapacity"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <SimpleField
                            inputLabel="Telematics IMEI"
                            placeholder="Telematics IMEI"
                            value={jobFields.vehicleTelematicsIMEI}
                            onChange={jobFieldsChange}
                            name="vehicleTelematicsIMEI"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <SimpleField inputLabel="Radio Code" placeholder="Radio Code" value={jobFields.radioCode} onChange={jobFieldsChange} name="radioCode" />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <SimpleField inputLabel="Wheel Plan" placeholder="Wheel Plan" value={state.wheelPlan} onChange={inputChange} name="wheelPlan" />
                        <FormHelperText error>{state.errors.wheelPlan}</FormHelperText>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <InputLabel shrink>Cab Type</InputLabel>
                        {/* <SelectBox
                            placeholder="Cab Type"
                            value={jobFields.cabTypeID}
                            onChange={jobFieldsChange}
                            name="cabTypeID"
                            List={jobFields.cabTypeList}
                        /> */}
                        <SingleSelect options={jobFields.cabTypeList} value={tempOptions.cabTypeIDValue} onChange={singleSelectChange('cabTypeIDValue')} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <InputLabel shrink>Maintenance Provider</InputLabel>
                        {/* <SelectBox
                            placeholder="Maintenance Provider"
                            value={jobFields.vehicleMaintenanceProviderID}
                            onChange={jobFieldsChange}
                            name="vehicleMaintenanceProviderID"
                            List={jobFields.vehicleMaintenanceProviderList}
                        /> */}
                        <SingleSelect
                            options={jobFields.vehicleMaintenanceProviderList}
                            value={tempOptions.vehicleMaintenanceProviderIDValue}
                            onChange={singleSelectChange('vehicleMaintenanceProviderIDValue')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <SimpleField
                            inputLabel="Annual Mileage"
                            type="number"
                            placeholder="Annual Mileage"
                            value={jobFields.vehicleAnnualMileage}
                            onChange={jobFieldsChange}
                            name="vehicleAnnualMileage"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <SimpleField
                            inputLabel="Tax Amount"
                            type="number"
                            placeholder="Tax Amount"
                            value={jobFields.vehicleTaxAmount}
                            onChange={jobFieldsChange}
                            name="vehicleTaxAmount"
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <InputLabel shrink>Suspension</InputLabel>
                        {/* <SelectBox
                            name="vehicleSuspensionTypeID"
                            value={jobFields.vehicleSuspensionTypeID}
                            onChange={jobFieldsChange}
                            List={jobFields.suspensionList}
                        /> */}
                        <SingleSelect
                            options={jobFields.suspensionList}
                            value={tempOptions.vehicleSuspensionTypeIDValue}
                            onChange={singleSelectChange('vehicleSuspensionTypeIDValue')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <InputLabel shrink>Body Type</InputLabel>
                        {/* <SelectBox
                            name="vehicleBodyTypeID"
                            value={jobFields.vehicleBodyTypeID}
                            onChange={jobFieldsChange}
                            List={jobFields.vehicleBodyTypeList}
                        /> */}
                        <SingleSelect
                            options={jobFields.vehicleBodyTypeList}
                            value={tempOptions.vehicleBodyTypeIDValue}
                            onChange={singleSelectChange('vehicleBodyTypeIDValue')}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <InputLabel shrink>Configuration</InputLabel>
                        {/* <SelectBox
                            name="vehicleConfigurationTypeID"
                            value={jobFields.vehicleConfigurationTypeID}
                            onChange={jobFieldsChange}
                            List={jobFields.configurationTypeList}
                        /> */}
                        <SingleSelect
                            options={jobFields.configurationTypeList}
                            value={tempOptions.vehicleConfigurationTypeIDValue}
                            onChange={singleSelectChange('vehicleConfigurationTypeIDValue')}
                        />
                    </Grid>

                    {/* <Grid item xs={12} sm={6} md={4} lg={3}>
                            <InputLabel shrink>Hire Supplier</InputLabel>
                            <SelectBox name="hireSupplierID" value={jobFields.hireSupplierID} onChange={jobFieldsChange} List={jobFields.hireSupplierList} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <InputLabel shrink>Hire History</InputLabel>
                            <SelectBox name="hireHistoryID" value={jobFields.hireHistoryID} onChange={jobFieldsChange} List={jobFields.hireHistoryList} />
                        </Grid> */}
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <SimpleField inputLabel="MPG" type="number" placeholder="MPG" value={otherFields.MPG} onChange={otherFieldsChange} name="MPG" />
                        <FormHelperText error>{otherFields.errors.MPG}</FormHelperText>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <SimpleField
                            inputLabel="Vehicle Check Frequency"
                            type="number"
                            placeholder="Vehicle Check Frequency"
                            value={otherFields.vehicleCheckFrequency}
                            onChange={otherFieldsChange}
                            name="vehicleCheckFrequency"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        {/* <InputLabel shrink></InputLabel>  */}
                        {/* <div> */}
                        <SimpleRating
                            title="Direct Vision Standard"
                            onRatingHandle={showRatingModal}
                            ratingValue={state.vehicleDirectVisionStandardRating}
                            name="vehicleDirectVisionStandardRating"
                            style={{ marginTop: -10 }}
                        />
                        {/* </div> */}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default JobFieldsModal;
