import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        boxShadow: (props) => `2px 5px 5px 0px ${props.color ? 'darkgrey' : 'lightgrey'}`,
        border: (props) => `1px solid ${props.color ? theme.palette[props.color].dark : 'lightgrey'}`,
        borderRadius: 5,
        marginBottom: 10,
        overflow: 'hidden',

        '&::before': {
            backgroundColor: 'transparent'
        }
    },

    heading: {
        background: (props) => (props.color ? theme.palette[props.color].dark : ''),
        color: (props) => (props.color ? theme.palette[props.color].contrastText : ''),
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
        borderBottom: (props) => `1px solid ${props.color ? theme.palette[props.color].dark : 'lightgrey'}`,

        '& .MuiIconButton-edgeEnd': {
            color: 'inherit'
        }
    }
}));

const AccordionComponent = (props) => {
    const classes = useStyles(props);
    const [expand, setExpand] = useState({ open: false });
    useEffect(() => {
        setExpand((st) => ({ ...st, open: props.Expand }));
    }, [props.Expand, props.changedAt]);
    const handleChange = (event, isExpanded) => {
        setExpand((st) => ({ ...st, open: isExpanded }));
    };
    return (
        <Accordion className={classes.root} key={props.accKey} expanded={expand.open} onChange={handleChange}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" className={classes.heading}>
                {props.header}
            </AccordionSummary>
            <AccordionDetails className={classes.body}>{props.accordianBody || props.children}</AccordionDetails>
        </Accordion>
    );
};

AccordionComponent.defaultProps = {
    Expand: false
};

export default AccordionComponent;
