import React from 'react';
import { FormControlLabel } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { useLoginInfo } from '../../Hooks/useLoginInfo';

const UnderlyingCheckBox = ({ props, isPrimary }) => {
    const { forceEditable, ...rest } = props;
    let { isReadOnlyUser } = useLoginInfo();
    forceEditable && (isReadOnlyUser = false);
    return (
        <Checkbox
            disabled={isReadOnlyUser}
            color={isPrimary ? 'primary' : 'secondary'}
            inputProps={{ 'aria-label': `${isPrimary ? 'primary' : 'secondary'} checkbox` }}
            {...rest}
        />
    );
};

const CustomCheckBox = ({ props, isPrimary, label }) => {
    return <FormControlLabel control={<UnderlyingCheckBox props={props} isPrimary={isPrimary} />} label={label} />;
};

export const PrimaryCheckbox = (props) => {
    return <CustomCheckBox props={props} isPrimary={true} label={props.label || ''} />;
};

export const SecondaryCheckbox = React.memo((props) => {
    return <CustomCheckBox props={props} isPrimary={false} label={props.label || ''} />;
});

