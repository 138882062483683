import React from 'react';
import { AddButton, EditButton, BreadCrumbs, formatters, SecondaryButton, SimpleRating } from '../Controls';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import DialogComp from '../Dialog/DialogComp';

export default function ConfirmationModal(props) {
    return (
        // <DialogComp title={props.title} onClose={props.handleCancel} maxWidth="lg">
        <Grid container spacing={0}>
            <Typography component="legend">{props.message}</Typography>
            <br />
            <Grid item container xs={12} spacing={1} style={{ marginTop: 16 }}>
                <Grid item xs={props.handleCancel ? 6 : 12}>
                    <SecondaryButton onClick={props.handleSubmit} fullWidth className="submit_btn">
                        Confirm
                    </SecondaryButton>
                </Grid>
                {props.handleCancel && (
                    <Grid item xs={6}>
                        <SecondaryButton onClick={props.handleCancel} fullWidth className="submit_btn">
                            Cancel
                        </SecondaryButton>
                    </Grid>
                )}
            </Grid>
        </Grid>
        // </DialogComp>
    );
}
