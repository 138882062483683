import React, { useEffect, useState } from 'react';
import { SelectBox, InputText, SecondaryButton, SecondaryCheckbox, MandatoryField, SimpleField } from '../../../../Core/Controls';
import { useToast } from '../../../../Core/Hooks/useToast';
import { getvehiclecheckchecksById, getVehicleCheckSection, postvehiclecheckchecksData } from '../../../../Core/Services/InternalUser/Vehicle/vehicleChecks';
import { FormControlLabel, FormHelperText, InputLabel } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

const VehicleCheckCheckMdal = (props) => {
    const { showToastSuccess, showToastError } = useToast();
    const [state, setState] = useState({
        inspectionSheetCheckID: null,
        inspectionSheetCheckDescription: '',
        inspectionSheetSectionID: props.addID,
        inspectionSheetCheckPosition: '',
        inspectionSheetCheckLegalRequirement: false,
        inspectionSheetCheckActive: true,
        inspectionSheetIMReference: '',
        dropdownList: [],
        errors: {}
    });
    useEffect(async () => {
        if (props.checkUserID) {
            let res = await getvehiclecheckchecksById(props.checkUserID);
            if (res.success) {
                setState((state) => ({
                    ...state,
                    inspectionSheetCheckID: res.data.inspectionSheetCheckID,
                    inspectionSheetSectionID: res.data.inspectionSheetCheckSectionID,
                    inspectionSheetCheckDescription: res.data.inspectionSheetCheckDescription,
                    inspectionSheetCheckPosition: res.data.inspectionSheetCheckPosition,
                    inspectionSheetIMReference: res.data.inspectionSheetIMReference,
                    inspectionSheetCheckLegalRequirement: res.data.inspectionSheetCheckLegalRequirement,
                    inspectionSheetCheckActive: res.data.inspectionSheetCheckActive
                }));
            }
        }

        let dropDownRes = await getVehicleCheckSection();
        const listss = dropDownRes.data.map((dropDownData) => ({
            id: dropDownData.inspectionSheetSectionID,
            name: dropDownData.inspectionSheetSectionDescription
        }));
        setState((st) => ({
            ...st,
            dropdownList: listss
        }));
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setState((state) => ({ ...state, [name]: value }));
    };

    const handleCheckbox = (e) => {
        const { name, checked } = e.target;
        setState((state) => {
            const nst = { ...state, [name]: checked };
            return nst;
        });
    };

    const validations = () => {
        const { inspectionSheetCheckDescription, inspectionSheetCheckPosition } = state;
        let formIsValid = true;
        let errors = {};
        if (!inspectionSheetCheckDescription) {
            errors.inspectionSheetCheckDescription = 'Description is required';
            formIsValid = false;
        }
        if (!inspectionSheetCheckPosition) {
            errors.inspectionSheetCheckPosition = 'Position is required';
            formIsValid = false;
        }
        setState((state) => ({ ...state, errors: errors }));
        return formIsValid;
    };

    const submitHandler = async () => {
        if (validations()) {
            let formData = {
                inspectionSheetCheckID: state.inspectionSheetCheckID,
                inspectionSheetCheckSectionID: state.inspectionSheetSectionID,
                inspectionSheetCheckDescription: state.inspectionSheetCheckDescription,
                inspectionSheetIMReference: state.inspectionSheetIMReference,
                inspectionSheetCheckPosition: state.inspectionSheetCheckPosition,
                inspectionSheetCheckLegalRequirement: state.inspectionSheetCheckLegalRequirement,
                inspectionSheetCheckActive: state.inspectionSheetCheckActive
            };
            let res = await postvehiclecheckchecksData(formData);
            if (res.success) {
                showToastSuccess('Vehicle Check Checks Added Successfully');
                props.onClose(true);
            } else {
                showToastError(res.message);
                // props.onClose(false);
            }
        }
    };

    return (
        <Grid container direction={'row'} spacing={2}>
            <Grid item xs={12} className="mandatory-fields">
                <MandatoryField
                    inputLabel="Description"
                    placeholder="Description"
                    name="inspectionSheetCheckDescription"
                    value={state.inspectionSheetCheckDescription}
                    onChange={handleInputChange}
                    errors={state.errors.inspectionSheetCheckDescription}
                />
            </Grid>
            <Grid item xs={6} className="mandatory-fields" style={{ display: 'none' }}>
                <MandatoryField
                    inputLabel="Section"
                    name="inspectionSheetSectionID"
                    value={state.inspectionSheetSectionID}
                    List={state.dropdownList}
                    onChange={handleInputChange}
                />
            </Grid>
            <Grid item xs={6}>
                <SimpleField
                    inputLabel="IM Reference"
                    placeholder="IM Reference"
                    name="inspectionSheetIMReference"
                    value={state.inspectionSheetIMReference}
                    onChange={handleInputChange}
                />
            </Grid>

            <Grid item xs={6} className="mandatory-fields">
                <MandatoryField
                    inputLabel="Position"
                    placeholder="Position"
                    name="inspectionSheetCheckPosition"
                    value={state.inspectionSheetCheckPosition}
                    onChange={handleInputChange}
                    errors={state.errors.inspectionSheetCheckPosition}
                />
            </Grid>
            <Grid item xs={6}>
                <SecondaryCheckbox
                    checked={state.inspectionSheetCheckLegalRequirement}
                    name="inspectionSheetCheckLegalRequirement"
                    onChange={handleCheckbox}
                    label="Legal Requirement"
                />
            </Grid>
            <Grid item xs={6}>
                <SecondaryCheckbox checked={state.inspectionSheetCheckActive} name="inspectionSheetCheckActive" onChange={handleCheckbox} label="Is Active ?" />
            </Grid>
            <Grid item xs={6} className="submit_btn">
                <SecondaryButton onClick={submitHandler}> {props.checkUserID ? 'Update' : 'Add'} </SecondaryButton>
            </Grid>
            <Grid item xs={6} className="submit_btn">
                <SecondaryButton onClick={() => props.onClose()}>Cancel </SecondaryButton>
            </Grid>
        </Grid>
    );
};

export default VehicleCheckCheckMdal;
