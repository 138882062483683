import React, { useState, useEffect, useMemo } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CardHeader from '@material-ui/core/CardHeader';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import { CustomIconButton, PrimaryButton, SecondaryButton } from '../../../Core/Controls/Inputs/Buttons/ButtonModal';
import NoteIcon from '@material-ui/icons/Note';
import EditIcon from '@material-ui/icons/Edit';
import EventIcon from '@material-ui/icons/Event';
import WorkIcon from '@material-ui/icons/Work';
import { Link } from 'react-router-dom';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { EditButton, formatters, BreadCrumbs, DatePicker, SpeedDials, SelectBox, SingleSelect } from '../../../Core/Controls';
// import ScheduleSpeedDials from '../../../Core/Controls/SpeedDial/scheduleSpeedDial';
import DialogComp from '../../../Core/Dialog/DialogComp';
import DialogBox from '../../InternalUser/Controllers/Vehicles/ScheduleVehicle/scheduleVehicleAdd';
import EditSchedule from '../../InternalUser/Controllers/Vehicles/ScheduleVehicle/scheduleVehicleEdit';
import ScheduleNotes from '../../InternalUser/Controllers/Vehicles/ScheduleVehicle/scheduleVehicleNotes';
import ScheduleBookingStatus from '../../InternalUser/Controllers/Vehicles/ScheduleVehicle/scheduleBookingStatus';
import ScheduleBookingSection from '../../InternalUser/Controllers/Vehicles/ScheduleVehicle/scheduleBookingSection';
import CompleteScheduleModal from '../../InternalUser/Schedule/completeSchedule';
import DataGrid from '../../../Core/Controls/Grid/index';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CloseIcon from '@material-ui/icons/Close';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import { InputLabel, FormHelperText, MenuItem, TextField } from '@material-ui/core';

import CircularProgress from '@material-ui/core/CircularProgress';
import { getScheduleVehicleById } from '../../../Core/Services/InternalUser/ControllerService/scheduleVehicleService';

import moment from 'moment';

// import './allSchedulesStyle.scss';

import { useLoginInfo } from '../../../Core/Hooks/useLoginInfo';
import { AppStorage } from '../../../Core/Basic/storage-service';
import { getAllVehicleSchedules } from '../../../Core/Services/InternalUser/Maintenance/scheduleService';
import {
    getCustomersListDropdowns,
    getSchedulePlannerData,
    getSchedulePlannerDropdowns,
    getVehicleScreenDropdowns
} from '../../../Core/Services/InternalUser/ControllerService/vehicleService';

const useStyles = makeStyles({
    yearInput: {
        '& .MuiOutlinedInput-inputMarginDense': {
            paddingTop: '4.1px',
            paddingBottom: '4.1px',
            color: 'white'
        }
    },
    root: {
        minWidth: 275,
        border: ' 1px solid black',
        height: '100%'
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)'
    },
    title: {
        fontSize: 14
    },
    pos: {
        marginBottom: 12
    },
    cardContent: (props) => {
        let cardContentHeight = props.fromTab ? Math.max(120, (window.innerHeight - 170) / 4 - 60) : Math.max(120, (window.innerHeight - 80) / 4 - 60);
        return {
            height: cardContentHeight,
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
                width: '0.3em'
            },
            '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#1976d2',
                opacity: 5,
                outline: '1px solid slategrey'
            }
        };
    }
});

const YEARS = () => {
    const years = [];
    const d = new Date();
    let startYear = d.getFullYear() - 7;
    let endYear = d.getFullYear() + 7;

    while (endYear - startYear >= 0) {
        years.push({ name: startYear, id: startYear });
        startYear = startYear + 1;
    }
    return years;
};

const ScheduleDashboard = (props) => {
    const classes = useStyles(props);
    const [state, setState] = useState({
        scheduleEventNames: [],
        sortedScheduleEvent: [],
        uniqueYear: [],
        showLoader: true,
        customerID: null,
        depotsID: null,
        customerList: [],
        depotWithCustList: [],
        serviceAgentsList: [],
        isDisabled: false,
        scheduleCustomerIDValue: '',
        scheduleDepotsIDValue: '',
        scheduleServiceAgentIDValue: ''
    });
    const [openDialog, setOpenDialog] = useState({});
    const { userRoles } = useLoginInfo();
    const isServiceAgent = userRoles.includes('serviceagent');
    const pos = isServiceAgent ? 's' : 'm';

    useEffect(async () => {
        pullApiData();
    }, [state.scheduleCustomerIDValue?.id, state.scheduleDepotsIDValue?.id, state.scheduleServiceAgentIDValue?.id]);

    // useEffect(async () => {
    //     let res = await getVehicleScreenDropdowns();
    //     setState((st) => ({
    //         ...st,
    //         customerList: res.data.customers,
    //         depotsList: res.data.depots,
    //         serviceAgentsList: res.data.serviceAgents
    //     }));
    // }, []);

    useEffect(async () => {
        let resCust = await getCustomersListDropdowns();
        setState((st) => ({
            ...st,
            customerList: resCust.data.customer.table
        }));
    }, []);

    const pullApiData = async (year = moment().format('YYYY')) => {
        let tempSortedScheduleEvent = [
            { month: 'January', events: [] },
            { month: 'February', events: [] },
            { month: 'March', events: [] },
            { month: 'April', events: [] },
            { month: 'May', events: [] },
            { month: 'June', events: [] },
            { month: 'July', events: [] },
            { month: 'August', events: [] },
            { month: 'September', events: [] },
            { month: 'October', events: [] },
            { month: 'November', events: [] },
            { month: 'December', events: [] }
        ];

        let res = props.fromTab
            ? await getScheduleVehicleById(
                  props.scheduleVehicleID,
                  state.scheduleCustomerIDValue?.id,
                  state.scheduleDepotsIDValue?.id,
                  year,
                  state.scheduleServiceAgentIDValue?.id || ''
              )
            : await getAllVehicleSchedules(
                  '',
                  state.scheduleCustomerIDValue?.id,
                  state.scheduleDepotsIDValue?.id,
                  year,
                  state.scheduleServiceAgentIDValue?.id || ''
              );

        const uniquescheduleEventNames = [...new Set(res.data?.schedules.map((item) => item.scheduleEventName))].map((w) => {
            return { name: w, icon: 'VpnKeyIcon' };
        });

        const uniqueYear = YEARS();

        res.data?.schedules
            // .filter((s) => moment(s.scheduleDateDue).format('YYYY') == year)
            .forEach((item) => {
                let objIndex = tempSortedScheduleEvent.findIndex((x) => x.month == moment(item.scheduleDateDue).format('MMMM'));
                if (objIndex + 1) {
                    tempSortedScheduleEvent[objIndex].events.push(item);
                }
            });
        setState((st) => ({
            ...st,
            scheduleEventNames: uniquescheduleEventNames,
            sortedScheduleEvent: tempSortedScheduleEvent,
            uniqueYear: uniqueYear,
            selectedYear: year,
            showLoader: false,
            gridShow: false
        }));
    };

    // useEffect(async () => {
    //     let Depots = [];
    //     if (state.customerID) {
    //         state.depotsList.filter((obj) => {
    //             if (state.customerID === obj.depotCustomerID) {
    //                 Depots.push(obj);
    //             }
    //         });
    //         setState((st) => ({ ...st, depotWithCustList: Depots, depotsID: '' }));
    //     } else {
    //         setState((st) => ({ ...st, depotWithCustList: [], depotsID: '' }));
    //     }
    // }, [state.customerID, state.depotsList]);

    useEffect(async () => {
        let Depots = [];
        let endUsers = [];
        let serviceAgents = [];
        if (state.scheduleCustomerIDValue?.id) {
            let resCust = await getSchedulePlannerDropdowns(state.scheduleCustomerIDValue?.id);
            Depots = resCust.data.depots;
            endUsers = resCust.data.endUsers;
            serviceAgents = resCust.data.serviceAgents;
            setState((st) => ({
                ...st,
                depotWithCustList: Depots,
                scheduleDepotsIDValue: '',
                endUserWithCustList: endUsers,
                serviceAgentsList: serviceAgents
            }));
        } else {
            setState((st) => ({
                ...st,
                depotWithCustList: [],
                scheduleDepotsIDValue: '',
                endUserWithCustList: endUsers,
                scheduleEndUserIDValue: '',
                serviceAgentsList: serviceAgents,
                scheduleServiceAgentIDValue: ''
            }));
        }
    }, [state.scheduleCustomerIDValue?.id]);

    const inputChange = (e) => {
        const { name, value } = e.target;
        setState((st) => {
            let newSt = { ...st, [name]: value };
            newSt.isDisabled = newSt.customerID || false;
            return newSt;
        });
    };

    const handleInputChange2 = (e) => {
        if (e.target.value == '') {
            pullApiData();
        } else pullApiData(e.target.value);
    };

    const checkButton = (j, label) => {
        setOpenDialog({ [label]: true });
        setState((st) => ({
            ...st,
            selectedRowData: j,
            scheduleVehicleID: j.scheduleVehicleID
        }));
    };

    const handleClose = (res) => {
        setState((st) => ({
            ...st,
            open: false,
            selectedRowData: {}
        }));
        res && pullApiData();
    };

    const closeTheDialog = (res) => {
        setOpenDialog({});
        pullApiData();
    };

    const handleClick = (data, month) => {
        setState((st) => ({
            ...st,
            RowData: data,
            selectedMonth: month,
            gridShow: true
        }));
    };

    const closeGrid = () => {
        setState((st) => ({
            ...st,
            gridShow: false
        }));
    };

    const dueDateFormatter = (data) => {
        let textColor = formatters.getScheduleColour(data.scheduleCompletionDate, data.scheduleDateDue, data.dayRange);

        return <span style={{ color: `${textColor}`, fontWeight: `${'bold'}` }}>{data.scheduleEventName}</span>;
    };
    let checkhaveAccess = AppStorage.getAllowAcces();

    const renderEdit = (j, param) => {
        return (
            <span style={{ marginTop: 10 }}>
                {!isServiceAgent && j?.scheduleBookingStatus != 'Completed' && (
                    <span className="modal-btn-update">
                        <CustomIconButton
                            icon={EditIcon}
                            className="modal-btn-update "
                            color="secondary"
                            onClick={() => {
                                setState((st) => ({
                                    ...st,
                                    open: true,
                                    selectedRowData: j,
                                    isAdd: false
                                }));
                            }}
                            toolTipTitle="Edit Vehicle Schedule"
                        />
                    </span>
                )}
                <CustomIconButton
                    icon={NoteIcon}
                    color="primary"
                    className="modal-btn-update "
                    onClick={() => {
                        checkButton(j, 'notes');
                    }}
                    toolTipTitle="Notes"
                />

                {j?.scheduleBookingStatus != 'Booked and Confirmed' && j?.scheduleBookingStatus != 'Completed' && !isServiceAgent ? (
                    <CustomIconButton
                        icon={EventIcon}
                        className="modal-btn-update "
                        color="Secondary"
                        onClick={() => {
                            checkButton(j, 'status');
                        }}
                        toolTipTitle="Update Booking Request"
                    />
                ) : null}

                {!isServiceAgent &&
                    (j?.scheduleBookingStatus != 'Booked and Confirmed' && j?.scheduleBookingStatus != 'Completed' ? (
                        <>
                            <CustomIconButton
                                icon={ConfirmationNumberIcon}
                                color="primary"
                                className="modal-btn-update "
                                onClick={() => {
                                    checkButton(j, 'section');
                                }}
                                toolTipTitle="Book Job"
                            />
                        </>
                    ) : j?.scheduleBookingStatus == 'Completed' ? (
                        <>
                            {checkhaveAccess?.CanViewJobs && (
                                <Link
                                    to={{
                                        pathname: `/controller/jobTabScreen/${j?.scheduleJobID}/${j?.scheduleVehicleID}`,
                                        state: pos
                                    }}
                                    style={{ marginLeft: '5px' }}
                                >
                                    <CustomIconButton icon={WorkIcon} color="secondary" className="modal-btn-update" toolTipTitle="Job Details" />
                                </Link>
                            )}
                        </>
                    ) : (
                        checkhaveAccess?.CanViewJobs && (
                            <Link
                                to={{
                                    pathname: `/controller/jobTabScreen/${j?.scheduleJobID}/${j?.scheduleVehicleID}`,
                                    state: pos
                                }}
                                style={{ marginLeft: '5px' }}
                            >
                                <CustomIconButton color="secondary" icon={WorkIcon} className="modal-btn-update" toolTipTitle="Job Details" />
                            </Link>
                        )
                    ))}
                {!isServiceAgent && j?.scheduleBookingStatus == 'Booked and Confirmed' && (
                    <>
                        &nbsp;
                        <CustomIconButton
                            icon={CheckCircleIcon}
                            className="modal-btn-update btn_space"
                            color="primary"
                            onClick={() => {
                                checkButton(j, 'complete');
                            }}
                            toolTipTitle="Complete Schedule"
                        />
                    </>
                )}
                {isServiceAgent && checkhaveAccess?.CanViewJobs && (
                    <Link
                        to={{
                            pathname: `/controller/jobTabScreen/${j?.scheduleJobID}/${j?.scheduleVehicleID}`,
                            state: pos
                        }}
                        style={{ marginLeft: '5px' }}
                    >
                        <CustomIconButton icon={WorkIcon} className="modal-btn-update" toolTipTitle="Job Details" />
                    </Link>
                )}
            </span>
        );
    };

    let regNumber = props.fromTab ? '' : `(${state.selectedRowData?.vehicleRegNumber})`;

    const downloadPdf = async () => {
        let res = await getSchedulePlannerData(
            `${state.selectedYear}-01-01`,
            `${state.selectedYear}-12-31`,
            state.scheduleCustomerIDValue?.id || '',
            state.scheduleDepotsIDValue?.id || '',
            state.scheduleServiceAgentIDValue?.id || ''
        );
        if (res) {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.getElementById('tempDownloadPDFLink') || document.createElement('a');
            link.id = 'tempDownloadPDFLink';
            link.href = url;
            link.setAttribute('download', `SchedulePlanner${moment().format('DD-MMM-YYYY HH:mm')}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            return true;
        }
    };

    const singleSelectChange = (nm) => (ev, val) => {
        setState((st) => {
            let newSt = { ...st, [nm]: val };
            newSt.isDisabled = newSt.scheduleCustomerIDValue || false;
            return newSt;
        });
    };

    return (
        <div className="internal-dashboard">
            {state.showLoader ? (
                <div
                    style={{
                        height: 'calc(100vh - 250px)',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center'
                    }}
                >
                    <CircularProgress style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                </div>
            ) : (
                <Grid container spacing={1} style={{ height: `calc(100vh - ${props.fromTab ? '188px' : '85px'})` }} justify="space-evenly">
                    <Grid container item xs={12} sm={12} lg={2} alignContent="flex-start">
                        {!props.fromTab && (
                            <>
                                {/* <Grid item style={{ marginBottom: 8 }} xs={12} sm={12} className="mandatory-fields">
                                    <InputLabel required shrink>
                                        Select Customer
                                    </InputLabel>
                                    <SelectBox name="customerID" value={state.customerID} onChange={inputChange} List={state.customerList} />
                                </Grid> */}
                                <Grid item xs={12} sm={12} className="mandatory-fields">
                                    <InputLabel required shrink>
                                        Select Customer
                                    </InputLabel>
                                    <SingleSelect
                                        options={state.customerList}
                                        value={state.scheduleCustomerIDValue}
                                        onChange={singleSelectChange('scheduleCustomerIDValue')}
                                    />
                                </Grid>
                                {/* <Grid style={{ marginBottom: 8 }} item xs={12} sm={12}>
                                    <InputLabel shrink>Select Depot</InputLabel>
                                    <SelectBox name="depotsID" value={state.depotsID} onChange={inputChange} List={state.depotWithCustList} />
                                </Grid> */}
                                <Grid item xs={12} sm={12}>
                                    <InputLabel shrink>Select Depots</InputLabel>
                                    <SingleSelect
                                        options={state.depotWithCustList}
                                        value={state.scheduleDepotsIDValue}
                                        onChange={singleSelectChange('scheduleDepotsIDValue')}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <InputLabel shrink>Service Agent</InputLabel>
                                    <SingleSelect
                                        options={state.serviceAgentsList}
                                        value={state.scheduleServiceAgentIDValue}
                                        onChange={singleSelectChange('scheduleServiceAgentIDValue')}
                                    />
                                    <FormHelperText error></FormHelperText>
                                </Grid>
                                <Grid item xs={12} style={{ marginTop: '8px', marginBottom: '16px' }}>
                                    <SecondaryButton onClick={downloadPdf} disabled={!state.isDisabled}>
                                        Get Schedule planner
                                    </SecondaryButton>
                                </Grid>
                            </>
                        )}
                        <Grid
                            container
                            item
                            xs={12}
                            style={{ height: '45px', backgroundColor: '#26a0b5', border: ' 1px solid black', color: 'white' }}
                            alignItems="center"
                        >
                            <Grid item xs={6} style={{ padding: '0px 0px 0px 9px' }}>
                                Schedules
                            </Grid>
                            <Grid item xs={6} style={{ padding: '0px 9px 0px 0px' }}>
                                <SelectBox
                                    name="navigationScreenID"
                                    value={state.selectedYear || ' '}
                                    onChange={handleInputChange2}
                                    List={state.uniqueYear}
                                    className={classes.yearInput}
                                />
                            </Grid>
                        </Grid>
                        {state.scheduleEventNames.map((q) => {
                            return (
                                <Grid container item xs={12} sm={6} md={4} lg={12} style={{ height: '50px', border: ' 1px solid black' }} alignItems="center">
                                    <Grid item xs={2} style={{ textAlign: 'center' }}>
                                        ⓘ
                                    </Grid>
                                    <Grid item xs={10}>
                                        {q.name}
                                    </Grid>
                                </Grid>
                            );
                        })}
                    </Grid>
                    {!state.gridShow ? (
                        <Grid container item xs={12} sm={12} lg={10} spacing={2}>
                            {state.sortedScheduleEvent.map((p) => {
                                return (
                                    <Grid item xs={12} sm={6} lg={4}>
                                        <Card className={classes.root}>
                                            <CardHeader
                                                action={
                                                    <IconButton style={{ padding: '0px', color: 'white' }} disabled={p.events.length !== 0 ? false : true}>
                                                        <AspectRatioIcon onClick={() => handleClick(p.events, p.month)} />
                                                    </IconButton>
                                                }
                                                titleTypographyProps={{ variant: 'body1' }}
                                                title={`${p.month} ${state.selectedYear}`}
                                                style={{ height: '10px', backgroundColor: '#26a0b5', border: ' 1px solid black', color: 'white' }}
                                            ></CardHeader>
                                            <CardContent style={{ padding: '0px 6px 0px 6px' }} className={classes.cardContent}>
                                                {p.events.length !== 0 ? (
                                                    p.events.map((j) => {
                                                        let isCompleteDate = j.scheduleBookingStatus === 'Completed' ? j.scheduleCompletionDate : j.bookingDate;

                                                        return (
                                                            <Grid
                                                                container
                                                                item
                                                                xs={12}
                                                                style={{
                                                                    padding: '10px',
                                                                    borderBottom: ' 1px solid black'
                                                                }}
                                                                alignItems="center"
                                                            >
                                                                <Grid item xs={1}>
                                                                    ⓘ
                                                                </Grid>

                                                                <Grid container item xs={11} alignItems="center">
                                                                    <Grid item xs={12}>
                                                                        <Typography variant="body2">
                                                                            {`${moment(j.scheduleDateDue).format('dddd')} ${moment(j.scheduleDateDue).format(
                                                                                'DD MMM YYYY'
                                                                            )} (${isCompleteDate ? moment(isCompleteDate).format('dddd') : ''} ${
                                                                                isCompleteDate ? moment(isCompleteDate).format('DD MMM YYYY') : '--:--'
                                                                            })`}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                            {props.fromTab ? null : (
                                                                                <>
                                                                                    {dueDateFormatter(j)}
                                                                                    &nbsp;({j.vehicleRegNumber})
                                                                                </>
                                                                            )}
                                                                            <span style={{ marginLeft: props.fromTab ? '' : '8px' }}> {renderEdit(j)}</span>
                                                                        </div>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        );
                                                    })
                                                ) : (
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            justifyContent: 'center'
                                                        }}
                                                    >
                                                        <div style={{ marginLeft: 'auto', marginRight: 'auto', color: '#D0D0D0', cursor: 'default' }}>
                                                            There are no schedules
                                                        </div>
                                                    </div>
                                                )}
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    ) : (
                        <Grid container item xs={12} md={12} lg={10} alignContent="flex-start" className="grid-style gray-grid">
                            <Grid
                                container
                                item
                                xs={12}
                                style={{ height: '45px', backgroundColor: '#26a0b5', border: ' 1px solid black', color: 'white', padding: '9px 0px 0px 9px' }}
                                justify="space-between"
                            >
                                <Grid item>{`${state.selectedMonth} ${state.selectedYear}`}</Grid>
                                <Grid item>
                                    <CloseIcon onClick={closeGrid} />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} style={{}}>
                                {state.RowData.map((j) => {
                                    let isCompleteDate = j.scheduleBookingStatus === 'Completed' ? j.scheduleCompletionDate : j.bookingDate;
                                    return (
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            style={{
                                                paddingTop: '10px',
                                                borderBottom: ' 1px solid black'
                                            }}
                                            alignItems="center"
                                            spacing={1}
                                        >
                                            <Grid item>ⓘ</Grid>

                                            <Grid container item xs={11} alignItems="center">
                                                <Grid item xs={12}>
                                                    <Typography variant="body2">
                                                        {`${moment(j.scheduleDateDue).format('dddd')} ${moment(j.scheduleDateDue).format('DD MMM YYYY')} (${
                                                            isCompleteDate ? moment(isCompleteDate).format('dddd') : ''
                                                        } ${isCompleteDate ? moment(isCompleteDate).format('DD MMM YYYY') : '--:--'})`}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <Typography variant="body2">{dueDateFormatter(j)}</Typography>
                                                    <Typography variant="body2">{j.agentName}</Typography>
                                                    <Typography variant="body2">{j.vehicleRegNumber}</Typography>
                                                </Grid>
                                                <Grid item xs={7} style={{ position: 'relative' }}>
                                                    {renderEdit(j)}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            )}

            {state.open ? (
                <DialogComp
                    open={state.open}
                    onClose={() => {
                        handleClose(false);
                    }}
                    title={state.isAdd ? 'Add Schedule' : `Edit Schedule ${regNumber}`}
                    maxWidth="sm"
                    name="addEdit"
                >
                    {state.isAdd ? (
                        <DialogBox
                            data={state}
                            isAdd={state.isAdd}
                            scheduleId={state.selectedRowData.scheduleID}
                            serviceAgentid={props.serviceAgentid}
                            onClose={() => {
                                handleClose(true);
                            }}
                        />
                    ) : (
                        <EditSchedule
                            data={state}
                            isAdd={state.isAdd}
                            vehicleId={state.selectedRowData.scheduleVehicleID}
                            onClose={() => {
                                handleClose(true);
                            }}
                        />
                    )}
                </DialogComp>
            ) : null}
            {openDialog.notes ? (
                <DialogComp title={`Schedule Notes ${regNumber}`} maxWidth="md" onClose={closeTheDialog}>
                    <ScheduleNotes vehicleId={state.scheduleVehicleID} scheduleId={state.selectedRowData.scheduleID} onClose={closeTheDialog} />
                </DialogComp>
            ) : null}
            {openDialog.status ? (
                <DialogComp title={`Customer Booking Request ${regNumber}`} maxWidth="md" onClose={closeTheDialog}>
                    <ScheduleBookingStatus vehicleId={state.scheduleVehicleID} scheduleId={state.selectedRowData.scheduleID} onClose={closeTheDialog} />
                </DialogComp>
            ) : null}
            {openDialog.section ? (
                <DialogComp title={`Booking Section ${regNumber}`} maxWidth="md" onClose={closeTheDialog}>
                    <ScheduleBookingSection vehicleId={state.scheduleVehicleID} scheduleId={state.selectedRowData.scheduleID} onClose={closeTheDialog} />
                </DialogComp>
            ) : null}
            {openDialog.complete ? (
                <DialogComp title={`Complete Schedule ${regNumber}`} maxWidth="sm" onClose={closeTheDialog}>
                    <CompleteScheduleModal rowData={state.selectedRowData} onClose={closeTheDialog} />
                </DialogComp>
            ) : null}
        </div>
    );
};

export default ScheduleDashboard;
