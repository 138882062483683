import { createContext, useCallback, useEffect, useState } from 'react';
import { useToast } from '../../../../Core/Hooks/useToast';
import { addCustomeres, updateCustomer, getCustomerById, getCustomerDropDown } from '../../../../Core/Services/InternalUser/ControllerService/customerService';
import { emailValidation, validatePostCode, validatePostCodeWothCountryCode } from '../../../../Core/Basic/StaticService';
import { useHistory } from 'react-router-dom';
import { isNull } from 'lodash';

export const CustomerScreenContext = createContext();

const ISODays = [
    { id: 1, name: 'Monday' },
    { id: 2, name: 'Tuesday' },
    { id: 3, name: 'Wednesday' },
    { id: 4, name: 'Thursday' },
    { id: 5, name: 'Friday' },
    { id: 6, name: 'Saturday' }
];

const countryList = [
    { id: 'GB', name: 'GB' },
    { id: 'IE', name: 'IE' }
];

export const CustomerProvider = (props) => {
    const { showToastSuccess, showToastError } = useToast();

    let history = useHistory();

    const [state, setState] = useState({
        errors: {},
        autoApproveDays: null,
        autoCreateMileageBasedService: false,
        customerAccountManagerID: '',
        customerActive: false,
        customerAddress1: '',
        customerAddress2: '',
        customerAllowedDayRange: null,
        customerAltCompanyName: '',
        customerAutoApprovalLimit: '',
        customerBreakdownEmail: '',
        customerCity: '',
        customerCompanyName: '',
        customerCountryCode: '',
        customerCounty: '',
        customerInspectionEventID: null,
        customerDateCreated: '',
        customerDateDeleted: null,
        customerDateUpdated: '',
        customerDefectEmail: '',
        customerDisplayDefectsMonths: null,
        customerERStartDate: '',
        customerEarnedRecognition: false,
        customerEmail: '',
        customerFailedDailyCheckEmail: '',
        customerFinanceDirectorEmail: '',
        customerFinanceDirectorName: '',
        customerFinanceDirectorPhone: '',
        customerHandlingDefects: false,
        customerID: null,
        customerISOWeekStartDay: '',
        customerJobApprovalRequiredEmail: '',
        customerOperationsManagerEmail: '',
        customerOperationsManagerID: '',
        customerOperationsManagerName: '',
        customerOperationsManagerPhone: '',
        customerPostCode: '',
        customerQueryResponseReceivedEmail: '',
        customerScheduleCompletionEmail: '',
        customerScheduleConfirmationEmail: '',
        customerSendBreakdownEmail: false,
        customerSendDefectEmail: false,
        customerSendEmailNotifications: false,
        customerSendFailedDailyCheck: false,
        customerSendJobApprovalRequired: false,
        customerSendQueryResponseReceived: false,
        customerSendScheduleCompletion: false,
        customerSendVOREmail: false,
        customerSendVehicleNotification: false,
        customerShortName: '',
        customerStatusID: '',
        customerTelephone: '',
        customerVOREmail: '',
        customerVORHours: null,
        customerVORMobileNumbers: null,
        customerVehicleNotificationEmail: '',
        customerWebSite: '',
        handlingOLicense: false,
        oLicenseWarningFollowupInterval: null,
        oLicenseWarningInterval: null,
        customerRechargeMarkup: '',
        customerInspectionSheetHeadingBackgroundColour: 'red',
        customerInspectionSheetHeadingTextColour: 'red',
        scheduleCompletionReminder: false,
        sendScheduleConfirmation: false,
        isLoader: true,

        customerLogo: '',
        customerPaymentDays: null,
        customerAccountCode: null,
        customerOverdueReminderEmail: '',
        customerSendOverdueReminderEmail: false,
        customerTableHeaderBackgroundColour: '',
        customerTableHeaderColour: '',
        customerOddRowBackgroundColour: '',
        customerOddRowColour: '',
        customerEvenRowBackgroundColour: '',
        customerEvenRowColour: '',
        customerLabourRate: null,
        customerOutOfHoursLabourRate: null,

        customerVORTimed: false,
        customerVORHours: null

        // customerSendInvoiceToDepot: false,
        // customerEmailInvoices: false,
        // customerUseAddressAsInvoiceAddress: false
    });

    const [btnLoader, setBtnLoader] = useState(false);

    const [optionsState, setOptionState] = useState({
        //  currencyList: [],
        aCManagerList: [],
        tradingStatusList: [],
        operationsManagerList: [],
        customerStatusList: [],
        scheduleEventsList: [],
        customerPaymentDaysList: [
            { name: '30 Days', id: 30 },
            { name: '45 Days', id: 45 },
            { name: '60 Days', id: 60 },
            { name: '90 Days', id: 90 }
        ]
    });

    const [tempOptions, setTempOptions] = useState({
        customerAccountManagerName: null,
        ISODaysValue: null,
        // customerCurrencyIDValue: null,
        customerStatusIDValue: null,
        customerInspectionEventIDValue: null,
        customerPaymentDaysValue: null
    });

    // const [invoiceFields, setInvoiceFields] = useState({
    //     customerSendInvoiceToDepot: false,
    //     customerUseAddressAsInvoiceAddress: false,
    //     customerInvoiceAddress1: null,
    //     customerInvoiceAddress2: null,
    //     customerInvoiceAddressCity: null,
    //     customerInvoiceAddressCounty: null,
    //     customerInvoiceAddressPostCode: null,
    //     customerInvoiceAddressCountryCode: null,
    //     customerInvoiceEmailAddress: null,
    //     customerEmailInvoices: false
    // });

    useEffect(async () => {
        pullCustomerdata();
    }, []);

    const pullCustomerdata = async () => {
        let list = await getCustomerDropDown();
        setOptionState((st) => ({
            ...st,
            aCManagerList: list.data.accountManager,
            tradingStatusList: list.data.tradingStatus,
            operationsManagerList: list.data.operationManager,
            customerStatusList: list.data.customerStatus,
            scheduleEventsList: list.data.scheduleEvents.map((x) => {
                return { id: x.scheduleEventID, name: x.scheduleEventName };
            })
        }));
        if (props.customerID) {
            let res = await getCustomerById(props.customerID);

            const data = res.data?.list[0];

            console.log(data)

            setInvoiceFields((st) => ({
                ...st,
                customerSendInvoiceToDepot: data?.customerSendInvoiceToDepot,
                customerUseAddressAsInvoiceAddress: data?.customerUseAddressAsInvoiceAddress,
                customerInvoiceAddress1: data?.customerInvoiceAddress1,
                customerInvoiceAddress2: data?.customerInvoiceAddress2,
                customerInvoiceAddressCity: data?.customerInvoiceAddressCity,
                customerInvoiceAddressCounty: data?.customerInvoiceAddressCounty,
                customerInvoiceAddressPostCode: data?.customerInvoiceAddressPostCode,
                customerInvoiceAddressCountryCode: data?.customerInvoiceAddressCountryCode,
                customerInvoiceEmailAddress: data?.customerInvoiceEmailAddress,
                customerEmailInvoices: data?.customerEmailInvoices
            }));
            setState((st) => ({
                ...st,
                customerID: data.customerID,
                customerShortName: data.customerShortName,
                customerCompanyName: data.customerCompanyName,
                customerAddress1: data.customerAddress1,
                customerAddress2: data.customerAddress2,
                customerCity: data.customerCity,
                customerCounty: data.customerCounty,
                customerPostCode: data.customerPostCode,
                customerCountryCode: data.customerCountryCode,
                customerTelephone: data.customerTelephone,
                customerEmail: data.customerEmail,
                customerWebSite: data.customerWebSite,
                customerActive: data.customerActive,
                customerStatusID: data.customerStatusID,
                customerAccountManagerID: data.customerAccountManagerID,
                customerOperationsManagerID: data.customerOperationsManagerID,
                customerOperationsManagerName: data.customerOperationsManagerName,
                customerOperationsManagerPhone: data.customerOperationsManagerPhone,
                customerOperationsManagerEmail: data.customerOperationsManagerEmail,
                customerFinanceDirectorName: data.customerFinanceDirectorName,
                customerFinanceDirectorPhone: data.customerFinanceDirectorPhone,
                customerFinanceDirectorEmail: data.customerFinanceDirectorEmail,
                //   customerCurrencyID: data.customerCurrencyID,
                customerInspectionEventID: data.customerInspectionEventID,
                customerISOWeekStartDay: data.customerISOWeekStartDay,
                customerSendEmailNotifications: data.customerSendEmailNotifications || false,
                customerSendVehicleNotification: data.customerSendVehicleNotification || false,
                customerVehicleNotificationEmail: data.customerVehicleNotificationEmail,
                customerSendDefectEmail: data.customerSendDefectEmail || false,
                customerDefectEmail: data.customerDefectEmail,
                customerSendBreakdownEmail: data.customerSendBreakdownEmail || false,
                customerBreakdownEmail: data.customerBreakdownEmail,
                customerSendFailedDailyCheck: data.customerSendFailedDailyCheck || false,
                customerFailedDailyCheckEmail: data.customerFailedDailyCheckEmail,
                ccheduleCompletionReminder: data.ccheduleCompletionReminder,
                sendScheduleConfirmation: data.sendScheduleConfirmation,
                customerScheduleConfirmationEmail: data.customerScheduleConfirmationEmail,
                customerSendScheduleCompletion: data.customerSendScheduleCompletion || false,
                customerScheduleCompletionEmail: data.customerScheduleCompletionEmail,
                customerSendVOREmail: data.customerSendVOREmail || false,
                customerVOREmail: data.customerVOREmail,
                customerSendQueryResponseReceived: data.customerSendQueryResponseReceived || false,
                customerQueryResponseReceivedEmail: data.customerQueryResponseReceivedEmail,
                customerSendJobApprovalRequired: data.customerSendJobApprovalRequired || false,
                customerJobApprovalRequiredEmail: data.customerJobApprovalRequiredEmail,
                customerVORMobileNumbers: data.customerVORMobileNumbers,
                customerVORHours: data.customerVORHours,
                oLicenseWarningInterval: data.oLicenseWarningInterval,
                oLicenseWarningFollowupInterval: data.oLicenseWarningFollowupInterval,
                customerAutoApprovalLimit: data.customerAutoApprovalLimit,
                autoApproveDays: data.autoApproveDays,
                customerRechargeMarkup: data.customerRechargeMarkup,
                customerInspectionSheetHeadingBackgroundColour: data.customerInspectionSheetHeadingBackgroundColour,
                customerInspectionSheetHeadingTextColour: data.customerInspectionSheetHeadingTextColour,
                customerAllowedDayRange: data.customerAllowedDayRange,
                customerDisplayDefectsMonths: data.customerDisplayDefectsMonths,
                customerHandlingDefects: data.customerHandlingDefects || false,
                handlingOLicense: data.handlingOLicense || false,
                autoCreateMileageBasedService: data.autoCreateMileageBasedService,
                customerEarnedRecognition: data.customerEarnedRecognition || false,
                customerERStartDate: data.customerERStartDate,
                customerLogo: data.customerLogo,
                customerLicenceCheckAccountCode: data.customerLicenceCheckAccountCode,
                credits: res.data.credits,
                isLoader: false,
                customerPaymentDays: data.customerPaymentDays,
                customerAccountCode: data.customerAccountCode,
                customerOverdueReminderEmail: data.customerOverdueReminderEmail,
                customerSendOverdueReminderEmail: data.customerSendOverdueReminderEmail,
                customerTableHeaderBackgroundColour: data.customerTableHeaderBackgroundColour,
                customerTableHeaderColour: data.customerTableHeaderColour,
                customerOddRowBackgroundColour: data.customerOddRowBackgroundColour,
                customerOddRowColour: data.customerOddRowColour,
                customerEvenRowBackgroundColour: data.customerEvenRowBackgroundColour,
                customerEvenRowColour: data.customerEvenRowColour,
                customerLabourRate: data.customerLabourRate,
                customerOutOfHoursLabourRate: data.customerOutOfHoursLabourRate,
                customerVORTimed: data.customerVORTimed,
                customerVORHours: data.customerVORHours
            }));
        }
    };

    const invoiceFieldsChange = (e) => {
        const { name, value, type, checked } = e.target;

        if (value === 'true' && type.toLowerCase() === 'radio') {
            setInvoiceFields((st) => ({ ...st, ...invoiceInfo, [name]: true }));
        } else if (value === 'false' && type?.toLowerCase() === 'radio') {
            setInvoiceFields((st) => ({ ...st, [name]: false }));
        } else {
            setInvoiceFields((st) => ({ ...st, [name]: value }));
        }
    };

    let invoiceInfo = {
        // customerUseAddressAsInvoiceAddress: false,
        customerInvoiceAddress1: '',
        customerInvoiceAddress2: '',
        customerInvoiceAddressCity: '',
        customerInvoiceAddressCounty: '',
        customerInvoiceAddressPostCode: '',
        customerInvoiceAddressCountryCode: 'GB',
        customerInvoiceEmailAddress: '',
        customerEmailInvoices: false
        // customerSendInvoiceToDepot: false
    };

    const [invoiceFields, setInvoiceFields] = useState({
        ...invoiceInfo,
        customerUseAddressAsInvoiceAddress: false,
        customerSendInvoiceToDepot: false,

        errors: {}
    });

    const invoiceCheckBox = (e) => {
        const { name, checked } = e.target;

        setInvoiceFields((st) => ({ ...st, [name]: checked }));
    };

    const setVal = (s) => {
        setState((st) => ({
            ...st,
            customerAddress1: s.addressLine1,
            customerAddress2: s.addressLine2,
            customerCity: s.town,
            customerCounty: s.county,
            customerPostCode: s.postCode
        }));
    };

    const activeLoader = (params) => {
        setState((st) => ({
            ...st,
            isLoader: params
        }));
    };
    const closeLoader = () => {
        setState((st) => ({
            ...st,
            isLoader: false
        }));
    };

    const setInvoiceVal = (s) => {
        setInvoiceFields((st) => ({
            ...st,
            customerInvoiceAddress1: s.addressLine1,
            customerInvoiceAddress2: s.addressLine2,
            customerInvoiceAddressCity: s.town,
            customerInvoiceAddressCounty: s.county,
            customerInvoiceAddressPostCode: s.postCode
        }));
    };

    useEffect(() => {
        let customerAccountManagerName = null;
        let ISODaysValue = null;
        let customerStatusIDValue = null;
        let customerInspectionEventIDValue = null;
        let customerPaymentDaysValue = null;

        if (state.customerAccountManagerID) {
            customerAccountManagerName = optionsState.aCManagerList.find(({ id }) => id === state.customerAccountManagerID);
        }
        if (state.customerISOWeekStartDay) {
            ISODaysValue = ISODays.find(({ id }) => id === state.customerISOWeekStartDay);
        }
        if (state.customerStatusID) {
            customerStatusIDValue = optionsState.customerStatusList.find(({ id }) => id === state.customerStatusID);
        }
        if (state.customerInspectionEventID) {
            customerInspectionEventIDValue = optionsState.scheduleEventsList.find(({ id }) => id === state.customerInspectionEventID);
        }
        if (state.customerPaymentDays) {
            customerPaymentDaysValue = optionsState.customerPaymentDaysList.find(({ id }) => id === state.customerPaymentDays);
        }
        setTempOptions((st) => ({
            ...st,
            customerAccountManagerName: customerAccountManagerName,
            ISODaysValue: ISODaysValue,
            //  customerCurrencyIDValue: customerCurrencyIDValue,
            customerStatusIDValue: customerStatusIDValue,
            customerInspectionEventIDValue: customerInspectionEventIDValue,
            customerPaymentDaysValue: customerPaymentDaysValue
        }));
    }, [
        state.customerPaymentDays,
        state.customerAccountManagerID,
        state.customerISOWeekStartDay,
        //  state.customerCurrencyID,
        state.customerStatusID,
        state.customerInspectionEventID
    ]);

    const inputChange = useCallback((e) => {
        const { name, value, type } = e.target;
        if (value === 'true' && type.toLowerCase() === 'radio') {
            setState((st) => ({ ...st, [name]: true }));
        } else if (value === 'false' && type.toLowerCase() === 'radio') {
            setState((st) => ({ ...st, [name]: false }));
        } else {
            setState((st) => ({ ...st, [name]: value }));
        }
    }, []);

    const resetInput = (name) => {
        setState((st) => ({ ...st, [name]: '' }));
    };

    const singleSelectChange = (nm) => (ev, val) => {
        setTempOptions((st) => ({ ...st, [nm]: val }));
    };

    const clearImage = () => {
        if (state.customerLogo) {
            setState((st) => ({ ...st, customerLogo: null }));
        }
    };

    const onColorChange = (updatedColor, name) => {
        if (updatedColor) {
            setState((state) => ({
                ...state,
                [name]: updatedColor?.hex
            }));
        }
    };

    let imagehandler = (event) => {
        let reader = new FileReader();
        reader.onload = (e) => {
            const { result } = e.target;
            setState((st) => ({ ...st, customerLogo: result }));
        };
        reader.readAsDataURL(event.target.files[0]);
    };

    const validations = () => {
        const {
            customerShortName,
            customerCompanyName,
            customerStatusID,
            customerAddress1,
            customerPostCode,
            customerAutoApprovalLimit,
            //   autoApproveQueryDays,
            customerRechargeMarkup,
            customerTelephone,
            customerOperationsManagerPhone,
            customerFinanceDirectorPhone,
            customerEmail,
            customerOperationsManagerEmail,
            customerFinanceDirectorEmail,
            customerDefectEmail,
            customerBreakdownEmail,
            customerVOREmail,
            customerFailedDailyCheckEmail,
            customerScheduleConfirmationEmail,
            customerScheduleCompletionEmail,
            customerQueryResponseReceivedEmail,
            customerJobApprovalRequiredEmail,
            customerSendDefectEmail,
            customerSendBreakdownEmail,
            customerSendVOREmail,
            customerSendFailedDailyCheck,
            customerSendScheduleCompletion,
            sendScheduleConfirmation,
            customerSendQueryResponseReceived,
            customerSendJobApprovalRequired,
            countryCode,
            customerCountryCode,
            customerSendOverdueReminderEmail,
            customerOverdueReminderEmail
        } = state;
        const { customerStatusIDValue } = tempOptions;
        let formIsValid = true;
        let errors = {};

        if (!/[^\s]/.test(customerShortName)) {
            errors.customerShortName = 'Customer Short Name is required';
            formIsValid = false;
        }
        if (!/[^\s]/.test(customerCompanyName)) {
            errors.customerCompanyName = 'Customer Name is required';
            formIsValid = false;
        }
        if (isNull(customerStatusIDValue)) {
            errors.customerStatusID = 'Status is required';
            formIsValid = false;
        }
        if (!/[^\s]/.test(customerAddress1)) {
            errors.customerAddress1 = 'Address is required';
            formIsValid = false;
        }
        // if (!validatePostCode(customerPostCode)) {
        //     errors.customerPostCode = `Please fill valid post code, "Ex: BB11AA"`;
        //
        if (!customerCountryCode) {
            errors.countryCode = 'Country code is required.';
            formIsValid = false;
        }
        if (state.customerCountryCode === 'GB') {
            if (customerCountryCode && !validatePostCodeWothCountryCode(customerCountryCode, customerPostCode)) {
                errors.customerPostCode = `Please fill valid post code`;
                formIsValid = false;
            }
        }

        if (customerAutoApprovalLimit < 0) {
            errors.customerAutoApprovalLimit = 'Limit can not be negative';
            formIsValid = false;
        }
        // if (autoApproveQueryDays < 0) {
        //     errors.autoApproveQueryDays = 'Days can not be negative';
        //     formIsValid = false;
        // }
        // if (overdueQueriesDays < 0) {
        //     errors.overdueQueriesDays = 'Days can not be negative';
        //     formIsValid = false;
        // }

        let phoneReg = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
        if (!phoneReg.test(customerTelephone) && /[^\s]/.test(customerTelephone) && !isNull(customerTelephone)) {
            errors.customerTelephone = 'Invalid phone number';
            formIsValid = false;
        }
        if (!phoneReg.test(customerOperationsManagerPhone) && /[^\s]/.test(customerOperationsManagerPhone) && !isNull(customerOperationsManagerPhone)) {
            errors.customerOperationsManagerPhone = 'Invalid phone number';
            formIsValid = false;
        }
        if (!phoneReg.test(customerFinanceDirectorPhone) && /[^\s]/.test(customerFinanceDirectorPhone) && !isNull(customerFinanceDirectorPhone)) {
            errors.customerFinanceDirectorPhone = 'Invalid phone number';
            formIsValid = false;
        }

        if (
            customerSendDefectEmail ||
            customerSendBreakdownEmail ||
            customerSendVOREmail ||
            customerSendFailedDailyCheck ||
            customerSendScheduleCompletion ||
            sendScheduleConfirmation ||
            customerSendQueryResponseReceived ||
            customerSendJobApprovalRequired ||
            customerSendOverdueReminderEmail
        ) {
            if (customerSendDefectEmail && (!/[^\s]/.test(customerDefectEmail) || isNull(customerDefectEmail))) {
                errors.customerDefectEmail = 'Email can not be empty for checked flag';
                formIsValid = false;
            }
            if (customerSendBreakdownEmail && (!/[^\s]/.test(customerBreakdownEmail) || isNull(customerBreakdownEmail))) {
                errors.customerBreakdownEmail = 'Email can not be empty for checked flag';
                formIsValid = false;
            }
            if (customerSendVOREmail && (!/[^\s]/.test(customerVOREmail) || isNull(customerVOREmail))) {
                errors.customerVOREmail = 'Email can not be empty for checked flag';
                formIsValid = false;
            }
            if (customerSendFailedDailyCheck && (!/[^\s]/.test(customerFailedDailyCheckEmail) || isNull(customerFailedDailyCheckEmail))) {
                errors.customerFailedDailyCheckEmail = 'Email can not be empty for checked flag';
                formIsValid = false;
            }
            if (sendScheduleConfirmation && (!/[^\s]/.test(customerScheduleConfirmationEmail) || isNull(customerScheduleConfirmationEmail))) {
                errors.customerScheduleConfirmationEmail = 'Email can not be empty for checked flag';
                formIsValid = false;
            }
            if (customerSendScheduleCompletion && (!/[^\s]/.test(customerScheduleCompletionEmail) || isNull(customerScheduleCompletionEmail))) {
                errors.customerScheduleCompletionEmail = 'Email can not be empty for checked flag';
                formIsValid = false;
            }
            if (customerSendQueryResponseReceived && (!/[^\s]/.test(customerQueryResponseReceivedEmail) || isNull(customerQueryResponseReceivedEmail))) {
                errors.customerQueryResponseReceivedEmail = 'Email can not be empty for checked flag';
                formIsValid = false;
            }
            if (customerSendJobApprovalRequired && (!/[^\s]/.test(customerJobApprovalRequiredEmail) || isNull(customerJobApprovalRequiredEmail))) {
                errors.customerJobApprovalRequiredEmail = 'Email can not be empty for checked flag';
                formIsValid = false;
            }
            if (customerSendOverdueReminderEmail && (!/[^\s]/.test(customerOverdueReminderEmail) || isNull(customerOverdueReminderEmail))) {
                errors.customerOverdueReminderEmail = 'Email can not be empty for checked flag';
                formIsValid = false;
            }
        }

        let emailReg = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/;

        if (!emailValidation(customerEmail) && /[^\s]/.test(customerEmail) && !isNull(customerEmail)) {
            errors.customerEmail = 'Invalid Email Id';
            formIsValid = false;
        }
        if (!emailValidation(customerOperationsManagerEmail) && /[^\s]/.test(customerOperationsManagerEmail) && !isNull(customerOperationsManagerEmail)) {
            errors.customerOperationsManagerEmail = 'Invalid Email Id';
            formIsValid = false;
        }

        if (!emailValidation(customerFinanceDirectorEmail) && /[^\s]/.test(customerFinanceDirectorEmail) && !isNull(customerFinanceDirectorEmail)) {
            errors.customerFinanceDirectorEmail = 'Invalid Email Id';
            formIsValid = false;
        }
        if (!emailValidation(customerDefectEmail) && /[^\s]/.test(customerDefectEmail) && !isNull(customerDefectEmail)) {
            errors.customerDefectEmail = 'Invalid Email Id';
            formIsValid = false;
        }
        if (!emailValidation(customerBreakdownEmail) && /[^\s]/.test(customerBreakdownEmail) && !isNull(customerBreakdownEmail)) {
            errors.customerBreakdownEmail = 'Invalid Email Id';
            formIsValid = false;
        }
        if (!emailValidation(customerVOREmail) && /[^\s]/.test(customerVOREmail) && !isNull(customerVOREmail)) {
            errors.customerVOREmail = 'Invalid Email Id';
            formIsValid = false;
        }
        if (!emailValidation(customerFailedDailyCheckEmail) && /[^\s]/.test(customerFailedDailyCheckEmail) && !isNull(customerFailedDailyCheckEmail)) {
            errors.customerFailedDailyCheckEmail = 'Invalid Email Id';
            formIsValid = false;
        }
        if (
            !emailValidation(customerScheduleConfirmationEmail) &&
            /[^\s]/.test(customerScheduleConfirmationEmail) &&
            !isNull(customerScheduleConfirmationEmail)
        ) {
            errors.customerScheduleConfirmationEmail = 'Invalid Email Id';
            formIsValid = false;
        }
        if (!emailValidation(customerScheduleCompletionEmail) && /[^\s]/.test(customerScheduleCompletionEmail) && !isNull(customerScheduleCompletionEmail)) {
            errors.customerScheduleCompletionEmail = 'Invalid Email Id';
            formIsValid = false;
        }
        if (
            !emailValidation(customerQueryResponseReceivedEmail) &&
            /[^\s]/.test(customerQueryResponseReceivedEmail) &&
            !isNull(customerQueryResponseReceivedEmail)
        ) {
            errors.customerQueryResponseReceivedEmail = 'Invalid Email Id';
            formIsValid = false;
        }
        if (!emailValidation(customerJobApprovalRequiredEmail) && /[^\s]/.test(customerJobApprovalRequiredEmail) && !isNull(customerJobApprovalRequiredEmail)) {
            errors.customerJobApprovalRequiredEmail = 'Invalid Email Id';
            formIsValid = false;
        }

        setState((st) => ({ ...st, errors: errors }));
        return formIsValid;
    };

    const handleSubmit = async () => {
        if (validations()) {
            let res;
            setBtnLoader(true);
            if (props.customerID) {
                res = await updateCustomer(state, tempOptions, invoiceFields);
            } else {
                res = await addCustomeres(state, tempOptions, invoiceFields);
            }
            if (res.success) {
                showToastSuccess(res.message);
                props.onFormSubmit(true);
                history.push(`/controller/customer`);
            } else {
                showToastError(res.message);
            }
            setBtnLoader(false);
        }
    };
    const handleCheckbox = (e) => {
        const { name, checked } = e.target;
        setState((state) => {
            const nst = { ...state, [name]: checked };
            return nst;
        });
    };

    return (
        <CustomerScreenContext.Provider
            value={{
                state,
                handleSubmit,
                inputChange,
                handleCheckbox,
                resetInput,
                clearImage,
                imagehandler,
                setVal,
                singleSelectChange,
                ISODays,
                countryList,
                optionsState,
                tempOptions,
                pullCustomerdata,
                activeLoader,
                closeLoader,
                btnLoader,
                onColorChange,
                invoiceFields,
                setInvoiceVal,
                invoiceFieldsChange,
                invoiceCheckBox
            }}
        >
            {props.children}
        </CustomerScreenContext.Provider>
    );
};
