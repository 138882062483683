import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { InputLabel, FormHelperText, Typography } from '@material-ui/core';
import { SecondaryButton, MandatoryField, DatePicker, SimpleField, YesButton, NoButton, SelectBox, SingleSelect, formatters } from '../../../Core/Controls';
import { PositiveInput } from '../../../Core/Controls/Inputs/PositiveInput';
import { useToast } from '../../../Core/Hooks/useToast';
import '../../commonStyle.scss';
import moment from 'moment';
import { isNull } from 'lodash';
import { getCompleteScheduleDropdowns, postCompletedSchedule } from '../../../Core/Services/InternalUser/ControllerService/scheduleVehicleService';
import { AppStorage } from '../../../Core/Basic/storage-service';

// const isOverDueSchedule = (dateCompleted, scheduleDateDue, dayRange) => {
//     dateCompleted = dateCompleted ? dateCompleted : moment().format();
//     let isSameWeek = moment(dateCompleted).isSame(scheduleDateDue, 'week');
//     let isSameMonth = moment(dateCompleted).isSame(scheduleDateDue, 'month');
//     if (scheduleDateDue < dateCompleted) {
//         if ((dayRange == 1 && scheduleDateDue < dateCompleted) || (dayRange == 2 && !isSameWeek) || (dayRange == 3 && !isSameMonth)) {
//             return true;
//         }
//     }
//     return false;
// };

// const getScheduleColour = (dateCompleted, scheduleDateDue, dayRange) => {
//     let isOverDueSchedule = isOverDueSchedule(dateCompleted, scheduleDateDue, dayRange);
//     // dateCompleted = dateCompleted ? dateCompleted : moment().format();
//     if (dateCompleted && !isOverDueSchedule) {
//         return 'green';
//     } else if (isOverDueSchedule) {
//         return 'red';
//     } else {
//         return 'black';
//     }
// };

function CompleteScheduleModal(props) {
    const [btnLoader, setBtnLoader] = useState(false);

    const [state, setState] = useState({
        date: moment().format('YYYY-MM-DD'),
        scheduleID: null,
        isActive: true,
        regno: '',
        scheduleDateDue: '',
        createNewEvent: true,
        slippageValue: null,
        customer: '',
        serviceAgent: '',
        slippage: '',
        dateCompleted: '',
        mandateSlippage: false,
        failReason: '',
        mileage: null,
        dropDown: [],
        errors: {},
        dayRange: 1,
        scheduleEventName: ''
    });

    const { showToastSuccess, showToastError } = useToast();
    let checkhaveAccess = AppStorage.getAllowAcces();
    useEffect(async () => {
        if (props.rowData.scheduleID) {
            setState((state) => ({
                ...state,
                scheduleID: props.rowData.scheduleID,
                regno: props.rowData.vehicleRegNumber,
                scheduleDateDue: props.rowData.scheduleDateDue,
                customer: props.rowData.customerCompanyName,
                serviceAgent: props.rowData.agentName,
                dayRange: props.rowData?.dayRange,
                scheduleEventName: props.rowData?.scheduleEventName,
                createNewEvent: props.rowData.createNew ==true ? true : false
                // dateCompleted: props.rowData.scheduleCompletionDate || ''
                // scheduleDescription: res.data.scheduleDescription
            }));
        }
        let res = await getCompleteScheduleDropdowns();
        setState((state) => ({
            ...state,
            dropDown: res.data.slippageReasons
        }));
    }, []);

    useEffect(() => {
        if (formatters.isOverDueSchedule(state.dateCompleted, state.scheduleDateDue, state.dayRange)) {
            setState((state) => ({
                ...state,
                mandateSlippage: true
            }));
        }

        // else {
        //     setState((state) => ({
        //         ...state,
        //         mandateSlippage: false
        //     }));
        // }
        if (state.isActive !== false) {
            setState((state) => ({
                ...state,
                failReason: ''
            }));
        }
    }, [state.scheduleDateDue, state.dateCompleted, state.isActive]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === 'dateCompleted') {
            setState((state) => ({
                ...state,
                mandateSlippage: false
            }));
        }
        setState((state) => ({
            ...state,
            [name]: value,
            errors: {}
        }));
    };

    const handleClick = (name, value) => {
        setState((st) => {
            const nst = { ...st, [name]: value };
            return nst;
        });
    };

    const validations = () => {
        const { dateCompleted, mandateSlippage, slippage, slippageValue, failReason, isActive } = state;
        let emptyStrigPattern = /[^\s]/;
        let formIsValid = true;
        let errors = {};
        if (!emptyStrigPattern.test(dateCompleted)) {
            errors.dateCompleted = ' Date completed is required';
            formIsValid = false;
        }
        if (isNull(slippageValue) && mandateSlippage) {
            errors.slippage = ' Slippage reason is required';
            formIsValid = false;
        }
        if (!emptyStrigPattern.test(failReason) && isActive === false) {
            errors.failReason = ' Failure reason is required';
            formIsValid = false;
        }
        if (state.mileage < 0) {
            errors.mileage = ' Odometer reading can not be negative';
            formIsValid = false;
        }
        setState((state) => ({ ...state, errors: errors }));
        return formIsValid;
    };

    const submitHandler = async () => {
        if (validations()) {
            setBtnLoader(true);
            let res = await postCompletedSchedule({
                scheduleID: props.rowData.scheduleID,
                scheduleDateCompleted: state.dateCompleted,
                scheduleTestResult: state.isActive === true ? 1 : state.isActive === false ? 2 : 3,
                scheduleFailReason: state.failReason || null,
                scheduleSlippageReasonID: state.slippageValue?.id || null,
                scheduleActualMiles: +state.mileage || null,
                createNewEvent: state.createNewEvent
            });
            if (res?.success) {
                showToastSuccess('Schedule Completed Successfully');
                props.onClose(true);
            } else {
                for (const [key, value] of Object.entries(res.errors)) {
                    showToastError(`${key}: ${value}`);
                }
            }
            setBtnLoader(false);
        }
    };

    const singleSelectChange = (nm) => (ev, val) => {
        setState((st) => ({ ...st, [nm]: val }));
    };

    return (
        <Grid container direction="row" spacing={2}>
            <Grid xs={3} item className="typo">
                <FormHelperText>Reg Number</FormHelperText>
                <Typography>{state.regno}</Typography>
            </Grid>
            <Grid xs={3} item className="typo">
                <FormHelperText>Date Due</FormHelperText>
                <Typography>{state.scheduleDateDue && moment(state.scheduleDateDue).format('DD/MM/YYYY')}</Typography>
            </Grid>
            <Grid xs={6} item className="typo">
                <FormHelperText>Customer</FormHelperText>
                <Typography>{state.customer}</Typography>
            </Grid>
            <Grid xs={6} item className="typo">
                <FormHelperText>Service Agent</FormHelperText>
                <Typography>{state.serviceAgent}</Typography>
            </Grid>
            <Grid xs={6} item className="typo">
                <FormHelperText>Event</FormHelperText>
                <Typography>{state.scheduleEventName}</Typography>
            </Grid>

            <Grid item xs={12} className="mandatory-fields">
                <InputLabel required shrink>
                    Date Completed
                </InputLabel>
                <DatePicker
                    placeholder="Date "
                    InputProps={{ inputProps: { max: `${state.date}` } }}
                    value={state.dateCompleted}
                    onChange={handleInputChange}
                    name="dateCompleted"
                />
                <FormHelperText error>{state.errors.dateCompleted}</FormHelperText>
            </Grid>
            {
                <Grid item xs={12} className={state.mandateSlippage && 'mandatory-fields'}>
                    <InputLabel required={state.mandateSlippage} shrink>
                        Slippage Reason
                    </InputLabel>
                    {/* <SelectBox List={state.dropDown || []} value={state.slippage} name="slippage" onChange={handleInputChange} /> */}
                    <SingleSelect options={state.dropDown} value={state.slippageValue} onChange={singleSelectChange('slippageValue')} />
                    <FormHelperText error>{state.errors.slippage}</FormHelperText>
                </Grid>
            }
            <Grid item xs={12}>
                <InputLabel shrink>Odometer Reading</InputLabel>
                <PositiveInput name="mileage" placeholder="Odometer Reading" onChange={handleInputChange} value={state.mileage} />
                <FormHelperText error>{state.errors.mileage}</FormHelperText>
            </Grid>
            {props.rowData.scheduleEventDisplayResult && (
                <Grid xs={12} item>
                    <InputLabel shrink>Result</InputLabel>
                    <Grid xs={12} container spacing={1}>
                        <Grid item xs={6}>
                            <YesButton btnName="Pass" state={state.isActive} name="isActive" onClick={() => handleClick('isActive', true)} />
                        </Grid>
                        <Grid item xs={6}>
                            <NoButton
                                btnName="Fail"
                                state={state.isActive === false ? false : true}
                                name="isActive"
                                onClick={() => handleClick('isActive', false)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}
            {state.isActive === false && (
                <Grid item xs={12} className={'mandatory-fields'}>
                    <MandatoryField
                        name="failReason"
                        value={state.failReason}
                        onChange={handleInputChange}
                        placeholder="Failure Reason"
                        inputLabel="Failure Reason"
                        errors={state.errors.failReason}
                    />
                </Grid>
            )}
            <Grid xs={12} item>
                <InputLabel shrink>Create New Schedule?</InputLabel>
                <Grid xs={12} container spacing={1}>
                    <Grid item xs={6}>
                        <YesButton btnName="Yes" state={state.createNewEvent} name="createNewEvent" onClick={() => handleClick('createNewEvent', true)} />
                    </Grid>
                    <Grid item xs={6}>
                        <NoButton
                            btnName="No"
                            state={state.createNewEvent === false ? false : true}
                            name="createNewEvent"
                            onClick={() => handleClick('createNewEvent', false)}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className="submit_btn">
                <SecondaryButton onClick={submitHandler} isBtnLoader={btnLoader} disabled={!checkhaveAccess?.CanUpdateSchedules}>
                    Complete and Schedule Next
                </SecondaryButton>
            </Grid>
        </Grid>
    );
}

export default CompleteScheduleModal;
