import moment from 'moment';
import { SortableHeaderCell } from 'react-data-grid';
const styles = { link: { textDecoration: 'none', color: '#f50057' } };
const formatters = {
    YesNo: (params) => {
        const field = params.column.key;
        return params.row[field] ? 'Yes' : 'No';
    },
    Date_DDMMYYYY: (params) => {
        const field = params.column.key;
        const val = params.row[field];
        return val ? moment(val).format('DD/MM/YYYY') : '--:--';
    },
    Date_DDMMYY: (params) => {
        const field = params.column.key;
        const val = params.row[field];
        return val ? moment(val).format('DD/MM/YY') : '--:--';
    },
    Date_DDMMMYYYY: (params) => {
        const field = params.column.key;
        const val = params.row[field];
        return val ? moment(val).format('DD-MMM-YYYY') : '-';
    },
    Date_DDMMMYYYY_HHMM: (params) => {
        const field = params.column.key;
        const val = params.row[field];
        return val ? moment(val).format('DD-MMM-YYYY HH:mm') : '-';
    },
    DateFormatter: (params) => {
        return params ? moment(params).format('YYYY-MM-DD') : '';
    },
    DateOnlyFormatter: (params) => {
        return params ? moment(params).format('DD-MMM-YYYY') : '-';
    },
    YearFormatter: (params) => {
        return params ? moment(params).format('DD/MM/YYYY') : '--:--';
    },
    DateTimeFormatter: (params) => {
        return params ? moment(params).format('DD-MMM-YYYY HH:mm') : null;
    },
    NumberWithPound: (params, grid) => {
        const field = params.column.key;
        const val = +params.row[field];
        if (grid.isGijgo) {
            return `<div style=" text-align:right">£${val ? val.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : '0.00'}</div>`;
        } else {
            return (
                <div
                    style={{
                        textAlign: 'right'
                    }}
                >
                    {val ? <>£{val.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</> : <>£0.00</>}
                </div>
            );
        }
    },
    ColumnTextAlignRight: (params, grid) => {
        const field = params.column.key;
        const val = params.row[field];

        if (grid.isGijgo) {
            return `<div style=" text-align:right ">${val}</div>`;
        } else {
            return <div style={{ textAlign: 'right' }}>{val}</div>;
        }
    },

    ColumnHeaderAlignRight: (params) => {
        const val = params.column.name;
        return (
            <SortableHeaderCell {...params}>
                <div style={{ textAlign: 'right' }}>{val}</div>
            </SortableHeaderCell>
        );
    },
    formatWithComma: (params) => {
        return !!params ? `${params}, ` : '';
    },
    capitalize: (string) => {
        return string ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase() : '';
    },
    dueDateFormatter: (data, grid) => {
        const field = data.column.key;
        const val = data.row[field];
        let textColor = '';
        let textWight = '';
        const dtDueIn = moment(val);
        const daysPast = moment().diff(dtDueIn, 'days');
        if (daysPast > 0) {
            textColor = 'red';
            textWight = 'bold';
        } else if (daysPast >= -14 && daysPast <= 0) {
            textColor = '#FFBF00';
            textWight = 'bold';
        } else {
            textColor = 'green';
            textWight = 'bold';
        }

        if (grid.isGijgo) {
            return `<div style=" color: ${textColor}; font-weight: ${textWight} ">${formatters.YearFormatter(val)}</div>`;
        } else {
            return <div style={{ color: `${textColor}`, fontWeight: `${textWight}` }}>{formatters.YearFormatter(val)}</div>;
        }
    },
    addressFormatter: (...values) => {
        return values
            .map((x) => x?.trim())
            .filter((x) => x)
            .join(', ');
    },
    phoneNumberFormatter: (data) => {
        return data ? (
            <a style={styles.link} href={`tel:${data}`}>
                {data}
            </a>
        ) : (
            'N\\A'
        );
    },
    mailFormatter: (data) => {
        return data ? (
            <a style={styles.link} href={`mailto:${data}`}>
                {data}
            </a>
        ) : (
            'N\\A'
        );
    },
    ToFixedDigit: (val) => {
        return <span>{val ? <>{val.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</> : <>0.00</>}</span>;
    },
    numberThousandsSeparator: (val) => {
        return val.toLocaleString('en-US', { maximumFractionDigits: 2 });
        //val.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
    },
    isOverDueSchedule: (dateCompleted, scheduleDateDue, dayRange) => {
        dateCompleted = dateCompleted ? dateCompleted : moment().format();
        let isSameWeek = moment(dateCompleted).isSame(scheduleDateDue, 'week');
        let isSameMonth = moment(dateCompleted).isSame(scheduleDateDue, 'month');
        if (scheduleDateDue < dateCompleted) {
            if ((dayRange == 1 && scheduleDateDue < dateCompleted) || (dayRange == 2 && !isSameWeek) || (dayRange == 3 && !isSameMonth)) {
                return true;
            }
        }
        return false;
    },
    getScheduleColour: (dateCompleted, scheduleDateDue, dayRange) => {
        let isOverDueSchedule = formatters.isOverDueSchedule(dateCompleted, scheduleDateDue, dayRange);
        // dateCompleted = dateCompleted ? dateCompleted : moment().format();
        if (dateCompleted && !isOverDueSchedule) {
            return 'green';
        } else if (isOverDueSchedule) {
            return 'red';
        } else {
            return 'black';
        }
    }
};
export default formatters;
