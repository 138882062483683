import React, { useContext, useRef, useState, useCallback, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { FormHelperText, InputLabel, Typography } from '@material-ui/core';
import { InputText, SecondaryButton, SecondaryCheckbox, DatePicker } from '../../../../Core/Controls';
import { CustomIconButton } from '../../../../Core/Controls/Inputs/Buttons/ButtonModal';
import ClearIcon from '@material-ui/icons/Clear';
import { postAdministratorSignOff } from '../../../../Core/Services/InternalUser/Maintenance/DefectBreackDownStepper/DefectBreackDownStepper';
import { useToast } from '../../../../Core/Hooks/useToast';
import SignaturePad from 'react-signature-canvas';
import { Tooltip } from '@material-ui/core';
import { AppStorage } from '../../../../Core/Basic/storage-service';
import DeleteIcon from '@material-ui/icons/Delete';
import CreateIcon from '@material-ui/icons/Create';
import BorderColorIcon from '@material-ui/icons/BorderColor';

const AdministratorSignOff = (props) => {
    const [state, setState] = useState({
        DefectSignedOffByName: props.displayName,
        signAvailable: AppStorage.getSignature() === 'absent' ? false : true,
        DefectSignOffNotes: '',
        errors: {}
    });
    const { showToastSuccess, showToastError } = useToast();
    const signCanvas = useRef({});

    useEffect(() => {
        if (props.jobData.vehicleJobProgressSignOffSignature) {
            setState((st) => ({
                ...st,
                isDisabled: true,
                imgadministartorsign: true,
                DefectSignOffSignature: props.jobData.vehicleJobProgressSignOffSignature,
                DefectSignOffNotes: props.jobData.vehicleJobProgressSignOffNotes,
                vehicleJobProgressStatusCode: props.jobData.vehicleJobProgressStatusCode === 'JS'
            }));
        }
    }, []);

    const clear = () => {
        if (state.imgadministartorsign) {
            setState((st) => ({
                ...st,
                imgadministartorsign: null,
                DefectSignOffSignature: null
            }));
        } else {
            signCanvas.current.clear();
        }
    };

    const GetTechnicianSign = () => {
        setState((st) => {
            const nst = {
                ...st,
                DefectSignOffSignature: signCanvas.current.toDataURL()
            };
            return nst;
        });
    };

    const InputChangeHandler = useCallback((e) => {
        const { name, value } = e.target;
        setState((st) => ({ ...st, [name]: value }));
    }, []);

    const validations = () => {
        const { DefectSignOffNotes, DefectSignOffSignature, DefectSignedOffByName } = state;
        let formIsValid = true;
        let errors = {};
        // if (!DefectSignOffNotes) {
        //     errors.DefectSignOffNotes = 'This field is required';
        //     formIsValid = false;
        // }
        if (!DefectSignedOffByName) {
            errors.DefectSignedOffByName = 'This field is required';
            formIsValid = false;
        }
        if (!DefectSignOffSignature) {
            errors.DefectSignOffSignature = 'This field is required';
            formIsValid = false;
        }
        setState((st) => ({ ...st, errors: errors }));
        return formIsValid;
    };

    const SubmitHandler = async () => {
        if (validations()) {
            let FormData = {
                defectID: props.defectID,
                jobID: props.jobData.vehicleJobProgressJobID,
                defectSignOffNotes: state.DefectSignOffNotes,
                defectSignedOffByName: state.DefectSignedOffByName,
                defectSignOffSignature: state.DefectSignOffSignature
                // defectSignOffIPAddress: ''
            };
            let res = await postAdministratorSignOff(FormData);
            if (res.success) {
                showToastSuccess(`Administartor sign off completed successfully`);
                props.onClose(true);
            } else {
                showToastError(res.message);
            }
        }
    };

    const useDefaultSig = () => {
        setState((st) => ({ ...st, imgadministartorsign: true, DefectSignOffSignature: AppStorage.getSignature() }));
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} className="mandatory-fields">
                <InputLabel style={{ fontWeight: 'bold' }}>{props.jobData.defect}</InputLabel>
            </Grid>
            <Grid item xs={12} sm={12}>
                <InputLabel shrink>Technician Comment</InputLabel>
                <InputText
                    multiline
                    rows={3}
                    placeholder="Comment"
                    value={props.jobData.vehicleJobProgressTechnicianSignOffNotes}
                    name="defectDescription"
                    disabled
                />
            </Grid>
            <Grid item xs={12} sm={12}>
                <InputLabel shrink>Signature</InputLabel>
                {props.jobData.vehicleJobProgressTechnicianSignOffSignature ? (
                    <img
                        src={props.jobData.vehicleJobProgressTechnicianSignOffSignature}
                        alt="my signature"
                        style={{
                            display: 'block',
                            margin: '0 auto',
                            border: '1px solid black',
                            width: '100%',
                            height: '100px'
                        }}
                    />
                ) : null}
                <InputLabel shrink>{props.jobData.vehicleJobProgressDateTechnicianSignOff}</InputLabel>
            </Grid>
            {/* <Grid item xs={12} sm={12} className="mandatory-fields">
                <InputLabel style={{ fontWeight: 'bold' }}>Declaration</InputLabel>
                <Typography variant="body2">{props.declaration}</Typography>
            </Grid> */}
            <Grid item xs={12} sm={12}>
                <InputLabel shrink>Comment</InputLabel>
                <InputText
                    multiline
                    rows={3}
                    placeholder="Comment"
                    value={state.DefectSignOffNotes}
                    onChange={InputChangeHandler}
                    name="DefectSignOffNotes"
                    disabled={state.isDisabled}
                />
                <FormHelperText error>{state.errors.DefectSignOffNotes}</FormHelperText>
            </Grid>
            <Grid item xs={12} sm={12} className="mandatory-fields">
                <InputLabel shrink>Name</InputLabel>
                <InputText placeholder="Comment" value={state.DefectSignedOffByName} onChange={InputChangeHandler} name="DefectSignedOffByName" />
                <FormHelperText error>{state.errors.DefectSignedOffByName}</FormHelperText>
            </Grid>
            <Grid item xs={12} className="mandatory-fields">
                <div style={{ position: 'relative' }}>
                    {/* <div style={{ display: 'flex', justifyContent: 'center' }}> */}
                    <InputLabel
                        shrink
                        component="span"
                        style={{
                            marginTop: '5px'
                        }}
                    >
                        Administrator Signature
                        {state.signAvailable ? (
                            <Tooltip title="Use default signature">
                                <BorderColorIcon onClick={useDefaultSig} style={{ margin: '0px 0px -5px 5px' }} />
                            </Tooltip>
                        ) : null}
                    </InputLabel>
                    {/* </div> */}
                    <div
                        style={{
                            position: 'absolute',
                            right: 0
                        }}
                    >
                        {!state.isDisabled && (
                            <Tooltip title="Clear Signature">
                                <DeleteIcon onClick={clear} />
                            </Tooltip>
                        )}
                    </div>

                    {state.imgadministartorsign ? (
                        <img
                            src={state.DefectSignOffSignature}
                            alt="my signature"
                            style={{
                                display: 'block',
                                margin: '0 auto',
                                border: '1px solid black',
                                width: '100%',
                                height: '165px'
                            }}
                        />
                    ) : (
                        <div className="sigContainer">
                            <SignaturePad
                                canvasProps={{ className: 'sigPad' }}
                                ref={signCanvas}
                                onEnd={() => {
                                    GetTechnicianSign();
                                }}
                            />
                        </div>
                    )}

                    <FormHelperText error>{state.errors.DefectSignOffSignature}</FormHelperText>
                </div>
            </Grid>
            <Grid item xs={12}>
                {props.showStepJob && (
                    <SecondaryButton fullWidth className="submit_btn" onClick={SubmitHandler} disabled={state.isDisabled}>
                        Sign off
                    </SecondaryButton>
                )}
                {state.vehicleJobProgressStatusCode && (
                    <SecondaryButton fullWidth className="submit_btn" onClick={SubmitHandler}>
                        Re-create job
                    </SecondaryButton>
                )}
            </Grid>
        </Grid>
    );
};

export default AdministratorSignOff;
