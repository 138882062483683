import { getWithAuth, postWithAuth } from '../Basic/HttpService';
import { AppStorage } from '../Basic/storage-service';

export const getUploaderConfig = async () => {
    let res = await getWithAuth('FileUploader');
    if (res.success) {
        AppStorage.saveFileUploaderConfig(res.data);
    }
};

export const getFilesForConfig = async (configId, recordId, docIDPassed = false) => {
    if (docIDPassed == undefined || docIDPassed == null) {
        docIDPassed = false;
    }
    let res = await getWithAuth(`FileUploader/${configId}/${recordId}?docIDPassed=${docIDPassed}`);
    return res;
};

export const uploadFileUsignUploaderConfig = async (configId, recordId, formData, docIDPassed) => {
    if (docIDPassed == undefined || docIDPassed == null) {
        docIDPassed = false;
    }
    let res = await postWithAuth(`FileUploader/${configId}/${recordId}?docIDPassed=${docIDPassed}`, formData);
    return res;
};

export const updateName = async (configId, recordId, uniqueFileName, newName) => {
    let res = await postWithAuth(`FileUploader/rename`, {
        configId,
        recordId,
        uniqueFileName,
        newName
    });
    return res;
};

export const deleteUploadedFile = async (configId, recordId, uniqueFileName) => {
    let res = await postWithAuth(`FileUploader/delete`, {
        configId,
        recordId,
        uniqueFileName
    });
    return res;
};

export const downloadUploadedFile = async (configId, recordId, uniqueFileName, fileNameFull, docIDPassed) => {
    if (docIDPassed == undefined || docIDPassed == null) {
        docIDPassed = false;
    }
    let res = await postWithAuth(
        `FileUploader/download`,
        {
            configId,
            recordId,
            uniqueFileName,
            docIDPassed
        },
        { responseType: 'blob', returnOrgRes: true }
    );

    if (res.status === 200) {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.getElementById('tempDownloadLink') || document.createElement('a');
        link.id = 'tempDownloadLink';
        link.href = url;
        link.setAttribute('download', fileNameFull);
        document.body.appendChild(link);
        link.click();
        return true;
    }
    return false;
};
