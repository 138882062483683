import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { InputLabel, FormHelperText } from '@material-ui/core';
import { SingleSelect, SecondaryButton, SelectBox, MandatoryField, SimpleField } from '../../../../Core/Controls';
import { getAllJobDropDowns } from '../../../../Core/Services/InternalUser/ControllerService/jobDetailsService';
import { postJobData } from '../../../../Core/Services/InternalUser/JOB/jobService';
import { useToast } from '../../../../Core/Hooks/useToast';
import '../controllerStyle.scss';

function JobScreenModal(props) {
    const [state, setState] = useState({
        errors: {},
        description: '',
        customerOrderNo: '',
        serviceAgentList: [],
        jobDepotList: [],
        jobAuthorityRef: ''
    });

    
    const [tempOptions, setTempOptions] = useState({
        jobDepotID: null,
        serviceAgentID: null
    });
    const { showToastSuccess, showToastError } = useToast();

    useEffect(async () => {
        let res = await getAllJobDropDowns(props.VehicleID);
        setState((state) => ({
            ...state,
            jobDepotList: res.data.depots,
            serviceAgentList: res.data.serviceAgents,
            vehicleId: props.VehicleID
        }));
    }, []);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setState((state) => ({
            ...state,
            [name]: value
        }));
    };

    const validations = () => {
        const { serviceAgentID, jobDepotID, description } = state;
        let emptyStrigPattern = /[^\s]/;
        let formIsValid = true;
        let errors = {};
        if (!emptyStrigPattern.test(description)) {
            errors.description = 'Description is required';
            formIsValid = false;
        }
        if (!emptyStrigPattern.test(serviceAgentID)) {
            errors.serviceAgentID = ' Service Agent is required';
            formIsValid = false;
        }
        if (!emptyStrigPattern.test(jobDepotID)) {
            errors.jobDepotID = ' Job Depot is required';
            formIsValid = false;
        }
        setState((state) => ({ ...state, errors: errors }));
        return formIsValid;
    };

    const singleSelectChange = (nm) => (ev, val) => {
        setTempOptions((st) => ({ ...st, [nm]: val }));
    };
    const submitHandler = async () => {
        if (validations()) {
            const data = {
                vehicleID: props.VehicleID,
                description: state.description,
                jobCustomerOrderNo: state.customerOrderNo,
                jobServiceAgentID: tempOptions.serviceAgentID?.id,
                jobDepotID: tempOptions.jobDepotID?.id,
                jobAuthorityRef: state.jobAuthorityRef
            };
            props.onClose(true);
            let res = await postJobData(data);
            if (res.success) {
                showToastSuccess('Job added sucessfully');
                props.onClose(true);
            } else {
                showToastError(res.message);
            }
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} className="mandatory-fields">
                <MandatoryField inputLabel="Description" name="description" value={state.description} onChange={handleInputChange} />
                <FormHelperText error>{state.errors.description}</FormHelperText>
            </Grid>
            <Grid item xs={12} sm={6} className="mandatory-fields">
                <InputLabel required shrink>
                    Service Agent
                </InputLabel>
                <SingleSelect options={state.serviceAgentList} value={tempOptions.serviceAgentID} onChange={singleSelectChange('serviceAgentID')} />
            </Grid>
            <Grid item xs={12} sm={6} className="mandatory-fields">
                <InputLabel required shrink>
                    Job Depot
                </InputLabel>
                <SingleSelect options={state.jobDepotList} value={tempOptions.jobDepotID} onChange={singleSelectChange('jobDepotID')} />
                <FormHelperText error>{state.errors.jobDepotID}</FormHelperText>
            </Grid>
            <Grid item xs={12} sm={6}>
                <SimpleField
                    inputLabel="Customer Order No"
                    name="customerOrderNo"
                    placeholder="Customer Order No"
                    onChange={handleInputChange}
                    value={state.customerOrderNo}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <SimpleField inputLabel="Authority Reference" value={state.jobAuthorityRef} name="jobAuthorityRef" onChange={handleInputChange} />
            </Grid>
            <Grid item xs={6} className="submit_btn">
                <SecondaryButton onClick={submitHandler}>Add</SecondaryButton>
            </Grid>
            <Grid item xs={6} className="submit_btn">
                <SecondaryButton forceEnabled={true} onClick={props.onClose}>
                    Cancel
                </SecondaryButton>
            </Grid>
        </Grid>
    );
}

export default JobScreenModal;
