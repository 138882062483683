import { useToast } from '../../../../Core/Hooks/useToast';
import { addDepot, updateDepot, getDepotById, getDropdown } from '../../../../Core/Services/InternalUser/ControllerService/depotsService';
import React, { createContext, useEffect, useState, useCallback } from 'react';
import { validatePostCode } from '../../../../Core/Basic/StaticService';
import { isNull } from 'lodash';

export const VehicleScreenContext = createContext();
let invoiceInfo = {
    depotUseAddressAsInvoiceAddress: false,
    depotInvoiceAddress1: '',
    depotInvoiceAddress2: '',
    depotInvoiceAddressCity: '',
    depotInvoiceAddressCounty: '',
    depotInvoiceAddressPostCode: '',
    depotInvoiceAddressCountryCode: 'GB',
    depotInvoiceEmailAddress: '',
    depotEmailInvoices: false
};
export const VehicleProvider = (props) => {
    const { showToastSuccess, showToastError } = useToast();
    const [depotFields, setDepotFields] = useState({
        name: '',
        address1: '',
        address2: '',
        depotTown: '',
        depotCounty: '',
        depotCountyCode: '',
        depotEmail: '',
        depotTelephone: '',
        depotPostCode: '',
        depotNotInUse: true,
        depotCustomersRef: '',
        serviceAgentID: '',
        firstDayofWeek: null,
        scheduleBookingModeList: [],
        depotVORMobileNumbers: '',
        depotContact: '',
        depotServiceAgentID: '',
        depotAgentSecondaryAgentID: '',
        serviceAgentsList: [],
        errors: {}
    });

    const [scheduleFields, setScheduleFields] = useState({
        errors: {},
        depotID: null,
        mergeSchedules: true,
        displayISOWeek: true,
        addPaperworkReceived: true,
        trafficStyleColourCoding: true,
        MergeRange: '',
        depotDefectNotificationEmail: '',
        sunday: false,
        monday: true,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        depotAdditionalPlanningEmails: '',
        cPCHolderEmail: '',
        cPCHolderName: '',
        secondaryEmail: '',
        depotSecondaryContact: '',
        depotPCNEmail: '',
        depotAccountName: '',
        depotAccountEmail: '',
        depotThirdPartyPlannerEmailAddress: '',
        depotBreakdownNotificationEmail: '',
        depotTimedVehicleStatusUpdateEmail: '',
        depotSchedulePlannerEmail: '',
        depotNotes: '',
        scheduleBookingModeList: [],
        depotScheduleBookingMode: ''
    });

    const [invoiceFields, setInvoiceFields] = useState({
        ...invoiceInfo,
        errors: {}
    });
    useEffect(async () => {
        let res = await getDropdown();
        const scheduleBookingModeList = res.data.scheduleBookingModes.map((item) => ({
            id: item.scheduleBookingModeCode,
            name: item.scheduleBookingModeDescription
        }));
        setDepotFields((st) => ({
            ...st,
            scheduleBookingModeList: scheduleBookingModeList,
            serviceAgentsList: res.data.serviceAgents
        }));
        if (props.depotID) {
            let res = await getDepotById(props.depotID);
            const data = res.data.details[0];
            setDepotFields((st) => ({
                ...st,
                name: data.depotName,
                address1: data.depotAddress1,
                address2: data.depotAddress2,
                depotCounty: data.depotCounty,
                depotTown: data.depotTown,
                depotCountyCode: data.depotCountryCode,
                depotEmail: data.depotEmail,
                depotCustomersRef: data.depotCustomersRef,
                scheduleBookingModeID: data.scheduleBookingModeID,
                depotPostCode: data.depotPostCode,
                depotTelephone: data.depotTelephone,
                depotContact: data.depotContact,
                depotNotInUse: data.depotNotInUse,
                depotCustomerID: data.depotCustomerID,
                firstDayofWeek: data.depotFirstDayofWeek,
                depotVORMobileNumbers: data.depotVORMobileNumbers,
                depotServiceAgentID: data.depotPrimaryAgentID,
                depotAgentSecondaryAgentID: data.depotAgentSecondaryAgentID
            }));
            setScheduleFields((st) => ({
                ...st,
                depotID: data.depotID,
                depotCustomerID: data.depotCustomerID,
                scheduleBookingModeID: data.scheduleBookingModeID,
                MergeSchedules: data.depotPlannerMergeSchedules,
                displayISOWeek: data.depotPlannerDisplayISOWeek,
                addPaperworkReceived: data.depotPlannerAddPaperworkReceived,
                trafficStyleColourCoding: data.depotPlannerTrafficStyleColourCoding,
                mergeRange: data.depotPlannerMergeRange,
                sunday: data.depotBookingPreferenceSunday,
                depotDefectNotificationEmail: data.depotDefectNotificationEmail,
                monday: data.depotBookingPreferenceMonday,
                tuesday: data.depotBookingPreferenceTuesday,
                wednesday: data.depotBookingPreferenceWednesday,
                thursday: data.depotBookingPreferenceThursday,
                friday: data.depotBookingPreferenceFriday,
                saturday: data.depotBookingPreferenceSaturday,
                depotAdditionalPlanningEmails: data.depotAdditionalPlanningEmails,
                cPCHolderEmail: data.depotCPCHolderEmail,
                cPCHolderName: data.depotCPCHolderName,
                secondaryEmail: data.depotSecondaryEmail,
                depotSecondaryContact: data.depotSecondaryContact,
                depotPCNEmail: data.depotPCNEmail,
                depotAccountName: data.depotAccountName,
                depotAccountEmail: data.depotAccountEmail,
                depotContactMobile: data.depotContactMobile,
                depotThirdPartyPlannerEmailAddress: data.depotThirdPartyPlannerEmailAddress,
                depotScheduleBookingMode: data.depotScheduleBookingMode,
                depotBreakdownNotificationEmail: data.depotBreakdownNotificationEmail,
                depotTimedVehicleStatusUpdateEmail: data.depotTimedVehicleStatusUpdateEmail,
                depotSchedulePlannerEmail: data.depotSchedulePlannerEmail,
                depotNotes: data.depotNotes
            }));
            setInvoiceFields((dt) => ({
                ...dt,
                depotUseAddressAsInvoiceAddress: data.depotUseAddressAsInvoiceAddress,
                depotInvoiceAddress1: data.depotInvoiceAddress1,
                depotInvoiceAddress2: data.depotInvoiceAddress2,
                depotInvoiceAddressCity: data.depotInvoiceAddressCity,
                depotInvoiceAddressCounty: data.depotInvoiceAddressCounty,
                depotInvoiceAddressPostCode: data.depotInvoiceAddressPostCode,
                depotInvoiceAddressCountryCode: data.depotInvoiceAddressCountryCode,
                depotInvoiceEmailAddress: data.depotInvoiceEmailAddress,
                depotEmailInvoices: data.depotEmailInvoices
            }));
        }
    }, []);

    const setVal = (s) => {
        setInvoiceFields((st) => ({
            ...st,
            depotInvoiceAddress1: s.addressLine1,
            depotInvoiceAddress2: s.addressLine2,
            depotInvoiceAddressCity: s.town,
            depotInvoiceAddressCounty: s.county,
            depotInvoiceAddressPostCode: s.postCode
        }));
    };
    const invoiceFieldsChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (value === 'true' && type.toLowerCase() === 'radio') {
            setInvoiceFields((st) => ({ ...st, ...invoiceInfo, [name]: true }));
        } else if (value === 'false' && type?.toLowerCase() === 'radio') {
            setInvoiceFields((st) => ({ ...st, [name]: false }));
        } else {
            setInvoiceFields((st) => ({ ...st, [name]: value }));
        }
    };
    const invoiceCheckBox = (e) => {
        const { name, checked } = e.target;
        setInvoiceFields((st) => ({ ...st, [name]: checked }));
    };

    const resetInput = (name) => {
        setInvoiceFields((st) => ({ ...st, [name]: '' }));
    };
    const scheduleDetailsChange = (e) => {
        const { name, value } = e.target;
        setScheduleFields((st) => ({ ...st, [name]: value }));
    };
    const handledepotFields = (e) => {
        const { name, value } = e.target;
        setDepotFields((st) => ({ ...st, [name]: value }));
    };
    const handledepotCheckbox = useCallback((e) => {
        const { name, checked } = e.target;
        setDepotFields((st) => {
            const nst = { ...st, [name]: checked };
            return nst;
        });
    }, []);
    const handleCheckbox = useCallback((e) => {
        const { name, checked } = e.target;
        setScheduleFields((st) => {
            const nst = { ...st, [name]: checked };
            return nst;
        });
    }, []);

    const handleActive = (val) => {
        setDepotFields((field) => ({
            ...field,
            depotNotInUse: val
        }));
    };

    const validations = () => {
        const { name, address1, address2, depotCounty } = depotFields;
        const { depotInvoiceEmailAddress, depotEmailInvoices } = invoiceFields;
        let formIsValid = true;
        let errors = {};
        if (!/[^\s]/.test(name)) {
            errors.name = 'Depot Name is required.';
            formIsValid = false;
        }
        if (!/[^\s]/.test(address1)) {
            errors.address1 = 'Depot Address1 is required.';
            formIsValid = false;
        }
        if (!/[^\s]/.test(depotCounty)) {
            errors.depotCounty = 'Depot county is required.';
            formIsValid = false;
        }

        // if (!validatePostCode(depotPostCode)) {
        //     errors.depotPostCode = `Please fill valid post code, "Ex: BB11AA"`;
        //     formIsValid = false;
        // }
        if (depotEmailInvoices && (!/[^\s]/.test(depotInvoiceEmailAddress) || isNull(depotInvoiceEmailAddress))) {
            errors.depotInvoiceEmailAddress = 'Email can not be empty for checked flag';
            formIsValid = false;
        }
        setDepotFields((st) => ({ ...st, errors: errors }));
        return formIsValid;
    };

    const handleSubmit = async () => {
        if (validations()) {
            const depotData = {
                depotID: scheduleFields.depotID,
                depotCustomerID: props.customerID,
                depotName: depotFields.name,
                depotAddress1: depotFields.address1,
                depotAddress2: depotFields.address2,
                depotCounty: depotFields.depotCounty,
                depotTown: depotFields.depotTown,
                depotCountryCode: depotFields.depotCountyCode,
                depotEmail: depotFields.depotEmail,
                depotCustomersRef: depotFields.depotCustomersRef,
                depotPostCode: depotFields.depotPostCode,
                depotTelephone: depotFields.depotTelephone,
                depotContact: depotFields.depotContact,
                depotNotInUse: depotFields.depotNotInUse,
                depotPrimaryAgentID: depotFields.depotServiceAgentID,
                depotAgentSecondaryAgentID: depotFields.depotAgentSecondaryAgentID,
                depotVORMobileNumbers: depotFields.depotVORMobileNumbers,
                depotPlannerMergeSchedules: scheduleFields.mergeSchedules,
                depotPlannerDisplayISOWeek: scheduleFields.displayISOWeek,
                depotPlannerAddPaperworkReceived: scheduleFields.addPaperworkReceived,
                depotPlannerTrafficStyleColourCoding: scheduleFields.trafficStyleColourCoding,
                depotPlannerMergeRange: scheduleFields.mergeRange,
                depotBookingPreferenceSunday: scheduleFields.sunday,
                depotDefectNotificationEmail: scheduleFields.depotDefectNotificationEmail,
                depotBookingPreferenceMonday: scheduleFields.monday,
                depotBookingPreferenceTuesday: scheduleFields.tuesday,
                depotBookingPreferenceWednesday: scheduleFields.wednesday,
                depotBookingPreferenceThursday: scheduleFields.thursday,
                depotBookingPreferenceFriday: scheduleFields.friday,
                depotBookingPreferenceSaturday: scheduleFields.saturday,
                depotAdditionalPlanningEmails: scheduleFields.depotAdditionalPlanningEmails,
                depotCPCHolderEmail: scheduleFields.cPCHolderEmail,
                depotCPCHolderName: scheduleFields.cPCHolderName,
                depotSecondaryEmail: scheduleFields.secondaryEmail,
                depotSecondaryContact: scheduleFields.depotSecondaryContact,
                depotPCNEmail: scheduleFields.depotPCNEmail,
                depotAccountName: scheduleFields.depotAccountName,
                depotContactMobile: scheduleFields.depotContactMobile,
                depotAccountEmail: scheduleFields.depotAccountEmail,
                depotThirdPartyPlannerEmailAddress: scheduleFields.depotThirdPartyPlannerEmailAddress,
                depotScheduleBookingMode: scheduleFields.depotScheduleBookingMode,
                depotBreakdownNotificationEmail: scheduleFields.depotBreakdownNotificationEmail,
                depotTimedVehicleStatusUpdateEmail: scheduleFields.depotTimedVehicleStatusUpdateEmail,
                depotVehicleStatusUpdateEmail: scheduleFields.depotVehicleStatusUpdateEmail,
                depotSchedulePlannerEmail: scheduleFields.depotSchedulePlannerEmail,
                depotfirstDayofWeek: scheduleFields.depotFirstDayofWeek,
                depotNotes: scheduleFields.depotNotes,
                depotUseAddressAsInvoiceAddress: invoiceFields.depotUseAddressAsInvoiceAddress,
                depotInvoiceAddress1: invoiceFields.depotInvoiceAddress1 || '',
                depotInvoiceAddress2: invoiceFields.depotInvoiceAddress2 || '',
                depotInvoiceAddressCity: invoiceFields.depotInvoiceAddressCity || '',
                depotInvoiceAddressCounty: invoiceFields.depotInvoiceAddressCounty || '',
                depotInvoiceAddressPostCode: invoiceFields.depotInvoiceAddressPostCode || '',
                depotInvoiceAddressCountryCode: invoiceFields.depotInvoiceAddressCountryCode || '',
                depotInvoiceEmailAddress: invoiceFields.depotInvoiceEmailAddress || '',
                depotEmailInvoices: invoiceFields.depotEmailInvoices
            };

            let res;
            if (props.depotID) {
                res = await updateDepot(depotData, props.depotID);
            } else {
                res = await addDepot(depotData);
            }
            if (res.success) {
                showToastSuccess('Depots saved successfully');
                props.onFormClose(true);
            } else {
                showToastError(res.message);
            }
        }
    };
    const closeModal = () => {
        props.onFormClose(false);
    };
    return (
        <VehicleScreenContext.Provider
            value={{
                scheduleFields,
                handleSubmit,
                scheduleDetailsChange,
                handleCheckbox,
                closeModal,
                handledepotFields,
                handledepotCheckbox,
                depotFields,
                handleActive,
                invoiceFieldsChange,
                invoiceFields,
                setVal,
                resetInput,
                invoiceCheckBox
            }}
        >
            {props.children}
        </VehicleScreenContext.Provider>
    );
};
